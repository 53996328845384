import { CryptoHelper } from "../../base/helpers/crypto-helper";
import { BusinessTechnicalIdentifierPair } from "./business-technical-identifier-pair";
import { EntityBusinessIdentifier } from "./entity-business-identifier";
import { EntityIdentifier } from "./entity-identifier";
import { EntityTechnicalIdentifier } from "./entity-technical-identifier";

/**
 * Helper for identifiers.
 */
export class Identifier {
    // noinspection JSUnusedLocalSymbols
    private constructor() {
        // Static class - do not allow creating an instance
    }

    private static idCounter: number = 0;

    public static readonly inMemoryIdentifierPrefix: string = "*";

    public static readonly placeholder: string = "┇PLACEHOLDER┇";

    public static identifierToString(identifier: EntityIdentifier): string {
        return `${identifier.businessIdentifier ? identifier.businessIdentifier : ""}:${identifier.technicalIdentifier ? identifier.technicalIdentifier : ""}`;
    }

    public static identifierToReadableString(identifier: EntityIdentifier|undefined): string {
        if (identifier) {
            if (identifier.businessIdentifier && identifier.technicalIdentifier) {
                return this.identifierToString(identifier);
            }
            if (identifier.businessIdentifier) {
                return identifier.businessIdentifier;
            }
            if (identifier.technicalIdentifier) {
                return `${identifier.technicalIdentifier}`;
            }
        }

        return "---";
    }

    public static identifierPartsToString(businessIdentifier?: EntityBusinessIdentifier, technicalIdentifier?: EntityTechnicalIdentifier): string {
        return `${businessIdentifier ? businessIdentifier : ""}:${technicalIdentifier ? technicalIdentifier : ""}`;
    }

    public static stringToIdentifier<TIdentifier extends EntityIdentifier>(identifierString?: string): TIdentifier {
        if (!identifierString?.includes(":")) {
            return this.empty();
        }
        const parts: Array<string> = identifierString.split(":");
        return this.create(parts[0], parts[1] ? Number(parts[1]) : undefined);
    }

    public static create<TIdentifier extends EntityIdentifier>(businessIdentifier?: EntityBusinessIdentifier, technicalIdentifier?: EntityTechnicalIdentifier): TIdentifier {
        return new BusinessTechnicalIdentifierPair({
            businessIdentifier: (businessIdentifier ?? "") as EntityBusinessIdentifier,
            technicalIdentifier: technicalIdentifier
        }) as TIdentifier;
    }

    public static createTemporaryIdentifier<TIdentifier extends EntityIdentifier>(technicalIdentifier?: EntityTechnicalIdentifier): TIdentifier {
        return this.create<TIdentifier>(`${Identifier.inMemoryIdentifierPrefix}${CryptoHelper.getUUID()}`, technicalIdentifier);
    }

    public static fromDto<TIdentifier extends EntityIdentifier, TDto extends { businessId?: string; technicalId?: number }>(dto: TDto|undefined): TIdentifier {
        return this.create<TIdentifier>(dto?.businessId, dto?.technicalId);
    }

    public static empty<TIdentifier extends EntityIdentifier>(): TIdentifier {
        return new BusinessTechnicalIdentifierPair({ businessIdentifier: "" as EntityBusinessIdentifier }) as TIdentifier;
    }

    public static isEmpty(identifier: EntityIdentifier|undefined): boolean {
        return !identifier ? true : !identifier.businessIdentifier && !identifier.technicalIdentifier;
    }

    public static isSameEntity(identifierA?: EntityIdentifier, identifierB?: EntityIdentifier): boolean {
        if (identifierA?.businessIdentifier && identifierA.businessIdentifier == identifierB?.businessIdentifier) {
            return true;
        } else if (identifierA?.businessIdentifier) {
            return false;
        }
        if (identifierA?.technicalIdentifier && identifierA.technicalIdentifier == identifierB?.technicalIdentifier) {
            return true;
        }
        return this.isEqual(identifierA, identifierB);
    }

    public static isEqual(identifierA?: EntityIdentifier, identifierB?: EntityIdentifier): boolean {
        return (identifierA?.businessIdentifier ?? "") == (identifierB?.businessIdentifier ?? "") && (identifierA?.technicalIdentifier ?? 0) == (identifierB?.technicalIdentifier ?? 0);
    }

    public static getNumericalId(): number {
        this.idCounter++;
        return this.idCounter;
    }

    public static merge<TIdentifier extends EntityIdentifier>(oldIdentifier: TIdentifier, newIdentifier: TIdentifier): TIdentifier {
        return Identifier.create<TIdentifier>(newIdentifier.businessIdentifier || oldIdentifier.businessIdentifier, newIdentifier.technicalIdentifier || oldIdentifier.technicalIdentifier);
    }
}
