import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";

/**
 * Form validators that extends the range of the framework validators.
 */
export class ExtendedValidators {
    public static async regexValidator(validatorType: string, pattern: string, control: AbstractControl|undefined): Promise<ValidationErrors|undefined> {
        if (!control) {
            return undefined;
        }
        const validatorResult: ValidationErrors|null = Validators.pattern(pattern)(control);
        if (validatorResult) {
            const result: any = {};
            result[validatorType] = false;
            return result;
        }
        return undefined;
    }

    public static yearValidator(control: AbstractControl|undefined): ValidationErrors|null {
        if (!control) {
            return null;
        }
        const validatorResult: ValidationErrors|null = Validators.pattern("^$|[0-9]{4}")(control);
        return validatorResult ? { year: true } : null;
    }

    public static extendedEmailValidator(control: AbstractControl|undefined): ValidationErrors|null {
        if (!control) {
            return null;
        }

        const validatorResult: ValidationErrors|null = Validators.pattern(".(.*)@.(.*)[\.].(.*)")(control);
        if (validatorResult) {
            return { email: true };
        }

        const regularResult: ValidationErrors|null = Validators.email(control);
        if (regularResult) {
            return regularResult;
        }
        return null;
    }

}
