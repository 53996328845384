/**
 * Information about the network directly given by the browser.
 */
export class NetworkConnectionInformation {
    public informationAvailable: boolean = false;

    public type?: string;

    public downlink: number = 0;

    public downlinkMax: number = 0;

    public effectiveType?: string;

    public roundTripTime: number = 0;

    public saveData: boolean = false;
}
