import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";

/**
 * Storage DTO for the partial document update.
 */
export abstract class PartialDocumentUpdateStorageDto {
    protected constructor(init?: Partial<PartialDocumentUpdateStorageDto>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public classId?: string;

    public updateType?: string;

    public saveState?: string;

    public updateCreated?: string;

    public personBusinessIdentifier?: string;

    public accountIdentifier?: number;

    public documentBusinessIdentifier?: string;

    public documentTechnicalIdentifier?: number;

    public documentType?: string;

    public synchronizationErrorString?: string;

    public noBatchUpdate?: boolean;
}
