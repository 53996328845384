// @formatter:off
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowsRepeat,
    faBallotCheck, faBook as faBookDuotone,
    faBriefcaseBlank as faBriefcaseBlankDuotone,
    faBuilding as faBuildingDuotone,
    faBuildings as faBuildingsDuotone,
    faCheckToSlot as faCheckToSlotDuotone,
    faCircleUser as faCircleUserDuotone,
    faDiagramNext as faDiagramNextDuotone,
    faDiagramProject,
    faDiagramSankey,
    faDroplet,
    faFaucet as faFaucetDuotone,
    faFileImage,
    faFileLines as faFileLinesDuotone,
    faFireFlame,
    faFolderImage,
    faGaugeHigh as faGaugeHighDuotone,
    faHeat,
    faMapLocation as faMapLocationDuotone,
    faMemoCircleInfo as faMemoCircleInfoDuotone,
    faMemoPad,
    faMicrochipAi as faMicrochipAiDuotone,
    faMugHot,
    faPage as faPageDuotone,
    faRectangleHistory as faRectangleHistoryDuotone,
    faShieldExclamation,
    faSquare as faSquareDuotone,
    faTableColumns,
    faTableTree as faTableTreeDuotone,
    faUser as faUserDuotone,
    faVial as faVialDuotone,
    faVials as faVialsDuotone} from "@fortawesome/pro-duotone-svg-icons";
import {
    fa0,
    fa1,
    fa2,
    fa3,
    fa4,
    fa7,
    fa9,
    faAddressCard, faAngle, faAngleRight,
    faApartment,
    faArrowDownArrowUp, faArrowDownToDottedLine,
    faArrowLeft,
    faArrowRight,
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faArrowsFromLine,
    faArrowsRotate, faArrowsToDot, faArrowsTurnRight, faArrowsUpDown, faArrowUpRightFromSquare,
    faBadge,
    faBadgeCheck as faBadgeCheckRegular,
    faBath,
    faBoxCheck,
    faBriefcaseBlank,
    faBriefcaseBlank as faBriefcaseBlankRegular,
    faBug,
    faBuilding as faBuildingRegular,
    faBuildingCircleXmark,
    faBuildings as faBuildingsRegular,
    faCalendarDay, faCalendarDays,
    faCamera,
    faChairOffice,
    faCheck,
    faCircle1,
    faCircleCheck, faCircleDashed,
    faCircleInfo,
    faCirclePlus as faCirclePlusRegular,
    faCircleQuestion,
    faCircleSmall,
    faCircleSort,
    faCircleTrash,
    faCircleUser as faCircleUserRegular,
    faCircleV,
    faCircleXmark,
    faCity,
    faClipboard, faClock, faClockRotateLeft, faClone,
    faCloud,
    faCloudArrowUp,
    faCloudCheck,
    faCloudMinus,
    faCloudPlus,
    faCloudQuestion,
    faCloudWord,
    faCloudXmark,
    faCodeCommit,
    faCommentLines,
    faCommentSmile,
    faDesktop,
    faDiagramNext as faDiagramNextRegular, faDial, faDialHigh, faDialLow, faDialMax, faDialMed, faDialMedLow, faDialMin,
    faDiamondExclamation as faDiamondExclamationRegular,
    faDiceFour,
    faDiceOne,
    faDiceThree,
    faDiceTwo,
    faDoorClosed, faDownload, faEllipsis, faEllipsisVertical,
    faEnvelope,
    faExclamation,
    faEye,
    faEyeSlash,
    faFanTable,
    faFaucet as faFaucetRegular,
    faFileArrowDown,
    faFileCirclePlus,
    faFileCircleQuestion,
    faFileInvoice,
    faFileLines as faFileLinesRegular,
    faFilePdf, faFileSlash,
    faFileUser, faFilterSlash,
    faFlag,
    faFloppyDisk,
    faFolderOpen, faForward,
    faGaugeHigh as faGaugeHighRegular,
    faGear,
    faGenderless,
    faGlobe,
    faHardDrive,
    faHeadset,
    faHouseBuilding,
    faIdBadge as faIdBadgeRegular,
    faImages,
    faImageSlash, faInputText, faKey,
    faLayerPlus,
    faLeftToLine, faLightbulb, faLightbulbOn, faLineColumns,
    faLocationArrow, faLocationDot,
    faMagnifyingGlass, faMagnifyingGlassArrowRight,
    faMagnifyingGlassChart, faMap,
    faMapLocation as faMapLocationRegular,
    faMemoCircleInfo as faMemoCircleInfoRegular,
    faMicrochipAi as faMicrochipAiRegular,
    faMicrophone,
    faMinus,
    faMobile,
    faMountainCity,
    faPage as faPageRegular,
    faPaperclip, faPaperPlaneTop, faPassport,
    faPencil,
    faPencilSlash, faPenField,
    faPenToSquare,
    faPersonCirclePlus,
    faPhone, faPiggyBank,
    faPipeSection,
    faPlayCircle,
    faPlus,
    faQrcode,
    faQuestion,
    faRectangleHistory as faRectangleHistoryRegular,
    faRepeat,
    faRightToLine,
    faRoad,
    faRotateLeft,
    faRouteInterstate,
    faRouter,
    faSealExclamation as faSealExclamationRegular, faShareNodes, faShieldSlash,
    faSignatureLock,
    faSnowflake,
    faSpinner,
    faSquare as faSquareRegular,
    faSquare1,
    faSquareCheck, faSquareDown,
    faSquareInfo,
    faSquareQuestion,
    faSquareSmall, faSquareUpRight,
    faStop,
    faStopCircle,
    faTableCellsLarge,
    faTableTree as faTableTreeRegular,
    faTally,
    faTerminal, faTimeline,
    faToolbox,
    faTrash, faTruckMedical, faUsbDrive,
    faUser as faUserRegular,
    faUserPlus,
    faUserTie,
    faVial as faVialRegular,
    faVials as faVialsRegular, faWifi,
    faWifiSlash,
    faXmark,
    faXmarkLarge
} from "@fortawesome/pro-regular-svg-icons";
import {
    faBadgeCheck as faBadgeCheckSolid,
    faBars,
    faBriefcaseBlank as faBriefcaseBlankSolid,
    faBuilding as faBuildingSolid,
    faBuildings as faBuildingsSolid,
    faCameraRotate,
    faCaretDown,
    faCheckToSlot as faCheckToSlotSolid,
    faCircleMinus as faCircleMinusSolid,
    faCirclePlus as faCirclePlusSolid,
    faCircleUser as faCircleUserSolid,
    faDiagramNext as faDiagramNextSolid,     faDiamondExclamation as faDiamondExclamationSolid,
    faElephant,
    faFaucet as faFaucetSolid,
    faFileLines as faFileLinesSolid,
    faGaugeHigh as faGaugeHighSolid,
    faHeat as faHeatSolid,
    faHexagonExclamation,
    faHourglass,
    faIdBadge as faIdBadgeSolid, faLock,
    faMapLocation as faMapLocationSolid,
    faMicrochipAi as faMicrochipAiSolid,
    faPage as faPageSolid,
    faPeopleArrowsLeftRight,
    faPersonToPortal, faRabbit, faRabbitRunning,
    faRectangleHistory as faRectangleHistorySolid,
    faSealExclamation as faSealExclamationSolid,
    faSignalBars,
    faSignalBarsFair,
    faSignalBarsSlash,
    faSparkles,
    faSquare as faSquareSolid,
    faStarOfLife,
    faTableTree as faTableTreeSolid,
    faTriangleExclamation, faTurtle,
    faUser as faUserSolid,
    faVial as faVialSolid,
    faVials as faVialsSolid,
    faXmarkToSlot
} from "@fortawesome/pro-solid-svg-icons";

/**
 * FontAwesome icons used in the application.
 */
export class AppIcons {
    public static genericCheck: IconDefinition = faCheck;
    public static genericRefresh: IconDefinition = faArrowsRotate;
    public static genericPlus: IconDefinition = faPlus;
    public static genericDot: IconDefinition = faCircleSmall;
    public static genericSave: IconDefinition = faFloppyDisk;
    public static genericClose: IconDefinition = faXmark;
    public static genericCancel: IconDefinition = faXmark;
    public static genericUndo: IconDefinition = faRotateLeft;
    public static genericLoading: IconDefinition = faSpinner;
    public static genericRetry: IconDefinition = faArrowsRotate;
    public static genericDelete: IconDefinition = faTrash;
    public static genericDuplicate: IconDefinition = faClone;
    public static genericEdit: IconDefinition = faPencil;
    public static genericLocked: IconDefinition = faLock;
    public static genericHourglass: IconDefinition = faHourglass;
    public static genericSearch: IconDefinition = faMagnifyingGlass;
    public static genericArrowRight: IconDefinition = faArrowRight;
    public static genericCaretDown: IconDefinition = faCaretDown;
    public static genericReload: IconDefinition = faArrowsRotate;
    public static genericWarning: IconDefinition = faTriangleExclamation;
    public static genericError: IconDefinition = faDiamondExclamationSolid;
    public static genericAdd: IconDefinition = faPlus;
    public static genericHelp: IconDefinition = faCircleQuestion;
    public static genericListMoreActions: IconDefinition = faEllipsis;
    public static genericDragUpDown: IconDefinition = faArrowsUpDown;
    public static genericInfo: IconDefinition = faSquareInfo;
    public static genericBackup: IconDefinition = faUsbDrive;
    public static genericOpenDialog: IconDefinition = faSquareUpRight;
    public static genericSkip: IconDefinition = faForward;

    public static sendMessage: IconDefinition = faPaperPlaneTop;

    public static openNewBrowserWindow: IconDefinition = faArrowUpRightFromSquare;

    public static dialogClose: IconDefinition = faXmarkLarge;

    public static clientVersion: IconDefinition = faCircleV;
    public static serverVersion: IconDefinition = faCircleV;
    public static debug: IconDefinition = faTerminal;
    public static share: IconDefinition = faShareNodes;
    public static help: IconDefinition = faQuestion;
    public static helpCircle: IconDefinition = faCircleQuestion;
    public static feedback: IconDefinition = faCommentSmile;
    public static support: IconDefinition = faHeadset;
    public static supportSendDump: IconDefinition = faTruckMedical;
    public static quickAdd: IconDefinition = faCirclePlusRegular;
    public static screenshot: IconDefinition = faDesktop;
    public static clearCache: IconDefinition = faCircleTrash;
    public static localStorage: IconDefinition = faHardDrive;
    public static restartApp: IconDefinition = faArrowsRotate;
    public static restartRecommendedWarning: IconDefinition = faDiamondExclamationRegular;
    public static appViewSettings: IconDefinition = faEllipsisVertical;
    public static focusMode: IconDefinition = faArrowsToDot;
    public static followMode: IconDefinition = faArrowsTurnRight;
    public static networkTest: IconDefinition = faWifi;

    public static login: IconDefinition = faArrowRightToBracket;
    public static logout: IconDefinition = faPersonToPortal;
    public static register: IconDefinition = faFileUser;
    public static changePassword: IconDefinition = faKey;
    public static invitePerson: IconDefinition = faUserPlus;
    public static switchAccount: IconDefinition = faPeopleArrowsLeftRight;
    public static profileSettings: IconDefinition = faAddressCard;
    public static appSettings: IconDefinition = faGear;
    public static about: IconDefinition = faCircleInfo;
    public static changelog: IconDefinition = faTimeline;
    public static rearrange: IconDefinition = faArrowsFromLine;
    public static installApp: IconDefinition = faSquareDown;

    public static connectedGoodConnection: IconDefinition = faSignalBars;
    public static connectedBadConnection: IconDefinition = faSignalBarsFair;
    public static offline: IconDefinition = faSignalBarsSlash;
    public static latencyVeryLow: IconDefinition = faRabbitRunning;
    public static latencyLow: IconDefinition = faRabbit;
    public static latencyHigh: IconDefinition = faElephant;
    public static latencyVeryHigh: IconDefinition = faTurtle;
    public static downlink: IconDefinition = faArrowDownToDottedLine;
    public static networkType: IconDefinition = faRouter;
    public static roundTripTime: IconDefinition = faClockRotateLeft;
    public static dataSaver: IconDefinition = faPiggyBank;
    public static synchronized: IconDefinition = faCloudCheck;
    public static synchronizing: IconDefinition = faCloudArrowUp;
    public static synchronizationError: IconDefinition = faCloudXmark;
    public static noInternet: IconDefinition = faWifiSlash;
    public static noPermission: IconDefinition = faShieldSlash;
    public static deviceIdentifier: IconDefinition = faPassport;

    public static documentStatusUnknown: IconDefinition = faSquareQuestion;
    public static documentStatusUnassigned: IconDefinition = faChairOffice;
    public static documentStatusOrderEntry: IconDefinition = faPenField;
    public static documentStatusScheduled: IconDefinition = faCalendarDays;
    public static documentStatusInProgress: IconDefinition = faFileInvoice;
    public static documentStatusInReview: IconDefinition = faMagnifyingGlassChart;
    public static documentStatusReviewAccepted: IconDefinition = faBadgeCheckRegular;
    public static documentStatusReviewRejected: IconDefinition = faSealExclamationRegular;
    public static documentStatusDone: IconDefinition = faBoxCheck;

    public static documentStatusInfoAvailable: IconDefinition = faSquareInfo;
    public static documentMissingInformation: IconDefinition = faFileCircleQuestion;

    public static documentAdditionalInfo: IconDefinition = faFileLinesRegular;

    public static accountSwitchManageIcon: IconDefinition = faBars;

    public static attachmentStateUnknown: IconDefinition = faCloudQuestion;
    public static attachmentStateCreated: IconDefinition = faCloudMinus;
    public static attachmentStatePersistedLocally: IconDefinition = faCloudPlus;
    public static attachmentStateSendingToServer: IconDefinition = faCloudArrowUp;
    public static attachmentStateUpdatingMeta: IconDefinition = faCloudArrowUp;
    public static attachmentStateNoInternet: IconDefinition = faCloud;
    public static attachmentStateMetaUpdated: IconDefinition = faCloudCheck;
    public static attachmentProcessingOnServerSide: IconDefinition = faCloudWord;
    public static attachmentStateRejectedByServer: IconDefinition = faCloudXmark;

    public static attachmentAddToQuestion: IconDefinition = faCirclePlusSolid;
    public static attachmentRemoveFromQuestion: IconDefinition = faCircleMinusSolid;

    public static answerUpdateUnknown: IconDefinition = faCloud;
    public static answerUpdateCreated: IconDefinition = faCloudMinus;
    public static answerUpdatePersistedLocally: IconDefinition = faCloudPlus;
    public static answerUpdateSendingToServer: IconDefinition = this.synchronizing;
    public static answerUpdateAcceptedByServer: IconDefinition = this.synchronized;
    public static answerUpdateRejectedByServer: IconDefinition = this.synchronizationError;

    public static answerScoringGood: IconDefinition = faCheck;
    public static answerScoringWarning: IconDefinition = faExclamation;
    public static answerScoringCritical: IconDefinition = faHexagonExclamation;
    public static answerScoringNeutral: IconDefinition = faCircleSmall;

    public static addComment: IconDefinition = faPencil;
    public static editComment: IconDefinition = this.addComment;
    public static removeComment: IconDefinition = faPencilSlash;

    public static addRepeatableSection: IconDefinition = faLayerPlus;
    public static removeRepeatableSection: IconDefinition = faTrash;
    public static reorderRepeatableSection: IconDefinition = faArrowDownArrowUp;
    public static finishReorderRepeatableSection: IconDefinition = faCheck;

    public static reloadDocument: IconDefinition = faArrowsRotate;
    public static sendToQa: IconDefinition = faBadge;
    public static finishDocument: IconDefinition = faCheckToSlotSolid;
    public static finishDocumentQaAccepted: IconDefinition = faCheckToSlotSolid;
    public static finishDocumentQaRejected: IconDefinition = faXmarkToSlot;
    public static startDocumentFinishing: IconDefinition = faPencil;
    public static previewDocument: IconDefinition = faEye;
    public static previewDocumentPdf: IconDefinition = faFilePdf;
    public static downloadDocumentPdf: IconDefinition = faFileArrowDown;
    public static unpreviewDocument: IconDefinition = faEyeSlash;
    public static importSignature: IconDefinition = faSignatureLock;
    public static createDocumentRevision: IconDefinition = faCodeCommit;
    public static deleteDocument: IconDefinition = faTrash;
    public static qualityMin: IconDefinition = faDialMin;
    public static qualityLow: IconDefinition = faDialLow;
    public static qualityMediumLow: IconDefinition = faDialMedLow;
    public static qualityMedium: IconDefinition = faDialMed;
    public static qualityMediumHigh: IconDefinition = faDial;
    public static qualityHigh: IconDefinition = faDialHigh;
    public static qualityMax: IconDefinition = faDialMax;

    public static createAuditDocument: IconDefinition = faFileCirclePlus;
    public static createAuditBasicInformation: IconDefinition = faMemoCircleInfoRegular;
    public static targetDate: IconDefinition = faCalendarDay;
    public static targetTime: IconDefinition = faClock;
    public static targetEndDate: IconDefinition = faCalendarDay;
    public static targetEndTime: IconDefinition = faClock;
    public static today: IconDefinition = faCalendarDay;

    public static attachment: IconDefinition = faPaperclip;
    public static auditNote: IconDefinition = faMicrophone;
    public static photoGallery: IconDefinition = faImages;
    public static camera: IconDefinition = faCamera;
    public static annotateImage: IconDefinition = faPenToSquare;
    public static downloadImage: IconDefinition = faDownload;
    public static imageSettings: IconDefinition = faEllipsis;
    public static imageLoading: IconDefinition = faSpinner;
    public static imageLoadError: IconDefinition = faImageSlash;
    public static imagePlaceholder: IconDefinition = faImages;
    public static qrCode: IconDefinition = faQrcode;
    public static checkbox: IconDefinition = faSquareCheck;
    public static xMarkCircle: IconDefinition = faCircleXmark;
    public static informationCircle: IconDefinition = faCircleInfo;

    public static galleryFilterLinked: IconDefinition = faCircle1;
    public static galleryFilterNotLinked: IconDefinition = faCircleDashed;
    public static galleryFilterDescription: IconDefinition = faPencil;
    public static galleryFilterNoDescription: IconDefinition = faPencilSlash;
    public static galleryFilterPdf: IconDefinition = faFilePdf;
    public static galleryFilterNotPdf: IconDefinition = faFileSlash;
    public static clearFilters: IconDefinition = faFilterSlash;

    public static cameraTorchOn: IconDefinition = faLightbulbOn;
    public static cameraTorchOff: IconDefinition = faLightbulb;
    public static cameraRotate: IconDefinition = faCameraRotate;
    public static cameraMultiShot: IconDefinition = faRectangleHistoryDuotone;

    public static audioRecordingMic: IconDefinition = faMicrophone;
    public static audioRecordingStop: IconDefinition = faStopCircle;
    public static audioRecordingPlay: IconDefinition = faPlayCircle;

    public static reviewAccept: IconDefinition = faBadgeCheckSolid;
    public static reviewReject: IconDefinition = faSealExclamationSolid;
    public static reviewAccepted: IconDefinition = faBadgeCheckSolid;
    public static reviewRejected: IconDefinition = faSealExclamationSolid;
    public static removeReview: IconDefinition = faMinus;

    public static leftHandedMode: IconDefinition = faLeftToLine;
    public static rightHandedMode: IconDefinition = faRightToLine;

    public static menuBadgeDone: IconDefinition = faCircleCheck;
    public static menuGoToOverview: IconDefinition = faTableColumns;
    public static menuGoBack: IconDefinition = faArrowLeft;
    public static menuOverview: IconDefinition = faTableColumns;
    public static menuProjectsList: IconDefinition = faTableTreeDuotone;
    public static menuPersonsList: IconDefinition = faUserDuotone;
    public static menuCompaniesList: IconDefinition = faBriefcaseBlankDuotone;
    public static menuBuildingComplexesList: IconDefinition = faBuildingsDuotone;
    public static menuBuildingsList: IconDefinition = faBuildingDuotone;
    public static menuDiagramEditor: IconDefinition = faDiagramProject;

    public static goBack: IconDefinition = faArrowLeft;
    public static goForward: IconDefinition = faAngleRight;

    public static number0: IconDefinition = fa0;
    public static number1: IconDefinition = fa1;
    public static number2: IconDefinition = fa2;
    public static number3: IconDefinition = fa3;
    public static number4: IconDefinition = fa4;
    public static number7: IconDefinition = fa7;
    public static number9: IconDefinition = fa9;

    public static logsWarning: IconDefinition = faTriangleExclamation;
    public static logsDebug: IconDefinition = faBug;
    public static logsError: IconDefinition = faHexagonExclamation;
    public static logsInfo: IconDefinition = faCircleInfo;
    public static logsNavigation: IconDefinition = faLocationArrow;
    public static logsIntentStart: IconDefinition = faArrowRightFromBracket;
    public static logsIntentEnd: IconDefinition = faArrowRightToBracket;

    public static dice1: IconDefinition = faDiceOne;
    public static dice2: IconDefinition = faDiceTwo;
    public static dice3: IconDefinition = faDiceThree;
    public static dice4: IconDefinition = faDiceFour;

    public static gameRestart: IconDefinition = faRepeat;
    public static gameStop: IconDefinition = faStop;

    public static entityUnknownDuotone: IconDefinition = faSquareDuotone;
    public static entityAllDuotone: IconDefinition = faRectangleHistoryDuotone;
    public static entityCoreDataDuotone: IconDefinition = faPageDuotone;
    public static entityProjectDuotone: IconDefinition = faTableTreeDuotone;
    public static entityProcessDuotone: IconDefinition = faDiagramNextDuotone;
    public static entityDocumentDuotone: IconDefinition = faFileLinesDuotone;
    public static entityPersonDuotone: IconDefinition = faUserDuotone;
    public static entityPersonEmployeeDuotone: IconDefinition = faCircleUserDuotone;
    public static entityCompanyDuotone: IconDefinition = faBriefcaseBlankDuotone;
    public static entityBuildingComplexDuotone: IconDefinition = faBuildingsDuotone;
    public static entityBuildingDuotone: IconDefinition = faBuildingDuotone;
    public static entityBuildingFeaturesDuotone: IconDefinition = faFaucetDuotone;
    public static entityAddressDuotone: IconDefinition = faMapLocationDuotone;
    public static entitySampleDuotone: IconDefinition = faVialDuotone;
    public static entitySamplingPlanDuotone: IconDefinition = faVialsDuotone;
    public static entityDashboardDuotone: IconDefinition = faGaugeHighDuotone;
    public static entityAiAnalysisDuotone: IconDefinition = faMicrochipAiDuotone;

    public static entityUnknownRegular: IconDefinition = faSquareRegular;
    public static entityAllRegular: IconDefinition = faRectangleHistoryRegular;
    public static entityCoreDataRegular: IconDefinition = faPageRegular;
    public static entityProjectRegular: IconDefinition = faTableTreeRegular;
    public static entityProcessRegular: IconDefinition = faDiagramNextRegular;
    public static entityDocumentRegular: IconDefinition = faFileLinesRegular;
    public static entityPersonRegular: IconDefinition = faUserRegular;
    public static entityPersonEmployeeRegular: IconDefinition = faCircleUserRegular;
    public static entityCompanyRegular: IconDefinition = faBriefcaseBlankRegular;
    public static entityBuildingComplexRegular: IconDefinition = faBuildingsRegular;
    public static entityBuildingRegular: IconDefinition = faBuildingRegular;
    public static entityBuildingFeaturesRegular: IconDefinition = faFaucetRegular;
    public static entityAddressRegular: IconDefinition = faMapLocationRegular;
    public static entitySampleRegular: IconDefinition = faVialRegular;
    public static entitySamplingPlanRegular: IconDefinition = faVialsRegular;
    public static entityDashboardRegular: IconDefinition = faGaugeHighRegular;
    public static entityAiAnalysisRegular: IconDefinition = faMicrochipAiRegular;

    public static entityUnknownSolid: IconDefinition = faSquareSolid;
    public static entityAllSolid: IconDefinition = faRectangleHistorySolid;
    public static entityCoreDataSolid: IconDefinition = faPageSolid;
    public static entityProjectSolid: IconDefinition = faTableTreeSolid;
    public static entityProcessSolid: IconDefinition = faDiagramNextSolid;
    public static entityDocumentSolid: IconDefinition = faFileLinesSolid;
    public static entityPersonSolid: IconDefinition = faUserSolid;
    public static entityPersonEmployeeSolid: IconDefinition = faCircleUserSolid;
    public static entityCompanySolid: IconDefinition = faBriefcaseBlankSolid;
    public static entityBuildingComplexSolid: IconDefinition = faBuildingsSolid;
    public static entityBuildingSolid: IconDefinition = faBuildingSolid;
    public static entityBuildingFeaturesSolid: IconDefinition = faFaucetSolid;
    public static entityAddressSolid: IconDefinition = faMapLocationSolid;
    public static entitySampleSolid: IconDefinition = faVialSolid;
    public static entitySamplingPlanSolid: IconDefinition = faVialsSolid;
    public static entityDashboardSolid: IconDefinition = faGaugeHighSolid;
    public static entityAiAnalysisSolid: IconDefinition = faMicrochipAiSolid;

    public static entitySelect: IconDefinition = faMagnifyingGlass;
    public static entitySelectAnother: IconDefinition = faMagnifyingGlassArrowRight;
    public static entityUpdateCheck: IconDefinition = faSpinner;
    public static entityUpdateAvailable: IconDefinition = faSparkles;

    // Document menu icons
    public static documentReview: IconDefinition = faCheckToSlotDuotone;
    public static documentRevisions: IconDefinition = faRectangleHistoryDuotone;
    public static documentMasterData: IconDefinition = faMemoPad;
    public static documentCommonData: IconDefinition = faMemoCircleInfoDuotone;
    public static documentImageOverview: IconDefinition = faFolderImage;
    public static documentRiskAssessment: IconDefinition = faShieldExclamation;
    public static documentMandatoryTesting: IconDefinition = faBallotCheck;
    public static documentDrinkingWaterInstallation: IconDefinition = faDroplet;
    public static documentPipeSystem: IconDefinition = faDiagramSankey;
    public static documentHeating: IconDefinition = faFireFlame;
    public static documentDrinkingWaterHeating: IconDefinition = faMugHot;
    public static documentWarmWaterSystem: IconDefinition = faHeat;
    public static documentCirculationSystem: IconDefinition = faArrowsRepeat;
    public static documentPhotos: IconDefinition = faFileImage;
    public static documentDocumentation: IconDefinition = faBookDuotone;

    public static openDocument: IconDefinition = faFolderOpen;

    public static externalReference: IconDefinition = faSquareSmall;

    public static projectTitle: IconDefinition = faTableTreeRegular;
    public static documentType: IconDefinition = faFileLinesRegular;
    public static processType: IconDefinition = faDiagramNextRegular;
    public static personFirstname: IconDefinition = faIdBadgeRegular;
    public static personLastname: IconDefinition = faIdBadgeSolid;
    public static personTitle: IconDefinition = faUserTie;
    public static personGender: IconDefinition = faGenderless;
    public static personRole: IconDefinition = faToolbox;
    public static personEmail: IconDefinition = faEnvelope;
    public static personPhone: IconDefinition = faPhone;
    public static personPhoneMobile: IconDefinition = faMobile;
    public static addressRaw: IconDefinition = faMap;
    public static addressStreet: IconDefinition = faRoad;
    public static addressStreetNumber: IconDefinition = faSquare1;
    public static addressZipcode: IconDefinition = faMountainCity;
    public static addressCity: IconDefinition = faCity;
    public static addressContinent: IconDefinition = faGlobe;
    public static addressCountry: IconDefinition = faFlag;
    public static addressProvince: IconDefinition = faRouteInterstate;
    public static addressAdditionalInfo: IconDefinition = faCommentLines;
    public static companyName: IconDefinition = faBuildingRegular;
    public static companyEmail: IconDefinition = faEnvelope;
    public static email: IconDefinition = faEnvelope;
    public static buildingComplexName: IconDefinition = faBuildingsRegular;
    public static buildingName: IconDefinition = faBuildingRegular;
    public static buildingUsage: IconDefinition = faHouseBuilding;
    public static floorNumber: IconDefinition = faCircleSort;
    public static roomName: IconDefinition = faDoorClosed;
    public static roomType: IconDefinition = faBath;
    public static roomLocation: IconDefinition = faTableCellsLarge;
    public static roomFeature: IconDefinition = faFaucetRegular;
    public static roomFeatureName: IconDefinition = faInputText;
    public static roomFeatureCount: IconDefinition = faTally;
    public static numberOfFlats: IconDefinition = faApartment;
    public static numberOfOffices: IconDefinition = faBriefcaseBlank;
    public static numberOfUnusedFlatsAndOffices: IconDefinition = faBuildingCircleXmark;
    public static visibleVerticalRisers: IconDefinition = faPipeSection;
    public static installationPlansExist: IconDefinition = faAngle;
    public static constructionYear: IconDefinition = faCalendarDay;
    public static typeOfDrinkingWaterHeating: IconDefinition = faHeatSolid;
    public static locationHouseConnection: IconDefinition = faLocationDot;
    public static ewKey: IconDefinition = faKey;
    public static ventilationVDI6022: IconDefinition = faFanTable;
    public static coolingVDI2047: IconDefinition = faSnowflake;
    public static additionalInfo: IconDefinition = faCommentLines;

    public static buildingAddFloor: IconDefinition = faCircleSort;
    public static buildingRemoveFloor: IconDefinition = faTrash;
    public static buildingAddRoom: IconDefinition = faDoorClosed;
    public static buildingRemoveRoom: IconDefinition = faTrash;
    public static buildingAddFeature: IconDefinition = faFaucetRegular;
    public static buildingRemoveFeature: IconDefinition = faTrash;

    public static linkCompany: IconDefinition = faBuildingRegular;
    public static linkPerson: IconDefinition = faPersonCirclePlus;
    public static linkBuildingComplex: IconDefinition = faBuildingsRegular;
    public static linkBuilding: IconDefinition = faBuildingRegular;

    public static errorCallForHelp: IconDefinition = faPhone;

    public static copyToClipboard: IconDefinition = faClipboard;

    public static moveSample: IconDefinition = faArrowRight;
    public static samplePlanExtendedView: IconDefinition = faLineColumns;
    public static samplePlanAdditionalInfo: IconDefinition = faFileLinesRegular;

    public static mandatory: IconDefinition = faStarOfLife;
}
// @formatter:on
