import { ChangeDetectorRef, Component, Input } from "@angular/core";

import { ContextMenuItem } from "../../../base/components/context-menu/context-menu-item";
import { ArrayHelper } from "../../../base/helpers/array-helper";
import { AsyncHelper } from "../../../base/helpers/async-helper";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { Pages } from "../../pages/pages";
import { AppIcons } from "../../services/icons/app-icons";
import { MenuItem } from "../../services/menu/menu-item";
import { MenuOptions } from "../../services/menu/menu-options";
import { NavigationService } from "../../services/navigation/navigation.service";

/**
 * The component to show a menu or a single {@link MenuItem}.
 */
@Component({
    selector: "business-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"]
})
export class MenuComponent {
    constructor(
        private readonly navigationService: NavigationService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly dialogService: DialogService
    ) {
    }

    // This is used to allow the UI to update the loading state and therefore react on clicks instantly.
    private readonly loadDelayMilliseconds: number = 5;

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly pages: typeof Pages = Pages;

    @Input()
    public menuItem?: MenuItem;

    @Input()
    public darkText: boolean = false;

    @Input()
    public level: number = 0;

    @Input()
    public menuOptions?: MenuOptions;

    public async itemClicked(menuItem: MenuItem, newTab: boolean = false, event?: Event): Promise<void> {
        event?.stopPropagation();

        if (!menuItem.targetPage) {
            return;
        }

        menuItem.loading = !newTab;
        // A bit hacky to allow the loading state to be updated.
        this.changeDetectorRef.detectChanges();
        await AsyncHelper.sleep(this.loadDelayMilliseconds);
        this.changeDetectorRef.detectChanges();
        await AsyncHelper.sleep(this.loadDelayMilliseconds);

        if (menuItem.targetPage && menuItem.targetPage != Pages.unknown) {
            const cleaned: Array<string> = ArrayHelper.removeEmpty(menuItem.targetRoute ?? []);
            if (newTab) {
                const url: string = this.navigationService.createRouteWithParameters(menuItem.targetPage, cleaned, menuItem.targetParameters);
                window.open(url, "_blank");
            } else {
                this.navigationService.navigate(menuItem.targetPage, ...cleaned).then();
            }
        }
    }

    public async contextMenu(event: MouseEvent): Promise<void> {
        event.preventDefault();

        if (!this.menuItem?.targetPage) {
            return;
        }

        const contextMenuItems: Array<ContextMenuItem> = [
            new ContextMenuItem($localize`:@@menu.openInNewWindow:Open in a new window`, {
                tag: "newWindow",
                icon: AppIcons.openNewBrowserWindow
            })
        ];

        const result: ContextMenuItem|undefined = await this.dialogService.showContextMenu(contextMenuItems, event, true);
        switch (result?.tag) {
            case "newWindow":
                await this.itemClicked(this.menuItem, true, event);
                break;
        }
    }
}
