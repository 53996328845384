import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { ModalModes } from "../../../base/services/dialog/modal-modes";
import { IntentOptions } from "../../../base/services/intents/intent-options";
import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../entities/documents/entity-link";
import { EditCoreDataIntent } from "./edit-core-data-intent";

/**
 * Options for the edit core data entity intent.
 */
export class EditCoreDataIntentOptions<TEntity extends GenericCoreDataEntity> implements IntentOptions<EditCoreDataIntent> {
    constructor(entity: TEntity, init?: Partial<EditCoreDataIntentOptions<TEntity>>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.entity = entity;
    }

    /**
     * The entity to edit.
     * If you want to create a new one, create a new entity beforehand and pass it to the intent.
     */
    public entity: TEntity;

    /**
     * Specify a custom title for the edit dialog.
     */
    public customTitle?: string;

    /**
     * The size the dialog should be shown.
     */
    public modalMode?: ModalModes;

    /**
     * Prevent the intent form saving the edited entity.
     */
    public resultOnlyDoNotSave: boolean = false;

    /**
     * Set the archive flag after saving the entity.
     */
    public archiveOnSave: boolean = false;

    /**
     * Open the dialog in readonly view-mode.
     */
    public readonly: boolean = false;

    /**
     * Some entities need additional entities to be saved. E.g, the building needs the buildings complex to be saved.
     * The other way around, the buildings complex can have buildings to show them in the edit dialog.
     * With the entity links you can either just pass the identifier or the whole entity.
     */
    public additionalEntityLinks?: Array<EntityLink<GenericCoreDataEntity>>;

    /**
     * The same as additionalEntityLinks but with pure entities.
     */
    public additionalEntities: Array<GenericCoreDataEntity> = [];

    /**
     * Pass this information to the edit forms to specify that only specific entities should be edited.
     */
    public propertiesFilterInclude: Array<keyof TEntity> = [];

    /**
     * Pass this information to the edit forms to specify that these properties should be excluded from editing.
     */
    public propertiesFilterExclude: Array<keyof TEntity> = [];

    /**
     * Fields that are mandatory to fill.
     */
    public mandatoryProperties: Array<keyof TEntity> = [];

    /**
     * After intent end, this property holds a copy of the edited entity, or it is undefined if the dialog/intent has been cancelled.
     */
    public resultEntity?: TEntity;

    /**
     * Autocomplete values.
     */
    public autocomplete?: Map<keyof TEntity, Array<string>>;
}
