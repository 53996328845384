<ion-list *ngIf="menuItem" [class.darkText]="darkText" [class.level0]="level == 0" [class.level1]="level == 1" [class.level2]="level == 2" [class.level3]="level == 3" class="maineMenuList">
    <hr *ngIf="menuItem.isSeparator" />

    <ion-item (click)="itemClicked(menuItem)" (contextmenu)="contextMenu($event)"
              *ngIf="!menuItem.isTopItem && menuItem.title && !menuItem.isSeparator"
              [class.clickable]="(menuItem.targetPage && menuItem.targetPage != pages.unknown)"
              [class.isLoading]="menuItem.loading"
              [class.isOpen]="menuItem.open"
              lines="none">
        <fa-icon *ngIf="menuItem.icon" [icon]="menuItem.icon" class="icon" size="lg"></fa-icon>

        <ion-label *ngIf="!menuItem.isHeadline">{{menuItem.title}}</ion-label>

        <ion-list-header *ngIf="menuItem.isHeadline" title="{{menuItem.title}}">{{menuItem.title}}</ion-list-header>

        <ion-text *ngIf="menuItem.badgeIcon || menuItem.neutralBadgeCount || menuItem.successBadgeCount || menuItem.warningBadgeCount || menuItem.criticalBadgeCount" class="badges" slot="end">
            <ion-badge *ngIf="menuItem.neutralBadgeCount" color="light">{{menuItem.neutralBadgeCount}}</ion-badge>
            <ion-badge *ngIf="menuItem.successBadgeCount" color="success">{{menuItem.successBadgeCount}}</ion-badge>
            <ion-badge *ngIf="menuItem.warningBadgeCount" color="warning">{{menuItem.warningBadgeCount}}</ion-badge>
            <ion-badge *ngIf="menuItem.criticalBadgeCount" color="danger">{{menuItem.criticalBadgeCount}}</ion-badge>
        </ion-text>
        <fa-icon *ngIf="menuItem.badgeIcon" [icon]="menuItem.badgeIcon" class="badgeIcon" slot="end"></fa-icon>
        <button (click)="itemClicked(menuItem, true, $event)" *ngIf="menuItem.allowNewTab" class="openTabButton" slot="end">
            <fa-icon [icon]="appIcons.openNewBrowserWindow" size="lg"></fa-icon>
        </button>
    </ion-item>

    <ion-note *ngIf="menuItem.subTitle">{{menuItem.subTitle}}</ion-note>

    <ion-item *ngIf="menuItem.hasSearchBar" lines="none">
        <ion-input [placeholder]="menuItem.placeholder" class="searchBar" inputmode="search" type="search"></ion-input>
    </ion-item>

    <business-menu *ngFor="let submenuItem of menuItem.children" [darkText]="darkText" [level]="level + (menuItem.isTopItem ? 0 : 1)" [menuItem]="submenuItem"></business-menu>
</ion-list>
