import {
    BuildingWaterWalkthroughInspectionDocument
} from "../../../domain/entities/building-water-walkthrough-inspection-document";
import { AuditDocument } from "../../entities/documents/audit-document";
import { AuditDocumentStorageDto } from "../../entities/documents/audit-document-storage-dto";
import { DocumentAnswerValue } from "../../entities/documents/document-answer-value";
import { DocumentAnswerValueStorageDto } from "../../entities/documents/document-answer-value-storage-dto";
import { DocumentSection } from "../../entities/documents/document-section";
import { DocumentSectionStorageDto } from "../../entities/documents/document-section-storage-dto";
import { DocumentTemplate } from "../../entities/documents/document-template";
import { DocumentTypes } from "../../entities/documents/document-types";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";
import { TemplateVersionTechnicalIdentifier } from "../../identifiers/template-version-technical-identifier";

/**
 * Factory for documents.
 */
export class DocumentFactory {
    public static createFromStorageDto(dto: AuditDocumentStorageDto): AuditDocument|undefined {
        switch (dto.type) {
            case DocumentTypes.buildingWaterWalkthroughInspection:
                const document: BuildingWaterWalkthroughInspectionDocument = new BuildingWaterWalkthroughInspectionDocument({ businessIdentifier: "" as DocumentBusinessIdentifier }, new DocumentTemplate(dto.templateVersionTechnicalId as TemplateVersionTechnicalIdentifier, DocumentTypes.buildingWaterWalkthroughInspection));
                document.fromStorageDto(dto);
                return document;
        }
        return undefined;
    }

    public static createSection(templateIdentifier: SectionTemplateIdentifier, init?: Partial<DocumentSection>): DocumentSection {
        return new DocumentSection(templateIdentifier, init);
    }

    public static createSectionFromStorageDto(dto: DocumentSectionStorageDto): DocumentSection {
        const section: DocumentSection = this.createSection(dto.templateId as SectionTemplateIdentifier);
        section.fromStorageDto(dto);
        return section;
    }

    public static createAnswerValue(init?: Partial<DocumentAnswerValue>): DocumentAnswerValue {
        return new DocumentAnswerValue(init);
    }

    public static createAnswerValueFromStorageDto(dto: DocumentAnswerValueStorageDto): DocumentAnswerValue {
        const answerValue: DocumentAnswerValue = this.createAnswerValue();
        answerValue.fromStorageDto(dto);
        return answerValue;
    }
}
