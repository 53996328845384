<ion-header class="modalHeader ion-no-border">
    <ion-toolbar>
        <ion-title>{{options.title}}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-label color="medium">
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [class.ion-padding]="options.padding" class="modalContent">
    <base-dynamic-component (componentCreated)="componentCreated($event)" [component]="options.child.component" [options]="options.child.options"></base-dynamic-component>
</ion-content>

<ion-footer class="modalFooter ion-no-border">
    <ion-toolbar>
        <ion-buttons *ngIf="helpArticle" slot="start">
            <ion-button (click)="openHelp()">
                <fa-icon [icon]="appIcons.genericHelp"></fa-icon>&nbsp;<ng-container i18n="@@dialog.genericHelpButton">Help</ng-container>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)="close()" *ngIf="hasClose">
                <fa-icon [icon]="appIcons.genericClose"></fa-icon>&nbsp;<ng-container i18n="@@dialog.genericCloseButton">Close</ng-container>
            </ion-button>
            <ion-button (click)="cancel()" *ngIf="hasCancel">
                <fa-icon [icon]="appIcons.genericCancel"></fa-icon>&nbsp;<ng-container i18n="@@dialog.genericCancelButton">Cancel</ng-container>
            </ion-button>
            <ion-button (click)="save()" *ngIf="hasSave" color="success" fill="solid">
                <fa-icon [icon]="appIcons.genericSave"></fa-icon>&nbsp;<ng-container>{{saveButtonTitle}}</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
