import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

import { environment } from "../../../../../environments/environment";
import { EventHelper } from "../../../../base/helpers/event-helper";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { IntentsService } from "../../../../base/services/intents/intents.service";
import { SafeResult } from "../../../common/safe-result";
import { Building } from "../../../entities/core-data/buildings/building";
import { BuildingComplex } from "../../../entities/core-data/buildings/building-complex";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../../entities/documents/entity-link";
import { EntityTypes } from "../../../entities/entity-types";
import { AppException } from "../../../entities/exceptions/app-exception";
import { Identifier } from "../../../identifiers/identifier";
import { DeleteEntityIntent } from "../../../intents/master-data/delete-entity-intent";
import { DeleteEntityIntentOptions } from "../../../intents/master-data/delete-entity-intent-options";
import { EditCoreDataIntent } from "../../../intents/master-data/edit-core-data-intent";
import { EditCoreDataIntentOptions } from "../../../intents/master-data/edit-core-data-intent-options";
import { I18nTextService } from "../../../services/i18n/i18n-text.service";
import { AppIcons } from "../../../services/icons/app-icons";
import { BuildingsService } from "../../../services/master-data/buildings/buildings.service";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { EntitySelectionListComponent } from "../../core-data/entity-selection-list/entity-selection-list.component";

/**
 * Form to create and edit building complexes.
 */
@Component({
    selector: "business-building-complex-form",
    templateUrl: "./building-complex-form.component.html",
    styleUrls: ["./building-complex-form.component.scss"]
})
export class BuildingComplexFormComponent implements OnInit {
    constructor(
        private readonly i18nTextService: I18nTextService,
        private readonly intentsService: IntentsService,
        private readonly formBuilder: FormBuilder,
        private readonly buildingsService: BuildingsService
    ) {
    }

    public readonly formHelper: typeof FormHelper = FormHelper;

    public readonly appIcons: typeof AppIcons = AppIcons;

    private buildingComplexField!: BuildingComplex;

    public readonly formSettings: {
        maxLengthName: number;
        maxLengthRawAddress: number;
        maxLengthReference: number;
    } = environment.formSettings.buildingComplex;

    @Input()
    public buildings?: Array<EntityLink<Building>>;

    @Output()
    public buildingsChange: EventEmitter<Array<EntityLink<Building>>|undefined> = new EventEmitter<Array<EntityLink<Building>>|undefined>();

    @Input()
    public readonly: boolean = false;

    @Input()
    public readonlyPermissions: boolean = false;

    @Input()
    public showBuildings: boolean = true;

    @Input()
    public isValid: boolean = false;

    @Output()
    public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild("buildingList")
    public buildingListComponent?: EntitySelectionListComponent;

    public externalReferenceEditable: boolean = false;

    public externalReferenceControl: FormControl<string|null> = new FormControl<string|null>("");

    public nameControl: FormControl<string|null> = new FormControl<string|null>("");

    public addressRawControl: FormControl<string|null> = new FormControl<string|null>("");

    private formUpdateSubscription?: Subscription;

    private initialized: boolean = false;

    public formData: FormGroup = this.formBuilder.group([this.externalReferenceControl, this.nameControl, this.addressRawControl]);

    public get formReadonly(): boolean {
        return this.readonly || this.readonlyPermissions;
    }

    public get buildingComplex(): BuildingComplex {
        return this.buildingComplexField;
    }

    @Input()
    public set buildingComplex(value: BuildingComplex) {
        const buildingComplexChanged: boolean = this.buildingComplexField != value || !Identifier.isEqual(this.buildingComplexField?.identifier, value?.identifier);
        this.buildingComplexField = value;
        if (buildingComplexChanged && this.initialized) {
            this.initialize();
        }
    }

    public ngOnInit(): void {
        this.initialize();
        this.formUpdateSubscription = EventHelper.subscribe(this.formData.statusChanges, this.formChanged, this);
    }

    public ngOnDestroy(): void {
        this.formUpdateSubscription = EventHelper.unsubscribe(this.formUpdateSubscription);
    }

    private initialize(): void {
        this.initialized = false;
        this.buildingComplex = this.buildingComplex ?? CoreDataFactory.createBuildingComplex();
        this.externalReferenceEditable = !this.buildingComplex.externalReference;

        this.updateLinks();

        this.assignValues();
        this.initialized = true;

        if (!this.buildings) {
            this.postLoadBuildings().then();
        }
    }

    private async postLoadBuildings(): Promise<void> {
        if (!this.buildingComplexField.identifier.technicalIdentifier) {
            return;
        }

        try {
            const buildingsResult: SafeResult<Array<Building>, AppException> = await this.buildingsService.getBuildings(this.buildingComplexField.identifier.technicalIdentifier);
            if (buildingsResult.isSuccess()) {
                this.buildings = buildingsResult.result.map((building: Building) => CoreDataFactory.createEntityLinkFromEntity(building));
                this.buildingsChange.emit(this.buildings);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private updateLinks(): void {
        if (this.initialized) {
            this.buildingListComponent?.updateTable(this.buildings);
        }
    }

    public assignValues(): void {
        this.assignIfChanged(this.externalReferenceControl, this.buildingComplex.externalReference);
        this.assignIfChanged(this.nameControl, this.buildingComplex.name);
        this.assignIfChanged(this.addressRawControl, this.buildingComplex.addressRaw);
    }

    public updateValidState(): void {
        this.formData.markAllAsTouched();

        this.isValid = this.formData.status == "VALID";
        this.isValidChange.emit(this.isValid);
    }

    public buildingLinksChanged(_buildingLinks: Array<EntityLink<GenericCoreDataEntity>>): void {
        this.updateValidState();
    }

    private assignIfChanged(control: AbstractControl, value: any): void {
        if (control.value != value) {
            control.setValue(value);
        }
    }

    private formChanged(): void {
        if (!this.initialized) {
            return;
        }

        this.buildingComplex.externalReference = FormHelper.formValueToData<string>(this.externalReferenceControl.value) || undefined;
        this.buildingComplex.name = FormHelper.formValueToData<string>(this.nameControl.value) || undefined;
        this.buildingComplex.addressRaw = FormHelper.formValueToData<string>(this.addressRawControl.value) || undefined;

        this.updateValidState();
    }

    public async addBuilding(): Promise<void> {
        if (!this.buildings) {
            return;
        }

        const building: Building = CoreDataFactory.createBuilding();
        const intentOptions: EditCoreDataIntentOptions<Building> = new EditCoreDataIntentOptions<Building>(building, {
            resultOnlyDoNotSave: true,
            additionalEntityLinks: [
                CoreDataFactory.createEntityLink(this.buildingComplex.identifier, EntityTypes.buildingComplex)
            ]
        });
        await this.intentsService.executeIntentAndWait(EditCoreDataIntent, intentOptions);

        if (intentOptions.resultEntity) {
            this.buildings.unshift(CoreDataFactory.createEntityLinkFromEntity(intentOptions.resultEntity as Building));
            this.buildingListComponent?.updateTable(this.buildings);
            this.updateValidState();
        }
    }

    public getErrorMessage(_formControl: FormControl): string|void {
        // Nothing to check for now
    }

    public buildingModified(entity: GenericCoreDataEntity): void {
        if (!this.buildings) {
            return;
        }

        for (const building of this.buildings) {
            if (building.identifier.businessIdentifier == entity.identifier.businessIdentifier) {
                building.entity?.fromStorageDto(entity.toStorageDto());
            }
        }
        this.updateValidState();
    }

    public isAddressEmpty(): boolean {
        if (!this.buildingComplex.address) {
            return true;
        }
        return this.buildingComplex.address.isEmpty;
    }

    public async deleteBuilding(entityLink: EntityLink<GenericCoreDataEntity>): Promise<void> {
        if (!entityLink.entity || !this.buildings) {
            return;
        }

        const options: DeleteEntityIntentOptions = new DeleteEntityIntentOptions(entityLink.entity);
        await this.intentsService.executeIntentAndWait(DeleteEntityIntent, options);
        if (options.result) {
            this.buildings = this.buildings.filter((link: EntityLink<Building>) => link.identifier.businessIdentifier != entityLink.identifier.businessIdentifier);
        }
    }
}
