import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { DefaultService, TranslationDto } from "../../../generated/api";
import { SessionService } from "../session/session.service";

/**
 * The service to talk to the server backend.
 */
@Injectable({
    providedIn: "root"
})
export class TranslationsApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public listTranslations(language: string): Promise<Array<TranslationDto>> {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listTranslations(this.sessionService.activeAccountId, language));
    }
}
