import { EntityIdentifierStorageDto } from "../../identifiers/entity-identifier-storage-dto";

/**
 * Storage DTO for {@link Attachment}.
 */
export class AttachmentStorageDto {
    public businessId?: string;

    public technicalId?: number;

    public type?: string;

    public state?: string;

    public created?: string;

    public updated?: string;

    public createdClient?: string;

    public deleted?: boolean;

    public linkedDocuments: Array<EntityIdentifierStorageDto> = [];

    public linkedQuestionTemplates: Array<number> = [];

    public linkedQuestions: Array<number> = [];

    public bytes?: number;

    public description?: string;

    public metaData?: string;

    public showInPdf?: boolean;

    public uploadError?: string;
}
