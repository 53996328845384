import { HttpErrorResponse } from "@angular/common/http";

import { AppException } from "../../business/entities/exceptions/app-exception";
import { HttpCodes } from "./http-codes";

/**
 * Helper class for web specific issues.
 */
export class WebHelper {
    public static isNoInternetError(error: any, treatNotFoundAsNoInternet: boolean = false): boolean {
        const appException: AppException = error as AppException;

        const httpError: HttpErrorResponse = appException?.innerException ? appException?.innerException as HttpErrorResponse : error as HttpErrorResponse;
        if (!httpError) {
            return false;
        }
        return httpError.status == 0 || httpError.status == HttpCodes.gatewayTimeout || httpError.status == HttpCodes.requestTimeout || httpError.status == HttpCodes.badGateway || (treatNotFoundAsNoInternet && httpError.status == HttpCodes.notFound);
    }

    public static isNotFoundError(error: any): boolean {
        const appException: AppException = error as AppException;

        const httpError: HttpErrorResponse = appException?.innerException ? appException?.innerException as HttpErrorResponse : error as HttpErrorResponse;
        if (!httpError) {
            return false;
        }
        return httpError.status == HttpCodes.notFound;
    }

    public static isLoggedOutError(error: any): boolean {
        const httpError: HttpErrorResponse = error as HttpErrorResponse;
        if (!httpError) {
            return false;
        }
        return httpError.status == HttpCodes.unauthorized;
    }

    public static downloadBlob(blob: Blob, filename: string): void {
        const blobUrl: string = URL.createObjectURL(blob);

        const link: HTMLAnchorElement = document.createElement("a");

        link.href = blobUrl;
        link.download = filename;

        // Append link to the body
        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        document.body.removeChild(link);
    }
}
