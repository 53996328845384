import { TypeScriptHelper } from "../../base/helpers/type-script-helper";
import { EntityIdentifier } from "./entity-identifier";

/**
 * Storage DTO for a identifier pair.
 */
export class EntityIdentifierStorageDto {
    constructor(init?: Partial<EntityIdentifierStorageDto>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public bid?: string;

    public tid?: number;

    public static fromIdentifier(identifier: EntityIdentifier|undefined): EntityIdentifierStorageDto {
        return new EntityIdentifierStorageDto({
            bid: identifier?.businessIdentifier,
            tid: identifier?.technicalIdentifier
        });
    }
}
