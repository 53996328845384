import { GenericStatus } from "../../../base/helpers/generic-status-enum";
import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";

/**
 * A single step of the network test.
 */
export class NetworkTestStep {
    constructor(key: string, title: string, init?: Partial<NetworkTestStep>) {
        TypeScriptHelper.initObjectFromPartial(this, init);

        this.key = key;
        this.title = title;
    }

    public key: string;

    public title: string;

    public description?: string;

    public status: GenericStatus = GenericStatus.pending;

    public url?: string;

    public reset(): NetworkTestStep {
        this.status = GenericStatus.pending;
        return this;
    }
}
