import { EntityLink } from "src/app/business/entities/documents/entity-link";

import { ArrayHelper } from "../../../base/helpers/array-helper";
import { DateTimeHelper } from "../../../base/helpers/date-time-helper";
import { nameOfFactory } from "../../../base/helpers/name-of-factory";
import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { Attachment } from "../../../business/entities/attachments/attachment";
import { AttachmentFactory } from "../../../business/entities/attachments/attachment-factory";
import { AttachmentStates } from "../../../business/entities/attachments/attachment-states";
import { ImageAttachment } from "../../../business/entities/attachments/image-attachment";
import { ChatMessage } from "../../../business/entities/communication/chat-message";
import { CommunicationFactory } from "../../../business/entities/communication/communication-factory";
import { Address } from "../../../business/entities/core-data/address";
import { Building } from "../../../business/entities/core-data/buildings/building";
import { BuildingComplex } from "../../../business/entities/core-data/buildings/building-complex";
import { BuildingUsageTypes } from "../../../business/entities/core-data/buildings/building-usage-types";
import { DrinkingWaterHeatingTypes } from "../../../business/entities/core-data/buildings/drinking-water-heating-types";
import { Floor } from "../../../business/entities/core-data/buildings/floor";
import { Room } from "../../../business/entities/core-data/buildings/room";
import { RoomFeature } from "../../../business/entities/core-data/buildings/room-feature";
import { RoomFeatureMapping } from "../../../business/entities/core-data/buildings/room-feature-mapping";
import { Company } from "../../../business/entities/core-data/company";
import { GenericCoreDataEntity } from "../../../business/entities/core-data/generic-core-data-entity";
import { Person } from "../../../business/entities/core-data/person";
import { DashboardProcesses } from "../../../business/entities/dashboards/dashboard-processes";
import { AuditDocument } from "../../../business/entities/documents/audit-document";
import { DocumentAnswer } from "../../../business/entities/documents/document-answer";
import { DocumentAnswerValue } from "../../../business/entities/documents/document-answer-value";
import { DocumentQuestion } from "../../../business/entities/documents/document-question";
import { DocumentSection } from "../../../business/entities/documents/document-section";
import { DocumentStatus } from "../../../business/entities/documents/document-status";
import { DocumentTemplate } from "../../../business/entities/documents/document-template";
import { DocumentTypes } from "../../../business/entities/documents/document-types";
import { QualityAssuranceStatus } from "../../../business/entities/documents/quality-assurance-status";
import { EntityTypes } from "../../../business/entities/entity-types";
import { AppException } from "../../../business/entities/exceptions/app-exception";
import { Process } from "../../../business/entities/projects-processes/process";
import { ProcessStatus } from "../../../business/entities/projects-processes/process-status";
import { ProcessTypes } from "../../../business/entities/projects-processes/process-types";
import { Project } from "../../../business/entities/projects-processes/project";
import { QualityAssuranceFactory } from "../../../business/entities/quality-assurance/quality-assurance-factory";
import { QualityAssuranceItem } from "../../../business/entities/quality-assurance/quality-assurance-item";
import { Sample } from "../../../business/entities/samplings/sample";
import { SampleMerge } from "../../../business/entities/samplings/sample-merge";
import { SampleTypes } from "../../../business/entities/samplings/sample-types";
import { SamplingPlan } from "../../../business/entities/samplings/sampling-plan";
import { SamplingPlanMerge } from "../../../business/entities/samplings/sampling-plan-merge";
import { FrontendErrors } from "../../../business/global/frontend-errors";
import { AddressIdentifier } from "../../../business/identifiers/address-identifier";
import { AnswerIdentifier } from "../../../business/identifiers/answer-identifier";
import { AnswerTemplateIdentifier } from "../../../business/identifiers/answer-template-identifier";
import { AttachmentBusinessIdentifier } from "../../../business/identifiers/attachment-business-identifier";
import { AttachmentTechnicalIdentifier } from "../../../business/identifiers/attachment-technical-identifier";
import { BuildingBusinessIdentifier } from "../../../business/identifiers/building-business-identifier";
import { BuildingComplexIdentifier } from "../../../business/identifiers/building-complex-identifier";
import { BuildingIdentifier } from "../../../business/identifiers/building-identifier";
import { BuildingTechnicalIdentifier } from "../../../business/identifiers/building-technical-identifier";
import { BusinessTechnicalIdentifierPair } from "../../../business/identifiers/business-technical-identifier-pair";
import { CompanyIdentifier } from "../../../business/identifiers/company-identifier";
import { DocumentBusinessIdentifier } from "../../../business/identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../../business/identifiers/document-identifier";
import { DocumentTechnicalIdentifier } from "../../../business/identifiers/document-technical-identifier";
import { EntityBusinessIdentifier } from "../../../business/identifiers/entity-business-identifier";
import { EntityIdentifier } from "../../../business/identifiers/entity-identifier";
import { EntityTechnicalIdentifier } from "../../../business/identifiers/entity-technical-identifier";
import { FloorTechnicalIdentifier } from "../../../business/identifiers/floor-technical-identifier";
import { Identifier } from "../../../business/identifiers/identifier";
import { PersonBusinessIdentifier } from "../../../business/identifiers/person-business-identifier";
import { PersonTechnicalIdentifier } from "../../../business/identifiers/person-technical-identifier";
import { ProcessIdentifier } from "../../../business/identifiers/process-identifier";
import { ProjectIdentifier } from "../../../business/identifiers/project-identifier";
import { QualityAssuranceIdentifier } from "../../../business/identifiers/quality-assurance-identifier";
import { QuestionIdentifier } from "../../../business/identifiers/question-identifier";
import { QuestionTechnicalIdentifier } from "../../../business/identifiers/question-technical-identifier";
import { QuestionTemplateIdentifier } from "../../../business/identifiers/question-template-identifier";
import { RoomTechnicalIdentifier } from "../../../business/identifiers/room-technical-identifier";
import { SectionIdentifier } from "../../../business/identifiers/section-identifier";
import { SectionTemplateIdentifier } from "../../../business/identifiers/section-template-identifier";
import { CoreDataFactory } from "../../../business/services/master-data/core-data-factory";
import { BuildingWaterWalkthroughInspectionDocument } from "../../../domain/entities/building-water-walkthrough-inspection-document";
import { AddressDto, AnswerDto, BuildingComplexDto, BuildingComplexPersonMappingDto, BuildingDto, ChatMessageDto, CompanyDto, FloorDto, IDDto, LegalReferenceDto, PersonDto, PhotoDto, ProcessDto, ProcessStatusDto, ProcessTypeEnumDto, ProjectDto, QualityAssuranceDto, QualityAssuranceStatusDto, QuestionDto, RequestBodyMergeSamplingPlanDto, ResponseBodyDashboardProcessesDto, ResponseBodyProcessOverviewDto, RoomDto, RoomFeatureDto, RoomRoomFeatureMappingDto, SampleDto, SampleRawDataDto, SampleTypeEnumDto, SamplingPlanDto, TemplateVersionDto, WalkthroughInspectionDto } from "../../../generated/api";
import { Api1Helpers } from "../api1-helpers";
import { DocumentParser } from "./document-parser";
import { GenericEntityDto } from "./generic-entity-dto";
import DocumentTypeEnum = TemplateVersionDto.DocumentTypeEnum;
import { environment } from "../../../../environments/environment";
import { SampleLegalReferences } from "../../../business/entities/samplings/sample-legal-references";
import { AddressTechnicalIdentifier } from "../../../business/identifiers/address-technical-identifier";

/**
 * Class to DTO converter.
 */
export class Api1Converter {
    public static companyToDto(company: Company): CompanyDto {
        const dto: CompanyDto = {
            businessId: company.identifier.businessIdentifier,
            technicalId: company.identifier.technicalIdentifier,
            externalReference: company.externalReference,
            name: company.name,
            email: company.email,
            address: this.addressToDto(company.address ?? new Address())
        };

        dto.personMappings = [];
        for (const personLink of company.personLinks) {
            dto.personMappings.push(this.entityLinkToToPersonMappingDto(personLink));
        }

        return dto;
    }

    public static addressToDto(address: Address): AddressDto {
        return this.genericEntityToDto(address, {
            street: address.street,
            streetNumber: address.streetNumber,
            zipCode: address.zipCode,
            city: address.city,
            country: address.country,
            province: address.province,
            additionalInfo: address.additionalInfo
        });
    }

    public static dtoToBuilding(dto?: BuildingDto): Building {
        const building: Building = new Building();
        building.identifier = {
            businessIdentifier: (dto?.businessId ?? "") as BuildingBusinessIdentifier,
            technicalIdentifier: dto?.technicalId as BuildingTechnicalIdentifier
        };

        building.created = dto?.created;
        building.updated = dto?.updated;
        building.updatedClient = dto?.updatedClient;

        building.name = dto?.name;
        building.buildingUsage = this.dtoToBuildingUsage(dto?.buildingUsage);
        building.publicUsage = !!dto?.publicUsage;

        building.addresses = [];
        for (const addressDto of dto?.addresses ?? []) {
            const address: Address|undefined = this.dtoToAddress(addressDto);
            if (address) {
                building.addresses.push(address);
            }
        }

        building.floors = [];
        for (const floorDto of dto?.floors ?? []) {
            building.floors.push(this.dtoToFloor(floorDto));
        }

        building.constructionDate = dto?.constructionDate;
        building.usageDescription = dto?.usageDescription;
        building.numberOfFlats = dto?.numberOfFlats;
        building.numberOfOffices = dto?.numberOfOffices;
        building.numberOfUnusedFlatsAndOffices = dto?.numberOfUnusedFlatsAndOffices;
        building.visibleAscendingPipes = dto?.visibleAscendingPipes;
        building.ascendingPipesEnds = dto?.ascendingPipesEnds;
        building.installationPlansExist = dto?.installationPlansExist;
        building.drinkingWaterHeatingType = this.dtoToDrinkingWaterHeatingType(dto?.drinkingWaterHeatingType);
        building.drinkingWaterHeatingLocation = dto?.drinkingWaterHeatingLocation;
        building.locationHouseConnection = dto?.locationHouseConnection;
        building.additionalInfo = dto?.additionalInfo;

        return building;
    }

    public static dtoToBuildingComplex(dto?: BuildingComplexDto): BuildingComplex {
        const buildingComplex: BuildingComplex = new BuildingComplex();
        buildingComplex.identifier = Identifier.create(dto?.businessId, dto?.technicalId);

        buildingComplex.created = dto?.created;
        buildingComplex.updated = dto?.updated;

        buildingComplex.externalReference = dto?.externalReference;

        buildingComplex.name = dto?.name;
        buildingComplex.address = this.dtoToAddress(dto?.address);
        buildingComplex.addressRaw = dto?.addressRaw;

        buildingComplex.companyLink = CoreDataFactory.createEntityLink(Identifier.create(undefined, dto?.companyId), EntityTypes.company);

        buildingComplex.personLinks = [];
        for (const personMappingDto of dto?.personMappings ?? []) {
            const entityLink: EntityLink<Person> = CoreDataFactory.createEntityLink(
                Identifier.create(personMappingDto.personId?.businessId as EntityBusinessIdentifier, personMappingDto.personId?.technicalId as EntityTechnicalIdentifier),
                EntityTypes.person,
                personMappingDto.role);
            buildingComplex.personLinks.push(entityLink);
        }

        return buildingComplex;
    }

    public static dtoToFloor(dto?: FloorDto): Floor {
        const floor: Floor = CoreDataFactory.createFloor({
            identifier: Identifier.create(dto?.businessId, dto?.technicalId),
            floorNumber: dto?.floorNumber
        });

        for (const roomDto of dto?.rooms ?? []) {
            floor.rooms.push(this.dtoToRoom(roomDto));
        }

        return floor;
    }

    public static dtoToRoom(dto?: RoomDto): Room {
        const room: Room = CoreDataFactory.createRoom({
            identifier: Identifier.fromDto(dto),
            name: dto?.name,
            location: dto?.location
        });

        for (const roomFeatureMappingDto of dto?.roomFeatureMappings ?? []) {
            room.roomFeaturesMapping.push(this.dtoToRoomFeatureMapping(roomFeatureMappingDto));
        }

        return room;
    }

    public static dtoToSample(dto?: SampleDto): Sample {
        return this.dtoToGenericEntity<Sample>(dto, EntityTypes.sample, {
            name: dto?.name,
            roomRoomFeatureMappingTechnicalIdentifier: dto?.roomRoomFeatureMappingId as EntityTechnicalIdentifier,
            buildingIdentifier: Identifier.create<BuildingIdentifier>(dto?.buildingId?.businessId, dto?.buildingId?.technicalId),
            addressTechnicalIdentifier: dto?.addressId as AddressTechnicalIdentifier,
            floorTechnicalIdentifier: dto?.floorId as FloorTechnicalIdentifier,
            roomTechnicalIdentifier: dto?.roomId as RoomTechnicalIdentifier,
            roomFeatureTechnicalIdentifier: dto?.roomFeatureId as EntityTechnicalIdentifier,
            sampleType: this.dtoToSampleType(dto?.sampleType),
            legalReference: this.dtoToSampleLegalReference(dto?.legalReference)
        });
    }

    public static dtoToSamplingPlan(dto?: SamplingPlanDto): SamplingPlan {
        const samplingPlan: SamplingPlan = this.dtoToGenericEntity<SamplingPlan>(dto, EntityTypes.samplingPlan, {
            additionalInfo: dto?.additionalInfo
        });

        for (const sampleDto of dto?.samples ?? []) {
            const sample: Sample = this.dtoToSample(sampleDto);
            samplingPlan.samples.push(sample);
        }

        return samplingPlan;
    }

    public static sampleToDto(sample: Sample): SampleDto {
        return this.genericEntityToDto<SampleDto>(sample, {
            buildingId: !sample.buildingIdentifier?.businessIdentifier && !sample.buildingIdentifier?.technicalIdentifier ? undefined : {
                businessId: sample.buildingIdentifier?.businessIdentifier || undefined,
                technicalId: sample.buildingIdentifier?.technicalIdentifier || undefined
            },
            name: sample.name,
            sampleType: this.sampleTypeToDto(sample.sampleType),
            addressId: sample.addressTechnicalIdentifier || undefined,
            floorId: sample.floorTechnicalIdentifier || undefined,
            roomFeatureId: sample.roomFeatureTechnicalIdentifier || undefined,
            roomId: sample.roomTechnicalIdentifier || undefined,
            roomRoomFeatureMappingId: sample.roomRoomFeatureMappingTechnicalIdentifier || undefined,
            legalReference: this.sampleLegalReferenceToDto(sample.legalReference)
        });
    }

    public static sampleTypeToDto(sampleType: SampleTypes): SampleTypeEnumDto|undefined {
        switch (sampleType) {
            case SampleTypes.unknown:
                return undefined;
            case SampleTypes.legionella:
                return SampleTypeEnumDto.Legionella;
            case SampleTypes.microbiology:
                return SampleTypeEnumDto.Microbiology;
            case SampleTypes.chemical:
                return SampleTypeEnumDto.Chemical;
            default:
                TypeScriptHelper.expectNever(sampleType);
                break;
        }
    }

    public static dtoToSampleType(sampleType: SampleTypeEnumDto|undefined): SampleTypes {
        switch (sampleType) {
            case SampleTypeEnumDto.Legionella:
                return SampleTypes.legionella;
            case SampleTypeEnumDto.Microbiology:
                return SampleTypes.microbiology;
            case SampleTypeEnumDto.Chemical:
                return SampleTypes.chemical;
            default:
                return SampleTypes.unknown;
        }
    }

    public static sampleLegalReferenceToDto(legalReference: SampleLegalReferences|undefined): LegalReferenceDto|undefined {
        if (!legalReference) {
            return LegalReferenceDto.DvgwW551;
        }
        switch (legalReference) {
            case SampleLegalReferences.dvgwW551:
                return LegalReferenceDto.DvgwW551;
            case SampleLegalReferences.ubaEmpfehlung18122018:
                return LegalReferenceDto.UbaEmpfehlung18122018;
            default:
                TypeScriptHelper.expectNever(legalReference);
                break;
        }
    }

    public static dtoToSampleLegalReference(legalReferenceDto: LegalReferenceDto|undefined): SampleLegalReferences {
        switch (legalReferenceDto) {
            case LegalReferenceDto.DvgwW551:
                return SampleLegalReferences.dvgwW551;
            case LegalReferenceDto.UbaEmpfehlung18122018:
                return SampleLegalReferences.ubaEmpfehlung18122018;
            default:
                return SampleLegalReferences.dvgwW551;
        }
    }

    public static sampleMergeToDto(sampleMerge: SampleMerge): SampleRawDataDto {
        const buildingBusinessId: string|undefined = sampleMerge.buildingIdentifier?.businessIdentifier?.length && sampleMerge.buildingIdentifier.businessIdentifier[0] != "#"
            ? sampleMerge.buildingIdentifier.businessIdentifier
            : undefined;
        return this.genericEntityToDto<SampleRawDataDto>(sampleMerge, {
            buildingBusinessId: buildingBusinessId || undefined,
            name: sampleMerge.name || undefined,
            sampleType: this.sampleTypeToDto(sampleMerge.sampleType) as SampleTypeEnumDto,
            floorNumber: sampleMerge.floorNumber,
            roomLocation: sampleMerge.roomLocation,
            roomName: sampleMerge.roomName,
            roomFeatureLocation: sampleMerge.roomFeatureLocation,
            roomFeatureName: sampleMerge.roomFeatureName,
            street: sampleMerge.street,
            streetNumber: sampleMerge.streetNumber,
            legalReference: this.sampleLegalReferenceToDto(sampleMerge.legalReference) as LegalReferenceDto
        });
    }

    public static samplingPlanToDto(samplingPlan: SamplingPlan): SamplingPlanDto {
        const dto: SamplingPlanDto = this.genericEntityToDto<SamplingPlanDto>(samplingPlan, {
            samples: [],
            additionalInfo: samplingPlan.additionalInfo
        });
        for (const sample of samplingPlan.samples) {
            dto.samples!.push(this.sampleToDto(sample));
        }
        return dto;
    }

    public static samplingPlanMergeToDto(samplingPlanMerge: SamplingPlanMerge): RequestBodyMergeSamplingPlanDto {
        const dto: RequestBodyMergeSamplingPlanDto = {
            documentBusinessId: samplingPlanMerge.documentIdentifier.businessIdentifier,
            sampleRawData: [],
            additionalInfo: samplingPlanMerge.additionalInfo
        };
        for (const sample of samplingPlanMerge.samples) {
            dto.sampleRawData!.push(this.sampleMergeToDto(sample));
        }
        return dto;
    }

    private static dtoToGenericEntity<TEntity = GenericCoreDataEntity>(dto: GenericEntityDto|undefined, entityType: EntityTypes, init?: Partial<TEntity>): TEntity|never {
        const entity: TEntity|undefined = CoreDataFactory.createFromEntityType(entityType, {
            identifier: Identifier.fromDto(dto),
            created: dto?.created || undefined,
            updated: dto?.updated || undefined,
            updatedClient: dto?.updatedClient || undefined,
            externalReference: dto?.externalReference || undefined
        }) as TEntity|undefined;
        if (!entity) {
            throw new AppException(FrontendErrors.FE54EntityTypeIsUnknown, $localize`:@@exception.fe54EntityTypeIsUnknown:The entity type "${entityType}:entityType:" is not known. Please update the app.`);
        }

        if (init) {
            TypeScriptHelper.initObjectFromPartial(entity, init);
        }

        return entity;
    }

    public static dtoToDashboardProcesses(dto: ResponseBodyDashboardProcessesDto): DashboardProcesses {
        const dashboard: DashboardProcesses = new DashboardProcesses({
            identifier: Identifier.empty(),
            created: DateTimeHelper.utcNow(),
            updated: DateTimeHelper.utcNow()
        });

        const addEntities: (dtoList: (Array<GenericEntityDto>|undefined), converter: (entity: GenericEntityDto) => GenericCoreDataEntity) => void = (dtoList: Array<GenericEntityDto>|undefined, converter: (entity: GenericEntityDto) => GenericCoreDataEntity): void => {
            for (const entityDto of dtoList ?? []) {
                const entity: GenericCoreDataEntity = converter(entityDto);
                dashboard.linkedEntities.push(CoreDataFactory.createEntityLinkFromEntity(entity));
            }
        };

        addEntities(dto?.projects, this.dtoToProject.bind(this));
        addEntities(dto?.processes, this.dtoToProcess.bind(this));
        addEntities(dto?.buildingComplexes, this.dtoToBuildingComplex.bind(this));
        addEntities(dto?.persons, this.dtoToPerson.bind(this));
        addEntities(dto?.companies, this.dtoToCompany.bind(this));

        return dashboard;
    }

    private static genericEntityToDto<TDto = GenericEntityDto>(entity: GenericCoreDataEntity, init?: Partial<TDto>): TDto {
        const dto: TDto = {
            businessId: entity.identifier.businessIdentifier || undefined,
            technicalId: entity.identifier.technicalIdentifier || undefined,
            created: entity.created || undefined,
            updated: entity.updated || undefined,
            updatedClient: entity.updatedClient || undefined,
            externalReference: entity.externalReference || undefined
        } as GenericEntityDto as TDto;

        if (init) {
            TypeScriptHelper.initObjectFromPartial(dto, init);
        }

        return dto;
    }

    public static dtoToCompany(dto?: CompanyDto): Company {
        const company: Company = CoreDataFactory.createCompany({
            identifier: Identifier.fromDto<CompanyIdentifier, CompanyDto>(dto),
            name: dto?.name,
            email: dto?.email,
            address: this.dtoToAddress(dto?.address),
            externalReference: dto?.externalReference
        });

        company.created = dto?.created;
        company.updated = dto?.updated;

        company.personLinks = [];
        for (const personMappingDto of dto?.personMappings ?? []) {
            const entityLink: EntityLink<Person> = CoreDataFactory.createEntityLink(
                Identifier.create(personMappingDto.personId?.businessId as EntityBusinessIdentifier, personMappingDto.personId?.technicalId as EntityTechnicalIdentifier),
                EntityTypes.person,
                personMappingDto.role || undefined);
            company.personLinks.push(entityLink);
        }
        return company;
    }

    public static dtoToBuildingUsage(usage?: BuildingDto.BuildingUsageEnum): BuildingUsageTypes {
        switch (usage) {
            case BuildingDto.BuildingUsageEnum.Residential:
                return BuildingUsageTypes.residential;
            case BuildingDto.BuildingUsageEnum.Commercial:
                return BuildingUsageTypes.commercial;
            case BuildingDto.BuildingUsageEnum.ResidentialAndCommercial:
                return BuildingUsageTypes.residentialAndCommercial;
            default:
                return BuildingUsageTypes.unknown;
        }
    }

    public static buildingUsageToDto(usage?: BuildingUsageTypes): BuildingDto.BuildingUsageEnum {
        switch (usage) {
            case BuildingUsageTypes.residential:
                return BuildingDto.BuildingUsageEnum.Residential;
            case BuildingUsageTypes.commercial:
                return BuildingDto.BuildingUsageEnum.Commercial;
            case BuildingUsageTypes.residentialAndCommercial:
                return BuildingDto.BuildingUsageEnum.ResidentialAndCommercial;
            default:
                return BuildingDto.BuildingUsageEnum.Unknown;
        }
    }

    public static dtoToDrinkingWaterHeatingType(usage?: BuildingDto.DrinkingWaterHeatingTypeEnum|undefined): DrinkingWaterHeatingTypes {
        switch (usage) {
            case BuildingDto.DrinkingWaterHeatingTypeEnum.Centralized:
                return DrinkingWaterHeatingTypes.centralized;
            case BuildingDto.DrinkingWaterHeatingTypeEnum.Decentralized:
                return DrinkingWaterHeatingTypes.decentralized;
            case BuildingDto.DrinkingWaterHeatingTypeEnum.Mixed:
                return DrinkingWaterHeatingTypes.mixed;
            case BuildingDto.DrinkingWaterHeatingTypeEnum.SmallCentralized:
                return DrinkingWaterHeatingTypes.smallCentralized;
            default:
                return DrinkingWaterHeatingTypes.unknown;
        }
    }

    public static drinkingWaterHeatingTypesToDto(heatingTypes?: DrinkingWaterHeatingTypes): BuildingDto.DrinkingWaterHeatingTypeEnum|undefined {
        switch (heatingTypes) {
            case DrinkingWaterHeatingTypes.centralized:
                return BuildingDto.DrinkingWaterHeatingTypeEnum.Centralized;
            case DrinkingWaterHeatingTypes.decentralized:
                return BuildingDto.DrinkingWaterHeatingTypeEnum.Decentralized;
            case DrinkingWaterHeatingTypes.mixed:
                return BuildingDto.DrinkingWaterHeatingTypeEnum.Mixed;
            case DrinkingWaterHeatingTypes.smallCentralized:
                return BuildingDto.DrinkingWaterHeatingTypeEnum.SmallCentralized;
            default:
                return undefined;
        }
    }

    public static dtoToDocumentStatus(status?: ProcessStatusDto): DocumentStatus {
        switch (status) {
            case ProcessStatusDto.OrderEntry:
                return DocumentStatus.orderEntry;
            case ProcessStatusDto.Scheduled:
                return DocumentStatus.scheduled;
            case ProcessStatusDto.InProgress:
                return DocumentStatus.inProgress;
            case ProcessStatusDto.Done:
                return DocumentStatus.done;
            case ProcessStatusDto.InReview:
                return DocumentStatus.inReview;
            case ProcessStatusDto.ReviewAccepted:
                return DocumentStatus.reviewAccepted;
            case ProcessStatusDto.ReviewRejected:
                return DocumentStatus.reviewRejected;
            default:
                return DocumentStatus.unknown;
        }
    }

    public static documentStatusToDto(status: DocumentStatus): ProcessStatusDto|undefined {
        switch (status) {
            case DocumentStatus.orderEntry:
                return ProcessStatusDto.OrderEntry;
            case DocumentStatus.scheduled:
                return ProcessStatusDto.Scheduled;
            case DocumentStatus.inProgress:
                return ProcessStatusDto.InProgress;
            case DocumentStatus.done:
                return ProcessStatusDto.Done;
            case DocumentStatus.inReview:
                return ProcessStatusDto.InReview;
            case DocumentStatus.reviewAccepted:
                return ProcessStatusDto.ReviewAccepted;
            case DocumentStatus.reviewRejected:
                return ProcessStatusDto.ReviewRejected;
            case DocumentStatus.unknown:
                return undefined;
            default:
                TypeScriptHelper.expectNever(status);
        }
    }

    public static dtoToDocumentType(type: TemplateVersionDto.DocumentTypeEnum|undefined): DocumentTypes {
        switch (type) {
            case TemplateVersionDto.DocumentTypeEnum.WalkthroughInspection:
                return DocumentTypes.buildingWaterWalkthroughInspection;
            default:
                return DocumentTypes.unknown;
        }
    }

    public static documentTypeToDto(documentType: DocumentTypes): TemplateVersionDto.DocumentTypeEnum|undefined {
        switch (documentType) {
            case DocumentTypes.buildingWaterWalkthroughInspection:
                return TemplateVersionDto.DocumentTypeEnum.WalkthroughInspection;
            case DocumentTypes.unknown:
            case DocumentTypes.simpleDocument:
                return undefined;
            default:
                TypeScriptHelper.expectNever(documentType);
                break;
        }
    }

    public static dtoToProject(dto?: ProjectDto): Project {
        const project: Project = CoreDataFactory.createProject({
            identifier: Identifier.fromDto(dto) as ProjectIdentifier,
            created: dto?.created,
            updated: dto?.updated,

            title: dto?.title
        });

        project.companyLink = new EntityLink<Company>(Identifier.create(undefined, dto?.customerCompanyId), EntityTypes.company);

        for (const processTechnicalId of dto?.processIds ?? []) {
            project.processes.push(CoreDataFactory.createEntityLink(Identifier.create(undefined, processTechnicalId), EntityTypes.process));
        }

        return project;
    }

    public static dtoToPerson(dto?: PersonDto): Person {
        const person: Person = CoreDataFactory.createPerson();
        person.identifier = {
            businessIdentifier: (dto?.businessId ?? "") as PersonBusinessIdentifier,
            technicalIdentifier: dto?.technicalId as PersonTechnicalIdentifier
        };

        person.created = dto?.created;
        person.updated = dto?.updated;

        person.email = dto?.email;
        person.firstname = dto?.firstname;
        person.gender = dto?.gender;
        person.lastname = dto?.lastname;
        person.phone = dto?.phone;
        person.phoneMobile = dto?.phoneMobile;
        person.title = dto?.title;

        person.status = dto?.status;

        person.address = this.dtoToAddress(dto?.address);

        return person;
    }

    public static identifierToDto(identifier: EntityIdentifier|undefined): IDDto|undefined {
        return identifier ? {
            businessId: identifier.businessIdentifier,
            technicalId: identifier.technicalIdentifier
        } : undefined;
    }

    public static projectToDto(project: Project): ProjectDto {
        return {
            businessId: project.identifier.businessIdentifier || undefined,
            technicalId: project.identifier.technicalIdentifier || undefined,

            title: project.title,
            customerCompanyId: project.companyLink?.identifier.technicalIdentifier
        };
    }

    public static processToDto(process: Process): ProcessDto {
        return {
            businessId: process.identifier.businessIdentifier || undefined,
            technicalId: process.identifier.technicalIdentifier || undefined,

            processType: this.processTypeToDto(process.processType),
            status: this.processStatusToDto(process.status),
            buildingComplexId: this.identifierToDto(process.linkedEntities.find((link: EntityLink<GenericCoreDataEntity>) => link.entityType == EntityTypes.buildingComplex)?.identifier),
            visitDate: process.targetDate,
            visitEndDate: process.targetEndDate,
            additionalInfo: process.additionalInfo,
            assigneeId: Identifier.isEmpty(process.linkedAssignee?.identifier) ? undefined : {
                businessId: process.linkedAssignee!.identifier.businessIdentifier,
                technicalId: process.linkedAssignee!.identifier.technicalIdentifier
            }
        };
    }

    public static personToDto(person: Person): PersonDto {
        return {
            businessId: person.identifier.businessIdentifier || undefined,
            technicalId: person.identifier.technicalIdentifier || undefined,

            email: person.email,
            firstname: person.firstname,
            gender: person.gender,
            lastname: person.lastname,
            phone: person.phone,
            phoneMobile: person.phoneMobile,
            title: person.title,

            address: person.address ? this.addressToDto(person.address) : undefined
        };
    }

    public static buildingToDto(building: Building): BuildingDto {
        const dto: BuildingDto = {
            businessId: building.identifier.businessIdentifier || undefined,
            technicalId: building.identifier.technicalIdentifier || undefined,
            updatedClient: building.updatedClient || undefined,

            externalReference: building.externalReference,

            name: building.name,
            buildingUsage: this.buildingUsageToDto(building.buildingUsage),
            publicUsage: building.publicUsage,

            constructionDate: DateTimeHelper.sanitizeDateTime(building.constructionDate),
            usageDescription: building.usageDescription,
            numberOfFlats: building.numberOfFlats,
            numberOfOffices: building.numberOfOffices,
            numberOfUnusedFlatsAndOffices: building.numberOfUnusedFlatsAndOffices,
            visibleAscendingPipes: building.visibleAscendingPipes,
            ascendingPipesEnds: building.ascendingPipesEnds,
            installationPlansExist: building.installationPlansExist,
            drinkingWaterHeatingType: this.drinkingWaterHeatingTypesToDto(building.drinkingWaterHeatingType),
            drinkingWaterHeatingLocation: building.drinkingWaterHeatingLocation,
            locationHouseConnection: building.locationHouseConnection,
            additionalInfo: building.additionalInfo,

            // Do not update floors with building update. This can be changed later.
            floors: undefined
        };

        dto.addresses = [];
        for (const address of building.addresses) {
            dto.addresses.push(this.addressToDto(address));
        }

        return dto;
    }

    public static buildingComplexToDto(buildingComplex: BuildingComplex): BuildingComplexDto {
        const dto: BuildingComplexDto = {
            businessId: buildingComplex.identifier.businessIdentifier || undefined,
            technicalId: buildingComplex.identifier.technicalIdentifier || undefined,

            externalReference: buildingComplex.externalReference,

            name: buildingComplex.name,
            companyId: buildingComplex.companyLink?.identifier.technicalIdentifier || undefined,

            address: buildingComplex.address ? this.addressToDto(buildingComplex.address) : undefined,
            addressRaw: buildingComplex.addressRaw
        };

        dto.personMappings = [];
        for (const personLink of buildingComplex.personLinks) {
            dto.personMappings.push(this.entityLinkToToPersonMappingDto(personLink));
        }

        return dto;
    }

    public static entityLinkToToPersonMappingDto(personLink: EntityLink<Person>): BuildingComplexPersonMappingDto {
        return {
            personId: {
                businessId: personLink.identifier.businessIdentifier || undefined,
                technicalId: personLink.identifier.technicalIdentifier ? (personLink.identifier.technicalIdentifier || undefined) : undefined
            },
            role: personLink.linkDescription || undefined
        };
    }

    public static floorToDto(floor: Floor): FloorDto {
        const dto: FloorDto = {
            businessId: floor.identifier.businessIdentifier || undefined,
            technicalId: floor.identifier.technicalIdentifier || undefined,
            floorNumber: floor.floorNumber,
            rooms: []
        };

        for (const room of floor.rooms) {
            dto.rooms!.push(this.roomToDto(room));
        }

        return dto;
    }

    public static roomToDto(room: Room): RoomDto {
        const dto: RoomDto = {
            businessId: room.identifier.businessIdentifier || undefined,
            technicalId: room.identifier.technicalIdentifier || undefined,
            name: room.name,
            location: room.location,
            roomFeatureMappings: []
        };

        for (const featureMapping of room.roomFeaturesMapping) {
            if (featureMapping.roomFeatureTechnicalIdentifier) {
                dto.roomFeatureMappings!.push(this.roomFeatureMappingToDto(featureMapping));
            }
        }

        return dto;
    }

    public static roomFeatureMappingToDto(roomFeatureMapping: RoomFeatureMapping): RoomRoomFeatureMappingDto {
        return {
            // ID must be empty for mappings, see BE47
            businessId: undefined,
            technicalId: undefined,
            roomFeatureId: roomFeatureMapping.roomFeatureTechnicalIdentifier,
            name: roomFeatureMapping.name,
            order: roomFeatureMapping.order
        };
    }

    public static dtoToAddress(dto?: AddressDto): Address {
        return this.dtoToGenericEntity<Address>(dto, EntityTypes.address, {
            identifier: Identifier.fromDto<AddressIdentifier, AddressDto>(dto),
            additionalInfo: dto?.additionalInfo ?? undefined,
            city: dto?.city ?? undefined,
            country: dto?.country ?? undefined,
            province: dto?.province ?? undefined,
            street: dto?.street ?? undefined,
            streetNumber: dto?.streetNumber ?? undefined,
            zipCode: dto?.zipCode ?? undefined
        });
    }

    public static walkthroughInspectionDtoToDocument(inspection: WalkthroughInspectionDto, template: TemplateVersionDto, buildingComplexes: Array<BuildingComplex>, buildings: Array<Building>, _companies: Array<Company>, persons: Array<Person>, attachments: Array<Attachment>): AuditDocument {
        const documentTemplate: DocumentTemplate = DocumentParser.dtoToTemplateVersion(template);

        let document: AuditDocument|undefined = undefined;
        const documentNameOf: (name: keyof AuditDocument) => keyof AuditDocument = nameOfFactory<AuditDocument>();

        switch (template.documentType) {
            case DocumentTypeEnum.WalkthroughInspection:
                const walkthroughInspectionNameOf: (name: keyof BuildingWaterWalkthroughInspectionDocument) => keyof BuildingWaterWalkthroughInspectionDocument = nameOfFactory<BuildingWaterWalkthroughInspectionDocument>();
                const buildingWaterWalkthroughInspectionDocument: BuildingWaterWalkthroughInspectionDocument = new BuildingWaterWalkthroughInspectionDocument({
                    businessIdentifier: inspection.businessId as DocumentBusinessIdentifier,
                    technicalIdentifier: inspection.technicalId as DocumentTechnicalIdentifier
                }, documentTemplate);
                document = buildingWaterWalkthroughInspectionDocument;

                for (const buildingId of inspection.buildingIds ?? []) {
                    const building: Building|undefined = Api1Helpers.findByIdentifierPair(buildings, undefined, buildingId);
                    const buildingIdentifier: BuildingIdentifier = building ? building.identifier : Identifier.create("", buildingId);
                    this.linkEntity(EntityTypes.building, document, $localize`:@@document.linkedBuilding:Building`, buildingIdentifier, walkthroughInspectionNameOf("linkedEntities"), buildings);
                }

                if (inspection.buildingComplexId) {
                    const buildingComplex: BuildingComplex|undefined = Api1Helpers.findByIdentifierPair(buildingComplexes, undefined, inspection.buildingComplexId);
                    const buildingComplexIdentifier: BuildingComplexIdentifier = buildingComplex ? buildingComplex.identifier : Identifier.create("", inspection.buildingComplexId);
                    this.linkEntity(EntityTypes.buildingComplex, document, $localize`:@@document.linkedBuildingComplex:Building complex`, buildingComplexIdentifier, walkthroughInspectionNameOf("linkedEntities"), buildings);
                }

                this.applyWalkthroughInspectionValues(buildingWaterWalkthroughInspectionDocument, inspection, attachments);
                break;
            default:
                throw new AppException(FrontendErrors.FE34DocumentTypeIsUnknown, $localize`:@@exception.fe34DocumentTypeIsUnknown:The document type "${template.documentType}:documentType:" is not known. Please update the app or refresh the browser.`);
        }

        // Generic document properties
        document.created = inspection.created;
        document.updated = inspection.updated;
        document.targetDate = inspection.visitDate;
        document.status = this.dtoToDocumentStatus(inspection.status);
        document.externalReference = inspection.externalReference;
        document.additionalInfo = inspection.additionalInfo;
        document.reviewSummary = inspection.reviewSummary;

        const person: Person|undefined = Api1Helpers.findByIdentifierPair(persons, undefined, inspection.auditorId);
        document.assignedAuditor = person ? person.identifier : {
            businessIdentifier: "" as PersonBusinessIdentifier,
            technicalIdentifier: inspection.auditorId as PersonTechnicalIdentifier
        };
        this.linkEntity(EntityTypes.person, document, $localize`:@@document.linkedAuditPerson:Auditor`, document.assignedAuditor, documentNameOf("assignedAuditor"), persons);

        this.sortEntityLinks(document);

        return document;
    }

    public static sortEntityLinks(document: AuditDocument): void {
        switch (document.type) {
            case DocumentTypes.buildingWaterWalkthroughInspection:
                const order: Array<EntityTypes> = [EntityTypes.company, EntityTypes.building, EntityTypes.person];
                document.linkedEntities.sort((a: EntityLink<GenericCoreDataEntity>, b: EntityLink<GenericCoreDataEntity>) => {
                    const posA: number = order.indexOf(a.entityType);
                    const posB: number = order.indexOf(b.entityType);
                    return posA - posB;
                });
                break;
        }
    }

    private static linkEntity<TEntity extends GenericCoreDataEntity>(entityType: EntityTypes, document: AuditDocument, linkDescription: string, identifier: BusinessTechnicalIdentifierPair<EntityBusinessIdentifier, EntityTechnicalIdentifier>, identifierPairPropertyName: string, entities?: Array<TEntity>): void {
        let entity: TEntity|undefined = Api1Helpers.findByIdentifierPair(entities, identifier.businessIdentifier, identifier.technicalIdentifier);
        if (entity) {
            document.linkEntity(entity, identifierPairPropertyName, linkDescription);
        } else {
            entity = CoreDataFactory.createFromEntityType<TEntity>(entityType) as TEntity;
            entity.identifier = identifier;
            document.linkEntity(entity, identifierPairPropertyName, linkDescription);
        }
    }

    public static applyWalkthroughInspectionValues(document: AuditDocument, inspectionDto: WalkthroughInspectionDto, attachments: Array<Attachment>): void {
        const questionDtoList: Array<QuestionDto> = inspectionDto.questions ?? [];
        const repeatableQuestionDtos: Array<QuestionDto> = questionDtoList.filter((question: QuestionDto) => question.repeatable);
        const regularQuestionDtos: Array<QuestionDto> = questionDtoList.filter((question: QuestionDto) => !question.repeatable);

        const repeatableQuestions: Array<DocumentSection> = [];

        // Extract all from repeatable questions
        for (const questionDto of repeatableQuestionDtos) {
            questionDto.questions = questionDtoList.filter((question: QuestionDto) => question.parentQuestionId == questionDto.technicalId);
            for (const concreteRepeatableQuestion of questionDto.questions) {
                ArrayHelper.removeElement(regularQuestionDtos, concreteRepeatableQuestion);
                concreteRepeatableQuestion.questions = questionDtoList.filter((question: QuestionDto) => question.parentQuestionId == concreteRepeatableQuestion.technicalId);
                for (const answerQuestionElement of concreteRepeatableQuestion.questions) {
                    ArrayHelper.removeElement(regularQuestionDtos, answerQuestionElement);
                }
            }
            const repeatableQuestion: DocumentSection = this.dtoToRepeatableQuestion(document, questionDto);
            repeatableQuestions.push(repeatableQuestion);
        }

        this.applyRepeatableQuestions(document, repeatableQuestions);

        for (const questionDto of regularQuestionDtos) {
            this.applyQuestionValues(document, questionDto, 0 as SectionTemplateIdentifier, Identifier.create<SectionIdentifier>("", questionDto.parentQuestionId), attachments);
        }
    }

    private static applyRepeatableQuestions(document: AuditDocument, repeatableQuestions: Array<DocumentSection>): void {
        for (const repeatableQuestion of repeatableQuestions) {
            document.instance.updateRepeatableQuestion(repeatableQuestion.templateIdentifier, repeatableQuestion.identifier, repeatableQuestion.sections);
        }
    }

    private static dtoToRepeatableQuestion(document: AuditDocument, repeatableRootQuestionDto: QuestionDto): DocumentSection {
        const template: DocumentSection = document.template.getSection(repeatableRootQuestionDto.questionTemplateId as SectionTemplateIdentifier);
        template.identifier = Identifier.create(repeatableRootQuestionDto.businessId, repeatableRootQuestionDto.technicalId);
        const rootSection: DocumentSection = template.clone();
        const sectionInstanceCloneSource: DocumentSection = template.clone();

        for (const repeatableInstanceDto of repeatableRootQuestionDto.questions ?? []) {
            const repeatableInstance: DocumentSection = sectionInstanceCloneSource.clone();
            repeatableInstance.identifier = Identifier.create(repeatableInstanceDto.businessId, repeatableInstanceDto.technicalId);
            rootSection.sections.push(repeatableInstance);

            for (const questionDto of repeatableInstanceDto.questions ?? []) {
                const question: DocumentQuestion|undefined = repeatableInstance.questions.find((value: DocumentQuestion) => value.templateIdentifier == questionDto.questionTemplateId);
                if (question) {
                    for (const answerDto of questionDto.answer ?? []) {
                        if (answerDto.value !== undefined && answerDto.value !== null) {
                            const answerValue: DocumentAnswerValue = new DocumentAnswerValue({
                                value: answerDto.value as unknown,
                                answerIdentifier: Identifier.create<AnswerIdentifier>(answerDto.businessId, answerDto.technicalId)
                            });
                            answerValue.value = answerDto.value as unknown;
                            const documentAnswer: DocumentAnswer = new DocumentAnswer(0 as AnswerTemplateIdentifier, { value: answerValue });
                            question.answers.push(documentAnswer);
                        }
                    }
                }
            }
        }

        return rootSection;
    }

    private static applyQuestionValues(document: AuditDocument, questionDto: QuestionDto, parentSectionTemplateIdentifier: SectionTemplateIdentifier|undefined, parentSectionIdentifier: SectionIdentifier|undefined, attachments: Array<Attachment>): void|never {
        if (document.template.isSection(questionDto.questionTemplateId as SectionTemplateIdentifier)) {
            return;
        }

        if (questionDto.technicalId && questionDto.questionTemplateId) {
            const answers: Array<AnswerDto>|undefined = questionDto.answer;

            if (answers) {
                const answerValues: Array<string> = [];
                for (const answer of answers) {
                    if (answer.value) {
                        answerValues.push(answer.value);
                    }
                }
                const questionId: QuestionIdentifier =  Identifier.create<QuestionIdentifier>(questionDto.businessId, questionDto.technicalId);
                document.instance.setValue(questionDto.questionTemplateId as QuestionTemplateIdentifier, questionId, parentSectionTemplateIdentifier, parentSectionIdentifier, [], answerValues);
                document.instance.setEnabled(questionDto.questionTemplateId as QuestionTemplateIdentifier, questionId, parentSectionTemplateIdentifier, parentSectionIdentifier, questionDto.enabled ?? true);
                document.instance.updateComment(questionDto.questionTemplateId as QuestionTemplateIdentifier, questionDto.comment);
            }

            const linkedAttachments: Array<Attachment> = [];
            for (const photoId of questionDto.photoIds ?? []) {
                const attachment: Attachment =
                    attachments.find((existingAttachment: Attachment) => existingAttachment.identifier.businessIdentifier == photoId.businessId && existingAttachment.identifier.technicalIdentifier == photoId.technicalId)
                    ?? AttachmentFactory.createImageAttachment();
                attachment.state = AttachmentStates.metaUpdated;
                attachment.identifier.businessIdentifier = photoId.businessId as AttachmentBusinessIdentifier;
                attachment.identifier.technicalIdentifier = photoId.technicalId as AttachmentTechnicalIdentifier;
                linkedAttachments.push(attachment);
            }
            document.instance.linkAttachments(linkedAttachments,
                true,
                questionDto.questionTemplateId as QuestionTemplateIdentifier,
                Identifier.create<QuestionIdentifier>(questionDto.businessId, questionDto.technicalId),
                parentSectionTemplateIdentifier,
                parentSectionIdentifier);
        } else {
            throw new AppException(FrontendErrors.FE27QuestionRequiredIdsMissing, $localize`:@@exception.fe27QuestionRequiredIdsMissing:Unable to apply a question value because at least one of the required IDs are missing (questionId: ${questionDto.technicalId}:questionId:, questionTemplateId: ${questionDto.questionTemplateId}:questionTemplateId:, sectionId: ${parentSectionIdentifier}:sectionId:, sectionTemplateId: ${parentSectionTemplateIdentifier}:sectionTemplateId:).`);
        }
    }

    public static walkthroughInspectionToDto(document: BuildingWaterWalkthroughInspectionDocument): WalkthroughInspectionDto {
        const dto: WalkthroughInspectionDto = {
            businessId: document.identifier.businessIdentifier,
            technicalId: document.identifier.technicalIdentifier,

            externalReference: document.externalReference,

            templateVersionId: document.template.technicalIdentifier,

            created: document.created,
            updated: document.updated,

            visitDate: DateTimeHelper.sanitizeDateTime(document.targetDate),

            status: this.documentStatusToDto(document.status),

            auditorId: document.assignedAuditor?.technicalIdentifier,

            additionalInfo: document.additionalInfo,
            reviewSummary: document.reviewSummary,

            buildingIds: []
        };

        for (const link of document.linkedEntities) {
            if (link.entityType == EntityTypes.building && link.identifier.technicalIdentifier) {
                dto.buildingIds?.push(link.identifier.technicalIdentifier);
            }
        }

        return dto;
    }

    public static documentQuestionToQuestionDto(question: DocumentQuestion): QuestionDto {
        const dto: QuestionDto = {
            questionTemplateId: question.templateIdentifier,
            answer: []
        };

        for (const answer of question.answers) {
            dto.answer!.push({
                value: answer.value !== undefined ? `${answer.value.value}` : undefined
            });
        }

        return dto;
    }

    public static documentSectionToSectionDto(section: DocumentSection): QuestionDto {
        const dto: QuestionDto = {
            businessId: section.identifier.businessIdentifier || undefined,
            technicalId: section.identifier.technicalIdentifier || undefined,
            questionTemplateId: section.templateIdentifier,
            repeatable: section.repeatable
        };
        dto.questions = [];

        if (section.sections.length > 0) {
            dto.questions = [];
            for (const subSection of section.sections) {
                dto.questions.push(this.documentSectionToSectionDto(subSection));
            }
        }

        if (section.questions.length > 0) {
            for (const question of section.questions) {
                dto.questions.push(this.documentQuestionToQuestionDto(question));
            }
        }

        return dto;
    }

    public static errorDtoToString(serverError: any): string {
        if (serverError.status && serverError.code && serverError.name && serverError.details) {
            // It is an error from the server we understand
            return `Server-Error ${serverError.code}/${serverError.name} (HTTP code ${serverError.status}): ${serverError.details} (${DateTimeHelper.utcNow()})`;
        }
        return serverError.message && serverError.status ? `${serverError.message} (${serverError.status})` : `${serverError} (${DateTimeHelper.utcNow()})`;
    }

    public static dtoToRoomFeatureMapping(dto: RoomRoomFeatureMappingDto|undefined): RoomFeatureMapping {
        return CoreDataFactory.createRoomFeatureMapping({
            identifier: Identifier.fromDto(dto),
            roomFeatureTechnicalIdentifier: dto?.roomFeatureId,
            name: dto?.name,
            order: dto?.order || 0,

            created: dto?.created,
            updated: dto?.updated
        });
    }

    public static dtoToRoomFeature(dto: RoomFeatureDto|undefined, translation?: string): RoomFeature {
        return CoreDataFactory.createRoomFeature(
            {
                identifier: {
                    businessIdentifier: dto?.businessId as EntityBusinessIdentifier,
                    technicalIdentifier: dto?.technicalId as EntityTechnicalIdentifier
                },
                created: dto?.created,
                updated: dto?.updated,
                name: dto?.name,
                title: translation,
                featureType: dto?.featureType,
                description: dto?.description
            }
        );
    }

    public static dtoToImageAttachment(dto: PhotoDto): ImageAttachment {
        const image: ImageAttachment = AttachmentFactory.createImageAttachment();
        image.identifier = Identifier.create(dto.businessId, dto.technicalId);
        image.state = AttachmentStates.metaUpdated;
        image.created = dto.created;
        image.updated = dto.updated;
        image.width = dto.width ?? 0;
        image.height = dto.height ?? 0;
        image.printQuality = dto.printQuality || environment.imageDefaultPrintQuality;
        image.description = dto.description;
        image.showInPdf = dto.showInPdf || false;
        image.createdClient = dto.createdOnDevice ?? DateTimeHelper.utcNow();
        image.linkedQuestions = dto.questionIds as Array<QuestionTechnicalIdentifier>;

        image.linkedDocuments = [];
        for (const documentTechnicalId of dto.documentIds ?? []) {
            image.linkedDocuments.push(Identifier.create(undefined, documentTechnicalId));
        }

        return image;
    }

    public static qualityAssuranceStatusToDto(state: QualityAssuranceStatus): QualityAssuranceStatusDto {
        switch (state) {
            case QualityAssuranceStatus.unknown:
                return QualityAssuranceStatusDto.Unknown;
            case QualityAssuranceStatus.accepted:
                return QualityAssuranceStatusDto.Accepted;
            case QualityAssuranceStatus.rejected:
                return QualityAssuranceStatusDto.Rejected;
            case QualityAssuranceStatus.implicitlyAccepted:
                return QualityAssuranceStatusDto.ImplicitlyAccepted;
            case QualityAssuranceStatus.rejectionIgnored:
                return QualityAssuranceStatusDto.RejectionIgnored;
            default:
                TypeScriptHelper.expectNever(state);
                return QualityAssuranceStatusDto.Unknown;
        }
    }

    public static dtoToQualityAssuranceStatus(dto?: QualityAssuranceStatusDto): QualityAssuranceStatus {
        switch (dto) {
            case QualityAssuranceStatusDto.Unknown:
                return QualityAssuranceStatus.unknown;
            case QualityAssuranceStatusDto.Accepted:
                return QualityAssuranceStatus.accepted;
            case QualityAssuranceStatusDto.Rejected:
                return QualityAssuranceStatus.rejected;
            case QualityAssuranceStatusDto.ImplicitlyAccepted:
                return QualityAssuranceStatus.implicitlyAccepted;
            case QualityAssuranceStatusDto.RejectionIgnored:
                return QualityAssuranceStatus.rejectionIgnored;
            default:
                return QualityAssuranceStatus.unknown;
        }
    }

    public static dtoToQualityAssuranceItem(dto?: QualityAssuranceDto): QualityAssuranceItem {
        const item: QualityAssuranceItem = QualityAssuranceFactory.createQualityAssuranceItem({
            identifier: Identifier.create<QualityAssuranceIdentifier>(dto?.businessId, dto?.technicalId),
            status: this.dtoToQualityAssuranceStatus(dto?.status),
            questionTechnicalIdentifier: dto?.questionId as QuestionTechnicalIdentifier
        });

        for (const message of dto?.messages ?? []) {
            item.messages.push(this.dtoToChatMessage(message));
        }

        return item;
    }

    public static dtoToChatMessage(dto?: ChatMessageDto): ChatMessage {
        return CommunicationFactory.createChatMessage(dto?.message ?? "", {
            personId: dto?.personId as PersonTechnicalIdentifier,
            created: dto?.created,
            updated: dto?.updated
        });
    }

    public static dtoToProcessType(type?: ProcessTypeEnumDto): ProcessTypes {
        switch (type) {
            case ProcessTypeEnumDto.WalkthroughInspection:
                return ProcessTypes.buildingWaterWalkthroughInspection;
            default:
                return ProcessTypes.unknown;
        }
    }

    public static processTypeToDto(type: ProcessTypes): ProcessTypeEnumDto|undefined {
        switch (type) {
            case ProcessTypes.buildingWaterWalkthroughInspection:
                return ProcessTypeEnumDto.WalkthroughInspection;
            case ProcessTypes.unknown:
                return undefined;
            default:
                TypeScriptHelper.expectNever(type);
        }
    }

    public static dtoToProcessStatus(status: ProcessStatusDto|undefined): ProcessStatus {
        switch (status) {
            case ProcessStatusDto.OrderEntry:
                return ProcessStatus.orderEntry;
            case ProcessStatusDto.Scheduled:
                return ProcessStatus.scheduled;
            case ProcessStatusDto.InProgress:
                return ProcessStatus.inProgress;
            case ProcessStatusDto.InReview:
                return ProcessStatus.inReview;
            case ProcessStatusDto.ReviewAccepted:
                return ProcessStatus.reviewAccepted;
            case ProcessStatusDto.ReviewRejected:
                return ProcessStatus.reviewRejected;
            case ProcessStatusDto.Done:
                return ProcessStatus.done;
            default:
                return ProcessStatus.unknown;
        }
    }

    public static processStatusToDto(status: ProcessStatus): ProcessStatusDto|undefined {
        switch (status) {
            case ProcessStatus.orderEntry:
                return ProcessStatusDto.OrderEntry;
            case ProcessStatus.scheduled:
                return ProcessStatusDto.Scheduled;
            case ProcessStatus.inProgress:
                return ProcessStatusDto.InProgress;
            case ProcessStatus.inReview:
                return ProcessStatusDto.InReview;
            case ProcessStatus.reviewAccepted:
                return ProcessStatusDto.ReviewAccepted;
            case ProcessStatus.reviewRejected:
                return ProcessStatusDto.ReviewRejected;
            case ProcessStatus.done:
                return ProcessStatusDto.Done;
            default:
                return undefined;
        }
    }

    public static dtoToProcess(dto: ProcessDto|undefined): Process {
        const process: Process = CoreDataFactory.createProcess({
            identifier: Identifier.create<ProcessIdentifier>(dto?.businessId, dto?.technicalId),
            created: dto?.created,
            updated: dto?.updated,
            processType: this.dtoToProcessType(dto?.processType),
            status: this.dtoToProcessStatus(dto?.status),
            linkedAssignee: dto?.assigneeId ? CoreDataFactory.createEntityLink(Identifier.create<BuildingComplexIdentifier>(dto?.assigneeId?.businessId, dto?.assigneeId?.technicalId), EntityTypes.person) : undefined,
            targetDate: dto?.visitDate,
            targetEndDate: dto?.visitEndDate,
            additionalInfo: dto?.additionalInfo
        });

        for (const documentId of dto?.documentIds ?? []) {
            const documentLink: EntityLink<AuditDocument> = CoreDataFactory.createEntityLink(Identifier.create<DocumentIdentifier>(documentId.businessId, documentId.technicalId), EntityTypes.document);
            process.linkedDocuments.push(documentLink);
        }

        if (dto?.buildingComplexId) {
            process.linkedEntities.push(CoreDataFactory.createEntityLink(Identifier.create<BuildingComplexIdentifier>(dto?.buildingComplexId?.businessId, dto?.buildingComplexId?.technicalId), EntityTypes.buildingComplex, undefined, { identifierPairPropertyName: "buildingComplex" }));
        }

        return process;
    }

    public static dtoToProcessesList(dto?: ResponseBodyProcessOverviewDto): Array<Process> {
        const result: Array<Process> = [];
        if (!dto?.processes) {
            return result;
        }

        for (const processDto of dto.processes) {
            const process: Process = this.dtoToProcess(processDto);

            result.push(process);
        }

        return result;
    }
}
