import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { ContextMenuItem } from "../../../../base/components/context-menu/context-menu-item";
import { DialogService } from "../../../../base/services/dialog/dialog.service";
import { Building } from "../../../entities/core-data/buildings/building";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../../entities/documents/entity-link";
import { EntityTypes } from "../../../entities/entity-types";
import { EntityHelper } from "../../../helpers/entity-helper";
import { AppIcons } from "../../../services/icons/app-icons";
import { MasterDataFormDialogOptions } from "./master-data-form-dialog-options";

/**
 * Dialog component to allow to view and edit master data.
 */
@Component({
    selector: "business-master-data-form-dialog",
    templateUrl: "./master-data-form-dialog.component.html",
    styleUrls: ["./master-data-form-dialog.component.scss"]
})
export class MasterDataFormDialogComponent implements OnInit {
    constructor(
        private readonly dialogService: DialogService,
        private readonly modalController: ModalController
    ) {
        // Do nothing for now
    }

    public readonly entityTypes: typeof EntityTypes = EntityTypes;

    public readonly entityHelper: typeof EntityHelper = EntityHelper;

    public readonly appIcons: typeof AppIcons = AppIcons;

    @Input()
    public options!: MasterDataFormDialogOptions;

    public loading: boolean = false;

    public entities: Array<GenericCoreDataEntity> = [];

    public additionalBuildings?: Array<EntityLink<Building>>;

    public isValid: boolean = false;

    public ngOnInit(): void {
        this.options = this.options ?? new MasterDataFormDialogOptions("- no title -");
        this.entities = this.options?.entities ?? [];

        this.additionalBuildings = this.options.additionalEntityLinks?.filter((link: EntityLink<GenericCoreDataEntity>) => link.entityType == EntityTypes.building) as Array<EntityLink<Building>>;
    }

    public save(): void {
        if (this.options.entities[0].entityType == EntityTypes.buildingComplex) {
            this.options.additionalEntityLinks = this.additionalBuildings;
        }

        this.close(this.options);
    }

    public cancel(): void {
        this.close();
    }

    public close(resultOptions?: MasterDataFormDialogOptions): void {
        this.modalController.dismiss(resultOptions).then();
    }

    public showPermissions(event: MouseEvent): void {
        const menuItems: Array<ContextMenuItem> = [];
        menuItems.push(new ContextMenuItem($localize`:@@permissions.permissionToCreate:Permission to create` as string, {
            icon: this.options.permissionCreate ? AppIcons.genericCheck : AppIcons.genericCancel,
            isLabel: true
        }));
        menuItems.push(new ContextMenuItem($localize`:@@permissions.permissionToUpdate:Permission to update` as string, {
            icon: this.options.permissionUpdate ? AppIcons.genericCheck : AppIcons.genericCancel,
            isLabel: true
        }));
        this.dialogService.showContextMenu(menuItems, event, false).then();
    }
}
