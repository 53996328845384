import { LicensesComponent } from "../../../components/licenses/licenses.component";
import { DialogService } from "../../dialog/dialog.service";
import { IocService } from "../../ioc/ioc.service";
import { Intent } from "../intent";

/**
 * Intent to show software licenses.
 */
export class ShowSoftwareLicensesIntent implements Intent {
    constructor(
        private readonly iocService: IocService
    ) {
    }

    private readonly dialogService: DialogService = this.iocService.resolve(DialogService);

    public get name(): string {
        return ShowSoftwareLicensesIntent.name;
    }

    public identifier?: number;

    public async execute(): Promise<boolean> {
        await this.dialogService.showDefaultModal($localize`:@@licenses.softwareLicensesTitle:Software licenses`, { component: LicensesComponent });
        return true;
    }

    public async cancel(): Promise<void> {
        // Do nothing
    }

}
