<form *ngIf="project" [formGroup]="formData" class="coreDataGroup">
    <mat-form-field [appearance]="formHelper.appearance(readonly)" class="titleField">
        <mat-label>
            <fa-icon [icon]="appIcons.projectTitle"></fa-icon>&nbsp;<ng-container i18n="@@project.title">Project title</ng-container>
        </mat-label>
        <input [formControl]="titleControl" [maxLength]="formSettings.maxLengthTitle" [readonly]="readonly" autocapitalize="on" matInput type="text">
        <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(titleControl, formSettings.maxLengthTitle)}}</mat-hint>
        <mat-error *ngIf="titleControl.invalid">{{getErrorMessage(titleControl)}}</mat-error>
    </mat-form-field>

    <div class="coreDataGroup">
        <ion-list-header>
            <ion-label i18n="@@project.linkedCompany">Company</ion-label>
            <ion-button (click)="linkCompany()" [disabled]="readonly" fill="outline">
                <fa-icon [icon]="appIcons.linkCompany"></fa-icon>&nbsp;<ng-container i18n="@@project.linkCompanyButton">Link company</ng-container>
            </ion-button>
        </ion-list-header>

        <business-entity-selection-list #companyList (selectedEntitiesChange)="companyLinksChanged($event)" [canDelete]="!readonly" [canEdit]="false" [canSelect]="false" [entityLinks]="companyLinks"></business-entity-selection-list>
    </div>

    <base-info-box [icon]="appIcons.informationCircle" [marginTop]="true" boxStyle="subtle" header="Additional information" i18n="@@project.additionalInformation" i18n-header="@@dialog.additionalInformationTitle">
        A project is directly linked to a company whereas a company can have multiple projects.<br />
        <br />
        The project title is optional and can help you to organize multiple projects for a single company. If you leave it empty the project is displayed with the company name as title.<br />
        <br />
        The project can hold a list of multiple processes. A process is usually a timed job, e.g., if you repeat an audit regularly every year you can create a process for each year.
    </base-info-box>
</form>
