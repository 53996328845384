import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { ChatMessageStorageDto } from "../communication/chat-message-storage-dto";

/**
 * Storage DTO for {@link QualityAssuranceItem}.
 */
export class QualityAssuranceItemStorageDto {
    constructor(init?: Partial<QualityAssuranceItemStorageDto>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public businessId?: string;
    public technicalId?: number;

    public questionTechnicalIdentifier?: number;

    public status?: string;
    public messages?: Array<ChatMessageStorageDto> = [];

}
