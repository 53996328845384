import { Injectable } from "@angular/core";

import { environment } from "../../../../../environments/environment";
import { Api1Converter } from "../../../../api/v1/converters/api1-converter";
import { BuildingComplexDto, BuildingDto, RoomFeatureDto } from "../../../../generated/api";
import { errorResult, SafeResult, successResult } from "../../../common/safe-result";
import { Building } from "../../../entities/core-data/buildings/building";
import { BuildingComplex } from "../../../entities/core-data/buildings/building-complex";
import { RoomFeature } from "../../../entities/core-data/buildings/room-feature";
import { RoomFeatureStorageDto } from "../../../entities/core-data/buildings/room-feature-storage-dto";
import { Person } from "../../../entities/core-data/person";
import { EntityTypes } from "../../../entities/entity-types";
import { AppException } from "../../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../../global/frontend-errors";
import { BuildingBusinessIdentifier } from "../../../identifiers/building-business-identifier";
import { BuildingComplexBusinessIdentifier } from "../../../identifiers/building-complex-business-identifier";
import { BuildingComplexTechnicalIdentifier } from "../../../identifiers/building-complex-technical-identifier";
import { BuildingsApiService } from "../../api/buildings-api.service";
import { BackendTranslationService } from "../../localization/backend-translation.service";
import { BackendTranslationUpdateSession } from "../../localization/backend-translation-update.session";
import { StorageService } from "../../storage/storage.service";
import { StorageKeys } from "../../storage/storage-keys";
import { CoreDataFactory } from "../core-data-factory";
import { CoreDataService } from "../core-data-service/core-data.service";

/**
 * Service to work with buildings.
 */
@Injectable({
    providedIn: "root"
})
export class BuildingsService {
    constructor(
        private readonly buildingsApiService: BuildingsApiService,
        private readonly backendTranslationService: BackendTranslationService,
        private readonly coreDataService: CoreDataService,
        private readonly storageService: StorageService
    ) {
    }

    private cachedRoomFeatures?: Array<RoomFeature>;

    private async loadCachedRoomFeatures(): Promise<void> {
        const storageDtos: Array<RoomFeatureStorageDto>|undefined = await this.storageService.get(StorageKeys.roomFeatures);
        if (!storageDtos) {
            return;
        }

        this.cachedRoomFeatures = [];
        for (const storageDto of storageDtos) {
            const roomFeature: RoomFeature|undefined = CoreDataFactory.createFromStorageDto(storageDto);
            if (roomFeature) {
                this.cachedRoomFeatures.push(roomFeature);
            }
        }
    }

    private async saveCachedRoomFeatures(): Promise<void> {
        const storageDtos: Array<RoomFeatureStorageDto> = [];
        for (const cachedRoomFeature of this.cachedRoomFeatures ?? []) {
            storageDtos.push(cachedRoomFeature.toStorageDto());
        }
        await this.storageService.set(StorageKeys.roomFeatures, storageDtos);
    }

    public async getRoomFeatures(forceLoad: boolean = false): Promise<Array<RoomFeature>>|never {
        if (this.cachedRoomFeatures && !forceLoad) {
            return this.cachedRoomFeatures;
        }

        if (!forceLoad) {
            await this.loadCachedRoomFeatures();
            if (this.cachedRoomFeatures) {
                return this.cachedRoomFeatures;
            }
        }

        const translationSession: BackendTranslationUpdateSession = new BackendTranslationUpdateSession();

        const result: Array<RoomFeature> = [];
        const featureDtos: Array<RoomFeatureDto> = await this.buildingsApiService.getRoomFeatures();
        for (const featureDto of featureDtos) {
            const translation: string|undefined = await this.backendTranslationService.getTranslation(featureDto.name, translationSession);
            result.push(Api1Converter.dtoToRoomFeature(featureDto, translation));
        }

        this.sortRoomFeatures(result);

        this.cachedRoomFeatures = result;
        await this.saveCachedRoomFeatures();
        return result;
    }

    private sortRoomFeatures(roomFeatures: Array<RoomFeature>): void {
        roomFeatures.sort((b: RoomFeature, a: RoomFeature) => (a.title ?? "ZZZ").localeCompare(b.title ?? "ZZZ"));
    }

    public async getBuildingComplexes(searchQuery?: string, limit: number = environment.searchDefaultResultsCount): Promise<Array<BuildingComplex>>|never {
        const buildingComplexDtos: Array<BuildingComplexDto> = await this.buildingsApiService.getBuildingComplexes(searchQuery, limit);
        const result: Array<BuildingComplex> = [];
        for (const buildingDto of buildingComplexDtos) {
            const buildingComplex: BuildingComplex = Api1Converter.dtoToBuildingComplex(buildingDto);
            await this.updateLinkedPersons(buildingComplex);
            await this.coreDataService.updateEntityCache(buildingComplex);
            result.push(buildingComplex);
        }
        return result;
    }

    public async getBuildings(buildingComplexTechnicalIdentifier: BuildingComplexTechnicalIdentifier): Promise<SafeResult<Array<Building>, AppException>> {
        try {
            const buildingDtos: Array<BuildingDto> = await this.buildingsApiService.getBuildings(buildingComplexTechnicalIdentifier);
            const result: Array<Building> = [];
            for (const buildingDto of buildingDtos) {
                const building: Building = Api1Converter.dtoToBuilding(buildingDto);
                await this.coreDataService.updateEntityCache(building);
                result.push(building);
            }
            return successResult(result);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE58UnableToDeleteBuilding, $localize`:@@exception.fe66UnableToLoadBuildingsFromBuildingComplex:Unable to load buildings from building complex with id "${buildingComplexTechnicalIdentifier}".`, error));
        }
    }

    public async updateLinkedPersons(building: BuildingComplex): Promise<void> {
        for (const personLink of building.personLinks) {
            if (!personLink.entity) {
                personLink.entity = await this.coreDataService.loadEntity<Person>(personLink.identifier, EntityTypes.person);
            }
        }
    }

    public async deleteBuilding(businessIdentifier: BuildingBusinessIdentifier): Promise<SafeResult<void, AppException>> {
        try {
            await this.buildingsApiService.deleteBuilding(businessIdentifier);
            return successResult(undefined);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE58UnableToDeleteBuilding, $localize`:@@exception.fe58UnableToDeleteBuilding:Unable to delete building with identifier "${businessIdentifier}".`, error));
        }
    }

    public async deleteBuildingComplex(businessIdentifier: BuildingComplexBusinessIdentifier): Promise<SafeResult<void, AppException>> {
        try {
            await this.buildingsApiService.deleteBuildingComplex(businessIdentifier);
            return successResult(undefined);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE59UnableToDeleteBuildingComplex, $localize`:@@exception.fe59UnableToDeleteBuildingComplex:Unable to delete building complex with identifier "${businessIdentifier}".`, error));
        }
    }
}
