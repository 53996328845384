/**
 * Item types like choices, text-input, image-input.
 */
export enum DocumentQuestionTypes {
    unknown = "unknown",
    text = "text",
    numeric = "numeric",
    date = "date",
    dateTime = "dateTime",
    time = "time",
    spacer = "spacer",
    choice = "choice",
    multipleChoice = "multipleChoice",

    singleImage = "singleImage",

    container = "container",

    customer = "customer"
}
