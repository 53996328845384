import { ArrayHelper } from "../../../base/helpers/array-helper";
import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { CompanyBusinessIdentifier } from "../../identifiers/company-business-identifier";
import { CompanyTechnicalIdentifier } from "../../identifiers/company-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { EntityLink } from "../documents/entity-link";
import { EntityTypes } from "../entity-types";
import { Address } from "./address";
import { CompanyStorageDto } from "./company-storage-dto";
import { CoreDataEntity } from "./core-data-entity";
import { Person } from "./person";

/**
 * Basic company class.
 */
export class Company extends CoreDataEntity<CompanyBusinessIdentifier, CompanyTechnicalIdentifier> {
    constructor(init?: Partial<Company>) {
        super(EntityTypes.company);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public name?: string;

    public email?: string;

    public address?: Address;

    public personLinks: Array<EntityLink<Person>> = [];

    public get displayTitle(): string {
        return ArrayHelper.removeEmpty([this.name, this.address?.displayTitle]).join(", ") || Identifier.identifierToString(this.identifier);
    }

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    protected toEntityStorageDto(): CompanyStorageDto {
        const dto: CompanyStorageDto = new CompanyStorageDto({
            name: this.name,
            email: this.email,

            address: this.address?.toStorageDto()
        });

        dto.personLinks = [];
        for (const personLink of this.personLinks) {
            dto.personLinks.push(personLink.toStorageDto());
        }

        return dto;
    }

    protected fromEntityStorageDto(dto: CompanyStorageDto|undefined): void {
        this.name = dto?.name;
        this.email = dto?.email;

        this.address = dto?.address ? new Address() : undefined;
        this.address?.fromStorageDto(dto?.address);

        for (const personLinkDto of dto?.personLinks ?? []) {
            this.personLinks.push(CoreDataFactory.createEntityLinkFromStorageDto(personLinkDto) as EntityLink<Person>);
        }
    }

    public clone(): Company {
        const clone: Company = CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
        clone.personLinks = [];
        for (const personLink of this.personLinks) {
            const personLinkClone: EntityLink<Person> = CoreDataFactory.createEntityLinkFromStorageDto(personLink.toStorageDto()) as EntityLink<Person>;
            personLinkClone.entity = personLink.entity?.clone();
            clone.personLinks.push(personLinkClone);
        }
        return clone;
    }
}
