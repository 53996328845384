import { Injectable } from "@angular/core";

import { EventHelper } from "../../../base/helpers/event-helper";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { FileAttachment } from "../../entities/attachments/file-attachment";
import { BackendErrors } from "../../global/backend-errors";
import { AttachmentLoaderService } from "../attachments/attachment-loader.service";

/**
 * This service listens to error events and displays a message to the user.
 */
@Injectable({
    providedIn: "root"
})
export class AsynchronousErrorNotifierService {
    constructor(
        private readonly attachmentLoaderService: AttachmentLoaderService,
        private readonly dialogService: DialogService
    ) {
        EventHelper.subscribe(this.attachmentLoaderService.unrecoverableUploadError, this.unrecoverableUploadError, this);
    }

    public initialize(): void {
        // Do nothing for now
    }

    private unrecoverableUploadError(attachment: FileAttachment): void {
        if (!attachment.uploadError?.code) {
            return;
        }

        if (attachment.uploadError?.code == BackendErrors.BE43UnsupportedMimeType) {
            this.dialogService.showAlert($localize`:@@error.fileUploadFailed:File upload not possible`, $localize`:@@error.fileUploadMimeTypeFiledMessage:The file "${attachment.filename}" could not be uploaded because the file type is not accepted or not allowed. The uploaded filetype was "${attachment.uploadError.details}".`).then();
        }
    }
}
