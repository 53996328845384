/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProcessStatusDto = 'orderEntry' | 'scheduled' | 'inProgress' | 'inReview' | 'reviewAccepted' | 'reviewRejected' | 'done';

export const ProcessStatusDto = {
    OrderEntry: 'orderEntry' as ProcessStatusDto,
    Scheduled: 'scheduled' as ProcessStatusDto,
    InProgress: 'inProgress' as ProcessStatusDto,
    InReview: 'inReview' as ProcessStatusDto,
    ReviewAccepted: 'reviewAccepted' as ProcessStatusDto,
    ReviewRejected: 'reviewRejected' as ProcessStatusDto,
    Done: 'done' as ProcessStatusDto
};

