import { GenericCoreDataEntity } from "../../business/entities/core-data/generic-core-data-entity";
import { EntityHelper } from "../../business/helpers/entity-helper";
import { EntityBusinessIdentifier } from "../../business/identifiers/entity-business-identifier";
import { EntityTechnicalIdentifier } from "../../business/identifiers/entity-technical-identifier";

/**
 * Helper class for the API v1.
 */
export class Api1Helpers {
    public static findByIdentifierPair<TEntity extends GenericCoreDataEntity>(list?: Array<TEntity>, businessId?: EntityBusinessIdentifier, technicalId?: EntityTechnicalIdentifier): TEntity|undefined {
        if (!list || list.length <= 0) {
            return undefined;
        }

        const possibleItems: Array<TEntity> = [];

        for (const entity of list) {
            if (!technicalId && businessId && entity.identifier.businessIdentifier && entity.identifier.businessIdentifier == businessId) {
                possibleItems.push(entity);
            }
            if (technicalId && entity.identifier.technicalIdentifier && entity.identifier.technicalIdentifier == technicalId) {
                if (!businessId || !entity.identifier.businessIdentifier || entity.identifier.businessIdentifier == businessId) {
                    possibleItems.push(entity);
                }
            }
        }

        if (possibleItems.length <= 0) {
            return undefined;
        }

        return EntityHelper.getLatestByCreatedAndUpdatedTime(possibleItems);
    }
}
