import { DocumentTypes } from "../../../entities/documents/document-types";
import { DocumentIdentifier } from "../../../identifiers/document-identifier";
import { PersonBusinessIdentifier } from "../../../identifiers/person-business-identifier";
import { DocumentUpdateTypes } from "../../documents/document-update-types";
import { PartialDocumentUpdate } from "./partial-document-update";
import { PartialDocumentUpdateStorageDto } from "./partial-document-update-storage-dto";

/**
 * Update to create a new revision.
 */
export class NewDocumentRevisionUpdate extends PartialDocumentUpdate {
    public constructor(accountIdentifier: number, personBusinessIdentifier: PersonBusinessIdentifier, documentId: DocumentIdentifier, documentType: DocumentTypes) {
        super(accountIdentifier, personBusinessIdentifier, documentId, documentType);

        this.noBatchUpdate = true;
    }

    public readonly updateType: DocumentUpdateTypes = DocumentUpdateTypes.newRevision;

    protected partialUpdateFromStorageDto(_dto: PartialDocumentUpdateStorageDto|undefined): void {
        // There's nothing to do
    }

    protected partialUpdateToStorageDto(): PartialDocumentUpdateStorageDto {
        return {} as PartialDocumentUpdateStorageDto;
    }
}
