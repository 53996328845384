<base-page-content-container *ngIf="loading" class="loading">
    <h2 i18n="@@storageInfo.loading">Loading...</h2>
    <base-loading></base-loading>
</base-page-content-container>

<base-page-content-container *ngIf="!loading">
    <ion-list>
        <ion-item *ngIf="storageEstimate?.usage">
            <ion-label i18n="@@storageInfo.totalUsage">Total usage</ion-label>
            <p>{{(storageEstimate!.usage! / (1048576)) | number:'1.1-1' }} MiB</p>
        </ion-item>
        <ion-item *ngIf="datasets">
            <ion-label i18n="@@storageInfo.datasets">Datasets</ion-label>
            <p>{{datasets | number:'1.0-0' }}</p>
        </ion-item>
        <ion-item *ngIf="storageEstimateAsAny?.usageDetails?.caches">
            <ion-label i18n="@@storageInfo.caches">Caches</ion-label>
            <p>{{(storageEstimateAsAny.usageDetails!.caches! / (1048576)) | number:'1.1-1' }} MiB</p>
        </ion-item>
        <ion-item *ngIf="storageEstimateAsAny?.usageDetails?.indexedDB">
            <ion-label i18n="@@storageInfo.indexedDB">Database</ion-label>
            <p>{{(storageEstimateAsAny.usageDetails!.indexedDB! / (1048576)) | number:'1.1-1' }} MiB</p>
        </ion-item>
        <ion-item *ngIf="storageEstimateAsAny?.usageDetails?.serviceWorkerRegistrations">
            <ion-label i18n="@@storageInfo.serviceWorker">Service worker</ion-label>
            <p>{{(storageEstimateAsAny.usageDetails!.serviceWorkerRegistrations! / (1048576)) | number:'1.1-1' }} MiB</p>
        </ion-item>
        <ion-item *ngIf="storageEstimate?.quota">
            <ion-label i18n="@@storageInfo.quota">Available (Quota)</ion-label>
            <p>{{(storageEstimate!.quota! / (1048576)) | number:'1.1-1' }} MiB</p>
        </ion-item>
        <ion-item>
            <ion-label i18n="@@storageInfo.locallyStoredFilesCount">Locally stored files</ion-label>
            <p>{{storedFiles}}</p>
        </ion-item>
        <ion-item>
            <base-process-button (triggered)="loadKeys($event)">Load keys</base-process-button>
        </ion-item>
        <ion-item *ngFor="let key of keys">
            <ion-label>{{key}}</ion-label>
        </ion-item>
    </ion-list>
</base-page-content-container>
