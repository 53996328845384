import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { IntentOptions } from "../../../base/services/intents/intent-options";
import { PrivacyPolicyContents } from "./privacy-policy-contents";
import { ShowPrivacyPolicyIntent } from "./show-privacy-policy-intent";

/**
 * Options for {@link ShowPrivacyPolicyIntent}.
 */
export class ShowPrivacyPolicyIntentOptions implements IntentOptions<ShowPrivacyPolicyIntent> {
    constructor(content: PrivacyPolicyContents, init?: Partial<ShowPrivacyPolicyIntentOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.content = content;
    }

    public content: PrivacyPolicyContents;
}
