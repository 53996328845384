import { Component, OnDestroy, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Subscription } from "rxjs";

import { EventHelper } from "../../../base/helpers/event-helper";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { IntentsService } from "../../../base/services/intents/intents.service";
import { PersonDto } from "../../../generated/api";
import { LoginIntent } from "../../intents/login-intent";
import { LogoutIntent } from "../../intents/logout-intent";
import { EditOwnPersonIntent } from "../../intents/master-data/edit-own-person-intent";
import { RegisterIntent } from "../../intents/register-intent";
import { AppService } from "../../services/app/app.service";
import { AppState } from "../../services/app/app-state";
import { OnlineHelpService } from "../../services/help/online-help.service";
import { AppIcons } from "../../services/icons/app-icons";
import { SessionService } from "../../services/session/session.service";
import { SessionChangedEventData } from "../../services/session/session-changed-event-data";
import { AboutComponent } from "../about/about.component";
import { AccountSwitchPopoverComponent } from "../account-switch-popover/account-switch-popover.component";
import { AppSettingsComponent } from "../settings/app-settings/app-settings.component";

/**
 * The popover component that is shown when clicking on the profile picture in the top bar.
 */
@Component({
    selector: "app-account-popover",
    templateUrl: "./account-popover.component.html",
    styleUrls: ["./account-popover.component.scss"]
})
export class AccountPopoverComponent implements OnInit, OnDestroy {
    constructor(
        private readonly intentService: IntentsService,
        private readonly sessionService: SessionService,
        private readonly dialogService: DialogService,
        private readonly appService: AppService,
        private readonly onlineHelpService: OnlineHelpService,
        private readonly popoverController: PopoverController
    ) {
        this.appState = this.appService.appState;
    }

    public activePerson?: PersonDto;

    private sessionChangedSubscription?: Subscription;

    public readonly appIcons: typeof AppIcons = AppIcons;

    public appState: AppState;

    public close(): void {
        this.popoverController.dismiss().then();
    }

    public logIn(): void {
        this.intentService.executeIntent(LoginIntent);
        this.close();
    }

    public logOut(): void {
        this.intentService.executeIntent(LogoutIntent);
        this.close();
    }

    public register(): void {
        this.intentService.executeIntent(RegisterIntent);
        this.close();
    }

    public openAppSettings(): void {
        this.dialogService.showModal({
            component: AppSettingsComponent
        }).then();

        this.close();
    }

    public openAbout(): void {
        this.dialogService.showModal({
            component: AboutComponent
        }).then();

        this.close();
    }

    public openProfileSettings(): void {
        this.intentService.executeIntent(EditOwnPersonIntent);
        this.close();
    }

    public openAccountSwitchMenu(event: MouseEvent): void {
        event.stopPropagation();

        this.dialogService.showPopover({
            component: AccountSwitchPopoverComponent,
            event: event
        }).then();

        this.close();
    }

    public openSupport(): void {
        this.onlineHelpService.openHelp();
        this.close();
    }

    public openChangelog(): void {
        this.onlineHelpService.openUpdateHistory();
        this.close();
    }

    public ngOnInit(): void {
        this.activePerson = this.sessionService.activePerson;
        this.sessionChangedSubscription = EventHelper.subscribe(this.sessionService.sessionChanged, this.sessionChanged, this);
    }

    public ngOnDestroy(): void {
        this.sessionChangedSubscription = EventHelper.unsubscribe(this.sessionChangedSubscription);
    }

    private sessionChanged(sessionData: SessionChangedEventData): void {
        this.activePerson = sessionData.activePerson;
    }

    public installPwa(): void {
        this.appService.installPwa();
    }
}
