<ion-grid *ngIf="entityLinks">
    <ng-container *ngFor="let entityLink of entityLinks">
        <ion-row>
            <ion-col [size]="hasRole ? 6 : 12">
                <ion-item>
                    <ion-label position="stacked">
                        <ng-container i18n="@@entities.linkedEntityNamePlaceholder">Name</ng-container>
                    </ion-label>
                    <ion-input autocapitalize="on" [readonly]="true" [value]="entityLink.entity ? entityLink.entity.displayTitle : entityLink.identifier.businessIdentifier"></ion-input>
                    <ion-buttons slot="end" *ngIf="entityLink.entity && !readonly">
                        <ion-button fill="clear" color="dark" (click)="editLinkedEntity(entityLink)">
                            <fa-icon [icon]="appIcons.genericEdit"></fa-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-item>
            </ion-col>
            <ion-col size="6" class="entityRole" *ngIf="hasRole">
                <ion-item>
                    <ion-label position="stacked">
                        <ng-container i18n="@@entities.linkedEntityRolePlaceholder">Role</ng-container>
                    </ion-label>
                    <ion-input autocapitalize="on" value="{{entityLink.linkDescription}}" (ionChange)="linkDescriptionChanged(entityLink, $event)" [disabled]="readonly"></ion-input>
                    <ion-buttons slot="end">
                        <ion-button fill="clear" color="dark" *ngIf="entityLink.latestEntity && !entityLink.latestEntity.identifier.technicalIdentifier" [disabled]="true">
                            <fa-icon [icon]="appIcons.entityUpdateCheck" [spin]="true"></fa-icon>
                        </ion-button>
                        <ion-button fill="clear" color="dark" (click)="deleteLink(entityLink)" *ngIf="!readonly">
                            <fa-icon [icon]="appIcons.genericDelete"></fa-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="entityLink.latestEntity?.identifier?.technicalIdentifier" class="updateAvailable">
            <ion-col size="12">
                <fa-icon [icon]="appIcons.entityUpdateAvailable"></fa-icon>&nbsp;<ng-container i18n="@@entities.updateAvailable">{{entityLink.entity ? entityLink.entity.displayTitle : entityLink.identifier.businessIdentifier}} has a newer version available.</ng-container>
                &nbsp;<span class="underline clickable" (click)="updateLink(entityLink)" i18n="@@entities.linkToLatestVersion" *ngIf="!readonly">Link to latest version</span>
            </ion-col>
        </ion-row>
    </ng-container>
</ion-grid>
