import { EventEmitter, Injectable } from "@angular/core";
import { SwUpdate, VersionEvent, VersionReadyEvent } from "@angular/service-worker";
import { Subscription } from "rxjs";

import { EventHelper } from "../../helpers/event-helper";
import { TimeService } from "../time/time.service";
import { Timer } from "../time/timer";

/**
 * Service to check for service worker updates.
 */
@Injectable({
    providedIn: "root"
})
export class AppUpdateService {
    constructor(
        private readonly timeService: TimeService,
        private readonly softwareUpdateService: SwUpdate
    ) {
        this.softwareUpdateService.versionUpdates.subscribe(this.versionInfoChanged.bind(this));
    }

    private updateCheckTimer?: Timer;

    private updateCheckTimerSubscription?: Subscription;

    private checking: boolean = false;

    private updateNotified: boolean = false;

    public updateAvailable: EventEmitter<VersionReadyEvent|undefined> = new EventEmitter<VersionReadyEvent|undefined>();

    public async initialize(): Promise<boolean> {
        // This method is used to force create this instance
        return true;
    }

    private versionInfoChanged(versionEvent: VersionEvent): void {
        if (versionEvent.type == "VERSION_READY") {
            console.info("New update ready.", versionEvent);
            this.setUpdateAvailable(versionEvent);
        }
    }

    public startUpdateCheck(interval: number, startImmediately: boolean): void {
        if (this.updateCheckTimer) {
            return;
        }

        if (!this.softwareUpdateService.isEnabled) {
            console.info("Update check not started, service worker is not enabled.");
            return;
        }

        this.updateCheckTimer = this.timeService.spawnTimer(interval);
        this.updateCheckTimerSubscription = EventHelper.subscribe(this.updateCheckTimer.elapsed, this.updateCheckTimerElapsed, this);
        this.updateCheckTimer.start();

        if (startImmediately) {
            this.checkForUpdates().then();
        }
    }

    private updateCheckTimerElapsed(): void {
        this.checkForUpdates().then();
    }

    private async checkForUpdates(): Promise<void> {
        if (this.checking) {
            return;
        }

        try {
            this.checking = true;

            const updateAvailable: boolean = await this.softwareUpdateService.checkForUpdate();
            if (updateAvailable) {
                console.info("Update available, installing...");
                await this.softwareUpdateService.activateUpdate();
                console.info("New version applied.");
                this.setUpdateAvailable();
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.checking = false;
        }
    }

    private setUpdateAvailable(versionReadyEvent?: VersionReadyEvent): void {
        if (this.updateNotified) {
            return;
        }
        this.updateNotified = true;

        this.updateCheckTimerSubscription = EventHelper.unsubscribe(this.updateCheckTimerSubscription);
        this.updateCheckTimer?.stop();
        this.updateCheckTimer = undefined;

        this.updateAvailable.emit(versionReadyEvent);
    }
}
