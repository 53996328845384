<form *ngIf="buildingComplex" [formGroup]="formData" enter-to-tab>
    <div class="coreDataGroup">
        <mat-form-field [appearance]="formHelper.appearance(formReadonly || !externalReferenceEditable)" class="externalReferenceField">
            <mat-label>
                <fa-icon [icon]="appIcons.externalReference"></fa-icon>&nbsp;<ng-container i18n="@@buildingComplex.externalReference">Reference (Building complex identifier)</ng-container>
            </mat-label>
            <input #nextTab [formControl]="externalReferenceControl" [maxlength]="formSettings.maxLengthReference" [readonly]="formReadonly || !externalReferenceEditable" autocapitalize="on" autocomplete="new-id" matInput type="text">
            <button (click)="externalReferenceEditable = true" *ngIf="!formReadonly && !externalReferenceEditable" mat-button matSuffix>
                <fa-icon [icon]="appIcons.genericLocked"></fa-icon>
                <label i18n="@@form.unlockField">Unlock</label>
            </button>
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(externalReferenceControl, formSettings.maxLengthReference)}}</mat-hint>
            <mat-error *ngIf="externalReferenceControl.invalid">{{getErrorMessage(externalReferenceControl)}}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="formHelper.appearance(formReadonly)" class="nameField">
            <mat-label>
                <fa-icon [icon]="appIcons.buildingName"></fa-icon>&nbsp;<ng-container i18n="@@building.name">Building name</ng-container>
            </mat-label>
            <input #nextTab [formControl]="nameControl" [maxlength]="formSettings.maxLengthName" [readonly]="formReadonly" autocapitalize="on" autocomplete="new-building-name" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(nameControl, formSettings.maxLengthName)}}</mat-hint>
            <mat-error *ngIf="nameControl.invalid">{{getErrorMessage(nameControl)}}</mat-error>
        </mat-form-field>
    </div>

    <div class="coreDataGroup">
        <mat-button-toggle-group #addressGroup="matButtonToggleGroup" [value]="isAddressEmpty() ? 'addressRaw' : 'address'">
            <mat-button-toggle value="addressRaw">
                <fa-icon [icon]="appIcons.addressRaw"></fa-icon>&nbsp;<ng-container i18n="@@buildingComplex.rawAddress">Raw address</ng-container>
            </mat-button-toggle>
            <mat-button-toggle value="address">
                <fa-icon [icon]="appIcons.entityAddressRegular"></fa-icon>&nbsp;<ng-container i18n="@@buildingComplex.detailedAddress">Detailed address</ng-container>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div class="marginTop"></div>
        <mat-form-field [appearance]="formHelper.appearance(formReadonly)" [hidden]="addressGroup.value != 'addressRaw'" class="addressRawField">
            <mat-label>
                <fa-icon [icon]="appIcons.addressRaw"></fa-icon>&nbsp;<ng-container i18n="@@buildingComplex.rawAddress">Raw address</ng-container>
            </mat-label>
            <textarea #nextTab [formControl]="addressRawControl" [maxlength]="formSettings.maxLengthRawAddress" [readonly]="formReadonly" autocapitalize="on" autocomplete="new-street-address" matInput type="text"></textarea>
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(addressRawControl, formSettings.maxLengthRawAddress)}}</mat-hint>
            <mat-error *ngIf="addressRawControl.invalid">{{getErrorMessage(addressRawControl)}}</mat-error>
        </mat-form-field>

        <business-address-form #nextTab (isValidChange)="updateValidState()" *ngIf="buildingComplex.address" [address]="buildingComplex.address" [hidden]="addressGroup.value != 'address'" [readonly]="formReadonly"></business-address-form>
    </div>

    <div *ngIf="showBuildings && buildings" class="coreDataGroup">
        <ion-list-header>
            <ion-label i18n="@@buildingComplex.linkedBuildings">Buildings</ion-label>
            <ion-button (click)="addBuilding()" [disabled]="readonly" fill="outline">
                <fa-icon [icon]="appIcons.linkBuilding"></fa-icon>&nbsp;<ng-container i18n="@@buildingComplex.addBuildingButton">Add a building</ng-container>
            </ion-button>
        </ion-list-header>

        <business-entity-selection-list #buildingList (deleteEntityRequested)="deleteBuilding($event)" (entityModified)="buildingModified($event)" (selectedEntitiesChange)="buildingLinksChanged($event)" [canDelete]="!readonly" [canEdit]="!readonly" [canOpen]="true" [canSelect]="false" [entityLinks]="buildings" [parentEntity]="buildingComplex"></business-entity-selection-list>
    </div>
</form>
