<div (click)="imageClicked($event)" (contextmenu)="openSettings($event, true)" [class.cover]="cover" [class.showDescription]="description && attachment?.description" class="root" title="{{attachment?.description}}">
    <fa-icon *ngIf="loading" [icon]="appIcons.imageLoading" [spin]="true" class="loading" size="lg"></fa-icon>
    <fa-icon *ngIf="loadError && !noInternetError" [icon]="appIcons.imageLoadError" class="loadError" size="lg"></fa-icon>
    <fa-icon *ngIf="noInternetError && !loadError" [icon]="appIcons.noInternet" class="loadError" size="lg"></fa-icon>
    <fa-icon *ngIf="emptyPlaceholder && !imageLoaded && !loading && !loadError && !noInternetError" [icon]="appIcons.imagePlaceholder" class="imagePlaceholder" size="4x"></fa-icon>

    <div #zoomElement (dblclick)="doubleClick($event)" (mousedown)="zoomMouseDown($event)" (mouseenter)="zoomMouseEnter()" (mouseleave)="zoomMouseLeave()" (mousemove)="zoomMouseMove($event)" (mouseup)="zoomMouseUp()" (touchend)="pinchTouchEnd($event)" (touchmove)="pinchTouchMove($event)" (touchstart)="pinchTouchStart($event)" (wheel)="zoomMouseWheel($event)" *ngIf="attachment" class="zoom">
        <div #imageElement *ngIf="attachment.type == attachmentTypes.image" class="image"></div>
    </div>

    <ion-buttons *ngIf="attachment && (settings || allowDownload) && !errorState" class="settingsButtons">
        <ion-button (click)="openSettings($event, false)" *ngIf="attachment.printQuality != defaultPrintQuality" color="dark" fill="solid">
            <fa-icon [icon]="getPrintQualityIcon()"></fa-icon>
        </ion-button>
        <ion-button (click)="openSettings($event, false)" *ngIf="attachment.showInPdf" color="dark" fill="solid">
            <fa-icon [icon]="appIcons.previewDocumentPdf"></fa-icon>
        </ion-button>
        <ion-button (click)="openSettings($event, false)" color="primary" fill="solid">
            <fa-icon [icon]="appIcons.imageSettings" size="lg"></fa-icon>
        </ion-button>
    </ion-buttons>

    <div *ngIf="attachment?.description" class="description">
        {{attachment?.description}}
    </div>

    <ng-container *ngIf="attachment && attachment.state != attachmentStates.metaUpdated && !attachment.replacedByExistingAttachment" class="working">
        <ion-progress-bar [value]="attachment.uploadProgress"></ion-progress-bar>
        <fa-icon [icon]="attachmentStateToIcon(attachment)" class="statusIcon"></fa-icon>
        <fa-icon *ngIf="!errorState" [icon]="appIcons.genericLoading" [spin]="true" class="workingIcon"></fa-icon>
    </ng-container>
    <fa-icon *ngIf="attachment && attachment.state == attachmentStates.metaUpdated && wasUploadingWhenOpened" [icon]="appIcons.attachmentStateMetaUpdated" class="workingIcon fadeOutAfterShown"></fa-icon>
</div>
