import { Injectable } from "@angular/core";

import { DefaultService } from "../../../generated/api";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";

/**
 * The service to talk to the server backend.
 */
@Injectable({
    providedIn: "root"
})
export class ApiService {
    constructor(
        private readonly storageService: StorageService,
        private readonly defaultService: DefaultService
    ) {
    }

    public async initialize(): Promise<void> {
        this.defaultService.configuration.basePath = await this.storageService.get(StorageKeys.apiUrl, this.defaultService.configuration.basePath);
        this.defaultService.configuration.withCredentials = true;
    }

    public getUsername(): string|undefined {
        return this.defaultService.configuration.username;
    }

    public getApiUrl(): string|undefined {
        return this.defaultService.configuration.basePath;
    }

    public setApiUrl(apiUrl: string): void {
        this.defaultService.configuration.basePath = apiUrl;
        this.storageService.set(StorageKeys.apiUrl, apiUrl).then();
    }
}
