import { Injectable } from "@angular/core";

import { LoggingService } from "../../../business/services/logging/logging.service";
import { IocService } from "../ioc/ioc.service";
import { Intent } from "./intent";
import { IntentOptions } from "./intent-options";

/**
 * The service to trigger intents.
 */
@Injectable({
    providedIn: "root"
})
export class IntentsService {
    private intentIdentifier: number = 0;

    private currentIntentType?: { new(iocService: IocService): Intent };
    private currentIntent?: Intent;
    private currentIntentOptions?: IntentOptions<Intent>;

    public get intentActive(): boolean {
        return !!this.currentIntent;
    }

    constructor(
        private readonly iocService: IocService,
        private readonly loggingService: LoggingService) {
    }

    public executeIntent<TIntent extends Intent>(intentType: { new(iocService: IocService): TIntent }, intentOptions?: IntentOptions<TIntent>): void {
        this.executeIntentAndWait(intentType, intentOptions).then();
    }

    private async callIntent<TIntent extends Intent>(intent: TIntent, intentOptions?: IntentOptions<TIntent>): Promise<boolean> {
        const intentDone: boolean = intentOptions ? await intent.execute(intentOptions) : await intent.execute();
        if (intentDone) {
            this.closeIntent(intent);
        }

        return intentDone;
    }

    private closeIntent(intent: Intent): void {
        this.loggingService.intentEnd(intent.name);

        this.currentIntent = undefined;
        this.currentIntentOptions = undefined;
    }

    public async executeIntentAndWait<TIntent extends Intent>(intentType: { new(iocService: IocService): TIntent }, intentOptions?: IntentOptions<TIntent>): Promise<boolean> {
        if (this.currentIntent && this.currentIntentType != intentType) {
            await this.currentIntent.cancel();
        }

        this.currentIntentType = intentType;
        this.currentIntent = new intentType(this.iocService);
        this.currentIntentOptions = intentOptions;

        this.intentIdentifier++;
        this.currentIntent.identifier = this.intentIdentifier;

        this.loggingService.intentStart(this.currentIntent.name, intentOptions);
        return this.callIntent(this.currentIntent, intentOptions);
    }

    public async executeActiveIntent(): Promise<boolean> {
        if (!this.intentActive || !this.currentIntent) {
            return Promise.resolve(false);
        }

        return this.callIntent(this.currentIntent, this.currentIntentOptions);
    }
}
