import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { IntentsService } from "../../../../../base/services/intents/intents.service";
import { LoadStrategies } from "../../../../common/load-strategies";
import { GenericCoreDataEntity } from "../../../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../../../entities/documents/entity-link";
import { Identifier } from "../../../../identifiers/identifier";
import { EditCoreDataIntent } from "../../../../intents/master-data/edit-core-data-intent";
import { EditCoreDataIntentOptions } from "../../../../intents/master-data/edit-core-data-intent-options";
import { AppIcons } from "../../../../services/icons/app-icons";
import { CoreDataFactory } from "../../../../services/master-data/core-data-factory";
import { CoreDataService } from "../../../../services/master-data/core-data-service/core-data.service";

/**
 * Component to allow editing entity links.
 */
@Component({
    selector: "business-entity-links",
    templateUrl: "./entity-links.component.html",
    styleUrls: ["./entity-links.component.scss"]
})
export class EntityLinksComponent implements OnInit {
    constructor(
        private readonly intentsService: IntentsService,
        private readonly coreDataService: CoreDataService
    ) {
        // Do nothing for now
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    @Input()
    public entityLinks?: Array<EntityLink<GenericCoreDataEntity>>;

    @Input()
    public readonly: boolean = false;

    @Input()
    public hasRole: boolean = true;

    @Output()
    public entityLinksChange: EventEmitter<Array<EntityLink<GenericCoreDataEntity>>> = new EventEmitter<Array<EntityLink<GenericCoreDataEntity>>>();

    private checkingForUpdates: boolean = false;

    public ngOnInit(): void {
        this.checkForUpdates().then();
    }

    private emitUpdate(): void {
        this.entityLinksChange.emit(this.entityLinks);
    }

    public linkDescriptionChanged(link: EntityLink<GenericCoreDataEntity>, event: Event): void {
        const customEvent: CustomEvent = event as CustomEvent;
        if (!customEvent?.detail?.value) {
            return;
        }

        link.linkDescription = customEvent.detail.value as string;
        this.emitUpdate();
    }

    public deleteLink(entityLink: EntityLink<GenericCoreDataEntity>): void {
        this.entityLinks = this.entityLinks?.filter((link: EntityLink<GenericCoreDataEntity>) => link != entityLink) ?? [];
        this.emitUpdate();
    }

    public async editLinkedEntity(entityLink: EntityLink<GenericCoreDataEntity>): Promise<void> {
        if (entityLink.entity) {
            await this.intentsService.executeIntentAndWait(EditCoreDataIntent, new EditCoreDataIntentOptions(entityLink.entity));
        }
    }

    public async checkForUpdates(): Promise<void> {
        if (this.checkingForUpdates || !this.entityLinks) {
            return;
        }

        this.checkingForUpdates = true;

        for (const entityLink of this.entityLinks) {
            if (entityLink.latestEntity && entityLink.latestEntity.identifier.technicalIdentifier != entityLink.identifier.technicalIdentifier) {
                // We already know that there's a newer version. Skip the check.
                continue;
            }

            try {
                entityLink.latestEntity = CoreDataFactory.createFromEntityType(entityLink.entityType);
                const updatedEntity: GenericCoreDataEntity|undefined = await this.coreDataService.loadEntity(Identifier.create(entityLink.identifier.businessIdentifier), entityLink.entityType, LoadStrategies.serverOnly);
                if (updatedEntity && updatedEntity.identifier.technicalIdentifier != entityLink.identifier.technicalIdentifier) {
                    entityLink.latestEntity = updatedEntity;
                } else {
                    entityLink.latestEntity = undefined;
                }
            } catch (error) {
                // Ignore errors - if we don't have internet or something else is not working that we just don't show an update information
                entityLink.latestEntity = undefined;
                console.warn(error);
            }
        }

        this.checkingForUpdates = false;
    }

    public async updateLink(entityLink: EntityLink<GenericCoreDataEntity>): Promise<void> {
        if (entityLink.latestEntity) {
            entityLink.entity = entityLink.latestEntity;
            entityLink.identifier = entityLink.latestEntity.identifier;
            entityLink.latestEntity = undefined;
        }
        this.emitUpdate();
    }
}
