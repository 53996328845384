/**
 * The helper class for string operations.
 */
export class StringHelper {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public static getNonSecureRandomString(length: number): string {
        let result: string = "";
        // noinspection SpellCheckingInspection
        const characters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength: number = characters.length;
        for (let i: number = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength)); // NOSONAR
        }
        return result;
    }

    public static toCamelCase(value: string|undefined|null): string {
        if (!value) {
            return "";
        }
        return value[0].toLowerCase() + value.substring(1).replace(/([-_ ][a-zA-Z])/g, (group: string) =>
            group
                .toUpperCase()
                .replace("-", "")
                .replace("_", "")
                .replace(" ", "")
        );
    }

    public static toHyphenCase(value: string|undefined|null): string {
        if (!value) {
            return "";
        }
        if (value.length == 1) {
            return value.toLowerCase();
        }

        const hyphenCase: string = value
            .replace(/[A-Z]/g, (character: string) => `-${character.toLowerCase()}`)
            .replace(/[_ ]/g, "-")
            .replace(/--/g, "-");
        return hyphenCase[0] == "-" ? hyphenCase.substring(1) : hyphenCase;
    }
}
