<ion-header class="modalHeader ion-no-border">
    <ion-toolbar>
        <ion-title i18n="@@games.arrow.title">Arrows</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-label color="medium">
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="level < 0" class="modalContent ion-padding">
    <div class="buttons">
        <ion-button (click)="start(0)">
            <fa-icon [icon]="appIcons.dice1" size="lg"></fa-icon>&nbsp;<ng-container i18n="@@games.arrow.level1">Level 1</ng-container>
        </ion-button>
        <ion-button (click)="start(1)">
            <fa-icon [icon]="appIcons.dice2" size="lg"></fa-icon>&nbsp;<ng-container i18n="@@games.arrow.level2">Level 2</ng-container>
        </ion-button>
        <ion-button (click)="start(2)">
            <fa-icon [icon]="appIcons.dice3" size="lg"></fa-icon>&nbsp;<ng-container i18n="@@games.arrow.level3">Level 3</ng-container>
        </ion-button>
        <ion-button (click)="start(3)">
            <fa-icon [icon]="appIcons.dice4" size="lg"></fa-icon>&nbsp;<ng-container i18n="@@games.arrow.level4">Level 4</ng-container>
        </ion-button>
    </div>

    <ion-grid class="highscores">
        <ion-row>
            <ion-col size="12">
                <h2 i18n="@@games.arrow.highscores">Highscores</h2>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="highscores && highscores.levels.length > 0">
            <ion-col i18n="@@games.arrow.level1">Level 1</ion-col>
            <ion-col>
                <ng-container *ngIf="highscores.levels[0] > 0">{{toTimeString(highscores.levels[0])}}</ng-container>
                <ng-container *ngIf="highscores.levels[0] <= 0">--:--</ng-container>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="highscores && highscores.levels.length > 1">
            <ion-col i18n="@@games.arrow.level2">Level 2</ion-col>
            <ion-col>
                <ng-container *ngIf="highscores.levels[1] > 0">{{toTimeString(highscores.levels[1])}}</ng-container>
                <ng-container *ngIf="highscores.levels[1] <= 0">--:--</ng-container>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="highscores && highscores.levels.length > 2">
            <ion-col i18n="@@games.arrow.level3">Level 3</ion-col>
            <ion-col>
                <ng-container *ngIf="highscores.levels[2] > 0">{{toTimeString(highscores.levels[2])}}</ng-container>
                <ng-container *ngIf="highscores.levels[2] <= 0">--:--</ng-container>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="highscores && highscores.levels.length > 3">
            <ion-col i18n="@@games.arrow.level4">Level 4</ion-col>
            <ion-col>
                <ng-container *ngIf="highscores.levels[3] > 0">{{toTimeString(highscores.levels[3])}}</ng-container>
                <ng-container *ngIf="highscores.levels[3] <= 0">--:--</ng-container>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<ion-content *ngIf="level >= 0" class="modalContent ion-padding">
    <div class="info">
        <div class="timeLabel">{{timeLabel}}</div>
    </div>
    <div class="field">
        <div *ngFor="let row of field; let y = index" class="row">
            <div (click)="rotate(x, y)" *ngFor="let cell of row; let x = index" [style.padding-bottom]="cellWidth" [style.width]="cellWidth" class="cell">
                <img [class]="{ 'value0': cell.value % 4 == 0, 'value1': cell.value % 4 == 1, 'value2': cell.value % 4 == 2, 'value3': cell.value % 4 == 3 }" [ngStyle]="{ 'transform': 'rotate('+cell.rotation+'deg)' }" alt="{{cell.value}}" class="cyan" height="155" ngSrc="assets/arrow-up-cyan.svg" width="155" />
                <img [class]="{ 'value0': cell.value % 4 == 0, 'value1': cell.value % 4 == 1, 'value2': cell.value % 4 == 2, 'value3': cell.value % 4 == 3 }" [ngStyle]="{ 'transform': 'rotate('+cell.rotation+'deg)' }" alt="{{cell.value}}" class="black" src="assets/arrow-up-black.svg" />
            </div>
        </div>
    </div>
    <ion-buttons class="buttons">
        <ion-button (click)="restart()">
            <fa-icon [icon]="appIcons.gameRestart"></fa-icon>&nbsp;<ng-container i18n="@@games.arrow.restart">Restart</ng-container>
        </ion-button>
        <ion-button (click)="stop()">
            <fa-icon [icon]="appIcons.gameStop"></fa-icon>&nbsp;<ng-container i18n="@@games.arrow.stop">Stop</ng-container>
        </ion-button>
    </ion-buttons>
</ion-content>
