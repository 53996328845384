import { ContextMenuItem } from "./context-menu-item";

/**
 * Options for a context menu.
 */
export class ContextMenuOptions {
    constructor(items: Array<ContextMenuItem>) {
        this.items = items;
    }

    public items: Array<ContextMenuItem>;
}
