import { DocumentTypes } from "../../../entities/documents/document-types";
import { SamplingPlanMerge } from "../../../entities/samplings/sampling-plan-merge";
import { DocumentIdentifier } from "../../../identifiers/document-identifier";
import { PersonBusinessIdentifier } from "../../../identifiers/person-business-identifier";
import { DocumentUpdateTypes } from "../../documents/document-update-types";
import { CoreDataFactory } from "../../master-data/core-data-factory";
import { PartialDocumentUpdate } from "./partial-document-update";
import { SamplingPlanUpdateStorageDto } from "./sampling-plan-update-storage-dto";

/**
 * Update for a sampling plan.
 */
export class SamplingPlanUpdate extends PartialDocumentUpdate {
    public constructor(accountIdentifier: number, personBusinessIdentifier: PersonBusinessIdentifier, documentId: DocumentIdentifier, documentType: DocumentTypes, samplingPlan: SamplingPlanMerge) {
        super(accountIdentifier, personBusinessIdentifier, documentId, documentType);

        this.samplingPlan = samplingPlan;
    }

    public readonly updateType: DocumentUpdateTypes = DocumentUpdateTypes.samplingPlan;

    public samplingPlan: SamplingPlanMerge;

    protected partialUpdateFromStorageDto(dto: SamplingPlanUpdateStorageDto|undefined): void {
        if (dto?.samplingPlan) {
            this.samplingPlan = CoreDataFactory.createFromStorageDto(dto.samplingPlan) as SamplingPlanMerge;
        }
    }

    protected partialUpdateToStorageDto(): SamplingPlanUpdateStorageDto {
        return new SamplingPlanUpdateStorageDto({
            samplingPlan: this.samplingPlan.toStorageDto()
        });
    }
}
