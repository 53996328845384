/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LegalReferenceDto = 'DVGW_W551' | 'UBA_Empfehlung_18-12-2018';

export const LegalReferenceDto = {
    DvgwW551: 'DVGW_W551' as LegalReferenceDto,
    UbaEmpfehlung18122018: 'UBA_Empfehlung_18-12-2018' as LegalReferenceDto
};

