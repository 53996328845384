import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { DefaultService, ProcessDto, RequestBodyCreateProcessDto, ResponseBodyProcessOverviewDto } from "../../../generated/api";
import { ProcessBusinessIdentifier } from "../../identifiers/process-business-identifier";
import { ProcessIdentifier } from "../../identifiers/process-identifier";
import { ProjectBusinessIdentifier } from "../../identifiers/project-business-identifier";
import { ProjectTechnicalIdentifier } from "../../identifiers/project-technical-identifier";
import { SessionService } from "../session/session.service";

/**
 * Service to work with processes.
 */
@Injectable({
    providedIn: "root"
})
export class ProcessesApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public async getProcesses(projectTechnicalIdentifier: ProjectTechnicalIdentifier|undefined): Promise<ResponseBodyProcessOverviewDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listProcesses(this.sessionService.activeAccountId, projectTechnicalIdentifier));
    }

    public async getProcess(projectTechnicalIdentifier: ProjectTechnicalIdentifier, processIdentifier: ProcessIdentifier): Promise<ResponseBodyProcessOverviewDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listProcesses(this.sessionService.activeAccountId, projectTechnicalIdentifier, processIdentifier.technicalIdentifier || undefined, processIdentifier.businessIdentifier || undefined));
    }

    public createProcess(projectBusinessIdentifier: ProjectBusinessIdentifier, projectDto: ProcessDto): Promise<ProcessDto>|never {
        this.sessionService.requiresAccount();

        const requestDto: RequestBodyCreateProcessDto = projectDto;
        requestDto.projectBusinessId = projectBusinessIdentifier;
        requestDto.assigneeBusinessId = projectDto.assigneeId?.businessId;
        requestDto.buildingComplexBusinessId = projectDto.buildingComplexId?.businessId;

        return lastValueFrom(this.defaultService.createProcess(requestDto, this.sessionService.activeAccountId));
    }

    public updateProcess(processBusinessIdentifier: ProcessBusinessIdentifier, processDto: ProcessDto, _archive: boolean): Promise<ProcessDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.updateProcess(processBusinessIdentifier, processDto, this.sessionService.activeAccountId));
    }

    public async deleteProcess(businessIdentifier: ProcessBusinessIdentifier): Promise<void>|never {
        this.sessionService.requiresAccount();
        await lastValueFrom(this.defaultService.deleteProcess(businessIdentifier, this.sessionService.activeAccountId));
    }
}
