import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { Intent } from "../../../base/services/intents/intent";
import { IntentOptions } from "../../../base/services/intents/intent-options";
import { IocService } from "../../../base/services/ioc/ioc.service";
import { AccountDto } from "../../../generated/api";
import { AccountSelectComponent } from "../../components/account-select/account-select.component";
import { AccountSelectOptions } from "../../components/account-select/account-select-options";
import { MasterDataFormDialogComponent } from "../../components/forms/form-dialog/master-data-form-dialog.component";
import { MasterDataFormDialogOptions } from "../../components/forms/form-dialog/master-data-form-dialog-options";
import { Person } from "../../entities/core-data/person";
import { CoreDataService } from "../../services/master-data/core-data-service/core-data.service";
import { SessionService } from "../../services/session/session.service";

/**
 * An abstract intent to handle all necessary process to login before using the inherited intent.
 */
export abstract class LoggedInIntent<TIntent extends Intent> implements Intent {
    protected constructor(
        protected iocService: IocService
    ) {
        this.name = this.constructor.name;
    }

    public name: string;

    public identifier?: number;

    protected readonly coreDataService: CoreDataService = this.iocService.resolve(CoreDataService);

    protected readonly sessionService: SessionService = this.iocService.resolve(SessionService);

    protected readonly dialogService: DialogService = this.iocService.resolve(DialogService);

    public abstract executeIntent(intentOptions?: IntentOptions<TIntent>): Promise<boolean>;

    public async execute(intentOptions?: IntentOptions<TIntent>): Promise<boolean> {
        if (this.sessionService.loggedIn && this.sessionService.activePerson && this.sessionService.activePerson.firstname && this.sessionService.activePerson.lastname) {
            return this.executeIntent(intentOptions);
        }

        if (!this.sessionService.loggedIn) {
            await this.sessionService.logIn();
            return false;
        }

        let accounts: Array<AccountDto> = this.sessionService.accounts;
        if (!this.sessionService.activeAccount) {
            if (!accounts || accounts.length <= 0) {
                await this.sessionService.updateAccounts();
                accounts = this.sessionService.accounts;
            }
            if (accounts && accounts.length) {
                if (accounts.length == 1 && accounts[0].id) {
                    await this.sessionService.setAccount(accounts[0].id);
                } else {
                    await this.selectAccount();
                }
            } else {
                await this.dialogService.showAlert($localize`:@@sessions.unableToLoadAccountsTitle:Unable to load accounts`, $localize`:@@sessions.unableToLoadAccountsText:The app was unable to load the accounts. Please check your internet connection or try to log out and log in again.`);
                return false;
            }
        }

        if (!this.sessionService.activePerson) {
            await this.sessionService.logOut();
            return true;
        }

        if (!this.sessionService.activePerson.firstname || !this.sessionService.activePerson.lastname) {
            await this.updatePerson();
            if (!this.sessionService.activePerson.firstname || !this.sessionService.activePerson.lastname) {
                await this.sessionService.logOut();
                return true;
            }
        }

        return this.executeIntent(intentOptions);
    }

    private selectAccount(): Promise<void> {
        const dialogService: DialogService = this.iocService.resolve(DialogService);
        const options: AccountSelectOptions = {
            createNew: false
        };
        return dialogService.showModal({
            component: AccountSelectComponent,
            payload: { options: options },
            singleInstance: true
        }).then();
    }

    private async updatePerson(): Promise<void> {
        let options: MasterDataFormDialogOptions|undefined = new MasterDataFormDialogOptions($localize`:@@form.editYourselfTitle:Edit your own data`, {
            allowClose: false,
            allowEntityLinking: false,
            entities: [this.sessionService.activePerson!],
            mandatoryFields: [TypeScriptHelper.nameOf<Person>("firstname"), TypeScriptHelper.nameOf<Person>("lastname")]
        });

        options = await this.dialogService.showModal<MasterDataFormDialogOptions>({
            component: MasterDataFormDialogComponent,
            payload: { options: options }
        });
        if (options && options.entities?.length) {
            await this.coreDataService.saveEntity(options.entities[0], false);
            await this.sessionService.updateActivePerson();
        }
    }

    public abstract cancel(): Promise<void>;
}
