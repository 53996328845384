import { CoreDataEntityStorageDto } from "../core-data/core-data-entity-storage-dto";
import { QualityAssuranceItemStorageDto } from "../quality-assurance/quality-assurance-item-storage-dto";
import { DocumentInstanceStorageDto } from "./document-instance-storage-dto";
import { DocumentTemplateStorageDto } from "./document-template-storage-dto";
import { EntityLinkStorageDto } from "./entity-link-storage-dto";

/**
 * Basic DTO to persist document to storage. This DTO is not used for API requests.
 */
export class AuditDocumentStorageDto extends CoreDataEntityStorageDto {
    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public instance?: DocumentInstanceStorageDto;

    public businessId?: string;

    public technicalId?: number;

    public templateVersionTechnicalId?: number;

    public template?: DocumentTemplateStorageDto;

    public type?: string;

    public created?: string;

    public updated?: string;

    public updatedClient?: string;

    public targetDate?: string;

    public state?: string;

    public assignedAuditorBusinessId?: string;

    public assignedAuditorTechnicalId?: number;

    public assignedPersonBusinessId?: string;

    public assignedPersonTechnicalId?: number;

    public assignedQualityManagerBusinessId?: string;

    public assignedQualityManagerTechnicalId?: number;

    public linkedEntities?: Array<EntityLinkStorageDto> = [];

    public qualityAssuranceItems?: Array<QualityAssuranceItemStorageDto> = [];

    public additionalInfo?: string;

    public reviewSummary?: string;
}
