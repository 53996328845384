import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";

/**
 * A table row for a core data entity.
 */
export class CoreDataTableEntry {
    constructor(entity: GenericCoreDataEntity, init?: Partial<CoreDataTableEntry>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.entity = entity;
    }

    public entity: GenericCoreDataEntity;
}
