import { EventEmitter } from "@angular/core";
import { Observable, Subject, Subscription, takeUntil } from "rxjs";

import { ArrayHelper } from "./array-helper";

/**
 * Helper class vor events.
 */
export class EventHelper {
    public static subscriptions: Array<Subscription> = [];

    public static subscribe<TEventData>(event: Observable<TEventData>|EventEmitter<TEventData>, handler: (eventData: TEventData) => void, context: object): Subscription {
        const subscriptions: Subscription = event.subscribe(handler.bind(context));
        this.subscriptions.push(subscriptions);
        return subscriptions;
    }

    public static unsubscribe(subscription?: Subscription): undefined {
        if (subscription) {
            ArrayHelper.removeElement(this.subscriptions, subscription);
            subscription.unsubscribe();
        }
        return undefined;
    }

    public static subscribeUntilDestroyed<TEventData>(event: Observable<TEventData>|EventEmitter<TEventData>, handler: (eventData: TEventData) => void, destroyedSubject: Subject<void>, context: object): Subscription {
        return event
            .pipe(takeUntil(destroyedSubject))
            .subscribe(handler.bind(context));
    }

    public static createDestroySubject(): Subject<void> {
        return new Subject<void>();
    }

    public static triggerDestroy(subject: Subject<void>): void {
        subject.next();
        subject.complete();
    }
}
