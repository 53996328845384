/* eslint-disable @typescript-eslint/no-magic-numbers */
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";

/**
 * Component to display encoded e-mails. Check https://www.email-encoder.com/ for more information.
 */
@Component({
    selector: "base-email",
    templateUrl: "./email.component.html",
    styleUrls: ["./email.component.scss"]
})
export class EmailComponent implements AfterViewInit {
    @ViewChild("contactEmail")
    private contactEmail?: ElementRef<HTMLSpanElement>;

    @Input()
    public chunks?: Array<string>;

    @Input()
    public chunkPositions?: Array<number>;

    private generateMail(): void {
        if (!this.chunks || !this.chunkPositions) {
            return;
        }

        const sorted: Array<string> = [];

        for (let i: number = 0; i < this.chunkPositions.length; i++) {
            sorted[this.chunkPositions[i]] = this.chunks[i];
        }

        let email: string = "";

        for (const item of sorted) {
            email += atob(`${item}==`);
        }

        if (this.contactEmail) {
            this.contactEmail.nativeElement.innerHTML = email;
        }
    }

    public ngAfterViewInit(): void {
        this.generateMail();
    }
}
