<ion-header class="modalHeader ion-no-border">
    <ion-toolbar>
        <ion-title i18n="@@persons.invitePersonDialogTitle">Invite person</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-label color="medium">
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="modalContent ion-padding loading" *ngIf="inviting">
    <h2 i18n="@@persons.inviteLoading">Inviting person...</h2>
    <base-loading></base-loading>
    <div i18n="@@persons.inviteLoadingDescription">We're now sending an invitation to {{emailControl.value}}.</div>
</ion-content>

<ion-content class="modalContent ion-padding" *ngIf="!inviting">
    <form [formGroup]="formData" (ngSubmit)="invite()">
        <span i18n="@@persons.invitePersonInfo">Please enter the e-mail of your invitee here.</span>
        <ion-item>
            <ion-input type="email" i18n-placeholder="@@persons.inviteEmailPlaceholder" placeholder="E-Mail" formControlName="email" #email></ion-input>
        </ion-item>
        <app-form-validation-error itemName="email" [formGroup]="formData" [validationMessages]="validationMessages"></app-form-validation-error>
        <ion-button (click)="invite()"><fa-icon [icon]="appIcons.genericArrowRight"></fa-icon>&nbsp;<ng-container i18n="@@persons.inviteButton">Invite</ng-container></ion-button>
    </form>
</ion-content>

<ion-footer class="modalFooter ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()"><fa-icon [icon]="appIcons.genericCancel"></fa-icon>&nbsp;<ng-container i18n="@@dialog.genericCloseButton">Close</ng-container></ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
