<div *ngIf="tableContent.length <= 0" i18n="@@cloudStatus.noPendingChanges">No pending changes.</div>

<div class="scrollX">
    <table #table [dataSource]="tableContent" [hidden]="tableContent.length <= 0" mat-table>
        <ng-container matColumnDef="icon">
            <th *matHeaderCellDef mat-header-cell>&nbsp;</th>
            <td *matCellDef="let row" mat-cell>
                <fa-icon *ngIf="row.type == syncTypes.question" [icon]="getSaveStateIcon(row.documentUpdate.saveState)"></fa-icon>&nbsp;
                <div *ngIf="row.type == syncTypes.image" class="imageHost">
                    <business-image-attachment [allowDownload]="false" [attachment]="row.fileAttachment" [cover]="true" [previewImage]="false" [settings]="false"></business-image-attachment>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="document">
            <th *matHeaderCellDef i18n="@@cloudStatus.columnDocumentInformation" mat-header-cell>Document</th>
            <td *matCellDef="let row" mat-cell>
                {{row.documentBusinessIdentifier}}
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th *matHeaderCellDef i18n="@@cloudStatus.columnSyncType" mat-header-cell>Type</th>
            <td *matCellDef="let row" mat-cell>
                <ng-container *ngIf="row.type == syncTypes.question">{{getDocumentUpdateTypeText(row.documentUpdate.updateType)}}</ng-container>
                <ng-container *ngIf="row.type == syncTypes.image" i18n="@@cloudStatus.syncTypeImage">Image</ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="error">
            <th *matHeaderCellDef i18n="@@cloudStatus.columnError" mat-header-cell>Error</th>
            <td *matCellDef="let row" mat-cell>
                {{row.error}}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th *matHeaderCellDef i18n="@@cloudStatus.columnContent" mat-header-cell>Content</th>
            <td *matCellDef="let row" class="lineFeedBreak" mat-cell>{{row.description}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell>&nbsp;</th>
            <td *matCellDef="let row" mat-cell>
                <div class="alignRight">
                    <ion-buttons>
                        <base-process-button (triggered)="retry(row.documentUpdate, $event)" *ngIf="row.canRetry" [icon]="appIcons.genericRetry" color="primary"></base-process-button>
                        <base-process-button (triggered)="delete(row.documentUpdate, $event)" *ngIf="row.canDelete" [icon]="appIcons.genericDelete" color="warn"></base-process-button>
                    </ion-buttons>
                </div>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
</div>
