import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { AnswerIdentifier } from "../../identifiers/answer-identifier";
import { Identifier } from "../../identifiers/identifier";
import { StorableEntity } from "../storable-entity";
import { DocumentAnswerValueStorageDto } from "./document-answer-value-storage-dto";

/**
 * A single value for a question answer.
 */
export class DocumentAnswerValue implements StorableEntity<DocumentAnswerValueStorageDto> {
    constructor(init?: Partial<DocumentAnswerValue>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public answerIdentifier?: AnswerIdentifier;

    public value?: any;

    public toStorageDto(): DocumentAnswerValueStorageDto {
        return {
            businessId: this.answerIdentifier?.businessIdentifier,
            technicalId: this.answerIdentifier?.technicalIdentifier,
            value: this.value as unknown
        };
    }

    public fromStorageDto(dto: DocumentAnswerValueStorageDto|undefined): void {
        this.answerIdentifier = Identifier.create(dto?.businessId, dto?.technicalId);
        this.value = dto?.value as unknown;
    }
}
