import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";

/**
 * Queue item for persisted queue.
 */
export class AttachmentQueueItemStorageDto {
    constructor(init?: Partial<AttachmentQueueItemStorageDto>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public storageId?: number;

    public businessId?: string;

    public technicalId?: number;
}
