import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { EventHelper } from "../../../base/helpers/event-helper";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { AttachmentLoaderService } from "../../services/attachments/attachment-loader.service";
import { DocumentQueuedUpdateService } from "../../services/document-update/document-queued-update.service";
import { DocumentSynchronizationSummary } from "../../services/documents/document-synchronization-summary";
import { AppIcons } from "../../services/icons/app-icons";
import { OnlineMonitorService } from "../../services/monitors/online-monitor.service";
import { CloudStatusPopoverComponent } from "./cloud-status-popover/cloud-status-popover.component";

/**
 * Status button for cloud status.
 */
@Component({
    selector: "app-cloud-status",
    templateUrl: "./cloud-status.component.html",
    styleUrls: ["./cloud-status.component.scss"]
})
export class CloudStatusComponent implements OnInit, OnDestroy {
    constructor(
        private readonly onlineMonitorService: OnlineMonitorService,
        private readonly documentService: DocumentQueuedUpdateService,
        private readonly attachmentLoaderService: AttachmentLoaderService,
        private readonly dialogService: DialogService
    ) {
    }

    private onlineStateChangedSubscription?: Subscription;

    private synchronizationUpdatedSubscription?: Subscription;

    private uploadQueueChangedSubscription?: Subscription;

    public onlineStateAlreadyChecked: boolean = false;

    public online: boolean = false;

    public synchronizationSummary: DocumentSynchronizationSummary = new DocumentSynchronizationSummary();

    public uploadQueuePendingItems: number = this.attachmentLoaderService.queueItemsCount;

    public appIcons: typeof AppIcons = AppIcons;

    public ngOnInit(): void {
        this.onlineStateChangedSubscription = EventHelper.subscribe(this.onlineMonitorService.onlineStateChanged, this.onlineStateChanged, this);
        this.synchronizationUpdatedSubscription = EventHelper.subscribe(this.documentService.synchronizationSummaryUpdated, this.synchronizationUpdated, this);
        this.uploadQueueChangedSubscription = EventHelper.subscribe(this.attachmentLoaderService.uploadQueueChanged, this.uploadQueueChanged, this);
    }

    public ngOnDestroy(): void {
        this.onlineStateChangedSubscription = EventHelper.unsubscribe(this.onlineStateChangedSubscription);
        this.synchronizationUpdatedSubscription = EventHelper.unsubscribe(this.synchronizationUpdatedSubscription);
        this.uploadQueueChangedSubscription = EventHelper.unsubscribe(this.uploadQueueChangedSubscription);
    }

    private onlineStateChanged(online: boolean): void {
        this.onlineStateAlreadyChecked = true;
        this.online = online;
    }

    private synchronizationUpdated(summary: DocumentSynchronizationSummary): void {
        this.synchronizationSummary = summary;
    }

    public showDetails(event: Event): void {
        this.onlineMonitorService.checkConnection().then();
        this.dialogService.showPopover({ component: CloudStatusPopoverComponent, event: event }).then();
    }

    private uploadQueueChanged(pending: number): void {
        this.uploadQueuePendingItems = pending;
    }
}
