import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";

import { EventHelper } from "../../../base/helpers/event-helper";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { AccountDto } from "../../../generated/api";
import { Company } from "../../entities/core-data/company";
import { UiWorkingStates } from "../../helpers/ui-working-states";
import { AppIcons } from "../../services/icons/app-icons";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { SessionService } from "../../services/session/session.service";
import { AccountSelectOptions } from "./account-select-options";

/**
 * Component to select or to create an account.
 */
@Component({
    selector: "app-account-select",
    templateUrl: "./account-select.component.html",
    styleUrls: ["./account-select.component.scss"]
})
export class AccountSelectComponent implements OnInit, OnDestroy {
    constructor(
        private readonly sessionService: SessionService,
        private readonly dialogService: DialogService,
        private readonly modalController: ModalController
    ) {
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    @Input()
    public options?: AccountSelectOptions;

    public workingStates: typeof UiWorkingStates = UiWorkingStates;

    public state: UiWorkingStates = UiWorkingStates.none;

    public accounts: Array<AccountDto> = [];

    public selectedAccount?: AccountDto;

    public editableCompany?: Company;

    public switchingTo?: AccountDto;

    public createMode: boolean = false;

    private sessionChangedSubscription?: Subscription;

    public ngOnInit(): void {
        this.options = this.options ?? new AccountSelectOptions();
        this.updateAccounts();
        this.createMode = this.options.createNew || this.accounts.length <= 0;

        if (this.createMode) {
            this.enterCreateMode();
        }

        this.sessionChangedSubscription = EventHelper.subscribe(this.sessionService.sessionChanged, this.sessionChanged, this);
    }

    public ngOnDestroy(): void {
        this.sessionChangedSubscription = EventHelper.unsubscribe(this.sessionChangedSubscription);
    }

    private updateAccounts(): void {
        this.accounts = this.sessionService.accounts ?? [];
        this.selectedAccount = this.sessionService.activeAccount;
    }

    private sessionChanged(): void {
        this.updateAccounts();
    }

    public enterCreateMode(): void {
        this.createMode = true;
        this.editableCompany = CoreDataFactory.createCompany();
    }

    public enterSelectMode(): void {
        this.updateAccounts();

        this.createMode = false;
        this.editableCompany = undefined;
    }

    public async selectAccount(account: AccountDto): Promise<void> {
        if (this.switchingTo || !account.id) {
            return;
        }

        try {
            this.switchingTo = account;
            await this.sessionService.setAccount(account.id);
            this.close();
        } catch (error) {
            // TODO: Proper error handling
            const typedError: Error = error as Error;
            await this.dialogService.showError(typedError);
        } finally {
            this.switchingTo = undefined;
        }
    }

    public async createAccount(): Promise<void> {
        if (!this.editableCompany) {
            console.error("editableCompany is empty.");
            return;
        }

        this.state = UiWorkingStates.saving;
        try {
            const account: AccountDto = await this.sessionService.createAccount(this.editableCompany);
            await this.selectAccount(account);
        } catch (error) {
            this.state = UiWorkingStates.none;
            // TODO: Properly handle error
            const typedError: Error = error as Error;
            await this.dialogService.showError(typedError);
        }
    }

    public close(): void {
        this.modalController.dismiss().then();
    }
}
