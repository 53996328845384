import { Params } from "@angular/router";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../entities/documents/entity-link";
import { EntityTypes } from "../../entities/entity-types";
import { Pages } from "../../pages/pages";

/**
 * The menu question is a composite of a single question in a menu, e.g. the main menu.
 */
export class MenuItem {
    constructor(init?: Partial<MenuItem>) {
        this.assign(init);
    }

    public identifier: number = 0;

    public title?: string;

    public subTitle?: string;

    public isTopItem: boolean = false;

    public isHeadline: boolean = false;

    public isSeparator: boolean = false;

    public allowNewTab: boolean = false;

    public icon?: IconDefinition;

    public neutralBadgeCount: number = 0;

    public warningBadgeCount: number = 0;

    public successBadgeCount: number = 0;

    public criticalBadgeCount: number = 0;

    public badgeIcon?: IconDefinition;

    public hasSearchBar: boolean = false;

    public placeholder?: string;

    public targetPage?: Pages;

    public targetRoute?: Array<string>;

    public targetParameters?: Params;

    public targetEntity: EntityTypes = EntityTypes.unknown;

    public linkedEntity?: EntityLink<GenericCoreDataEntity>;

    public children: Array<MenuItem> = [];

    public open: boolean = false;

    public loading: boolean = false;

    public assign(other: Partial<MenuItem>|undefined): void {
        if (!other) {
            return;
        }
        this.identifier = other.identifier ?? 0;
        this.title = other.title;
        this.subTitle = other.subTitle;
        this.isTopItem = !!other.isTopItem;
        this.isHeadline = !!other.isHeadline;
        this.isSeparator = !!other.isSeparator;
        this.allowNewTab = !!other.allowNewTab;
        this.icon = other.icon;
        this.neutralBadgeCount = other.neutralBadgeCount || 0;

        this.hasSearchBar = !!other.hasSearchBar;
        this.placeholder = other.placeholder;

        this.targetPage = other.targetPage;
        this.targetRoute = other.targetRoute || [];
        this.targetEntity = other.targetEntity ?? EntityTypes.unknown;

        this.neutralBadgeCount = other.neutralBadgeCount ?? 0;
        this.successBadgeCount = other.successBadgeCount ?? 0;
        this.warningBadgeCount = other.warningBadgeCount ?? 0;
        this.criticalBadgeCount = other.criticalBadgeCount ?? 0;

        this.children = other.children || [];
    }

    public addChild(menuItem: MenuItem): void {
        this.children.push(menuItem);
    }

    public addPartialChild(partialMenuItem: Partial<MenuItem>): MenuItem {
        const item: MenuItem = new MenuItem(partialMenuItem);
        this.addChild(item);
        return item;
    }

}
