/**
 * Class to measure performance.
 */
export class PerformanceMeasurement {
    public static start(key: string): void {
        try {
            performance.mark(key);
        } catch (error) {
            console.warn(error);
        }
    }

    public static stop(key: string, comment?: string): void {
        try {
            if (comment) {
                console.info("measurement", key, Math.round(performance.measure(key, key).duration), comment);
            } else {
                console.info("measurement", key, Math.round(performance.measure(key, key).duration));
            }
            performance.clearMarks(key);
        } catch (error) {
            console.warn(error);
        }
    }
}
