import { DocumentSectionStorageDto } from "./document-section-storage-dto";
import { DocumentValueContainerStorageDto } from "./document-value-container-storage-dto";

/**
 * Storage DTO for {@link DocumentInstance}.
 */
export class DocumentInstanceStorageDto {
    public repeatableQuestions: Array<DocumentSectionStorageDto> = [];
    public values: Array<DocumentValueContainerStorageDto> = [];
}
