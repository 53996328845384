import { TypeScriptHelper } from "../../base/helpers/type-script-helper";
import { EntityBusinessIdentifier } from "./entity-business-identifier";
import { EntityTechnicalIdentifier } from "./entity-technical-identifier";
import { Identifier } from "./identifier";

/**
 * Class to link business and technical identifiers.
 */
export class BusinessTechnicalIdentifierPair<TBusinessIdentifier extends EntityBusinessIdentifier, TTechnicalIdentifier extends EntityTechnicalIdentifier> {
    constructor(init?: Partial<BusinessTechnicalIdentifierPair<TBusinessIdentifier, TTechnicalIdentifier>>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public businessIdentifier: TBusinessIdentifier = "" as TBusinessIdentifier;
    public technicalIdentifier?: TTechnicalIdentifier;

    public toString(): string {
        return Identifier.identifierToString({ businessIdentifier: this.businessIdentifier, technicalIdentifier: this.technicalIdentifier });
    }
}
