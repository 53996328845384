<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="close()">
                <fa-icon [icon]="appIcons.goBack"></fa-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            Logs
        </ion-title>
        <ion-buttons slot="end">
            <debug>
                <ion-button (click)="takeScreenshot()">
                    <fa-icon [icon]="appIcons.screenshot"></fa-icon>&nbsp;Screenshot
                </ion-button>
                <ion-button (click)="provokeError(true)">
                    <fa-icon [icon]="appIcons.number0"></fa-icon>&nbsp;FE0
                </ion-button>
                <ion-button (click)="provokeError(false)">
                    <fa-icon [icon]="appIcons.number7"></fa-icon>&nbsp;FE7
                </ion-button>
            </debug>
            <ion-button (click)="sendDump()">
                <fa-icon [icon]="appIcons.supportSendDump"></fa-icon>
            </ion-button>
            <ion-button (click)="refresh()">
                <fa-icon [icon]="appIcons.genericRefresh"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-list>
        <ion-item *ngFor="let item of logs">
            <fa-icon *ngIf="item[1] === 'Warning'" [icon]="appIcons.logsWarning" class="warning" slot="start"></fa-icon>
            <fa-icon *ngIf="item[1] === 'Debug'" [icon]="appIcons.logsDebug" class="debug" slot="start"></fa-icon>
            <fa-icon *ngIf="item[1] === 'Error'" [icon]="appIcons.logsError" class="error" slot="start"></fa-icon>
            <fa-icon *ngIf="item[1] === 'Info'" [icon]="appIcons.logsInfo" slot="start"></fa-icon>
            <fa-icon *ngIf="item[1] === 'Navigation'" [icon]="appIcons.logsNavigation" slot="start"></fa-icon>
            <fa-icon *ngIf="item[1] === 'Intent-Start'" [icon]="appIcons.logsIntentStart" slot="start"></fa-icon>
            <fa-icon *ngIf="item[1] === 'Intent-End'" [icon]="appIcons.logsIntentEnd" slot="start"></fa-icon>
            <ion-label class="messageLabel">
                <h2><strong>{{item[1]}}</strong> {{item[0]}}</h2>
                <p *ngFor="let message of item[2]">{{message}}</p>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>
