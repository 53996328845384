import { Component, Input } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { IntentsService } from "../../../base/services/intents/intents.service";
import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../entities/documents/entity-link";
import { EntityTypes } from "../../entities/entity-types";
import { EntityBusinessIdentifier } from "../../identifiers/entity-business-identifier";
import { EditCoreDataIntent } from "../../intents/master-data/edit-core-data-intent";
import { EditCoreDataIntentOptions } from "../../intents/master-data/edit-core-data-intent-options";
import { I18nTextService } from "../../services/i18n/i18n-text.service";
import { IconsService } from "../../services/icons/icons.service";

/**
 * Component to show a badge with the business identifier.
 */
@Component({
    selector: "business-identifier-badge",
    templateUrl: "./identifier-badge.component.html",
    styleUrls: ["./identifier-badge.component.scss"]
})
export class IdentifierBadgeComponent {
    constructor(
        private readonly iconsService: IconsService,
        private readonly i18nTextService: I18nTextService,
        private readonly intentsService: IntentsService
    ) {
    }

    @Input()
    public entity?: GenericCoreDataEntity;

    @Input()
    public entityLink?: EntityLink<GenericCoreDataEntity>;

    @Input()
    public canOpen: boolean = true;

    @Input()
    public hideIfEmpty: boolean = false;

    @Input()
    public customIcon?: IconDefinition;

    private tooltipText?: string;

    public get businessIdentifier(): EntityBusinessIdentifier|undefined {
        return this.entity?.identifier?.businessIdentifier || this.entityLink?.entity?.identifier?.businessIdentifier || this.entityLink?.identifier?.businessIdentifier;
    }

    public get tooltip(): string {
        if (this.tooltipText) {
            return this.tooltipText;
        }
        this.tooltipText = `${this.i18nTextService.getEntityTypeText(this.entity?.entityType ?? this.entityLink?.entityType ?? EntityTypes.unknown)}`;
        if (this.entity) {
            this.tooltipText += `\n${this.entity.displayTitle}`;
        }
        const businessIdentifier: string|undefined = this.entity?.identifier.businessIdentifier || this.entityLink?.identifier.businessIdentifier;
        if (businessIdentifier && businessIdentifier[0] != "*") {
            this.tooltipText += `\n${$localize`:@@entities.identifierColon:Identifier: ${this.entity?.identifier.businessIdentifier ?? this.entityLink?.identifier.businessIdentifier}`}`;
        }

        return this.tooltipText;
    }

    public get icon(): IconDefinition {
        if (this.customIcon) {
            return this.customIcon;
        }
        return this.iconsService.getEntityIconSolid(this.entity?.entityType ?? this.entityLink?.entityType ?? EntityTypes.unknown);
    }

    public openEntity(): void {
        if (!this.canOpen || !this.entity) {
            return;
        }
        this.intentsService.executeIntent(EditCoreDataIntent, new EditCoreDataIntentOptions(this.entity!, { readonly: true }));
    }
}
