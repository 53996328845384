import { Injectable } from "@angular/core";
import { Camera, CameraResultType, CameraSource, GalleryPhotos, PermissionStatus, Photo } from "@capacitor/camera";

import { environment } from "../../../../environments/environment";
import { AppException } from "../../../business/entities/exceptions/app-exception";
import { FrontendErrors } from "../../../business/global/frontend-errors";
import { CameraComponent } from "../../components/camera/camera.component";
import { CameraOptions } from "../../components/camera/camera-options";
import { TypeScriptHelper } from "../../helpers/type-script-helper";
import { DialogService } from "../dialog/dialog.service";
import { ModalModes } from "../dialog/modal-modes";

/**
 * The service to interact with the camera and image gallery.
 */
@Injectable({
    providedIn: "root"
})
export class CameraService {
    constructor(
        private readonly dialogService: DialogService
    ) {
        // Do nothing for now
    }

    private cameraImageQuality: number = environment.cameraImageQuality;

    private cameraMaxSize: number = environment.cameraMaxSize;

    private async convertBase64IntoPhotoObject(base64Photo?: string): Promise<Photo|undefined> {
        if (base64Photo) {
            const blob: Blob = await TypeScriptHelper.base64StringToBlob(base64Photo, "image/jpeg");
            return {
                saved: false,
                format: "image/jpeg",
                webPath: URL.createObjectURL(blob)
            };
        }
    }

    public async takePhotoMethodA(_allowEditing: boolean = true, multiShotCallback?: (photo: Photo) => void, multiShotActivated: boolean = false): Promise<Photo|undefined> {
        const callback: (base64: string) => void = async (base64: string) => {
            if (multiShotCallback) {
                const photo: Photo|undefined = await this.convertBase64IntoPhotoObject(base64);

                if (photo) {
                    multiShotCallback(photo);
                }
            }
        };

        const base64String: string|undefined = await this.dialogService.showModal({
            component: CameraComponent,
            mode: ModalModes.fullscreen,
            payload: { options: new CameraOptions(multiShotCallback ? callback : undefined, multiShotActivated) }
        });

        return this.convertBase64IntoPhotoObject(base64String);
    }

    public async takePhotoMethodB(allowEditing: boolean = true): Promise<Photo|undefined> {
        try {
            let permission: PermissionStatus = await Camera.checkPermissions();
            if (permission.camera != "granted") {
                await Camera.requestPermissions({ permissions: ["camera"] });
                permission = await Camera.checkPermissions();
            }
            if (permission.camera != "granted") {
                console.warn("Unable to get camera permission.");
            }
        } catch (error) {
            // Some browsers do not support permissions, and it usually works just to request the photo.
            console.warn(error);
        }

        try {
            const photo: Photo = await Camera.getPhoto({
                resultType: CameraResultType.Uri as CameraResultType,
                source: CameraSource.Camera as CameraSource,
                correctOrientation: true,
                allowEditing: allowEditing,
                presentationStyle: "fullscreen",
                quality: this.cameraImageQuality,
                height: this.cameraMaxSize,
                width: this.cameraMaxSize
            });
            console.info(photo);
            return photo;
        } catch (error) {
            const typedError: Error = error as Error;
            if (typedError && typedError.message && typedError.message.startsWith("User ")) {
                // User interaction prevented taking image, e.g. user cancelled taking the photo. Unfortunately there is not yet another way to check this except parsing the error message.
                console.warn(error);
                return undefined;
            }
            throw new AppException(FrontendErrors.FE2UnableToAccessCamera, $localize`:@@exception.fe2UnableToAccessCamera:Unable to access the camera of the device. Please check the permissions and try again.`, typedError);
        }
    }

    public async getPhotosFromGalleryCapacitor(): Promise<GalleryPhotos|undefined> {
        try {
            let permission: PermissionStatus = await Camera.checkPermissions();
            if (permission.photos != "granted") {
                await Camera.requestPermissions({ permissions: ["photos"] });
                permission = await Camera.checkPermissions();
            }
            if (permission.photos != "granted") {
                console.warn("Unable to get photos permission.");
            }
        } catch (error) {
            // Some browsers do not support permissions, and it usually works just to request the photo.
            console.warn(error);
        }

        try {
            return await Camera.pickImages({
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                correctOrientation: true,
                quality: this.cameraImageQuality,
                height: this.cameraMaxSize,
                width: this.cameraMaxSize
            });
        } catch (error) {
            const typedError: Error = error as Error;
            if (typedError && typedError.message && typedError.message.startsWith("User ")) {
                // User interaction prevented picking an image, e.g. user cancelled the action. Unfortunately there is not yet another way to check this except parsing the error message.
                console.warn(error);
                return undefined;
            }
            throw new AppException(FrontendErrors.FE1UnableToAccessGallery, $localize`:@@exception.fe1UnableToAccessGallery:Unable to access the gallery of the device. Please check the permissions and try again.`, typedError);
        }
    }

    public getPhotosFromGallery(multiple: boolean = true): Promise<GalleryPhotos|undefined> {
        const result: GalleryPhotos = {
            photos: []
        };

        const id: string = "dynamicImagePicker";

        let inputElement: HTMLInputElement = document.querySelector(`#${id}`) as HTMLInputElement;

        const cleanup: () => void = (): void => {
            document.body.removeChild(inputElement);
        };

        return new Promise((resolve: (value: (PromiseLike<GalleryPhotos|undefined>|GalleryPhotos|undefined)) => void) => {

            if (!inputElement) {
                inputElement = document.createElement("input");
                inputElement.id = id;
                inputElement.type = "file";
                inputElement.hidden = true;
                inputElement.multiple = multiple;
                inputElement.accept = "image/jpeg,image/png";
                inputElement.onchange = (): void => {
                    const files: FileList|null = inputElement.files;

                    if (files) {
                        for (const file of files) {
                            result.photos.push({
                                path: file.name,
                                format: file.type,
                                webPath: URL.createObjectURL(file)
                            });
                        }
                    }

                    cleanup();

                    resolve(result);
                };

                document.body.appendChild(inputElement);
            }

            inputElement.click();
        });
    }
}
