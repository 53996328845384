<ion-list *ngIf="error">
    <ion-item class="item-text-wrap" lines="none">
        <p *ngIf="error.message">
            {{error.message}}<span *ngIf="appException && appException.errorCode">&nbsp;({{appException.errorCode}})</span>
        </p>
        <p *ngIf="!error.message">
            {{error}}<span *ngIf="appException && appException.errorCode">&nbsp;({{appException.errorCode}})</span>
        </p>
    </ion-item>
    <ion-item *ngIf="appException && appException.innerException" class="innerException item-text-wrap" lines="none">
        <p>
            <span *ngIf="appException.innerErrorDetails"><ng-container i18n="@@errorView.innerErrorDetails">Details:</ng-container>&nbsp;{{appException.innerErrorDetails}}<br /><br /></span>
            <span *ngIf="appException.innerErrorCode"><ng-container i18n="@@errorView.innerErrorCode">Inner error code:</ng-container>&nbsp;{{appException.innerErrorCode}}<br /></span>
            <span *ngIf="appException.innerPayload?.code"><ng-container i18n="@@errorView.innerErrorCode">Inner error code:</ng-container>&nbsp;{{appException.innerPayload.code}}<br /></span>
            <span *ngIf="appException.innerStatusCode"><ng-container i18n="@@errorView.innerStatusCode">Status code:</ng-container>&nbsp;{{appException.innerStatusCode}}<br /></span>
            <span *ngIf="appException.innerErrorName">{{appException.innerErrorName}}<br /></span>

            <span *ngIf="appException.innerException.message">
                {{appException.innerException.message}}<br />
            </span>
            <span *ngIf="appException.innerPayload?.details">
                {{appException.innerPayload.details}}<br />
            </span>
            <span *ngIf="!appException.innerException.message">
                {{appException.innerException}}
            </span>

            <span *ngIf="developerInfo">
                <br /><br />{{developerInfo}}
            </span>
        </p>
    </ion-item>
    <ion-item *ngIf="appException && appException.errorCode" lines="none">
        <ion-buttons>
            <ion-button (click)="searchSolution(appException.innerErrorCode)" *ngIf="appException.innerErrorCode">
                <fa-icon [icon]="appIcons.genericSearch"></fa-icon>&nbsp;<ng-container i18n="@@errorView.searchSolutionOnline">Search solution online</ng-container>&nbsp;({{appException.innerErrorCode}})
            </ion-button>
            <ion-button (click)="searchSolution(appException.errorCode)">
                <fa-icon [icon]="appIcons.genericSearch"></fa-icon>&nbsp;<ng-container i18n="@@errorView.searchSolutionOnline">Search solution online</ng-container>
                <ng-container *ngIf="appException.innerErrorCode">&nbsp;({{appException.errorCode}})</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-item>
    <ion-item *ngIf="false" lines="none">
        <ion-buttons>
            <ion-button (click)="copyToClipboard()">
                <fa-icon [icon]="appIcons.copyToClipboard"></fa-icon>&nbsp;<ng-container i18n="@@errorView.copyToClipboard">Copy to clipboard</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-item>
</ion-list>
