import { SectionIdentifier } from "../../identifiers/section-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";

/**
 * Event arguments for updated repeatable section.
 */
export class RepeatableSectionUpdateEventArguments {
    constructor(sectionTemplateIdentifier: SectionTemplateIdentifier, sectionIdentifier: SectionIdentifier) {
        this.sectionTemplateIdentifier = sectionTemplateIdentifier;
        this.sectionIdentifier = sectionIdentifier;
    }

    public sectionTemplateIdentifier: SectionTemplateIdentifier;
    public sectionIdentifier: SectionIdentifier;
}
