import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { SamplingPlanMergeStorageDto } from "../../../entities/samplings/sampling-plan-merge-storage-dto";
import { PartialDocumentUpdateStorageDto } from "./partial-document-update-storage-dto";

/**
 * Storage DTO for {@link SamplingPlanUpdate}.
 */
export class SamplingPlanUpdateStorageDto extends PartialDocumentUpdateStorageDto {
    constructor(init?: Partial<SamplingPlanUpdateStorageDto>) {
        super(init);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public samplingPlan?: SamplingPlanMergeStorageDto;
}
