/* eslint-disable max-classes-per-file */

export type SafeResult<TL, TA> = SuccessResult<TL, TA>|ErrorResult<TL, TA>;

/**
 * The successful result option.
 */
export class SuccessResult<TValue, TError> {
    public readonly result: TValue;

    constructor(result: TValue) {
        this.result = result;
    }

    public isSuccess(): this is SuccessResult<TValue, TError> {
        return true;
    }

    public isError(): this is ErrorResult<TValue, TError> {
        return false;
    }
}

/**
 * The error result option.
 */
export class ErrorResult<TResult, TError> {
    public readonly error: TError;

    constructor(error: TError) {
        this.error = error;
    }

    public isSuccess(): this is SuccessResult<TResult, TError> {
        return false;
    }

    public isError(): this is ErrorResult<TResult, TError> {
        return true;
    }
}

export const successResult: <TResult, TError>(result: TResult) => SafeResult<TResult, TError> = <TResult, TError>(result: TResult): SafeResult<TResult, TError> => new SuccessResult(result);

export const errorResult: <TResult, TError>(error: TError) => SafeResult<TResult, TError> = <TResult, TTError>(error: TTError): SafeResult<TResult, TTError> => new ErrorResult<TResult, TTError>(error);

