import { FileAttachmentStorageDto } from "./file-attachment-storage-dto";

/**
 * Storage DTO for {@link ImageAttachment}.
 */
export class ImageAttachmentStorageDto extends FileAttachmentStorageDto {
    public width?: number;

    public height?: number;

    public printQuality?: number;

    public storedImageIsFromLocalDevice?: boolean;
}
