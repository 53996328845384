import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { AnswerTemplateIdentifier } from "../../identifiers/answer-template-identifier";
import { AppIcons } from "../../services/icons/app-icons";
import { StorableEntity } from "../storable-entity";
import { AnswerScorings } from "./answer-scorings";
import { DocumentAnswerComment } from "./document-answer-comment";
import { DocumentAnswerStorageDto } from "./document-answer-storage-dto";
import { DocumentAnswerValue } from "./document-answer-value";

/**
 * Specification for the selectable values of a question.
 */
export class DocumentAnswer implements StorableEntity<DocumentAnswerStorageDto> {
    public constructor(templateIdentifier: AnswerTemplateIdentifier, init?: Partial<DocumentAnswer>) {
        TypeScriptHelper.initObjectFromPartial(this, init);

        this.templateIdentifier = templateIdentifier;
    }

    public templateIdentifier: AnswerTemplateIdentifier;
    public value: DocumentAnswerValue = new DocumentAnswerValue();
    public label?: string;
    public unit?: string;
    public unitLabel?: string;
    public order: number = 0;
    public commentMandatory: boolean = false;

    public scoring: AnswerScorings = AnswerScorings.neutral;

    public get icon(): IconDefinition|undefined {
        return this.scoringToIcon(this.scoring);
    }

    public get color(): string|undefined {
        return this.scoringToColor(this.scoring);
    }

    public comments: Array<DocumentAnswerComment> = [];

    public scoringToIcon(scoring?: AnswerScorings): IconDefinition|undefined {
        switch (scoring) {
            case AnswerScorings.good:
                return AppIcons.answerScoringGood;
            case AnswerScorings.warning:
                return AppIcons.answerScoringWarning;
            case AnswerScorings.critical:
                return AppIcons.answerScoringCritical;
            case AnswerScorings.neutral:
            case AnswerScorings.unknown:
            default:
                return undefined;
        }
    }

    public scoringToColor(scoring?: AnswerScorings): string|undefined {
        switch (scoring) {
            case AnswerScorings.good:
                return "success";
            case AnswerScorings.warning:
                return "warning";
            case AnswerScorings.critical:
                return "danger";
            case AnswerScorings.neutral:
            case AnswerScorings.unknown:
            default:
                return undefined;
        }
    }

    public toStorageDto(): DocumentAnswerStorageDto {
        const dto: DocumentAnswerStorageDto = new DocumentAnswerStorageDto();
        dto.templateIdentifier = this.templateIdentifier;
        dto.value = this.value.toStorageDto();
        dto.label = this.label;
        dto.unit = this.unit;
        dto.unitLabel = this.unitLabel;
        dto.order = this.order;
        dto.scoring = this.scoring;
        dto.commentMandatory = this.commentMandatory;

        for (const comment of this.comments) {
            dto.comments.push(comment.toStorageDto());
        }

        return dto;
    }

    public fromStorageDto(dto: DocumentAnswerStorageDto|undefined): void {
        this.templateIdentifier = dto?.templateIdentifier as AnswerTemplateIdentifier;
        this.value = new DocumentAnswerValue();
        this.value.fromStorageDto(dto?.value);
        this.label = dto?.label;
        this.unit = dto?.unit;
        this.unitLabel = dto?.unitLabel;
        this.order = dto?.order ?? 0;
        this.scoring = dto?.scoring ? dto.scoring as AnswerScorings : AnswerScorings.unknown;
        this.commentMandatory = dto?.commentMandatory ?? false;

        this.comments = [];
        for (const commentDto of dto?.comments ?? []) {
            const comment: DocumentAnswerComment = new DocumentAnswerComment();
            comment.fromStorageDto(commentDto);
            this.comments.push(comment);
        }
    }
}
