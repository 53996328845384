import { LocalFileStorageIdentifier } from "../../services/attachments/local-file-storage-identifier";
import { StorableEntity } from "../storable-entity";
import { Attachment } from "./attachment";
import { AttachmentStorageDto } from "./attachment-storage-dto";
import { FileAttachmentStorageDto } from "./file-attachment-storage-dto";

/**
 * Generic file attachment only possible to store and download.
 */
export abstract class FileAttachment extends Attachment implements StorableEntity<FileAttachmentStorageDto> {
    public remoteUrl?: string;

    public localUrl?: string;

    public remoteUrlPreview?: string;

    public localUrlPreview?: string;

    public filename?: string;

    public mimeType?: string;

    public localStorageId?: LocalFileStorageIdentifier;

    public localStorageIdPreview?: LocalFileStorageIdentifier;

    public storedFileIsFromLocalDevice: boolean = false;

    protected abstract toAttachmentDtoSpecificProperties(): FileAttachmentStorageDto;

    protected abstract fromAttachmentDtoSpecificProperties(dto: FileAttachmentStorageDto|undefined): void;

    protected toStorageDtoSpecificProperties(): AttachmentStorageDto {
        const dto: FileAttachmentStorageDto = this.toAttachmentDtoSpecificProperties();
        dto.remoteUrl = this.remoteUrl;
        dto.remoteUrlPreview = this.remoteUrlPreview;
        dto.filename = this.filename;
        dto.mimeType = this.mimeType;
        dto.localStorageId = this.localStorageId;
        dto.localStorageIdPreview = this.localStorageIdPreview;

        // Local URL only works per application instance, therefore the localUrl is not valid and should not be stored
        dto.localUrl = undefined;
        dto.localUrlPreview = undefined;

        return dto;
    }

    protected fromStorageDtoSpecificProperties(dto: AttachmentStorageDto|undefined): void {
        this.fromAttachmentDtoSpecificProperties(dto);

        const fileAttachmentDto: FileAttachmentStorageDto = dto as FileAttachmentStorageDto;
        this.remoteUrl = fileAttachmentDto.remoteUrl;
        this.remoteUrlPreview = fileAttachmentDto.remoteUrlPreview;
        this.filename = fileAttachmentDto.filename;
        this.mimeType = fileAttachmentDto.mimeType;
        this.localStorageId = fileAttachmentDto?.localStorageId as LocalFileStorageIdentifier;
        this.localStorageIdPreview = fileAttachmentDto?.localStorageIdPreview as LocalFileStorageIdentifier;

        // Local URL only works per application instance, therefore the localUrl is not valid and should not be stored
        this.localUrl = undefined;
        this.localUrlPreview = undefined;
    }
}
