/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A generic object telling the client that the invoked endpoint succeeded.
 */
export interface ResponseBodySuccessDto { 
    status: ResponseBodySuccessDto.StatusEnum;
}
export namespace ResponseBodySuccessDto {
    export type StatusEnum = 'OK';
    export const StatusEnum = {
        Ok: 'OK' as StatusEnum
    };
}


