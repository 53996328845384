import { DateTimeHelper } from "../../../base/helpers/date-time-helper";
import { BusinessTechnicalIdentifierPair } from "../../identifiers/business-technical-identifier-pair";
import { EntityBusinessIdentifier } from "../../identifiers/entity-business-identifier";
import { EntityTechnicalIdentifier } from "../../identifiers/entity-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { EntityTypes } from "../entity-types";
import { StorableEntity } from "../storable-entity";
import { CoreDataEntityStorageDto } from "./core-data-entity-storage-dto";

/**
 * Base class for master data entities.
 */
export abstract class CoreDataEntity<TBusinessIdentifier extends EntityBusinessIdentifier, TTechnicalIdentifier extends EntityTechnicalIdentifier> implements StorableEntity<CoreDataEntityStorageDto> {
    protected constructor(entityType: EntityTypes) {
        this.entityType = entityType;
    }

    public identifier: BusinessTechnicalIdentifierPair<TBusinessIdentifier, TTechnicalIdentifier> = Identifier.empty();

    public readonly entityType: EntityTypes = EntityTypes.unknown;

    public created?: string;

    public updated?: string;

    public updatedClient?: string = DateTimeHelper.utcNow();

    public externalReference?: string;

    public abstract get displayTitle(): string;

    public abstract get isNew(): boolean;

    protected abstract toEntityStorageDto(): CoreDataEntityStorageDto;

    protected abstract fromEntityStorageDto(dto: CoreDataEntityStorageDto|undefined): void;

    public toStorageDto(): CoreDataEntityStorageDto {
        const dto: CoreDataEntityStorageDto = this.toEntityStorageDto();
        dto.entityType = EntityTypes[this.entityType];
        dto.businessId = this.identifier.businessIdentifier;
        dto.technicalId = this.identifier.technicalIdentifier;

        dto.created = this.created;
        dto.updated = this.updated;
        dto.updatedClient = this.updatedClient;

        dto.extRef = this.externalReference;

        return dto;
    }

    public fromStorageDto(dto: CoreDataEntityStorageDto|undefined): void {
        this.fromEntityStorageDto(dto);

        this.identifier.businessIdentifier = (dto?.businessId ?? "") as TBusinessIdentifier;
        this.identifier.technicalIdentifier = (dto?.technicalId ?? 0) as TTechnicalIdentifier;

        this.created = dto?.created;
        this.updated = dto?.updated;
        this.updatedClient = dto?.updatedClient;

        this.externalReference = dto?.extRef;
    }

    public abstract clone(): CoreDataEntity<TBusinessIdentifier, TTechnicalIdentifier>|never;
}
