import { IocService } from "../../base/services/ioc/ioc.service";
import { LoggedInIntent } from "./abstract/logged-in-intent";

/**
 * Intent to log in user.
 */
export class LoginIntent extends LoggedInIntent<LoginIntent> {
    constructor(
        iocService: IocService) {
        super(iocService);
    }

    public cancel(): Promise<void> {
        return Promise.resolve(undefined);
    }

    public async executeIntent(): Promise<boolean> {
        return true;
    }
}
