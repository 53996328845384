import { DBConfig } from "ngx-indexed-db";

import { environment } from "../../../environments/environment";

/**
 * Local database configuration.
 */
export class LocalDatabaseConfiguration {
    public static databaseConfiguration: DBConfig = {
        name: environment.additionalDatabaseName,
        version: 1,
        objectStoresMeta: [{
            store: environment.fileStorageName,
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: []
        }],
        migrationFactory: databaseMigrationFactory
    };
}

/**
 * Migration function for local database.
 *
 * @returns Migration methods.
 */
export function databaseMigrationFactory(): { [key: number]: (db: IDBDatabase, transaction: IDBTransaction) => void } {
    return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        1: (_db: IDBDatabase, _transaction: IDBTransaction): void => {
            // There is nothing to migrate in version 1
        }
    };
}
