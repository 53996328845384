import { DocumentSectionStorageDto } from "./document-section-storage-dto";

/**
 * Storage DTO class for {@link DocumentTemplate}.
 */
export class DocumentTemplateStorageDto {
    public technicalIdentifier?: number;

    public documentType?: string;

    public name?: string;

    public sections: Array<DocumentSectionStorageDto> = [];
}
