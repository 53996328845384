import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";

/**
 * A single error item.
 */
export class ErrorLogItem {
    constructor(init?: Partial<ErrorLogItem>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public time?: string;

    public deviceId?: string;

    public personId?: string;

    public sessionId?: string;

    public code?: string;

    public error?: string;

    public innerError?: string;

    public activeUrl?: string;
}
