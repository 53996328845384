export enum AttachmentStates {
    unknown = "unknown",
    storingLocally = "storingLocally",
    storedLocally = "storedLocally",
    uploading = "uploading",
    uploaded = "uploaded",
    updatingMeta = "updatingMeta",
    metaUpdated = "metaUpdated",

    noInternetUploading = "noInternetUploading",
    noInternetUpdatingMeta = "noInternetUpdatingMeta",

    errorUploading = "errorUploading",
    errorUpdatingMeta = "errorUpdatingMeta"
}
