import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { AttachmentStorageDto } from "../../../entities/attachments/attachment-storage-dto";
import { DocumentAnswerValueStorageDto } from "../../../entities/documents/document-answer-value-storage-dto";
import { PartialDocumentUpdateStorageDto } from "./partial-document-update-storage-dto";

/**
 * Storage DTO for {@link DocumentQuestionUpdate}.
 */
export class DocumentQuestionUpdateStorageDto extends PartialDocumentUpdateStorageDto {
    constructor(init?: Partial<DocumentQuestionUpdateStorageDto>) {
        super(init);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public sectionTemplateIdentifier?: number;
    public sectionBusinessIdentifier?: string;
    public sectionTechnicalIdentifier?: number;

    public questionTemplateIdentifier?: number;
    public questionBusinessIdentifier?: string;
    public questionTechnicalIdentifier?: number;

    public answers: Array<DocumentAnswerValueStorageDto> = [];
    public linkedAttachments: Array<AttachmentStorageDto> = [];

    public commentTemplateIdentifier?: number;
    public commentValue?: string;
}
