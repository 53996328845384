import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { LoggedInGuard } from "./business/guards/logged-in-guard";

const routes: Routes = [
    { path: "", redirectTo: "dashboard", pathMatch: "full" },
    { path: "*", redirectTo: "dashboard" },
    { path: "start", redirectTo: "dashboard" },
    {
        path: "dashboard",
        loadChildren: () => import("./business/pages/dashboard/dashboard.module").then((m: any) => m.DashboardPageModule)
    },
    {
        path: "user-session",
        loadChildren: (): any => import("./business/pages/user-session/user-session.module").then((m: any) => m.UserSessionPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "document/:documentId/:contentType/:chapterId/:sectionIds",
        loadChildren: (): any => import("./business/pages/document/document.module").then((m: any) => m.DocumentPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "project",
        loadChildren: () => import("./business/pages/project/project.module").then((m: any) => m.ProjectPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "project/:projectBusinessId",
        loadChildren: () => import("./business/pages/project/project.module").then((m: any) => m.ProjectPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "project/:projectBusinessId/:projectTechnicalId",
        loadChildren: () => import("./business/pages/project/project.module").then((m: any) => m.ProjectPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "process",
        loadChildren: () => import("./business/pages/process/process.module").then((m: any) => m.ProcessPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "process/:projectBusinessId",
        loadChildren: () => import("./business/pages/process/process.module").then((m: any) => m.ProcessPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "process/:projectBusinessId/:processBusinessId",
        loadChildren: () => import("./business/pages/process/process.module").then((m: any) => m.ProcessPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "document/:documentId/:contentType/:chapterId",
        loadChildren: (): any => import("./business/pages/document/document.module").then((m: any) => m.DocumentPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "document/:documentId/:contentType",
        loadChildren: (): any => import("./business/pages/document/document.module").then((m: any) => m.DocumentPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "document/:documentId",
        loadChildren: (): any => import("./business/pages/document/document.module").then((m: any) => m.DocumentPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "document",
        loadChildren: (): any => import("./business/pages/document/document.module").then((m: any) => m.DocumentPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "document",
        loadChildren: (): any => import("./business/pages/document/document.module").then((m: any) => m.DocumentPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "diagram-editor",
        loadChildren: (): any => import("./business/pages/diagram-editor/diagram-editor.module").then((m: any) => m.DiagramEditorPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "core-data",
        loadChildren: () => import("./business/pages/core-data/core-data-list/core-data-list.module").then((m: any) => m.CoreDataListPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "core-data/:entityType",
        loadChildren: () => import("./business/pages/core-data/core-data-list/core-data-list.module").then((m: any) => m.CoreDataListPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "core-data/:entityType/:searchQuery",
        loadChildren: () => import("./business/pages/core-data/core-data-list/core-data-list.module").then((m: any) => m.CoreDataListPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: "storage-info",
        loadChildren: () => import("./business/pages/storage-info/storage-info.module").then((m: any) => m.StorageInfoPageModule)
    },
    {
        path: "intent/:intent",
        loadChildren: () => import("./business/pages/intents/intents.module").then((m: any) => m.IntentsPageModule)
    },
    {
        path: "intent",
        loadChildren: () => import("./business/pages/intents/intents.module").then((m: any) => m.IntentsPageModule)
    },
    {
        path: "login",
        loadChildren: () => import("./base/pages/login/login.module").then((m: any) => m.LoginPageModule)
    },
    {
        path: "certificates",
        loadChildren: () => import("./business/pages/certificates/certificates.module").then((m: any) => m.CertificatesPageModule)
    },
    {
        path: "**",
        loadChildren: (): any => import("./business/pages/not-found/not-found.module").then((m: any) => m.NotFoundPageModule)
    }
];

/**
 * The main routing entrypoint for the page navigation.
 */
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
