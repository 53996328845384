import { Component, Input, OnInit } from "@angular/core";

/**
 * A wrapper for the {@link HTMLIonContentElement} to apply global designs.
 */
@Component({
    selector: "base-page-content-container",
    templateUrl: "./page-content-container.component.html",
    styleUrls: ["./page-content-container.component.scss"]
})
export class PageContentContainerComponent implements OnInit {
    @Input()
    public usePadding: boolean = true;

    @Input()
    public title?: string;

    public ngOnInit(): void {
        // Do nothing for now
    }
}
