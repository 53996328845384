import { Component, Input, OnInit } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { AppIcons } from "../../services/icons/app-icons";
import { LocalizationService } from "../../services/localization/localization.service";
import { PrivacyPolicyComponentOptions, PrivacyPolicyContents } from "./privacy-policy.component.options";

/**
 * Component to show the privacy policy.
 */
@Component({
    selector: "app-privacy-policy",
    templateUrl: "./privacy-policy.component.html",
    styleUrls: ["./privacy-policy.component.scss"]
})
export class PrivacyPolicyComponent implements OnInit {
    constructor(
        private readonly localizationService: LocalizationService
    ) {
        // Do nothing for now
    }

    public readonly appIcons: typeof AppIcons = AppIcons;
    public readonly contents: typeof PrivacyPolicyContents = PrivacyPolicyContents;

    public emailChunks: Array<string> = environment.supportEmailChunks;
    public emailChunkPositions: Array<number> = environment.supportEmailChunkPositions;

    @Input()
    public options!: PrivacyPolicyComponentOptions;

    public language: string = this.localizationService.localeId;

    public ngOnInit(): void {
        this.options = this.options ?? new PrivacyPolicyComponentOptions(PrivacyPolicyContents.privacyPolicy);
    }

    public navigate(content: PrivacyPolicyContents): void {
        this.options.content = content;
    }
}
