import { Component, Input, ViewChild } from "@angular/core";
import { MatTable } from "@angular/material/table";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { QuestionSaveStates } from "src/app/business/entities/documents/question-save-states";

import { BaseComponent } from "../../../../base/components/base-component";
import { ArrayHelper } from "../../../../base/helpers/array-helper";
import { ProcessElementTriggeredEventArguments } from "../../../../base/interfaces/process-element-triggered-event-arguments";
import { FileAttachment } from "../../../entities/attachments/file-attachment";
import { AttachmentLoaderService } from "../../../services/attachments/attachment-loader.service";
import { DocumentQueuedUpdateService } from "../../../services/document-update/document-queued-update.service";
import { DocumentQuestionUpdate } from "../../../services/document-update/update-containers/document-question-update";
import { PartialDocumentUpdate } from "../../../services/document-update/update-containers/partial-document-update";
import { DocumentUpdateTypes } from "../../../services/documents/document-update-types";
import { I18nTextService } from "../../../services/i18n/i18n-text.service";
import { AppIcons } from "../../../services/icons/app-icons";
import { IconsService } from "../../../services/icons/icons.service";
import { SyncErrorListOptions } from "./sync-error-list-options";
import { SyncTableItem } from "./sync-table-item";
import { SyncTableTypes } from "./sync-table-types";

/**
 * Component to show and handle synchronization errors.
 */
@Component({
    selector: "app-sync-error-list",
    templateUrl: "./sync-error-list.component.html",
    styleUrls: ["./sync-error-list.component.scss"]
})
export class SyncErrorListComponent extends BaseComponent {
    constructor(
        private readonly documentQueuedUpdateService: DocumentQueuedUpdateService,
        private readonly attachmentLoaderService: AttachmentLoaderService,
        private readonly iconsService: IconsService,
        private readonly i18nTextService: I18nTextService
    ) {
        super();
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly syncTypes: typeof SyncTableTypes = SyncTableTypes;

    @Input()
    public options!: SyncErrorListOptions;

    public pendingUpdates: Array<PartialDocumentUpdate> = [];

    public pendingUploads: Array<FileAttachment> = [];

    public tableContent: Array<SyncTableItem> = [];

    public displayedColumns: Array<string> = ["icon", "document", "type", "error", "description", "actions"];

    @ViewChild("table")
    public tableElement?: MatTable<any>;

    protected componentInit(): void {
        this.options = this.options ?? new SyncErrorListOptions();

        this.subscribe(this.attachmentLoaderService.uploadQueueChanged, this.refresh);
        this.subscribe(this.documentQueuedUpdateService.synchronizationSummaryUpdated, this.refresh);

        this.refresh();
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public refresh(): void {
        this.pendingUpdates = this.documentQueuedUpdateService.pendingUpdates;
        this.pendingUploads = this.attachmentLoaderService.getQueuedItems();

        const toRemove: Array<SyncTableItem> = [];

        // Remove all that are not present anymore
        for (const syncTableItem of this.tableContent) {
            if (syncTableItem.documentUpdate) {
                if (!this.pendingUpdates.some((item: PartialDocumentUpdate) => item == syncTableItem.documentUpdate)) {
                    toRemove.push(syncTableItem);
                }
            }
            if (syncTableItem.fileAttachment) {
                if (!this.pendingUploads.some((item: FileAttachment) => item == syncTableItem.fileAttachment)) {
                    toRemove.push(syncTableItem);
                }
            }
        }
        for (const removeTableItem of toRemove) {
            ArrayHelper.removeElement(this.tableContent, removeTableItem);
        }

        for (const pendingUpdate of this.pendingUpdates) {
            if (!this.tableContent.some((item: SyncTableItem) => item.documentUpdate == pendingUpdate)) {
                this.tableContent.push(new SyncTableItem(SyncTableTypes.question, pendingUpdate));
            }
        }

        for (const pendingUpload of this.pendingUploads) {
            if (!this.tableContent.some((item: SyncTableItem) => item.fileAttachment == pendingUpload)) {
                this.tableContent.push(new SyncTableItem(SyncTableTypes.image, pendingUpload));
            }
        }

        this.tableElement?.renderRows();
    }

    public retry(update: PartialDocumentUpdate, triggeredEventArguments: ProcessElementTriggeredEventArguments): void {
        this.documentQueuedUpdateService.retry(update);

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        setTimeout(() => triggeredEventArguments.finishedReceiver.finished(), 1000);
    }

    public async delete(update: PartialDocumentUpdate, triggeredEventArguments: ProcessElementTriggeredEventArguments): Promise<void> {
        await this.documentQueuedUpdateService.delete(update);

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        setTimeout(() => triggeredEventArguments.finishedReceiver.finished(), 500);
    }

    public getSaveStateIcon(saveState: QuestionSaveStates): IconDefinition {
        return this.iconsService.getQuestionSaveStateFaIcon(saveState);
    }

    public getDocumentUpdateTypeText(updateType: DocumentUpdateTypes): string {
        return this.i18nTextService.getDocumentUpdateTypeText(updateType);
    }

    public asQuestionUpdate(update: PartialDocumentUpdate): DocumentQuestionUpdate {
        return update as DocumentQuestionUpdate;
    }
}
