import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { EntityIdentifierStorageDto } from "../../identifiers/entity-identifier-storage-dto";
import { Identifier } from "../../identifiers/identifier";
import { ProcessBusinessIdentifier } from "../../identifiers/process-business-identifier";
import { ProcessTechnicalIdentifier } from "../../identifiers/process-technical-identifier";
import { ProjectIdentifier } from "../../identifiers/project-identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { BuildingComplex } from "../core-data/buildings/building-complex";
import { CoreDataEntity } from "../core-data/core-data-entity";
import { GenericCoreDataEntity } from "../core-data/generic-core-data-entity";
import { Person } from "../core-data/person";
import { AuditDocument } from "../documents/audit-document";
import { EntityLink } from "../documents/entity-link";
import { EntityTypes } from "../entity-types";
import { ProcessStatus } from "./process-status";
import { ProcessStorageDto } from "./process-storage-dto";
import { ProcessTypes } from "./process-types";

/**
 * A process within a {@link Project}.
 */
export class Process extends CoreDataEntity<ProcessBusinessIdentifier, ProcessTechnicalIdentifier> {
    constructor(init?: Partial<Process>) {
        super(EntityTypes.process);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public projectIdentifier: ProjectIdentifier = Identifier.empty();

    public processType: ProcessTypes = ProcessTypes.unknown;

    public status: ProcessStatus = ProcessStatus.unknown;

    public targetDate?: string;

    public targetEndDate?: string;

    public linkedAssignee?: EntityLink<Person>;

    public linkedEntities: Array<EntityLink<GenericCoreDataEntity>> = [];

    public linkedDocuments: Array<EntityLink<AuditDocument>> = [];

    public additionalInfo?: string;

    public get displayTitle(): string {
        const builder: Array<string> = [];
        const buildingComplex: EntityLink<BuildingComplex>|undefined = this.linkedEntities.find((link: EntityLink<GenericCoreDataEntity>) => link.entityType == EntityTypes.buildingComplex) as EntityLink<BuildingComplex>|undefined;
        if (buildingComplex?.displayTitle) {
            builder.push(buildingComplex?.displayTitle);
        }
        if (builder.length <= 0) {
            builder.push(Identifier.identifierToString(this.identifier));
            if (buildingComplex) {
                builder.push(Identifier.identifierToString(buildingComplex.identifier));
            }
        }
        return builder.join(", ");
    }

    public get processTypeTitle(): string {
        switch (this.processType) {
            case ProcessTypes.buildingWaterWalkthroughInspection:
                return $localize`:@@process.typeTitleBuildingWaterWalkthroughInspection:Walkthrough Inspection`;
            case ProcessTypes.unknown:
                return $localize`:@@process.typeTitleUnknown:Unknown`;
            default:
                TypeScriptHelper.expectNever(this.processType);
                return $localize`:@@process.typeTitleUnknown:Unknown`;
        }
    }

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    protected toEntityStorageDto(): ProcessStorageDto {
        const dto: ProcessStorageDto = new ProcessStorageDto({
            processType: this.processType,
            status: this.status,
            projectIdentifier: EntityIdentifierStorageDto.fromIdentifier(this.projectIdentifier),
            linkedAssignee: this.linkedAssignee?.toStorageDto(),
            targetDate: this.targetDate,
            targetEndDate: this.targetEndDate,
            additionalInfo: this.additionalInfo
        });

        dto.linkedEntities = [];
        for (const entityLink of this.linkedEntities) {
            dto.linkedEntities.push(entityLink.toStorageDto());
        }

        dto.linkedDocuments = [];
        for (const documentLink of this.linkedDocuments) {
            dto.linkedDocuments.push(documentLink.toStorageDto());
        }

        return dto;
    }

    protected fromEntityStorageDto(dto: ProcessStorageDto|undefined): void {
        this.processType = TypeScriptHelper.dtoToEnum(ProcessTypes, dto?.processType, ProcessTypes.unknown) as ProcessTypes;
        this.status = TypeScriptHelper.dtoToEnum(ProcessStatus, dto?.status, ProcessStatus.unknown) as ProcessStatus;
        this.projectIdentifier = Identifier.create(dto?.projectIdentifier?.bid, dto?.projectIdentifier?.tid);
        this.linkedAssignee = dto?.linkedAssignee ? CoreDataFactory.createEntityLinkFromStorageDto<Person>(dto.linkedAssignee) : undefined;
        this.targetDate = dto?.targetDate;
        this.targetEndDate = dto?.targetEndDate;
        this.additionalInfo = dto?.additionalInfo;

        this.linkedEntities = [];
        for (const linkedEntityDto of dto?.linkedEntities ?? []) {
            const linkedEntity: EntityLink<GenericCoreDataEntity>|undefined = CoreDataFactory.createEntityLinkFromStorageDto(linkedEntityDto);
            if (linkedEntity) {
                this.linkedEntities.push(linkedEntity);
            }
        }

        this.linkedDocuments = [];
        for (const linkedDocumentDto of dto?.linkedDocuments ?? []) {
            const linkedDocument: EntityLink<AuditDocument>|undefined = CoreDataFactory.createEntityLinkFromStorageDto(linkedDocumentDto) as EntityLink<AuditDocument>|undefined;
            if (linkedDocument) {
                this.linkedDocuments.push(linkedDocument);
            }
        }
    }

    public clone(): Process {
        const clone: Process = CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
        clone.linkedEntities = [];
        for (const link of this.linkedEntities) {
            const linkClone: EntityLink<GenericCoreDataEntity> = CoreDataFactory.createEntityLinkFromStorageDto(link.toStorageDto()) as EntityLink<GenericCoreDataEntity>;
            linkClone.entity = link.entity?.clone();
            clone.linkedEntities.push(linkClone);
        }
        return clone;
    }
}
