import { Injectable } from "@angular/core";

import { EventHelper } from "../../../base/helpers/event-helper";
import { SessionService } from "../session/session.service";
import { SessionChangedEventData } from "../session/session-changed-event-data";

/**
 * Service to monitor the user state and trigger specific intents like navigating to the start page.
 */
@Injectable({
    providedIn: "root"
})
export class UserStateService {
    constructor(
        private readonly sessionService: SessionService
    ) {
        EventHelper.subscribe(this.sessionService.sessionChanged, this.sessionChanged, this);
    }

    public initialize(): Promise<void> {
        // There is nothing to do right now.
        return Promise.resolve();
    }

    private sessionChanged(_sessionData: SessionChangedEventData): void {
        // Do nothing for now
    }
}
