import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import {
    DefaultService, PermissionDto
} from "../../../generated/api";

/**
 * The service to talk to the server backend.
 */
@Injectable({
    providedIn: "root"
})
export class UsersApiService {
    constructor(
        private readonly defaultService: DefaultService
    ) {
    }

    public getMyPermissions(accountIdentifier: number): Promise<Array<PermissionDto>> {
        return lastValueFrom(this.defaultService.listMyPermissions(accountIdentifier));
    }
}
