import { Component, OnInit } from "@angular/core";

import { ProcessElementTriggeredEventArguments } from "../../../base/interfaces/process-element-triggered-event-arguments";
import { LocalFileService } from "../../services/attachments/local-file.service";
import { CachingService } from "../../services/caching/caching.service";
import { StorageService } from "../../services/storage/storage.service";

/**
 * Page with storage information.
 */
@Component({
    selector: "app-storage-info",
    templateUrl: "./storage-info.page.html",
    styleUrls: ["./storage-info.page.scss"]
})
export class StorageInfoPage implements OnInit {
    constructor(
        private readonly cachingService: CachingService,
        private readonly localFileService: LocalFileService,
        private readonly storageService: StorageService
    ) {
    }

    public loading: boolean = false;

    public datasets: number = 0;

    public storedFiles: number = 0;

    public storageEstimate?: StorageEstimate;

    public keys: Array<string> = [];

    public get storageEstimateAsAny(): any|undefined {
        return this.storageEstimate;
    }

    public async ngOnInit(): Promise<void> {
        await this.refresh();
    }

    public async refresh(): Promise<void> {
        this.loading = true;

        this.datasets = (await this.storageService.allKeys()).length;

        this.storedFiles = await this.localFileService.itemsCount();

        try {
            this.storageEstimate = await navigator.storage.estimate();
        } catch (error) {
            // Some browsers do not support estimate
            console.warn(error);
        }

        this.loading = false;
    }

    public async loadKeys(triggeredEventArguments: ProcessElementTriggeredEventArguments): Promise<void> {
        try {
            this.keys = await this.storageService.allKeys();
        } finally {
            triggeredEventArguments.finishedReceiver.finished();
        }
    }
}
