<ion-list>
    <!-- Switch account -->
    <ion-item (click)="installPwa()" *ngIf="!appState.pwaInstalled">
        <fa-icon [icon]="appIcons.installApp" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.installApp">Install app</ion-label>
    </ion-item>

    <!-- Switch account -->
    <ion-item (click)="openAccountSwitchMenu($event)" *ngIf="false">
        <fa-icon [icon]="appIcons.switchAccount" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.switchAccount">Switch account</ion-label>
    </ion-item>

    <!-- Profile -->
    <ion-item (click)="openProfileSettings()" *ngIf="activePerson">
        <fa-icon [icon]="appIcons.profileSettings" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.profileSettings">Profile settings</ion-label>
    </ion-item>

    <!-- Login -->
    <ion-item (click)="logIn()" *ngIf="!activePerson">
        <fa-icon [icon]="appIcons.login" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.logIn">Log in</ion-label>
    </ion-item>

    <!-- Register -->
    <ion-item (click)="register()" *ngIf="!activePerson">
        <fa-icon [icon]="appIcons.register" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.createAccount">Create account</ion-label>
    </ion-item>

    <!--------------- Show always --------------->

    <!-- Settings -->
    <ion-item (click)="openAppSettings()">
        <fa-icon [icon]="appIcons.appSettings" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.appSettings">Application settings</ion-label>
    </ion-item>

    <!-- Support -->
    <ion-item (click)="openSupport()">
        <fa-icon [icon]="appIcons.support" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.support">Support</ion-label>
    </ion-item>

    <!-- Changelog -->
    <ion-item (click)="openChangelog()">
        <fa-icon [icon]="appIcons.changelog" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.changelog">Changelog</ion-label>
    </ion-item>

    <!-- Imprint -->
    <ion-item (click)="openAbout()" [lines]="activePerson ? null : 'none'">
        <fa-icon [icon]="appIcons.about" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.aboutAndImprint">About & Imprint</ion-label>
    </ion-item>

    <!-- Logout -->
    <ion-item (click)="logOut()" *ngIf="activePerson" lines="none">
        <fa-icon [icon]="appIcons.logout" slot="start"></fa-icon>
        <ion-label i18n="@@userMenu.logOut">Log out</ion-label>
    </ion-item>
</ion-list>
