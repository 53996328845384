import { EventEmitter } from "@angular/core";

import { FrontendErrors } from "../../global/frontend-errors";
import { AnswerBusinessIdentifier } from "../../identifiers/answer-business-identifier";
import { AnswerIdentifier } from "../../identifiers/answer-identifier";
import { Identifier } from "../../identifiers/identifier";
import { QuestionIdentifier } from "../../identifiers/question-identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { SectionIdentifier } from "../../identifiers/section-identifier";
import { SectionTechnicalIdentifier } from "../../identifiers/section-technical-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";
import { DocumentFactory } from "../../services/documents/document-factory";
import { Attachment } from "../attachments/attachment";
import { AttachmentTypes } from "../attachments/attachment-types";
import { AppException } from "../exceptions/app-exception";
import { StorableEntity } from "../storable-entity";
import { AttachmentsUpdatedEventArguments } from "./attachments-updated-event-arguments";
import { DocumentAnswerComment } from "./document-answer-comment";
import { DocumentAnswerValue } from "./document-answer-value";
import { DocumentInstanceStorageDto } from "./document-instance-storage-dto";
import { DocumentQuestion } from "./document-question";
import { DocumentQuestionTypes } from "./document-question-types";
import { DocumentSection } from "./document-section";
import { DocumentSectionStorageDto } from "./document-section-storage-dto";
import { DocumentTemplate } from "./document-template";
import { DocumentValueContainer } from "./document-value-container";
import { RepeatableSectionUpdateEventArguments } from "./repeatable-section-update-event-arguments";

/**
 * The class to hold all instance values of a single document.
 */
export class DocumentInstance implements StorableEntity<DocumentInstanceStorageDto> {
    public constructor(
        documentTemplate: DocumentTemplate
    ) {
        this.documentTemplate = documentTemplate;
    }

    private readonly documentTemplate: DocumentTemplate;

    private readonly values: Map<QuestionTemplateIdentifier, DocumentValueContainer> = new Map<QuestionTemplateIdentifier, DocumentValueContainer>();

    private readonly repeatableQuestions: Map<SectionTemplateIdentifier, Array<DocumentSection>> = new Map<SectionTemplateIdentifier, Array<DocumentSection>>();

    public questionUpdated: EventEmitter<QuestionTemplateIdentifier> = new EventEmitter<QuestionTemplateIdentifier>();

    public attachmentLinksUpdated: EventEmitter<AttachmentsUpdatedEventArguments> = new EventEmitter<AttachmentsUpdatedEventArguments>();

    public repeatableSectionUpdated: EventEmitter<RepeatableSectionUpdateEventArguments> = new EventEmitter<RepeatableSectionUpdateEventArguments>();

    public createValueContainer(questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, sectionTemplateIdentifier: SectionTemplateIdentifier|undefined, sectionIdentifier: SectionIdentifier|undefined): DocumentValueContainer {
        const container: DocumentValueContainer = new DocumentValueContainer(this.documentTemplate.getQuestionType(questionTemplateIdentifier), questionTemplateIdentifier, questionIdentifier, sectionTemplateIdentifier, sectionIdentifier);
        this.values.set(questionTemplateIdentifier, container);

        return container;
    }

    public addSectionToRepeatableSection(sectionIdentifier: SectionTechnicalIdentifier, sectionTemplateIdentifier: SectionTemplateIdentifier): void {
        console.warn("TODO addSectionToRepeatableSection", sectionIdentifier, sectionTemplateIdentifier);
    }

    public setValue(questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, sectionTemplateIdentifier: SectionTemplateIdentifier|undefined, sectionIdentifier: SectionIdentifier|undefined, answerIdentifiers: Array<AnswerBusinessIdentifier>, answerValues: Array<any>, ...allowedTypes: Array<DocumentQuestionTypes>): DocumentValueContainer {
        let instanceValue: DocumentValueContainer|undefined = this.getValueContainer(questionTemplateIdentifier);
        if (!instanceValue) {
            instanceValue = this.createValueContainer(questionTemplateIdentifier, questionIdentifier, sectionTemplateIdentifier, sectionIdentifier);
        }
        const question: DocumentQuestion = this.documentTemplate.getQuestion(instanceValue.questionTemplateIdentifier);
        if (allowedTypes && allowedTypes.length > 0 && !allowedTypes.includes(question.type)) {
            throw new AppException(FrontendErrors.FE6UnableToSaveTypeMismatch, $localize`:@@exception.fe6InvalidTypeToSetValue:Unable to save the value with identifier ${questionTemplateIdentifier}:identifier: to an item of type ${DocumentQuestionTypes[question.type]}:itemType:.`);
        }

        const answers: Array<DocumentAnswerValue> = [];
        for (const answerIdentifier of answerIdentifiers) {
            const answerValue: DocumentAnswerValue = new DocumentAnswerValue();
            answerValue.answerIdentifier = Identifier.create<AnswerIdentifier>(answerIdentifier);
            answers.push(answerValue);
        }
        for (const answer of answerValues) {
            const answerValue: DocumentAnswerValue = new DocumentAnswerValue();
            answerValue.value = answer as unknown;
            answers.push(answerValue);
        }
        instanceValue.answers = answers;

        // If an ID has been set and the comment is also an ID we have to remove the comment
        if (answerIdentifiers.length > 0 && instanceValue.comment?.identifier) {
            instanceValue.comment = undefined;
        }

        if (this.questionUpdated.length <= 0) {
            console.warn("CRITICAL: No subscribers for questionUpdated event, document instance will not be updated.", instanceValue);
        }
        this.questionUpdated.emit(questionTemplateIdentifier);

        return instanceValue;
    }

    public setEnabled(questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, sectionTemplateIdentifier: SectionTemplateIdentifier|undefined, sectionIdentifier: SectionIdentifier|undefined, enabled: boolean): DocumentValueContainer {
        let instanceValue: DocumentValueContainer|undefined = this.getValueContainer(questionTemplateIdentifier);
        if (!instanceValue) {
            instanceValue = this.createValueContainer(questionTemplateIdentifier, questionIdentifier, sectionTemplateIdentifier, sectionIdentifier);
        }

        const question: DocumentQuestion = this.documentTemplate.getQuestion(instanceValue.questionTemplateIdentifier);

        if (!question.enablable) {
            return instanceValue;
        }

        instanceValue.enabled = enabled;

        if (this.questionUpdated.length <= 0) {
            console.warn("CRITICAL: No subscribers for questionUpdated event, document instance will not be updated.", instanceValue);
        }

        this.questionUpdated.emit(questionTemplateIdentifier);

        return instanceValue;
    }

    public linkAttachments(attachments: Array<Attachment>, replaceExisting: boolean, questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, sectionTemplateIdentifier: SectionTemplateIdentifier|undefined, sectionIdentifier: SectionIdentifier|undefined): DocumentValueContainer {
        let instanceValue: DocumentValueContainer|undefined = this.getValueContainer(questionTemplateIdentifier);
        if (!instanceValue) {
            instanceValue = this.createValueContainer(questionTemplateIdentifier, questionIdentifier, sectionTemplateIdentifier, sectionIdentifier);
        }

        // Filter out the item with the attachment business identifier
        instanceValue.linkedAttachments = replaceExisting ? [] : instanceValue.linkedAttachments.filter((alreadyLinked: Attachment) => !attachments.some((newAttachment: Attachment) => newAttachment.identifier.businessIdentifier == alreadyLinked.identifier.businessIdentifier));
        instanceValue.linkedAttachments.push(...attachments);

        const eventData: AttachmentsUpdatedEventArguments = new AttachmentsUpdatedEventArguments({
            attachments: instanceValue.linkedAttachments,
            questionTemplateIdentifier: questionTemplateIdentifier,
            questionIdentifier: questionIdentifier
        });
        this.attachmentLinksUpdated.emit(eventData);

        return instanceValue;
    }

    public setNumber(questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, sectionTemplateIdentifier: SectionTemplateIdentifier|undefined, sectionIdentifier: SectionIdentifier|undefined, answerBusinessIdentifier: AnswerBusinessIdentifier|undefined, value: number|undefined): DocumentValueContainer {
        const finalValue: number|undefined = value && isNaN(value) ? undefined : value;
        return this.setValue(questionTemplateIdentifier, questionIdentifier, sectionTemplateIdentifier, sectionIdentifier, answerBusinessIdentifier ? [answerBusinessIdentifier] : [], finalValue !== undefined ? [finalValue] : [], DocumentQuestionTypes.numeric);
    }

    public setString(questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, sectionTemplateIdentifier: SectionTemplateIdentifier|undefined, sectionIdentifier: SectionIdentifier|undefined, answerBusinessIdentifier: AnswerBusinessIdentifier|undefined, value: string|undefined): DocumentValueContainer {
        return this.setValue(questionTemplateIdentifier, questionIdentifier, sectionTemplateIdentifier, sectionIdentifier, answerBusinessIdentifier ? [answerBusinessIdentifier] : [], value !== undefined ? [value] : [], DocumentQuestionTypes.text, DocumentQuestionTypes.choice);
    }

    public setStringArray(questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, sectionTemplateIdentifier: SectionTemplateIdentifier|undefined, sectionIdentifier: SectionIdentifier|undefined, answerIdentifiers: Array<AnswerBusinessIdentifier>|undefined, values: Array<string>|undefined): DocumentValueContainer {
        return this.setValue(questionTemplateIdentifier, questionIdentifier, sectionTemplateIdentifier, sectionIdentifier, answerIdentifiers !== undefined ? answerIdentifiers : [], values !== undefined ? values : [], DocumentQuestionTypes.multipleChoice);
    }

    public getValueContainer(questionTemplateIdentifier: QuestionTemplateIdentifier): DocumentValueContainer|undefined {
        return this.values.get(questionTemplateIdentifier);
    }

    public getRepeatableQuestion(sectionTemplateIdentifier: SectionTemplateIdentifier): Array<DocumentSection>|undefined {
        return this.repeatableQuestions.get(sectionTemplateIdentifier);
    }

    public getAllValueContainers(): Array<DocumentValueContainer> {
        return Array.from(this.values.values());
    }

    public getAllRepeatableQuestions(): Array<Array<DocumentSection>> {
        return Array.from(this.repeatableQuestions.values());
    }

    public getValues(questionTemplateIdentifier: QuestionTemplateIdentifier): Array<DocumentAnswerValue> {
        const valueContainer: DocumentValueContainer|undefined = this.getValueContainer(questionTemplateIdentifier);
        if (!valueContainer) {
            return [];
        }
        return valueContainer.answers;
    }

    public hasValue(questionTemplateIdentifier: QuestionTemplateIdentifier): boolean {
        const valueContainer: DocumentValueContainer|undefined = this.getValueContainer(questionTemplateIdentifier);
        if (!valueContainer) {
            return false;
        }

        if (valueContainer.questionType == DocumentQuestionTypes.singleImage) {
            return valueContainer.linkedAttachments.some((attachment: Attachment) => attachment.type == AttachmentTypes.image);
        }

        return valueContainer.hasValue();
    }

    public updateComment(questionTemplateIdentifier: QuestionTemplateIdentifier, commentText?: string): void|never {
        const container: DocumentValueContainer|undefined = this.getValueContainer(questionTemplateIdentifier);
        if (!container) {
            throw new AppException(FrontendErrors.FE23UpdatedCommentForNonExistingQuestion, $localize`:@@exception.fe23UpdatedCommentForNonExistingQuestion:Received a comment update for question with id ${questionTemplateIdentifier}:questionIdentifier:. The question does not have a value-container. This is a technical error, please contact the support.`);
        }
        if (!container.comment) {
            container.comment = new DocumentAnswerComment();
        }

        const trimmedCommentText: string|undefined = commentText ? commentText.trim() : commentText;
        if (trimmedCommentText != container.comment.text) {
            container.comment.text = trimmedCommentText;

            this.questionUpdated.emit(questionTemplateIdentifier);
        }
    }

    public updateRepeatableQuestion(sectionTemplateIdentifier: SectionTemplateIdentifier, sectionIdentifier: SectionIdentifier, rows: Array<DocumentSection>): void {
        this.repeatableQuestions.set(sectionTemplateIdentifier, rows);
        const eventArguments: RepeatableSectionUpdateEventArguments = new RepeatableSectionUpdateEventArguments(sectionTemplateIdentifier, sectionIdentifier);
        this.repeatableSectionUpdated.emit(eventArguments);
    }

    public toStorageDto(): DocumentInstanceStorageDto {
        const dto: DocumentInstanceStorageDto = new DocumentInstanceStorageDto();

        for (const repeatedQuestions of this.repeatableQuestions.values()) {
            dto.repeatableQuestions = repeatedQuestions.map((section: DocumentSection) => section.toStorageDto());
        }

        for (const value of this.values.values()) {
            dto.values.push(value.toStorageDto());
        }

        return dto;
    }

    public fromStorageDto(dto: DocumentInstanceStorageDto|undefined): void {
        if (dto) {
            this.fromStorageDtoRepeatableQuestions(dto);
        }

        this.values.clear();
        for (const valueDto of dto?.values ?? []) {
            const questionIdentifier: QuestionIdentifier = Identifier.create(valueDto.questionBusinessIdentifier, valueDto.questionTechnicalIdentifier);
            const sectionIdentifier: SectionIdentifier = Identifier.create(valueDto.sectionBusinessIdentifier, valueDto.sectionTechnicalIdentifier);
            const value: DocumentValueContainer = new DocumentValueContainer(DocumentQuestionTypes.unknown, valueDto.questionTemplateIdentifier as QuestionTemplateIdentifier, questionIdentifier, valueDto.sectionTemplateIdentifier as SectionTemplateIdentifier, sectionIdentifier);
            value.fromStorageDto(valueDto);
            this.values.set(value.questionTemplateIdentifier, value);
        }
    }

    private fromStorageDtoRepeatableQuestions(dto: DocumentInstanceStorageDto): void {
        // Group by template identifier
        const dtosSortedByTemplateId: Map<SectionTemplateIdentifier, Array<DocumentSectionStorageDto>> = new Map<SectionTemplateIdentifier, Array<DocumentSectionStorageDto>>();
        for (const repeatedQuestionDto of dto.repeatableQuestions ?? []) {
            const list: Array<DocumentSectionStorageDto> = dtosSortedByTemplateId.get(repeatedQuestionDto.templateId as SectionTemplateIdentifier) ?? [];
            if (list.length <= 0) {
                dtosSortedByTemplateId.set(repeatedQuestionDto.templateId as SectionTemplateIdentifier, list);
            }
            list.push(repeatedQuestionDto);
        }

        // Remove existing ones that do not exist anymore
        const existingTemplateIds: Array<SectionTemplateIdentifier> = Array.from(this.repeatableQuestions.keys());
        for (const templateId of existingTemplateIds) {
            if (!dtosSortedByTemplateId.has(templateId)) {
                this.repeatableQuestions.delete(templateId);
            }
        }

        // Soft update (update the sub-questions and do not create new instances)
        for (const templateId of dtosSortedByTemplateId.keys()) {
            const updateList: Array<DocumentSectionStorageDto> = dtosSortedByTemplateId.get(templateId)!;
            const existingList: Array<DocumentSection> = this.repeatableQuestions.get(templateId) ?? [];
            if (existingList.length <= 0) {
                this.repeatableQuestions.set(templateId, existingList);
            }

            let isSoftUpdate: boolean = updateList.length == existingList.length;
            if (isSoftUpdate) {
                for (let index: number = 0; index < existingList.length; index++) {
                    if (existingList[index].identifier.businessIdentifier && existingList[index].identifier.businessIdentifier != updateList[index].businessId) {
                        isSoftUpdate = false;
                        break;
                    }
                }
            }

            if (isSoftUpdate) {
                for (let index: number = 0; index < existingList.length; index++) {
                    existingList[index].fromStorageDto(updateList[index]);
                }
            } else {
                const newList: Array<DocumentSection> = updateList.map((storageDto: DocumentSectionStorageDto) => {
                    const repeatedQuestion: DocumentSection = DocumentFactory.createSection(storageDto.templateId as SectionTemplateIdentifier);
                    repeatedQuestion.fromStorageDto(storageDto);
                    return repeatedQuestion;
                });
                this.repeatableQuestions.set(templateId, newList);
            }
        }
    }
}
