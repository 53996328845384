import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { CoreDataEntityStorageDto } from "../core-data/core-data-entity-storage-dto";
import { EntityLinkStorageDto } from "../documents/entity-link-storage-dto";

/**
 * Storage DTO for {@link DashboardProcesses}.
 */
export class DashboardProcessesStorageDto extends CoreDataEntityStorageDto {
    constructor(init?: Partial<DashboardProcessesStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public linkedEntities: Array<EntityLinkStorageDto> = [];
}
