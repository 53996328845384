import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { EntityLinkStorageDto } from "../../documents/entity-link-storage-dto";
import { AddressStorageDto } from "../address-storage-dto";
import { CoreDataEntityStorageDto } from "../core-data-entity-storage-dto";

/**
 * Storage DTO for {@link BuildingComplex}.
 */
export class BuildingComplexStorageDto extends CoreDataEntityStorageDto {
    constructor(init?: Partial<BuildingComplexStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public name?: string;

    public externalReference?: string;

    public address?: AddressStorageDto;

    public addressRaw?: string;

    public personLinks?: Array<EntityLinkStorageDto>;

    public companyLink?: EntityLinkStorageDto;
}
