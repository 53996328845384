import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { AddressStorageDto } from "../address-storage-dto";
import { CoreDataEntityStorageDto } from "../core-data-entity-storage-dto";
import { Floor } from "./floor";

/**
 * Storage DTO for {@link Building}.
 */
export class BuildingStorageDto extends CoreDataEntityStorageDto {
    constructor(init?: Partial<BuildingStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public buildingComplexBusinessId?: string;

    public buildingComplexTechnicalId?: number;

    public name?: string;

    public companyIdentifier?: number;

    public addresses?: Array<AddressStorageDto>;

    public contactId?: number;

    public photoIds?: Array<number>;

    public constructionDate?: string;

    public buildingUsage?: string;

    public usageDescription?: string;

    public numberOfFlats?: number;

    public numberOfOffices?: number;

    public numberOfUnusedFlatsAndOffices?: number;

    public installationPlansExist?: boolean;

    public visibleAscendingPipes?: number;

    public ascendingPipesEnds?: string;

    public floors: Array<Floor> = [];

    public drinkingWaterHeatingType?: string;

    public drinkingWaterHeatingLocation?: string;

    public locationHouseConnection?: string;

    public additionalInfo?: string;

    public publicUsage?: boolean;
}
