import { Injectable } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";

import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { DefaultService, QualityAssuranceDto } from "../../../generated/api";
import { errorResult, SafeResult, successResult } from "../../common/safe-result";
import { QualityAssuranceStatus } from "../../entities/documents/quality-assurance-status";
import { AppException } from "../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../global/frontend-errors";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { QualityAssuranceIdentifier } from "../../identifiers/quality-assurance-identifier";
import { QuestionIdentifier } from "../../identifiers/question-identifier";
import { SessionService } from "../session/session.service";

/**
 * The QA service to talk to the backend.
 */
@Injectable({
    providedIn: "root"
})
export class QualityAssuranceApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public async createState(_documentIdentifier: DocumentIdentifier, questionIdentifier: QuestionIdentifier, status: QualityAssuranceStatus, initialMessage?: string): Promise<QualityAssuranceDto>|never {
        this.sessionService.requiresAccount();

        const qaDto: QualityAssuranceDto = {
            questionId: questionIdentifier.technicalIdentifier,
            status: Api1Converter.qualityAssuranceStatusToDto(status),
            messages: []
        };
        if (initialMessage) {
            qaDto.messages?.push({ message: initialMessage });
        }
        return lastValueFrom(this.defaultService.createQualityAssurance(qaDto, this.sessionService.activeAccountId));
    }

    public async getItems(documentIdentifier: DocumentIdentifier): Promise<SafeResult<Array<QualityAssuranceDto>, AppException>> {
        const requiresAccountResult: SafeResult<void, AppException> = this.sessionService.requiresAccountSafeResult();
        if (requiresAccountResult.isError()) {
            return errorResult(requiresAccountResult.error);
        }

        try {
            return successResult(await lastValueFrom(this.defaultService.listQualityAssurances(this.sessionService.activeAccountId, documentIdentifier.technicalIdentifier)));
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE47UnableToLoadQualityAssuranceInformation, $localize`:@@exception.fe47UnableToLoadQualityAssuranceInformation:Unable to load quality assurance information.`, error as Error));
        }
    }

    public async getItem(documentIdentifier: DocumentIdentifier, questionIdentifier: QuestionIdentifier): Promise<QualityAssuranceDto|undefined>|never {
        this.sessionService.requiresAccount();

        // TODO: This should be cached somehow
        const allItems: SafeResult<Array<QualityAssuranceDto>, AppException> = await this.getItems(documentIdentifier);
        if (allItems.isError()) {
            throw allItems.error;
        }
        return allItems.result.find((value: QualityAssuranceDto) => value.questionId == questionIdentifier.technicalIdentifier);
    }

    public async addMessage(_documentIdentifier: DocumentIdentifier, qualityAssuranceIdentifier: QualityAssuranceIdentifier, message: string, status: QualityAssuranceStatus): Promise<QualityAssuranceDto>|never {
        this.sessionService.requiresAccount();

        const observable: Observable<QualityAssuranceDto> = this.defaultService.updateQualityAssurance(qualityAssuranceIdentifier.businessIdentifier!, {
            status: Api1Converter.qualityAssuranceStatusToDto(status),
            qualityAssuranceBusinessId: qualityAssuranceIdentifier.businessIdentifier,
            message: message
        },
        this.sessionService.activeAccountId);

        return lastValueFrom(observable);
    }
}
