export enum Units {
    year = "year",
    month = "month",
    day = "day",
    celsius = "celsius",
    kelvin = "kelvin",
    liter = "liter",
    cfu = "cfu",
    meter = "meter",
    centimeter = "centimeter",
    millimeter = "millimeter"
}
