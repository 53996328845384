import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EmptyError, Observable } from "rxjs";

import { environment } from "../../../../environments/environment";
import { PhotoDto, ResponseBodySuccessDto } from "../../../generated/api";
import { ImageAttachment } from "../../entities/attachments/image-attachment";
import { AppException } from "../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../global/frontend-errors";
import { AttachmentBusinessIdentifier } from "../../identifiers/attachment-business-identifier";
import { AttachmentTechnicalIdentifier } from "../../identifiers/attachment-technical-identifier";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { DocumentTechnicalIdentifier } from "../../identifiers/document-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { QuestionBusinessIdentifier } from "../../identifiers/question-business-identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { PhotoApiService } from "../api/photo-api.service";
import { SessionService } from "../session/session.service";

/**
 * Service to work with photos.
 */
@Injectable({
    providedIn: "root"
})
export class ImageService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly photoApiService: PhotoApiService
    ) {
    }

    private readonly imagePreviewSize: number = environment.imagePreviewSize;

    public async uploadImage(attachment: ImageAttachment, blob: Blob, documentBusinessIdentifier?: DocumentBusinessIdentifier, questionTemplateIdentifier?: QuestionTemplateIdentifier, questionBusinessIdentifier?: QuestionBusinessIdentifier): Promise<PhotoDto> {
        this.sessionService.requiresAccount();

        const observable: Observable<HttpEvent<PhotoDto>> = this.photoApiService.createPhoto(blob, documentBusinessIdentifier, questionBusinessIdentifier, questionTemplateIdentifier);

        return new Promise((resolve: (value: (PromiseLike<PhotoDto>|PhotoDto)) => void, reject: (reason?: any) => void) => {
            let photoDto: PhotoDto|null;
            observable.subscribe({
                next: (value: any) => {
                    switch (value.type) {
                        case HttpEventType.Response:
                            const response: HttpResponse<PhotoDto> = value as HttpResponse<PhotoDto>;
                            photoDto = response?.body;
                            break;
                        case HttpEventType.UploadProgress:
                            const progressEvent: HttpProgressEvent = value as HttpProgressEvent;
                            attachment.uploadProgress = !progressEvent.total || progressEvent.total <= 0 ? 0 : progressEvent.loaded / progressEvent.total;
                            break;
                    }
                },
                error: reject,
                complete: () => {
                    if (photoDto) {
                        this.updateAttachmentProperties(attachment, photoDto, true);
                        resolve(photoDto);
                    } else {
                        reject(new EmptyError());
                    }
                }
            });
        });
    }

    public async updateImageMetadata(attachment: ImageAttachment): Promise<void> {
        this.sessionService.requiresAccount();

        const photoDto: PhotoDto = {
            createdOnDevice: attachment.createdClient,
            description: attachment.description,
            showInPdf: attachment.showInPdf || false,
            printQuality: attachment.printQuality || environment.imageDefaultPrintQuality,
            documentIds: Array.from(attachment.linkedDocuments.map((idPair: DocumentIdentifier) => idPair.technicalIdentifier as number))
        };
        const result: PhotoDto = await this.photoApiService.updatePhoto(attachment.identifier.businessIdentifier, photoDto);
        this.updateAttachmentProperties(attachment, result, false);
    }

    public async getPhotoMetadata(imageTechnicalIdentifier: AttachmentTechnicalIdentifier): Promise<PhotoDto> {
        this.sessionService.requiresAccount();

        // TODO WORKAROUND: Getting photo by loading complete list instead of getting a single photo.
        console.warn("TODO WORKAROUND: Getting photo by loading complete list instead of getting a single photo.");
        const allPhotos: Array<PhotoDto> = await this.photoApiService.listPhotos();
        for (const photo of allPhotos) {
            if (photo.technicalId == imageTechnicalIdentifier) {
                return photo;
            }
        }
        throw new AppException(FrontendErrors.FE38ImageNotFound, $localize`:@@exception.fe38ImageNotFound:The image with the identifier "${imageTechnicalIdentifier}:id:" cannot be found.`);
    }

    public getPhotoBlob(imageTechnicalIdentifier: AttachmentTechnicalIdentifier, previewImage: boolean): Promise<Blob> {
        this.sessionService.requiresAccount();

        return previewImage
            ? this.photoApiService.getPhotoBinary(imageTechnicalIdentifier, this.imagePreviewSize, this.imagePreviewSize)
            : this.photoApiService.getPhotoBinary(imageTechnicalIdentifier);
    }

    private updateAttachmentProperties(attachment: ImageAttachment, dto: PhotoDto, isUpload: boolean): void {
        attachment.identifier = Identifier.create(dto.businessId, dto.technicalId);
        attachment.created = dto.created;
        attachment.updated = dto.updated;
        attachment.width = dto.width ?? 0;
        attachment.height = dto.height ?? 0;
        attachment.printQuality = dto.printQuality || environment.imageDefaultPrintQuality;
        if (!isUpload) {
            attachment.description = dto.description;
            attachment.showInPdf = dto.showInPdf || false;
        }

        if (attachment.linkedDocuments.length == 1 && dto.documentIds?.length == 1) {
            // TODO WORKAROUND: Photo DTO should contain (?) then we would not need this workaround
            attachment.linkedDocuments[0].technicalIdentifier = dto.documentIds[0] as DocumentTechnicalIdentifier;
        } else {
            attachment.linkedDocuments = [];
            for (const linkedDocumentTechnicalId of dto.documentIds ?? []) {
                attachment.linkedDocuments.push(Identifier.create("", linkedDocumentTechnicalId as DocumentTechnicalIdentifier));
            }
        }
    }

    public getDocumentPhotos(documentTechnicalIdentifier: DocumentTechnicalIdentifier): Promise<Array<PhotoDto>> {
        this.sessionService.requiresAccount();

        return this.photoApiService.listPhotos(documentTechnicalIdentifier);
    }

    public async deletePhoto(businessIdentifier: AttachmentBusinessIdentifier): Promise<boolean>|never {
        this.sessionService.requiresAccount();

        const result: ResponseBodySuccessDto = await this.photoApiService.deletePhoto(businessIdentifier);
        return result.status == "OK";
    }
}
