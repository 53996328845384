import { BasicDialogOptions } from "../basic-dialog/basic-dialog-options";
import { DialogButton } from "../basic-dialog/dialog-button";

/**
 * Options for the input dialog component.
 */
export class InputDialogOptions extends BasicDialogOptions {
    public inputPlaceholder?: string;
    public inputValue?: string;
    public multiline: boolean;

    public constructor(title: string, subtitle?: string, message?: string, buttons?: Array<DialogButton>, inputPlaceholder?: string, inputValue?: string, multiline: boolean = false) {
        super(title, subtitle, message, buttons);

        this.inputPlaceholder = inputPlaceholder;
        this.inputValue = inputValue;
        this.multiline = multiline;
    }
}
