/* eslint-disable @typescript-eslint/no-magic-numbers */

import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AppIcons } from "src/app/business/services/icons/app-icons";

import { AsyncHelper } from "../../../../base/helpers/async-helper";
import { StorageService } from "../../../services/storage/storage.service";
import { StorageKeys } from "../../../services/storage/storage-keys";
import { ArrowGameCell } from "./arrow-game-cell";
import { ArrowGameHighscores } from "./arrow-game-highscores";

/**
 * Arrow game component.
 */
@Component({
    selector: "app-arrow-game",
    templateUrl: "./arrow-game.component.html",
    styleUrls: ["./arrow-game.component.scss"]
})
export class ArrowGameComponent implements OnInit {
    constructor(
        private readonly storageService: StorageService,
        private readonly modalController: ModalController
    ) {
    }

    public appIcons: typeof AppIcons = AppIcons;

    public level: number = -1;

    public size: number = 0;

    public cellWidth: string = "0%";

    public field: Array<Array<ArrowGameCell>> = new Array<Array<ArrowGameCell>>();

    public startTime: number = 0;

    private clock?: any;

    public timeLabel?: string;

    public highscores: ArrowGameHighscores = new ArrowGameHighscores();

    private sizes: Array<number> = [3, 4, 7, 9];

    public async ngOnInit(): Promise<void> {
        this.highscores = await this.storageService.get(StorageKeys.gameArrowHighscores) ?? new ArrowGameHighscores();
    }

    public async start(level: number): Promise<void> {
        this.level = level;
        const size: number = this.sizes[level];
        this.size = size;
        this.cellWidth = `${100 / size}%`;

        this.field = new Array<Array<ArrowGameCell>>(size);
        for (let row: number = 0; row < size; row++) {
            this.field[row] = new Array<ArrowGameCell>(size);
            for (let column: number = 0; column < size; column++) {
                this.field[row][column] = new ArrowGameCell();
            }
        }

        this.timeLabel = "00:00";
        await this.randomize();
        this.startTime = (Date.now() / 1000);
        this.clock = setInterval(this.clockUpdate.bind(this), 1000);
    }

    private clockUpdate(): void {
        const diff: number = (Date.now() / 1000) - this.startTime;
        this.timeLabel = this.toTimeString(diff);
    }

    public toTimeString(totalSeconds: number): string {
        let minutes: number = totalSeconds / 60;
        let seconds: number = minutes - Math.trunc(minutes);
        minutes = Math.round(minutes - seconds);
        seconds = Math.round(seconds * 60);
        return `${`${minutes}`.padStart(2, "0")}:${`${seconds}`.padStart(2, "0")}`;
    }

    public async randomize(): Promise<void> {
        await AsyncHelper.sleep(200);
        for (let i: number = 0; i < 10 * this.size; i++) {
            const x: number = Math.round(Math.random() * (this.size - 1)); // NOSONAR
            const y: number = Math.round(Math.random() * (this.size - 1)); // NOSONAR
            this.rotate(x, y, true);
            await AsyncHelper.sleep(10);
        }
    }

    public rotate(x: number, y: number, shuffle: boolean = false): void {
        this.field[y][x].value++;

        if (x > 0 && y > 0) {
            this.field[y - 1][x - 1].value++;
        }
        if (y > 0) {
            this.field[y - 1][x].value++;
        }
        if (x < this.size - 1 && y > 0) {
            this.field[y - 1][x + 1].value++;
        }
        if (x < this.size - 1) {
            this.field[y][x + 1].value++;
        }
        if (x < this.size - 1 && y < this.size - 1) {
            this.field[y + 1][x + 1].value++;
        }
        if (y < this.size - 1) {
            this.field[y + 1][x].value++;
        }
        if (x > 0) {
            this.field[y][x - 1].value++;
        }
        if (x > 0 && y < this.size - 1) {
            this.field[y + 1][x - 1].value++;
        }

        if (!shuffle) {
            this.check();
        }
    }

    public check(): void {
        let allUp: boolean = true;
        for (let y: number = 0; y < this.size; y++) {
            for (let x: number = 0; x < this.size; x++) {
                if (this.field[y][x].value % 4 != 0) {
                    allUp = false;
                    break;
                }
            }
        }

        if (allUp) {
            this.gameWon().then();
        }
    }

    private async gameWon(): Promise<void> {
        const time: number = (Date.now() / 1000) - this.startTime;
        const level: number = this.level;

        this.stop();

        if (!this.highscores.levels[level] || time < this.highscores.levels[level]) {
            this.highscores.levels[level] = time;
            await this.storageService.set(StorageKeys.gameArrowHighscores, this.highscores);
        }
    }

    private stopTimer(): void {
        if (this.clock) {
            clearInterval(this.clock);
            this.clock = undefined;
        }
    }

    public stop(): void {
        this.stopTimer();
        this.level = -1;
        this.size = 0;
    }

    public restart(): void {
        this.stopTimer();
        this.start(this.level).then();
    }

    public close(): void {
        this.modalController.dismiss().then();
    }
}
