import { DialogButton } from "../../../base/components/dialogs/basic-dialog/dialog-button";
import { IocService } from "../../../base/services/ioc/ioc.service";
import { SafeResult } from "../../common/safe-result";
import { Building } from "../../entities/core-data/buildings/building";
import { BuildingComplex } from "../../entities/core-data/buildings/building-complex";
import { Company } from "../../entities/core-data/company";
import { Person } from "../../entities/core-data/person";
import { EntityTypes } from "../../entities/entity-types";
import { AppException } from "../../entities/exceptions/app-exception";
import { Process } from "../../entities/projects-processes/process";
import { Project } from "../../entities/projects-processes/project";
import { FrontendErrors } from "../../global/frontend-errors";
import { I18nTextService } from "../../services/i18n/i18n-text.service";
import { BuildingsService } from "../../services/master-data/buildings/buildings.service";
import { CompaniesService } from "../../services/master-data/companies/companies.service";
import { PersonsService } from "../../services/master-data/persons/persons.service";
import { ProcessesService } from "../../services/projects-processes/processes.service";
import { ProjectsService } from "../../services/projects-processes/projects.service";
import { LoggedInIntent } from "../abstract/logged-in-intent";
import { DeleteEntityIntentOptions } from "./delete-entity-intent-options";

/**
 * Intent to delete a building.
 */
export class DeleteEntityIntent extends LoggedInIntent<DeleteEntityIntent> {
    constructor(
        iocService: IocService) {
        super(iocService);
    }

    private readonly i18nTextService: I18nTextService = this.iocService.resolve(I18nTextService);

    public async executeIntent(intentOptions: DeleteEntityIntentOptions): Promise<boolean> {
        if (intentOptions.showConfirmMessage) {
            enum Decisions {
                yes = "yes",
                no = "no"
            }

            const entityTypeName: string = this.i18nTextService.getEntityTypeText(intentOptions.entity.entityType);
            const decision: string|undefined = await this.dialogService.showAlert(
                $localize`:@@entities.confirmDeleteTitle:Delete ${entityTypeName}?`,
                $localize`:@@entities.confirmDeleteMessage:Do you really want to delete this item?`,
                intentOptions.entity?.displayTitle,
                [
                    new DialogButton($localize`:@@entities.confirmDeleteNo:No, do not delete`, Decisions.no),
                    new DialogButton($localize`:@@entities.confirmDeleteYes:Yes, delete`, Decisions.yes, false, "destructive")
                ]);
            if (!decision || decision == Decisions.no) {
                return true;
            }
        }

        await this.deleteEntity(intentOptions);

        return true;
    }

    private async deleteEntity(intentOptions: DeleteEntityIntentOptions): Promise<void> {
        try {
            switch (intentOptions.entity.entityType) {
                case EntityTypes.buildingComplex:
                    intentOptions.result = await this.deleteBuildingComplex(intentOptions.entity as BuildingComplex);
                    return;
                case EntityTypes.building:
                    intentOptions.result = await this.deleteBuilding(intentOptions.entity as Building);
                    return;
                case EntityTypes.company:
                    intentOptions.result = await this.deleteCompany(intentOptions.entity as Company);
                    return;
                case EntityTypes.person:
                    intentOptions.result = await this.deletePerson(intentOptions.entity as Person);
                    return;
                case EntityTypes.project:
                    intentOptions.result = await this.deleteProject(intentOptions.entity as Project);
                    return;
                case EntityTypes.process:
                    intentOptions.result = await this.deleteProcess(intentOptions.entity as Process);
                    return;
                default:
                    const entityTypeName: string = this.i18nTextService.getEntityTypeText(intentOptions.entity.entityType);
                    await this.dialogService.showAlert($localize`:@@app.notSupportedDialogTitle:Not supported`, $localize`:@@entities.deleteNotSupportedMessage:Items of type ${entityTypeName} cannot be deleted.`);
                    break;
            }
        } catch (error) {
            await this.handleErrors(error, intentOptions);
        }
    }

    private async handleErrors(error: any, _intentOptions: DeleteEntityIntentOptions): Promise<void> {
        const appException: AppException|undefined = error instanceof AppException ? error as AppException : undefined;
        if (appException?.errorCode == FrontendErrors.FE64UnableToDeleteMyself) {
            await this.dialogService.showAlert($localize`:@@app.actionNotPossibleTitle:Not possible`, $localize`:@@entities.cannotDeleteMyselfInfo:It is not possible to delete yourself.`);
        } else {
            await this.dialogService.showError(error);
        }
    }

    private async deleteBuilding(building: Building): Promise<boolean> {
        const buildingsService: BuildingsService = this.iocService.resolve(BuildingsService);
        const result: SafeResult<void, AppException> = await buildingsService.deleteBuilding(building.identifier.businessIdentifier);
        if (result.isError()) {
            throw result.error;
        }
        return true;
    }

    private async deleteCompany(company: Company): Promise<boolean> {
        const companiesService: CompaniesService = this.iocService.resolve(CompaniesService);
        const result: SafeResult<void, AppException> = await companiesService.deleteCompany(company.identifier.businessIdentifier);
        if (result.isError()) {
            throw result.error;
        }
        return true;
    }

    private async deleteBuildingComplex(buildingComplex: BuildingComplex): Promise<boolean> {
        const buildingsService: BuildingsService = this.iocService.resolve(BuildingsService);
        const result: SafeResult<void, AppException> = await buildingsService.deleteBuildingComplex(buildingComplex.identifier.businessIdentifier);
        if (result.isError()) {
            throw result.error;
        }
        return true;
    }

    private async deletePerson(person: Person): Promise<boolean> {
        const personsService: PersonsService = this.iocService.resolve(PersonsService);
        const result: SafeResult<void, AppException> = await personsService.deletePerson(person.identifier.businessIdentifier);
        if (result.isError()) {
            throw result.error;
        }
        return true;
    }

    private async deleteProject(project: Project): Promise<boolean> {
        if (project.processes.length > 0) {
            await this.dialogService.showAlert($localize`:@@app.actionNotPossibleTitle:Not possible`, $localize`:@@project.unableToDeleteProcessesPresent:This project cannot be deleted because there are still processes in this project. Please delete the processes first.`);
            return false;
        }

        const projectsService: ProjectsService = this.iocService.resolve(ProjectsService);
        const result: SafeResult<void, AppException> = await projectsService.deleteProject(project.identifier.businessIdentifier);
        if (result.isError()) {
            throw result.error;
        }
        return true;
    }

    private async deleteProcess(process: Process): Promise<boolean> {
        if (process.linkedDocuments.length > 0) {
            await this.dialogService.showAlert($localize`:@@app.actionNotPossibleTitle:Not possible`, $localize`:@@process.unableToDeleteDocumentsPresent:This process cannot be deleted because there are still documents in this process. Please delete the documents first.`);
            return false;
        }

        const processesService: ProcessesService = this.iocService.resolve(ProcessesService);
        const result: SafeResult<void, AppException> = await processesService.deleteProcess(process.identifier.businessIdentifier);
        if (result.isError()) {
            throw result.error;
        }
        return true;
    }

    public cancel(): Promise<void> {
        return Promise.resolve();
    }
}
