import { AbstractControl, FormGroup } from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";

import { environment } from "../../../environments/environment";
import { DateTimeHelper } from "./date-time-helper";

/**
 * Helper for forms.
 */
export class FormHelper {
    public static appearance(readonly: boolean): MatFormFieldAppearance {
        return readonly ? "fill" : "outline";
    }

    public static trimWhitespacesBeforeInput(event: Event): void {
        const inputEvent: InputEvent = event as InputEvent;
        if (inputEvent?.data == " ") {
            inputEvent.preventDefault();
        }
    }

    public static trimWhiteSpacesOnPaste(event: Event): void {
        const clipboardEvent: ClipboardEvent = event as ClipboardEvent;
        if (clipboardEvent) {
            let content: string|undefined = clipboardEvent.clipboardData?.getData("text/plain");
            if (content && content.includes(" ")) {
                content = content.replace(/ /g, "");
                const input: HTMLInputElement = event.target as HTMLInputElement;
                if (input) {
                    input.value = content;
                    event.preventDefault();
                }
            }
        }
    }

    public static formValueToData<TType extends string|number>(formValue: TType|undefined|null): TType|undefined {
        if (formValue && typeof formValue == "string") {
            return formValue.trim().replace(/\t/g, " ") as unknown as TType;
        }
        return formValue === null ? undefined : formValue;
    }

    public static formValueToDataByProperty<TOwner, TType>(formGroup: FormGroup, propertyName: keyof TOwner): TType|undefined {
        const formValue: TType = formGroup.controls[propertyName as string].value as TType;
        if (formValue && typeof formValue == "string") {
            return formValue.trim() as unknown as TType;
        }
        return formValue;
    }

    public static dateTimeStringToFormString(dateTime: string|undefined): string|undefined {
        if (!dateTime) {
            return undefined;
        }
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return DateTimeHelper.removeTimeZone(DateTimeHelper.utcToLocalTimeString(dateTime)).substring(0, 19);
    }

    public static dateTimeStringToTimeOnlyFormString(dateTime: string|undefined): string|undefined {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (!dateTime || dateTime.length < 16) {
            return undefined;
        }
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return DateTimeHelper.utcToLocalTimeString(dateTime).substring(11, 16);
    }

    public static formValueToDateTimeString(formValue: string|null|undefined): string|undefined {
        if (!formValue) {
            return undefined;
        }
        return DateTimeHelper.toUtc(formValue);
    }

    public static assignIfChanged(control: AbstractControl, value: any): void {
        if (control.value != value) {
            control.setValue(value);
        }
    }

    public static maxLengthText(control: AbstractControl<string|null|undefined>|undefined, max: number): string {
        const currentLength: number = control?.value?.length || 0;
        if (currentLength <= 0 || !max || max <= 0 || currentLength / max < environment.formSettings.lengthLimitDisplayPercent) {
            return "";
        }

        return `${currentLength} / ${max}`;
    }
}
