<form *ngIf="person" [formGroup]="formData" class="coreDataGroup" enter-to-tab>
    <div class="groupedInputs">
        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="firstnameField">
            <mat-label>
                <fa-icon [icon]="appIcons.personFirstname"></fa-icon>&nbsp;<ng-container i18n="@@person.firstname">First name</ng-container>
            </mat-label>
            <input #nextTab [formControl]="firstnameControl" [maxLength]="formSettings.maxLengthFirstName" [readonly]="readonly" autocapitalize="on" autocomplete="new-given-name" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(firstnameControl, formSettings.maxLengthFirstName)}}</mat-hint>
            <mat-error *ngIf="firstnameControl.invalid">{{getErrorMessage(firstnameControl)}}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="lastnameField">
            <mat-label>
                <fa-icon [icon]="appIcons.personLastname"></fa-icon>&nbsp;<ng-container i18n="@@person.lastname">Last name</ng-container>
            </mat-label>
            <input #nextTab [formControl]="lastnameControl" [maxLength]="formSettings.maxLengthLastName" [readonly]="readonly" autocapitalize="on" autocomplete="new-family-name" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(lastnameControl, formSettings.maxLengthLastName)}}</mat-hint>
            <mat-error *ngIf="lastnameControl.invalid">{{getErrorMessage(lastnameControl)}}</mat-error>
        </mat-form-field>
    </div>

    <mat-form-field [appearance]="formHelper.appearance(readonly)" class="titleField">
        <mat-label>
            <fa-icon [icon]="appIcons.personTitle"></fa-icon>&nbsp;<ng-container i18n="@@person.title">Title</ng-container>
        </mat-label>
        <input #nextTab [formControl]="titleControl" [maxLength]="formSettings.maxLengthTitle" [readonly]="readonly" autocapitalize="on" autocomplete="new-honorific-prefix" matInput type="text">
        <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(titleControl, formSettings.maxLengthTitle)}}</mat-hint>
        <mat-error *ngIf="titleControl.invalid">{{getErrorMessage(titleControl)}}</mat-error>
    </mat-form-field>

    <mat-form-field [appearance]="formHelper.appearance(readonly)" [class.readonly]="readonly" class="genderField">
        <mat-label>
            <fa-icon [icon]="appIcons.personGender"></fa-icon>&nbsp;<ng-container i18n="@@person.gender">Gender</ng-container>
        </mat-label>

        <mat-select #nextTab [disabled]="readonly" [formControl]="genderControl">
            <mat-option [value]="'unknown'" i18n="@@gender.unknown">Unknown</mat-option>
            <mat-option [value]="'female'" i18n="@@gender.female">Female</mat-option>
            <mat-option [value]="'male'" i18n="@@gender.male">Male</mat-option>
            <mat-option [value]="'other'" i18n="@@gender.other">Other</mat-option>
            <mat-option [value]="'notApplicable'" i18n="@@gender.notApplicable">Not applicable</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field [appearance]="formHelper.appearance(readonly)" class="emailField">
        <mat-label>
            <fa-icon [icon]="appIcons.personEmail"></fa-icon>&nbsp;<ng-container i18n="@@person.email">E-Mail</ng-container>
        </mat-label>
        <input #nextTab [formControl]="emailControl" [maxLength]="formSettings.maxLengthEmail" [readonly]="readonly" autocomplete="new-email" matInput type="email">
        <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(emailControl, formSettings.maxLengthEmail)}}</mat-hint>
        <mat-error *ngIf="emailControl.invalid">{{getErrorMessage(emailControl)}}</mat-error>

    </mat-form-field>

    <div class="groupedInputs">
        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="phoneField">
            <mat-label>
                <fa-icon [icon]="appIcons.personPhone"></fa-icon>&nbsp;<ng-container i18n="@@person.phone">Phone</ng-container>
            </mat-label>
            <input #nextTab [formControl]="phoneControl" [maxLength]="formSettings.maxLengthPhone" [readonly]="readonly" autocomplete="new-tel" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(phoneControl, formSettings.maxLengthPhone)}}</mat-hint>
            <mat-error *ngIf="phoneControl.invalid">{{getErrorMessage(phoneControl)}}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="phoneMobileField">
            <mat-label>
                <fa-icon [icon]="appIcons.personPhoneMobile"></fa-icon>&nbsp;<ng-container i18n="@@person.phoneMobile">Mobile phone</ng-container>
            </mat-label>
            <input #nextTab [formControl]="phoneMobileControl" [maxLength]="formSettings.maxLengthPhoneMobile" [readonly]="readonly" autocomplete="new-tel" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(phoneMobileControl, formSettings.maxLengthPhoneMobile)}}</mat-hint>
            <mat-error *ngIf="phoneMobileControl.invalid">{{getErrorMessage(phoneMobileControl)}}</mat-error>
        </mat-form-field>
    </div>

    <business-address-form #nextTab (isValidChange)="updateValidState()" *ngIf="person.address" [address]="person.address" [readonly]="readonly"></business-address-form>
</form>
