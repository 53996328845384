import { AttachmentStorageDto } from "../attachments/attachment-storage-dto";
import { DocumentAnswerStorageDto } from "./document-answer-storage-dto";

/**
 * Storage class for a question.
 */
export class DocumentQuestionStorageDto {
    public templateIdentifier?: number;

    public sectionTemplateIdentifier?: number;

    public type?: string;

    public typeRaw?: string;

    public title?: string;

    public description?: string;

    public references?: string;

    public allowsNewEntry?: boolean;

    public mandatory?: boolean;

    public simpleItem?: boolean;

    public enabled?: boolean;

    public enablable?: boolean;

    public hasComments?: boolean;

    public hasInternalNotes?: boolean;

    public hasImages?: boolean;

    public hasAudio?: boolean;

    public hasFiles?: boolean;

    public questions: Array<DocumentQuestionStorageDto> = [];

    public answers: Array<DocumentAnswerStorageDto> = [];

    public attachments: Array<AttachmentStorageDto> = [];
}
