export enum EntityTypes {
    unknown = "unknown",
    all = "all",

    project = "project",
    process = "process",
    document = "document",
    documentCommon = "documentCommon",
    chapter = "chapter",
    section = "section",
    question = "question",
    coreData = "coreData",
    person = "person",
    address = "address",
    buildingComplex = "buildingComplex",
    building = "building",
    buildingFeatures = "buildingFeatures",
    roomFeature = "roomFeature",
    company = "company",
    attachment = "attachment",
    attachments = "attachments",
    image = "image",
    revisions = "revisions",
    review = "review",
    sample = "sample",
    sampleMerge = "sampleMerge",
    samplingPlan = "samplingPlan",
    samplingPlanMerge = "samplingPlanMerge",
    aiAnalysis = "aiAnalysis",

    dashboardProcesses = "dashboardProcesses"
}
