import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatLuxonDateModule } from "@angular/material-luxon-adapter";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { Drivers } from "@ionic/storage";
import { IonicStorageModule } from "@ionic/storage-angular";
import { AngularPinturaModule } from "@pqina/angular-pintura";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { NgxIndexedDBModule } from "ngx-indexed-db";
import { MarkdownModule } from "ngx-markdown";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { RequestInterceptor } from "./base/services/auth/request-interceptor";
import { BusinessComponentsModule } from "./business/components/business-components.module";
import { LocalDatabaseConfiguration } from "./business/global/local-database";
import { LoggedInGuard } from "./business/guards/logged-in-guard";
import { ApiModule, BASE_PATH } from "./generated/api";

/**
 * Storage for OAuth.
 *
 * @returns Storage.
 */
export function storageFactory(): OAuthStorage {
    return localStorage;
}

/**
 * The root module for the app.
 */
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ mode: "md" }),
        IonicStorageModule.forRoot({
            name: "appStorage",
            storeName: "appSettings",
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        NgxIndexedDBModule.forRoot(LocalDatabaseConfiguration.databaseConfiguration),
        AppRoutingModule,
        BusinessComponentsModule,
        ReactiveFormsModule,
        MatLuxonDateModule,
        ApiModule,
        HttpClientModule,
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true,
                allowedUrls: [environment.apiBasePath]
            }
        }),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 15 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:5000"
        }),
        FontAwesomeModule,
        BrowserAnimationsModule,
        MatButtonModule,
        AngularPinturaModule,
        NgxExtendedPdfViewerModule,
        MarkdownModule.forRoot()
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: BASE_PATH, useValue: environment.apiBasePath },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        LoggedInGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
