import { ArrayHelper } from "../../../../base/helpers/array-helper";
import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { BuildingComplexBusinessIdentifier } from "../../../identifiers/building-complex-business-identifier";
import { BuildingComplexTechnicalIdentifier } from "../../../identifiers/building-complex-technical-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { EntityLink } from "../../documents/entity-link";
import { EntityTypes } from "../../entity-types";
import { Address } from "../address";
import { Company } from "../company";
import { CoreDataEntity } from "../core-data-entity";
import { Person } from "../person";
import { BuildingComplexStorageDto } from "./building-complex-storage-dto";

/**
 * Basic class for a building complex.
 */
export class BuildingComplex extends CoreDataEntity<BuildingComplexBusinessIdentifier, BuildingComplexTechnicalIdentifier> {
    constructor(init?: Partial<BuildingComplex>) {
        super(EntityTypes.buildingComplex);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public name?: string;

    public address?: Address;

    public addressRaw?: string;

    public personLinks: Array<EntityLink<Person>> = [];

    public companyLink?: EntityLink<Company>;

    public get displayTitle(): string {
        const builder: Set<string|undefined> = new Set<string|undefined>();

        builder.add(this.name);
        builder.add(this.address?.displayTitle);
        if (!this.address?.displayTitle) {
            builder.add(this.addressRaw);
        }
        const builderArray: Array<string> = ArrayHelper.removeEmpty(Array.from(builder));

        if (builderArray.length <= 0) {
            builderArray.push(Identifier.identifierToReadableString(this.identifier));
        }
        return builderArray.join(", ");
    }

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    protected toEntityStorageDto(): BuildingComplexStorageDto {
        const dto: BuildingComplexStorageDto = new BuildingComplexStorageDto({
            name: this.name,

            address: this.address?.toStorageDto(),
            addressRaw: this.addressRaw,

            companyLink: this.companyLink?.toStorageDto()
        });

        dto.personLinks = [];
        for (const personLink of this.personLinks) {
            dto.personLinks.push(personLink.toStorageDto());
        }

        return dto;
    }

    protected fromEntityStorageDto(dto: BuildingComplexStorageDto|undefined): void {
        this.name = dto?.name;

        this.address = CoreDataFactory.createAddress();
        this.address.fromStorageDto(dto?.address);
        this.addressRaw = dto?.addressRaw;

        this.companyLink = dto?.companyLink ? CoreDataFactory.createEntityLinkFromStorageDto(dto.companyLink) as EntityLink<Company> : undefined;

        for (const personLinkDto of dto?.personLinks ?? []) {
            this.personLinks.push(CoreDataFactory.createEntityLinkFromStorageDto(personLinkDto) as EntityLink<Person>);
        }
    }

    public clone(): CoreDataEntity<BuildingComplexBusinessIdentifier, BuildingComplexTechnicalIdentifier> {
        const clone: BuildingComplex = CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
        clone.personLinks = [];
        for (const personLink of this.personLinks) {
            const personLinkClone: EntityLink<Person> = CoreDataFactory.createEntityLinkFromStorageDto(personLink.toStorageDto()) as EntityLink<Person>;
            personLinkClone.entity = personLink.entity?.clone();
            clone.personLinks.push(personLinkClone);
        }
        return clone;
    }
}
