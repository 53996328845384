import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import {
    BuildingWaterWalkthroughInspectionDocument
} from "../../../domain/entities/building-water-walkthrough-inspection-document";
import {
    DefaultService, QuestionDto, RequestBodyCreateWalkthroughInspectionDto, ResponseBodyListWalkthroughInspectionsDto, ResponseBodySuccessDto, TemplateVersionDto,
    WalkthroughInspectionDto
} from "../../../generated/api";
import { errorResult, SafeResult, successResult } from "../../common/safe-result";
import { AuditDocument } from "../../entities/documents/audit-document";
import { DocumentTypes } from "../../entities/documents/document-types";
import { AppException } from "../../entities/exceptions/app-exception";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { DocumentTechnicalIdentifier } from "../../identifiers/document-technical-identifier";
import { SessionService } from "../session/session.service";

/**
 * The service to talk to the server backend.
 */
@Injectable({
    providedIn: "root"
})
export class DocumentsApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    private pendingQuestionUpdates: number = 0;

    public listTemplateVersions(): Promise<Array<TemplateVersionDto>>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listTemplateVersions(this.sessionService.activeAccountId));
    }

    public listWalkthroughInspections(): Promise<ResponseBodyListWalkthroughInspectionsDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listWalkthroughInspections(this.sessionService.activeAccountId));
    }

    public createWalkthroughInspection(walkthroughInspectionDto: RequestBodyCreateWalkthroughInspectionDto): Promise<ResponseBodyListWalkthroughInspectionsDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.createWalkthroughInspection(walkthroughInspectionDto, this.sessionService.activeAccountId));
    }

    public async updateWalkthroughInspectionQuestions(documentIdentifier: DocumentIdentifier, questionDtos: Array<QuestionDto>, createRevision: boolean): Promise<ResponseBodyListWalkthroughInspectionsDto>|never {
        this.sessionService.requiresAccount();

        if (this.pendingQuestionUpdates > 0) {
            console.error(`Detected multiple question updates. Requests pending: ${this.pendingQuestionUpdates + 1}`);
        }

        try {
            this.pendingQuestionUpdates++;
            return await lastValueFrom(this.defaultService.updateWalkthroughInspectionQuestions(documentIdentifier.businessIdentifier as string, questionDtos, this.sessionService.activeAccountId, createRevision));
        } finally {
            this.pendingQuestionUpdates--;
        }
    }

    public getWalkthroughInspection(documentBusinessIdentifier: DocumentBusinessIdentifier, documentTechnicalIdentifier?: DocumentTechnicalIdentifier): Promise<ResponseBodyListWalkthroughInspectionsDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listWalkthroughInspections(this.sessionService.activeAccountId, documentBusinessIdentifier, documentTechnicalIdentifier));
    }

    public async saveDocument(document: AuditDocument): Promise<ResponseBodyListWalkthroughInspectionsDto|undefined> {
        switch (document.type) {
            case DocumentTypes.unknown:
            case DocumentTypes.simpleDocument:
                // These documents cannot be saved to server.
                break;
            case DocumentTypes.buildingWaterWalkthroughInspection:
                const dto: WalkthroughInspectionDto = Api1Converter.walkthroughInspectionToDto(document as BuildingWaterWalkthroughInspectionDocument);
                return lastValueFrom(this.defaultService.updateWalkthroughInspection(document.identifier.businessIdentifier, dto, this.sessionService.activeAccountId));
        }
    }

    public async deleteDocument(documentBusinessIdentifier: DocumentBusinessIdentifier): Promise<SafeResult<void, Error>> {
        const accountResult: SafeResult<void, AppException> = this.sessionService.requiresAccountSafeResult();
        if (accountResult.isError()) {
            return accountResult;
        }

        try {
            const response: ResponseBodySuccessDto = await lastValueFrom(this.defaultService.deleteWalkthroughInspection(documentBusinessIdentifier, this.sessionService.activeAccountId));
            if (response.status == ResponseBodySuccessDto.StatusEnum.Ok) {
                return successResult(undefined);
            }
            return errorResult(new Error(`Unable to delete document ${documentBusinessIdentifier}, result was not "OK".`));
        } catch (error) {
            return errorResult(error as Error);
        }
    }
}
