import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IonInput, ModalController } from "@ionic/angular";

import { DialogService } from "../../../base/services/dialog/dialog.service";
import { ErrorMessageDto } from "../../../generated/api";
import { AppException } from "../../entities/exceptions/app-exception";
import { BackendErrors } from "../../global/backend-errors";
import { FrontendErrors } from "../../global/frontend-errors";
import { AppIcons } from "../../services/icons/app-icons";
import { PersonsService } from "../../services/master-data/persons/persons.service";
import { FormValidationErrorMessages } from "../forms/form-validation-error/form-validation-error-messages";

/**
 * Dialog component to invite a person-form.
 */
@Component({
    selector: "business-invite-person-form",
    templateUrl: "./invite-person.component.html",
    styleUrls: ["./invite-person.component.scss"]
})
export class InvitePersonComponent implements OnInit {
    constructor(
        private readonly personsService: PersonsService,
        private readonly dialogService: DialogService,
        private readonly modalController: ModalController,
        private readonly formBuilder: FormBuilder
    ) {
        this.emailControl = this.formData.get("email")!;
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public inviting: boolean = false;

    public validationMessages: FormValidationErrorMessages = new FormValidationErrorMessages(
        [
            {
                controlName: "email", messages: [
                    {
                        type: "required",
                        message: $localize`:@@persons.validation.inviteEmailRequired:The e-mail is required.` as string
                    },
                    {
                        type: "email",
                        message: $localize`:@@entity.validation.emailFormat:The e-mail is not in the correct format (e.g. name@example.com).` as string
                    }
                ]
            }
        ]
    );

    public formData: FormGroup = this.formBuilder.group({
        email: ["", [Validators.required, Validators.email]]
    });

    @ViewChild("email")
    public emailViewChild!: IonInput;

    public readonly emailControl: AbstractControl;

    public ngOnInit(): void {
        // Do nothing for now
    }

    public async invite(): Promise<void> {
        if (this.formData.status != "VALID") {
            return;
        }

        this.inviting = true;
        try {
            await this.personsService.invitePerson(this.emailControl.value);
            this.dialogService.showToast(":@@persons.invitationSentToast:Invitation has been sent.");
            this.close();
        } catch (error) {
            this.inviting = false;

            const httpError: HttpErrorResponse = error as HttpErrorResponse;
            if (httpError && httpError.error) {
                const serverError: ErrorMessageDto = httpError.error as ErrorMessageDto;
                if (serverError && serverError.code == BackendErrors.BE19PermissionRequired) {
                    this.notifyPermissionDenied();
                    return;
                }
            }
            const appException: AppException = new AppException(FrontendErrors.FE32UnableToInvitePerson, $localize`:@@exception.fe32UnableToInvitePerson:Unable to invite the person. Please try again.`, error as Error);
            this.dialogService.showError(appException).then();
        }
    }

    private notifyPermissionDenied(): void {
        this.dialogService.showAlert($localize`:@@dialog.genericPermissionRequiredTitle:Permission required`, $localize`:@@dialog.genericPermissionRequiredText:There are more permissions required than you currently have. Please talk to your supervisor to invite this person for you or ask the supervisor to adjust your permissions.`).then();
    }

    public close(): void {
        this.modalController.dismiss().then();
    }
}
