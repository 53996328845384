import { AuditDocument } from "../../entities/documents/audit-document";
import { AuditDocumentStorageDto } from "../../entities/documents/audit-document-storage-dto";
import { DocumentTypes } from "../../entities/documents/document-types";
import { AppException } from "../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../global/frontend-errors";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { DocumentTechnicalIdentifier } from "../../identifiers/document-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";
import { DocumentFactory } from "./document-factory";

/**
 * Storage to handle persisted, dirty and updated versions of documents.
 */
export class DocumentStorage {
    constructor(
        private readonly storageService: StorageService
    ) {
    }

    private cachingDisabled: boolean = false;

    private cachedDocuments: Map<DocumentBusinessIdentifier, Map<DocumentTechnicalIdentifier, AuditDocument>> = new Map<DocumentBusinessIdentifier, Map<DocumentTechnicalIdentifier, AuditDocument>>();

    private latestKnownDocuments: Map<DocumentBusinessIdentifier, AuditDocument> = new Map<DocumentBusinessIdentifier, AuditDocument>();

    public async getLatestByBusinessIdentifier(documentBusinessIdentifier: DocumentBusinessIdentifier): Promise<AuditDocument|undefined> {
        if (this.cachingDisabled) {
            return undefined;
        }

        const latestKnown: AuditDocument|undefined = this.latestKnownDocuments.get(documentBusinessIdentifier);
        if (latestKnown) {
            return latestKnown;
        }

        return this.loadLatestFromStorage(documentBusinessIdentifier);
    }

    private async loadLatestFromStorage(businessIdentifier: DocumentBusinessIdentifier): Promise<AuditDocument|undefined> {
        const keys: Array<string> = await this.storageService.allKeys();
        const groupKeyPrefix: string = this.storageService.getGroupKey(StorageKeys.groupDocuments, `${businessIdentifier}:`);
        const documentKeys: Array<string> = [];
        for (const key of keys) {
            if (key.startsWith(groupKeyPrefix)) {
                documentKeys.push(key);
            }
        }

        let latestDocument: AuditDocumentStorageDto|undefined;
        for (const documentKey of documentKeys) {
            const dto: AuditDocumentStorageDto|undefined = await this.storageService.getByRawKey(documentKey);
            if (!latestDocument?.created || dto?.created && dto.created > latestDocument?.created) {
                latestDocument = dto;
            }
        }

        if (!latestDocument) {
            return undefined;
        }

        const document: AuditDocument|undefined = DocumentFactory.createFromStorageDto(latestDocument);
        if (!document) {
            return undefined;
        }

        this.latestKnownDocuments.set(businessIdentifier, document);

        this.updateCache(document);
        return document;
    }

    // noinspection JSUnusedLocalSymbols
    private async loadFromStorage(identifier: DocumentIdentifier): Promise<AuditDocument|undefined> {
        const key: string = Identifier.identifierToString(identifier);
        const dto: AuditDocumentStorageDto|undefined = await this.storageService.getGroupItem(StorageKeys.groupDocuments, key);
        if (!dto) {
            return undefined;
        }

        if (dto.storageVersion != dto.currentStorageVersion) {
            // Delete if stored version is not compatible to the current one
            await this.storageService.delete(StorageKeys.groupDocuments, key);
            return undefined;
        }

        const document: AuditDocument|undefined = DocumentFactory.createFromStorageDto(dto);
        if (!document) {
            return undefined;
        }
        this.updateCache(document);
        return document;
    }

    private updateCache(document: AuditDocument): void {
        let documents: Map<DocumentTechnicalIdentifier, AuditDocument>|undefined = this.cachedDocuments.get(document.identifier.businessIdentifier);
        if (!documents) {
            documents = new Map<DocumentTechnicalIdentifier, AuditDocument>();
            this.cachedDocuments.set(document.identifier.businessIdentifier, documents);
        }
        documents.set(document.identifier.technicalIdentifier ?? 0 as DocumentTechnicalIdentifier, document);
    }

    public async updateDocument(document: AuditDocument): Promise<void> {
        const latestKnown: AuditDocument|undefined = this.latestKnownDocuments.get(document.identifier.businessIdentifier);
        if (!latestKnown?.created || (document.created && document.created > latestKnown.created)) {
            this.latestKnownDocuments.set(document.identifier.businessIdentifier, document);
        }

        this.updateCache(document);

        await this.persistDocument(document);
    }

    private async persistDocument(document: AuditDocument): Promise<void> {
        let dto: AuditDocumentStorageDto|undefined = undefined;
        switch (document.type) {
            case DocumentTypes.unknown:
                break;
            case DocumentTypes.simpleDocument:
            case DocumentTypes.buildingWaterWalkthroughInspection:
                dto = document.toStorageDto();
                break;
        }

        if (!dto) {
            throw new AppException(FrontendErrors.FE34DocumentTypeIsUnknown, $localize`:@@exception.fe35UnableToPersistDocumentTypeIsUnknown:Unable to persist document, the document type "${document.type}:documentType:" is not known. Please update the app or refresh the browser.`);
        }

        const key: string = Identifier.identifierToString(document.identifier);
        await this.storageService.setGroupItem(StorageKeys.groupDocuments, key, dto);
    }

    public activateCaching(active: boolean): void {
        this.cachingDisabled = !active;
    }
}
