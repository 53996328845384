import { DialogButton } from "./dialog-button";

/**
 * Options for the basic dialog.
 */
export class BasicDialogOptions {
    public title: string;
    public subtitle?: string;
    public message?: string;
    public buttons?: Array<DialogButton>;

    constructor(title: string, subtitle?: string, message?: string, buttons?: Array<DialogButton>) {
        this.title = title;
        this.subtitle = subtitle;
        this.message = message;
        this.buttons = buttons;
    }
}
