<ion-header class="modalHeader ion-no-border">
    <ion-toolbar>
        <ion-title i18n="@@registration.dialogTitle">
            Create a new account
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-label color="medium">
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="modalContent">
    <swiper (swiper)="setSwiperInstance($event)" [allowSlideNext]="true" [allowSlidePrev]="true" [allowTouchMove]="false" [centeredSlides]="true" [pagination]="true" class="swiper">
        <ng-template swiperSlide>
            <ul>
                <li i18n="@@registration.welcomeText">Welcome to Audit!<br /><br />You can register a free account in the next steps.</li>
                <li class="newBlock" i18n="@@registration.haveInvitationCode">Do you have an invitation code?</li>
                <li>
                    <ion-button (click)="swipeTo(pages.enterInvitationCode)" color="success">
                        <fa-icon [icon]="appIcons.checkbox"></fa-icon>&nbsp;<span i18n="@@registration.answerYes">Yes</span></ion-button>
                    <ion-button (click)="swipeTo(pages.enterEmail)" color="primary">
                        <fa-icon [icon]="appIcons.xMarkCircle"></fa-icon>&nbsp;<span i18n="@@registration.answerNo">No</span></ion-button>
                </li>
                <li class="newBlock">
                    <ion-buttons>
                        <ion-button>
                            <fa-icon [icon]="appIcons.helpCircle"></fa-icon>&nbsp;<span i18n="@@registration.whereToGetInvitationCode">Where do I get an invitation code from?</span></ion-button>
                    </ion-buttons>
                    <ion-buttons>
                        <ion-button>
                            <fa-icon [icon]="appIcons.helpCircle"></fa-icon>&nbsp;<span i18n="@@registration.whyNeedInvitationCode">Do I need an invitation code?</span></ion-button>
                    </ion-buttons>
                </li>
            </ul>
        </ng-template>

        <ng-template swiperSlide>
            <form (ngSubmit)="checkInvitationCode()" [formGroup]="formData">
                <ul>
                    <li i18n="@@registration.enterInvitationCode">Please enter your invitation code below.</li>
                    <li class="newBlock">
                        <ion-item>
                            <ion-input #invitationCode formControlName="invitationCode" i18n-placeholder="@@registration.invitationCodePlaceholder" placeholder="Enter your code here"></ion-input>
                        </ion-item>
                        <app-form-validation-error [formGroup]="formData" [validationMessages]="validationMessages" itemName="invitationCode"></app-form-validation-error>
                    </li>
                    <li class="newBlock">
                        <ion-button (click)="checkInvitationCode()">
                            <fa-icon [icon]="appIcons.genericArrowRight"></fa-icon>&nbsp;<ng-container i18n="@@registration.continue">Continue</ng-container>
                        </ion-button>
                    </li>
                    <li class="newBlock">
                        <ion-buttons>
                            <ion-button>
                                <fa-icon [icon]="appIcons.helpCircle"></fa-icon>&nbsp;<span i18n="@@registration.whereToGetInvitationCode">Where do I get an invitation code from?</span></ion-button>
                        </ion-buttons>
                        <ion-buttons>
                            <ion-button>
                                <fa-icon [icon]="appIcons.helpCircle"></fa-icon>&nbsp;<span i18n="@@registration.whyNeedInvitationCode">Do I need an invitation code?</span></ion-button>
                        </ion-buttons>
                    </li>
                </ul>
            </form>
        </ng-template>

        <ng-template swiperSlide>
            <form (ngSubmit)="checkEmail()" [formGroup]="formData">
                <ul>
                    <li i18n="@@registration.enterEmail">To create an account we would need your e-mail to log you in afterwards. Please type in your e-mail here.</li>
                    <li class="newBlock">
                        <ion-item>
                            <ion-input #email (beforeinput)="formHelper.trimWhitespacesBeforeInput($event)" (paste)="formHelper.trimWhiteSpacesOnPaste($event)" formControlName="email" i18n-placeholder="@@registration.emailPlaceholder" placeholder="E-Mail" type="email"></ion-input>
                        </ion-item>
                        <app-form-validation-error [formGroup]="formData" [validationMessages]="validationMessages" itemName="email"></app-form-validation-error>
                    </li>
                    <li class="newBlock">
                        <ion-button (click)="checkEmail()" [disabled]="formData.controls.email.invalid">
                            <fa-icon [icon]="appIcons.genericArrowRight"></fa-icon>&nbsp;<ng-container i18n="@@registration.continue">Continue</ng-container>
                        </ion-button>
                    </li>
                </ul>
            </form>
        </ng-template>

        <ng-template swiperSlide>
            <form (ngSubmit)="checkPassword()" [formGroup]="formData">
                <ul>
                    <li i18n="@@registration.enterPassword">Protect your account with a password.</li>
                    <li class="newBlock">
                        <ion-item>
                            <ion-input #password formControlName="password" i18n-placeholder="@@registration.passwordPlaceholder" placeholder="Your password" type="password"></ion-input>
                        </ion-item>
                        <app-form-validation-error [formGroup]="formData" [validationMessages]="validationMessages" itemName="password"></app-form-validation-error>
                        <ion-item>
                            <ion-input (keyup.enter)="checkPasswordSubmit()" formControlName="passwordRepeat" i18n-placeholder="@@registration.passwordRepeatPlaceholder" placeholder="Repeat your password" type="password"></ion-input>
                        </ion-item>
                        <app-form-validation-error [formGroup]="formData" [validationMessages]="validationMessages" itemName="passwordRepeat"></app-form-validation-error>
                    </li>
                    <li class="newBlock">
                        <ion-button #createAccountButton (click)="checkPassword()" [disabled]="formData.controls.password.invalid || formData.controls.passwordRepeat.invalid">
                            <fa-icon [icon]="appIcons.genericArrowRight"></fa-icon>&nbsp;<ng-container i18n="@@registration.createAccountButton">Create account</ng-container>
                        </ion-button>
                    </li>
                </ul>
            </form>
        </ng-template>

        <ng-template swiperSlide>
            <ul>
                <li>
                    <ion-spinner></ion-spinner>
                </li>
                <li i18n="@@registration.creatingAccount">Creating your account, please wait...</li>
            </ul>
        </ng-template>
    </swiper>
</ion-content>

<ion-footer class="modalFooter ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="goBack()" *ngIf="pageHistory.length">
                <fa-icon [icon]="appIcons.goBack"></fa-icon>&nbsp;<ng-container i18n="@@registration.goBack">Back</ng-container>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <fa-icon [icon]="appIcons.genericCancel"></fa-icon>&nbsp;<ng-container i18n="@@dialog.genericCloseButton">Close</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
