import { WebHelper } from "../../../base/helpers/web-helper";
import { ModalModes } from "../../../base/services/dialog/modal-modes";
import { IntentsService } from "../../../base/services/intents/intents.service";
import { IocService } from "../../../base/services/ioc/ioc.service";
import { SafeResult } from "../../common/safe-result";
import { MasterDataFormDialogComponent } from "../../components/forms/form-dialog/master-data-form-dialog.component";
import { MasterDataFormDialogOptions } from "../../components/forms/form-dialog/master-data-form-dialog-options";
import { Building } from "../../entities/core-data/buildings/building";
import { BuildingComplex } from "../../entities/core-data/buildings/building-complex";
import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../entities/documents/entity-link";
import { EntityTypes } from "../../entities/entity-types";
import { AppException } from "../../entities/exceptions/app-exception";
import { Process } from "../../entities/projects-processes/process";
import { FrontendErrors } from "../../global/frontend-errors";
import { PermissionsHelper } from "../../helpers/permissions-helper";
import { BuildingComplexIdentifier } from "../../identifiers/building-complex-identifier";
import { ProjectIdentifier } from "../../identifiers/project-identifier";
import { I18nTextService } from "../../services/i18n/i18n-text.service";
import { BuildingsService } from "../../services/master-data/buildings/buildings.service";
import { LoggedInIntent } from "../abstract/logged-in-intent";
import { EditCoreDataIntentOptions } from "./edit-core-data-intent-options";

/**
 * Intent to edit a core data entity.
 */
export class EditCoreDataIntent extends LoggedInIntent<EditCoreDataIntent> {
    constructor(
        iocService: IocService) {
        super(iocService);
    }

    private readonly i18nTextService: I18nTextService = this.iocService.resolve(I18nTextService);

    private readonly intentsService: IntentsService = this.iocService.resolve(IntentsService);

    private readonly buildingsService: BuildingsService = this.iocService.resolve(BuildingsService);

    public async executeIntent(intentOptions: EditCoreDataIntentOptions<GenericCoreDataEntity>): Promise<boolean> {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const validName: boolean = intentOptions.entity.displayTitle.length > 3;
        const entityName: string = validName ? intentOptions.entity.displayTitle : this.i18nTextService.getEntityTypeText(intentOptions.entity.entityType);

        const hasCreatePermissions: boolean = PermissionsHelper.canCreate(intentOptions.entity.entityType, this.sessionService.activePermissions);
        const hasUpdatePermissions: boolean = PermissionsHelper.canUpdate(intentOptions.entity.entityType, this.sessionService.activePermissions);

        const permissionReadonly: boolean = (intentOptions.entity.isNew && !hasCreatePermissions) || (!intentOptions.entity.isNew && !hasUpdatePermissions);

        const dialogOptions: MasterDataFormDialogOptions = new MasterDataFormDialogOptions(
            intentOptions.customTitle
                ? intentOptions.customTitle
                : intentOptions.readonly
                    ? $localize`:@@form.viewCoreDataTitle:View ${entityName}`
                    : intentOptions.entity.isNew
                        ? $localize`:@@form.createCoreDataTitle:Create ${entityName}`
                        : $localize`:@@form.editCoreDataTitle:Edit ${entityName}`,
            {
                readonly: intentOptions.readonly,
                readonlyDueToPermissions: permissionReadonly,
                permissionCreate: hasCreatePermissions,
                permissionUpdate: hasUpdatePermissions,
                additionalEntityLinks: intentOptions.additionalEntityLinks,
                propertiesFilterInclude: intentOptions.propertiesFilterInclude,
                propertiesFilterExclude: intentOptions.propertiesFilterExclude,
                mandatoryProperties: intentOptions.mandatoryProperties,
                autocomplete: intentOptions.autocomplete
            }
        );
        const clone: GenericCoreDataEntity = intentOptions.entity.clone();
        dialogOptions.entities = [clone];

        const resultOptions: MasterDataFormDialogOptions|undefined = await this.dialogService.showModal<MasterDataFormDialogOptions>({
            component: MasterDataFormDialogComponent,
            mode: intentOptions.modalMode ?? ModalModes.large,
            payload: { options: dialogOptions }
        });
        if (resultOptions?.entities && resultOptions.entities.length > 0) {
            await this.save(resultOptions.entities[0], intentOptions, resultOptions);
        }

        return true;
    }

    private async save(entity: GenericCoreDataEntity, intentOptions: EditCoreDataIntentOptions<GenericCoreDataEntity>, resultOptions: MasterDataFormDialogOptions): Promise<void> {
        let loading: HTMLIonLoadingElement|undefined = intentOptions.resultOnlyDoNotSave ? undefined : await this.dialogService.showLoading($localize`:@@coreData.savingItem:Saving, please wait...`);
        let savedEntity: GenericCoreDataEntity|undefined = undefined;
        try {
            switch (entity.entityType) {
                case EntityTypes.process:
                    savedEntity = await this.prepareSaveProcess(entity as Process, intentOptions);
                    break;
                case EntityTypes.building:
                    savedEntity = await this.prepareSaveBuilding(entity as Building, intentOptions);
                    break;
                case EntityTypes.buildingComplex:
                    intentOptions.resultEntity = await this.prepareSaveBuildingComplex(entity as BuildingComplex, intentOptions, resultOptions);
                    // Return because the method handles everything regarding the save process.
                    return;
            }

            intentOptions.resultEntity = savedEntity ?? (intentOptions.resultOnlyDoNotSave ? entity : await this.coreDataService.saveEntity(entity, intentOptions.archiveOnSave));
        } catch (error) {
            await loading?.dismiss();
            loading = undefined;

            if (WebHelper.isNoInternetError(error)) {
                await this.dialogService.showAlert($localize`:@@coreData.unableToSaveNoInternetTitle:No internet - Unable to save`, $localize`:@@coreData.unableToSaveNoInternet:The item could not be saved due to an unstable internet connection. Please try again.`);
            } else {
                const appException: AppException = new AppException(FrontendErrors.FE42UnableToSaveCoreDataEntity, $localize`:@@coreData.unableToSaveEntity:It was not possible to save the item. Please check the error details, try again or contact the support.`, error as Error);
                await this.dialogService.showError(appException).then();
            }
            intentOptions.entity = entity;
            this.intentsService.executeIntent(EditCoreDataIntent, intentOptions);
        } finally {
            await loading?.dismiss();
        }
    }

    private async prepareSaveProcess(process: Process, intentOptions: EditCoreDataIntentOptions<GenericCoreDataEntity>): Promise<Process|undefined> {
        const projectIdentifier: ProjectIdentifier|undefined = intentOptions.additionalEntityLinks?.find((value: EntityLink<GenericCoreDataEntity>) => value.entityType == EntityTypes.project)?.identifier as ProjectIdentifier;
        if (!projectIdentifier) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Process ${process.displayTitle} does not have a required project identifier.`);
        }
        process.projectIdentifier = projectIdentifier;
        return undefined;
    }

    private async prepareSaveBuilding(building: Building, intentOptions: EditCoreDataIntentOptions<GenericCoreDataEntity>): Promise<Building|undefined> {
        let buildingComplexIdentifier: BuildingComplexIdentifier|undefined = intentOptions.additionalEntityLinks?.find((link: EntityLink<GenericCoreDataEntity>) => link.entityType == EntityTypes.buildingComplex)?.identifier as BuildingComplexIdentifier;
        buildingComplexIdentifier = buildingComplexIdentifier ?? intentOptions.additionalEntities.find((item: GenericCoreDataEntity) => item.entityType == EntityTypes.buildingComplex)?.identifier as BuildingComplexIdentifier;
        if (!buildingComplexIdentifier) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Building ${building.displayTitle} does not have a required business complex identifier.`);
        }
        building.buildingComplexIdentifier = buildingComplexIdentifier;
        return undefined;
    }

    private async prepareSaveBuildingComplex(buildingComplex: BuildingComplex, intentOptions: EditCoreDataIntentOptions<GenericCoreDataEntity>, resultOptions: MasterDataFormDialogOptions): Promise<BuildingComplex|undefined> {
        if (intentOptions.resultOnlyDoNotSave) {
            return undefined;
        }

        const buildingComplexIsNew: boolean = buildingComplex.isNew;

        const canCreateBuildingComplex: boolean = PermissionsHelper.canCreate(EntityTypes.buildingComplex, this.sessionService.activePermissions);
        const canUpdateBuildingComplex: boolean = PermissionsHelper.canUpdate(EntityTypes.buildingComplex, this.sessionService.activePermissions);
        const canCreateBuilding: boolean = PermissionsHelper.canCreate(EntityTypes.building, this.sessionService.activePermissions);
        if (buildingComplexIsNew && !canCreateBuildingComplex) {
            return undefined;
        }

        const savedBuildingComplex: BuildingComplex = canUpdateBuildingComplex
            ? await this.coreDataService.saveEntity(buildingComplex, intentOptions.archiveOnSave)
            : buildingComplex;

        let initialBuilding: Building|undefined;
        if (buildingComplexIsNew && savedBuildingComplex.identifier.technicalIdentifier) {
            const buildingsResult: SafeResult<Array<Building>, AppException> = await this.buildingsService.getBuildings(savedBuildingComplex.identifier.technicalIdentifier);
            if (buildingsResult.isSuccess()) {
                initialBuilding = buildingsResult.result.length == 1 ? buildingsResult.result[0] : undefined;
            }
        }
        const buildings: Array<Building> = (resultOptions.additionalEntityLinks?.filter((link: EntityLink<GenericCoreDataEntity>) => link.entityType == EntityTypes.building && link.entity).map((link: EntityLink<GenericCoreDataEntity>) => link.entity) ?? []) as Array<Building>;
        // Save all new buildings that has been added
        for (const subBuilding of buildings) {
            if (subBuilding.isNew && canCreateBuilding) {
                subBuilding.buildingComplexIdentifier = savedBuildingComplex.identifier as BuildingComplexIdentifier;
                if (initialBuilding) {
                    subBuilding.identifier = initialBuilding.identifier;
                    initialBuilding = undefined;
                }
                await this.coreDataService.saveEntity(subBuilding, intentOptions.archiveOnSave);
            }
        }

        return savedBuildingComplex;
    }

    public cancel(): Promise<void> {
        return Promise.resolve();
    }
}
