import { EntityHelper } from "../../helpers/entity-helper";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { DocumentTechnicalIdentifier } from "../../identifiers/document-technical-identifier";
import { PersonBusinessIdentifier } from "../../identifiers/person-business-identifier";
import { PersonIdentifier } from "../../identifiers/person-identifier";
import { PersonTechnicalIdentifier } from "../../identifiers/person-technical-identifier";
import { TemplateVersionTechnicalIdentifier } from "../../identifiers/template-version-technical-identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { CoreDataEntity } from "../core-data/core-data-entity";
import { GenericCoreDataEntity } from "../core-data/generic-core-data-entity";
import { EntityTypes } from "../entity-types";
import { QualityAssuranceItem } from "../quality-assurance/quality-assurance-item";
import { StorableEntity } from "../storable-entity";
import { AnswerScorings } from "./answer-scorings";
import { AuditDocumentConflict } from "./audit-document-conflict";
import { AuditDocumentStorageDto } from "./audit-document-storage-dto";
import { DocumentAnswer } from "./document-answer";
import { DocumentInstance } from "./document-instance";
import { DocumentQuestion } from "./document-question";
import { DocumentQuestionTypes } from "./document-question-types";
import { DocumentStatus } from "./document-status";
import { DocumentTemplate } from "./document-template";
import { DocumentTypes } from "./document-types";
import { DocumentValueContainer } from "./document-value-container";
import { EntityLink } from "./entity-link";

/**
 * The base class of a document. Only concrete implementations are allowed.
 */
export abstract class AuditDocument extends CoreDataEntity<DocumentBusinessIdentifier, DocumentTechnicalIdentifier> implements StorableEntity<AuditDocumentStorageDto> {
    protected constructor(identifier: DocumentIdentifier, documentTemplate: DocumentTemplate, init?: Partial<AuditDocument>) {
        super(EntityTypes.document);

        if (init) {
            Object.assign(this, init);
        }

        this.identifier = identifier;
        this.template = documentTemplate;
        this.instance = new DocumentInstance(documentTemplate);
    }

    public readonly abstract type: DocumentTypes;

    public created?: string;

    public updated?: string;

    public updatedClient?: string;

    public status: DocumentStatus = DocumentStatus.inProgress;

    public targetDate?: string;

    public template: DocumentTemplate;

    public instance: DocumentInstance;

    public assignedAuditor?: PersonIdentifier;

    public assignedPerson?: PersonIdentifier;

    public assignedQualityManager?: PersonIdentifier;

    public linkedEntities: Array<EntityLink<GenericCoreDataEntity>> = [];

    public qualityAssuranceItems: Array<QualityAssuranceItem> = [];

    public additionalInfo?: string;

    public reviewSummary?: string;

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    public abstract get shortCode(): string;

    public abstract get displayTitle(): string;

    public abstract get displaySubTitle(): string;

    public abstract get documentTypeTitle(): string;

    public get isInReview(): boolean {
        return this.status == DocumentStatus.inReview
            || this.status == DocumentStatus.reviewAccepted
            || this.status == DocumentStatus.reviewRejected;
    }

    public linkEntity<TEntity extends GenericCoreDataEntity>(entity: TEntity, identifierPairPropertyName: string, linkDescription?: string): EntityLink<TEntity> {
        EntityHelper.typeAssertIdentifierPropertyName(this, identifierPairPropertyName);

        const link: EntityLink<TEntity> = CoreDataFactory.createEntityLinkFromEntity(entity, linkDescription);
        link.identifierPairPropertyName = identifierPairPropertyName;
        this.linkedEntities.push(link);
        return link;
    }

    protected abstract toStorageDtoSpecificProperties(): AuditDocumentStorageDto;

    protected abstract fromStorageDtoSpecificProperties(dto: AuditDocumentStorageDto): void;

    protected toEntityStorageDto(): AuditDocumentStorageDto {
        const dto: AuditDocumentStorageDto = this.toStorageDtoSpecificProperties();

        dto.instance = this.instance.toStorageDto();

        dto.businessId = this.identifier.businessIdentifier;
        dto.technicalId = this.identifier.technicalIdentifier;

        dto.templateVersionTechnicalId = this.template.technicalIdentifier;
        dto.template = this.template.toStorageDto();

        dto.type = DocumentTypes[this.type];
        dto.created = this.created;
        dto.updated = this.updated;
        dto.updatedClient = this.updatedClient;
        dto.targetDate = this.targetDate;
        dto.state = DocumentStatus[this.status];
        dto.assignedAuditorBusinessId = this.assignedAuditor?.businessIdentifier;
        dto.assignedAuditorTechnicalId = this.assignedAuditor?.technicalIdentifier;
        dto.assignedPersonBusinessId = this.assignedPerson?.businessIdentifier;
        dto.assignedPersonTechnicalId = this.assignedPerson?.technicalIdentifier;
        dto.assignedQualityManagerBusinessId = this.assignedQualityManager?.businessIdentifier;
        dto.assignedQualityManagerTechnicalId = this.assignedQualityManager?.technicalIdentifier;

        dto.additionalInfo = this.additionalInfo;
        dto.reviewSummary = this.reviewSummary;

        dto.linkedEntities = [];
        for (const entityLink of this.linkedEntities) {
            dto.linkedEntities.push(entityLink.toStorageDto());
        }

        dto.qualityAssuranceItems = [];
        for (const qualityAssuranceItem of this.qualityAssuranceItems) {
            dto.qualityAssuranceItems.push(qualityAssuranceItem.toStorageDto());
        }

        return dto;
    }

    protected fromEntityStorageDto(dto: AuditDocumentStorageDto): void {
        this.fromStorageDtoSpecificProperties(dto);

        this.identifier = {
            businessIdentifier: dto.businessId as DocumentBusinessIdentifier,
            technicalIdentifier: dto.technicalId as DocumentTechnicalIdentifier
        };
        this.created = dto.created;
        this.updated = dto.updated;
        this.updatedClient = dto.updatedClient;
        this.targetDate = dto.targetDate;
        this.status = (dto.state ? dto.state as DocumentStatus : DocumentStatus.unknown) ?? DocumentStatus.unknown;
        this.assignedAuditor = {
            businessIdentifier: dto.assignedAuditorBusinessId as PersonBusinessIdentifier,
            technicalIdentifier: dto.assignedAuditorTechnicalId as PersonTechnicalIdentifier
        };
        this.assignedPerson = {
            businessIdentifier: dto.assignedPersonBusinessId as PersonBusinessIdentifier,
            technicalIdentifier: dto.assignedPersonTechnicalId as PersonTechnicalIdentifier
        };
        this.assignedQualityManager = {
            businessIdentifier: dto.assignedQualityManagerBusinessId as PersonBusinessIdentifier,
            technicalIdentifier: dto.assignedQualityManagerTechnicalId as PersonTechnicalIdentifier
        };

        this.additionalInfo = dto.additionalInfo;
        this.reviewSummary = dto.reviewSummary;

        this.linkedEntities = [];
        for (const linkedEntityDto of dto.linkedEntities ?? []) {
            const linkedEntity: EntityLink<GenericCoreDataEntity>|undefined = CoreDataFactory.createEntityLinkFromStorageDto(linkedEntityDto);
            if (linkedEntity) {
                this.linkedEntities.push(linkedEntity);
            }
        }

        this.qualityAssuranceItems = [];
        for (const qualityAssuranceItemDto of dto.qualityAssuranceItems ?? []) {
            const qualityAssuranceItem: QualityAssuranceItem = new QualityAssuranceItem();
            qualityAssuranceItem.fromStorageDto(qualityAssuranceItemDto);
            if (qualityAssuranceItem) {
                this.qualityAssuranceItems.push(qualityAssuranceItem);
            }
        }

        this.template = this.template ?? new DocumentTemplate(dto.templateVersionTechnicalId as TemplateVersionTechnicalIdentifier, this.type);
        this.template.fromStorageDto(dto.template);

        this.instance = this.instance ?? new DocumentInstance(this.template);
        this.instance.fromStorageDto(dto.instance);
    }

    protected abstract checkDocumentUpdate(updateDocument: AuditDocument): Array<AuditDocumentConflict>;

    public applyFromUpdatedDocument(updatedDocument: AuditDocument, applyNewLinkedEntities: boolean): Array<AuditDocumentConflict> {
        const conflictsBaseDocument: Array<AuditDocumentConflict> = this.checkBaseDocumentUpdate(updatedDocument);
        const conflictsConcreteDocument: Array<AuditDocumentConflict> = this.checkDocumentUpdate(updatedDocument);
        if (conflictsBaseDocument.length > 0 || conflictsConcreteDocument.length > 0) {
            return conflictsBaseDocument.concat(conflictsConcreteDocument);
        }

        this.fromStorageDto(updatedDocument.toStorageDto());

        if (applyNewLinkedEntities) {
            this.linkedEntities = updatedDocument.linkedEntities;
        }

        this.identifier.technicalIdentifier = updatedDocument.identifier.technicalIdentifier;

        return [];
    }

    private checkBaseDocumentUpdate(_updateDocument: AuditDocument): Array<AuditDocumentConflict> {
        return [];
    }

    public clone(): AuditDocument {
        // Cloning of documents is not allowed
        console.error("Tried to clone a document. This is not allowed.");
        return this;
    }

    public generateSummaryForAi(): string {
        let result: string = "|Frage|Antwort|Kommentar|Schweregrad|\n" +
            "|---|---|---|---|\n";
        const containers: Array<DocumentValueContainer> = this.instance.getAllValueContainers();
        for (const container of containers) {
            const question: DocumentQuestion = this.template.getQuestion(container.questionTemplateIdentifier);
            if (question.type != DocumentQuestionTypes.singleImage) {
                result += `|${question.title}`;
                const answerStrings: Array<string> = [];
                let maxScoring: AnswerScorings = AnswerScorings.neutral;
                for (const answer of container.answers) {
                    const answerTemplate: DocumentAnswer|undefined = question.answers.find((item: DocumentAnswer) => item.value.value == answer.value);
                    answerStrings.push(answerTemplate ? `${answerTemplate.label} ${answerTemplate.unit ? answerTemplate.unit : ""}`.trim() : answer.value);
                    if (answerTemplate?.scoring) {
                        maxScoring = answerTemplate.scoring;
                    }
                }
                result += `|${answerStrings.length ? answerStrings.join(", ") : "---"}|${(container.comment?.text ? container.comment?.text : "")}|${maxScoring}|\n`;
            }
        }
        return result;
    }
}
