import { Identifier } from "../../identifiers/identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { SectionIdentifier } from "../../identifiers/section-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";
import { StorableEntity } from "../storable-entity";
import { DocumentQuestion } from "./document-question";
import { DocumentQuestionStorageDto } from "./document-question-storage-dto";
import { DocumentSectionStorageDto } from "./document-section-storage-dto";

/**
 * The section of a chapter. A section is a group of question-items that are somehow linked to each other.
 */
export class DocumentSection implements StorableEntity<DocumentSectionStorageDto> {
    public constructor(templateIdentifier: SectionTemplateIdentifier, init?: Partial<DocumentSection>) {
        if (init) {
            Object.assign(this, init);
        }

        this.templateIdentifier = templateIdentifier;
    }

    public identifier: SectionIdentifier = Identifier.empty();

    public templateIdentifier: SectionTemplateIdentifier;

    public parentTemplateIdentifier?: SectionTemplateIdentifier;

    public title?: string;

    public icon?: string;

    public sections: Array<DocumentSection> = [];

    public questions: Array<DocumentQuestion> = [];

    public repeatable: boolean = false;

    public enabled: boolean = true;

    public enablable: boolean = false;

    public addPartialQuestion(templateIdentifier: QuestionTemplateIdentifier, sectionTemplateIdentifier: SectionTemplateIdentifier, partialQuestion: Partial<DocumentQuestion>): DocumentQuestion {
        const question: DocumentQuestion = new DocumentQuestion(templateIdentifier, sectionTemplateIdentifier, partialQuestion);
        this.questions.push(question);
        return question;
    }

    public addQuestion(question: DocumentQuestion): DocumentQuestion {
        this.questions.push(question);
        return question;
    }

    public addPartialSection(templateIdentifier: SectionTemplateIdentifier, partialSection: Partial<DocumentSection>): DocumentSection {
        const section: DocumentSection = new DocumentSection(templateIdentifier, partialSection);
        this.sections.push(section);
        return section;
    }

    public addSection(item: DocumentSection): DocumentSection {
        this.sections.push(item);
        return item;
    }

    public allQuestionsEmpty(): boolean {
        return this.questions.some((question: DocumentQuestion) => !question.isEmpty());
    }

    public clone(): DocumentSection {
        const clone: DocumentSection = new DocumentSection(this.templateIdentifier);
        const cloneDto: DocumentSectionStorageDto = this.toStorageDto();
        clone.fromStorageDto(cloneDto);
        return clone;
    }

    public toStorageDto(): DocumentSectionStorageDto {
        const dto: DocumentSectionStorageDto = new DocumentSectionStorageDto();
        dto.businessId = this.identifier.businessIdentifier;
        dto.technicalId = this.identifier.technicalIdentifier;
        dto.templateId = this.templateIdentifier;
        dto.parentTemplateId = this.parentTemplateIdentifier;

        dto.title = this.title;
        dto.icon = this.icon;
        dto.repeatable = this.repeatable;
        dto.enablable = this.enablable;
        dto.enabled = this.enabled;

        for (const section of this.sections) {
            const sectionDto: DocumentSectionStorageDto = section.toStorageDto();
            dto.sections.push(sectionDto);
        }

        for (const question of this.questions) {
            const questionDto: DocumentQuestionStorageDto = question.toStorageDto();
            dto.questions.push(questionDto);
        }

        return dto;
    }

    public fromStorageDto(dto: DocumentSectionStorageDto|undefined): void {
        this.identifier = Identifier.create(dto?.businessId, dto?.technicalId);
        this.templateIdentifier = dto?.templateId as SectionTemplateIdentifier;
        this.parentTemplateIdentifier = dto?.parentTemplateId as SectionTemplateIdentifier;

        this.title = dto?.title;
        this.icon = dto?.icon;
        this.repeatable = dto?.repeatable ?? false;
        this.enablable = dto?.enablable ?? false;
        this.enabled = dto?.enabled ?? true;

        for (const sectionDto of dto?.sections ?? []) {
            const existingSection: DocumentSection|undefined = this.sections.find((section: DocumentSection) => section.templateIdentifier == sectionDto.templateId);
            if (existingSection) {
                existingSection.fromStorageDto(sectionDto);
            } else {
                const section: DocumentSection = new DocumentSection(sectionDto.templateId as SectionTemplateIdentifier);
                section.fromStorageDto(sectionDto);
                this.sections.push(section);
            }
        }

        // noinspection DuplicatedCode
        for (const questionDto of dto?.questions ?? []) {
            const existingQuestion: DocumentQuestion|undefined = this.questions.find((question: DocumentQuestion) => question.templateIdentifier == questionDto.templateIdentifier);
            if (existingQuestion) {
                existingQuestion.fromStorageDto(questionDto);
            } else {
                const question: DocumentQuestion = new DocumentQuestion(questionDto.templateIdentifier as QuestionTemplateIdentifier, questionDto.sectionTemplateIdentifier as SectionTemplateIdentifier);
                question.fromStorageDto(questionDto);
                this.questions.push(question);
            }
        }
    }
}
