import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { EntityBusinessIdentifier } from "../../identifiers/entity-business-identifier";
import { EntityTechnicalIdentifier } from "../../identifiers/entity-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { CoreDataEntity } from "../core-data/core-data-entity";
import { EntityTypes } from "../entity-types";
import { Sample } from "./sample";
import { SamplingPlanStorageDto } from "./sampling-plan-storage-dto";

/**
 * A sampling plan containing multiple {@link Sample}.
 */
export class SamplingPlan extends CoreDataEntity<EntityBusinessIdentifier, EntityTechnicalIdentifier> {
    constructor(init?: Partial<SamplingPlan>) {
        super(EntityTypes.samplingPlan);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public documentIdentifier: DocumentIdentifier = Identifier.empty();

    public samples: Array<Sample> = [];

    public additionalInfo?: string;

    public get displayTitle(): string {
        return Identifier.identifierToString(this.identifier);
    }

    public get isNew(): boolean {
        return !Identifier.isEmpty(this.documentIdentifier);
    }

    protected toEntityStorageDto(): SamplingPlanStorageDto {
        const dto: SamplingPlanStorageDto = new SamplingPlanStorageDto({
            businessId: this.identifier.businessIdentifier,
            technicalId: this.identifier.technicalIdentifier,

            documentBusinessId: this.documentIdentifier.businessIdentifier,
            documentTechnicalId: this.documentIdentifier.technicalIdentifier,

            samples: [],

            additionalInfo: this.additionalInfo
        });

        for (const sample of this.samples) {
            dto.samples?.push(sample.toStorageDto());
        }

        return dto;
    }

    protected fromEntityStorageDto(dto: SamplingPlanStorageDto|undefined): void {
        this.documentIdentifier = Identifier.create(dto?.documentBusinessId, dto?.documentTechnicalId);

        this.samples = [];
        for (const sampleDto of dto?.samples ?? []) {
            const sample: Sample|undefined = CoreDataFactory.createFromStorageDto<Sample>(sampleDto);
            if (sample) {
                this.samples.push(sample);
            }
        }

        this.additionalInfo = dto?.additionalInfo;
    }

    public clone(): SamplingPlan {
        return CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
    }
}
