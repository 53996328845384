import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { environment } from "../../../../environments/environment";
import { DefaultService, PersonDto, RequestBodyInviteDto, ResponseBodyInviteDto } from "../../../generated/api";
import { AppException } from "../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../global/frontend-errors";
import { EntityHelper } from "../../helpers/entity-helper";
import { PersonBusinessIdentifier } from "../../identifiers/person-business-identifier";
import { PersonIdentifier } from "../../identifiers/person-identifier";
import { SessionService } from "../session/session.service";

/**
 * The service to talk to the server backend regarding persons.
 */
@Injectable({
    providedIn: "root"
})
export class PersonsApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public async getMe(): Promise<PersonDto|undefined>|never {
        this.sessionService.requiresAccount();
        const result: Array<PersonDto> = await lastValueFrom(this.defaultService.listPersons(this.sessionService.activeAccountId, undefined, undefined, undefined, undefined, true));
        return result && result.length > 0 ? EntityHelper.getLatestByCreatedAndUpdatedTime(result) : undefined;
    }

    public listPersons(): Promise<Array<PersonDto>>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listPersons(this.sessionService.activeAccountId));
    }

    public listAuditors(): Promise<Array<PersonDto>>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listPersons(this.sessionService.activeAccountId, undefined, undefined, undefined, undefined, undefined, true));
    }

    public getPersons(searchQuery?: string, limit: number = environment.searchDefaultResultsCount): Promise<Array<PersonDto>>|never {
        this.sessionService.requiresAccount();

        return lastValueFrom(this.defaultService.listPersons(this.sessionService.activeAccountId, undefined, undefined, searchQuery, limit));
    }

    public async getPerson(personIdentifier: PersonIdentifier): Promise<PersonDto|undefined>|never {
        this.sessionService.requiresAccount();

        const persons: Array<PersonDto> = await lastValueFrom(this.defaultService.listPersons(this.sessionService.activeAccountId, personIdentifier.businessIdentifier || undefined, personIdentifier.technicalIdentifier || undefined));
        if (personIdentifier.technicalIdentifier) {
            return persons.find((dto: PersonDto) => dto.technicalId == personIdentifier.technicalIdentifier);
        } else {
            return EntityHelper.getLatestByCreatedAndUpdatedTime(persons);
        }
    }

    public getPersonHistory(personIdentifier: PersonIdentifier): Promise<Array<PersonDto>>|never {
        this.sessionService.requiresAccount();

        return lastValueFrom(this.defaultService.listPersons(this.sessionService.activeAccountId, personIdentifier.businessIdentifier || undefined, personIdentifier.technicalIdentifier || undefined));
    }

    public invitePerson(requestBodyInviteDto: RequestBodyInviteDto): Promise<ResponseBodyInviteDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.invitePerson(requestBodyInviteDto, this.sessionService.activeAccountId));
    }

    public createPerson(personDto: PersonDto): Promise<PersonDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.createPerson(personDto, this.sessionService.activeAccountId));
    }

    public updatePerson(personBusinessId: PersonBusinessIdentifier, personDto: PersonDto, archive: boolean): Promise<PersonDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.updatePerson(personBusinessId, this.sessionService.activeAccountId, archive, personDto));
    }

    public async deletePerson(businessIdentifier: PersonBusinessIdentifier): Promise<void>|never {
        this.sessionService.requiresAccount();

        if (businessIdentifier == this.sessionService.activePersonBusinessId) {
            throw new AppException(FrontendErrors.FE64UnableToDeleteMyself, $localize`:@@exception.fe64UnableToDeleteMyself:You cannot delete yourself.`);
        }

        await lastValueFrom(this.defaultService.deletePerson(businessIdentifier, this.sessionService.activeAccountId));
    }
}
