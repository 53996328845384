import { Injectable } from "@angular/core";

import { ShortcutListener } from "./shortcut-listener";

/**
 * Handler service for keyboard events.
 */
@Injectable({
    providedIn: "root"
})
export class KeyboardService {
    private shortcutCounter: number = 0;

    private shortcutListeners: Array<ShortcutListener> = [];

    public keyDown(event: KeyboardEvent): void {
        if (!event.ctrlKey) {
            // It is no a shortcut
            return;
        }

        for (const shortcutListener of this.shortcutListeners) {
            shortcutListener.delegate(event);
        }
    }

    public registerListener(delegate: (event: KeyboardEvent) => void): ShortcutListener {
        this.shortcutCounter++;
        const listener: ShortcutListener = new ShortcutListener(this.shortcutCounter, delegate, this.unregisterListener.bind(this));
        this.shortcutListeners.push(listener);
        return listener;
    }

    public unregisterListener(listener: ShortcutListener): void {
        this.shortcutListeners = this.shortcutListeners.filter((item: ShortcutListener) => item.index != listener.index);
    }
}
