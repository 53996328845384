import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { environment } from "../../../../../environments/environment";
import { BaseComponent } from "../../../../base/components/base-component";
import { ArrayHelper } from "../../../../base/helpers/array-helper";
import { DateTimeHelper } from "../../../../base/helpers/date-time-helper";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { ExtendedValidators } from "../../../../base/validators/extended-validators";
import { Address } from "../../../entities/core-data/address";
import { Building } from "../../../entities/core-data/buildings/building";
import { BuildingUsageTypes } from "../../../entities/core-data/buildings/building-usage-types";
import { DrinkingWaterHeatingTypes } from "../../../entities/core-data/buildings/drinking-water-heating-types";
import { Identifier } from "../../../identifiers/identifier";
import { I18nTextService } from "../../../services/i18n/i18n-text.service";
import { AppIcons } from "../../../services/icons/app-icons";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { FormValidationErrorMessages } from "../form-validation-error/form-validation-error-messages";

/**
 * Component to view and edit a building.
 */
@Component({
    selector: "business-building-form",
    templateUrl: "./building-form.component.html",
    styleUrls: ["./building-form.component.scss"]
})
export class BuildingFormComponent extends BaseComponent {
    constructor(
        private readonly i18nTextService: I18nTextService,
        private readonly formBuilder: FormBuilder
    ) {
        super();

        for (const enumKey of TypeScriptHelper.getEnumKeys(BuildingUsageTypes)) {
            this.usageTypeLabels.push({
                id: enumKey as string,
                label: this.i18nTextService.getBuildingUsageTypeText(enumKey as BuildingUsageTypes)
            });
        }
        for (const enumKey of TypeScriptHelper.getEnumKeys(DrinkingWaterHeatingTypes)) {
            this.heatingTypeLabels.push({
                id: enumKey as string,
                label: this.i18nTextService.getBuildingDrinkingWaterHeatingTypeText(enumKey as DrinkingWaterHeatingTypes)
            });
        }
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly formHelper: typeof FormHelper = FormHelper;

    public readonly formSettings: { maxLengthName: number; maxLengthAdditionalInfo: number; maxLengthBuildingUsage: number; maxLengthLocationHouseConnection: number; maxLengthDrinkingWaterHeatingLocation: number; maxLengthUsageDescription: number; maxLengthAscendingPipesEnds: number } = environment.formSettings.building;

    private buildingField!: Building;

    @Input()
    public readonly: boolean = false;

    @Input()
    public isValid: boolean = false;

    @Output()
    public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public externalReferenceEditable: boolean = false;

    public usageTypeLabels: Array<{ id: string; label: string }> = [];

    public heatingTypeLabels: Array<{ id: string; label: string }> = [];

    private featureTableValid: boolean = true;

    private initialized: boolean = false;

    public addressIndex: number = 0;

    public validationMessages: FormValidationErrorMessages = new FormValidationErrorMessages(
        [
            {
                controlName: "name", messages: [
                    {
                        type: "required",
                        message: $localize`:@@building.validation.nameRequired:The name is required.` as string
                    }
                ]
            }
        ]
    );

    public nameControl: FormControl<string|null> = new FormControl<string|null>("");

    public externalReferenceControl: FormControl<string|null> = new FormControl<string|null>("");

    public buildingUsageControl: FormControl<BuildingUsageTypes|null> = new FormControl<BuildingUsageTypes|null>(BuildingUsageTypes.unknown);

    public publicUsageControl: FormControl<boolean|null> = new FormControl<boolean|null>(false);

    public usageDescriptionControl: FormControl<string|null> = new FormControl<string|null>("");

    public constructionDateControl: FormControl<string|null> = new FormControl<string|null>("", [ExtendedValidators.yearValidator]);

    public numberOfFlatsControl: FormControl<number|null> = new FormControl<number|null>(null, [Validators.min(0)]);

    public numberOfOfficesControl: FormControl<number|null> = new FormControl<number|null>(null, [Validators.min(0)]);

    public numberOfUnusedFlatsAndOfficesControl: FormControl<number|null> = new FormControl<number|null>(null, [Validators.min(0)]);

    public visibleAscendingPipesControl: FormControl<number|null> = new FormControl<number|null>(null, [Validators.min(0)]);

    public ascendingPipesEndsControl: FormControl<string|null> = new FormControl<string|null>("", []);

    public installationPlansExistControl: FormControl<boolean|null> = new FormControl<boolean|null>(false);

    public typeOfDrinkingWaterHeatingControl: FormControl<string|null> = new FormControl<string|null>("", []);

    public drinkingWaterHeatingLocationControl: FormControl<string|null> = new FormControl<string|null>("", []);

    public locationHouseConnectionControl: FormControl<string|null> = new FormControl<string|null>("", []);

    public additionalInfoControl: FormControl<string|null> = new FormControl<string|null>("", []);

    public selectedAddressControl: FormControl<string|null> = new FormControl<string|null>("0", []);

    public formData: FormGroup = this.formBuilder.group([
        this.nameControl,
        this.externalReferenceControl,
        this.buildingUsageControl,
        this.publicUsageControl,
        this.usageDescriptionControl,
        this.constructionDateControl,
        this.numberOfFlatsControl,
        this.numberOfOfficesControl,
        this.numberOfUnusedFlatsAndOfficesControl,
        this.visibleAscendingPipesControl,
        this.ascendingPipesEndsControl,
        this.installationPlansExistControl,
        this.typeOfDrinkingWaterHeatingControl,
        this.drinkingWaterHeatingLocationControl,
        this.locationHouseConnectionControl,
        this.additionalInfoControl,

        this.selectedAddressControl
    ]);

    public get building(): Building {
        return this.buildingField;
    }

    @Input()
    public set building(value: Building) {
        const buildingChanged: boolean = this.buildingField != value || !Identifier.isEqual(this.buildingField?.identifier, value?.identifier);
        this.buildingField = value;
        if (buildingChanged && this.initialized) {
            this.initialize();
        }
    }

    protected componentInit(): void {
        this.initialize();
        this.subscribe(this.formData.statusChanges, this.formChanged);
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public getErrorMessage(formControl: FormControl): string|void {
        switch (true) {
            case formControl == this.constructionDateControl && formControl.hasError("year"):
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                return $localize`:@@building.validation.yearFormat:The year is not in the correct format. Use four digits (e.g., ${DateTimeHelper.utcNow().substring(0, 4)})` as string;
        }
    }

    private initialize(): void {
        this.initialized = false;
        this.building = this.building ?? CoreDataFactory.createBuilding();
        if (this.building.addresses.length <= 0) {
            this.building.addresses.push(CoreDataFactory.createAddress());
        }
        this.assignValues();
        this.externalReferenceEditable = !this.building.externalReference;

        this.selectedAddressControl.setValue("0");
        this.addressIndex = 0;

        this.initialized = true;
    }

    public assignValues(): void {
        FormHelper.assignIfChanged(this.externalReferenceControl, this.building.externalReference);
        FormHelper.assignIfChanged(this.nameControl, this.building.name);
        FormHelper.assignIfChanged(this.buildingUsageControl, this.building.buildingUsage || BuildingUsageTypes.unknown);
        FormHelper.assignIfChanged(this.publicUsageControl, this.building.publicUsage);
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        FormHelper.assignIfChanged(this.constructionDateControl, this.building.constructionDate && this.building.constructionDate.length >= 4 ? this.building.constructionDate.substring(0, 4) : undefined);
        FormHelper.assignIfChanged(this.usageDescriptionControl, this.building.usageDescription);
        FormHelper.assignIfChanged(this.numberOfFlatsControl, this.building.numberOfFlats);
        FormHelper.assignIfChanged(this.numberOfOfficesControl, this.building.numberOfOffices);
        FormHelper.assignIfChanged(this.numberOfUnusedFlatsAndOfficesControl, this.building.numberOfUnusedFlatsAndOffices);
        FormHelper.assignIfChanged(this.visibleAscendingPipesControl, this.building.visibleAscendingPipes);
        FormHelper.assignIfChanged(this.ascendingPipesEndsControl, this.building.ascendingPipesEnds);
        FormHelper.assignIfChanged(this.installationPlansExistControl, this.building.installationPlansExist);
        FormHelper.assignIfChanged(this.typeOfDrinkingWaterHeatingControl, this.building.drinkingWaterHeatingType);
        FormHelper.assignIfChanged(this.drinkingWaterHeatingLocationControl, this.building.drinkingWaterHeatingLocation);
        FormHelper.assignIfChanged(this.locationHouseConnectionControl, this.building.locationHouseConnection);
        FormHelper.assignIfChanged(this.additionalInfoControl, this.building.additionalInfo);

        TypeScriptHelper.ignoreResult(this.readonly ? this.buildingUsageControl.disable() : this.buildingUsageControl.enable());
    }

    public addressChanged(): void {
        this.updateValidState();
    }

    public updateValidState(): void {
        this.formData.markAllAsTouched();

        this.isValid = this.formData.status == "VALID" && this.featureTableValid;
        this.isValidChange.emit(this.isValid);
    }

    private addNewAddress(): void {
        const addressInit: Partial<Address> = {};
        if (this.building.addresses.length > 0) {
            const activeAddress: Address = this.building.addresses[this.addressIndex];
            addressInit.country = activeAddress.country;
            addressInit.province = activeAddress.province;
            addressInit.city = activeAddress.city;
            addressInit.zipCode = activeAddress.zipCode;
            addressInit.street = activeAddress.street;
        }
        this.building.addresses.push(CoreDataFactory.createAddress(addressInit));
        this.addressIndex = this.building.addresses.length - 1;
        this.selectedAddressControl.setValue(`${this.addressIndex}`);
    }

    private formChanged(): void { // NOSONAR
        if (!this.initialized) {
            return;
        }

        if (this.selectedAddressControl.value == "+") {
            this.addNewAddress();
            return;
        } else if (this.selectedAddressControl.value && this.selectedAddressControl.value != `${this.addressIndex}`) {
            this.addressIndex = parseInt(this.selectedAddressControl.value, 10);
            return;
        }

        this.building.externalReference = this.formHelper.formValueToData<string>(this.externalReferenceControl.value) || undefined;
        this.building.name = this.formHelper.formValueToData<string>(this.nameControl.value) || undefined;
        this.building.buildingUsage = this.buildingUsageControl.value as BuildingUsageTypes || undefined;
        this.building.publicUsage = !!this.publicUsageControl.value;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.building.constructionDate = this.constructionDateControl.value && `${this.constructionDateControl.value}`.length == 4 ? `${this.constructionDateControl.value}-05-15T00:00:00.000Z` : undefined;
        this.building.usageDescription = this.formHelper.formValueToData<string>(this.usageDescriptionControl.value) || undefined;
        this.building.numberOfFlats = this.numberOfFlatsControl.value as number || undefined;
        this.building.numberOfOffices = this.numberOfOfficesControl.value as number || undefined;
        this.building.numberOfUnusedFlatsAndOffices = this.numberOfUnusedFlatsAndOfficesControl.value as number || undefined;
        this.building.visibleAscendingPipes = this.visibleAscendingPipesControl.value as number || undefined;
        this.building.ascendingPipesEnds = this.formHelper.formValueToData<string>(this.ascendingPipesEndsControl.value) || undefined;
        this.building.installationPlansExist = !!this.installationPlansExistControl.value;
        this.building.drinkingWaterHeatingType = this.typeOfDrinkingWaterHeatingControl.value as DrinkingWaterHeatingTypes || undefined;
        this.building.drinkingWaterHeatingLocation = this.formHelper.formValueToData(this.drinkingWaterHeatingLocationControl.value) || undefined;
        this.building.locationHouseConnection = this.formHelper.formValueToData<string>(this.locationHouseConnectionControl.value) || undefined;
        this.building.additionalInfo = this.formHelper.formValueToData<string>(this.additionalInfoControl.value) || undefined;

        this.updateValidState();
    }

    public deleteAddress(index: number): void {
        ArrayHelper.removeElement(this.building.addresses, this.building.addresses[index]);
        this.updateValidState();
        if (this.addressIndex >= this.building.addresses.length) {
            this.addressIndex = this.building.addresses.length - 1;
            this.selectedAddressControl.setValue(`${this.addressIndex}`);
        }
    }
}
