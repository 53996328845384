<ion-button (click)="showDetails($event)" aria-label="Synchronization status" i18n-aria-label="@@cloudStatus.cloudStatusButton">
    <fa-icon *ngIf="!onlineStateAlreadyChecked" [icon]="appIcons.genericLoading" [spin]="true"></fa-icon>
    <ng-container *ngIf="onlineStateAlreadyChecked">
        <fa-icon *ngIf="!online" [icon]="appIcons.offline"></fa-icon>
        <fa-icon *ngIf="online && !synchronizationSummary.pending && !synchronizationSummary.error && !uploadQueuePendingItems" [icon]="appIcons.synchronized"></fa-icon>
        <fa-icon *ngIf="online && (synchronizationSummary.pending || uploadQueuePendingItems)" [icon]="appIcons.synchronizing"></fa-icon>
        <fa-icon *ngIf="online && synchronizationSummary.error && !synchronizationSummary.pending && !uploadQueuePendingItems" [icon]="appIcons.synchronizationError"></fa-icon>
    </ng-container>
    <ng-container *ngIf="synchronizationSummary.pending || synchronizationSummary.error || uploadQueuePendingItems">
        &nbsp;{{synchronizationSummary.pending + synchronizationSummary.error + uploadQueuePendingItems}}
    </ng-container>
    <ng-container *ngIf="synchronizationSummary.error">
        <fa-icon [icon]="appIcons.genericWarning"></fa-icon>
    </ng-container>
</ion-button>
