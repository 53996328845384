import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { AppException } from "../../entities/exceptions/app-exception";
import { BackendErrors } from "../../global/backend-errors";
import { FrontendErrors } from "../../global/frontend-errors";
import { BackendHelper } from "../../helpers/backend-helper";
import { AppIcons } from "../../services/icons/app-icons";

/**
 * Dialog component to show an error to the user.
 */
@Component({
    selector: "app-error-dialog",
    templateUrl: "./error-dialog.component.html",
    styleUrls: ["./error-dialog.component.scss"]
})
export class ErrorDialogComponent implements OnInit {
    constructor(
        private modalController: ModalController
    ) {
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    @Input()
    public error?: Error;

    public appException?: AppException;

    public permissionRequiredError: boolean = false;

    public showDetails: boolean = true;

    public ngOnInit(): void {
        if (BackendHelper.isBackendError(this.error, BackendErrors.BE19PermissionRequired)) {
            this.permissionRequiredError = true;
            this.showDetails = false;
        }

        if (this.error instanceof HttpErrorResponse) {
            this.appException = new AppException(FrontendErrors.FE41HttpError, this.error.message, this.error);
            this.error = this.appException;
        }

        if (this.error instanceof AppException) {
            this.appException = this.error;
        }
    }

    public close(): void {
        this.modalController.dismiss().then();
    }

    public contactSupport(): void {
        window.open("mailto:support@cyanaudit.com?subject=Support");
    }
}
