import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { environment } from "../../../../../environments/environment";
import { BaseComponent } from "../../../../base/components/base-component";
import { FocusableComponent } from "../../../../base/components/focusable-component";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { Address } from "../../../entities/core-data/address";
import { Identifier } from "../../../identifiers/identifier";
import { AppIcons } from "../../../services/icons/app-icons";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { FormValidationErrorMessages } from "../form-validation-error/form-validation-error-messages";

/**
 * Form component to edit addresses.
 */
@Component({
    selector: "business-address-form",
    templateUrl: "./address-form.component.html",
    styleUrls: ["./address-form.component.scss"]
})
export class AddressFormComponent extends BaseComponent implements FocusableComponent {
    constructor(
        private readonly formBuilder: FormBuilder
    ) {
        super();
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly formHelper: typeof FormHelper = FormHelper;

    public readonly formSettings: {
        maxLengthStreet: number;
        maxLengthStreetNumber: number;
        maxLengthZipCode: number;
        maxLengthCity: number;
        maxLengthProvince: number;
        maxLengthAdditionalInfo: number;
    } = environment.formSettings.address;

    private addressField!: Address;

    @Input()
    public readonly: boolean = false;

    @Input()
    public isValid: boolean = false;

    @Input()
    public propertiesInclude: Array<keyof Address> = [];

    @Input()
    public propertiesExclude: Array<keyof Address> = [];

    @Input()
    public mandatoryProperties: Array<keyof Address> = [];

    @Input()
    public autocomplete?: Map<keyof Address, Array<string>>;

    @Output()
    public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild("firstInput")
    public firstInput?: ElementRef;

    public autocompleteStreet: Array<string> = [];

    public validationMessages: FormValidationErrorMessages = new FormValidationErrorMessages(
        []
    );

    public readonly streetControl: FormControl<string|null> = new FormControl<string|null>("");

    public readonly streetNumberControl: FormControl<string|null> = new FormControl<string|null>("");

    public readonly zipCodeControl: FormControl<string|null> = new FormControl<string|null>("");

    public readonly cityControl: FormControl<string|null> = new FormControl<string|null>("");

    public readonly countryControl: FormControl<string|null|undefined> = new FormControl<string|null|undefined>(""); // NOSONAR - Replace this union type with a type alias.

    public readonly provinceControl: FormControl<string|null> = new FormControl<string|null>("");

    public readonly additionalInformationControl: FormControl<string|null> = new FormControl<string|null>("");

    public formGroup: FormGroup = this.formBuilder.group([
        this.streetControl,
        this.streetNumberControl,
        this.zipCodeControl,
        this.cityControl,
        this.countryControl,
        this.provinceControl,
        this.additionalInformationControl
    ]);

    private initialized: boolean = false;

    public get address(): Address {
        return this.addressField;
    }

    @Input()
    public set address(value: Address) {
        const addressChanged: boolean = value != this.addressField || !Identifier.isEqual(this.addressField?.identifier, value?.identifier) || Identifier.isEmpty(value?.identifier);
        this.addressField = value;
        if (addressChanged && this.initialized) {
            this.initialize();
        }
    }

    protected componentInit(): void {
        this.initialize();

        this.subscribe(this.formGroup.statusChanges, this.formChanged);
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public focus(): void {
        if (this.firstInput?.nativeElement && ("focus" in this.firstInput.nativeElement)) {
            this.firstInput.nativeElement.focus();
        }
    }

    private initialize(): void {
        this.initialized = false;

        this.setRestrictions();

        this.address = this.address ?? CoreDataFactory.createAddress();
        this.assignValues();

        this.initialized = true;
    }

    private setRestrictions(): void {
        for (const mandatoryProperty of this.mandatoryProperties) {
            switch (mandatoryProperty) {
                case "street":
                    this.streetControl.addValidators(Validators.required);
                    break;
                case "streetNumber":
                    this.streetNumberControl.addValidators(Validators.required);
                    break;
                case "zipCode":
                    this.zipCodeControl.addValidators(Validators.required);
                    break;
                case "city":
                    this.cityControl.addValidators(Validators.required);
                    break;
                case "country":
                    this.countryControl.addValidators(Validators.required);
                    break;
                case "province":
                    this.provinceControl.addValidators(Validators.required);
                    break;
            }
        }
    }

    public assignValues(): void {
        this.autocompleteStreet = this.autocomplete?.get("street") ?? [];

        this.assignIfChanged(this.streetControl, this.address.street);
        this.assignIfChanged(this.streetNumberControl, this.address.streetNumber);
        this.assignIfChanged(this.zipCodeControl, this.address.zipCode);
        this.assignIfChanged(this.cityControl, this.address.city);
        this.assignIfChanged(this.countryControl, this.address.country);
        this.assignIfChanged(this.provinceControl, this.address.province);
        this.assignIfChanged(this.additionalInformationControl, this.address.additionalInfo);
    }

    private assignIfChanged(control: AbstractControl, value: any): void {
        if (control.value != value) {
            control.setValue(value);
        }
    }

    public updateValidState(): void {
        if (!this.initialized) {
            return;
        }

        this.formGroup.markAllAsTouched();

        this.isValid = this.formGroup.status == "VALID";
        this.isValidChange.emit(this.isValid);
    }

    private formChanged(): void {
        if (!this.initialized) {
            return;
        }

        this.address.street = FormHelper.formValueToData(this.streetControl.value) || undefined;
        this.address.streetNumber = FormHelper.formValueToData(this.streetNumberControl.value) || undefined;
        this.address.zipCode = FormHelper.formValueToData(this.zipCodeControl.value) || undefined;
        this.address.city = FormHelper.formValueToData(this.cityControl.value) || undefined;
        this.address.country = FormHelper.formValueToData(this.countryControl.value) || undefined;
        this.address.province = FormHelper.formValueToData(this.provinceControl.value) || undefined;
        this.address.additionalInfo = FormHelper.formValueToData(this.additionalInformationControl.value) || undefined;

        this.updateValidState();
    }

    public countryChanged(country: string|null|undefined): void {
        this.countryControl.setValue(country ?? undefined);
        this.updateValidState();
    }

    public showInput(propertyName: keyof Address): boolean {
        return !this.propertiesExclude.includes(propertyName) && (this.propertiesInclude.length <= 0 || this.propertiesInclude.includes(propertyName));
    }

    public getErrorMessage(formControl: FormControl): string|void {
        switch (true) {
            case formControl.hasError("required"):
                return $localize`:@@entity.validation.required:This field is mandatory.` as string;
        }
    }
}
