/**
 * Static helper class for arrays.
 */
export class ArrayHelper {
    public static arraysEqual(a?: Array<any|undefined|null>|null, b?: Array<any|undefined|null>|null): boolean {
        if (a === b) {
            return true;
        }
        if (!a || !b) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }

        for (let index: number = 0; index < a.length; ++index) {
            if (a[index] !== b[index]) {
                return false;
            }
        }
        return true;
    }

    public static createArrayAndStopAtFirstEmptyValue<TType>(...items: Array<TType|undefined|null>): Array<TType> {
        const result: Array<TType> = [];
        for (const item of items) {
            if (!item) {
                return result;
            }
            result.push(item);
        }
        return result;
    }

    public static removeEmpty<TType>(items: Array<TType|undefined|null>): Array<TType> {
        const result: Array<TType> = [];
        for (const item of items) {
            if (item) {
                result.push(item);
            }
        }
        return result;
    }

    public static removeElement<TType>(array: Array<TType>, itemToRemove: TType): TType|undefined {
        const index: number = array.indexOf(itemToRemove);
        if (index < 0) {
            return undefined;
        }
        const removeElements: Array<TType> = array.splice(index, 1);
        return removeElements.length > 0 ? removeElements[0] : undefined;
    }

    public static sortByUpdatedAndCreated<TItem extends { updated?: string; created?: string }>(list: Array<TItem>, ascending: boolean = false): void {
        if (ascending) {
            list.sort((b: TItem, a: TItem) => (b.updated || b.created || "ZZZ").localeCompare(a.updated || a.created || "ZZZ"));
        } else {
            list.sort((a: TItem, b: TItem) => (b.updated || b.created || "ZZZ").localeCompare(a.updated || a.created || "ZZZ"));
        }
    }

    public static sortByCreated<TItem extends { created?: string; createdClient?: string }>(list: Array<TItem>, ascending: boolean = false): void {
        if (ascending) {
            list.sort((a: TItem, b: TItem) => (a.created || a.createdClient || "ZZZ").localeCompare(b.created || b.createdClient || "ZZZ"));
        } else {
            list.sort((b: TItem, a: TItem) => (a.created || a.createdClient || "ZZZ").localeCompare(b.created || b.createdClient || "ZZZ"));
        }
    }

    public static groupBy<TItem, TKeyType = any>(list: Array<TItem>, groupBy: keyof TItem): Map<TKeyType, Array<TItem>> {
        return list.reduce(
            (entryMap: Map<TKeyType, Array<TItem>>, item: any) => entryMap.set(item[groupBy], [...entryMap.get(item[groupBy]) || [], item]),
            new Map<TKeyType, Array<TItem>>()
        );
    }

    public static shuffleArrayNonSecure<TElement>(array: Array<TElement>): void {
        for (let i: number = array.length - 1; i > 0; i--) {
            const j: number = Math.floor(Math.random() * (i + 1)); // NOSONAR
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    public static compareNumeric(a: number|undefined, b: number|undefined): number {
        return ((a ?? 0) - (b ?? 0));
    }

    public static removeDuplicates<TType>(arr: Array<TType>): Array<TType> {
        const uniqueValues: Set<TType> = new Set<TType>();

        for (const elem of arr) {
            uniqueValues.add(elem);
        }

        return Array.from(uniqueValues);
    }
}
