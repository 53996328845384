import { Api1Entity } from "../../../api/v1/api1-entity";
import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { ProcessDto, SampleDto } from "../../../generated/api";
import { Address } from "../../entities/core-data/address";
import { Building } from "../../entities/core-data/buildings/building";
import { BuildingComplex } from "../../entities/core-data/buildings/building-complex";
import { Floor } from "../../entities/core-data/buildings/floor";
import { Room } from "../../entities/core-data/buildings/room";
import { RoomFeature } from "../../entities/core-data/buildings/room-feature";
import { RoomFeatureMapping } from "../../entities/core-data/buildings/room-feature-mapping";
import { Company } from "../../entities/core-data/company";
import { CoreDataEntityStorageDto } from "../../entities/core-data/core-data-entity-storage-dto";
import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { Person } from "../../entities/core-data/person";
import { DashboardProcesses } from "../../entities/dashboards/dashboard-processes";
import { EntityLink } from "../../entities/documents/entity-link";
import { EntityLinkStorageDto } from "../../entities/documents/entity-link-storage-dto";
import { EntityTypes } from "../../entities/entity-types";
import { Process } from "../../entities/projects-processes/process";
import { Project } from "../../entities/projects-processes/project";
import { Sample } from "../../entities/samplings/sample";
import { SampleMerge } from "../../entities/samplings/sample-merge";
import { SamplingPlan } from "../../entities/samplings/sampling-plan";
import { SamplingPlanMerge } from "../../entities/samplings/sampling-plan-merge";
import { EntityIdentifier } from "../../identifiers/entity-identifier";
import { Identifier } from "../../identifiers/identifier";

/**
 * Factory for core data entities.
 */
export class CoreDataFactory {
    public static createFromStorageDto<TEntity extends GenericCoreDataEntity>(dto: CoreDataEntityStorageDto): TEntity|undefined {
        switch (dto.entityType) {
            case EntityTypes.project:
                const project: Project = this.createProject();
                project.fromStorageDto(dto);
                return project as GenericCoreDataEntity as TEntity;
            case EntityTypes.process:
                const process: Process = this.createProcess();
                process.fromStorageDto(dto);
                return process as GenericCoreDataEntity as TEntity;
            case EntityTypes.person:
                const person: Person = this.createPerson();
                person.fromStorageDto(dto);
                return person as GenericCoreDataEntity as TEntity;
            case EntityTypes.buildingComplex:
                const buildingComplex: BuildingComplex = this.createBuildingComplex();
                buildingComplex.fromStorageDto(dto);
                return buildingComplex as GenericCoreDataEntity as TEntity;
            case EntityTypes.building:
                const building: Building = this.createBuilding();
                building.fromStorageDto(dto);
                return building as GenericCoreDataEntity as TEntity;
            case EntityTypes.company:
                const company: Company = this.createCompany();
                company.fromStorageDto(dto);
                return company as GenericCoreDataEntity as TEntity;
            case EntityTypes.address:
                const address: Address = this.createAddress();
                address.fromStorageDto(dto);
                return address as GenericCoreDataEntity as TEntity;
            case EntityTypes.sample:
                const sample: Sample = this.createSample();
                sample.fromStorageDto(dto);
                return sample as GenericCoreDataEntity as TEntity;
            case EntityTypes.sampleMerge:
                const sampleMerge: SampleMerge = this.createSampleMerge();
                sampleMerge.fromStorageDto(dto);
                return sampleMerge as GenericCoreDataEntity as TEntity;
            case EntityTypes.samplingPlan:
                const samplingPlan: SamplingPlan = this.createSamplingPlan();
                samplingPlan.fromStorageDto(dto);
                return samplingPlan as GenericCoreDataEntity as TEntity;
            case EntityTypes.samplingPlanMerge:
                const samplingPlanMerge: SamplingPlanMerge = this.createSamplingPlanMerge();
                samplingPlanMerge.fromStorageDto(dto);
                return samplingPlanMerge as GenericCoreDataEntity as TEntity;
            case EntityTypes.dashboardProcesses:
                const dashboardProcesses: DashboardProcesses = this.createDashboardProcesses();
                dashboardProcesses.fromStorageDto(dto);
                return dashboardProcesses as GenericCoreDataEntity as TEntity;
            case EntityTypes.roomFeature:
                const roomFeature: RoomFeature = this.createRoomFeature();
                roomFeature.fromStorageDto(dto);
                return roomFeature as GenericCoreDataEntity as TEntity;
        }
        return undefined;
    }

    public static createFromServerDto<TEntity extends GenericCoreDataEntity>(dto: Api1Entity, entityType: EntityTypes): TEntity|undefined {
        switch (entityType) {
            case EntityTypes.project:
                return Api1Converter.dtoToProject(dto) as GenericCoreDataEntity as TEntity;
            case EntityTypes.process:
                return Api1Converter.dtoToProcess(dto as ProcessDto) as GenericCoreDataEntity as TEntity;
            case EntityTypes.person:
                return Api1Converter.dtoToPerson(dto) as GenericCoreDataEntity as TEntity;
            case EntityTypes.buildingComplex:
                return Api1Converter.dtoToBuildingComplex(dto) as GenericCoreDataEntity as TEntity;
            case EntityTypes.building:
                return Api1Converter.dtoToBuilding(dto) as GenericCoreDataEntity as TEntity;
            case EntityTypes.company:
                return Api1Converter.dtoToCompany(dto) as GenericCoreDataEntity as TEntity;
            case EntityTypes.address:
                return Api1Converter.dtoToAddress(dto) as GenericCoreDataEntity as TEntity;
            case EntityTypes.samplingPlan:
                return Api1Converter.dtoToSamplingPlan(dto) as GenericCoreDataEntity as TEntity;
            case EntityTypes.sample:
                return Api1Converter.dtoToSample(dto as SampleDto) as GenericCoreDataEntity as TEntity;
        }
        return undefined;
    }

    public static createFromEntityType<TEntity extends GenericCoreDataEntity>(entityType: EntityTypes, init?: Partial<GenericCoreDataEntity>): TEntity|undefined {
        switch (entityType) {
            case EntityTypes.project:
                return this.createProject(init as Partial<Project>) as GenericCoreDataEntity as TEntity;
            case EntityTypes.process:
                return this.createProcess(init as Partial<Process>) as GenericCoreDataEntity as TEntity;
            case EntityTypes.person:
                return this.createPerson(init as Partial<Person>) as GenericCoreDataEntity as TEntity;
            case EntityTypes.buildingComplex:
                return this.createBuildingComplex(init as Partial<BuildingComplex>) as GenericCoreDataEntity as TEntity;
            case EntityTypes.building:
                return this.createBuilding(init as Partial<Building>) as GenericCoreDataEntity as TEntity;
            case EntityTypes.company:
                return this.createCompany(init as Partial<Company>) as GenericCoreDataEntity as TEntity;
            case EntityTypes.address:
                return this.createAddress(init as Partial<Address>) as GenericCoreDataEntity as TEntity;
            case EntityTypes.samplingPlan:
                return this.createSamplingPlan(init as Partial<SamplingPlan>) as GenericCoreDataEntity as TEntity;
            case EntityTypes.sample:
                return this.createSample(init as Partial<Sample>) as GenericCoreDataEntity as TEntity;
        }
        return undefined;
    }

    public static createEntityLink<TEntity extends GenericCoreDataEntity>(identifier: EntityIdentifier, entityType: EntityTypes, linkDescription?: string, init?: Partial<EntityLink<TEntity>>): EntityLink<TEntity> {
        const entity: TEntity|undefined = init?.entity;
        const entityLink: EntityLink<TEntity> = new EntityLink<TEntity>(identifier, entityType, entity, init);
        entityLink.linkDescription = linkDescription;
        return entityLink;
    }

    public static createEntityLinkFromEntity<TEntity extends GenericCoreDataEntity>(entity: TEntity, linkDescription?: string): EntityLink<TEntity> {
        const entityLink: EntityLink<TEntity> = new EntityLink<TEntity>(entity.identifier, entity.entityType, entity as TEntity);
        entityLink.linkDescription = linkDescription;
        return entityLink;
    }

    public static createEntityLinkFromStorageDto<TEntity extends GenericCoreDataEntity>(entityLinkDto: EntityLinkStorageDto): EntityLink<TEntity>|undefined {
        const entityLink: EntityLink<GenericCoreDataEntity> = new EntityLink<TEntity>(Identifier.create(entityLinkDto.entityBusinessId, entityLinkDto.entityTechnicalId), TypeScriptHelper.dtoToEnum(EntityTypes, entityLinkDto.entityType, EntityTypes.unknown) as EntityTypes, undefined, { identifierPairPropertyName: entityLinkDto.identifierPairPropertyName });
        entityLink.fromStorageDto(entityLinkDto);

        return entityLink as TEntity;
    }

    public static createProject(init?: Partial<Project>): Project {
        return new Project(init);
    }

    public static createProcess(init?: Partial<Process>): Process {
        return new Process(init);
    }

    public static createPerson(init?: Partial<Person>): Person {
        const person: Person = new Person(init);
        person.address = person.address ?? this.createAddress();
        return person;
    }

    public static createBuildingComplex(init?: Partial<BuildingComplex>): BuildingComplex {
        const buildingComplex: BuildingComplex = new BuildingComplex(init);
        buildingComplex.address = buildingComplex.address ?? this.createAddress();
        return buildingComplex;
    }

    public static createBuilding(init?: Partial<Building>): Building {
        return new Building(init);
    }

    public static createCompany(init?: Partial<Company>): Company {
        const company: Company = new Company(init);
        company.address = company.address ?? this.createAddress();
        return company;
    }

    public static createAddress(init?: Partial<Address>): Address {
        const address: Address = new Address(init);
        address.country = "DE";
        return address;
    }

    public static createSample(init?: Partial<Sample>): Sample {
        return new Sample(init);
    }

    public static createSampleMerge(init?: Partial<SampleMerge>): SampleMerge {
        return new SampleMerge(init);
    }

    public static createSamplingPlan(init?: Partial<SamplingPlan>): SamplingPlan {
        return new SamplingPlan(init);
    }

    public static createSamplingPlanMerge(init?: Partial<SamplingPlanMerge>): SamplingPlanMerge {
        return new SamplingPlanMerge(init);
    }

    public static createDashboardProcesses(init?: Partial<DashboardProcesses>): DashboardProcesses {
        return new DashboardProcesses(init);
    }

    public static createFloor(init?: Partial<Floor>): Floor {
        return new Floor(init);
    }

    public static createRoom(init?: Partial<Room>): Room {
        return new Room(init);
    }

    public static createRoomFeatureMapping(init?: Partial<RoomFeatureMapping>): RoomFeatureMapping {
        return new RoomFeatureMapping(init);
    }

    public static createRoomFeature(init?: Partial<RoomFeature>): RoomFeature {
        return new RoomFeature(init);
    }
}
