import { IntentsService } from "../../../base/services/intents/intents.service";
import { IocService } from "../../../base/services/ioc/ioc.service";
import { Person } from "../../entities/core-data/person";
import { LoggedInIntent } from "../abstract/logged-in-intent";
import { EditCoreDataIntent } from "./edit-core-data-intent";
import { EditCoreDataIntentOptions } from "./edit-core-data-intent-options";

/**
 * Intent to edit own person data.
 */
export class EditOwnPersonIntent extends LoggedInIntent<EditOwnPersonIntent> {
    constructor(iocService: IocService) {
        super(iocService);
    }

    private readonly intentsService: IntentsService = this.iocService.resolve(IntentsService);

    public async executeIntent(): Promise<boolean> {
        const me: Person|undefined = this.sessionService.activePerson;
        if (!me) {
            return true;
        }
        await this.intentsService.executeIntentAndWait(EditCoreDataIntent, new EditCoreDataIntentOptions(me));
        await this.sessionService.refreshActivePerson();

        return true;
    }

    public async cancel(): Promise<void> {
        // Do nothing
    }
}
