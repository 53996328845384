import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";

/**
 * Options for the {@link PrivacyPolicyComponent}.
 */
export class PrivacyPolicyComponentOptions {
    constructor(content: PrivacyPolicyContents, init?: Partial<PrivacyPolicyComponentOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.content = content;
    }

    public content: PrivacyPolicyContents;
}

export enum PrivacyPolicyContents {
    privacyPolicy = "privacyPolicy",
    firstContactPrivacyPolicy = "firstContactPrivacyPolicy"
}
