import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { EntityLinkStorageDto } from "../documents/entity-link-storage-dto";
import { AddressStorageDto } from "./address-storage-dto";
import { CoreDataEntityStorageDto } from "./core-data-entity-storage-dto";

/**
 * Storage DTO for {@link Company}.
 */
export class CompanyStorageDto extends CoreDataEntityStorageDto {
    constructor(init?: Partial<CompanyStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public name?: string;

    public email?: string;

    public address?: AddressStorageDto;

    public personLinks?: Array<EntityLinkStorageDto>;
}
