import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { EntityTypes } from "src/app/business/entities/entity-types";
import { Pages } from "src/app/business/pages/pages";

import { ArrayHelper } from "../../../../base/helpers/array-helper";
import { AttachmentTypes } from "../../../entities/attachments/attachment-types";
import { DocumentBusinessIdentifier } from "../../../identifiers/document-business-identifier";
import { EntityBusinessIdentifier } from "../../../identifiers/entity-business-identifier";
import { ProcessBusinessIdentifier } from "../../../identifiers/process-business-identifier";
import { ProjectBusinessIdentifier } from "../../../identifiers/project-business-identifier";
import { QuestionTemplateIdentifier } from "../../../identifiers/question-template-identifier";
import { SectionTemplateIdentifier } from "../../../identifiers/section-template-identifier";
import { NavigationService } from "../navigation.service";

/**
 * Special navigation service to navigate to and within documents.
 */
@Injectable({
    providedIn: "root"
})
export class DocumentNavigationService {
    constructor(
        private readonly navigationService: NavigationService
    ) {
    }

    private failedHighlightAttempts: Array<QuestionTemplateIdentifier> = [];

    private highlightQuestionObservables: Map<QuestionTemplateIdentifier, Subject<void>> = new Map();

    public getRouteToDocument(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier,
        chapterTemplateIdentifier?: SectionTemplateIdentifier,
        sectionTemplateIdentifier?: SectionTemplateIdentifier
    ): Array<string> {
        return ArrayHelper.createArrayAndStopAtFirstEmptyValue(`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`, `${documentBusinessIdentifier}`, `${EntityTypes.document}`, chapterTemplateIdentifier ? `${chapterTemplateIdentifier}` : undefined, sectionTemplateIdentifier ? `${sectionTemplateIdentifier}` : undefined);
    }

    public navigateToDocument(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier,
        chapterTemplateIdentifier?: SectionTemplateIdentifier,
        sectionTemplateIdentifier?: SectionTemplateIdentifier
    ): Promise<boolean> {
        const route: Array<string> = this.getRouteToDocument(projectBusinessIdentifier, processBusinessIdentifier, documentBusinessIdentifier, chapterTemplateIdentifier, sectionTemplateIdentifier);
        return this.navigationService.navigate(Pages.document, ...route);
    }

    public getRouteToDocumentEntity(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier,
        entityType: EntityTypes,
        propertyName: string|undefined,
        entityBusinessIdentifier?: EntityBusinessIdentifier,
        filter?: string
    ): Array<string> {
        return ArrayHelper.createArrayAndStopAtFirstEmptyValue(`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`, `${documentBusinessIdentifier}`, `${entityType}`, propertyName, entityBusinessIdentifier, filter);
    }

    public navigateToDocumentEntity(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier,
        entityType: EntityTypes,
        propertyName: string|undefined,
        entityBusinessIdentifier?: EntityBusinessIdentifier,
        filter?: string
    ): Promise<boolean> {
        const route: Array<string> = ArrayHelper.createArrayAndStopAtFirstEmptyValue(`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`, `${documentBusinessIdentifier}`, `${entityType}`, propertyName, entityBusinessIdentifier, filter);
        return this.navigationService.navigate(Pages.document, ...route);
    }

    public getRouteToCommonData(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier
    ): Array<string> {
        return ArrayHelper.createArrayAndStopAtFirstEmptyValue(`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`, `${documentBusinessIdentifier}`, `${EntityTypes.documentCommon}`);
    }

    public getRouteToAiAnalysis(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier
    ): Array<string> {
        return ArrayHelper.createArrayAndStopAtFirstEmptyValue(`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`, `${documentBusinessIdentifier}`, `${EntityTypes.aiAnalysis}`);
    }

    public getRouteToSamplingPlan(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier
    ): Array<string> {
        return ArrayHelper.createArrayAndStopAtFirstEmptyValue(`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`, `${documentBusinessIdentifier}`, `${EntityTypes.samplingPlan}`);
    }

    public getRouteToAttachments(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier,
        attachmentType: AttachmentTypes
    ): Array<string> {
        return ArrayHelper.createArrayAndStopAtFirstEmptyValue(`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`, `${documentBusinessIdentifier}`, `${EntityTypes.attachments}`, `${attachmentType}`);
    }

    public getRouteToRevisions(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier
    ): Array<string> {
        return ArrayHelper.createArrayAndStopAtFirstEmptyValue(`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`, `${documentBusinessIdentifier}`, `${EntityTypes.revisions}`);
    }

    public getRouteToReview(
        projectBusinessIdentifier: ProjectBusinessIdentifier,
        processBusinessIdentifier: ProcessBusinessIdentifier,
        documentBusinessIdentifier: DocumentBusinessIdentifier
    ): Array<string> {
        return ArrayHelper.createArrayAndStopAtFirstEmptyValue(`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`, `${documentBusinessIdentifier}`, `${EntityTypes.review}`);
    }

    public highlightQuestion(questionTemplateIdentifier: QuestionTemplateIdentifier): void {
        const subscriber: Subject<void>|undefined = this.highlightQuestionObservables.get(questionTemplateIdentifier);

        if (subscriber) {
            subscriber.next();
        } else {
            this.failedHighlightAttempts.push(questionTemplateIdentifier);
        }
    }

    public unsubscribeForQuestionHighlight(questionTemplateIdentifier: QuestionTemplateIdentifier): void {
        const existingObservable: Subject<void>|undefined = this.highlightQuestionObservables.get(questionTemplateIdentifier);

        if (existingObservable) {
            existingObservable.unsubscribe();
            this.highlightQuestionObservables.delete(questionTemplateIdentifier);
        }
    }

    public subscribeForQuestionHighlight(questionTemplateIdentifier: QuestionTemplateIdentifier): Observable<void> {
        const existingObservable: Observable<void>|undefined = this.highlightQuestionObservables.get(questionTemplateIdentifier);

        if (existingObservable) {
            return existingObservable;
        }

        const observable: Subject<void> = new Subject<void>();

        this.highlightQuestionObservables.set(questionTemplateIdentifier, observable);

        if (this.failedHighlightAttempts.includes(questionTemplateIdentifier)) {
            setTimeout(() => {
                this.highlightQuestionObservables.get(questionTemplateIdentifier)?.next();
            });

            this.failedHighlightAttempts.splice(this.failedHighlightAttempts.indexOf(questionTemplateIdentifier), 1);
        }

        return observable;
    }
}
