import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { QuestionIdentifier } from "../../identifiers/question-identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { Attachment } from "../attachments/attachment";

/**
 * Event data for updated attachments.
 */
export class AttachmentsUpdatedEventArguments {
    constructor(init?: Partial<AttachmentsUpdatedEventArguments>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public questionTemplateIdentifier?: QuestionTemplateIdentifier;
    public questionIdentifier?: QuestionIdentifier;
    public attachments: Array<Attachment> = [];
}
