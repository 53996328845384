<ion-header class="modalHeader ion-no-border">
    <ion-toolbar>
        <ion-title>
            <span i18n="@@accountSelect.title">Accounts</span>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-label color="medium">
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="state != workingStates.none" class="modalContent ion-padding loading">
    <h2 *ngIf="state == workingStates.loading" i18n="@@accountSelect.loading">Loading...</h2>
    <h2 *ngIf="state == workingStates.saving" i18n="@@accountSelect.saving">Saving...</h2>
    <base-loading></base-loading>
</ion-content>

<ion-content *ngIf="state == workingStates.none" class="modalContent ion-padding">
    <ion-list *ngIf="!createMode">
        <p i18n="@@accountSelect.selectInfo" class="infoText">Please select your account.</p>

        <ion-item *ngFor="let account of accounts" class="selectableAccount" (click)="selectAccount(account)">
            <ion-avatar slot="start">
                <img alt="{{account.name}}" src="assets/logos/cyanaudit-signum.svg" />
            </ion-avatar>
            <ion-label>
                {{account.name}}
            </ion-label>
            <fa-icon [icon]="appIcons.genericHourglass" slot="end" *ngIf="account == switchingTo"></fa-icon>
            <fa-icon [icon]="appIcons.genericCheck" slot="end" *ngIf="account != switchingTo && selectedAccount && account.id == selectedAccount.id"></fa-icon>
        </ion-item>
        <ion-button (click)="enterCreateMode()" fill="clear" class="createAccountButton">
            <fa-icon [icon]="appIcons.genericPlus"></fa-icon>&nbsp;<ng-container i18n="@@accountSelect.createNewButton">Add a new account</ng-container>
        </ion-button>
    </ion-list>

    <ion-list *ngIf="createMode && editableCompany">
        <p i18n="@@accountSelect.createInfo" class="infoText">Please specify your company information below.</p>

        <business-company-form [company]="editableCompany" [allowEntityLinking]="false"></business-company-form>

        <ion-button (click)="createAccount()"><fa-icon [icon]="appIcons.genericPlus"></fa-icon>&nbsp;<ng-container i18n="@@accountSelect.createButton">Create</ng-container></ion-button>
    </ion-list>
</ion-content>

<ion-footer class="modalFooter ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <fa-icon [icon]="appIcons.genericCancel"></fa-icon>&nbsp;<ng-container i18n="@@accountSelect.cancel">Cancel</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
