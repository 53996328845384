import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { CoreDataEntityStorageDto } from "./core-data-entity-storage-dto";

/**
 * Storage DTO for {@link Address}.
 */
export class AddressStorageDto extends CoreDataEntityStorageDto {
    public readonly currentStorageVersion: number = 1;
    public storageVersion: number = this.currentStorageVersion;

    constructor(init?: Partial<AddressStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public street?: string;
    public streetNumber?: string;
    public zipCode?: string;
    public city?: string;
    public province?: string;
    public country?: string;
    public additionalInfo?: string;
}
