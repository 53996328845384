import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";

import { BuildingComplexDto, BuildingDto, DefaultService, RequestBodyCreateBuildingDto, RoomFeatureDto } from "../../../generated/api";
import { EntityHelper } from "../../helpers/entity-helper";
import { BuildingBusinessIdentifier } from "../../identifiers/building-business-identifier";
import { BuildingComplexBusinessIdentifier } from "../../identifiers/building-complex-business-identifier";
import { BuildingComplexIdentifier } from "../../identifiers/building-complex-identifier";
import { BuildingComplexTechnicalIdentifier } from "../../identifiers/building-complex-technical-identifier";
import { BuildingIdentifier } from "../../identifiers/building-identifier";
import { SessionService } from "../session/session.service";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class BuildingsApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public listBuildings(): Promise<Array<BuildingDto>>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listBuildings(this.sessionService.activeAccountId));
    }

    public createBuildingComplex(buildingComplexDto: BuildingComplexDto): Promise<BuildingComplexDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.createBuildingComplex(buildingComplexDto, this.sessionService.activeAccountId));
    }

    public updateBuildingComplex(buildingComplexBusinessId: BuildingComplexBusinessIdentifier, buildingComplexDto: BuildingComplexDto, archive: boolean): Promise<BuildingComplexDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.updateBuildingComplex(buildingComplexBusinessId, buildingComplexDto, this.sessionService.activeAccountId, archive));
    }

    public createBuilding(buildingComplexBusinessIdentifier: BuildingComplexBusinessIdentifier, buildingDto: BuildingDto): Promise<BuildingDto>|never {
        this.sessionService.requiresAccount();
        const requestDto: RequestBodyCreateBuildingDto = {
            buildingComplexBusinessId: buildingComplexBusinessIdentifier,
            building: buildingDto
        };
        return lastValueFrom(this.defaultService.createBuilding(requestDto, this.sessionService.activeAccountId));
    }

    public updateBuilding(buildingBusinessId: BuildingBusinessIdentifier, buildingDto: BuildingDto, archive: boolean): Promise<BuildingDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.updateBuilding(buildingBusinessId, buildingDto, this.sessionService.activeAccountId, archive));
    }

    public async getRoomFeatures(): Promise<Array<RoomFeatureDto>>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listRoomFeatures(this.sessionService.activeAccountId));
    }

    public getBuildingComplexes(searchQuery?: string, limit: number = environment.searchDefaultResultsCount): Promise<Array<BuildingComplexDto>>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listBuildingComplexes(this.sessionService.activeAccountId, undefined, undefined, searchQuery, limit));
    }

    public async getBuilding(buildingIdentifier: BuildingIdentifier, onlyLatest: boolean = false): Promise<BuildingDto|undefined>|never {
        this.sessionService.requiresAccount();

        const buildings: Array<BuildingDto> = await lastValueFrom(this.defaultService.listBuildings(this.sessionService.activeAccountId, buildingIdentifier.businessIdentifier || undefined, buildingIdentifier.technicalIdentifier || undefined, undefined, onlyLatest));
        if (buildingIdentifier.technicalIdentifier) {
            return buildings.find((dto: BuildingDto) => dto.technicalId == buildingIdentifier.technicalIdentifier);
        } else {
            return EntityHelper.getLatestByCreatedAndUpdatedTime(buildings);
        }
    }

    public getBuildings(buildingComplexTechnicalIdentifier: BuildingComplexTechnicalIdentifier): Promise<Array<BuildingDto>>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listBuildings(this.sessionService.activeAccountId, undefined, undefined, undefined, undefined, buildingComplexTechnicalIdentifier));
    }

    public async getBuildingComplex(buildingComplexIdentifier: BuildingComplexIdentifier): Promise<BuildingComplexDto|undefined>|never {
        this.sessionService.requiresAccount();

        const buildings: Array<BuildingComplexDto> = await lastValueFrom(this.defaultService.listBuildingComplexes(this.sessionService.activeAccountId, buildingComplexIdentifier.businessIdentifier || undefined, buildingComplexIdentifier.technicalIdentifier || undefined));
        if (buildingComplexIdentifier.technicalIdentifier) {
            return buildings.find((dto: BuildingComplexDto) => dto.technicalId == buildingComplexIdentifier.technicalIdentifier);
        } else {
            return EntityHelper.getLatestByCreatedAndUpdatedTime(buildings);
        }
    }

    public async deleteBuilding(businessIdentifier: BuildingBusinessIdentifier): Promise<void>|never {
        this.sessionService.requiresAccount();
        await lastValueFrom(this.defaultService.deleteBuildingByBusinessId(businessIdentifier, this.sessionService.activeAccountId));
    }

    public async deleteBuildingComplex(businessIdentifier: BuildingComplexBusinessIdentifier): Promise<void>|never {
        this.sessionService.requiresAccount();
        await lastValueFrom(this.defaultService.deleteBuildingComplexByBusinessId(businessIdentifier, this.sessionService.activeAccountId));
    }
}
