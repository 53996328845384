import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DateTime } from "luxon";

import { BaseComponent } from "../../../../base/components/base-component";
import { DateTimeHelper } from "../../../../base/helpers/date-time-helper";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { IntentsService } from "../../../../base/services/intents/intents.service";
import { LoadStrategies } from "../../../common/load-strategies";
import { BuildingComplex } from "../../../entities/core-data/buildings/building-complex";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { Person } from "../../../entities/core-data/person";
import { EntityLink } from "../../../entities/documents/entity-link";
import { EntityTypes } from "../../../entities/entity-types";
import { Process } from "../../../entities/projects-processes/process";
import { ProcessTypes } from "../../../entities/projects-processes/process-types";
import { Identifier } from "../../../identifiers/identifier";
import { PersonBusinessIdentifier } from "../../../identifiers/person-business-identifier";
import { PersonIdentifier } from "../../../identifiers/person-identifier";
import { SelectEntityIntent } from "../../../intents/master-data/select-entity-intent";
import { SelectEntityIntentOptions } from "../../../intents/master-data/select-entity-intent-options";
import { I18nTextService } from "../../../services/i18n/i18n-text.service";
import { AppIcons } from "../../../services/icons/app-icons";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { PersonsService } from "../../../services/master-data/persons/persons.service";
import { EntitySelectionListComponent } from "../../core-data/entity-selection-list/entity-selection-list.component";
import { FormValidationErrorMessages } from "../form-validation-error/form-validation-error-messages";

/**
 * Form to create and edit processes.
 */
@Component({
    selector: "business-process-form",
    templateUrl: "./process-form.component.html",
    styleUrls: ["./process-form.component.scss"]
})
export class ProcessFormComponent extends BaseComponent {
    constructor(
        private readonly i18nTextService: I18nTextService,
        private readonly intentsService: IntentsService,
        private readonly personsService: PersonsService,
        private readonly formBuilder: FormBuilder
    ) {
        super();

        for (const enumKey of TypeScriptHelper.getEnumKeys(ProcessTypes)) {
            if (enumKey != ProcessTypes.unknown) {
                this.processTypeLabels.push({
                    id: enumKey as string,
                    label: this.i18nTextService.getProcessTypeText(enumKey as ProcessTypes)
                });
            }
        }
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly formHelper: typeof FormHelper = FormHelper;

    @Input()
    public process!: Process;

    @Input()
    public readonly: boolean = false;

    @Input()
    public isValid: boolean = false;

    @Output()
    public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild("buildingComplexList")
    public buildingComplexListComponent?: EntitySelectionListComponent;

    public isNew: boolean = false;

    public auditors: Array<Person> = [];

    public processTypeLabels: Array<{ id: string; label: string }> = [];

    public selectedProcessTypeLabel?: string;

    public noAuditorPlaceholder: Person = CoreDataFactory.createPerson({
        identifier: Identifier.empty<PersonIdentifier>(),
        lastname: $localize`:@@person.assignNoAuditor:None - Assign later` as string
    });

    public validationMessages: FormValidationErrorMessages = new FormValidationErrorMessages(
        []
    );

    public processTypeControl: FormControl<string|null> = new FormControl<string|null>("");

    public targetDateControl: FormControl<DateTime|null|undefined> = new FormControl<DateTime|null|undefined>(undefined);

    public targetTimeControl: FormControl<string|null> = new FormControl<string|null>("");

    public auditorControl: FormControl<string|null> = new FormControl<string|null>("");

    public targetDateHasTime: boolean = false;

    public formData: FormGroup = this.formBuilder.group([
        this.processTypeControl,
        this.targetDateControl,
        this.targetTimeControl,
        this.auditorControl
    ]);

    private initializing: boolean = false;

    protected componentInit(): void {
        this.initialize();
        this.subscribe(this.formData.statusChanges, this.formChanged);
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public assignValues(): void {
        this.assignIfChanged(this.targetDateControl, this.process.targetDate ? DateTime.fromISO(this.process.targetDate) : undefined);
        this.assignIfChanged(this.targetTimeControl, this.process.targetDate && !DateTimeHelper.hasDateOnlyMarker(this.process.targetDate) ? FormHelper.dateTimeStringToTimeOnlyFormString(this.process.targetDate) : undefined);
        this.targetDateHasTime = !!(this.process.targetDate && !DateTimeHelper.hasDateOnlyMarker(this.process.targetDate));

        this.assignIfChanged(this.processTypeControl, this.process.processType);
        this.assignIfChanged(this.auditorControl, this.process.linkedAssignee?.identifier.businessIdentifier);

        this.selectedProcessTypeLabel = this.processTypeLabels.find((item: {
            id: string;
            label: string;
        }) => item.id == this.process.processType)?.label ?? this.i18nTextService.getProcessTypeText(ProcessTypes.unknown);

        this.setAuditor().then();
    }

    private async setAuditor(): Promise<void> {
        this.auditors = await this.personsService.getAllAuditors(LoadStrategies.preferServer);
        this.auditors.unshift(this.noAuditorPlaceholder);

        if (!Identifier.isEmpty(this.process.linkedAssignee?.identifier)) {
            this.auditorControl.setValue(this.process.linkedAssignee!.identifier.businessIdentifier);
        } else if (this.auditors.length <= 1 || !this.process.isNew) {
            this.auditorControl.setValue(this.noAuditorPlaceholder.identifier.businessIdentifier);
        } else {
            this.auditorControl.setValue(this.auditors[0].identifier.businessIdentifier);
        }
    }

    public updateValidState(): void {
        this.formData.markAllAsTouched();

        const typeSelected: boolean = this.process.processType != ProcessTypes.unknown;
        const entityLinksValid: boolean = this.process.linkedEntities.length == 1;

        this.isValid = this.formData.status == "VALID" && typeSelected && entityLinksValid;
        this.isValidChange.emit(this.isValid);
    }

    public async linkBuildingComplex(): Promise<void> {
        const options: SelectEntityIntentOptions = new SelectEntityIntentOptions(
            EntityTypes.buildingComplex,
            $localize`:@@process.linkBuildingComplexToProcessDialogTitle:Link building complex to process` as string,
            { allowCreate: true });
        await this.intentsService.executeIntentAndWait(SelectEntityIntent, options);

        const buildingComplexes: Array<BuildingComplex>|undefined = options.resultEntities as Array<BuildingComplex>;

        if (buildingComplexes && buildingComplexes.length > 0) {
            const buildingComplex: BuildingComplex = buildingComplexes[0];
            const entityLink: EntityLink<BuildingComplex> = CoreDataFactory.createEntityLinkFromEntity(buildingComplex);
            this.process.linkedEntities = [entityLink];
        }
        this.formChanged();
    }

    public buildingComplexLinksChanged(companyLinks: Array<EntityLink<GenericCoreDataEntity>>): void {
        this.process.linkedEntities = companyLinks as Array<EntityLink<BuildingComplex>>;
        this.updateValidState();
    }

    private initialize(): void {
        this.initializing = true;
        this.process = this.process ?? CoreDataFactory.createProcess();
        this.isNew = this.process.isNew;

        if (this.processTypeLabels.length == 1) {
            this.process.processType = this.processTypeLabels[0].id as ProcessTypes;
        }

        this.assignValues();
        this.initializing = false;
    }

    private assignIfChanged(control: AbstractControl, value: any): void {
        if (control.value != value) {
            if (typeof value === "string") {
                control.setValue(value?.trim());
            } else {
                control.setValue(value);
            }
        }
    }

    private formChanged(): void {
        if (this.initializing) {
            return;
        }

        this.process.processType = this.processTypeControl.value as ProcessTypes;
        const assigneeBusinessIdentifier: PersonBusinessIdentifier = this.auditorControl.value as PersonBusinessIdentifier;
        const linkedAssignee: Person|undefined = this.auditors.find((person: Person) => person.identifier.businessIdentifier == assigneeBusinessIdentifier);
        this.process.linkedAssignee = linkedAssignee ? CoreDataFactory.createEntityLinkFromEntity(linkedAssignee) : undefined;

        this.process.targetDate = DateTimeHelper.toUtc(DateTimeHelper.replaceTime(this.targetDateControl.value?.toString(), this.targetTimeControl.value));
        if (this.process.targetDate) {
            this.process.targetDate = this.targetDateHasTime && this.targetTimeControl.value ? DateTimeHelper.removeDateOnlyMarker(this.process.targetDate)! : DateTimeHelper.setDateOnlyMarker(this.process.targetDate)!;
        }

        this.updateValidState();
        this.buildingComplexListComponent?.updateTable();
    }

    public setHasTargetTime(hasTime: boolean): void {
        this.targetDateHasTime = hasTime;
        this.formChanged();
    }
}
