/* eslint-disable @typescript-eslint/no-magic-numbers */
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Subscription } from "rxjs";

import { environment } from "../../../../environments/environment";
import { ContextMenuItem } from "../../../base/components/context-menu/context-menu-item";
import { DialogButton } from "../../../base/components/dialogs/basic-dialog/dialog-button";
import { EventHelper } from "../../../base/helpers/event-helper";
import { ImageHelper } from "../../../base/helpers/image-helper";
import { WebHelper } from "../../../base/helpers/web-helper";
import { AlertSizes } from "../../../base/services/dialog/alert-sizes";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { TimeService } from "../../../base/services/time/time.service";
import { Timer } from "../../../base/services/time/timer";
import { SafeResult } from "../../common/safe-result";
import { Attachment } from "../../entities/attachments/attachment";
import { AttachmentStates } from "../../entities/attachments/attachment-states";
import { AttachmentTypes } from "../../entities/attachments/attachment-types";
import { ImageAttachment } from "../../entities/attachments/image-attachment";
import { AppException } from "../../entities/exceptions/app-exception";
import { HelpArticles } from "../../global/help-articles";
import { AttachmentLoaderService } from "../../services/attachments/attachment-loader.service";
import { AttachmentsService } from "../../services/attachments/attachments.service";
import { OnlineHelpService } from "../../services/help/online-help.service";
import { AppIcons } from "../../services/icons/app-icons";
import { IconsService } from "../../services/icons/icons.service";

/**
 * Component to show images, including upload progress and zoomed view.
 */
@Component({
    selector: "business-image-attachment",
    templateUrl: "./image-attachment.component.html",
    styleUrls: ["./image-attachment.component.scss"]
})
export class ImageAttachmentComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        private readonly iconsService: IconsService,
        private readonly attachmentsService: AttachmentsService,
        private readonly attachmentLoaderService: AttachmentLoaderService,
        private readonly dialogService: DialogService,
        private readonly timeService: TimeService,
        private readonly onlineHelpService: OnlineHelpService
    ) {
    }

    private readonly reloadImageInterval: number = 5000;

    private readonly downloadJpegQuality: number = environment.modifiedImageQuality;

    private readonly zoomSpeed: number = 1.2;

    public appIcons: typeof AppIcons = AppIcons;

    public attachmentTypes: typeof AttachmentTypes = AttachmentTypes;

    public attachmentStates: typeof AttachmentStates = AttachmentStates;

    public loading: boolean = false;

    public loadError: boolean = false;

    public noInternetError: boolean = false;

    public imageLoaded: boolean = false;

    private initialLoadFinished: boolean = false;

    public wasUploadingWhenOpened: boolean = false;

    public defaultPrintQuality: number = environment.imageDefaultPrintQuality;

    private attachmentField: ImageAttachment|undefined;

    @Output()
    public imageAnnotationRequested: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public cover: boolean = false;

    @Input()
    public previewImage: boolean = false;

    @Input()
    public zoom: boolean = false;

    @Input()
    public description: boolean = false;

    @Input()
    public settings: boolean = false;

    @Input()
    public allowDownload: boolean = false;

    @Input()
    public allowDelete: boolean = false;

    @Input()
    public emptyPlaceholder: boolean = false;

    @Input()
    public controlShowInPdf: boolean = true;

    @ViewChild("zoomElement")
    public zoomElement?: ElementRef;

    @ViewChild("imageElement")
    public imageElement?: ElementRef;

    private panningActive: boolean = false;

    private pinchActive: boolean = false;

    private onePinchDone: boolean = false;

    private panningMouseInside: boolean = false;

    private panningStart: {
        x: number;
        y: number;
    } = { x: 0, y: 0 };

    private panningPoint: {
        x: number;
        y: number;
    } = { x: 0, y: 0 };

    private pinchDistanceStart: number = 0;

    private zoomScale: number = 1;

    private reloadImageTimer?: Timer;

    private reloadImageSubscription?: Subscription;

    private pinchScaleStartOffset: number = 0;

    public get attachment(): ImageAttachment|undefined {
        return this.attachmentField;
    }

    @Input()
    public set attachment(value: ImageAttachment|undefined) {
        this.attachmentField = value;
        if (this.initialLoadFinished) {
            this.loadImage(true).then();
        }
    }

    public get errorState(): boolean {
        return this.attachment?.hasError ?? false;
    }

    public get imageUrl(): string|undefined {
        if (this.previewImage && this.attachment?.localUrlPreview) {
            return this.attachment.localUrlPreview;
        }
        if (this.previewImage && this.attachment?.localUrl) {
            return this.attachment.localUrl;
        }
        if (!this.previewImage && this.attachment?.localUrl) {
            return this.attachment.localUrl;
        }
        if (!this.previewImage && this.attachment?.localUrlPreview) {
            return this.attachment.localUrlPreview;
        }
    }

    public ngOnInit(): void {
        // Do nothing for now
    }

    public ngOnDestroy(): void {
        this.stopReloadTimer();
    }

    public ngAfterViewInit(): void {
        // Prevent expression changed after load
        setTimeout(this.loadImage.bind(this), 10);
    }

    private async loadImage(forceLoad?: boolean): Promise<void> {
        this.stopReloadTimer();
        this.wasUploadingWhenOpened = !!this.attachment && this.attachment.state != AttachmentStates.metaUpdated;

        if (!forceLoad && ((this.previewImage && this.attachment?.localUrlPreview) || (!this.previewImage && this.attachment?.localUrl))) {
            this.updateImage();

            this.initialLoadFinished = true;
            return;
        }

        if (this.loading) {
            return;
        }

        if (this.attachment) {
            this.loading = true;
            this.loadError = false;
            this.noInternetError = false;

            try {
                await this.attachmentLoaderService.loadAttachment(this.attachment, this.previewImage, !this.previewImage);
                // If no preview image is available, load the full image
                if (!this.imageUrl && this.previewImage) {
                    await this.attachmentLoaderService.loadAttachment(this.attachment, false, true);
                }
                this.updateImage();
            } catch (error) {
                if (WebHelper.isNoInternetError(error)) {
                    this.noInternetError = true;
                    this.startReloadTimer();
                } else {
                    this.loadError = true;
                }
            } finally {
                this.loading = false;
            }
        }

        this.initialLoadFinished = true;
    }

    private startReloadTimer(): void {
        if (this.reloadImageTimer) {
            this.stopReloadTimer();
        }

        this.reloadImageTimer = this.timeService.spawnTimer(this.reloadImageInterval);
        this.reloadImageSubscription = EventHelper.subscribe(this.reloadImageTimer.elapsed, () => this.loadImage(), this);
        this.reloadImageTimer.start();
    }

    private stopReloadTimer(): void {
        if (this.reloadImageSubscription) {
            this.reloadImageSubscription = EventHelper.unsubscribe(this.reloadImageSubscription);
        }

        if (this.reloadImageTimer) {
            this.reloadImageTimer.stop();
            this.reloadImageTimer = undefined;
        }
    }

    private updateImage(): void {
        const imageUrl: string|undefined = this.imageUrl;
        if (this.imageElement && imageUrl) {
            this.imageElement.nativeElement.style.backgroundImage = `url(${imageUrl})`;
            this.imageLoaded = true;
        }
    }

    public attachmentStateToIcon(attachment: Attachment): IconDefinition {
        if (attachment.state == AttachmentStates.uploading && attachment.uploadProgress >= 1) {
            return this.appIcons.attachmentProcessingOnServerSide;
        }
        return this.iconsService.getAttachmentStateFaIcon(attachment.state);
    }

    @HostListener("window:mouseup", ["$event"])
    public handleKeyDown(): void {
        if (!this.panningMouseInside && this.panningActive) {
            this.panningActive = false;
        }
    }

    public zoomMouseDown(event: MouseEvent): void {
        if (!this.zoom) {
            return;
        }
        event.preventDefault();

        if (this.zoomElement?.nativeElement?.style) {
            this.zoomElement.nativeElement.style.transition = "none";
        }

        this.panningStart.x = event.clientX - this.panningPoint.x;
        this.panningStart.y = event.clientY - this.panningPoint.y;
        this.panningActive = true;
        this.panningMouseInside = true;
    }

    public zoomMouseUp(): void {
        this.panningActive = false;
    }

    public zoomMouseMove(event: MouseEvent): void {
        if (!this.zoom || !this.panningActive) {
            return;
        }
        event.preventDefault();

        this.panningPoint.x = event.clientX - this.panningStart.x;
        this.panningPoint.y = event.clientY - this.panningStart.y;
        this.updateZoomTransform(false);
    }

    public zoomMouseLeave(): void {
        this.panningMouseInside = false;
    }

    public zoomMouseEnter(): void {
        this.panningMouseInside = true;
    }

    public zoomMouseWheel(event: WheelEvent): void {
        if (!this.zoom) {
            return;
        }

        event.preventDefault();
        const xs: number = (event.clientX - this.panningPoint.x) / this.zoomScale;
        const ys: number = (event.clientY - this.panningPoint.y) / this.zoomScale;
        const delta: number = ((event as any).wheelDelta as number ? (event as any).wheelDelta as number : -event.deltaY);
        this.zoomScale = delta > 0 ? this.zoomScale * this.zoomSpeed : this.zoomScale / this.zoomSpeed;
        this.zoomScale = Math.max(1.0, this.zoomScale);
        if (this.zoomScale > 1.0) {
            this.panningPoint.x = event.clientX - xs * this.zoomScale;
            this.panningPoint.y = event.clientY - ys * this.zoomScale;
        } else {
            this.panningPoint.x = 0;
            this.panningPoint.y = 0;
        }

        this.updateZoomTransform(this.zoomScale <= 1.0);
    }

    private updateZoomTransform(toOrigin: boolean): void {
        if (this.zoomElement?.nativeElement?.style) {
            this.zoomElement.nativeElement.style.transform = `translate(${this.panningPoint.x}px, ${this.panningPoint.y}px) scale(${this.zoomScale})`;
            this.zoomElement.nativeElement.style.transition = toOrigin ? "transform 0.2s ease 0s" : "none";
        }
    }

    // eslint-disable-next-line complexity
    public async openSettings(event: Event, atMousePosition: boolean): Promise<void> {
        event.preventDefault();
        event.stopPropagation();

        if (!this.attachment) {
            return;
        }

        if (this.attachment.hasError) {
            return;
        }

        enum MenuOptions {
            none,
            description,
            annotate,
            showInPdf,
            printQualityLow,
            printQualityMedium,
            printQualityHigh,
            printQualityHighest,
            download,
            delete,
            help,
        }

        const menuItems: Array<ContextMenuItem> = [];
        if (this.settings) {
            menuItems.push(new ContextMenuItem($localize`:@@image.editDescription:Edit description` as string, {
                tag: MenuOptions.description,
                icon: AppIcons.genericEdit
            }));
            menuItems.push(new ContextMenuItem($localize`:@@mediaView.annotateImageButton:Annotate image` as string, {
                tag: MenuOptions.annotate,
                icon: AppIcons.annotateImage
            }));
            if (this.controlShowInPdf) {
                menuItems.push(new ContextMenuItem("-", { isDivider: true }));
                menuItems.push(new ContextMenuItem($localize`:@@image.showInPdf:Show in PDF` as string, {
                    tag: MenuOptions.showInPdf,
                    hasCheckbox: true,
                    isChecked: this.attachment.showInPdf,
                    icon: AppIcons.previewDocumentPdf
                }));
                menuItems.push(new ContextMenuItem("-", { isDivider: true }));
                let menuItem: ContextMenuItem = new ContextMenuItem($localize`:@@image.printQualityLow:Low print quality` as string, {
                    tag: MenuOptions.printQualityLow,
                    hasCheckbox: false,
                    isChecked: this.attachment.printQuality < environment.imagePrintQualityMedium,
                    icon: AppIcons.qualityLow
                });
                menuItem.isFaded = !menuItem.isChecked;
                menuItem.icon = menuItem.isChecked ? AppIcons.genericCheck : menuItem.icon;
                menuItems.push(menuItem);
                menuItem = new ContextMenuItem($localize`:@@image.printQualityMedium:Medium print quality` as string, {
                    tag: MenuOptions.printQualityMedium,
                    hasCheckbox: false,
                    isChecked: this.attachment.printQuality >= environment.imagePrintQualityMedium && this.attachment.printQuality < environment.imagePrintQualityHigh,
                    icon: AppIcons.qualityMedium
                });
                menuItem.isFaded = !menuItem.isChecked;
                menuItem.icon = menuItem.isChecked ? AppIcons.genericCheck : menuItem.icon;
                menuItems.push(menuItem);
                menuItem = new ContextMenuItem($localize`:@@image.printQualityHigh:High print quality` as string, {
                    tag: MenuOptions.printQualityHigh,
                    hasCheckbox: false,
                    isChecked: this.attachment.printQuality >= environment.imagePrintQualityHigh && this.attachment.printQuality < environment.imagePrintQualityHighest,
                    icon: AppIcons.qualityHigh
                });
                menuItem.isFaded = !menuItem.isChecked;
                menuItem.icon = menuItem.isChecked ? AppIcons.genericCheck : menuItem.icon;
                menuItems.push(menuItem);
                menuItem = new ContextMenuItem($localize`:@@image.printQualityHighest:Highest print quality` as string, {
                    tag: MenuOptions.printQualityHighest,
                    hasCheckbox: false,
                    isChecked: this.attachment.printQuality >= environment.imagePrintQualityHighest,
                    icon: AppIcons.qualityMax
                });
                menuItem.isFaded = !menuItem.isChecked;
                menuItem.icon = menuItem.isChecked ? AppIcons.genericCheck : menuItem.icon;
                menuItems.push(menuItem);
            }
        }

        if (this.allowDownload) {
            menuItems.push(new ContextMenuItem("-", { isDivider: true }));
            menuItems.push(new ContextMenuItem($localize`:@@mediaView.downloadImageButton:Download image` as string, {
                tag: MenuOptions.download,
                icon: AppIcons.downloadImage
            }));
        }

        if (this.allowDelete && this.attachment.identifier.technicalIdentifier) {
            menuItems.push(new ContextMenuItem("-", { isDivider: true }));
            menuItems.push(new ContextMenuItem($localize`:@@mediaView.deleteImageButton:Delete image` as string, {
                tag: MenuOptions.delete,
                icon: AppIcons.genericDelete
            }));
        }

        menuItems.push(new ContextMenuItem("-", { isDivider: true }));
        menuItems.push(new ContextMenuItem($localize`:@@manual.genericHelp:Help` as string, {
            tag: MenuOptions.help,
            icon: AppIcons.genericHelp
        }));

        if (menuItems.length <= 0) {
            return;
        }

        const result: ContextMenuItem|undefined = await this.dialogService.showContextMenu(menuItems, event, atMousePosition);
        switch (result?.tag) {
            case MenuOptions.description:
                await this.updateDescription();
                break;
            case MenuOptions.annotate:
                this.annotateImage();
                break;
            case MenuOptions.download:
                await this.downloadImage();
                break;
            case MenuOptions.showInPdf:
                await this.toggleVisibleInPdf();
                break;
            case MenuOptions.printQualityLow:
                await this.changePrintQuality(environment.imagePrintQualityLow);
                break;
            case MenuOptions.printQualityMedium:
                await this.changePrintQuality(environment.imagePrintQualityMedium);
                break;
            case MenuOptions.printQualityHigh:
                await this.changePrintQuality(environment.imagePrintQualityHigh);
                break;
            case MenuOptions.printQualityHighest:
                await this.changePrintQuality(environment.imagePrintQualityHighest);
                break;
            case MenuOptions.delete:
                await this.deleteImage();
                break;
            case MenuOptions.help:
                this.onlineHelpService.openArticle(HelpArticles.ADT2ImageAttachments);
                break;
        }
    }

    public async updateDescription(): Promise<void> {
        if (!this.attachment) {
            return;
        }
        const newDescription: string|undefined = await this.dialogService.showInputPrompt($localize`:@@image.updateDescriptionTitle:Image description`, undefined, undefined, undefined, this.attachment?.description, false, AlertSizes.large);
        if (newDescription !== undefined && newDescription != this.attachment.description) {
            this.attachment.description = newDescription;
            await this.attachmentsService.updateMetaData(this.attachment);
        }
    }

    public async downloadImage(): Promise<void> {
        if (!this.attachment?.identifier.technicalIdentifier) {
            return;
        }

        const loading: HTMLIonLoadingElement = await this.dialogService.showLoading($localize`:@@image.downloadingImage:Downloading image...`);
        try {
            const downloadResult: SafeResult<Blob, AppException> = await this.attachmentsService.loadOriginalAttachmentBlob(this.attachment.identifier.technicalIdentifier, AttachmentTypes.image);
            if (downloadResult.isError()) {
                this.dialogService.showError(downloadResult.error).then();
                return;
            }

            const blob: Blob = await ImageHelper.blobToJpegBlob(downloadResult.result, this.downloadJpegQuality);
            WebHelper.downloadBlob(blob, `${this.attachment.identifier.technicalIdentifier}.jpeg`);
        } catch (error) {
            await this.dialogService.showError(error);
        } finally {
            await loading?.dismiss();
        }
    }

    public annotateImage(): void {
        this.imageAnnotationRequested.emit();
    }

    public async toggleVisibleInPdf(): Promise<void> {
        if (!this.attachment) {
            return;
        }

        this.attachment.showInPdf = !this.attachment.showInPdf;
        await this.attachmentsService.updateMetaData(this.attachment);
    }

    public async changePrintQuality(value: number): Promise<void> {
        if (!this.attachment) {
            return;
        }

        this.attachment.printQuality = value;
        await this.attachmentsService.updateMetaData(this.attachment);
    }

    public async deleteImage(): Promise<void> {
        const confirmDelete: boolean = (await this.dialogService.showAlert($localize`:@@image.confirmDeleteTitle:Confirm delete`, $localize`:@@image.confirmDeleteText:Do you really want to delete this image?<br /><br />Once the image is deleted it is not possible to restore it afterwards.`, undefined,
            [new DialogButton($localize`:@@image.confirmDeleteYesButton:Yes, delete`, "delete", false, "destructive"), new DialogButton($localize`:@@image.confirmDeleteNoButton:No, keep image`, "keep", true)]
        )) == "delete";
        if (confirmDelete) {
            await this.attachmentsService.deleteImage(this.attachment!);
        }
    }

    public async imageClicked(event: MouseEvent): Promise<void> {
        if (this.noInternetError) {
            event.stopPropagation();
            await this.loadImage();
        }
    }

    public getPrintQualityIcon(): IconDefinition {
        if (!this.attachment) {
            return AppIcons.qualityHigh;
        }

        switch (true) {
            case this.attachment.printQuality < environment.imagePrintQualityMedium:
                return AppIcons.qualityLow;
            case this.attachment.printQuality < environment.imagePrintQualityHigh:
                return AppIcons.qualityMedium;
            case this.attachment.printQuality < environment.imagePrintQualityHighest:
                return AppIcons.qualityHigh;
            default:
                return AppIcons.qualityMax;
        }
    }

    private panningDistance(event: TouchEvent): number {
        return Math.hypot(event.touches[0].clientX - event.touches[1].clientX, event.touches[0].clientY - event.touches[1].clientY);
    }

    public pinchTouchStart(event: TouchEvent): void {
        if (!this.zoom) {
            return;
        }

        if (event.touches.length == 1) {
            this.panningStart.x = event.touches[0].clientX - this.panningPoint.x;
            this.panningStart.y = event.touches[0].clientY - this.panningPoint.y;
        } else if (event.touches.length == 2) {
            event.preventDefault();
            this.pinchActive = true;
            this.onePinchDone = false;
            this.panningStart.x = (event.touches[0].clientX + event.touches[1].clientX) / 2 - this.panningPoint.x;
            this.panningStart.y = (event.touches[0].clientY + event.touches[1].clientY) / 2 - this.panningPoint.y;
            this.pinchDistanceStart = this.panningDistance(event);
            this.pinchScaleStartOffset = this.zoomScale - 1.0;
        }
    }

    public pinchTouchEnd(_event: TouchEvent): void {
        if (_event.touches.length <= 0) {
            this.pinchActive = false;
        }
    }

    public pinchTouchMove(event: TouchEvent): void {
        if (!this.zoom || !this.pinchActive) {
            return;
        }

        if (event.touches.length == 1 && !this.pinchActive) {
            if (this.zoomScale > 1.0) {
                this.panningPoint.x = event.touches[0].clientX - this.panningStart.x;
                this.panningPoint.y = event.touches[0].clientY - this.panningStart.y;
            } else {
                this.panningPoint.x = 0;
                this.panningPoint.y = 0;
            }
            this.updateZoomTransform(false);
            return;
        }

        if (event.touches.length !== 2) {
            return;
        }
        event.preventDefault(); // Prevent page scroll

        const oldScale: number = this.zoomScale;
        this.zoomScale = Math.max(1.0, this.pinchScaleStartOffset + this.panningDistance(event) / this.pinchDistanceStart);
        const zoomDiff: number = this.zoomScale / oldScale;
        this.panningStart.x = this.panningStart.x * zoomDiff;
        this.panningStart.y = this.panningStart.y * zoomDiff;

        if (this.zoomScale > 1.0) {
            this.onePinchDone = true;
            this.panningPoint.x = (event.touches[0].clientX + event.touches[1].clientX) / 2 - this.panningStart.x;
            this.panningPoint.y = (event.touches[0].clientY + event.touches[1].clientY) / 2 - this.panningStart.y;
        } else {
            this.panningPoint.x = 0;
            this.panningPoint.y = 0;
            if (this.onePinchDone) {
                this.pinchActive = false;
            }
        }

        this.updateZoomTransform(this.zoomScale <= 1.0);
    }

    public doubleClick(event: MouseEvent): void {
        if (this.zoomScale > 1.0) {
            this.zoomScale = 1.0;
            this.panningPoint.x = 0;
            this.panningPoint.y = 0;
        } else {
            this.zoomScale = 3.0;
            this.panningPoint.x = event.clientX - event.clientX * this.zoomScale;
            this.panningPoint.y = event.clientY - event.clientY * this.zoomScale;
        }
        this.updateZoomTransform(this.zoomScale <= 1.0);
    }
}
