import { Injectable } from "@angular/core";
import { EntityTypes } from "src/app/business/entities/entity-types";
import { ProcessStatus } from "src/app/business/entities/projects-processes/process-status";
import { BuildingComplexDto, BuildingDto, CompanyDto, PersonDto, ProcessDto, ProjectDto, ResponseBodyDashboardProcessesDto, ResponseBodyProcessOverviewDto, SamplingPlanDto } from "src/app/generated/api";

import { Api1Converter } from "../../../../api/v1/converters/api1-converter";
import { LoadStrategies } from "../../../common/load-strategies";
import { Building } from "../../../entities/core-data/buildings/building";
import { BuildingComplex } from "../../../entities/core-data/buildings/building-complex";
import { Company } from "../../../entities/core-data/company";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { Person } from "../../../entities/core-data/person";
import { DashboardProcesses } from "../../../entities/dashboards/dashboard-processes";
import { EntityLink } from "../../../entities/documents/entity-link";
import { AppException } from "../../../entities/exceptions/app-exception";
import { Process } from "../../../entities/projects-processes/process";
import { Project } from "../../../entities/projects-processes/project";
import { SamplingPlan } from "../../../entities/samplings/sampling-plan";
import { FrontendErrors } from "../../../global/frontend-errors";
import { EntityHelper } from "../../../helpers/entity-helper";
import { BuildingComplexIdentifier } from "../../../identifiers/building-complex-identifier";
import { BuildingIdentifier } from "../../../identifiers/building-identifier";
import { CompanyIdentifier } from "../../../identifiers/company-identifier";
import { DocumentIdentifier } from "../../../identifiers/document-identifier";
import { EntityIdentifier } from "../../../identifiers/entity-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { PersonIdentifier } from "../../../identifiers/person-identifier";
import { ProjectIdentifier } from "../../../identifiers/project-identifier";
import { ProjectTechnicalIdentifier } from "../../../identifiers/project-technical-identifier";
import { BuildingsApiService } from "../../api/buildings-api.service";
import { CompaniesApiService } from "../../api/companies-api.service";
import { DashboardsApiService } from "../../api/dashboards-api.service";
import { PersonsApiService } from "../../api/persons-api.service";
import { ProcessesApiService } from "../../api/processes-api.service";
import { ProjectsApiService } from "../../api/projects-api.service";
import { SamplingsApiService } from "../../api/samplings-api.service";
import { SessionService } from "../../session/session.service";
import { CoreDataStorageService } from "../storage/core-data-storage.service";

/**
 * Service to save entities to the server.
 */
@Injectable({
    providedIn: "root"
})
export class CoreDataService {
    constructor(
        private readonly coreDataStorageService: CoreDataStorageService,
        private readonly sessionService: SessionService,
        private readonly projectsApiService: ProjectsApiService,
        private readonly processesApiService: ProcessesApiService,
        private readonly personsApiService: PersonsApiService,
        private readonly buildingsApiService: BuildingsApiService,
        private readonly companiesApiService: CompaniesApiService,
        private readonly samplingsApiService: SamplingsApiService,
        private readonly dashboardsApiService: DashboardsApiService
    ) {
    }

    public async saveEntity<TEntity extends GenericCoreDataEntity>(entity: TEntity, archive: boolean): Promise<TEntity> {
        const updatedEntity: TEntity = await this.sendToServer(entity, archive) as TEntity;
        await this.updateLinkedEntitiesOfCachedEntity(updatedEntity, LoadStrategies.cachedOnly);
        await this.updateEntityCache(updatedEntity);
        return updatedEntity;
    }

    public async updateEntityCache(entity: GenericCoreDataEntity): Promise<void> {
        await this.coreDataStorageService.updateEntity(entity);
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public async loadEntity<TEntity extends GenericCoreDataEntity>(identifier: EntityIdentifier, entityType: EntityTypes, loadStrategy: LoadStrategies = LoadStrategies.preferCached, parentIdentifier?: EntityIdentifier, depth: number = 9999): Promise<TEntity|undefined>|never {
        let cachedEntity: TEntity|undefined;
        if (loadStrategy != LoadStrategies.serverOnly && loadStrategy != LoadStrategies.serverOnlyLatest) {
            cachedEntity = await this.coreDataStorageService.get(identifier, entityType);
            if (cachedEntity) {
                if (depth > 0) {
                    await this.updateLinkedEntitiesOfCachedEntity(cachedEntity, loadStrategy);
                }
                if (loadStrategy == LoadStrategies.cachedOnly || loadStrategy == LoadStrategies.preferCached) {
                    return cachedEntity;
                }
            }
        }

        if (loadStrategy == LoadStrategies.cachedOnly) {
            return cachedEntity;
        }

        switch (entityType) {
            case EntityTypes.project:
                return await this.loadProject(identifier, cachedEntity, loadStrategy, depth - 1) as TEntity|undefined;
            case EntityTypes.process:
                return await this.loadProcess(parentIdentifier!, identifier, cachedEntity, loadStrategy) as TEntity|undefined;
            case EntityTypes.person:
                return await this.loadPerson(identifier, cachedEntity, loadStrategy) as TEntity|undefined;
            case EntityTypes.buildingComplex:
                return await this.loadBuildingComplex(identifier, cachedEntity, loadStrategy) as TEntity|undefined;
            case EntityTypes.building:
                return await this.loadBuilding(identifier, cachedEntity, loadStrategy) as TEntity|undefined;
            case EntityTypes.company:
                return await this.loadCompany(identifier, cachedEntity, loadStrategy) as TEntity|undefined;
            case EntityTypes.samplingPlan:
                return await this.loadSamplingPlan(identifier, cachedEntity, loadStrategy) as TEntity|undefined;
            case EntityTypes.dashboardProcesses:
                return await this.loadDashboardProcesses(cachedEntity, loadStrategy) as TEntity|undefined;
        }
        throw new AppException(FrontendErrors.FE37UnablePersistEntityTypeUnknown, $localize`:@@exception.fe37UnablePersistEntityTypeUnknown:The entity ${Identifier.identifierToString(identifier)}:entityId: of type "${entityType}:entityType:" cannot be saved to server because the type is not known.`);
    }

    private async loadProject<TEntity extends GenericCoreDataEntity>(identifier: EntityIdentifier, cachedEntity: TEntity|undefined, loadStrategy: LoadStrategies, depth: number): Promise<TEntity|undefined> {
        try {
            const projectDto: ProjectDto|undefined = await this.projectsApiService.getProject(identifier as ProjectIdentifier);
            if (projectDto) {
                const project: Project = Api1Converter.dtoToProject(projectDto);
                if (project.companyLink && depth > 0) {
                    await this.updateLinkedEntities([project.companyLink], loadStrategy);
                }
                await this.updateEntityCache(project);
                return project as GenericCoreDataEntity as TEntity;
            }
        } catch (error) {
            if (!cachedEntity || loadStrategy == LoadStrategies.serverOnly || loadStrategy == LoadStrategies.serverOnlyLatest) {
                throw error;
            }
            return cachedEntity;
        }
        return undefined;
    }

    private async loadProcess<TEntity extends GenericCoreDataEntity>(projectIdentifier: EntityIdentifier, processIdentifier: EntityIdentifier, cachedEntity: TEntity|undefined, loadStrategy: LoadStrategies): Promise<TEntity|undefined> {
        try {
            const dtos: ResponseBodyProcessOverviewDto = await this.processesApiService.getProcesses(projectIdentifier.technicalIdentifier as ProjectTechnicalIdentifier);
            const result: Array<Process> = Api1Converter.dtoToProcessesList(dtos);
            let process: Process|undefined;
            if (processIdentifier.technicalIdentifier) {
                process = result.find((item: Process) => item.identifier.technicalIdentifier == processIdentifier.technicalIdentifier);
            } else if (processIdentifier.businessIdentifier) {
                process = EntityHelper.getLatestByCreatedAndUpdatedTime(Array.from(result.filter((item: Process) => item.identifier.businessIdentifier == processIdentifier.businessIdentifier)));
            }
            if (process) {
                await this.updateLinkedEntities(process.linkedEntities, loadStrategy);
                await this.updateEntityCache(process);
            }
            return process as TEntity|undefined;
        } catch (error) {
            if (!cachedEntity || loadStrategy == LoadStrategies.serverOnly || loadStrategy == LoadStrategies.serverOnlyLatest) {
                throw error;
            }
            return cachedEntity;
        }
    }

    private async loadPerson<TEntity extends GenericCoreDataEntity>(identifier: EntityIdentifier, cachedEntity: TEntity|undefined, loadStrategy: LoadStrategies): Promise<TEntity|undefined> {
        try {
            const personDto: PersonDto|undefined = await this.personsApiService.getPerson(identifier as PersonIdentifier);
            if (personDto) {
                const person: Person = Api1Converter.dtoToPerson(personDto);
                await this.updateEntityCache(person);
                return person as GenericCoreDataEntity as TEntity;
            }
        } catch (error) {
            if (!cachedEntity || loadStrategy == LoadStrategies.serverOnly || loadStrategy == LoadStrategies.serverOnlyLatest) {
                throw error;
            }
            return cachedEntity;
        }
        return undefined;
    }

    private async loadBuildingComplex<TEntity extends GenericCoreDataEntity>(identifier: EntityIdentifier, cachedEntity: TEntity|undefined, loadStrategy: LoadStrategies): Promise<TEntity|undefined> {
        try {
            const buildingComplexDto: BuildingComplexDto|undefined = await this.buildingsApiService.getBuildingComplex(identifier as BuildingComplexIdentifier);
            if (buildingComplexDto) {
                const buildingComplex: BuildingComplex = Api1Converter.dtoToBuildingComplex(buildingComplexDto);
                await this.updateLinkedEntities(buildingComplex.personLinks, loadStrategy);
                await this.updateEntityCache(buildingComplex);
                return buildingComplex as GenericCoreDataEntity as TEntity;
            }
        } catch (error) {
            if (!cachedEntity || loadStrategy == LoadStrategies.serverOnly || loadStrategy == LoadStrategies.serverOnlyLatest) {
                throw error;
            }
            return cachedEntity;
        }
        return undefined;
    }

    private async loadBuilding<TEntity extends GenericCoreDataEntity>(identifier: EntityIdentifier, cachedEntity: TEntity|undefined, loadStrategy: LoadStrategies): Promise<TEntity|undefined> {
        try {
            const buildingDto: BuildingDto|undefined = await this.buildingsApiService.getBuilding(identifier as BuildingIdentifier, loadStrategy == LoadStrategies.serverOnlyLatest);
            if (buildingDto) {
                const building: Building = Api1Converter.dtoToBuilding(buildingDto);
                await this.updateEntityCache(building);
                return building as GenericCoreDataEntity as TEntity;
            }
        } catch (error) {
            if (!cachedEntity || loadStrategy == LoadStrategies.serverOnly || loadStrategy == LoadStrategies.serverOnlyLatest) {
                throw error;
            }
            return cachedEntity;
        }
        return undefined;
    }

    private async loadCompany<TEntity extends GenericCoreDataEntity>(identifier: EntityIdentifier, cachedEntity: TEntity|undefined, loadStrategy: LoadStrategies): Promise<TEntity|undefined> {
        try {
            const companyDto: CompanyDto|undefined = await this.companiesApiService.getCompany(identifier as CompanyIdentifier);
            if (companyDto) {
                const company: Company = Api1Converter.dtoToCompany(companyDto);
                await this.updateLinkedEntities(company.personLinks, loadStrategy);
                await this.updateEntityCache(company);
                return company as GenericCoreDataEntity as TEntity;
            }
        } catch (error) {
            if (!cachedEntity || loadStrategy == LoadStrategies.serverOnly || loadStrategy == LoadStrategies.serverOnlyLatest) {
                throw error;
            }
            return cachedEntity;
        }
        return undefined;
    }

    private async loadSamplingPlan<TEntity extends GenericCoreDataEntity>(documentIdentifier: EntityIdentifier, cachedEntity: TEntity|undefined, loadStrategy: LoadStrategies): Promise<TEntity|undefined> {
        try {
            const samplingPlanDto: SamplingPlanDto|undefined = await this.samplingsApiService.getSamplingPlan(documentIdentifier as DocumentIdentifier);
            if (samplingPlanDto) {
                const samplingPlan: SamplingPlan = Api1Converter.dtoToSamplingPlan(samplingPlanDto);
                await this.updateEntityCache(samplingPlan);
                return samplingPlan as GenericCoreDataEntity as TEntity;
            }
        } catch (error) {
            if (!cachedEntity || loadStrategy == LoadStrategies.serverOnly || loadStrategy == LoadStrategies.serverOnlyLatest) {
                throw error;
            }
            return cachedEntity;
        }
        return undefined;
    }

    private async loadDashboardProcesses<TEntity extends GenericCoreDataEntity>(cachedEntity: TEntity|undefined, loadStrategy: LoadStrategies): Promise<TEntity|undefined> {
        try {
            const dashboardProcessesDto: ResponseBodyDashboardProcessesDto|undefined = await this.dashboardsApiService.getProcessesDashboard();
            if (dashboardProcessesDto) {
                const dashboardProcesses: DashboardProcesses = Api1Converter.dtoToDashboardProcesses(dashboardProcessesDto);
                await this.updateEntityCache(dashboardProcesses);
                for (const linkedEntity of dashboardProcesses.linkedEntities) {
                    if (linkedEntity.entity) {
                        if ((linkedEntity.entityType == EntityTypes.process) && (linkedEntity.entity as Process)?.status == ProcessStatus.done) {
                            // These processes should not be cached because they are already done.
                        } else {
                            await this.updateEntityCache(linkedEntity.entity);
                        }
                    }
                }

                await this.updateLinkedEntitiesOfCachedProcesses(dashboardProcesses.linkedEntities);

                return dashboardProcesses as GenericCoreDataEntity as TEntity;
            }
        } catch (error) {
            if (!cachedEntity || loadStrategy == LoadStrategies.serverOnly || loadStrategy == LoadStrategies.serverOnlyLatest) {
                throw error;
            }
            return cachedEntity;
        }
        return undefined;
    }

    private async updateLinkedEntitiesOfCachedProcesses(linkedEntities: Array<EntityLink<GenericCoreDataEntity>>): Promise<void> {
        for (const linkedEntity of linkedEntities.filter((link: EntityLink<GenericCoreDataEntity>) => link.entityType == EntityTypes.process)) {
            const process: Process = linkedEntity.entity as Process;
            if (process) {
                await this.updateLinkedEntities([process.linkedAssignee], LoadStrategies.cachedOnly);

                for (const processLinkedEntity of process.linkedEntities) {
                    if (!processLinkedEntity.entity) {
                        processLinkedEntity.entity = await this.loadEntity(processLinkedEntity.identifier, processLinkedEntity.entityType, LoadStrategies.cachedOnly);
                    }
                }
                this.updateProjectIdentifierInProcess(process, linkedEntities);
            }
        }
    }

    private updateProjectIdentifierInProcess(process: Process, linkedEntities: Array<EntityLink<GenericCoreDataEntity>>): void {
        if (Identifier.isEmpty(process.projectIdentifier)) {
            process.projectIdentifier = linkedEntities.find((link: EntityLink<GenericCoreDataEntity>) =>
                link.entityType == EntityTypes.project && (link.entity as Project|undefined)?.processes.some(
                    (innerProcess: EntityLink<Process>) => innerProcess.identifier.businessIdentifier == process.identifier.businessIdentifier || innerProcess.identifier.technicalIdentifier == process.identifier.technicalIdentifier))?.identifier as ProjectIdentifier|undefined ?? Identifier.empty<ProjectIdentifier>();
        }
    }

    private async updateLinkedEntitiesOfCachedEntity<TEntity extends GenericCoreDataEntity>(entity: TEntity, loadStrategy: LoadStrategies): Promise<void> {
        switch (entity.entityType) {
            case EntityTypes.project:
                const project: Project = entity as GenericCoreDataEntity as Project;
                if (project.companyLink) {
                    await this.updateLinkedEntities([project.companyLink], loadStrategy);
                }
                break;
            case EntityTypes.process:
                const process: Process = entity as GenericCoreDataEntity as Process;
                await this.updateLinkedEntities(process.linkedEntities, loadStrategy);
                if (process.linkedAssignee) {
                    await this.updateLinkedEntities([process.linkedAssignee], loadStrategy);
                }
                break;
            case EntityTypes.buildingComplex:
                const buildingComplex: BuildingComplex = entity as GenericCoreDataEntity as BuildingComplex;
                await this.updateLinkedEntities(buildingComplex.personLinks, loadStrategy);
                break;
            case EntityTypes.company:
                const company: Company = entity as GenericCoreDataEntity as Company;
                await this.updateLinkedEntities(company.personLinks, loadStrategy);
                break;
            case EntityTypes.dashboardProcesses:
                const dashboardProcesses: DashboardProcesses = entity as GenericCoreDataEntity as DashboardProcesses;
                await this.updateLinkedEntities(dashboardProcesses.linkedEntities, loadStrategy);
                await this.updateLinkedEntitiesOfCachedProcesses(dashboardProcesses.linkedEntities);
                break;
        }
    }

    private async updateLinkedEntities<TEntity extends GenericCoreDataEntity>(entityLinks: Array<EntityLink<TEntity>|undefined>, loadStrategy: LoadStrategies): Promise<void> {
        for (const entityLink of entityLinks) {
            if (entityLink && !entityLink.entity) {
                entityLink.entity = await this.loadEntity<TEntity>(entityLink.identifier, entityLink.entityType, loadStrategy);
                if (entityLink.entity) {
                    entityLink.identifier = Identifier.merge(entityLink.identifier, entityLink.entity.identifier);
                }
            }
        }
    }

    private async sendToServer(entity: GenericCoreDataEntity, archive: boolean): Promise<GenericCoreDataEntity> {
        switch (entity.entityType) {
            case EntityTypes.project:
                const project: Project = entity as Project;
                let projectDto: ProjectDto = Api1Converter.projectToDto(project);
                projectDto = project.isNew ? await this.projectsApiService.createProject(projectDto) : await this.projectsApiService.updateProject(project.identifier.businessIdentifier, projectDto, archive);
                return Api1Converter.dtoToProject(projectDto);
            case EntityTypes.process:
                const process: Process = entity as Process;
                let processDto: ProcessDto = Api1Converter.processToDto(process);
                processDto = process.isNew ? await this.processesApiService.createProcess(process.projectIdentifier.businessIdentifier, processDto) : await this.processesApiService.updateProcess(process.identifier.businessIdentifier, processDto, archive);
                return Api1Converter.dtoToProcess(processDto);
            case EntityTypes.person:
                const person: Person = entity as Person;
                let personDto: PersonDto = Api1Converter.personToDto(person);
                personDto = person.isNew ? await this.personsApiService.createPerson(personDto) : await this.personsApiService.updatePerson(person.identifier.businessIdentifier, personDto, archive);
                return Api1Converter.dtoToPerson(personDto);
            case EntityTypes.buildingComplex:
                const buildingComplex: BuildingComplex = entity as BuildingComplex;
                let buildingComplexDto: BuildingComplexDto = Api1Converter.buildingComplexToDto(buildingComplex);
                buildingComplexDto = buildingComplex.isNew ? await this.buildingsApiService.createBuildingComplex(buildingComplexDto) : await this.buildingsApiService.updateBuildingComplex(buildingComplex.identifier.businessIdentifier, buildingComplexDto, archive);
                return Api1Converter.dtoToBuildingComplex(buildingComplexDto);
            case EntityTypes.building:
                const building: Building = entity as Building;
                let buildingDto: BuildingDto = Api1Converter.buildingToDto(building);
                buildingDto = building.isNew ? await this.buildingsApiService.createBuilding(building.buildingComplexIdentifier.businessIdentifier, buildingDto) : await this.buildingsApiService.updateBuilding(building.identifier.businessIdentifier, buildingDto, archive);
                return Api1Converter.dtoToBuilding(buildingDto);
            case EntityTypes.company:
                const company: Company = entity as Company;
                let companyDto: CompanyDto = Api1Converter.companyToDto(company);
                companyDto = company.isNew ? await this.companiesApiService.createCompany(companyDto) : await this.companiesApiService.updateCompany(company.identifier.businessIdentifier, companyDto, archive);
                return Api1Converter.dtoToCompany(companyDto);
            case EntityTypes.samplingPlan:
                const samplingPlan: SamplingPlan = entity as SamplingPlan;
                let samplingPlanDto: SamplingPlanDto = Api1Converter.samplingPlanToDto(samplingPlan);
                samplingPlanDto = samplingPlan.isNew ? await this.samplingsApiService.createSamplingPlan(samplingPlan.documentIdentifier, samplingPlanDto) : await this.samplingsApiService.updateSamplingPlan(samplingPlan.identifier.businessIdentifier, samplingPlanDto, archive);
                return Api1Converter.dtoToSamplingPlan(samplingPlanDto);
        }
        throw new AppException(FrontendErrors.FE37UnablePersistEntityTypeUnknown, $localize`:@@exception.fe37UnablePersistEntityTypeUnknown:The entity ${Identifier.identifierToString(entity.identifier)}:entityId: of type "${EntityTypes[entity.entityType]}:entityType:" cannot be saved to server because the type is not known.`);
    }
}
