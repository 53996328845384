import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

import { EventHelper } from "../../../../base/helpers/event-helper";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { Sample } from "../../../entities/samplings/sample";
import { AppIcons } from "../../../services/icons/app-icons";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { FormValidationErrorMessages } from "../form-validation-error/form-validation-error-messages";

/**
 *
 */
@Component({
    selector: "business-sample-form",
    templateUrl: "./sample-form.component.html",
    styleUrls: ["./sample-form.component.scss"]
})
export class SampleFormComponent implements OnInit {
    constructor(
        private readonly formBuilder: FormBuilder
    ) {
        this.nameControl = this.formData.get("name")!;
    }

    public appIcons: typeof AppIcons = AppIcons;

    @Input()
    public sample!: Sample;

    @Input()
    public readonly: boolean = false;

    @Input()
    public isValid: boolean = false;

    @Output()
    public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private formUpdateSubscription?: Subscription;

    private initializing: boolean = false;

    public validationMessages: FormValidationErrorMessages = new FormValidationErrorMessages(
        []
    );

    public formData: FormGroup = this.formBuilder.group({
        name: ["", []]
    });

    public nameControl: AbstractControl;

    public ngOnInit(): void {
        this.initialize();
        this.formUpdateSubscription = EventHelper.subscribe(this.formData.statusChanges, this.formChanged, this);
    }

    public ngOnDestroy(): void {
        this.formUpdateSubscription = EventHelper.unsubscribe(this.formUpdateSubscription);
    }

    private initialize(): void {
        this.initializing = true;
        this.sample = this.sample ?? CoreDataFactory.createSample();
        this.assignValues();
        this.initializing = false;
    }

    public assignValues(): void {
        this.assignIfChanged("name", this.sample.name);
    }

    private assignIfChanged(controlName: keyof Sample, value: any): void {
        const control: AbstractControl = this.formData.controls[controlName];
        if (control.value != value) {
            control.setValue(value);
        }
    }

    public updateValidState(): void {
        this.formData.markAllAsTouched();

        this.isValid = this.formData.status == "VALID";
        this.isValidChange.emit(this.isValid);
    }

    private formChanged(): void {
        if (this.initializing) {
            return;
        }

        this.sample.name = FormHelper.formValueToDataByProperty<Sample, string>(this.formData, "name") || undefined;

        this.updateValidState();
    }
}
