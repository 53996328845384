import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import {
    DefaultService, ResponseBodyDashboardProcessesDto
} from "../../../generated/api";
import { SessionService } from "../session/session.service";

/**
 * The dashboard api service to talk to the server backend.
 */
@Injectable({
    providedIn: "root"
})
export class DashboardsApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public async getProcessesDashboard(): Promise<ResponseBodyDashboardProcessesDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.getProcessDashboard(this.sessionService.activeAccountId));
    }
}
