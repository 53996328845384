import { DocumentQuestionTypes } from "../../entities/documents/document-question-types";

/**
 * Global system-wide settings for documents.
 */
export class DocumentSettings {
    public static isSimpleQuestion(questionType: DocumentQuestionTypes): boolean {
        switch (questionType) {
            case DocumentQuestionTypes.unknown:
            case DocumentQuestionTypes.singleImage:
                return true;
            default:
                return false;
        }
    }
}
