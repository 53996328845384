import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { OAuthEvent, OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthService, OAuthStorage } from "angular-oauth2-oidc";
import { catchError, filter, finalize, from, Observable, switchMap, take } from "rxjs";

/**
 * The main request interceptor to handle token refresh on 401 errors.
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private readonly authStorage: OAuthStorage,
        private readonly oauthService: OAuthService,
        private readonly errorHandler: OAuthResourceServerErrorHandler,
        @Optional() private readonly moduleConfig: OAuthModuleConfig
    ) {
    }

    private refreshTokenInProgress: boolean = false;

    private checkUrl(url: string): boolean {
        const found: any = this.moduleConfig?.resourceServer?.allowedUrls?.find((u: string) => url.startsWith(u));
        return !!found;
    }

    private getToken(): string|null {
        return this.oauthService.getAccessToken();
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedRequest: HttpRequest<any> = request;
        const url: string = request.url.toLowerCase();

        if (!this.moduleConfig) {
            return next.handle(request);
        }
        if (!this.moduleConfig.resourceServer) {
            return next.handle(request);
        }
        if (!this.moduleConfig.resourceServer.allowedUrls) {
            return next.handle(request);
        }
        if (!this.checkUrl(url)) {
            return next.handle(request);
        }

        const sendAccessToken: boolean = this.moduleConfig.resourceServer.sendAccessToken;

        if (sendAccessToken) {
            modifiedRequest = this.getAuthRequest(modifiedRequest);
        }

        return next.handle(modifiedRequest).pipe(catchError((error: any) => this.handleError(error, request, next)));
    }

    private getAuthRequest(request: HttpRequest<any>): HttpRequest<any> {
        const token: string|null = this.getToken();
        if (!token) {
            return request;
        }
        const headers: HttpHeaders = request.headers.set("Authorization", `Bearer ${token}`);
        return request.clone({ headers });
    }

    private handleError(error: HttpResponse<any>, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!error || error.status != HttpStatusCode.Unauthorized) {
            return this.errorHandler.handleError(error);
        }

        if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until token is silently refreshed
            // which means the new token is ready, and we can retry the request
            return this.oauthService.events.pipe(
                filter((result: OAuthEvent) => result.type === "silently_refreshed"),
                take(1),
                switchMap(() => next.handle(this.getAuthRequest(request))),
                catchError((innerError: any) => {
                    console.warn("Failed to wait for silently refreshed token.", innerError, error);
                    return this.errorHandler.handleError(error);
                })
            );
        } else {
            if (!this.hasRefreshToken() || !this.oauthService.hasValidAccessToken()) {
                this.refreshTokenInProgress = false;
                if (this.oauthService.getAccessToken() || this.oauthService.getRefreshToken()) {
                    this.oauthService.logOut();
                }
                return this.errorHandler.handleError(error);
            }

            this.refreshTokenInProgress = true;

            return from(this.oauthService.silentRefresh()).pipe(
                switchMap(() => next.handle(this.getAuthRequest(request))),
                catchError((innerError: any) => {
                    console.warn("Failed to refresh token.", innerError, error);
                    this.refreshTokenInProgress = false;
                    return this.errorHandler.handleError(error);
                }),
                // When the call to silentRefresh completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                finalize(() => this.refreshTokenInProgress = false)
            );
        }
    }

    private hasRefreshToken(): boolean {
        return !!this.oauthService.getRefreshToken();
    }
}
