import { Injectable } from "@angular/core";

import { TranslationDto } from "../../../generated/api";
import { AuditDocument } from "../../entities/documents/audit-document";
import { DocumentQuestion } from "../../entities/documents/document-question";
import { DocumentSection } from "../../entities/documents/document-section";
import { TranslationsApiService } from "../api/translations-api.service";
import { I18nTextService } from "../i18n/i18n-text.service";
import { SessionService } from "../session/session.service";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";
import { BackendTranslationUpdateSession } from "./backend-translation-update.session";
import { LocalizationService } from "./localization.service";

/**
 * The service to get the translations from the server.
 */
@Injectable({
    providedIn: "root"
})
export class BackendTranslationService {
    constructor(
        private readonly localizationService: LocalizationService,
        private readonly sessionService: SessionService,
        private readonly translationsApiService: TranslationsApiService,
        private readonly storageService: StorageService,
        private readonly i18nTextService: I18nTextService
    ) {
    }

    private translations: Map<string, string> = new Map<string, string>();

    private loadedFromStorage: boolean = false;

    private async updateTranslations(session: BackendTranslationUpdateSession): Promise<void> {
        if (session.requestToServerSent) {
            return;
        }
        session.requestToServerSent = true;

        const translations: Array<TranslationDto>|undefined = await this.translationsApiService.listTranslations(this.localizationService.localeId);
        for (const translation of translations?.values() ?? []) {
            if (translation.key) {
                this.translations.set(translation.key, translation.value ?? "");
            }
        }
        await this.saveToStorage(translations ?? []);
    }

    public async getTranslation(translationId: string|undefined, session: BackendTranslationUpdateSession): Promise<string|undefined> {
        if (!translationId) {
            return "";
        }

        if (!this.loadedFromStorage) {
            await this.loadFromStorage();
        }

        let translation: string|undefined = this.translations.get(translationId);
        if (translation === undefined) {
            await this.updateTranslations(session);
            translation = this.translations.get(translationId);
        }
        if (translation === undefined) {
            console.warn(`Translation for ${translationId} not found.`);
            return undefined;
        }
        return translation;
    }

    private async saveToStorage(translations: Array<TranslationDto>): Promise<void> {
        await this.storageService.setGroupItem(StorageKeys.groupTranslations, this.localizationService.localeId, translations);
    }

    private async loadFromStorage(): Promise<void> {
        const translations: Array<TranslationDto> = await this.storageService.getGroupItem(StorageKeys.groupTranslations, this.localizationService.localeId) ?? [];

        for (const translation of translations) {
            if (translation.key) {
                this.translations.set(translation.key, translation.value ?? "");
            }
        }

        this.loadedFromStorage = true;
    }

    public async translateDocument(document: AuditDocument): Promise<void> {
        const session: BackendTranslationUpdateSession = new BackendTranslationUpdateSession();

        const sections: Map<number, DocumentSection> = document.template.getAllSections();
        for (const section of sections.values()) {
            section.title = await this.getTranslation(section.title, session) ?? section.title;
        }

        const questions: Map<number, DocumentQuestion> = document.template.getAllQuestions();
        for (const question of questions.values()) {
            question.title = await this.getTranslation(question.title, session) ?? question.title;
            question.description = await this.getTranslation(question.description, session) ?? question.description;
            for (const answer of question.answers) {
                answer.label = await this.getTranslation(answer.label, session) ?? answer.label;
                for (const comment of answer.comments) {
                    comment.text = await this.getTranslation(comment.textKey, session) ?? comment.text;
                }

                if (answer.unit) {
                    answer.unitLabel = this.i18nTextService.getUnitPluralText(answer.unit);
                }
            }
        }

        // Translate repeatable questions
        const repeatableQuestions: Array<Array<DocumentSection>>|undefined = document.instance.getAllRepeatableQuestions();
        for (const repeatableQuestion of repeatableQuestions) {
            for (const repeatableQuestionInstance of repeatableQuestion) {
                repeatableQuestionInstance.title = await this.getTranslation(repeatableQuestionInstance.title, session) ?? repeatableQuestionInstance.title;
                for (const question of repeatableQuestionInstance.questions) {
                    question.title = await this.getTranslation(question.title, session) ?? question.title;
                }
            }
        }
    }
}
