import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { EntityBusinessIdentifier } from "../../identifiers/entity-business-identifier";
import { EntityTechnicalIdentifier } from "../../identifiers/entity-technical-identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { CoreDataEntity } from "../core-data/core-data-entity";
import { GenericCoreDataEntity } from "../core-data/generic-core-data-entity";
import { EntityLink } from "../documents/entity-link";
import { EntityTypes } from "../entity-types";
import { DashboardProcessesStorageDto } from "./dashboard-processes-storage-dto";

/**
 * Basic person-form class.
 */
export class DashboardProcesses extends CoreDataEntity<EntityBusinessIdentifier, EntityTechnicalIdentifier> {
    constructor(init?: Partial<DashboardProcesses>) {
        super(EntityTypes.dashboardProcesses);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public linkedEntities: Array<EntityLink<GenericCoreDataEntity>> = [];

    public get displayTitle(): string {
        return "Dashboard";
    }

    public get isNew(): boolean {
        return false;
    }

    protected toEntityStorageDto(): DashboardProcessesStorageDto {
        const dto: DashboardProcessesStorageDto = new DashboardProcessesStorageDto();
        for (const linkedEntity of this.linkedEntities) {
            dto.linkedEntities.push(linkedEntity.toStorageDto());
        }
        return dto;
    }

    protected fromEntityStorageDto(dto: DashboardProcessesStorageDto|undefined): void {
        this.linkedEntities = [];
        for (const linkedEntityDto of dto?.linkedEntities ?? []) {
            const linkedEntity: EntityLink<GenericCoreDataEntity>|undefined = CoreDataFactory.createEntityLinkFromStorageDto(linkedEntityDto);
            if (linkedEntity) {
                this.linkedEntities.push(linkedEntity);
            }
        }
    }

    public clone(): DashboardProcesses {
        return CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
    }
}
