import { Injectable } from "@angular/core";

import { CryptoHelper } from "../../../base/helpers/crypto-helper";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";

/**
 * Service to provide all device related functions.
 */
@Injectable({
    providedIn: "root"
})
export class DeviceService {
    constructor(
        private readonly storageService: StorageService
    ) {
    }

    private deviceIdentifierField?: string;
    public get deviceIdentifier(): string {
        return this.deviceIdentifierField!;
    }

    public async initialize(): Promise<void> {
        this.deviceIdentifierField = await this.storageService.get(StorageKeys.deviceId);
        if (!this.deviceIdentifierField) {
            this.deviceIdentifierField = CryptoHelper.getUUID();
            await this.storageService.set(StorageKeys.deviceId, this.deviceIdentifierField);
        }
    }
}
