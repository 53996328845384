import { Injectable, Injector, Type } from "@angular/core";

/**
 * The service to resolve instances from classes in the Angular IoC container.
 */
@Injectable({
    providedIn: "root"
})
export class IocService {
    constructor(
        private injector: Injector
    ) {
        IocService.instance = this;
    }

    public static instance: IocService;

    public resolve<TService>(service: Type<TService>): TService {
        return this.injector.get<TService>(service) as TService;
    }
}
