import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";

/**
 * The database entry for a local file.
 */
export class LocaleFileStorageItem {
    constructor(init?: Partial<LocaleFileStorageItem>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public blob?: Blob;
}
