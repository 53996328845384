<h1 mat-dialog-title>{{options?.title}}</h1>
<div mat-dialog-content>
    <h5 *ngIf="options?.subtitle">{{options?.subtitle}}</h5>
    <p *ngIf="options?.message" [innerHTML]="options?.message"></p>
</div>
<div mat-dialog-actions>
    <button
        (click)="onClick(button)"
        *ngFor="let button of options?.buttons"
        [attr.cdkFocusInitial]="button.isPrimaryButton ? '' : null"
        [class]="button.role"
        mat-button>
        <label *ngIf="button.text">{{button.text}}</label>
    </button>

    <button
        (click)="onClick()"
        *ngIf="!options?.buttons || options?.buttons?.length == 0"
        cdkFocusInitial
        mat-button>
        <label i18n="@@dialog.genericOk">OK</label>
    </button>
</div>
