import { Injectable } from "@angular/core";

import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { WebHelper } from "../../../base/helpers/web-helper";
import { RequestBodyMergeSamplingPlanDto, SamplingPlanDto } from "../../../generated/api";
import { LoadStrategies } from "../../common/load-strategies";
import { errorResult, SafeResult, successResult } from "../../common/safe-result";
import { Address } from "../../entities/core-data/address";
import { Building } from "../../entities/core-data/buildings/building";
import { BuildingComplex } from "../../entities/core-data/buildings/building-complex";
import { Floor } from "../../entities/core-data/buildings/floor";
import { Room } from "../../entities/core-data/buildings/room";
import { RoomFeature } from "../../entities/core-data/buildings/room-feature";
import { RoomFeatureMapping } from "../../entities/core-data/buildings/room-feature-mapping";
import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { AuditDocument } from "../../entities/documents/audit-document";
import { EntityLink } from "../../entities/documents/entity-link";
import { EntityTypes } from "../../entities/entity-types";
import { AppException } from "../../entities/exceptions/app-exception";
import { SampleMerge } from "../../entities/samplings/sample-merge";
import { SampleTypes } from "../../entities/samplings/sample-types";
import { SamplingPlan } from "../../entities/samplings/sampling-plan";
import { SamplingPlanMerge } from "../../entities/samplings/sampling-plan-merge";
import { FrontendErrors } from "../../global/frontend-errors";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { Identifier } from "../../identifiers/identifier";
import { SamplingsApiService } from "../api/samplings-api.service";
import { BuildingsService } from "../master-data/buildings/buildings.service";
import { CoreDataFactory } from "../master-data/core-data-factory";
import { CoreDataService } from "../master-data/core-data-service/core-data.service";
import { SampleError } from "./sample-error";

/**
 * The service to work with sampling plans and samplings.
 */
@Injectable({
    providedIn: "root"
})
export class SamplingsService {
    constructor(
        private readonly coreDataService: CoreDataService,
        private readonly buildingsService: BuildingsService,
        private readonly samplingsApiService: SamplingsApiService
    ) {
    }

    public async getSamplingPlan(documentIdentifier: DocumentIdentifier, loadStrategy: LoadStrategies): Promise<SafeResult<SamplingPlan, AppException>> {
        try {
            const samplingPlan: SamplingPlan|undefined = await this.coreDataService.loadEntity<SamplingPlan>(documentIdentifier, EntityTypes.samplingPlan, loadStrategy);
            if (!samplingPlan) {
                return errorResult(new AppException(FrontendErrors.FE52SamplingPlanDoesNotExistOrPermissionsMissing, $localize`:@@exception.fe52SamplingPlanDoesNotExistOrPermissionsMissing:The sampling plan for the document with the identifier ${Identifier.identifierToReadableString(documentIdentifier)}:identifier: does not exist or the permission required to view the project is missing.`));
            }
            return successResult(samplingPlan);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE53UnableToLoadSamplingPlan, $localize`:@@exception.fe53UnableToLoadSamplingPlan:Unable to load the specified sampling plan.`, error));
        }
    }

    public async getSamplingPlanMerge(document: AuditDocument, loadStrategy: LoadStrategies): Promise<SafeResult<SamplingPlanMerge, AppException>> {
        try {
            const samplingPlan: SamplingPlan|undefined = await this.coreDataService.loadEntity<SamplingPlan>(document.identifier, EntityTypes.samplingPlan, loadStrategy);
            if (!samplingPlan) {
                return errorResult(new AppException(FrontendErrors.FE52SamplingPlanDoesNotExistOrPermissionsMissing, $localize`:@@exception.fe52SamplingPlanDoesNotExistOrPermissionsMissing:The sampling plan for the document with the identifier ${Identifier.identifierToReadableString(document.identifier)}:identifier: does not exist or the permission required to view the project is missing.`));
            }

            const samplingPlanMerge: SamplingPlanMerge = await this.samplingPlanToSamplingPlanMerge(
                samplingPlan,
                document.linkedEntities.find((link: EntityLink<GenericCoreDataEntity>) => link.entity && link.entityType == EntityTypes.buildingComplex)!.entity as BuildingComplex,
                document.linkedEntities.filter((link: EntityLink<GenericCoreDataEntity>) => link.entity && link.entityType == EntityTypes.building).map((link: EntityLink<GenericCoreDataEntity>) => link.entity as Building));
            samplingPlanMerge.documentIdentifier = document.identifier;

            return successResult(samplingPlanMerge);
        } catch (error) {
            if (WebHelper.isNotFoundError(error)) {
                return successResult(CoreDataFactory.createSamplingPlanMerge({ documentIdentifier: document.identifier }));
            }
            return errorResult(new AppException(FrontendErrors.FE53UnableToLoadSamplingPlan, $localize`:@@exception.fe53UnableToLoadSamplingPlan:Unable to load the specified sampling plan.`, error));
        }
    }

    public async saveSamplingPlanMerge(samplingPlanMerge: SamplingPlanMerge): Promise<SafeResult<SamplingPlan, AppException>> {
        try {
            const samplingPlanMergeDto: RequestBodyMergeSamplingPlanDto = Api1Converter.samplingPlanMergeToDto(samplingPlanMerge);
            const samplingPlanDto: SamplingPlanDto = await this.samplingsApiService.updateSamplingPlanMerge(samplingPlanMerge.documentIdentifier.businessIdentifier, samplingPlanMergeDto);
            const samplingPlan: SamplingPlan = Api1Converter.dtoToSamplingPlan(samplingPlanDto);
            return successResult(samplingPlan);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE55UnableToSaveSamplingPlan, $localize`:@@exception.fe55UnableToSaveSamplingPlan:Unable to save the sampling plan.`, error));
        }
    }

    public async saveSamplingPlan(samplingPlan: SamplingPlan): Promise<SafeResult<SamplingPlan, AppException>> {
        try {
            const samplingPlanResult: SamplingPlan = await this.coreDataService.saveEntity(samplingPlan, true) as SamplingPlan;
            return successResult(samplingPlanResult);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE55UnableToSaveSamplingPlan, $localize`:@@exception.fe55UnableToSaveSamplingPlan:Unable to save the sampling plan.`, error));
        }
    }

    public async samplingPlanToSamplingPlanMerge(samplingPlan: SamplingPlan, buildingComplex: BuildingComplex, buildings: Array<Building>): Promise<SamplingPlanMerge> {
        const samplingPlanMerge: SamplingPlanMerge = CoreDataFactory.createSamplingPlanMerge({
            identifier: samplingPlan.identifier,
            documentIdentifier: samplingPlan.documentIdentifier,
            additionalInfo: samplingPlan.additionalInfo
        });

        let roomFeatures: Array<RoomFeature> = await this.buildingsService.getRoomFeatures();
        let roomFeaturesReloaded: boolean = false;

        for (const sample of samplingPlan.samples) {
            const building: Building|undefined = buildings.find((item: Building) => item.identifier.businessIdentifier == sample.buildingIdentifier?.businessIdentifier);
            const address: Address|undefined = building?.addresses.find((item: Address) => item.identifier.technicalIdentifier == sample.addressTechnicalIdentifier);
            const floor: Floor|undefined = building?.floors.find((item: Floor) => item.identifier.technicalIdentifier == sample.floorTechnicalIdentifier);
            const room: Room|undefined = floor?.rooms.find((item: Room) => item.identifier.technicalIdentifier == sample.roomTechnicalIdentifier);
            const roomFeatureMapping: RoomFeatureMapping|undefined = room?.roomFeaturesMapping.find((item: RoomFeatureMapping) => item.identifier.technicalIdentifier == sample.roomRoomFeatureMappingTechnicalIdentifier);
            let roomFeature: RoomFeature|undefined = undefined;
            if (sample.roomFeatureTechnicalIdentifier) {
                roomFeature = roomFeatures.find((item: RoomFeature) => item.identifier.technicalIdentifier == sample.roomFeatureTechnicalIdentifier);
                if (!roomFeature && !roomFeaturesReloaded) {
                    roomFeatures = await this.buildingsService.getRoomFeatures(true);
                    roomFeature = roomFeatures.find((item: RoomFeature) => item.identifier.technicalIdentifier == sample.roomFeatureTechnicalIdentifier);
                    roomFeaturesReloaded = true;
                }
            }
            const sampleMerge: SampleMerge = CoreDataFactory.createSampleMerge({
                identifier: sample.identifier,
                buildingIdentifier: building?.identifier,
                addressIdentifier: address?.identifier,
                name: sample.name,
                sampleType: sample.sampleType,
                street: address?.street,
                streetNumber: address?.streetNumber,
                floorNumber: floor?.floorNumber,
                roomLocation: room?.location,
                roomName: room?.name,
                roomFeatureName: roomFeature?.title,
                roomFeatureLocation: roomFeatureMapping?.name,
                legalReference: sample.legalReference
            });
            samplingPlanMerge.samples.push(sampleMerge);
        }

        return samplingPlanMerge;
    }

    public validateSamplingPlanMerge(samplingPlanMerge: SamplingPlanMerge): Array<SampleError<SampleMerge>> {
        const errors: Array<SampleError<SampleMerge>> = [];
        for (const sample of samplingPlanMerge.samples) {
            const error: SampleError<SampleMerge>|undefined = this.validateSampleMerge(sample);
            if (error) {
                errors.push(error);
            }
        }
        return errors;
    }

    public validateSamplingPlanMergeGetValidItems(samplingPlanMerge: SamplingPlanMerge): Array<SampleMerge> {
        const result: Array<SampleMerge> = [];
        for (const sample of samplingPlanMerge.samples) {
            const error: SampleError<SampleMerge>|undefined = this.validateSampleMerge(sample);
            if (!error) {
                result.push(sample);
            }
        }
        return result;
    }

    private validateSampleMerge(sample: SampleMerge): SampleError<SampleMerge>|undefined {
        if (sample.buildingIdentifier?.businessIdentifier == "#ORPHANED") {
            return new SampleError<SampleMerge>(sample, $localize`:@@sampling.error.orphanedSample:This sample does not have an owner. Please move it to a building or address.`);
        }
        if (!sample.street || !sample.streetNumber) {
            return new SampleError<SampleMerge>(sample, $localize`:@@sampling.error.noAddress:This sample does not have an address. Please be sure that a street and a street number is present in the building or address.`);
        }
        if (!sample.floorNumber) {
            return new SampleError<SampleMerge>(sample, $localize`:@@sampling.error.noFloorNumber:This sample does not have an floor number but is required. Please specify a number, an abbreviation like "GF", "FF", or "-" if it is not relevant.`);
        }
        if (!sample.roomName) {
            return new SampleError<SampleMerge>(sample, $localize`:@@sampling.error.noRoomName:This sample does not have a room name but is required. The room name can be something to help other people identify this room (e.g. Kitchen, Living Room, Workshop, Server room).`);
        }
        if (!sample.roomLocation) {
            return new SampleError<SampleMerge>(sample, $localize`:@@sampling.error.noRoomLocation:This sample does not have a room location but is required. The room location is a relative location information, e.g. 1//2 which means "First door on the left, and then second door on the left."`);
        }
        if (!sample.roomFeatureName) {
            return new SampleError<SampleMerge>(sample, $localize`:@@sampling.error.noRoomFeatureName:This sample does not have a feature selected. The feature is the part in the room that has to be sampled. Please select a possible feature from the list.`);
        }
        if (!sample.sampleType || sample.sampleType == SampleTypes.unknown) {
            return new SampleError<SampleMerge>(sample, $localize`:@@sampling.error.noSampleType:This sample does not have a sample type. The person taking the sample has to know which sample to take. Please select a possible value from the list.`);
        }
    }
}
