import { Component } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { AppService } from "../../../business/services/app/app.service";

/**
 * Component to show debug information.
 */
@Component({
    selector: "debug",
    templateUrl: "./debug.component.html",
    styleUrls: ["./debug.component.scss"]
})
export class DebugComponent {
    constructor(
        private readonly appService: AppService
    ) {
    }

    public isDebug: boolean = !environment.production || this.appService.appState.inspectionMode;
}
