<button (click)="clickReceived($event)"
        [class]="{
            'mat-stroked-button': buttonStyle == 'stroked' || disabled,
            'mat-raised-button': buttonStyle == 'raised' && !disabled,
            'mat-flat-button': buttonStyle == 'flat' && !disabled,
            'noPermission': !hasPermission,
            'noInternet': !hasInternet && requiresInternet,
            'mat-good': color == 'good',
            'mat-danger': color == 'danger',
            'mat-dark': color == 'dark'
        }"
        [color]="mappedColor"
        [disabled]="loading || disabled" mat-button>
    <ng-container *ngIf="icon">
        <fa-icon [icon]="loading && showLoadingAnimation ? appIcons.genericLoading : icon!" [spin]="loading && showLoadingAnimation"></fa-icon>
    </ng-container>

    <label>
        <ng-content></ng-content>
    </label>

    <ng-container *ngIf="!hasPermission">
        <fa-icon [icon]="appIcons.noPermission" class="restrictionIcon"></fa-icon>
    </ng-container>

    <ng-container *ngIf="!hasInternet && requiresInternet">
        <fa-icon [icon]="appIcons.noInternet" class="restrictionIcon"></fa-icon>
    </ng-container>
</button>
