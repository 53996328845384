import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { environment } from "../../../../../environments/environment";
import { BaseComponent } from "../../../../base/components/base-component";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { ExtendedValidators } from "../../../../base/validators/extended-validators";
import { Person } from "../../../entities/core-data/person";
import { Identifier } from "../../../identifiers/identifier";
import { AppIcons } from "../../../services/icons/app-icons";
import { PersonsService } from "../../../services/master-data/persons/persons.service";

/**
 * Component to view and to edit persons.
 */
@Component({
    selector: "business-person-form",
    templateUrl: "./person-form.component.html",
    styleUrls: ["./person-form.component.scss"]
})
export class PersonFormComponent extends BaseComponent {
    constructor(
        private readonly personsService: PersonsService,
        private readonly formBuilder: FormBuilder
    ) {
        super();
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly formHelper: typeof FormHelper = FormHelper;

    public loading: boolean = false;

    private personField!: Person;

    public formSettings: {
        maxLengthFirstName: number;
        maxLengthLastName: number;
        maxLengthTitle: number;
        maxLengthEmail: number;
        maxLengthPhone: number;
        maxLengthPhoneMobile: number;

    } = environment.formSettings.person;

    @Input()
    public readonly: boolean = false;

    @Input()
    public isValid: boolean = false;

    @Output()
    public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public firstnameControl: FormControl<string|null> = new FormControl<string|null>("", [Validators.required]);

    public lastnameControl: FormControl<string|null> = new FormControl<string|null>("", [Validators.required]);

    public titleControl: FormControl<string|null> = new FormControl<string|null>("");

    public emailControl: FormControl<string|null> = new FormControl<string|null>("", [ExtendedValidators.extendedEmailValidator]);

    public phoneControl: FormControl<string|null> = new FormControl<string|null>("");

    public phoneMobileControl: FormControl<string|null> = new FormControl<string|null>("");

    public genderControl: FormControl<string|null> = new FormControl<string|null>("");

    public formData: FormGroup = this.formBuilder.group([
        this.firstnameControl,
        this.lastnameControl,
        this.titleControl,
        this.emailControl,
        this.phoneControl,
        this.phoneMobileControl,
        this.genderControl
    ]);

    private initialized: boolean = false;

    public get person(): Person {
        return this.personField;
    }

    @Input()
    public set person(value: Person) {
        const personChanged: boolean = this.personField != value || !Identifier.isEqual(this.personField?.identifier, value?.identifier);
        this.personField = value;
        if (personChanged && this.initialized) {
            this.initialize();
        }
    }

    public componentInit(): void {
        this.initialize();
        this.subscribe(this.formData.statusChanges, this.formChanged);
    }

    public componentDestroy(): void {
        // Nothing to do right now
    }

    private initialize(): void {
        this.initialized = false;
        this.person = this.person ?? new Person();
        this.assignValues();

        this.initialized = true;
    }

    public assignValues(): void {
        FormHelper.assignIfChanged(this.firstnameControl, this.person.firstname);
        FormHelper.assignIfChanged(this.lastnameControl, this.person.lastname);
        FormHelper.assignIfChanged(this.titleControl, this.person.title);
        FormHelper.assignIfChanged(this.genderControl, this.person.gender ?? "unknown");
        FormHelper.assignIfChanged(this.emailControl, this.person.email);
        FormHelper.assignIfChanged(this.phoneControl, this.person.phone);
        FormHelper.assignIfChanged(this.phoneMobileControl, this.person.phoneMobile);
    }

    public getErrorMessage(formControl: FormControl): string|void {
        switch (true) {
            case formControl == this.emailControl && formControl.hasError("email"):
                return $localize`:@@entity.validation.emailFormat:The e-mail is not in the correct format (e.g. name@example.com).` as string;
            case formControl == this.emailControl && formControl.hasError("required"):
                return $localize`:@@entity.validation.emailRequired:The e-mail is required.` as string;
            case formControl == this.firstnameControl && formControl.hasError("required"):
                return $localize`:@@person.validation.firstnameRequired:The first name is required.` as string;
            case formControl == this.lastnameControl && formControl.hasError("required"):
                return $localize`:@@person.validation.lastnameRequired:The last name is required.` as string;
        }
    }

    public updateValidState(): void {
        this.formData.markAllAsTouched();

        this.isValid = this.formData.status == "VALID";
        this.isValidChange.emit(this.isValid);
    }

    private formChanged(): void {
        if (!this.initialized) {
            return;
        }

        this.person.firstname = FormHelper.formValueToData<string>(this.firstnameControl.value);
        this.person.lastname = FormHelper.formValueToData<string>(this.lastnameControl.value);
        this.person.title = FormHelper.formValueToData<string>(this.titleControl.value);
        this.person.gender = FormHelper.formValueToData<string>(this.genderControl.value);
        this.person.email = FormHelper.formValueToData<string>(this.emailControl.value);
        this.person.phone = FormHelper.formValueToData<string>(this.phoneControl.value);
        this.person.phoneMobile = FormHelper.formValueToData<string>(this.phoneMobileControl.value);

        this.isValid = this.formData.status == "VALID";
        this.isValidChange.emit(this.isValid);

        this.updateValidState();
    }
}
