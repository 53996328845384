import { Injectable } from "@angular/core";
import { GenericResponse, RecordingData, VoiceRecorder } from "capacitor-voice-recorder";

// Using plugin https://www.npmjs.com/package/capacitor-voice-recorder

/**
 * The service to interact with the microphone.
 */
@Injectable({
    providedIn: "root"
})
export class MicrophoneService {
    public isRecording: boolean = false;

    constructor() {
        // Do nothing for now
    }

    public async requestPermission(): Promise<boolean> {
        let response: GenericResponse = await VoiceRecorder.hasAudioRecordingPermission();
        if (response.value) {
            return true;
        }

        response = await VoiceRecorder.requestAudioRecordingPermission();
        if (!response.value) {
            return false;
        }

        response = await VoiceRecorder.hasAudioRecordingPermission();
        return response.value;
    }

    public async startRecording(): Promise<boolean> {
        if (!await this.requestPermission()) {
            return false;
        }

        const recording: GenericResponse = await VoiceRecorder.startRecording();
        if (!recording.value) {
            return false;
        }

        this.isRecording = true;

        return recording.value;
    }

    public stopRecording(): Promise<RecordingData> {
        return VoiceRecorder.stopRecording();
    }
}
