import { DialogService } from "../../../base/services/dialog/dialog.service";
import { IocService } from "../../../base/services/ioc/ioc.service";
import { InvitePersonComponent } from "../../components/invite-person/invite-person.component";
import { LoggedInIntent } from "../abstract/logged-in-intent";

/**
 * Intent to edit a person-form.
 */
export class InvitePersonIntent extends LoggedInIntent<InvitePersonIntent> {
    constructor(
        iocService: IocService) {
        super(iocService);
    }

    public async executeIntent(): Promise<boolean> {
        const dialogService: DialogService = this.iocService.resolve(DialogService);

        await dialogService.showModal({
            component: InvitePersonComponent,
            payload: { }
        });

        return true;
    }

    public cancel(): Promise<void> {
        return Promise.resolve();
    }
}
