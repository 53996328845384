import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { environment } from "../../../../environments/environment";
import {
    CompanyDto,
    DefaultService
} from "../../../generated/api";
import { EntityHelper } from "../../helpers/entity-helper";
import { CompanyBusinessIdentifier } from "../../identifiers/company-business-identifier";
import { CompanyIdentifier } from "../../identifiers/company-identifier";
import { SessionService } from "../session/session.service";

/**
 * The service to talk to the server backend.
 */
@Injectable({
    providedIn: "root"
})
export class CompaniesApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public createCompany(companyDto: CompanyDto): Promise<CompanyDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.createCompany(companyDto, this.sessionService.activeAccountId));
    }

    public updateCompany(companyBusinessId: CompanyBusinessIdentifier, companyDto: CompanyDto, _archive: boolean): Promise<CompanyDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.updateCompany(companyBusinessId, companyDto, this.sessionService.activeAccountId));
    }

    public getCompanies(searchQuery?: string, limit: number = environment.searchDefaultResultsCount): Promise<Array<CompanyDto>>|never {
        this.sessionService.requiresAccount();

        return lastValueFrom(this.defaultService.listCompanies(this.sessionService.activeAccountId, undefined, undefined, searchQuery, limit));
    }

    public async getCompany(companyIdentifier: CompanyIdentifier): Promise<CompanyDto|undefined>|never {
        this.sessionService.requiresAccount();

        const companies: Array<CompanyDto> = await lastValueFrom(this.defaultService.listCompanies(this.sessionService.activeAccountId, companyIdentifier.businessIdentifier || undefined, companyIdentifier.technicalIdentifier || undefined));
        if (companyIdentifier.technicalIdentifier) {
            return companies.find((dto: CompanyDto) => dto.technicalId == companyIdentifier.technicalIdentifier);
        } else {
            return EntityHelper.getLatestByCreatedAndUpdatedTime(companies);
        }
    }

    public async deleteCompany(businessIdentifier: CompanyBusinessIdentifier): Promise<void>|never {
        this.sessionService.requiresAccount();
        await lastValueFrom(this.defaultService.deleteCompany(businessIdentifier, this.sessionService.activeAccountId));
    }
}
