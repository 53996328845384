import { Type } from "@angular/core";

import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";

/**
 * Options class to pass the child components and options.
 */
export class DefaultDialogComponentOptions {
    constructor(title: string, child: { component: Type<any>; options?: any }, init?: Partial<DefaultDialogComponentOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.title = title;
        this.child = child;
    }

    public title: string;

    public subtitle?: string;

    public child: { component: Type<any>; options?: any };

    public padding: boolean = true;
}
