import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { environment } from "../../../../../environments/environment";
import { BaseComponent } from "../../../../base/components/base-component";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { IntentsService } from "../../../../base/services/intents/intents.service";
import { Company } from "../../../entities/core-data/company";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { Person } from "../../../entities/core-data/person";
import { EntityLink } from "../../../entities/documents/entity-link";
import { EntityTypes } from "../../../entities/entity-types";
import { Identifier } from "../../../identifiers/identifier";
import { SelectEntityIntent } from "../../../intents/master-data/select-entity-intent";
import { SelectEntityIntentOptions } from "../../../intents/master-data/select-entity-intent-options";
import { AppIcons } from "../../../services/icons/app-icons";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";

/**
 * A form to view or edit a company.
 */
@Component({
    selector: "business-company-form",
    templateUrl: "./company-form.component.html",
    styleUrls: ["./company-form.component.scss"]
})
export class CompanyFormComponent extends BaseComponent {
    constructor(
        private readonly intentsService: IntentsService,
        private readonly formBuilder: FormBuilder
    ) {
        super();
    }

    public appIcons: typeof AppIcons = AppIcons;

    public formHelper: typeof FormHelper = FormHelper;

    private companyField!: Company;

    public readonly formSettings: {
        maxLengthReference: number;
        maxLengthName: number;
    } = environment.formSettings.company;

    @Input()
    public readonly: boolean = false;

    @Input()
    public allowEntityLinking: boolean = true;

    @Input()
    public isValid: boolean = false;

    public isValidEmail: boolean = true;

    @Output()
    public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public externalReferenceEditable: boolean = false;

    public nameControl: FormControl = new FormControl("", [Validators.required]);

    public externalReferenceControl: FormControl = new FormControl("", []);

    public formData: FormGroup = this.formBuilder.group([this.nameControl, this.externalReferenceControl]);

    private initialized: boolean = false;

    public get company(): Company {
        return this.companyField;
    }

    @Input()
    public set company(value: Company) {
        const companyChanged: boolean = this.companyField != value || !Identifier.isEqual(this.companyField?.identifier, value?.identifier);
        this.companyField = value;
        if (companyChanged && this.initialized) {
            this.initialize();
        }
    }

    protected componentInit(): void {
        this.initialize();

        this.subscribe(this.formData.statusChanges, this.formChanged);
    }

    protected componentDestroy(): void {
        // Nothing to do right now
    }

    private initialize(): void {
        this.initialized = false;
        this.companyField = this.companyField ?? CoreDataFactory.createCompany();

        this.nameControl.setValue(this.companyField.name);
        this.externalReferenceControl.setValue(this.companyField.externalReference);

        this.externalReferenceEditable = !this.companyField.externalReference;
        this.initialized = true;
    }

    public getErrorMessage(formControl: FormControl): string|void {
        switch (true) {
            case formControl == this.nameControl && formControl.hasError("required"):
                return $localize`:@@company.validation.nameRequired:The name is required.` as string as string;
        }
    }

    public updateValidStateEmail(isValid: boolean): void {
        this.isValidEmail = isValid;
        this.updateValidState();
    }

    public updateValidState(): void {
        this.formData.markAllAsTouched();

        this.isValid = this.formData.status == "VALID" && this.isValidEmail;
        this.isValidChange.emit(this.isValid);
    }

    private formChanged(): void {
        if (!this.initialized) {
            return;
        }

        this.companyField.name = FormHelper.formValueToData<string>(this.nameControl.value);
        this.companyField.externalReference = FormHelper.formValueToData<string>(this.externalReferenceControl.value);

        this.updateValidState();
    }

    public async linkPerson(): Promise<void> {
        const options: SelectEntityIntentOptions = new SelectEntityIntentOptions(
            EntityTypes.person,
            this.companyField.name ? $localize`:@@company.linkPersonToCompanyWithNameDialogTitle:Link a person to company ${this.companyField.displayTitle}` as string : $localize`:@@company.linkPersonToCompanyDialogTitle:Link person to company` as string,
            { allowCreate: true });
        await this.intentsService.executeIntentAndWait(SelectEntityIntent, options);

        const persons: Array<Person>|undefined = options.resultEntities as Array<Person>;

        if (persons && persons.length > 0) {
            const person: Person = persons[0];
            const entityLink: EntityLink<Person> = CoreDataFactory.createEntityLinkFromEntity(person);
            this.companyField.personLinks.push(entityLink);
        }
        this.updateValidState();
    }

    public personLinksChanged(personLinks: Array<EntityLink<GenericCoreDataEntity>>): void {
        this.companyField.personLinks = personLinks as Array<EntityLink<Person>>;
        this.updateValidState();
    }

    public emailsChanged(emails: string): void {
        this.companyField.email = emails;
        this.updateValidState();
    }
}
