import { FinishedReceiver } from "./finished-receiver";

/**
 * Triggered event for {@link ProcessButtonComponent}.
 */
export class ProcessElementTriggeredEventArguments {
    constructor(finishedReceiver: FinishedReceiver, event: Event) {
        this.finishedReceiver = finishedReceiver;
        this.event = event;
    }

    public finishedReceiver: FinishedReceiver;

    public event: Event;
}
