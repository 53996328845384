import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

import { EventHelper } from "../../../base/helpers/event-helper";
import { AuthService } from "../../../base/services/auth/auth.service";
import { BuildingsService } from "../master-data/buildings/buildings.service";

/**
 * Service to prepare for offline functionality.
 * This service loads resources that could be used in the later app usage.
 */
@Injectable({
    providedIn: "root"
})
export class OfflineLoaderService {
    constructor(
        private readonly authService: AuthService,
        private readonly buildingsService: BuildingsService
    ) {
        this.authenticationChangedSubscription = EventHelper.subscribe(this.authService.isAuthenticatedChanged, this.authenticationChanged, this);
    }

    private authenticationChangedSubscription?: Subscription;

    private loaded: boolean = false;

    public async initialize(): Promise<void> {
        if (this.authService.isAuthenticated) {
            await this.preloadData();
        }
    }

    private async preloadData(): Promise<void> {
        if (this.loaded) {
            return;
        }

        this.loaded = true;
        this.authenticationChangedSubscription = EventHelper.unsubscribe(this.authenticationChangedSubscription);

        await Promise.all([
            this.cacheRoomFeatures()
        ]);
    }

    private async cacheRoomFeatures(): Promise<void> {
        try {
            await this.buildingsService.getRoomFeatures(true);
        } catch (error) {
            console.warn("Unable to pre-cache room features", error);
        }
    }

    private authenticationChanged(isAuthenticated: boolean): void {
        if (isAuthenticated) {
            this.preloadData().then();
        }
    }
}
