import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { RecordingData } from "capacitor-voice-recorder";

import { MicrophoneService } from "../../../base/services/devices/microphone.service";
import { AppIcons } from "../../services/icons/app-icons";

/**
 * The service to record and play audio recordings like audio notes.
 */
@Component({
    selector: "app-audio-recording",
    templateUrl: "./audio-recording.component.html",
    styleUrls: ["./audio-recording.component.scss"]
})
export class AudioRecordingComponent implements OnInit {
    public audioRecording?: RecordingData;
    public appIcons: typeof AppIcons = AppIcons;

    public get isRecording(): boolean {
        return this.microphoneService.isRecording;
    }

    constructor(
        private microphoneService: MicrophoneService,
        private modalController: ModalController
    ) {
        // Do nothing for now
    }

    public startRecording(): void {
        this.microphoneService.startRecording().then();
    }

    public async stopRecording(): Promise<void> {
        this.audioRecording = await this.microphoneService.stopRecording();
        console.info(`Recorded ${this.audioRecording.value.mimeType} with ${this.audioRecording.value.msDuration} ms.`);
    }

    public playRecording(): void {
        if (!this.audioRecording) {
            return;
        }

        const audioRef: HTMLAudioElement = new Audio(`data:${this.audioRecording.value.mimeType};base64,${this.audioRecording.value.recordDataBase64}`);
        audioRef.oncanplaythrough = (): Promise<void> => audioRef.play();
        audioRef.load();
    }

    public close(): void {
        this.modalController.dismiss().then();
    }

    public ngOnInit(): void {
        // Do nothing for now
    }
}
