import { HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";

import { DefaultService, PhotoDto, ResponseBodySuccessDto } from "../../../generated/api";
import { AttachmentBusinessIdentifier } from "../../identifiers/attachment-business-identifier";
import { AttachmentTechnicalIdentifier } from "../../identifiers/attachment-technical-identifier";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentTechnicalIdentifier } from "../../identifiers/document-technical-identifier";
import { QuestionBusinessIdentifier } from "../../identifiers/question-business-identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { SessionService } from "../session/session.service";

/**
 * Service to work with photos.
 */
@Injectable({
    providedIn: "root"
})
export class PhotoApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public createPhoto(blob: Blob, documentBusinessIdentifier: DocumentBusinessIdentifier|undefined, questionBusinessIdentifier: QuestionBusinessIdentifier|undefined, questionTemplateIdentifier: QuestionTemplateIdentifier|undefined): Observable<HttpEvent<PhotoDto>> {
        this.sessionService.requiresAccount();
        return this.defaultService.createPhoto(blob, this.sessionService.activeAccountId, documentBusinessIdentifier, questionBusinessIdentifier, questionTemplateIdentifier, "events", true);
    }

    public updatePhoto(businessIdentifier: AttachmentBusinessIdentifier, photoDto: PhotoDto): Promise<PhotoDto> {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.updatePhoto(businessIdentifier, photoDto, this.sessionService.activeAccountId));
    }

    public listPhotos(documentId?: DocumentTechnicalIdentifier): Promise<Array<PhotoDto>> {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listPhotos(this.sessionService.activeAccountId, documentId));
    }

    public getPhotoBinary(imageTechnicalIdentifier: AttachmentTechnicalIdentifier, width?: number, height?: number): Promise<Blob> {
        return lastValueFrom(this.defaultService.getPhotoBinary(imageTechnicalIdentifier, this.sessionService.activeAccountId, width, height));
    }

    public deletePhoto(businessIdentifier: AttachmentBusinessIdentifier): Promise<ResponseBodySuccessDto> {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.deletePhoto(businessIdentifier, this.sessionService.activeAccountId));
    }
}
