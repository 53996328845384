import { Inject, Injectable, LOCALE_ID } from "@angular/core";

/**
 * The service to manage most of the localization specific tasks.
 */
@Injectable({
    providedIn: "root"
})
export class LocalizationService {
    constructor(
    @Inject(LOCALE_ID) localeId: string
    ) {
        const parts: Array<string> = localeId.split("-");
        this.languageTwoLetters = parts[0];
        this.localeId = `${parts[0]}-${parts[1].toUpperCase()}`;
    }

    public readonly localeId: string;

    public readonly languageTwoLetters: string;
}
