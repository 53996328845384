import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { BasicDialogOptions } from "./basic-dialog-options";
import { DialogButton } from "./dialog-button";

/**
 * Basic dialog with title, message and buttons.
 */
@Component({
    selector: "app-basic-dialog",
    templateUrl: "./basic-dialog.component.html",
    styleUrls: ["./basic-dialog.component.scss"]
})
export class BasicDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<BasicDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public options: BasicDialogOptions|undefined
    ) {}

    public onClick(button?: DialogButton): void {
        this.dialogRef.close(button);
    }
}
