import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { CoreDataEntityStorageDto } from "../core-data-entity-storage-dto";

/**
 * Storage DTO for {@link RoomFeature}.
 */
export class RoomFeatureStorageDto extends CoreDataEntityStorageDto {
    constructor(init?: Partial<RoomFeatureStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public name?: string;

    public title?: string;

    public description?: string;

    public featureType?: string;
}
