import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { EntityIdentifierStorageDto } from "../../identifiers/entity-identifier-storage-dto";
import { CoreDataEntityStorageDto } from "../core-data/core-data-entity-storage-dto";
import { EntityLinkStorageDto } from "../documents/entity-link-storage-dto";

/**
 * Storage DTO for a {@link Process}.
 */
export class ProcessStorageDto extends CoreDataEntityStorageDto {
    constructor(init?: Partial<ProcessStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public processType?: string;

    public status?: string;

    public projectIdentifier?: EntityIdentifierStorageDto;

    public linkedAssignee?: EntityLinkStorageDto;

    public linkedEntities?: Array<EntityLinkStorageDto> = [];

    public linkedDocuments?: Array<EntityLinkStorageDto> = [];

    public targetDate?: string;

    public targetEndDate?: string;

    public additionalInfo?: string;
}
