import { Component, Input } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

/**
 * An info box that can be shown everywhere in the ui to inform or warn the user about a specific situation. It is displayed as a colored box with an optional title and an optional text.
 * The component can house custom content.
 */
@Component({
    selector: "base-info-box",
    templateUrl: "./info-box.component.html",
    styleUrls: ["./info-box.component.scss"]
})
export class InfoBoxComponent {
    @Input()
    public header?: string;

    @Input()
    public text?: string;

    @Input()
    public color?: string;

    @Input()
    public icon?: IconDefinition;

    @Input()
    public marginTop: boolean = false;

    @Input()
    public boxStyle: "regular"|"subtle"|"warning"|"danger" = "regular";
}
