import { DecimalPipe } from "@angular/common";

/**
 * Helper class for numbers.
 */
export class NumbersHelper {
    private static decimalPipe?: DecimalPipe;

    public static injectNumbersPipe(decimalPipe: DecimalPipe): void {
        this.decimalPipe = decimalPipe;
    }

    public static format(value: number, format: string): string {
        return this.decimalPipe?.transform(value, format) ?? `${value}`;
    }
}
