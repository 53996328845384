import { AuditDocumentConflict } from "src/app/business/entities/documents/audit-document-conflict";

import { Address } from "../../business/entities/core-data/address";
import { Building } from "../../business/entities/core-data/buildings/building";
import { BuildingComplex } from "../../business/entities/core-data/buildings/building-complex";
import { GenericCoreDataEntity } from "../../business/entities/core-data/generic-core-data-entity";
import { AuditDocument } from "../../business/entities/documents/audit-document";
import { AuditDocumentStorageDto } from "../../business/entities/documents/audit-document-storage-dto";
import { DocumentTemplate } from "../../business/entities/documents/document-template";
import { DocumentTypes } from "../../business/entities/documents/document-types";
import { EntityLink } from "../../business/entities/documents/entity-link";
import { EntityTypes } from "../../business/entities/entity-types";
import { AddressFormats } from "../../business/helpers/address-formats";
import { EntityHelper } from "../../business/helpers/entity-helper";
import { DocumentIdentifier } from "../../business/identifiers/document-identifier";
import { BuildingWaterWalkthroughInspectionDocumentStorageDto } from "./building-water-walkthrough-inspection-document-storage-dto";

/**
 * The concrete document for walkthrough inspections for buildings with focus on water (Begehungsbericht).
 */
export class BuildingWaterWalkthroughInspectionDocument extends AuditDocument {
    public constructor(identifier: DocumentIdentifier, documentTemplate: DocumentTemplate, init?: Partial<AuditDocument>) {
        super(identifier, documentTemplate, init);

        if (init) {
            Object.assign(this, init);
        }
    }

    public readonly type: DocumentTypes = DocumentTypes.buildingWaterWalkthroughInspection;

    public get shortCode(): string {
        return "BG";
    }

    public get displayTitle(): string {
        return this.documentTypeTitle;
    }

    public get displaySubTitle(): string {
        const buildingComplex: EntityLink<BuildingComplex>|undefined = this.linkedEntities.find((link: EntityLink<GenericCoreDataEntity>) => link.entityType == EntityTypes.buildingComplex && link.entity) as EntityLink<BuildingComplex>;
        const buildings: Array<EntityLink<Building>> = this.linkedEntities.filter((link: EntityLink<GenericCoreDataEntity>) => link.entityType == EntityTypes.building && link.entity) as Array<EntityLink<Building>>;
        const addresses: Array<Address> = [];
        for (const buildingLink of buildings) {
            const building: Building|undefined = buildingLink.entity as Building;
            if (buildingLink.entity?.addresses && buildingLink.entity.addresses.length > 0) {
                addresses.push(...building.addresses);
            }
        }
        const result: string|undefined = addresses.length > 0 ? EntityHelper.multipleAddressesToString(addresses, true, false, AddressFormats.streetStreetNumber) : undefined;
        return result || (buildingComplex?.entity?.addressRaw
            ? buildingComplex.entity.addressRaw
            : "");
    }

    public get documentTypeTitle(): string {
        return $localize`:@@document.typeTitleBuildingWaterWalkthroughInspection:Walkthrough Inspection`;
    }

    public toStorageDtoSpecificProperties(): AuditDocumentStorageDto {
        return new BuildingWaterWalkthroughInspectionDocumentStorageDto();
    }

    public fromStorageDtoSpecificProperties(_dto: AuditDocumentStorageDto): void {
        // const inspectionDto: BuildingWaterWalkthroughInspectionDocumentStorageDto = dto as BuildingWaterWalkthroughInspectionDocumentStorageDto;
    }

    protected checkDocumentUpdate(_updateDocument: AuditDocument): Array<AuditDocumentConflict> {
        return [];
    }
}
