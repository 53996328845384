import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { Attachment } from "../../../entities/attachments/attachment";
import { AttachmentFactory } from "../../../entities/attachments/attachment-factory";
import { DocumentAnswerValue } from "../../../entities/documents/document-answer-value";
import { DocumentTypes } from "../../../entities/documents/document-types";
import { CommentTemplateIdentifier } from "../../../identifiers/comment-template-identifier";
import { DocumentIdentifier } from "../../../identifiers/document-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { PersonBusinessIdentifier } from "../../../identifiers/person-business-identifier";
import { QuestionIdentifier } from "../../../identifiers/question-identifier";
import { QuestionTemplateIdentifier } from "../../../identifiers/question-template-identifier";
import { SectionIdentifier } from "../../../identifiers/section-identifier";
import { SectionTemplateIdentifier } from "../../../identifiers/section-template-identifier";
import { DocumentFactory } from "../../documents/document-factory";
import { DocumentUpdateTypes } from "../../documents/document-update-types";
import { DocumentQuestionUpdateStorageDto } from "./document-question-update-storage-dto";
import { PartialDocumentUpdate } from "./partial-document-update";
import { PartialDocumentUpdateStorageDto } from "./partial-document-update-storage-dto";

/**
 * Update for a single question.
 */
export class DocumentQuestionUpdate extends PartialDocumentUpdate {
    public constructor(accountIdentifier: number, personBusinessIdentifier: PersonBusinessIdentifier, documentId: DocumentIdentifier, documentType: DocumentTypes, init?: Partial<DocumentQuestionUpdate>) {
        super(accountIdentifier, personBusinessIdentifier, documentId, documentType, init);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public updateType: DocumentUpdateTypes = DocumentUpdateTypes.question;

    public allUpdatedExceptPendingAttachments: boolean = false;

    public sectionTemplateIdentifier?: SectionTemplateIdentifier;

    public sectionIdentifier?: SectionIdentifier;

    public questionTemplateIdentifier?: QuestionTemplateIdentifier;

    public questionIdentifier: QuestionIdentifier|undefined;

    public answers: Array<DocumentAnswerValue> = [];

    public linkedAttachments: Array<Attachment> = [];

    public commentTemplateIdentifier?: CommentTemplateIdentifier;

    public commentValue?: string;

    public enabled?: boolean;

    protected partialUpdateFromStorageDto(dto: DocumentQuestionUpdateStorageDto|undefined): void {
        this.sectionTemplateIdentifier = (dto?.sectionTemplateIdentifier ?? 0) as SectionTemplateIdentifier;
        this.sectionIdentifier = Identifier.create<SectionIdentifier>(dto?.sectionBusinessIdentifier, dto?.sectionTechnicalIdentifier);

        this.questionTemplateIdentifier = (dto?.questionTemplateIdentifier ?? 0) as QuestionTemplateIdentifier;
        this.questionIdentifier = Identifier.create<QuestionIdentifier>(dto?.questionBusinessIdentifier, dto?.questionTechnicalIdentifier);

        for (const answerDto of dto?.answers ?? []) {
            this.answers.push(DocumentFactory.createAnswerValueFromStorageDto(answerDto));
        }

        for (const attachmentDto of dto?.linkedAttachments ?? []) {
            this.linkedAttachments.push(AttachmentFactory.createFromStorageDto(attachmentDto));
        }

        this.commentTemplateIdentifier = (dto?.commentTemplateIdentifier ?? 0) as CommentTemplateIdentifier;
        this.commentValue = dto?.commentValue;
    }

    protected partialUpdateToStorageDto(): PartialDocumentUpdateStorageDto {
        const dto: DocumentQuestionUpdateStorageDto = new DocumentQuestionUpdateStorageDto({
            sectionTemplateIdentifier: this.sectionTemplateIdentifier,
            sectionBusinessIdentifier: this.sectionIdentifier?.businessIdentifier,
            sectionTechnicalIdentifier: this.sectionIdentifier?.technicalIdentifier,

            questionTemplateIdentifier: this.questionTemplateIdentifier,
            questionBusinessIdentifier: this.questionIdentifier?.businessIdentifier,
            questionTechnicalIdentifier: this.questionIdentifier?.technicalIdentifier,

            commentTemplateIdentifier: this.commentTemplateIdentifier,
            commentValue: this.commentValue,

            answers: [],
            linkedAttachments: []
        });

        for (const answer of this.answers) {
            dto.answers.push(answer.toStorageDto());
        }

        for (const attachment of this.linkedAttachments) {
            dto.linkedAttachments.push(attachment.toStorageDto());
        }

        return dto;
    }
}
