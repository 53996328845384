import { EntityTypes } from "../entities/entity-types";
import { UserPermissions } from "../global/user-permissions";

/**
 * Helper class to work with permissions.
 */
export class PermissionsHelper {
    public static canCreate(entityType: EntityTypes|undefined, activePermissions: Array<string>|undefined): boolean {
        const requiredPermission: Array<string> = this.permissionForCreate(entityType);
        if (requiredPermission.length <= 0) {
            return true;
        }
        for (const permission of requiredPermission) {
            if (!activePermissions?.includes(permission)) {
                return false;
            }
        }
        return true;
    }

    public static canUpdate(entityType: EntityTypes|undefined, activePermissions: Array<string>): boolean {
        const requiredPermission: Array<string> = this.permissionForUpdate(entityType);
        if (requiredPermission.length <= 0) {
            return true;
        }
        for (const permission of requiredPermission) {
            if (!activePermissions?.includes(permission)) {
                return false;
            }
        }
        return true;
    }

    public static canDelete(entityType: EntityTypes|undefined, activePermissions: Array<string>): boolean {
        const requiredPermission: Array<string> = this.permissionForDelete(entityType);
        if (requiredPermission.length <= 0) {
            return true;
        }
        for (const permission of requiredPermission) {
            if (!activePermissions?.includes(permission)) {
                return false;
            }
        }
        return true;
    }

    public static permissionForCreate(entityType: EntityTypes|undefined): Array<string> {
        switch (entityType) {
            case EntityTypes.buildingComplex:
                return [UserPermissions.buildingComplexCreate];
            case EntityTypes.building:
                return [UserPermissions.buildingCreate];
            case EntityTypes.project:
                return [UserPermissions.projectCreate];
            case EntityTypes.process:
                return [UserPermissions.processCreate];
            case EntityTypes.company:
                return [UserPermissions.companyCreate];
            case EntityTypes.person:
                return [UserPermissions.personCreate];
            case EntityTypes.image:
                return [UserPermissions.imageCreate];
        }
        return [];
    }

    public static permissionForUpdate(entityType: EntityTypes|undefined): Array<string> {
        switch (entityType) {
            case EntityTypes.buildingComplex:
                return [UserPermissions.buildingComplexUpdate];
            case EntityTypes.building:
                return [UserPermissions.buildingUpdate];
            case EntityTypes.project:
                return [UserPermissions.projectUpdate];
            case EntityTypes.process:
                return [UserPermissions.processUpdate];
            case EntityTypes.company:
                return [UserPermissions.companyUpdate];
            case EntityTypes.person:
                return [UserPermissions.personUpdate];
        }
        return [];
    }

    public static permissionForDelete(entityType: EntityTypes|undefined): Array<string> {
        switch (entityType) {
            case EntityTypes.buildingComplex:
                return [UserPermissions.buildingComplexDelete];
            case EntityTypes.building:
                return [UserPermissions.buildingDelete];
            case EntityTypes.project:
                return [UserPermissions.projectDelete];
            case EntityTypes.process:
                return [UserPermissions.processDelete];
            case EntityTypes.company:
                return [UserPermissions.companyDelete];
            case EntityTypes.person:
                return [UserPermissions.personDelete];
            case EntityTypes.image:
                return [UserPermissions.imageDelete];
        }
        return [];
    }
}
