<base-page-content-container>
    <cdk-virtual-scroll-viewport itemSize="40">
        <ion-list>
            <ion-item *cdkVirtualFor="let license of licenses">
                <ion-label>
                    <h3>{{license.name}}</h3>
                    <p>{{license.publisher}}</p>
                    <p><a [href]="license.licenseUrl" target="_blank">License: {{license.licenses}}</a></p>
                    <p *ngIf="license.repository"><a [href]="license.repository" target="_blank">{{license.repository}}</a></p>
                </ion-label>
            </ion-item>
        </ion-list>
    </cdk-virtual-scroll-viewport>
</base-page-content-container>
