import { Component, OnInit } from "@angular/core";
import { GalleryPhotos, Photo } from "@capacitor/camera";
import { ModalController } from "@ionic/angular";

import { CameraService } from "../../../base/services/devices/camera.service";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { AppIcons } from "../../services/icons/app-icons";
import { AudioRecordingComponent } from "../audio-recording/audio-recording.component";

/**
 * The component that allows to quickly add content to an audit or document.
 */
@Component({
    selector: "app-quick-add",
    templateUrl: "./quick-add.component.html",
    styleUrls: ["./quick-add.component.scss"]
})
export class QuickAddComponent implements OnInit {
    constructor(
        private readonly modalController: ModalController,
        private readonly cameraService: CameraService,
        private readonly dialogService: DialogService
    ) {
    }

    public appIcons: typeof AppIcons = AppIcons;

    public async takePhoto(): Promise<void> {
        const photo: Photo|undefined = await this.cameraService.takePhotoMethodA();
        console.info(photo);
    }

    public async pickPhotos(): Promise<void> {
        const photos: GalleryPhotos|undefined = await this.cameraService.getPhotosFromGallery();
        console.info(photos);
    }

    public async recordAudio(): Promise<void> {
        await this.dialogService.showModal({ component: AudioRecordingComponent });
    }

    public close(): void {
        this.modalController.dismiss().then();
    }

    public ngOnInit(): void {
        // Do nothing for now
    }
}
