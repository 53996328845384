import { InteractiveToastButton } from "./interactive-toast.button";

/**
 *  Options for the interactive toast component.
 */
export class InteractiveToastOptions {
    public title?: string;
    public message?: string;
    public buttons: Array<InteractiveToastButton> = [];

    constructor(title: string, message: string, buttons: Array<InteractiveToastButton>) {
        this.title = title;
        this.message = message;
        this.buttons = buttons;
    }
}
