import { Component } from "@angular/core";

import { AppIcons } from "../../../business/services/icons/app-icons";
import { AsyncHelper } from "../../helpers/async-helper";
import { ProcessElementTriggeredEventArguments } from "../../interfaces/process-element-triggered-event-arguments";
import { BaseComponent } from "../base-component";

/**
 * The Component for the share Dialog.
 */
@Component({
    selector: "base-share-url",
    templateUrl: "./share-url.component.html",
    styleUrls: ["./share-url.component.scss"]
})
export class ShareUrlComponent extends BaseComponent {
    public appIcons: typeof AppIcons = AppIcons;

    public url: string = window.location.href;

    public copied: boolean = false;

    public canShare: boolean = false;

    protected componentInit(): void {
        this.canShare = "share" in navigator;
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public async copyAnimation(): Promise<void> {
        if (this.copied) {
            return;
        }

        this.copied = true;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        await AsyncHelper.sleep(2500);
        this.copied = false;
    }

    public async share(triggeredEventArguments: ProcessElementTriggeredEventArguments): Promise<void> {
        try {
            await navigator.share({ url: this.url });
        } catch (error) {
            // Share has been cancelled - do nothing
        } finally {
            triggeredEventArguments.finishedReceiver.finished();
        }
    }
}

