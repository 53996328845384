import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../../entities/documents/entity-link";

/**
 * Table row for a single entity.
 */
export class EntityListTableRow {
    constructor(entityLink: EntityLink<GenericCoreDataEntity>) {
        this.entityLink = entityLink;
    }

    public entityLink: EntityLink<GenericCoreDataEntity>;

    public checked: boolean = false;

    public get title(): string {
        return this.entityLink.displayTitle;
    }
}
