import { DocumentSection } from "../../../entities/documents/document-section";
import { DocumentTypes } from "../../../entities/documents/document-types";
import { DocumentIdentifier } from "../../../identifiers/document-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { PersonBusinessIdentifier } from "../../../identifiers/person-business-identifier";
import { SectionIdentifier } from "../../../identifiers/section-identifier";
import { SectionTemplateIdentifier } from "../../../identifiers/section-template-identifier";
import { DocumentFactory } from "../../documents/document-factory";
import { DocumentUpdateTypes } from "../../documents/document-update-types";
import { DocumentRepeatableQuestionUpdateStorageDto } from "./document-repeatable-question-update-storage-dto";
import { PartialDocumentUpdate } from "./partial-document-update";
import { PartialDocumentUpdateStorageDto } from "./partial-document-update-storage-dto";

/**
 * Update for a repeatable question.
 */
export class DocumentRepeatableQuestionUpdate extends PartialDocumentUpdate {
    public constructor(accountIdentifier: number, personBusinessIdentifier: PersonBusinessIdentifier, documentId: DocumentIdentifier, documentType: DocumentTypes, sectionTemplateIdentifier: SectionTemplateIdentifier, sectionIdentifier: SectionIdentifier) {
        super(accountIdentifier, personBusinessIdentifier, documentId, documentType);

        this.sectionTemplateIdentifier = sectionTemplateIdentifier;
        this.sectionIdentifier = sectionIdentifier;
    }

    public readonly updateType: DocumentUpdateTypes = DocumentUpdateTypes.repeatableQuestion;

    public sectionTemplateIdentifier: SectionTemplateIdentifier;

    public sectionIdentifier: SectionIdentifier;

    public repeatableQuestion: Array<DocumentSection> = [];

    protected partialUpdateFromStorageDto(dto: DocumentRepeatableQuestionUpdateStorageDto|undefined): void {
        this.sectionTemplateIdentifier = (dto?.sectionTemplateIdentifier ?? 0) as SectionTemplateIdentifier;
        this.sectionIdentifier = Identifier.create(dto?.sectionBusinessIdentifier, dto?.sectionTechnicalIdentifier);
        for (const repeatableQuestionDto of dto?.repeatableQuestion ?? []) {
            const repeatableQuestion: DocumentSection = DocumentFactory.createSectionFromStorageDto(repeatableQuestionDto);
            this.repeatableQuestion.push(repeatableQuestion);
        }
    }

    protected partialUpdateToStorageDto(): PartialDocumentUpdateStorageDto {
        const dto: DocumentRepeatableQuestionUpdateStorageDto = new DocumentRepeatableQuestionUpdateStorageDto();
        dto.sectionTemplateIdentifier = this.sectionTemplateIdentifier;
        dto.sectionBusinessIdentifier = this.sectionIdentifier.businessIdentifier;
        dto.sectionTechnicalIdentifier = this.sectionIdentifier.technicalIdentifier;
        dto.repeatableQuestion = [];
        for (const repeatableQuestion of this.repeatableQuestion) {
            dto.repeatableQuestion.push(repeatableQuestion.toStorageDto());
        }
        return dto;
    }
}
