<ion-card [class.marginTop]="marginTop" [class]="{
            'subtle': boxStyle == 'subtle',
            'regular': boxStyle == 'regular',
            'warning': boxStyle == 'warning',
            'danger': boxStyle == 'danger'
          }"
          [color]="color">
    <ion-card-header>
        <ion-card-title *ngIf="header">
            <ng-container *ngIf="icon">
                <fa-icon [icon]="icon"></fa-icon>&nbsp;
            </ng-container>
            {{header}}
        </ion-card-title>
        <ion-card-subtitle *ngIf="text">
            {{text}}
        </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
        <ng-content></ng-content>
    </ion-card-content>
</ion-card>
