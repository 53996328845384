import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { PersonBusinessIdentifier } from "../../identifiers/person-business-identifier";
import { PersonTechnicalIdentifier } from "../../identifiers/person-technical-identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { EntityTypes } from "../entity-types";
import { StorableEntity } from "../storable-entity";
import { Address } from "./address";
import { CoreDataEntity } from "./core-data-entity";
import { PersonStorageDto } from "./person-storage-dto";

/**
 * Basic person-form class.
 */
export class Person extends CoreDataEntity<PersonBusinessIdentifier, PersonTechnicalIdentifier> implements StorableEntity<PersonStorageDto> {
    constructor(init?: Partial<Person>) {
        super(EntityTypes.person);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public created?: string;

    public updated?: string;

    public gender?: string;

    public title?: string;

    public firstname?: string;

    public lastname?: string;

    public email?: string;

    public phone?: string;

    public phoneMobile?: string;

    public status?: string;

    public address?: Address;

    public get displayTitle(): string {
        if (this.firstname && this.lastname) {
            return $localize`:@@generic.fullNameInCode:${this.firstname} ${this.lastname}`;
        }
        return (this.firstname ? this.firstname : this.lastname) ?? "";
    }

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    protected toEntityStorageDto(): PersonStorageDto {
        return new PersonStorageDto({
            gender: this.gender,
            title: this.title,
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            phone: this.phone,
            phoneMobile: this.phoneMobile,
            status: this.status,

            address: this.address?.toStorageDto()
        });
    }

    protected fromEntityStorageDto(dto: PersonStorageDto|undefined): void {
        this.gender = dto?.gender;
        this.title = dto?.title;
        this.firstname = dto?.firstname;
        this.lastname = dto?.lastname;
        this.email = dto?.email;
        this.phone = dto?.phone;
        this.phoneMobile = dto?.phoneMobile;
        this.status = dto?.status;

        this.address = dto?.address ? new Address() : undefined;
        this.address?.fromStorageDto(dto?.address);
    }

    public clone(): Person {
        return CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
    }
}
