<ion-header>
    <ion-toolbar>
        <ion-title i18n="@@quickAdd.title">Quick add content</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-button (click)="takePhoto()"><fa-icon [icon]="appIcons.camera"></fa-icon>&nbsp;<ng-container i18n="@@devices.takePhotoButton">Take photo</ng-container></ion-button>
    <ion-button (click)="pickPhotos()"><fa-icon [icon]="appIcons.photoGallery"></fa-icon>&nbsp;<ng-container i18n="@@devices.selectPhotoFromGalleryButton">Select photo from gallery</ng-container></ion-button>
    <ion-button (click)="recordAudio()"><fa-icon [icon]="appIcons.audioRecordingMic"></fa-icon>&nbsp;<ng-container i18n="@@devices.recordAudioMemoButton">Record audio memo</ng-container></ion-button>
    <ion-button><fa-icon [icon]="appIcons.qrCode"></fa-icon>&nbsp;<ng-container i18n="@@header.scanQrCode">Scan QR code</ng-container></ion-button>
</ion-content>
<ion-footer>
    <ion-buttons>
        <ion-button (click)="close()" i18n="@@dialog.genericCloseButton">Close</ion-button>
    </ion-buttons>
</ion-footer>
