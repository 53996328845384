import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { WebHelper } from "../../../base/helpers/web-helper";
import { ResponseBodyListWalkthroughInspectionsDto, WalkthroughInspectionDto } from "../../../generated/api";
import { SafeResult } from "../../common/safe-result";
import { AuditDocument } from "../../entities/documents/audit-document";
import { AppException } from "../../entities/exceptions/app-exception";
import { QualityAssuranceItem } from "../../entities/quality-assurance/quality-assurance-item";
import { EntityHelper } from "../../helpers/entity-helper";
import { DocumentsApiService } from "../api/documents-api.service";
import { QualityAssuranceService } from "../quality-assurance/quality-assurance.service";
import { DocumentsService } from "./documents.service";

/**
 * Updater for existing documents.
 * This updater is responsible for updating documents that have been modified on the server.
 */
export class DocumentBackgroundUpdater {
    constructor(
        private readonly documentService: DocumentsService,
        private readonly qualityAssuranceService: QualityAssuranceService,
        private readonly documentApiService: DocumentsApiService
    ) {
    }

    public checkForUpdates(document: AuditDocument): Promise<ResponseBodyListWalkthroughInspectionsDto|undefined> {
        return this.getUpdatedDocumentIfNewer(document);
    }

    private async getUpdatedDocumentIfNewer(document: AuditDocument): Promise<ResponseBodyListWalkthroughInspectionsDto|undefined> {
        // Spawn QA update in parallel
        this.updateQualityAssurance(document).then();

        const createdTime: number = document.created ? Date.parse(document.created) : 0;
        const updatedTime: number = document.updated ? Date.parse(document.updated) : 0;

        // TODO: Should be replaced with update-endpoint if available, see https://cyancor.atlassian.net/browse/ADT-8058
        let inspectionsDto: ResponseBodyListWalkthroughInspectionsDto;

        try {
            inspectionsDto = await this.documentApiService.getWalkthroughInspection(document.identifier.businessIdentifier);
        } catch (error) {
            if (WebHelper.isNoInternetError(error)) {
                return;
            }
            console.error(error);
            return;
        }
        if (!inspectionsDto.walkthroughInspections || inspectionsDto.walkthroughInspections.length <= 0) {
            return undefined;
        }

        const documentDto: WalkthroughInspectionDto = EntityHelper.getLatestByCreatedAndUpdatedTime(inspectionsDto.walkthroughInspections) ?? inspectionsDto.walkthroughInspections[0];
        const remoteCreatedTime: number = documentDto.created ? Date.parse(documentDto.created) : 0;
        const remoteUpdatedTime: number = documentDto.updated ? Date.parse(documentDto.updated) : 0;
        if (remoteCreatedTime > createdTime || remoteUpdatedTime > updatedTime || documentDto.technicalId != document.identifier.technicalIdentifier) {
            inspectionsDto.walkthroughInspections = [documentDto];
            return inspectionsDto;
        }

        return undefined;
    }

    private async updateQualityAssurance(document: AuditDocument): Promise<void> {
        const reviewData: SafeResult<Array<QualityAssuranceItem>, AppException> = await this.qualityAssuranceService.getItems(document.identifier);
        if (reviewData.isSuccess()) {
            const oldItems: Array<QualityAssuranceItem> = document.qualityAssuranceItems;
            const newItems: Array<QualityAssuranceItem> = reviewData.result ?? [];
            if (!TypeScriptHelper.objectsEqual(oldItems, newItems)) {
                document.qualityAssuranceItems = newItems;
                await this.documentService.applyUpdatedQualityAssuranceItems(document, newItems);
            }
        }
    }
}
