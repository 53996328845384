import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";

/**
 * Options for {@link SyncErrorListComponent}.
 */
export class SyncErrorListOptions {
    constructor(init?: Partial<SyncErrorListOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }
}
