import { IocService } from "../../../base/services/ioc/ioc.service";
import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { CoreDataListOptions } from "../../pages/core-data/core-data-list/core-data-list-options";
import { CoreDataListPage } from "../../pages/core-data/core-data-list/core-data-list-page.component";
import { LoggedInIntent } from "../abstract/logged-in-intent";
import { SelectEntityIntentOptions } from "./select-entity-intent-options";

/**
 * Intent to select a core entity.
 */
export class SelectEntityIntent extends LoggedInIntent<SelectEntityIntent> {
    constructor(iocService: IocService) {
        super(iocService);
    }

    public async executeIntent(intentOptions?: SelectEntityIntentOptions): Promise<boolean> {
        if (!intentOptions) {
            return true;
        }

        const options: CoreDataListOptions = new CoreDataListOptions({
            entityType: intentOptions.entityType,
            allowCreate: intentOptions.allowCreate,
            dialogMode: true,
            selectMode: true,
            auditorsOnly: intentOptions.auditorsOnly
        });
        const entities: Array<GenericCoreDataEntity>|undefined = await this.dialogService.showDefaultModal(intentOptions.title, {
            component: CoreDataListPage,
            options: options
        });

        intentOptions.resultEntities = entities ?? [];
        return true;
    }

    public async cancel(): Promise<void> {
        // Do nothing
    }

}
