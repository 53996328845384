import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { TypeScriptHelper } from "../../helpers/type-script-helper";

/**
 * A single context menu item.
 */
export class ContextMenuItem {
    constructor(title: string, init?: Partial<ContextMenuItem>) {
        TypeScriptHelper.initObjectFromPartial(this, init);

        this.title = title;
    }

    public icon?: IconDefinition;

    public title: string;

    public isLabel: boolean = false;

    public isFaded: boolean = false;

    public isDivider: boolean = false;

    public hasCheckbox: boolean = false;

    public isChecked: boolean = false;

    public tag?: any;
}
