import { ChatMessage } from "./chat-message";

/**
 * Factory for all communication related classes, like chat messages.
 */
export class CommunicationFactory {
    public static createChatMessage(text: string, init?: Partial<ChatMessage>): ChatMessage {
        return new ChatMessage(text, init);
    }
}
