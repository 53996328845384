import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { CoreDataEntityStorageDto } from "../core-data/core-data-entity-storage-dto";
import { SampleStorageDto } from "./sample-storage-dto";

/**
 * Storage DTO for {@link SamplingPlan}.
 */
export class SamplingPlanStorageDto extends CoreDataEntityStorageDto {
    constructor(init?: Partial<SamplingPlanStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public documentBusinessId?: string;

    public documentTechnicalId?: number;

    public samples?: Array<SampleStorageDto>;

    public additionalInfo?: string;
}
