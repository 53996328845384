/**
 * Storage DTO for master data.
 */
export abstract class CoreDataEntityStorageDto {
    public abstract readonly currentStorageVersion: number;

    public abstract storageVersion: number;

    public entityType?: string;

    public businessId?: string;

    public technicalId?: number;

    public extRef?: string;

    public created?: string;

    public updated?: string;

    public updatedClient?: string;
}
