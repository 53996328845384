import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { DocumentSectionStorageDto } from "../../../entities/documents/document-section-storage-dto";
import { PartialDocumentUpdateStorageDto } from "./partial-document-update-storage-dto";

/**
 * Storage DTO for {@link DocumentRepeatableQuestionUpdate}.
 */
export class DocumentRepeatableQuestionUpdateStorageDto extends PartialDocumentUpdateStorageDto {
    constructor(init?: Partial<DocumentRepeatableQuestionUpdateStorageDto>) {
        super(init);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public sectionTemplateIdentifier?: number;
    public sectionBusinessIdentifier?: string;
    public sectionTechnicalIdentifier?: number;

    public repeatableQuestion?: Array<DocumentSectionStorageDto> = [];
}
