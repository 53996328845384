import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { EntityBusinessIdentifier } from "../../../identifiers/entity-business-identifier";
import { EntityTechnicalIdentifier } from "../../../identifiers/entity-technical-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { EntityTypes } from "../../entity-types";
import { CoreDataEntity } from "../core-data-entity";
import { RoomFeatureStorageDto } from "./room-feature-storage-dto";

/**
 * Room features to be added to a specific room.
 */
export class RoomFeature extends CoreDataEntity<EntityBusinessIdentifier, EntityTechnicalIdentifier> {
    constructor(init?: Partial<RoomFeature>) {
        super(EntityTypes.roomFeature);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public name?: string;

    public title?: string;

    public description?: string;

    public featureType?: string;

    public get displayTitle(): string {
        return this.title || this.name || Identifier.identifierToReadableString(this.identifier);
    }

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    protected toEntityStorageDto(): RoomFeatureStorageDto {
        return new RoomFeatureStorageDto({
            name: this.name,
            title: this.title,
            description: this.description,
            featureType: this.featureType
        });
    }

    protected fromEntityStorageDto(dto: RoomFeatureStorageDto|undefined): void {
        this.name = dto?.name;
        this.title = dto?.title;
        this.description = dto?.description;
        this.featureType = dto?.featureType;
    }

    public clone(): RoomFeature {
        return CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
    }
}
