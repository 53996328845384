import { DocumentQuestionStorageDto } from "./document-question-storage-dto";

/**
 * Section DTO for storage.
 */
export class DocumentSectionStorageDto {
    public businessId?: string;
    public technicalId?: number;
    public templateId?: number;
    public parentTemplateId?: number;

    public title?: string;
    public icon?: string;

    public repeatable?: boolean;
    public enabled?: boolean;
    public enablable?: boolean;

    public sections: Array<DocumentSectionStorageDto> = [];
    public questions: Array<DocumentQuestionStorageDto> = [];
}
