import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { EntityIdentifier } from "../../../identifiers/entity-identifier";
import { EntityTechnicalIdentifier } from "../../../identifiers/entity-technical-identifier";
import { Identifier } from "../../../identifiers/identifier";

/**
 * Mapping for room to room features.
 */
export class RoomFeatureMapping {
    constructor(init?: Partial<RoomFeatureMapping>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public identifier: EntityIdentifier = Identifier.empty();
    public created?: string;
    public updated?: string;
    public name?: string;
    public roomFeatureTechnicalIdentifier?: EntityTechnicalIdentifier;
    public order?: number;
}
