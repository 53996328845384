import { Injectable } from "@angular/core";

import { environment } from "../../../../../environments/environment";
import { Api1Converter } from "../../../../api/v1/converters/api1-converter";
import { PersonDto } from "../../../../generated/api";
import { LoadStrategies } from "../../../common/load-strategies";
import { errorResult, SafeResult, successResult } from "../../../common/safe-result";
import { Person } from "../../../entities/core-data/person";
import { EntityTypes } from "../../../entities/entity-types";
import { AppException } from "../../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../../global/frontend-errors";
import { Identifier } from "../../../identifiers/identifier";
import { PersonBusinessIdentifier } from "../../../identifiers/person-business-identifier";
import { PersonIdentifier } from "../../../identifiers/person-identifier";
import { PersonsApiService } from "../../api/persons-api.service";
import { SessionService } from "../../session/session.service";
import { StorageService } from "../../storage/storage.service";
import { StorageKeys } from "../../storage/storage-keys";
import { CoreDataService } from "../core-data-service/core-data.service";

/**
 * Service to work with persons.
 */
@Injectable({
    providedIn: "root"
})
export class PersonsService {
    constructor(
        private readonly personsApiService: PersonsApiService,
        private readonly coreDataService: CoreDataService,
        private readonly sessionService: SessionService,
        private readonly storageService: StorageService
    ) {
    }

    public async getCoreDataMe(): Promise<Person|undefined> {
        const meId: PersonBusinessIdentifier = this.sessionService.activePersonBusinessId;
        if (meId) {
            return this.getPerson(Identifier.create(meId));
        }
        return this.getMe();
    }

    public async getMe(): Promise<Person|undefined>|never {
        const personDto: PersonDto|undefined = await this.personsApiService.getMe();
        if (!personDto) {
            throw new AppException(FrontendErrors.FE13UnableToFetchPersons, $localize`:@@exception.fe13UnableToFetchPersons:Unable to get persons from server. Please try again.`);
        }

        const person: Person = Api1Converter.dtoToPerson(personDto);
        await this.coreDataService.updateEntityCache(person);

        return Api1Converter.dtoToPerson(personDto);
    }

    public async getAllPersons(): Promise<Array<Person>>|never {
        const personDtos: Array<PersonDto>|undefined = await this.personsApiService.listPersons();
        if (!personDtos) {
            throw new AppException(FrontendErrors.FE13UnableToFetchPersons, $localize`:@@exception.fe13UnableToFetchPersons:Unable to get persons from server. Please try again.`);
        }

        const result: Array<Person> = [];
        for (const personDto of personDtos) {
            const person: Person = Api1Converter.dtoToPerson(personDto);
            await this.coreDataService.updateEntityCache(person);
            result.push(person);
        }

        return result;
    }

    public async getAllAuditors(loadStrategy: LoadStrategies): Promise<Array<Person>>|never {
        let personDtos: Array<PersonDto>|undefined;
        let updateCache: boolean = false;
        if (loadStrategy == LoadStrategies.cachedOnly || loadStrategy == LoadStrategies.preferCached) {
            personDtos = await this.storageService.get(StorageKeys.rawAuditors) as Array<PersonDto>;
        }

        if (loadStrategy != LoadStrategies.cachedOnly && (!personDtos || personDtos.length <= 0)) {
            personDtos = await this.personsApiService.listAuditors();
            if (personDtos) {
                await this.storageService.set(StorageKeys.rawAuditors, personDtos);
                updateCache = true;
            } else {
                if (loadStrategy == LoadStrategies.preferServer) {
                    // Try to load the cached ones
                    personDtos = await this.storageService.get(StorageKeys.rawAuditors) as Array<PersonDto>;
                }
                if (!personDtos || personDtos.length <= 0) {
                    throw new AppException(FrontendErrors.FE13UnableToFetchPersons, $localize`:@@exception.fe13UnableToFetchPersons:Unable to get persons from server. Please try again.`);
                }
            }
        }

        const result: Array<Person> = [];
        for (const personDto of personDtos ?? []) {
            const person: Person = Api1Converter.dtoToPerson(personDto);
            if (updateCache) {
                await this.coreDataService.updateEntityCache(person);
            }
            result.push(person);
        }

        return result;
    }

    public async getPerson(personIdentifier: PersonIdentifier): Promise<Person>|never {
        let person: Person|undefined = await this.coreDataService.loadEntity(personIdentifier, EntityTypes.person) as Person;
        if (person) {
            return person;
        }

        const personDto: PersonDto|undefined = await this.personsApiService.getPerson(personIdentifier);
        if (!personDto) {
            throw new AppException(FrontendErrors.FE31PersonNotFound, $localize`:@@exception.fe31PersonNotFound:The person with the identifier "${Identifier.identifierToString(personIdentifier)}:identifier:" cannot be found.`);
        }

        person = Api1Converter.dtoToPerson(personDto);
        await this.coreDataService.updateEntityCache(person);
        return person;
    }

    public async getPersons(searchQuery?: string, limit: number = environment.searchDefaultResultsCount): Promise<Array<Person>>|never {
        const personDtos: Array<PersonDto> = await this.personsApiService.getPersons(searchQuery, limit);
        const result: Array<Person> = [];
        for (const personDto of personDtos) {
            const person: Person = Api1Converter.dtoToPerson(personDto);
            await this.coreDataService.updateEntityCache(person);
            result.push(person);
        }
        return result;
    }

    public async invitePerson(email: string): Promise<void> {
        await this.personsApiService.invitePerson({ email: email });
    }

    public async deletePerson(businessIdentifier: PersonBusinessIdentifier): Promise<SafeResult<void, AppException>> {
        try {
            await this.personsApiService.deletePerson(businessIdentifier);
            return successResult(undefined);
        } catch (error) {
            return error instanceof AppException ? errorResult(error) : errorResult(new AppException(FrontendErrors.FE61UnableToDeletePerson, $localize`:@@exception.fe61UnableToDeletePerson:Unable to delete person with identifier "${businessIdentifier}".`, error));
        }
    }
}
