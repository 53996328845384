import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { AppException } from "../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../global/frontend-errors";
import { OnlineHelpService } from "../../services/help/online-help.service";
import { AppIcons } from "../../services/icons/app-icons";

/**
 * Component to show an error.
 */
@Component({
    selector: "business-error",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"]
})
export class ErrorComponent implements OnInit {
    constructor(
        private readonly onlineHelpService: OnlineHelpService
    ) {
        // Do nothing for now
    }

    @Input()
    public error?: any;

    public appException?: AppException;

    public appIcons: typeof AppIcons = AppIcons;

    public developerInfo?: string;

    public async ngOnInit(): Promise<void> {
        if (this.error instanceof AppException) {
            this.appException = this.error;
        } else {
            await this.processCustomErrors();
        }

        this.extractDeveloperInfo();
    }

    private extractDeveloperInfo(): void {
        if (environment.production) {
            return;
        }

        if (this.error?.stack) {
            this.developerInfo = typeof this.error.stack == "object" ? JSON.stringify(this.error.stack) : `${this.error.stack}`;
        }
    }

    private async processCustomErrors(): Promise<void> {
        try {
            if (this.error instanceof HttpErrorResponse && this.error.error instanceof Blob && this.error.error.type == "application/json") {
                // Blob endpoint errors are interpreted as blob as well. Do some magic to unfold the blob JSON
                const json: string = await (new Response(this.error.error)).text();
                const parsedError: any = JSON.parse(json) as unknown;
                this.appException = new AppException(FrontendErrors.FE41HttpError, this.error.message, this.error, parsedError);
                this.error = this.appException;
            }
        } catch (error) {
            console.error(error);
        }
    }

    public copyToClipboard(): void {
        throw new Error("TODO: not yet supported");
    }

    public searchSolution(errorCode: string): void {
        this.onlineHelpService.searchSolution(errorCode);
    }
}
