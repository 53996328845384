<ion-list>
    <ion-item (click)="installPwa()" *ngIf="!appState.pwaInstalled" class="clickable" lines="full">
        <fa-icon [icon]="appIcons.installApp" class="danger" slot="start"></fa-icon>
        <ion-label class="ion-text-wrap" color="danger" i18n="@@cloudStatus.appNotInstalled">App is not installed. Offline support is not possible.<br />Click here to install.</ion-label>
    </ion-item>

    <ion-item (click)="showSyncQueue($event)" *ngIf="synchronizationSummary && synchronizationSummary.error" class="clickable" lines="full">
        <fa-icon [icon]="appIcons.synchronizationError" class="danger" slot="start"></fa-icon>
        <ion-label class="ion-text-wrap" color="danger" i18n="@@cloudStatus.errors">{synchronizationSummary.error, plural, =0 {There are no errors.} =1 {There is one error that needs your attention.} other {There are {{synchronizationSummary.error}} errors that need your attention.}}</ion-label>
        <p (click)="showSyncQueue($event)" class="linkLabel" i18n="@@cloudStatus.showLink">Show</p>
    </ion-item>

    <ion-item *ngIf="online" lines="none">
        <fa-icon [icon]="appIcons.connectedGoodConnection" slot="start"></fa-icon>
        <ion-label class="ion-text-wrap" i18n="@@cloudStatus.connectedToTheServer">You are connected to the server.</ion-label>
    </ion-item>
    <ion-item *ngIf="!online" lines="none">
        <fa-icon [icon]="appIcons.offline" slot="start"></fa-icon>
        <ion-label class="ion-text-wrap" i18n="@@cloudStatus.disconnectedFromTheServer">You are offline.</ion-label>
        <p (click)="networkTest()" class="linkLabel" i18n="@@cloudStatus.networkTest">Network test</p>
    </ion-item>

    <ion-item (click)="showSyncQueue($event)" *ngIf="synchronizationSummary && !synchronizationSummary.pending && !uploadQueuePendingItems" lines="full">
        <fa-icon [icon]="appIcons.synchronized" slot="start"></fa-icon>
        <ion-label class="ion-text-wrap" i18n="@@cloudStatus.everythingSynchronized">Everything is saved on the server.</ion-label>
    </ion-item>
    <ion-item (click)="showSyncQueue($event)" *ngIf="synchronizationSummary && synchronizationSummary.pending || uploadQueuePendingItems" class="clickable" lines="full">
        <fa-icon [icon]="appIcons.synchronizing" slot="start"></fa-icon>
        <ion-label class="ion-text-wrap" i18n="@@cloudStatus.pendingUpdates">{(synchronizationSummary?.pending || 0) + uploadQueuePendingItems, plural, =0 {There are no updates that have to be synchronized.} =1 {There is one update that has to be synchronized.} other {There are {{(synchronizationSummary?.pending || 0) + uploadQueuePendingItems}} updates which are not yet synchronized.}}</ion-label>
        <p (click)="showSyncQueue($event)" class="linkLabel" i18n="@@cloudStatus.showLink">Show</p>
    </ion-item>

    <ion-item (click)="showExtendedInformation()" *ngIf="!extendedInformationVisible" lines="full">
        <label class="linkLabel" i18n="@@cloudStatus.moreInformation">More information</label>
    </ion-item>

    <ion-item *ngIf="online && latency && extendedInformationVisible" [lines]="networkInformation.informationAvailable ? 'none' : 'full'">
        <fa-icon *ngIf="latency <= 200" [icon]="appIcons.latencyVeryLow" slot="start"></fa-icon>
        <fa-icon *ngIf="latency > 200 && latency <= 500" [icon]="appIcons.latencyLow" slot="start"></fa-icon>
        <fa-icon *ngIf="latency > 500 && latency <= 1500" [icon]="appIcons.latencyHigh" slot="start"></fa-icon>
        <fa-icon *ngIf="latency > 1500" [icon]="appIcons.latencyVeryHigh" slot="start"></fa-icon>
        <ion-label class="ion-text-wrap" i18n="@@cloudStatus.latency">Latency: {{latency | number:'1.0-0'}}&thinsp;ms</ion-label>
    </ion-item>

    <ng-container *ngIf="networkInformation.informationAvailable && extendedInformationVisible">
        <ion-item *ngIf="networkInformation.type || networkInformation.effectiveType" lines="none">
            <fa-icon [icon]="appIcons.networkType" slot="start"></fa-icon>
            <ion-label class="ion-text-wrap">
                <ng-container i18n="@@cloudStatus.networkTypeColon">Type:</ng-container>&nbsp;
                <ng-container>{{networkInformation.type || '---'}}</ng-container>
                <ng-container *ngIf="networkInformation.effectiveType">/{{networkInformation.effectiveType}}</ng-container>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="networkInformation.downlink" lines="none">
            <fa-icon [icon]="appIcons.downlink" slot="start"></fa-icon>
            <ion-label class="ion-text-wrap">
                <ng-container i18n="@@cloudStatus.bandwidth">Bandwidth: {{networkInformation.downlink | number:'1.0-0'}}&thinsp;mbps</ng-container>&nbsp;
                <ng-container *ngIf="networkInformation.downlinkMax" i18n="@@cloudStatus.bandwidthMaxAdditionalInfo">(max. {{networkInformation.downlinkMax | number:'1.0-0'}}&thinsp;mbps)</ng-container>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="networkInformation.roundTripTime" lines="none">
            <fa-icon [icon]="appIcons.roundTripTime" slot="start"></fa-icon>
            <ion-label class="ion-text-wrap" i18n="@@cloudStatus.roundTripTime">Round-trip time: {{networkInformation.roundTripTime | number:'1.0-0'}}&thinsp;ms</ion-label>
        </ion-item>
        <ion-item lines="none">
            <fa-icon [icon]="appIcons.dataSaver" slot="start"></fa-icon>
            <ion-label class="ion-text-wrap" i18n="@@cloudStatus.dataSaver">Data saver: {{booleanToYesNo(networkInformation.saveData)}}</ion-label>
        </ion-item>
        <ion-item lines="none">
            <fa-icon [icon]="appIcons.deviceIdentifier" slot="start"></fa-icon>
            <ion-label class="ion-text-wrap" i18n="@@cloudStatus.deviceId">Device ID: {{deviceId}}</ion-label>
        </ion-item>
        <ion-item lines="full">
            <fa-icon [icon]="appIcons.deviceIdentifier" slot="start"></fa-icon>
            <ion-label class="ion-text-wrap" i18n="@@cloudStatus.sessionId">Session ID: {{sessionId}}</ion-label>
        </ion-item>
    </ng-container>

    <ion-item lines="none">
        <fa-icon [icon]="appIcons.clientVersion" slot="start"></fa-icon>
        <ion-label class="ion-text-wrap">
            <ng-container i18n="@@cloudStatus.clientVersion">Version: {{clientVersion}}</ng-container>
            <br *ngIf="serverVersion" />
            <ng-container *ngIf="serverVersion" i18n="@@cloudStatus.serverVersion">Server Version: {{serverVersion}}</ng-container>
        </ion-label>
    </ion-item>

    <ion-item *ngIf="clearingCacheActive" lines="none">
        <fa-icon [icon]="appIcons.genericLoading" [spin]="true" slot="start"></fa-icon>
        <ion-label>{{infoMessage}}</ion-label>
    </ion-item>

    <div class="actionButtons">
        <base-process-button (triggered)="networkTest($event)" [icon]="appIcons.networkTest" i18n="@@cloudStatus.networkTest">Network test</base-process-button>
        <base-process-button (triggered)="openStorageInfo($event)" [icon]="appIcons.localStorage" i18n="@@cloudStatus.storageInfoButton">Storage info</base-process-button>
        <base-process-button (triggered)="clearCache($event)" *ngIf="!clearingCacheActive" [icon]="appIcons.clearCache" i18n="@@cloudStatus.clearCacheButton">Clear cache</base-process-button>
    </div>
</ion-list>
