<ion-header class="modalHeader ion-no-border">
    <ion-toolbar>
        <ion-title>
            <span i18n="@@appSettings.title">Application settings</span>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-label color="medium">
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="modalContent ion-padding">
    <form *ngIf="isDebug || hiddenSettingsVisible > 0" [formGroup]="formData">
        <ion-list>
            <ion-item>
                <ion-label i18n="@@settings.serverUrl" position="floating">Server URL</ion-label>
                <ion-input formControlName="serverUrl"></ion-input>
            </ion-item>
            <base-process-button (triggered)="save($event)" [disabled]="formData.status != 'VALID'" [icon]="appIcons.genericSave" i18n="@@appSettings.saveButton">Save</base-process-button>
        </ion-list>
        <div class="buttonSection">
            <base-process-button (triggered)="toggleGpt($event)" [icon]="appIcons.entityAiAnalysisRegular" [showLoadingAnimation]="false" buttonStyle="stroked">
                <ng-container *ngIf="!environment.featureGpt">Activate GPT</ng-container>
                <ng-container *ngIf="environment.featureGpt">Deactivate GPT</ng-container>
            </base-process-button>
        </div>
    </form>

    <div class="buttonSection">
        <base-process-button (triggered)="changePassword($event)" [icon]="appIcons.changePassword" [showLoadingAnimation]="false" buttonStyle="stroked" i18n="@@sessions.changePassword">Change password</base-process-button>
    </div>

    <div class="buttonSection">
        <base-process-button (triggered)="toggleInspectionMode($event)" [icon]="appIcons.debug" [showLoadingAnimation]="false" buttonStyle="stroked">
            <ng-container *ngIf="!appState.inspectionMode" i18n="@@app.activateInspectionMode">Activate inspection mode</ng-container>
            <ng-container *ngIf="appState.inspectionMode" i18n="@@app.deactivateInspectionMode">Deactivate inspection mode</ng-container>
        </base-process-button>
    </div>

    <div class="buttonSection">
        <base-process-button (triggered)="restartApp($event)" [icon]="appIcons.restartApp" buttonStyle="stroked" color="warn" i18n="@@app.restartButton">Restart app</base-process-button>
    </div>
</ion-content>

<ion-footer class="modalFooter ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="activateHiddenSettings()">
                &nbsp;
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <fa-icon [icon]="appIcons.genericCheck"></fa-icon>&nbsp;<ng-container i18n="@@errorView.close">Close</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
