<ion-header class="modalHeader ion-no-border">
    <ion-toolbar>
        <ion-title *ngIf="!permissionRequiredError">
            <span i18n="@@errorView.title">Error</span><span *ngIf="appException && appException.errorCode">&nbsp;{{appException.errorCode}}</span> -
            <ng-container i18n="@@errorView.dontPanic">Don't panic</ng-container>
        </ion-title>
        <ion-title *ngIf="permissionRequiredError" i18n="@@errorView.permissionRequiredTitle">Permission required</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-label color="medium">
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="error" class="modalContent ion-padding">
    <div class="errorIcon bumpAfterShown centerContent">
        <fa-icon *ngIf="permissionRequiredError" [icon]="appIcons.noPermission" size="3x"></fa-icon>
        <fa-icon *ngIf="!permissionRequiredError" [icon]="appIcons.genericError" size="3x"></fa-icon>
    </div>

    <div *ngIf="permissionRequiredError" class="infoText" i18n="@@errorView.PermissionRequiredText">For this operation, a special permission is required. Unfortunately, your account does not have this permission.<br />Please contact your manager or administrator if you want your permissions to be changed.</div>

    <ion-button (click)="showDetails = true" *ngIf="!showDetails" class="detailsButton" fill="outline" i18n="@@errorView.ShowDetailsButton">Show Details</ion-button>
    <business-error *ngIf="showDetails" [error]="error"></business-error>
</ion-content>

<ion-footer class="modalFooter ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="contactSupport()">
                <fa-icon [icon]="appIcons.errorCallForHelp"></fa-icon>&nbsp;<ng-container i18n="@@errorView.contactSupport">Contact support</ng-container>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <fa-icon [icon]="appIcons.genericCheck"></fa-icon>&nbsp;<ng-container i18n="@@errorView.close">Close</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
