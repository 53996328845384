import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { IntentOptions } from "../../../base/services/intents/intent-options";
import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { EntityTypes } from "../../entities/entity-types";
import { SelectEntityIntent } from "./select-entity-intent";

/**
 * Options for {@SelectEntityIntent}.
 */
export class SelectEntityIntentOptions implements IntentOptions<SelectEntityIntent> {
    constructor(entityType: EntityTypes, title: string, init?: Partial<SelectEntityIntentOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.entityType = entityType;
        this.title = title;
    }

    public entityType: EntityTypes;

    public title: string;

    public allowCreate: boolean = true;

    public auditorsOnly: boolean = false;

    public resultEntities: Array<GenericCoreDataEntity> = [];
}
