import { DateTime, Duration } from "luxon";

import { HumanizeDuration } from "./humanize-duration/humanize-duration";
import { HumanizeDurationLanguage } from "./humanize-duration/humanize-duration.lang";

/* eslint-disable @typescript-eslint/no-magic-numbers */
/**
 * A helper class for date and time operations.
 */
export class DateTimeHelper {
    public static second: number = 1000;

    public static minute: number = 60 * DateTimeHelper.second;

    public static hour: number = 60 * DateTimeHelper.minute;

    public static day: number = 24 * DateTimeHelper.hour;

    public static week: number = 7 * DateTimeHelper.day;

    public static month: number = 30 * DateTimeHelper.day;

    public static year: number = 365 * DateTimeHelper.day;

    public static dateOnlyMillisecondsMarker: string = "001";

    private static localTimeZoneField?: string;

    private static humanizeDuration: HumanizeDuration = new HumanizeDuration(new HumanizeDurationLanguage());

    public static get localTimezone(): string {
        return this.localTimeZoneField ?? (this.localTimeZoneField = Intl.DateTimeFormat().resolvedOptions().timeZone);
    }

    public static toLocaleDateString(utcString: string, localeId: string): string {
        return DateTime.fromISO(utcString).setLocale(localeId).toLocaleString({
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        });
    }

    /* eslint-disable @typescript-eslint/no-magic-numbers */
    public static getGreeting(name: string): string {
        const hour: number = new Date().getHours();
        if (hour >= 4 && hour < 10) {
            return $localize`:@@greetings.goodMorning:Good morning ${name}:name:`;
        } else if (hour >= 17 && hour < 21) {
            return $localize`:@@greetings.goodEvening:Good evening ${name}:name:`;
        }
        return $localize`:@@greetings.hello:Hello ${name}:name:`;
    }

    public static utcNow(): string {
        return new Date().toISOString();
    }

    public static utcNowDateOnly(): string {
        return new Date().toISOString().substring(0, 10);
    }

    public static localNowDateOnly(): string {
        return DateTime.now().toString().substring(0, 10);
    }

    public static timestampToUtcString(timestampMilliseconds: number): string {
        return (new Date(timestampMilliseconds)).toISOString();
    }

    public static hasTimezone(dateTimeString: string|undefined): boolean {
        if (!dateTimeString) {
            return false;
        }

        const match: RegExpMatchArray|null = dateTimeString.match(/(?:Z|[+-](?:2[0-3]|[01]\d):[0-5]\d)$/);
        return !!match?.length && match.length > 0;
    }

    public static getTimeZoneString(dateTimeString: string|undefined): string {
        if (!dateTimeString) {
            return "";
        }

        const match: RegExpMatchArray|null = dateTimeString.match(/(?:Z|[+-](?:2[0-3]|[01]\d):[0-5]\d)$/);
        return match?.length && match.length > 0 ? match[0] : "";
    }

    public static sanitizeDateTime(value: string|undefined): string|undefined {
        if (!value) {
            return undefined;
        }
        const dateTimeString: string = value.length <= 10 ? `${value}T00:00:00Z` : value;
        const hasTimeZone: boolean = this.hasTimezone(dateTimeString);
        const date: Date = new Date(hasTimeZone ? dateTimeString : `${dateTimeString}Z`);

        return date.toISOString();
    }

    public static removeTimeZone(dateTimeString: string): string {
        const timeZoneString: string = this.getTimeZoneString(dateTimeString);
        if (timeZoneString) {
            return dateTimeString.replace(timeZoneString, "");
        }
        return dateTimeString;
    }

    /**
     * Removes the timezone information and replaces it with Z.
     *
     * @param dateTimeString - The source date time string.
     * @returns Modified date time string.
     */
    public static forceToUtc(dateTimeString: string|undefined): string|undefined {
        if (!dateTimeString) {
            return undefined;
        }

        const timeZoneString: string = this.getTimeZoneString(dateTimeString);
        if (timeZoneString) {
            return dateTimeString.replace(timeZoneString, "Z");
        }
    }

    public static toIsoString(date: Date): string {
        const timezone: number = -date.getTimezoneOffset();
        const sign: string = timezone >= 0 ? "+" : "-";
        const pad: (num: number) => string = (num: number) => (num < 10 ? "0" : "") + num;

        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}${sign}${pad(Math.floor(Math.abs(timezone) / 60))}:${pad(Math.abs(timezone) % 60)}`;
    }

    public static getLocalTimeZoneString(): string {
        const timezone: number = -new Date().getTimezoneOffset();
        const sign: string = timezone >= 0 ? "+" : "-";
        const pad: (num: number) => string = (num: number) => (num < 10 ? "0" : "") + num;
        return `${sign}${pad(Math.floor(Math.abs(timezone) / 60))}:${pad(Math.abs(timezone) % 60)}`;
    }

    public static utcToLocalTimeString(utcDate: string): string {
        return DateTime.fromISO(utcDate).toLocal().toString();
    }

    public static toUtc(localTime: string|null|undefined): string|undefined {
        return localTime ? DateTime.fromISO(localTime).toUTC().toString() : undefined;
    }

    public static isUtcTimeZero(utcTime: string): boolean {
        return utcTime.endsWith("00:00:00.000Z");
    }

    public static setUtcTimeZero(utcTime: string): string {
        return `${utcTime.substring(0, 10)}T00:00:00.000Z`;
    }

    public static hasDateOnlyMarker(dateTimeString: string|null|undefined): boolean {
        return !!dateTimeString && dateTimeString.length >= 23 && dateTimeString.substring(20, 23) == this.dateOnlyMillisecondsMarker;
    }

    public static setDateOnlyMarker(dateTimeString: string|null|undefined): string|null|undefined {
        if (!dateTimeString || dateTimeString.length < 23) {
            return dateTimeString;
        }
        return `${dateTimeString.substring(0, 20)}${this.dateOnlyMillisecondsMarker}${dateTimeString.substring(23)}`;
    }

    public static removeDateOnlyMarker(dateTimeString: string|null|undefined): string|null|undefined {
        if (!dateTimeString || dateTimeString.length < 23) {
            return dateTimeString;
        }
        return `${dateTimeString.substring(0, 20)}000${dateTimeString.substring(23)}`;
    }

    public static replaceTime(fullIsoString: string|null|undefined, timeString: string|null|undefined): string|undefined {
        if (!fullIsoString) {
            return undefined;
        }

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (timeString && timeString.length == 5 && timeString[2] == ":") {
            return `${fullIsoString.substring(0, 10)}T${timeString}:${fullIsoString.substring(17)}`;
        }
        return fullIsoString;
    }

    public static toRelativeTime(otherTime: string|null|undefined, referenceTime?: string|null|undefined): Duration {
        if (!otherTime) {
            return Duration.invalid("empty");
        }

        let otherDateTime: DateTime = DateTime.fromISO(otherTime);
        const daysOnly: boolean = this.hasDateOnlyMarker(otherTime) || this.hasDateOnlyMarker(referenceTime);
        let referenceDateTime: DateTime = referenceTime ? DateTime.fromISO(referenceTime) : DateTime.now();

        if (daysOnly) {
            otherDateTime = otherDateTime.startOf("day");
            referenceDateTime = referenceDateTime.startOf("day");
        }

        return otherDateTime.diff(referenceDateTime);
    }

    public static meaningfulUnits(duration: Duration, onlyDays: boolean = false): Array<"y"|"mo"|"w"|"d"|"h"|"m"|"s"|"ms"> {
        const milliseconds: number = Math.abs(duration.toMillis());
        if (milliseconds > 6 * this.month) {
            return ["y", "mo", "w"];
        }
        if (milliseconds > this.week || onlyDays) {
            return ["y", "mo", "w", "d"];
        }
        return ["y", "mo", "w", "d", "h", "m"];
    }

    public static durationToString(duration: Duration, localeTwoLetters: string = "en", units: Array<"y"|"mo"|"w"|"d"|"h"|"m"|"s"|"ms"> = ["y", "mo", "w", "d", "h", "m", "s", "ms"], addPreposition: boolean = false, isRelativeToNow: boolean = false): string {
        const milliseconds: number = duration.toMillis();

        if (isRelativeToNow && Math.abs(milliseconds) < DateTimeHelper.minute && units.includes("m")) {
            return $localize`:@@dateTime.nowLower:now`;
        }

        const days: number = duration.as("day");
        if (isRelativeToNow && days == 0 && !units.includes("m")) {
            return $localize`:@@dateTime.todayLower:today`;
        }
        if (isRelativeToNow && days == 1 && !units.includes("m")) {
            return $localize`:@@dateTime.tomorrowLower:tomorrow`;
        }

        const value: string = this.humanizeDuration.humanize(milliseconds, {
            language: localeTwoLetters,
            units: units,
            round: true,
            preposition: addPreposition
        });
        if (addPreposition) {
            return milliseconds < 0 ? $localize`:@@dateTime.timeAgo:${value} ago` : $localize`:@@dateTime.timeIn:in ${value}`;
        }

        return value;
    }
}
