export enum Pages {
    unknown = "-",

    dashboard = "dashboard",
    login = "login",
    register = "user-session",
    auditsList = "audits-list",
    intent = "intent",
    project = "project",
    process = "process",
    document = "document",
    documentsList = "documents-list",
    personsList = "persons-list",
    companiesList = "companies-list",
    coreDataList = "core-data",
    diagramEditor = "diagram-editor",
    storageInfo = "storage-info",
    certificates = "certificates"
}


