import { Component, OnInit } from "@angular/core";

import Licenses from "../../../../assets/licenses.json";

/**
 * Shows used licenses in the app.
 */
@Component({
    selector: "app-licenses",
    templateUrl: "./licenses.component.html",
    styleUrls: ["./licenses.component.scss"]
})
export class LicensesComponent implements OnInit {
    public licenses: Array<any> = [];

    public ngOnInit(): void {
        const licenseAlias: any = Licenses as unknown;
        for (const key of Object.keys(licenseAlias)) {
            const sourceItem: any = licenseAlias[key] as unknown;
            const licenseItem: object = {
                name: key,
                publisher: sourceItem.publisher as unknown,
                licenses: sourceItem.licenses as unknown,
                licenseUrl: `/licenses/${key?.replace(/\//, "-")}.txt`,
                repository: sourceItem.repository as unknown
            };
            this.licenses.push(licenseItem);
        }
    }
}
