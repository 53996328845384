import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { DefaultService, RequestBodyCreateSamplingPlanDto, RequestBodyMergeSamplingPlanDto, SampleRawDataDto, SamplingPlanDto } from "../../../generated/api";
import { SamplingPlanMerge } from "../../entities/samplings/sampling-plan-merge";
import { EntityHelper } from "../../helpers/entity-helper";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { EntityBusinessIdentifier } from "../../identifiers/entity-business-identifier";
import { SessionService } from "../session/session.service";

/**
 * Service to work with projects.
 */
@Injectable({
    providedIn: "root"
})
export class SamplingsApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public async getSamplingPlan(documentIdentifier: DocumentIdentifier): Promise<SamplingPlanDto|undefined>|never {
        this.sessionService.requiresAccount();
        const result: Array<SamplingPlanDto> = await lastValueFrom(this.defaultService.listSamplingPlan(this.sessionService.activeAccountId, documentIdentifier.technicalIdentifier, documentIdentifier.businessIdentifier));
        return result && result.length > 0 ? EntityHelper.getLatestByCreatedAndUpdatedTime(result) : undefined;
    }

    public async createSamplingPlan(documentIdentifier: DocumentIdentifier, samplingPlanDto: SamplingPlanDto): Promise<SamplingPlanDto>|never {
        this.sessionService.requiresAccount();

        const dto: RequestBodyCreateSamplingPlanDto = {
            documentBusinessId: documentIdentifier.businessIdentifier,
            samplingPlan: samplingPlanDto
        };

        return lastValueFrom(this.defaultService.createSamplingPlan(dto, this.sessionService.activeAccountId));
    }

    public updateSamplingPlan(samplingPlanBusinessIdentifier: EntityBusinessIdentifier, samplingPlanDto: SamplingPlanDto, archive: boolean): Promise<SamplingPlanDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.updateSamplingPlan(samplingPlanBusinessIdentifier, samplingPlanDto, this.sessionService.activeAccountId, archive));
    }

    public updateSamplingPlanMerge(documentBusinessIdentifier: DocumentBusinessIdentifier, requestBody: RequestBodyMergeSamplingPlanDto): Promise<SamplingPlanDto>|never {
        this.sessionService.requiresAccount();

        requestBody.documentBusinessId = documentBusinessIdentifier;

        return lastValueFrom(this.defaultService.mergeSamplingPlan(requestBody, this.sessionService.activeAccountId));
    }

    public updateSamplingPlanWithinBuilding(samplingPlanMerge: SamplingPlanMerge): Promise<SamplingPlanDto>|never {
        this.sessionService.requiresAccount();

        const sampleRawDataDtos: Array<SampleRawDataDto> = [];

        const dto: RequestBodyMergeSamplingPlanDto = {
            documentBusinessId: samplingPlanMerge.documentIdentifier.businessIdentifier,
            sampleRawData: sampleRawDataDtos
        };

        return lastValueFrom(this.defaultService.mergeSamplingPlan(dto, this.sessionService.activeAccountId));
    }
}
