// Original code by https://github.com/Nightapes/HumanizeDuration.ts

/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
    HumanizeDurationOptions,
    Language,
    Piece,
    UnitName
} from "./humanize-duration.interface";
import { HumanizeDurationLanguage } from "./humanize-duration.lang";

/**
 * Translate duration information to readable strings.
 */
export class HumanizeDuration {
    constructor(private languageUtil: HumanizeDurationLanguage) {
        this.options = this.defaultOptions;
    }

    private defaultOptions: HumanizeDurationOptions = {
        language: "en",
        delimiter: ", ",
        spacer: " ",
        conjunction: "",
        serialComma: true,
        units: ["y", "mo", "w", "d", "h", "m", "s"],
        languages: {},
        largest: 10,
        decimal: ".",
        round: false,
        preposition: false,
        unitMeasures: {
            y: 31557600000,
            mo: 2629800000,
            w: 604800000,
            d: 86400000,
            h: 3600000,
            m: 60000,
            s: 1000,
            ms: 1
        }
    };

    private options: HumanizeDurationOptions;

    public humanize(value: number, tempOptions?: HumanizeDurationOptions): string {
        try {
            const options: HumanizeDurationOptions =
                tempOptions
                    ? this.extend(this.options, tempOptions)
                    : this.defaultOptions;
            return this.doHumanization(value, options);
        } catch (error) {
            console.warn(error);
            return "";
        }
    }

    public setOptions(passedOptions: HumanizeDurationOptions): void {
        this.options =
            passedOptions !== undefined
                ? this.extend(this.defaultOptions, passedOptions)
                : this.defaultOptions;
    }

    public getSupportedLanguages(): Array<string> {
        const result: Array<string> = [];
        for (const language in this.languageUtil.languages) {
            if (this.languageUtil.languages.hasOwnProperty(language)) {
                result.push(language);
            }
        }
        return result;
    }

    public addLanguage(key: string, lang: Language): void {
        this.languageUtil.addLanguage(key, lang);
    }

    // eslint-disable-next-line complexity
    private doHumanization(ms: number, options: HumanizeDurationOptions): string { // NOSONAR typescript:S3776 Cognitive Complexity (Tom Riedl)
        let i: number;
        let len: number;
        let piece: Piece;

        // Make sure we have a positive number.
        // Has the nice side effect of turning Number objects into primitives.
        let msAbsolute: number = Math.abs(ms);

        let dictionary: Language|undefined;
        if (options.languages && options.language) {
            if (options.preposition) {
                dictionary = options.languages[`${options.language}_preposition`];
            }
            if (!dictionary) {
                dictionary = options.languages[options.language];
            }
        }
        if (!dictionary) {
            if (options.preposition) {
                dictionary = this.languageUtil.languages[`${options.language}_preposition`];
            }
            if (!dictionary) {
                dictionary = this.languageUtil.languages[options.language!];
            }
        }
        if (!dictionary) {
            return "NO_DICTIONARY";
        }

        const pieces: Array<Piece> = [];

        // Start at the top and keep removing units, bit by bit.
        let unitName: UnitName;
        let unitMS: number;
        let unitCount: number;
        for (i = 0, len = (options.units ?? []).length; i < len; i++) {
            unitName = options.units![i];
            unitMS = options.unitMeasures ? options.unitMeasures[unitName] : 0;

            // What's the number of full units we can fit?
            if (i + 1 === len) {
                unitCount = msAbsolute / unitMS;
            } else {
                unitCount = Math.floor(msAbsolute / unitMS);
            }

            // Add the string.
            pieces.push({
                unitCount: unitCount,
                unitName: unitName
            });

            // Remove what we just figured out.
            msAbsolute -= unitCount * unitMS;
        }

        let firstOccupiedUnitIndex: number = 0;
        for (i = 0; i < pieces.length; i++) {
            if (pieces[i].unitCount) {
                firstOccupiedUnitIndex = i;
                break;
            }
        }

        if (options.round && options.unitMeasures) {
            let ratioToLargerUnit: number;
            let previousPiece: Piece;
            for (i = pieces.length - 1; i >= 0; i--) {
                piece = pieces[i];
                piece.unitCount = Math.round(piece.unitCount);

                if (i === 0) {
                    break;
                }

                previousPiece = pieces[i - 1];

                ratioToLargerUnit =
                    options.unitMeasures[previousPiece.unitName] /
                    options.unitMeasures[piece.unitName];
                if (
                    piece.unitCount % ratioToLargerUnit === 0 ||
                    (options.largest && options.largest - 1 < i - firstOccupiedUnitIndex)
                ) {
                    previousPiece.unitCount += piece.unitCount / ratioToLargerUnit;
                    piece.unitCount = 0;
                }
            }
        }

        const result: Array<string> = [];
        for (i = 0, pieces.length; i < len; i++) {
            piece = pieces[i];
            if (piece.unitCount) {
                result.push(
                    this.render(piece.unitCount, piece.unitName, dictionary, options)
                );
            }

            if (result.length === options.largest) {
                break;
            }
        }

        if (result.length) {
            if (!options.conjunction || result.length === 1) {
                return result.join(options.delimiter);
            } else if (result.length === 2) {
                return result.join(options.conjunction);
            } else if (result.length > 2) {
                return (
                    result.slice(0, -1).join(options.delimiter) +
                    (options.serialComma ? "," : "") +
                    options.conjunction +
                    result.slice(-1)
                );
            }
        } else {
            return options.units ? this.render(
                0,
                options.units[options.units.length - 1],
                dictionary,
                options
            ) : "";
        }

        return "";
    }

    private render(
        count: number,
        type: UnitName,
        dictionary: Language,
        options: HumanizeDurationOptions
    ): string {
        let decimal: string;
        if (options.decimal === void 0) {
            decimal = dictionary.decimal;
        } else {
            decimal = options.decimal;
        }

        const countStr: string = count.toString().replace(".", decimal.toString());

        const word: string = dictionary[type](count);

        return countStr + options.spacer + word;
    }

    private extend(
        options: HumanizeDurationOptions,
        override: HumanizeDurationOptions
    ): HumanizeDurationOptions {
        for (const prop in override) {
            if (options.hasOwnProperty(prop)) {
                // @ts-expect-error: Ignored indexing type mismatch
                options[prop] = override[prop as keyof HumanizeDurationOptions];
            }
        }

        return options;
    }
}
