import { QualityAssuranceItem } from "./quality-assurance-item";

/**
 * Factory for all quality assurance related classes.
 */
export class QualityAssuranceFactory {
    public static createQualityAssuranceItem(init?: Partial<QualityAssuranceItem>): QualityAssuranceItem {
        return new QualityAssuranceItem(init);
    }
}
