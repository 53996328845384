import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { environment } from "../../../../environments/environment";
import { DefaultService, ProjectDto } from "../../../generated/api";
import { EntityHelper } from "../../helpers/entity-helper";
import { ProjectBusinessIdentifier } from "../../identifiers/project-business-identifier";
import { ProjectIdentifier } from "../../identifiers/project-identifier";
import { SessionService } from "../session/session.service";

/**
 * Service to work with projects.
 */
@Injectable({
    providedIn: "root"
})
export class ProjectsApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly defaultService: DefaultService
    ) {
    }

    public async getProjects(searchQuery?: string, limit: number = environment.searchDefaultResultsCount): Promise<Array<ProjectDto>>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.listProjects(this.sessionService.activeAccountId, undefined, undefined, searchQuery, limit));
    }

    public createProject(projectDto: ProjectDto): Promise<ProjectDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.createProject(projectDto, this.sessionService.activeAccountId));
    }

    public updateProject(projectBusinessId: ProjectBusinessIdentifier, projectDto: ProjectDto, archive: boolean): Promise<ProjectDto>|never {
        this.sessionService.requiresAccount();
        return lastValueFrom(this.defaultService.updateProject(projectBusinessId, projectDto, this.sessionService.activeAccountId, archive));
    }

    public async getProject(projectIdentifier: ProjectIdentifier): Promise<ProjectDto|undefined>|never {
        this.sessionService.requiresAccount();

        const companies: Array<ProjectDto> = await lastValueFrom(this.defaultService.listProjects(this.sessionService.activeAccountId, projectIdentifier.businessIdentifier || undefined, projectIdentifier.technicalIdentifier || undefined));
        if (projectIdentifier.technicalIdentifier) {
            return companies.find((dto: ProjectDto) => dto.technicalId == projectIdentifier.technicalIdentifier);
        } else {
            return EntityHelper.getLatestByCreatedAndUpdatedTime(companies);
        }
    }

    public async deleteProject(businessIdentifier: ProjectBusinessIdentifier): Promise<void>|never {
        this.sessionService.requiresAccount();
        await lastValueFrom(this.defaultService.deleteProject(businessIdentifier, this.sessionService.activeAccountId));
    }
}
