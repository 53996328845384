import { DocumentAnswerCommentStorageDto } from "./document-answer-comment-storage-dto";
import { DocumentAnswerValueStorageDto } from "./document-answer-value-storage-dto";

/**
 * Storage DTO for {@link DocumentAnswer}.
 */
export class DocumentAnswerStorageDto {
    public templateIdentifier?: number;
    public value?: DocumentAnswerValueStorageDto;
    public label?: string;
    public unit?: string;
    public unitLabel?: string;
    public order?: number = 0;
    public scoring?: string;
    public commentMandatory?: boolean;

    public comments: Array<DocumentAnswerCommentStorageDto> = [];
}
