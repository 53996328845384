import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import {
    DefaultService,
    ServerInfoDto
} from "../../../generated/api";

/**
 * The service to talk to the server backend.
 */
@Injectable({
    providedIn: "root"
})
export class InfoApiService {
    constructor(
        private readonly defaultService: DefaultService
    ) {
    }

    public getInfo(): Promise<ServerInfoDto> {
        return lastValueFrom(this.defaultService.getInfo());
    }
}
