import { environment } from "../../../../environments/environment";
import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { QuestionTechnicalIdentifier } from "../../identifiers/question-technical-identifier";
import { StorableEntity } from "../storable-entity";
import { AttachmentTypes } from "./attachment-types";
import { FileAttachment } from "./file-attachment";
import { FileAttachmentStorageDto } from "./file-attachment-storage-dto";
import { ImageAttachmentStorageDto } from "./image-attachment-storage-dto";

/**
 * An image attachment like photos or screenshots.
 */
export class ImageAttachment extends FileAttachment implements StorableEntity<ImageAttachmentStorageDto> {
    constructor(init?: Partial<ImageAttachment>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly type: AttachmentTypes = AttachmentTypes.image;

    public width: number = 0;

    public height: number = 0;

    public printQuality: number = environment.imageDefaultPrintQuality;

    protected toAttachmentDtoSpecificProperties(): FileAttachmentStorageDto {
        const dto: ImageAttachmentStorageDto = new ImageAttachmentStorageDto();
        dto.width = this.width;
        dto.height = this.height;
        dto.printQuality = this.printQuality;
        dto.storedImageIsFromLocalDevice = this.storedFileIsFromLocalDevice;
        dto.linkedQuestions = this.linkedQuestions || [];
        return dto;
    }

    protected fromAttachmentDtoSpecificProperties(dto: FileAttachmentStorageDto|undefined): void {
        const imageDto: ImageAttachmentStorageDto = dto as ImageAttachmentStorageDto;
        this.width = imageDto?.width ?? 0;
        this.height = imageDto?.height ?? 0;
        this.printQuality = imageDto?.printQuality || environment.imageDefaultPrintQuality;
        this.storedFileIsFromLocalDevice = imageDto?.storedImageIsFromLocalDevice ?? false;
        this.linkedQuestions = imageDto?.linkedQuestions as Array<QuestionTechnicalIdentifier> || [];
    }
}
