import { AttachmentStorageDto } from "./attachment-storage-dto";

/**
 * Storage DTO for {@link FileAttachment}.
 */
export class FileAttachmentStorageDto extends AttachmentStorageDto {
    public remoteUrl?: string;
    public localUrl?: string;
    public remoteUrlPreview?: string;
    public localUrlPreview?: string;

    public filename?: string;
    public mimeType?: string;

    public localStorageId?: number;
    public localStorageIdPreview?: number;
}
