import { Injectable } from "@angular/core";

import { ProductionTimer } from "./production-timer";
import { Timer } from "./timer";

/**
 * A service to get the current time and to spawn timers.
 */
@Injectable({
    providedIn: "root"
})
export class TimeService {
    public spawnTimer(interval: number) : Timer {
        return new ProductionTimer(interval);
    }

    public get currentTimestamp(): number {
        return Date.now();
    }
}
