import { ArrayHelper } from "../../../base/helpers/array-helper";
import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { Identifier } from "../../identifiers/identifier";
import { ProjectBusinessIdentifier } from "../../identifiers/project-business-identifier";
import { ProjectTechnicalIdentifier } from "../../identifiers/project-technical-identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { Company } from "../core-data/company";
import { CoreDataEntity } from "../core-data/core-data-entity";
import { EntityLink } from "../documents/entity-link";
import { EntityTypes } from "../entity-types";
import { Process } from "./process";
import { ProjectStorageDto } from "./project-storage-dto";

/**
 * A project that can hold processes and is usually linked to a company/customer.
 */
export class Project extends CoreDataEntity<ProjectBusinessIdentifier, ProjectTechnicalIdentifier> {
    constructor(init?: Partial<Project>) {
        super(EntityTypes.project);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public title?: string;

    public companyLink?: EntityLink<Company>;

    public processes: Array<EntityLink<Process>> = [];

    public get displayTitle(): string {
        const builder: Set<string|undefined> = new Set<string|undefined>();
        builder.add(this.title);
        builder.add(this.companyLink?.entity?.externalReference);
        builder.add(this.companyLink?.entity?.displayTitle);

        const builderArray: Array<string> = ArrayHelper.removeEmpty(Array.from(builder));
        if (builderArray.length <= 0) {
            builderArray.push(Identifier.identifierToReadableString(this.identifier));
        }
        return builderArray.join(", ");
    }

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    protected toEntityStorageDto(): ProjectStorageDto {
        const dto: ProjectStorageDto = new ProjectStorageDto({
            title: this.title,
            companyLink: this.companyLink?.toStorageDto()
        });

        dto.processes = [];
        for (const process of this.processes) {
            dto.processes.push(process.toStorageDto());
        }

        return dto;
    }

    protected fromEntityStorageDto(dto: ProjectStorageDto|undefined): void {
        this.title = dto?.title;
        this.companyLink = dto?.companyLink ? new EntityLink<Company>(Identifier.create(dto.companyLink.entityBusinessId, dto.companyLink.entityTechnicalId), EntityTypes.company) : undefined;
        this.companyLink?.fromStorageDto(dto?.companyLink);

        this.processes = [];
        for (const processDto of dto?.processes ?? []) {
            const process: EntityLink<Process>|undefined = CoreDataFactory.createEntityLinkFromStorageDto(processDto) as EntityLink<Process>|undefined;
            if (process) {
                this.processes.push(process);
            }
        }
    }

    public clone(): Project {
        const clone: Project = CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
        if (this.companyLink) {
            clone.companyLink = CoreDataFactory.createEntityLinkFromStorageDto(this.companyLink.toStorageDto()) as EntityLink<Company>;
            if (this.companyLink?.entity) {
                clone.companyLink.entity = this.companyLink.entity.clone();
            }
        }
        return clone;
    }
}
