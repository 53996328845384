import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import {
    AccountDto, CompanyDto,
    DefaultService
} from "../../../generated/api";

/**
 * The service to talk to the server backend.
 */
@Injectable({
    providedIn: "root"
})
export class AccountsApiService {
    constructor(
        private readonly defaultService: DefaultService
    ) {
    }

    public listAccounts(): Promise<Array<AccountDto>> {
        return lastValueFrom(this.defaultService.listAccounts());
    }

    public createAccount(companyDto: CompanyDto): Promise<AccountDto> {
        return lastValueFrom(this.defaultService.createAccount(companyDto));
    }
}
