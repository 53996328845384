import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";

/**
 * Options for registration component.
 */
export class RegistrationOptions {
    constructor(init?: Partial<RegistrationOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public email?: string;
    public invitationCode?: string;

}
