import { Component, Input, OnInit, Type } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { HelpArticles } from "../../global/help-articles";
import { OnlineHelpService } from "../../services/help/online-help.service";
import { AppIcons } from "../../services/icons/app-icons";
import { DefaultDialogComponentOptions } from "./default-dialog.component.options";
import { DialogComponent } from "./dialog-component";

/**
 * Dialog component to show other components within.
 */
@Component({
    selector: "business-default-dialog",
    templateUrl: "./default-dialog.component.html",
    styleUrls: ["./default-dialog.component.scss"]
})
export class DefaultDialogComponent implements OnInit {
    constructor(
        private readonly onlineHelpService: OnlineHelpService,
        private readonly modalController: ModalController
    ) {
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    private componentInstance?: DialogComponent<any>;

    @Input()
    public options!: DefaultDialogComponentOptions;

    public helpArticle?: HelpArticles;

    public get hasCancel(): boolean {
        return this.componentInstance?.hasCancel ?? true;
    }

    public get hasSave(): boolean {
        return this.componentInstance?.hasSave ?? false;
    }

    public get saveButtonTitle(): string {
        return this.componentInstance?.customSaveButtonTitle || $localize`:@@dialog.genericSaveButton:Save`;
    }

    public get hasClose(): boolean {
        return !this.hasCancel;
    }

    public ngOnInit(): void {
        // Do nothing for now
    }

    public componentCreated(eventData: { component: Type<any>; instance: any }): void {
        this.componentInstance = eventData.instance as DialogComponent<any>;

        if (this.componentInstance) {
            this.helpArticle = this.componentInstance.helpArticle;
            this.componentInstance.closeDialog = this.close.bind(this);
        }
    }

    public async close(): Promise<void> {
        const dialogComponent: DialogComponent<any> = this.componentInstance as DialogComponent<any>;
        if (dialogComponent && dialogComponent.closeTriggered) {
            await dialogComponent.closeTriggered();
        }

        await this.modalController.dismiss();
    }

    public async cancel(): Promise<void> {
        const dialogComponent: DialogComponent<any> = this.componentInstance as DialogComponent<any>;
        if (dialogComponent && dialogComponent.cancelTriggered) {
            await dialogComponent.cancelTriggered();
        }

        await this.modalController.dismiss();
    }

    public async save(): Promise<void> {
        let result: any|undefined = undefined;
        const dialogComponent: DialogComponent<any> = this.componentInstance as DialogComponent<any>;
        if (dialogComponent && dialogComponent.saveTriggered) {
            result = await dialogComponent.saveTriggered() as unknown;
        }

        await this.modalController.dismiss(result);
    }

    public openHelp(): void {
        if (!this.helpArticle) {
            return;
        }

        this.onlineHelpService.openArticle(this.helpArticle);
    }
}
