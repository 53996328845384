import { OverlayRef } from "@angular/cdk/overlay";
import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";

import { OverlayComponent } from "../../interfaces/overlay-component";
import { ContextMenuItem } from "./context-menu-item";
import { ContextMenuOptions } from "./context-menu-options";

/**
 * Component to show a context menu.
 */
@Component({
    selector: "base-context-menu",
    templateUrl: "./context-menu.component.html",
    styleUrls: ["./context-menu.component.scss"]
})
export class ContextMenuComponent implements OnInit, OverlayComponent<ContextMenuItem> {
    constructor() {
        // Do nothing for now
    }

    @Input()
    public options?: ContextMenuOptions;

    @Input()
    public overlayRef?: OverlayRef;

    public dismissed: Subject<ContextMenuItem> = new Subject<ContextMenuItem>();

    public ignoreFirstEvent: boolean = true;

    public ngOnInit(): void {
        // Do nothing for now
    }

    public regularItemClick(item: ContextMenuItem): void {
        this.overlayRef?.dispose();
        this.dismissed.next(item);
        this.dismissed.complete();
    }

    public checkboxChanged(item: ContextMenuItem, checked: boolean): void {
        if (this.ignoreFirstEvent && item.isChecked) {
            this.ignoreFirstEvent = false;
            return;
        }

        item.isChecked = checked;
        this.overlayRef?.dispose();
        this.dismissed.next(item);
        this.dismissed.complete();
    }
}
