<ion-toolbar *ngIf="!appState.focusMode">
    <ion-menu-button *ngIf="menuOptions?.menuVisible" slot="start"></ion-menu-button>

    <ion-img (click)="openAccountSwitchMenu($event)" *ngIf="!loading && activeAccount" slot="start" src="assets/logos/diwa-signum.png"></ion-img>
    <ion-img (click)="loggedOutLogoClick($event)" *ngIf="!loading && !activeAccount" slot="start" src="assets/logos/cyanaudit-logo.svg"></ion-img>

    <base-loading *ngIf="loading" slot="start"></base-loading>

    <ion-title (click)="navigateToStart()" *ngIf="activeAccount">{{title}}</ion-title>

    <ion-buttons slot="end">
        <ion-button (click)="restart()" *ngIf="appState?.frontendUpdateAvailable" class="restartRecommended" color="danger">
            <fa-icon [icon]="appIcons.restartRecommendedWarning"></fa-icon>&nbsp;<ng-container i18n="@@header.restartRecommended">Update available - Please restart</ng-container>
        </ion-button>

        <ion-button (click)="openHelpMenu($event)" aria-label="Help" i18n-aria-label="@@header.help">
            <fa-icon [icon]="appIcons.helpCircle"></fa-icon>
        </ion-button>

        <ion-button (click)="debug()" aria-label="Debug" i18n-aria-label="@@header.debug">
            <fa-icon [icon]="appIcons.debug"></fa-icon>
        </ion-button>

        <ion-button (click)="share($event)" aria-label="Share" i18n-aria-label="@@header.share">
            <fa-icon [icon]="appIcons.share"></fa-icon>
        </ion-button>

        <app-cloud-status></app-cloud-status>
    </ion-buttons>

    <ion-chip (click)="openProfileMenu($event)" color="tertiary" slot="end">
        <ion-label>
            <span *ngIf="activePerson && activePerson.firstname && activePerson.lastname" i18n="@@generic.fullName">{{activePerson.firstname}} {{activePerson.lastname}}</span>
            <span *ngIf="activePerson && activePerson.firstname && !activePerson.lastname">{{activePerson.firstname}}</span>
            <span *ngIf="activePerson && !activePerson.firstname && !activePerson.firstname">Person #{{activePerson.identifier.technicalIdentifier}}</span>
            <span *ngIf="!activePerson" i18n="@@sessions.loginOrRegister">Login/Register</span>
            <fa-icon [icon]="appIcons.genericCaretDown"></fa-icon>
        </ion-label>
        <ion-avatar *ngIf="activePerson">
            <img *ngIf="activePerson.gender == 'female'" alt="Avatar" src="assets/avatars/4530908-038-female.svg" />
            <img *ngIf="activePerson.gender == 'male'" alt="Avatar" src="assets/avatars/4530908-048-male.svg" />
            <img *ngIf="activePerson.gender != 'female' && activePerson.gender != 'male'" alt="Avatar" src="assets/avatars/4530908-026-other.svg" />
        </ion-avatar>
    </ion-chip>
</ion-toolbar>
