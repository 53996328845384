/**
 * Button used in a dialog.
 */
export class DialogButton {
    public constructor(text: string, id?: string, isPrimaryButton: boolean = false, role: "normal"|"destructive" = "normal") {
        this.text = text;
        this.id = id;
        this.isPrimaryButton = isPrimaryButton;
        this.role = role;
    }

    public text: string;

    public id?: string;

    public isPrimaryButton: boolean;

    public role: "normal"|"destructive";
}
