<ion-list>
    <ion-item *ngFor="let account of accounts" (click)="selectAccount(account)">
        <ion-avatar slot="start">
            <img alt="Account" src="assets/logos/cyanaudit-signum.svg" />
        </ion-avatar>
        <ion-label>
            {{account.name}}
        </ion-label>
        <fa-icon [icon]="appIcons.genericHourglass" slot="end" *ngIf="account == switchingTo"></fa-icon>
        <fa-icon [icon]="appIcons.genericCheck" slot="end" *ngIf="account != switchingTo && selectedAccount && account.id == selectedAccount.id"></fa-icon>
    </ion-item>

    <ion-item (click)="manageAccounts()" lines="none">
        <fa-icon [icon]="appIcons.accountSwitchManageIcon" slot="start"></fa-icon>
        <ion-label i18n="@@accountsPopover.manageAccounts">Manage accounts</ion-label>
    </ion-item>
</ion-list>
