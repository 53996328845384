import { registerPlugin } from "@capacitor/core";

import { CameraPreviewPlugin } from "./definitions";

// eslint-disable-next-line @typescript-eslint/naming-convention
const CameraPreview: CameraPreviewPlugin = registerPlugin<CameraPreviewPlugin>("CameraPreview", {
    web: () => import("./camera-preview-web").then((m: any) => new m.CameraPreviewWeb())
});

export * from "./definitions";
export { CameraPreview };
