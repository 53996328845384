import { Injectable } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { AttachmentStates } from "../../entities/attachments/attachment-states";
import { DocumentStatus } from "../../entities/documents/document-status";
import { QuestionSaveStates } from "../../entities/documents/question-save-states";
import { EntityTypes } from "../../entities/entity-types";
import { ProcessStatus } from "../../entities/projects-processes/process-status";
import { AppIcons } from "./app-icons";

/**
 * A UI helper service to get icons for mainly enumerations.
 */
@Injectable({
    providedIn: "root"
})
export class IconsService {
    public getProcessStatusFaIcon(processStatus: ProcessStatus): IconDefinition {
        switch (processStatus) {
            case ProcessStatus.unknown:
                return AppIcons.documentStatusUnknown;
            case ProcessStatus.orderEntry:
                return AppIcons.documentStatusOrderEntry;
            case ProcessStatus.scheduled:
                return AppIcons.documentStatusScheduled;
            case ProcessStatus.inProgress:
                return AppIcons.documentStatusInProgress;
            case ProcessStatus.inReview:
                return AppIcons.documentStatusInReview;
            case ProcessStatus.reviewAccepted:
                return AppIcons.documentStatusReviewAccepted;
            case ProcessStatus.reviewRejected:
                return AppIcons.documentStatusReviewRejected;
            case ProcessStatus.done:
                return AppIcons.documentStatusDone;
            default:
                TypeScriptHelper.expectNever(processStatus);
                return AppIcons.documentStatusUnknown;
        }
    }

    public getAuditDocumentStatusFaIcon(auditState: DocumentStatus): IconDefinition {
        switch (auditState) {
            case DocumentStatus.unknown:
                return AppIcons.documentStatusUnknown;
            case DocumentStatus.orderEntry:
                return AppIcons.documentStatusOrderEntry;
            case DocumentStatus.scheduled:
                return AppIcons.documentStatusScheduled;
            case DocumentStatus.inProgress:
                return AppIcons.documentStatusInProgress;
            case DocumentStatus.inReview:
                return AppIcons.documentStatusInReview;
            case DocumentStatus.reviewAccepted:
                return AppIcons.documentStatusReviewAccepted;
            case DocumentStatus.reviewRejected:
                return AppIcons.documentStatusReviewRejected;
            case DocumentStatus.done:
                return AppIcons.documentStatusDone;
            default:
                TypeScriptHelper.expectNever(auditState);
                return AppIcons.documentStatusUnknown;
        }
    }

    public getQuestionSaveStateIonicIcon(saveState: QuestionSaveStates): string {
        switch (saveState) {
            case QuestionSaveStates.none:
                return "checkmark-outline";
            case QuestionSaveStates.updateCreated:
                return "ellipse-outline";
            case QuestionSaveStates.persistedLocally:
                return "cloudy-outline";
            case QuestionSaveStates.sendingToServer:
                return "cloud-upload-outline";
            case QuestionSaveStates.acceptedByServer:
                return "cloud-done-outline";
            case QuestionSaveStates.rejectedByServer:
                return "warning";
            default:
                TypeScriptHelper.expectNever(saveState);
                return "checkmark-outline";
        }
    }

    public getQuestionSaveStateFaIcon(saveState: QuestionSaveStates): IconDefinition {
        switch (saveState) {
            case QuestionSaveStates.none:
                return AppIcons.answerUpdateUnknown;
            case QuestionSaveStates.updateCreated:
                return AppIcons.answerUpdateCreated;
            case QuestionSaveStates.persistedLocally:
                return AppIcons.answerUpdatePersistedLocally;
            case QuestionSaveStates.sendingToServer:
                return AppIcons.answerUpdateSendingToServer;
            case QuestionSaveStates.acceptedByServer:
                return AppIcons.answerUpdateAcceptedByServer;
            case QuestionSaveStates.rejectedByServer:
                return AppIcons.answerUpdateRejectedByServer;
            default:
                TypeScriptHelper.expectNever(saveState);
                return AppIcons.answerUpdateUnknown;
        }
    }

    public getAttachmentStateFaIcon(states: AttachmentStates): IconDefinition {
        switch (states) {
            case AttachmentStates.unknown:
                return AppIcons.attachmentStateUnknown;
            case AttachmentStates.storingLocally:
                return AppIcons.attachmentStateCreated;
            case AttachmentStates.storedLocally:
                return AppIcons.attachmentStatePersistedLocally;
            case AttachmentStates.uploading:
                return AppIcons.attachmentStateSendingToServer;
            case AttachmentStates.uploaded:
                return AppIcons.attachmentStateMetaUpdated;
            case AttachmentStates.updatingMeta:
                return AppIcons.attachmentStateUpdatingMeta;
            case AttachmentStates.metaUpdated:
                return AppIcons.attachmentStateMetaUpdated;
            case AttachmentStates.noInternetUploading:
            case AttachmentStates.noInternetUpdatingMeta:
                return AppIcons.attachmentStateNoInternet;
            case AttachmentStates.errorUploading:
            case AttachmentStates.errorUpdatingMeta:
                return AppIcons.attachmentStateRejectedByServer;
            default:
                TypeScriptHelper.expectNever(states);
                return AppIcons.attachmentStateUnknown;
        }
    }

    public getDocumentSectionIcon(iconName?: string): IconDefinition|undefined {
        switch (iconName) {
            case "masterData":
                return AppIcons.documentMasterData;
            case "customer":
                return AppIcons.entityPersonDuotone;
            case "building":
                return AppIcons.entityBuildingDuotone;
            case "riskAssessment":
                return AppIcons.documentRiskAssessment;
            case "mandatoryTesting":
                return AppIcons.documentMandatoryTesting;
            case "drinkingWaterInstallation":
                return AppIcons.documentDrinkingWaterInstallation;
            case "pipeSystem":
                return AppIcons.documentPipeSystem;
            case "heating":
                return AppIcons.documentHeating;
            case "drinkingWaterHeating":
                return AppIcons.documentDrinkingWaterHeating;
            case "warmWaterSystem":
                return AppIcons.documentWarmWaterSystem;
            case "circulationSystem":
                return AppIcons.documentCirculationSystem;
            case "documentation":
                return AppIcons.documentDocumentation;
            case "photo":
                return AppIcons.documentPhotos;
            default:
                return undefined;
        }
    }

    // eslint-disable-next-line complexity
    public getEntityIconDuotone(entityType: EntityTypes): IconDefinition {
        switch (entityType) {
            case EntityTypes.all:
                return AppIcons.entityAllDuotone;
            case EntityTypes.coreData:
                return AppIcons.entityCoreDataDuotone;
            case EntityTypes.project:
                return AppIcons.entityProjectDuotone;
            case EntityTypes.process:
                return AppIcons.entityProcessDuotone;
            case EntityTypes.person:
                return AppIcons.entityPersonDuotone;
            case EntityTypes.address:
                return AppIcons.entityAddressDuotone;
            case EntityTypes.buildingComplex:
                return AppIcons.entityBuildingComplexDuotone;
            case EntityTypes.building:
                return AppIcons.entityBuildingDuotone;
            case EntityTypes.buildingFeatures:
                return AppIcons.entityBuildingFeaturesDuotone;
            case EntityTypes.company:
                return AppIcons.entityCompanyDuotone;
            case EntityTypes.sample:
            case EntityTypes.sampleMerge:
                return AppIcons.entitySampleDuotone;
            case EntityTypes.samplingPlan:
            case EntityTypes.samplingPlanMerge:
                return AppIcons.entitySamplingPlanDuotone;
            case EntityTypes.dashboardProcesses:
                return AppIcons.entityDashboardDuotone;
            case EntityTypes.document:
            case EntityTypes.documentCommon:
                return AppIcons.entityDocumentDuotone;
            case EntityTypes.aiAnalysis:
                return AppIcons.entityAiAnalysisDuotone;
            case EntityTypes.unknown:
            case EntityTypes.chapter:
            case EntityTypes.section:
            case EntityTypes.question:
            case EntityTypes.image:
            case EntityTypes.attachment:
            case EntityTypes.attachments:
            case EntityTypes.revisions:
            case EntityTypes.review:
            case EntityTypes.roomFeature:
                return AppIcons.entityUnknownDuotone;
            default:
                TypeScriptHelper.expectNever(entityType);
                return AppIcons.entityUnknownDuotone;
        }
    }

    // eslint-disable-next-line complexity
    public getEntityIconRegular(entityType: EntityTypes): IconDefinition {
        switch (entityType) {
            case EntityTypes.all:
                return AppIcons.entityAllRegular;
            case EntityTypes.coreData:
                return AppIcons.entityCoreDataRegular;
            case EntityTypes.project:
                return AppIcons.entityProjectRegular;
            case EntityTypes.process:
                return AppIcons.entityProcessRegular;
            case EntityTypes.person:
                return AppIcons.entityPersonRegular;
            case EntityTypes.address:
                return AppIcons.entityAddressRegular;
            case EntityTypes.buildingComplex:
                return AppIcons.entityBuildingComplexRegular;
            case EntityTypes.building:
                return AppIcons.entityBuildingRegular;
            case EntityTypes.buildingFeatures:
                return AppIcons.entityBuildingFeaturesRegular;
            case EntityTypes.company:
                return AppIcons.entityCompanyRegular;
            case EntityTypes.sample:
            case EntityTypes.sampleMerge:
                return AppIcons.entitySampleRegular;
            case EntityTypes.samplingPlan:
            case EntityTypes.samplingPlanMerge:
                return AppIcons.entitySamplingPlanRegular;
            case EntityTypes.dashboardProcesses:
                return AppIcons.entityDashboardRegular;
            case EntityTypes.document:
            case EntityTypes.documentCommon:
                return AppIcons.entityDocumentRegular;
            case EntityTypes.aiAnalysis:
                return AppIcons.entityAiAnalysisRegular;
            case EntityTypes.unknown:
            case EntityTypes.chapter:
            case EntityTypes.section:
            case EntityTypes.question:
            case EntityTypes.image:
            case EntityTypes.attachment:
            case EntityTypes.attachments:
            case EntityTypes.revisions:
            case EntityTypes.review:
            case EntityTypes.roomFeature:
                return AppIcons.entityUnknownRegular;
            default:
                TypeScriptHelper.expectNever(entityType);
                return AppIcons.entityUnknownRegular;
        }
    }

    // eslint-disable-next-line complexity
    public getEntityIconSolid(entityType: EntityTypes): IconDefinition {
        switch (entityType) {
            case EntityTypes.all:
                return AppIcons.entityAllSolid;
            case EntityTypes.coreData:
                return AppIcons.entityCoreDataSolid;
            case EntityTypes.project:
                return AppIcons.entityProjectSolid;
            case EntityTypes.process:
                return AppIcons.entityProcessSolid;
            case EntityTypes.person:
                return AppIcons.entityPersonSolid;
            case EntityTypes.address:
                return AppIcons.entityAddressSolid;
            case EntityTypes.buildingComplex:
                return AppIcons.entityBuildingComplexSolid;
            case EntityTypes.building:
                return AppIcons.entityBuildingSolid;
            case EntityTypes.buildingFeatures:
                return AppIcons.entityBuildingFeaturesSolid;
            case EntityTypes.company:
                return AppIcons.entityCompanySolid;
            case EntityTypes.sample:
            case EntityTypes.sampleMerge:
                return AppIcons.entitySampleSolid;
            case EntityTypes.samplingPlan:
            case EntityTypes.samplingPlanMerge:
                return AppIcons.entitySamplingPlanSolid;
            case EntityTypes.dashboardProcesses:
                return AppIcons.entityDashboardSolid;
            case EntityTypes.document:
            case EntityTypes.documentCommon:
                return AppIcons.entityDocumentSolid;
            case EntityTypes.aiAnalysis:
                return AppIcons.entityAiAnalysisSolid;
            case EntityTypes.unknown:
            case EntityTypes.chapter:
            case EntityTypes.section:
            case EntityTypes.question:
            case EntityTypes.image:
            case EntityTypes.attachment:
            case EntityTypes.attachments:
            case EntityTypes.revisions:
            case EntityTypes.review:
            case EntityTypes.roomFeature:
                return AppIcons.entityUnknownSolid;
            default:
                TypeScriptHelper.expectNever(entityType);
                return AppIcons.entityUnknownSolid;
        }
    }
}
