<base-page-content-container [usePadding]="true">
    <ion-item *ngIf="!options.dialogMode || options.allowCreate || options.allowInvite" class="headlineItem" lines="none">
        <ng-container *ngIf="!options.dialogMode">
            <h2 *ngIf="options.entityType == entityTypes.all" class="pageTitle" i18n="@@coreData.selectEntityTitle">Select dataset</h2>
            <h2 *ngIf="options.entityType == entityTypes.project" class="pageTitle" i18n="@@coreData.projectsListTitle">Projects</h2>
            <h2 *ngIf="options.entityType == entityTypes.buildingComplex" class="pageTitle" i18n="@@coreData.buildingComplexesListTitle">Building complexes</h2>
            <h2 *ngIf="options.entityType == entityTypes.building" class="pageTitle" i18n="@@coreData.buildingsListTitle">Buildings</h2>
            <h2 *ngIf="options.entityType == entityTypes.person" class="pageTitle" i18n="@@coreData.personsListTitle">Persons</h2>
            <h2 *ngIf="options.entityType == entityTypes.company" class="pageTitle" i18n="@@coreData.companiesListTitle">Companies</h2>
        </ng-container>
        <ion-buttons class="pageActions actionButtons" slot="end">
            <ng-container *ngIf="options.allowCreate">
                <base-process-button (triggered)="createEntity($event)" *ngIf="options.entityType == entityTypes.project" [hasPermission]="hasCreatePermission" [icon]="appIcons.genericPlus" [requiredPermissions]="requiredCreatePermissions" [showLoadingAnimation]="false" buttonStyle="stroked" color="primary" i18n="@@coreData.createProjectButton">Create project</base-process-button>
                <base-process-button (triggered)="createEntity($event)" *ngIf="options.entityType == entityTypes.buildingComplex" [hasPermission]="hasCreatePermission" [icon]="appIcons.genericPlus" [requiredPermissions]="requiredCreatePermissions" [showLoadingAnimation]="false" buttonStyle="stroked" color="primary" i18n="@@coreData.createBuildingComplexButton">Create building complex</base-process-button>
                <base-process-button (triggered)="createEntity($event)" *ngIf="options.entityType == entityTypes.building" [hasPermission]="hasCreatePermission" [icon]="appIcons.genericPlus" [requiredPermissions]="requiredCreatePermissions" [showLoadingAnimation]="false" buttonStyle="stroked" color="primary" i18n="@@coreData.createBuildingButton">Create building</base-process-button>
                <base-process-button (triggered)="createEntity($event)" *ngIf="options.entityType == entityTypes.person" [hasPermission]="hasCreatePermission" [icon]="appIcons.genericPlus" [requiredPermissions]="requiredCreatePermissions" [showLoadingAnimation]="false" buttonStyle="stroked" color="primary" i18n="@@coreData.createPersonButton">Create person</base-process-button>
                <base-process-button (triggered)="createEntity($event)" *ngIf="options.entityType == entityTypes.company" [hasPermission]="hasCreatePermission" [icon]="appIcons.genericPlus" [requiredPermissions]="requiredCreatePermissions" [showLoadingAnimation]="false" buttonStyle="stroked" color="primary" i18n="@@coreData.createCompanyButton">Create company</base-process-button>
            </ng-container>
            <base-process-button (triggered)="refresh($event)" [icon]="appIcons.genericRefresh" [showLoadingAnimation]="true" buttonStyle="stroked" color="primary" i18n="@@coreData.refreshButton">Refresh</base-process-button>
        </ion-buttons>
    </ion-item>

    <ion-searchbar #searchbar *ngIf="options.entityType != entityTypes.all" [(ngModel)]="searchQuery" [debounce]="searchInputDebounce" autofocus="autofocus"></ion-searchbar>

    <div *ngIf="searching" class="loading centerContent">
        <h2 *ngIf="options.entityType == entityTypes.project" i18n="@@coreData.searchingProjects">Searching projects...</h2>
        <h2 *ngIf="options.entityType == entityTypes.buildingComplex" i18n="@@coreData.searchingBuildingComplexes">Searching building complexes...</h2>
        <h2 *ngIf="options.entityType == entityTypes.building" i18n="@@coreData.searchingBuildings">Searching buildings...</h2>
        <h2 *ngIf="options.entityType == entityTypes.person" i18n="@@coreData.searchingPersons">Searching persons...</h2>
        <h2 *ngIf="options.entityType == entityTypes.company" i18n="@@coreData.searchingCompanies">Searching companies...</h2>
        <base-loading></base-loading>
    </div>

    <ion-list *ngIf="options.entityType == entityTypes.all" class="selectEntity">
        <ion-item (click)="switchToEntity(entityTypes.project)" lines="none">
            <fa-icon [icon]="appIcons.entityProjectDuotone" slot="start"></fa-icon>
            <ion-label i18n="@@coreData.projects">Projects</ion-label>
        </ion-item>
        <ion-item (click)="switchToEntity(entityTypes.person)" lines="none">
            <fa-icon [icon]="appIcons.entityPersonDuotone" slot="start"></fa-icon>
            <ion-label i18n="@@coreData.persons">Persons</ion-label>
        </ion-item>
        <ion-item (click)="switchToEntity(entityTypes.company)" lines="none">
            <fa-icon [icon]="appIcons.entityCompanyDuotone" slot="start"></fa-icon>
            <ion-label i18n="@@coreData.companies">Companies</ion-label>
        </ion-item>
        <ion-item (click)="switchToEntity(entityTypes.buildingComplex)" lines="none">
            <fa-icon [icon]="appIcons.entityBuildingComplexDuotone" slot="start"></fa-icon>
            <ion-label i18n="@@coreData.buildingComplexes">Building complexes</ion-label>
        </ion-item>
        <ion-item (click)="switchToEntity(entityTypes.building)" lines="none">
            <fa-icon [icon]="appIcons.entityBuildingDuotone" slot="start"></fa-icon>
            <ion-label i18n="@@coreData.buildings">Buildings</ion-label>
        </ion-item>
    </ion-list>

    <div *ngIf="!searching && options.entityType != entityTypes.all" class="listContainer">
        <table #table [dataSource]="rows" mat-table>
            <ng-container matColumnDef="badge">
                <td *matCellDef="let row" mat-cell>
                    <business-identifier-badge [canOpen]="false" [customIcon]="itemIcon(row.entity)" [entity]="row.entity"></business-identifier-badge>
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <td *matCellDef="let row" mat-cell>
                    {{externalReference(row.entity)}}{{row.entity.displayTitle}}
                </td>
            </ng-container>

            <ng-container matColumnDef="subTitle">
                <td *matCellDef="let row" mat-cell>
                    {{row.entity.displaySubTitle}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <td *matCellDef="let row" mat-cell>
                    <button (click)="openActions($event, row.entity)" mat-icon-button>
                        <fa-icon [icon]="appIcons.genericListMoreActions"></fa-icon>
                    </button>
                </td>
            </ng-container>

            <tr (click)="entityClicked(row.entity)" *matRowDef="let row; columns: displayedColumns;" class="clickable hoverHighlight" mat-row></tr>
        </table>

        <div (click)="focusSearch()" *ngIf="!searching && rows.length <= 0" class="noResults">
            <fa-icon [icon]="appIcons.informationCircle"></fa-icon>
            <ion-label i18n="@@coreData.noResults">No results found. Please try broadening your search or check for any spelling errors.</ion-label>
        </div>

        <div (click)="focusSearch()" *ngIf="hasMoreResults" class="moreResults">
            <fa-icon [icon]="appIcons.informationCircle"></fa-icon>
            <ion-label i18n="@@coreData.tooManyResults">More than {{pageSize}} results found, please narrow down your search query.</ion-label>
        </div>
    </div>
</base-page-content-container>
