export enum UserPermissions {
    qualityAssuranceCreate = "QUALITY_ASSURANCE_CREATE",
    qualityAssuranceRead = "QUALITY_ASSURANCE_READ",

    walkthroughInspectionDeleteAll = "WALKTHROUGH_INSPECTION_DELETE_ALL",
    walkthroughInspectionDeleteOrderEntry = "WALKTHROUGH_INSPECTION_DELETE_ORDER_ENTRY",
    walkthroughInspectionDeleteScheduled = "WALKTHROUGH_INSPECTION_DELETE_SCHEDULED",
    walkthroughInspectionDeleteInProgress = "WALKTHROUGH_INSPECTION_DELETE_IN_PROGRESS",
    walkthroughInspectionDeleteQA = "WALKTHROUGH_INSPECTION_DELETE_QA",

    walkthroughInspectionCreate = "WALKTHROUGH_INSPECTION_CREATE",

    walkthroughInspectionUpdateOrderEntry = "WALKTHROUGH_INSPECTION_UPDATE_ORDER_ENTRY",
    walkthroughInspectionUpdateInProgress = "WALKTHROUGH_INSPECTION_UPDATE_IN_PROGRESS",
    walkthroughInspectionUpdateQa = "WALKTHROUGH_INSPECTION_UPDATE_QA",
    walkthroughInspectionAllowMoveToDone = "WALKTHROUGH_INSPECTION_DONE",

    buildingComplexCreate = "BUILDING_COMPLEX_CREATE",
    buildingComplexUpdate = "BUILDING_COMPLEX_UPDATE",
    buildingComplexDelete = "BUILDING_COMPLEX_DELETE",
    buildingCreate = "BUILDING_CREATE",
    buildingUpdate = "BUILDING_UPDATE",
    buildingDelete = "BUILDING_DELETE",
    processCreate = "PROCESS_CREATE",
    processUpdate = "PROCESS_UPDATE",
    processDelete = "PROCESS_DELETE",
    projectCreate = "PROJECT_CREATE",
    projectUpdate = "PROJECT_UPDATE",
    projectDelete = "PROJECT_DELETE",
    companyCreate = "COMPANY_CREATE",
    companyUpdate = "COMPANY_UPDATE",
    companyDelete = "COMPANY_DELETE",
    personCreate = "PERSON_CREATE",
    personUpdate = "PERSON_UPDATE_ALL",
    personDelete = "PERSON_DELETE",

    imageCreate = "PHOTO_CREATE",
    imageDelete = "PHOTO_DELETE"
}
