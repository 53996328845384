export enum LoadStrategies {
    /**
     * Check if it is cached. If there's a cached version return this and do not check the server version. If there is no cached version, load from server.
     */
    preferCached = "preferCached",

    /**
     * Ask the server, if there's no internet use the cached version.
     */
    preferServer = "preferServer",

    /**
     * Only use the version from cache.
     */
    cachedOnly = "cachedOnly",

    /**
     * Only use the version from server.
     */
    serverOnly = "serverOnly",

    /**
     * Only use the version from server and only fetch the latest version.
     */
    serverOnlyLatest = "serverOnlyLatest",
}
