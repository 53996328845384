import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IonicModule } from "@ionic/angular";
import { NgSelectModule } from "@ng-select/ng-select";
import { AngularPinturaModule } from "@pqina/angular-pintura";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { MarkdownModule } from "ngx-markdown";
import { SwiperModule } from "swiper/angular";

import { BaseComponentsModule } from "../../base/components/base-components.module";
import { DocumentValidationComponent } from "../pages/document/components/document-validation/document-validation.component";
import { QuestionContainerDirective } from "../pages/document/components/question-items/question-container.directive";
import { SingleImageComponent } from "../pages/document/components/question-items/single-image/single-image.component";
import { AboutComponent } from "./about/about.component";
import { AccountPopoverComponent } from "./account-popover/account-popover.component";
import { AccountSelectComponent } from "./account-select/account-select.component";
import { AccountSwitchPopoverComponent } from "./account-switch-popover/account-switch-popover.component";
import { AiAnalysisComponent } from "./ai-analysis/ai-analysis.component";
import { AudioRecordingComponent } from "./audio-recording/audio-recording.component";
import { ChatComponent } from "./chat/chat.component";
import { CloudStatusComponent } from "./cloud-status/cloud-status.component";
import { CloudStatusPopoverComponent } from "./cloud-status/cloud-status-popover/cloud-status-popover.component";
import { SyncErrorListComponent } from "./cloud-status/sync-error-list/sync-error-list.component";
import { EntitySelectionListComponent } from "./core-data/entity-selection-list/entity-selection-list.component";
import { DefaultDialogComponent } from "./default-dialog/default-dialog.component";
import { DiagramEditorComponent } from "./diagram-editor/diagram-editor.component";
import { CreateAuditDocumentComponent } from "./documents/create-audit-document/create-audit-document.component";
import { ErrorComponent } from "./error/error.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { AddressFormComponent } from "./forms/address-form/address-form.component";
import { BuildingComplexFormComponent } from "./forms/building-complex-form/building-complex-form.component";
import { BuildingFormComponent } from "./forms/building-form/building-form.component";
import { CompanyFormComponent } from "./forms/company-form/company-form.component";
import { EmailFormComponent } from "./forms/email-form/email-form.component";
import { EntityLinksComponent } from "./forms/entities/entity-links/entity-links.component";
import { FloorFormComponent } from "./forms/floor-form/floor-form.component";
import { MasterDataFormDialogComponent } from "./forms/form-dialog/master-data-form-dialog.component";
import { FormValidationErrorComponent } from "./forms/form-validation-error/form-validation-error.component";
import { PersonFormComponent } from "./forms/person-form/person-form.component";
import { ProcessFormComponent } from "./forms/process-form/process-form.component";
import { ProjectFormComponent } from "./forms/project-form/project-form.component";
import { RoomFeatureFormComponent } from "./forms/room-feature-form/room-feature-form.component";
import { RoomFeaturesTableComponent } from "./forms/room-features-table/room-features-table.component";
import { RoomFormComponent } from "./forms/room-form/room-form.component";
import { SampleFormComponent } from "./forms/sample-form/sample-form.component";
import { ArrowGameComponent } from "./games/arrow-game/arrow-game.component";
import { LegendPopoverComponent } from "./help/legend-popover/legend-popover.component";
import { ManualComponent } from "./help/manual/manual.component";
import { IdentifierBadgeComponent } from "./identifier-badge/identifier-badge.component";
import { ImageAnnotationDialogComponent } from "./image-annotation-dialog/image-annotation-dialog.component";
import { ImageAttachmentComponent } from "./image-attachment/image-attachment.component";
import { ImageEditorComponent } from "./image-editor/image-editor.component";
import { ImageFullViewComponent } from "./image-full-view/image-full-view.component";
import { InvitePersonComponent } from "./invite-person/invite-person.component";
import { ImageDownloadComponent } from "./media-view/image-download/image-download.component";
import { ImageGalleryComponent } from "./media-view/image-gallery/image-gallery.component";
import { MediaViewComponent } from "./media-view/media-view.component";
import { MenuComponent } from "./menu/menu.component";
import { NetworkTestComponent } from "./network-test/network-test.component";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { QuickAddComponent } from "./quick-add/quick-add.component";
import { AppSettingsComponent } from "./settings/app-settings/app-settings.component";
import { SignatureComponent } from "./signature/signature.component";
import { LoginComponent } from "./user/login/login.component";
import { RegistrationComponent } from "./user/registration/registration.component";

/**
 * The components-module that includes all globally used components.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SwiperModule,
        ReactiveFormsModule,
        BaseComponentsModule,
        FontAwesomeModule,
        ScrollingModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatButtonModule,
        MatTooltipModule,
        MatChipsModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        AngularPinturaModule,
        MatDatepickerModule,
        MatIconModule,
        MatProgressBarModule,
        MatRadioModule,
        MatAutocompleteModule,
        NgSelectModule,
        MatListModule,
        NgxExtendedPdfViewerModule,
        MarkdownModule,
        NgOptimizedImage
    ],
    declarations: [
        // Directives
        QuestionContainerDirective,

        // Components
        PageHeaderComponent,
        MenuComponent,
        QuickAddComponent,
        AudioRecordingComponent,
        AccountPopoverComponent,
        AccountSwitchPopoverComponent,
        MediaViewComponent,
        ErrorComponent,
        ErrorDialogComponent,
        LoginComponent,
        RegistrationComponent,
        AppSettingsComponent,
        FormValidationErrorComponent,
        DocumentValidationComponent,
        AccountSelectComponent,
        CompanyFormComponent,
        AddressFormComponent,
        FormValidationErrorComponent,
        AboutComponent,
        CreateAuditDocumentComponent,
        CloudStatusComponent,
        CloudStatusPopoverComponent,
        LegendPopoverComponent,
        ArrowGameComponent,
        ChatComponent,
        SignatureComponent,
        ProjectFormComponent,
        ProcessFormComponent,
        PersonFormComponent,
        InvitePersonComponent,
        MasterDataFormDialogComponent,
        BuildingComplexFormComponent,
        BuildingFormComponent,
        ImageAttachmentComponent,
        ImageFullViewComponent,
        FloorFormComponent,
        SampleFormComponent,
        RoomFormComponent,
        RoomFeatureFormComponent,
        RoomFeaturesTableComponent,
        DiagramEditorComponent,
        ImageAnnotationDialogComponent,
        ImageGalleryComponent,
        SingleImageComponent,
        DefaultDialogComponent,
        SyncErrorListComponent,
        PdfViewerComponent,
        EntityLinksComponent,
        PrivacyPolicyComponent,
        ManualComponent,
        EntitySelectionListComponent,
        IdentifierBadgeComponent,
        ImageEditorComponent,
        ImageDownloadComponent,
        NetworkTestComponent,
        AiAnalysisComponent,
        EmailFormComponent
    ],
    exports: [
        // Directives
        QuestionContainerDirective,

        // Components
        PageHeaderComponent,
        MenuComponent,
        QuickAddComponent,
        AudioRecordingComponent,
        AccountPopoverComponent,
        AccountSwitchPopoverComponent,
        MediaViewComponent,
        ErrorComponent,
        ErrorDialogComponent,
        LoginComponent,
        RegistrationComponent,
        AppSettingsComponent,
        FormValidationErrorComponent,
        DocumentValidationComponent,
        AccountSelectComponent,
        CompanyFormComponent,
        AddressFormComponent,
        FormValidationErrorComponent,
        AboutComponent,
        CreateAuditDocumentComponent,
        CloudStatusComponent,
        CloudStatusPopoverComponent,
        LegendPopoverComponent,
        ArrowGameComponent,
        ChatComponent,
        SignatureComponent,
        ProjectFormComponent,
        ProcessFormComponent,
        PersonFormComponent,
        MasterDataFormDialogComponent,
        BuildingComplexFormComponent,
        BuildingFormComponent,
        InvitePersonComponent,
        DiagramEditorComponent,
        ImageAttachmentComponent,
        ImageFullViewComponent,
        FloorFormComponent,
        SampleFormComponent,
        RoomFormComponent,
        RoomFeatureFormComponent,
        RoomFeaturesTableComponent,
        ImageAnnotationDialogComponent,
        ImageGalleryComponent,
        SingleImageComponent,
        DefaultDialogComponent,
        SyncErrorListComponent,
        PdfViewerComponent,
        EntityLinksComponent,
        PrivacyPolicyComponent,
        ManualComponent,
        EntitySelectionListComponent,
        IdentifierBadgeComponent,
        ImageEditorComponent,
        ImageDownloadComponent,
        NetworkTestComponent,
        NgxExtendedPdfViewerModule,
        AiAnalysisComponent
    ]
})
export class BusinessComponentsModule {
}
