import { Sample } from "../../entities/samplings/sample";
import { SampleMerge } from "../../entities/samplings/sample-merge";

/**
 * A single error for a sampling plan.
 */
export class SampleError<TSample extends Sample|SampleMerge> {
    constructor(sample: TSample, errorMessage: string) {
        this.sample = sample;
        this.errorMessage = errorMessage;
    }

    public sample: TSample;

    public errorMessage: string;
}
