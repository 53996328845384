import { Injectable } from "@angular/core";
import { NgxIndexedDBService, WithID } from "ngx-indexed-db";
import { lastValueFrom } from "rxjs";

import { environment } from "../../../../environments/environment";
import { LocalFileStorageIdentifier } from "./local-file-storage-identifier";
import { LocaleFileStorageItem } from "./locale-file-storage-item";

/**
 * Service to store files locally.
 */
@Injectable({
    providedIn: "root"
})
export class LocalFileService {
    constructor(
        private readonly indexedDbService: NgxIndexedDBService
    ) {
    }

    private readonly storeName: string = environment.fileStorageName;

    public async storeFile(blob: Blob): Promise<LocalFileStorageIdentifier> {
        const dbEntry: LocaleFileStorageItem = new LocaleFileStorageItem({
            blob: blob
        });

        const result: LocaleFileStorageItem&WithID = await lastValueFrom(this.indexedDbService.add(this.storeName, dbEntry));
        return result.id as LocalFileStorageIdentifier;
    }

    public async loadFromStorage(identifier: LocalFileStorageIdentifier): Promise<LocaleFileStorageItem|undefined> {
        return await lastValueFrom(this.indexedDbService.getByKey<LocaleFileStorageItem>(this.storeName, identifier)) as LocaleFileStorageItem;
    }

    public async deleteFile(identifier: LocalFileStorageIdentifier): Promise<void> {
        await lastValueFrom(this.indexedDbService.delete(this.storeName, identifier));
    }

    public async itemsCount(): Promise<number> {
        return lastValueFrom(this.indexedDbService.count(this.storeName));
    }
}
