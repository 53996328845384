<form *ngIf="building" [formGroup]="formData" enter-to-tab>
    <div class="coreDataGroup">
        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="nameField">
            <mat-label>
                <fa-icon [icon]="appIcons.buildingName"></fa-icon>&nbsp;<ng-container i18n="@@building.name">Building name</ng-container>
            </mat-label>
            <input #nextTab [formControl]="nameControl" [maxlength]="formSettings.maxLengthName" [readonly]="readonly" autocapitalize="on" autocomplete="new-building-name" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(nameControl, formSettings.maxLengthName)}}</mat-hint>
            <mat-error *ngIf="nameControl.invalid">{{getErrorMessage(nameControl)}}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="formHelper.appearance(readonly)" [class.readonly]="readonly" class="buildingUsageField">
            <mat-label>
                <fa-icon [icon]="appIcons.buildingUsage"></fa-icon>&nbsp;<ng-container i18n="@@building.buildingUsagePlaceholder">Building usage</ng-container>
            </mat-label>

            <mat-select [disabled]="readonly" [formControl]="buildingUsageControl">
                <mat-option *ngFor="let usageType of usageTypeLabels" [value]="usageType.id">{{usageType.label}}</mat-option>
            </mat-select>
        </mat-form-field>

        <section>
            <mat-checkbox [disabled]="readonly" [formControl]="publicUsageControl" color="primary" i18n="@@building.publicUsage">Public usage</mat-checkbox>
        </section>

        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="usageDescriptionField">
            <mat-label>
                <fa-icon [icon]="appIcons.buildingUsage"></fa-icon>&nbsp;<ng-container i18n="@@building.usageDescription">Usage description</ng-container>
            </mat-label>
            <input #nextTab [formControl]="usageDescriptionControl" [maxlength]="formSettings.maxLengthUsageDescription" [readonly]="readonly" autocomplete="new-building-usage" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(usageDescriptionControl, formSettings.maxLengthUsageDescription)}}</mat-hint>
            <mat-error *ngIf="usageDescriptionControl.invalid">{{getErrorMessage(usageDescriptionControl)}}</mat-error>
        </mat-form-field>
    </div>

    <div class="coreDataGroup">
        <mat-button-toggle-group [formControl]="selectedAddressControl">
            <mat-button-toggle *ngFor="let address of building.addresses; let index = index" value="{{index}}">
                <fa-icon [icon]="appIcons.addressRaw"></fa-icon>&nbsp;<ng-container i18n="@@address.addressWithIndex">Address {{index + 1}}</ng-container>
            </mat-button-toggle>
            <mat-button-toggle *ngIf="!readonly" value="+">
                <fa-icon [icon]="appIcons.genericAdd"></fa-icon>&nbsp;<ng-container i18n="@@address.addAddress">Add address</ng-container>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div class="marginTop"></div>
        <business-address-form #nextTab (isValidChange)="addressChanged()" *ngIf="addressIndex < building.addresses.length" [address]="building.addresses[addressIndex]" [readonly]="readonly"></business-address-form>
        <div *ngIf="!readonly && building.addresses.length > 1" class="alignRightContent">
            <button (click)="deleteAddress(addressIndex)" color="warn" mat-button>
                <fa-icon [icon]="appIcons.genericDelete"></fa-icon>
                <label i18n="@@address.deleteAddressWithIndex">Delete address {{addressIndex + 1}}</label>
            </button>
        </div>
    </div>

    <div class="coreDataGroup">
        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="constructionYearField">
            <mat-label>
                <fa-icon [icon]="appIcons.constructionYear"></fa-icon>&nbsp;<ng-container i18n="@@building.constructionYear">Construction year</ng-container>
            </mat-label>
            <input #nextTab [formControl]="constructionDateControl" [readonly]="readonly" autocomplete="new-construction-year" matInput max="9999" maxlength="4" min="1000" step="1" type="number">
            <mat-error *ngIf="constructionDateControl.invalid">{{getErrorMessage(constructionDateControl)}}</mat-error>
        </mat-form-field>

        <div class="groupedInputs">
            <mat-form-field [appearance]="formHelper.appearance(readonly)" class="numberOfFlatsField">
                <mat-label>
                    <fa-icon [icon]="appIcons.numberOfFlats"></fa-icon>&nbsp;<ng-container i18n="@@building.numberOfFlats">Number of flats</ng-container>
                </mat-label>
                <input #nextTab [formControl]="numberOfFlatsControl" [readonly]="readonly" autocomplete="new-number-of-flats" matInput min="0" step="1" type="number">
                <mat-error *ngIf="numberOfFlatsControl.invalid">{{getErrorMessage(numberOfFlatsControl)}}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="formHelper.appearance(readonly)">
                <mat-label>
                    <fa-icon [icon]="appIcons.numberOfOffices"></fa-icon>&nbsp;<ng-container i18n="@@building.numberOfCommercialUnits">Number of commercial units</ng-container>
                </mat-label>
                <input #nextTab [formControl]="numberOfOfficesControl" [readonly]="readonly" autocomplete="new-number-of-commercial-units" matInput min="0" step="1" type="number">
                <mat-error *ngIf="numberOfOfficesControl.invalid">{{getErrorMessage(numberOfOfficesControl)}}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="formHelper.appearance(readonly)">
                <mat-label>
                    <fa-icon [icon]="appIcons.numberOfUnusedFlatsAndOffices"></fa-icon>&nbsp;<ng-container i18n="@@building.numberOfUnusedFlatsAndCommercialUnits">Number of unused flats and unused commercial units</ng-container>
                </mat-label>
                <input #nextTab [formControl]="numberOfUnusedFlatsAndOfficesControl" [readonly]="readonly" autocomplete="new-number-of-unused" matInput min="0" step="1" type="number">
                <mat-error *ngIf="numberOfUnusedFlatsAndOfficesControl.invalid">{{getErrorMessage(numberOfUnusedFlatsAndOfficesControl)}}</mat-error>
            </mat-form-field>
        </div>

        <div class="groupedInputs">
            <mat-form-field [appearance]="formHelper.appearance(readonly)">
                <mat-label>
                    <fa-icon [icon]="appIcons.visibleVerticalRisers" [rotate]="90"></fa-icon>&nbsp;<ng-container i18n="@@building.visibleVerticalRisers">Visible vertical risers</ng-container>
                </mat-label>
                <input #nextTab [formControl]="visibleAscendingPipesControl" [readonly]="readonly" autocomplete="new-visible-risers" matInput min="0" step="1" type="number">
                <mat-error *ngIf="visibleAscendingPipesControl.invalid">{{getErrorMessage(visibleAscendingPipesControl)}}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="formHelper.appearance(readonly)">
                <mat-label>
                    <fa-icon [icon]="appIcons.visibleVerticalRisers"></fa-icon>&nbsp;<ng-container i18n="@@building.riserEndsFloors">Riser ends (floors)</ng-container>
                </mat-label>
                <input #nextTab [formControl]="ascendingPipesEndsControl" [maxlength]="formSettings.maxLengthAscendingPipesEnds" [readonly]="readonly" autocomplete="new-riser-ends" matInput type="text">
                <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(ascendingPipesEndsControl, formSettings.maxLengthAscendingPipesEnds)}}</mat-hint>
                <mat-error *ngIf="ascendingPipesEndsControl.invalid">{{getErrorMessage(ascendingPipesEndsControl)}}</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="coreDataGroup">
        <section>
            <mat-checkbox [disabled]="readonly" [formControl]="installationPlansExistControl" color="primary">
                <fa-icon [icon]="appIcons.installationPlansExist"></fa-icon>&nbsp;<ng-container i18n="@@building.installationPlansExist">Installation plans available</ng-container>
            </mat-checkbox>
        </section>

        <mat-form-field [appearance]="formHelper.appearance(readonly)" [class.readonly]="readonly" class="typeOfDrinkingWaterHeatingField">
            <mat-label>
                <fa-icon [icon]="appIcons.typeOfDrinkingWaterHeating"></fa-icon>&nbsp;<ng-container i18n="@@building.typeOfDrinkingWaterHeating">Type of drinking water heating</ng-container>
            </mat-label>

            <mat-select [disabled]="readonly" [formControl]="typeOfDrinkingWaterHeatingControl">
                <mat-option *ngFor="let heatingType of heatingTypeLabels" [value]="heatingType.id">{{heatingType.label}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="drinkingWaterHeatingLocationField">
            <mat-label>
                <fa-icon [icon]="appIcons.locationHouseConnection"></fa-icon>&nbsp;<ng-container i18n="@@building.drinkingWaterHeatingLocation">Location of the drinking water heating</ng-container>
            </mat-label>
            <input #nextTab [formControl]="drinkingWaterHeatingLocationControl" [maxlength]="formSettings.maxLengthDrinkingWaterHeatingLocation" [readonly]="readonly" autocapitalize="on" autocomplete="new-drinking-water-heating-location" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(drinkingWaterHeatingLocationControl, formSettings.maxLengthDrinkingWaterHeatingLocation)}}</mat-hint>
            <mat-error *ngIf="drinkingWaterHeatingLocationControl.invalid">{{getErrorMessage(drinkingWaterHeatingLocationControl)}}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="locationHouseConnectionField">
            <mat-label>
                <fa-icon [icon]="appIcons.locationHouseConnection"></fa-icon>&nbsp;<ng-container i18n="@@building.locationHouseConnection">Location of the house connection</ng-container>
            </mat-label>
            <input #nextTab [formControl]="locationHouseConnectionControl" [maxlength]="formSettings.maxLengthLocationHouseConnection" [readonly]="readonly" autocapitalize="on" autocomplete="new-location-house-connection" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(locationHouseConnectionControl, formSettings.maxLengthLocationHouseConnection)}}</mat-hint>
            <mat-error *ngIf="locationHouseConnectionControl.invalid">{{getErrorMessage(locationHouseConnectionControl)}}</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="formHelper.appearance(readonly)">
            <mat-label>
                <fa-icon [icon]="appIcons.additionalInfo"></fa-icon>&nbsp;<ng-container i18n="@@building.additionalInformation">Additional information</ng-container>
            </mat-label>
            <textarea #nextTab [formControl]="additionalInfoControl" [maxlength]="formSettings.maxLengthAdditionalInfo" [readonly]="readonly" autocapitalize="on" autocomplete="new-additional-info" matInput type="text"></textarea>
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(additionalInfoControl, formSettings.maxLengthAdditionalInfo)}}</mat-hint>
            <mat-error *ngIf="additionalInfoControl.invalid">{{getErrorMessage(additionalInfoControl)}}</mat-error>
        </mat-form-field>
    </div>
</form>
