<ion-content [class.ion-padding]="usePadding">
    <ion-item lines="none" class="headline" *ngIf="title">
        <h2 class="pageTitle">{{title}}</h2>
        <div class="actions" slot="end">
            <ng-content select="[actions]"></ng-content>
        </div>
    </ion-item>

    <ng-content></ng-content>
</ion-content>
