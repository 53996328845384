/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionTemplateDto } from './questionTemplate';


export interface TemplateVersionDto { 
    readonly id?: number;
    readonly created?: string;
    readonly updated?: string;
    documentType?: TemplateVersionDto.DocumentTypeEnum;
    name?: string;
    questions?: Array<QuestionTemplateDto>;
}
export namespace TemplateVersionDto {
    export type DocumentTypeEnum = 'WALKTHROUGH_INSPECTION';
    export const DocumentTypeEnum = {
        WalkthroughInspection: 'WALKTHROUGH_INSPECTION' as DocumentTypeEnum
    };
}


