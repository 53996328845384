import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { EntityIdentifier } from "../../../identifiers/entity-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { RoomFeatureMapping } from "./room-feature-mapping";

/**
 * Basic class for rooms.
 */
export class Room {
    constructor(init?: Partial<Room>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public identifier: EntityIdentifier = Identifier.empty();
    public name?: string;
    public location?: string;

    public roomFeaturesMapping: Array<RoomFeatureMapping> = [];
}
