<div *ngIf="options" [class]="isOpen ? 'open' : 'close'" class="container">
    <div class="header">
        <h3>{{options.title}}</h3>
        <button (click)="close()" class="closeButton" mat-icon-button>
            <fa-icon [icon]="appIcons.genericCancel"></fa-icon>
        </button>
    </div>

    <p>{{options.message}}</p>

    <div class="buttons">
        <button (click)="close()" mat-button>
            <fa-icon [icon]="appIcons.genericClose"></fa-icon>
            <label i18n="@@interactiveToast.close">Close</label>
        </button>

        <button (click)="button.clickHandler($event)" *ngFor="let button of options.buttons" mat-button>
            <fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon>
            <label *ngIf="button.text">{{button.text}}</label>
        </button>
    </div>
</div>
