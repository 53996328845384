import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { ModalController } from "@ionic/angular";

import { environment } from "../../../../environments/environment";
import { AppIcons } from "../../../business/services/icons/app-icons";
import { CameraPosition, CameraPreview, CameraPreviewOptions } from "../../../modules/camera-preview";
import { AsyncHelper } from "../../helpers/async-helper";
import { CameraOptions } from "./camera-options";

/**
 * Component to capture images from the camera.
 */
@Component({
    selector: "app-camera",
    templateUrl: "./camera.component.html",
    styleUrls: ["./camera.component.scss"]
})
export class CameraComponent implements OnInit {
    constructor(
        private readonly modalController: ModalController
    ) {
        // Do nothing for now.
    }

    @Input()
    public options: CameraOptions = new CameraOptions();

    @ViewChild("cameraParent")
    public cameraParent?: ElementRef<HTMLDivElement>;

    private readonly showPreviewDuration: number = 1000;

    private showPreviewTimeout?: any;

    public readonly appIcons: typeof AppIcons = AppIcons;

    public flashModeSupported: boolean = false;

    public cameraPreviewLoaded: boolean = false;

    public currentCameraMode: CameraPosition = "rear";

    public torchActive: boolean = false;

    public frontCameraAvailable: boolean = false;

    public cameraPermissionDenied: boolean = false;

    public showFlash: boolean = false;

    public showPreview: boolean = false;

    public currentMultiShotImage?: string;

    public ngOnInit(): void {
        this.initialize().then();
    }

    private checkError(error: any): void {
        if (error?.name == "NotAllowedError") {
            this.cameraPermissionDenied = true;
        } else {
            console.info(error);
        }
    }

    private async initialize(): Promise<void> {
        await this.startCamera();
        this.flashModeSupported = await this.checkFlashModeSupported();
    }

    private async checkFlashModeSupported(): Promise<boolean> {
        return CameraPreview.torchSupported();
    }

    private async activateTorch(enabled: boolean): Promise<void> {
        this.torchActive = enabled;
        await CameraPreview.activateTorch(enabled);
    }

    private async checkFrontCameraAvailable(): Promise<boolean> {
        try {
            const devices: Array<MediaDeviceInfo> = (await navigator?.mediaDevices?.enumerateDevices())?.filter((device: MediaDeviceInfo) => device.kind == "videoinput");

            for (const device of devices) {
                if (device.label.toLowerCase().includes("front")) {
                    return true;
                }
            }
        } catch (error) {
            this.checkError(error);
        }

        return false;
    }

    public async startCamera(position: CameraPosition = "rear"): Promise<void> {
        this.cameraPreviewLoaded = false;

        try {
            await CameraPreview.stop();
        } catch (error) {
            try {
                const children: HTMLCollectionOf<Element>|undefined = this.cameraParent?.nativeElement?.getElementsByClassName("cameraContent");

                if (children) {
                    children.item(0)?.remove();
                }
            } catch (inner) {
                console.info(inner);
            }
        }

        try {
            // Wait until cameraParent is available
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            const waitUntil: number = Date.now() + 5000;
            while (!this.cameraParent?.nativeElement && Date.now() < waitUntil) {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                await AsyncHelper.sleep(10);
            }
            if (!this.cameraParent?.nativeElement) {
                await this.close();
                return;
            }

            // Wait some extra time to allow DOM rendering to resize everything. This should not be necessary, it's just for safety.
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            await AsyncHelper.sleep(10);

            const cameraPreviewOptions: CameraPreviewOptions = {
                className: "cameraContent",
                parent: this.cameraParent?.nativeElement,
                position: position,
                disableAudio: true,
                width: environment.cameraMaxSize,
                height: environment.cameraMaxSize
            };

            this.currentCameraMode = position;
            await CameraPreview.start(cameraPreviewOptions);
            this.frontCameraAvailable = await this.checkFrontCameraAvailable();
            this.cameraPermissionDenied = false;
        } catch (error) {
            this.checkError(error);
        }

        this.cameraPreviewLoaded = true;
    }

    public async capture(event?: Event): Promise<void> {
        event?.preventDefault();
        try {
            this.showFlash = true;
            if (this.showPreviewTimeout) {
                clearTimeout(this.showPreviewTimeout);
                this.showPreviewTimeout = undefined;
            }

            const picture: { value: string } = await CameraPreview.capture({
                width: environment.cameraMaxSize,
                height: environment.cameraMaxSize,
                quality: environment.cameraImageQuality
            });

            if (this.options.multiShotActivated && this.options.multiShotCallback) {
                this.currentMultiShotImage = `data:image/png;base64,${picture.value}`;
                this.showPreview = true;
                this.options.multiShotCallback(picture.value);

                this.showPreviewTimeout = setTimeout(() => {
                    this.showPreview = false;
                }, this.showPreviewDuration);
            } else {
                this.close(picture.value).then();
            }
        } catch (error) {
            console.info(error);
        } finally {
            setTimeout(() => {
                this.showFlash = false;
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            }, 200);
        }
    }

    public async close(base64Content?: string, event?: Event): Promise<void> {
        event?.preventDefault();
        try {
            if (!this.cameraPermissionDenied) {
                await this.activateTorch(false);
                await CameraPreview.stop();
            }
        } catch (error) {
            this.checkError(error);
        }

        await this.modalController.dismiss(base64Content);
    }

    public async toggleFlash(): Promise<void> {
        await this.activateTorch(!this.torchActive);
    }

    public async rotateCamera(): Promise<void> {
        try {
            if (Capacitor.isNativePlatform()) {
                await CameraPreview.flip();
            } else {
                await this.startCamera(this.currentCameraMode == "rear" ? "front" : "rear");
            }
        } catch (error) {
            this.checkError(error);
        }
    }

    public changeMultiShotMode(): void {
        this.options.multiShotActivated = !this.options.multiShotActivated;
    }
}
