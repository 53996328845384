/**
 * Error messages show in the form validation component.
 */
export class FormValidationErrorMessages {
    public controlMessages: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();

    public constructor(controlMessages: Array<{ controlName: string; messages: Array<{type: string; message: string}>}>) {
        for (const control of controlMessages) {
            const messages: Map<string, string> = new Map<string, string>();
            for (const message of control.messages) {
                messages.set(message.type, message.message);
            }
            this.controlMessages.set(control.controlName, messages);
        }
    }
}
