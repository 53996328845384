import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";

import { environment } from "../../../../../environments/environment";
import { EnvironmentInterface } from "../../../../../environments/environment-interface";
import { ProcessElementTriggeredEventArguments } from "../../../../base/interfaces/process-element-triggered-event-arguments";
import { ApiService } from "../../../services/api/api.service";
import { AppService } from "../../../services/app/app.service";
import { AppState } from "../../../services/app/app-state";
import { AppIcons } from "../../../services/icons/app-icons";
import { SessionService } from "../../../services/session/session.service";
import { FormValidationErrorMessages } from "../../forms/form-validation-error/form-validation-error-messages";

/**
 * Settings component for app related configuration. Most of them are stored in the local storage.
 */
@Component({
    selector: "app-app-settings",
    templateUrl: "./app-settings.component.html",
    styleUrls: ["./app-settings.component.scss"]
})
export class AppSettingsComponent implements OnInit {
    constructor(
        private readonly appService: AppService,
        private readonly apiService: ApiService,
        private readonly sessionService: SessionService,
        private readonly formBuilder: FormBuilder,
        private readonly modalController: ModalController
    ) {
        this.serverUrlControl = this.formData.get("serverUrl")!;
    }

    public readonly appState: AppState = this.appService.appState;

    public readonly appIcons: typeof AppIcons = AppIcons;

    public isDebug: boolean = !environment.production;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public hiddenSettingsVisible: number = -10;

    public validationMessages: FormValidationErrorMessages = new FormValidationErrorMessages(
        [
            {
                controlName: "serverUrl", messages: [
                    { type: "pattern", message: "Invalid format. Correct example: https://domain.tld/v1" }
                ]
            }
        ]
    );

    public formData: FormGroup = this.formBuilder.group({
        serverUrl: [environment.apiBasePath, [Validators.pattern("(.*)")]]
    });

    public readonly serverUrlControl: AbstractControl;

    protected readonly environment: EnvironmentInterface = environment;

    public ngOnInit(): void {
        this.serverUrlControl.setValue(this.apiService.getApiUrl());
    }

    public async save(triggeredEventArguments?: ProcessElementTriggeredEventArguments): Promise<void> {
        if (this.isDebug || this.hiddenSettingsVisible > 0 && this.serverUrlControl.status == "VALID") {
            this.apiService.setApiUrl(this.serverUrlControl.value);
        }

        triggeredEventArguments?.finishedReceiver.finished();
    }

    public activateHiddenSettings(): void {
        this.hiddenSettingsVisible++;
    }

    public close(): void {
        this.modalController.dismiss().then();
    }

    public toggleInspectionMode(triggeredEventArguments: ProcessElementTriggeredEventArguments): void {
        this.appService.appState.inspectionMode = !this.appService.appState.inspectionMode;
        triggeredEventArguments.finishedReceiver.finished();
    }

    public restartApp(_triggeredEventArguments: ProcessElementTriggeredEventArguments): void {
        this.appService.restartApp(false).then();
    }

    public async changePassword(triggeredEventArguments: ProcessElementTriggeredEventArguments): Promise<void> {
        await this.sessionService.changePassword();
        triggeredEventArguments.finishedReceiver.finished();
    }

    public toggleGpt(triggeredEventArguments: ProcessElementTriggeredEventArguments): void {
        environment.featureGpt = !environment.featureGpt;
        triggeredEventArguments.finishedReceiver.finished();
    }
}
