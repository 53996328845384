import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { EntityBusinessIdentifier } from "../../identifiers/entity-business-identifier";
import { EntityIdentifier } from "../../identifiers/entity-identifier";
import { EntityTechnicalIdentifier } from "../../identifiers/entity-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { GenericCoreDataEntity } from "../core-data/generic-core-data-entity";
import { EntityTypes } from "../entity-types";
import { StorableEntity } from "../storable-entity";
import { EntityLinkStorageDto } from "./entity-link-storage-dto";

/**
 * Link to an entity.
 */
export class EntityLink<TEntity extends GenericCoreDataEntity> implements StorableEntity<EntityLinkStorageDto> {
    constructor(identifier: EntityIdentifier, entityType: EntityTypes, entity?: TEntity, init?: Partial<EntityLink<TEntity>>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.identifier = identifier;
        this.entityType = entityType;
        this.entity = entity;
    }

    public identifier: EntityIdentifier;

    public entityType: EntityTypes;

    public linkDescription?: string;

    public identifierPairPropertyName?: string;

    public entity?: TEntity;

    public latestEntity?: TEntity;

    public toStorageDto(): EntityLinkStorageDto {
        return new EntityLinkStorageDto({
            entityType: this.entityType,
            entityBusinessId: this.identifier.businessIdentifier as EntityBusinessIdentifier,
            entityTechnicalId: this.identifier.technicalIdentifier as EntityTechnicalIdentifier,
            identifierPairPropertyName: this.identifierPairPropertyName,
            linkDescription: this.linkDescription
        });
    }

    public fromStorageDto(dto: EntityLinkStorageDto|undefined): void {
        // `entity` has to be set from outside.

        this.identifierPairPropertyName = dto?.identifierPairPropertyName ?? "";
        this.linkDescription = dto?.linkDescription;
    }

    public get displayTitle(): string {
        return this.entity?.displayTitle || Identifier.identifierToReadableString(this.identifier);
    }
}
