<form *ngIf="address" [formGroup]="formGroup" enter-to-tab>
    <div class="groupedInputs">
        <mat-form-field *ngIf="showInput('street')" [appearance]="formHelper.appearance(readonly)" class="streetField">
            <mat-autocomplete #streetAutocompleteComponent="matAutocomplete">
                <mat-option *ngFor="let option of autocompleteStreet" [value]="option">{{option}}</mat-option>
            </mat-autocomplete>

            <mat-label>
                <fa-icon [icon]="appIcons.addressStreet"></fa-icon>&nbsp;<ng-container i18n="@@address.street">Street</ng-container>
            </mat-label>
            <input #firstInput #nextTab [formControl]="streetControl" [matAutocomplete]="streetAutocompleteComponent" [maxlength]="formSettings.maxLengthStreet" [readonly]="readonly" autocomplete="new-street-address" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(streetControl, formSettings.maxLengthStreet)}}</mat-hint>
            <mat-error *ngIf="streetControl.invalid">{{getErrorMessage(streetControl)}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="showInput('streetNumber')" [appearance]="formHelper.appearance(readonly)" class="streetNumberField">
            <mat-label>
                <fa-icon [icon]="appIcons.addressStreetNumber"></fa-icon>&nbsp;<ng-container i18n="@@address.streetNumber">Street Number</ng-container>
            </mat-label>
            <input #nextTab [formControl]="streetNumberControl" [maxlength]="formSettings.maxLengthStreetNumber" [readonly]="readonly" autocomplete="new-address-line1" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(streetNumberControl, formSettings.maxLengthStreetNumber)}}</mat-hint>
            <mat-error *ngIf="streetNumberControl.invalid">{{getErrorMessage(streetNumberControl)}}</mat-error>
        </mat-form-field>
    </div>
    <div class="groupedInputs">
        <mat-form-field *ngIf="showInput('zipCode')" [appearance]="formHelper.appearance(readonly)" class="zipCodeField">
            <mat-label>
                <fa-icon [icon]="appIcons.addressZipcode"></fa-icon>&nbsp;<ng-container i18n="@@address.zipcode">Zip code</ng-container>
            </mat-label>
            <input #nextTab [formControl]="zipCodeControl" [maxlength]="formSettings.maxLengthZipCode" [readonly]="readonly" autocomplete="new-postal-code" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(zipCodeControl, formSettings.maxLengthZipCode)}}</mat-hint>
            <mat-error *ngIf="zipCodeControl.invalid">{{getErrorMessage(zipCodeControl)}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="showInput('city')" [appearance]="formHelper.appearance(readonly)" class="cityField">
            <mat-label>
                <fa-icon [icon]="appIcons.addressCity"></fa-icon>&nbsp;<ng-container i18n="@@address.city">City</ng-container>
            </mat-label>
            <input #nextTab [formControl]="cityControl" [maxlength]="formSettings.maxLengthCity" [readonly]="readonly" autocomplete="new-address-level2" matInput type="text">
            <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(cityControl, formSettings.maxLengthCity)}}</mat-hint>
            <mat-error *ngIf="cityControl.invalid">{{getErrorMessage(cityControl)}}</mat-error>
        </mat-form-field>
    </div>

    <base-country-selection (countryChanged)="countryChanged($event)" *ngIf="showInput('country')" [country]="(countryControl.value ?? undefined)" [readonly]="readonly"></base-country-selection>

    <mat-form-field *ngIf="showInput('province')" [appearance]="formHelper.appearance(readonly)" class="marginTop provinceField">
        <mat-label>
            <fa-icon [icon]="appIcons.addressProvince"></fa-icon>&nbsp;<ng-container i18n="@@address.province">Province/State</ng-container>
        </mat-label>
        <input #nextTab [formControl]="provinceControl" [maxlength]="formSettings.maxLengthProvince" [readonly]="readonly" autocomplete="new-address-level1" matInput type="text">
        <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(provinceControl, formSettings.maxLengthProvince)}}</mat-hint>
        <mat-error *ngIf="provinceControl.invalid">{{getErrorMessage(provinceControl)}}</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="showInput('additionalInfo')" [appearance]="formHelper.appearance(readonly)" class="additionalInfoField">
        <mat-label>
            <fa-icon [icon]="appIcons.addressAdditionalInfo"></fa-icon>&nbsp;<ng-container i18n="@@address.additionalInfo">Additional address information</ng-container>
        </mat-label>
        <input #nextTab [formControl]="additionalInformationControl" [maxlength]="formSettings.maxLengthAdditionalInfo" [readonly]="readonly" autocomplete="new-additional-info" matInput type="text">
        <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(additionalInformationControl, formSettings.maxLengthAdditionalInfo)}}</mat-hint>
        <mat-error *ngIf="additionalInformationControl.invalid">{{getErrorMessage(additionalInformationControl)}}</mat-error>
    </mat-form-field>
</form>
