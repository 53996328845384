<form [formGroup]="formGroup">
    <mat-form-field *ngFor="let emailForm of emailFormArray; let i = index" [appearance]="formHelper.appearance(readonly)" class="emailField">

        <mat-label>
            <fa-icon [icon]="appIcons.email"></fa-icon>&nbsp;<ng-container i18n="@@entity.email">E-Mail</ng-container>
        </mat-label>

        <input (input)="inputValueChanged(i)" [formControl]="emailForm" [readonly]="readonly" autocomplete="new-email" matInput type="email">
        <mat-error *ngIf="emailControl.invalid">{{getErrorMessage(emailControl)}}</mat-error>
    </mat-form-field>
</form>
