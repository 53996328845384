import { AnswerTemplateIdentifier } from "../../identifiers/answer-template-identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";
import { Attachment } from "../attachments/attachment";
import { AttachmentFactory } from "../attachments/attachment-factory";
import { AttachmentStorageDto } from "../attachments/attachment-storage-dto";
import { StorableEntity } from "../storable-entity";
import { DocumentAnswer } from "./document-answer";
import { DocumentAnswerStorageDto } from "./document-answer-storage-dto";
import { DocumentQuestionStorageDto } from "./document-question-storage-dto";
import { DocumentQuestionTypes } from "./document-question-types";

/**
 * A question that contains a single option to select or choose from. It is a composite that can contain multiple children.
 */
export class DocumentQuestion implements StorableEntity<DocumentQuestionStorageDto> {
    public constructor(templateIdentifier: QuestionTemplateIdentifier, sectionTemplateIdentifier: SectionTemplateIdentifier, init?: Partial<DocumentQuestion>) {
        if (init) {
            Object.assign(this, init);
        }

        this.templateIdentifier = templateIdentifier;
        this.sectionTemplateIdentifier = sectionTemplateIdentifier;
    }

    public templateIdentifier: QuestionTemplateIdentifier;

    public sectionTemplateIdentifier: SectionTemplateIdentifier;

    public type: DocumentQuestionTypes = DocumentQuestionTypes.unknown;

    public typeRaw?: string;

    public title?: string;

    public description?: string;

    public references?: string;

    public allowsNewEntry: boolean = false;

    public mandatory: boolean = false;

    public simpleItem: boolean = false;

    public enabled: boolean = true;

    public enablable: boolean = false;

    public hasComments: boolean = true;

    public hasInternalNotes: boolean = true;

    public hasImages: boolean = true;

    public hasAudio: boolean = true;

    public hasFiles: boolean = true;

    public answers: Array<DocumentAnswer> = [];

    public questions: Array<DocumentQuestion> = [];

    public attachments: Array<Attachment> = [];

    // noinspection JSUnusedGlobalSymbols
    public addPartialQuestion(templateIdentifier: QuestionTemplateIdentifier, sectionTemplateIdentifier: SectionTemplateIdentifier, partialItem: Partial<DocumentQuestion>): DocumentQuestion {
        const item: DocumentQuestion = new DocumentQuestion(templateIdentifier, sectionTemplateIdentifier, partialItem);
        this.questions.push(item);
        return item;
    }

    public addQuestion(item: DocumentQuestion): DocumentQuestion {
        this.questions.push(item);
        return item;
    }

    public addValue(value: DocumentAnswer): DocumentAnswer {
        this.answers.push(value);
        return value;
    }

    public getAnswerByTemplateIdentifier(templateIdentifier: number): DocumentAnswer|undefined {
        return this.answers.find((answer: DocumentAnswer) => answer.templateIdentifier == templateIdentifier);
    }

    public getAnswerByValue(value: string): DocumentAnswer|undefined {
        return this.answers.find((answer: DocumentAnswer) => answer.value.value == value);
    }

    public isEmpty(): boolean {
        return this.answers.length <= 0;
    }

    public clone(): DocumentQuestion {
        const clone: DocumentQuestion = new DocumentQuestion(this.templateIdentifier, this.sectionTemplateIdentifier);
        clone.fromStorageDto(this.toStorageDto());
        return clone;
    }

    public toStorageDto(): DocumentQuestionStorageDto {
        const dto: DocumentQuestionStorageDto = new DocumentQuestionStorageDto();
        dto.templateIdentifier = this.templateIdentifier;
        dto.sectionTemplateIdentifier = this.sectionTemplateIdentifier;

        dto.type = DocumentQuestionTypes[this.type];
        dto.typeRaw = this.typeRaw;

        dto.title = this.title;
        dto.description = this.description;
        dto.references = this.references;
        dto.allowsNewEntry = this.allowsNewEntry;
        dto.mandatory = this.mandatory;
        dto.simpleItem = this.simpleItem;
        dto.enabled = this.enabled;
        dto.enablable = this.enablable;
        dto.hasComments = this.hasComments;
        dto.hasInternalNotes = this.hasInternalNotes;
        dto.hasImages = this.hasImages;
        dto.hasAudio = this.hasAudio;
        dto.hasFiles = this.hasFiles;

        for (const question of this.questions) {
            const questionDto: DocumentQuestionStorageDto = question.toStorageDto();
            dto.questions.push(questionDto);
        }
        for (const answer of this.answers) {
            const answerDto: DocumentAnswerStorageDto = answer.toStorageDto();
            dto.answers.push(answerDto);
        }
        for (const attachment of this.attachments) {
            const attachmentDto: AttachmentStorageDto = attachment.toStorageDto();
            dto.attachments.push(attachmentDto);
        }

        return dto;
    }

    public fromStorageDto(dto: DocumentQuestionStorageDto|undefined): void {
        this.templateIdentifier = dto?.templateIdentifier as QuestionTemplateIdentifier;
        this.sectionTemplateIdentifier = dto?.sectionTemplateIdentifier as SectionTemplateIdentifier;

        this.type = dto?.type ? dto.type as DocumentQuestionTypes : DocumentQuestionTypes.unknown;
        this.typeRaw = dto?.typeRaw;

        this.title = dto?.title;
        this.description = dto?.description;
        this.references = dto?.references;
        this.allowsNewEntry = dto?.allowsNewEntry ?? false;
        this.mandatory = dto?.mandatory ?? false;
        this.simpleItem = dto?.simpleItem ?? false;
        this.enabled = dto?.enabled ?? true;
        this.enablable = dto?.enablable ?? false;
        this.hasComments = dto?.hasComments === undefined ? true : !!dto?.hasComments;
        this.hasInternalNotes = dto?.hasInternalNotes ?? false;
        this.hasImages = dto?.hasImages ?? false;
        this.hasAudio = dto?.hasAudio ?? false;
        this.hasFiles = dto?.hasFiles ?? false;

        // noinspection DuplicatedCode
        for (const questionDto of dto?.questions ?? []) {
            const existingQuestion: DocumentQuestion|undefined = this.questions.find((question: DocumentQuestion) => question.templateIdentifier == questionDto.templateIdentifier);
            if (existingQuestion) {
                existingQuestion.fromStorageDto(questionDto);
            } else {
                const question: DocumentQuestion = new DocumentQuestion(questionDto.templateIdentifier as QuestionTemplateIdentifier, questionDto.sectionTemplateIdentifier as SectionTemplateIdentifier);
                question.fromStorageDto(questionDto);
                this.questions.push(question);
            }
        }

        for (const answerDto of dto?.answers ?? []) {
            const existingAnswer: DocumentAnswer|undefined = this.answers.find((answer: DocumentAnswer) => answer.templateIdentifier == answerDto.templateIdentifier);
            if (existingAnswer) {
                existingAnswer.fromStorageDto(answerDto);
            } else {
                const answer: DocumentAnswer = new DocumentAnswer(answerDto.templateIdentifier as AnswerTemplateIdentifier);
                answer.fromStorageDto(answerDto);
                this.answers.push(answer);
            }
        }

        this.attachments = [];
        for (const attachmentDto of dto?.attachments ?? []) {
            const attachment: Attachment = AttachmentFactory.createFromStorageDto(attachmentDto);
            attachment.fromStorageDto(attachmentDto);
            this.attachments.push(attachment);
        }
    }
}
