import { ArrayHelper } from "../../../base/helpers/array-helper";
import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { AddressBusinessIdentifier } from "../../identifiers/address-business-identifier";
import { AddressTechnicalIdentifier } from "../../identifiers/address-technical-identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { EntityTypes } from "../entity-types";
import { AddressStorageDto } from "./address-storage-dto";
import { CoreDataEntity } from "./core-data-entity";

/**
 * Basic address class.
 */
export class Address extends CoreDataEntity<AddressBusinessIdentifier, AddressTechnicalIdentifier> {
    constructor(init?: Partial<Address>) {
        super(EntityTypes.address);

        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public street?: string;

    public streetNumber?: string;

    public zipCode?: string;

    public city?: string;

    public province?: string;

    public country?: string;

    public additionalInfo?: string;

    public get displayTitle(): string {
        const builder: Array<string> = ArrayHelper.removeEmpty([`${this.street || ""} ${this.streetNumber || ""}`.trim(), this.zipCode, this.city]);
        return builder.join(", ");
    }

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    public get isEmpty(): boolean {
        return !(this.street || this.streetNumber || this.city || this.zipCode || this.province || this.additionalInfo);
    }

    protected toEntityStorageDto(): AddressStorageDto {
        return new AddressStorageDto({
            street: this.street,
            streetNumber: this.streetNumber,
            zipCode: this.zipCode,
            city: this.city,
            province: this.province,
            country: this.country,
            additionalInfo: this.additionalInfo
        });
    }

    protected fromEntityStorageDto(dto: AddressStorageDto|undefined): void {
        this.street = dto?.street;
        this.streetNumber = dto?.streetNumber;
        this.zipCode = dto?.zipCode;
        this.city = dto?.city;
        this.province = dto?.province;
        this.country = dto?.country;
        this.additionalInfo = dto?.additionalInfo;
    }

    public clone(): Address {
        return CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
    }
}
