import { OverlayRef } from "@angular/cdk/overlay";
import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";

import { AppIcons } from "../../../business/services/icons/app-icons";
import { OverlayComponent } from "../../interfaces/overlay-component";
import { InteractiveToastOptions } from "./interactive-toast.options";

/**
 *  Interactive toast box.
 */
@Component({
    selector: "app-interactive-toast",
    templateUrl: "./interactive-toast.component.html",
    styleUrls: ["./interactive-toast.component.scss"]
})
export class InteractiveToastComponent implements OverlayComponent<void> {
    private readonly animationDuration: number = 300;

    @Input()
    public options?: InteractiveToastOptions;

    @Input()
    public overlayRef?: OverlayRef;

    public dismissed: Subject<void> = new Subject<void>();
    public isOpen: boolean = true;
    public readonly appIcons: typeof AppIcons = AppIcons;

    public close(): void {
        this.isOpen = false;
        setTimeout(() => {
            this.overlayRef?.dispose();
            this.dismissed.next();
            this.dismissed.complete();
        }, this.animationDuration);
    }
}
