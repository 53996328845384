import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

import { AppIcons } from "../../../business/services/icons/app-icons";
import { LocalizationService } from "../../../business/services/localization/localization.service";
import { FormHelper } from "../../helpers/form-helper";
import { BaseComponent } from "../base-component";
import { FocusableComponent } from "../focusable-component";
import { CountryListItem } from "./country-list-item";

/**
 * Selection component for countries.
 */
@Component({
    selector: "base-country-selection",
    templateUrl: "./country-selection.component.html",
    styleUrls: ["./country-selection.component.scss"]
})
export class CountrySelectionComponent extends BaseComponent implements FocusableComponent {
    constructor(
        private readonly localizationService: LocalizationService,
        private readonly formBuilder: FormBuilder
    ) {
        super();
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly formHelper: typeof FormHelper = FormHelper;

    @Input()
    public readonly: boolean = false;

    private countryField?: string;

    @Output()
    public countryChanged: EventEmitter<string|undefined> = new EventEmitter<string|undefined>();

    @ViewChild("firstInput")
    public firstInput?: ElementRef;

    public countryControl: FormControl<string|null> = new FormControl<string|null>("", []);

    public countries: Array<CountryListItem> = [];

    public formData: FormGroup = this.formBuilder.group([
        this.countryControl
    ]);

    // https://www.html-code-generator.com/javascript/json/country-names
    public countriesEnUs: Array<CountryListItem> = [
        { code: "AF", name: "Afghanistan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "AX", name: "Aland Islands", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "AL", name: "Albania", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "DZ", name: "Algeria", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "AS", name: "American Samoa", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "AD", name: "Andorra", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "AO", name: "Angola", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "AI", name: "Anguilla", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "AQ",
            name: $localize`:@@continents.antarctica:Antarctica` as string,
            continent: $localize`:@@continents.antarctica:Antarctica` as string
        },
        {
            code: "AG",
            name: "Antigua and Barbuda",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "AR", name: "Argentina", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "AM", name: "Armenia", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "AW", name: "Aruba", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "AU", name: "Australia", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "AT", name: "Austria", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "AZ", name: "Azerbaijan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BS", name: "Bahamas", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "BH", name: "Bahrain", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BD", name: "Bangladesh", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BB", name: "Barbados", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "BY", name: "Belarus", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "BE", name: "Belgium", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "BZ", name: "Belize", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "BJ", name: "Benin", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "BM", name: "Bermuda", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "BT", name: "Bhutan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BO", name: "Bolivia", continent: $localize`:@@continents.southAmerica:South America` as string },
        {
            code: "BQ",
            name: "Bonaire, Sint Eustatius and Saba",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "BA", name: "Bosnia and Herzegovina", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "BW", name: "Botswana", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "BV", name: "Bouvet Island", continent: $localize`:@@continents.antarctica:Antarctica` as string },
        { code: "BR", name: "Brazil", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "IO", name: "British Indian Ocean Territory", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BN", name: "Brunei Darussalam", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BG", name: "Bulgaria", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "BF", name: "Burkina Faso", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "BI", name: "Burundi", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "KH", name: "Cambodia", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CM", name: "Cameroon", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "CA", name: "Canada", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "CV", name: "Cape Verde", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "KY",
            name: "Cayman Islands",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "CF", name: "Central African Republic", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "TD", name: "Chad", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "CL", name: "Chile", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "CN", name: "China", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CX", name: "Christmas Island", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CC", name: "Cocos (Keeling) Islands", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CO", name: "Colombia", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "KM", name: "Comoros", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "CG", name: "Congo", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "CD",
            name: "Congo, Democratic Republic of the Congo",
            continent: $localize`:@@continents.africa:Africa` as string
        },
        { code: "CK", name: "Cook Islands", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "CR", name: "Costa Rica", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "CI", name: "Cote D'Ivoire", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "HR", name: "Croatia", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "CU", name: "Cuba", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "CW", name: "Curacao", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "CY", name: "Cyprus", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CZ", name: "Czech Republic", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "DK", name: "Denmark", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "DJ", name: "Djibouti", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "DM", name: "Dominica", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "DO",
            name: "Dominican Republic",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "EC", name: "Ecuador", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "EG", name: "Egypt", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SV", name: "El Salvador", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GQ", name: "Equatorial Guinea", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "ER", name: "Eritrea", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "EE", name: "Estonia", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "ET", name: "Ethiopia", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "FK",
            name: "Falkland Islands (Malvinas)",
            continent: $localize`:@@continents.southAmerica:South America` as string
        },
        { code: "FO", name: "Faroe Islands", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "FJ", name: "Fiji", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "FI", name: "Finland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "FR", name: "France", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "GF", name: "French Guiana", continent: $localize`:@@continents.southAmerica:South America` as string },
        {
            code: "PF",
            name: "French Polynesia",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        {
            code: "TF",
            name: "French Southern Territories",
            continent: $localize`:@@continents.antarctica:Antarctica` as string
        },
        { code: "GA", name: "Gabon", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GM", name: "Gambia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GE", name: "Georgia", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "DE", name: "Germany", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "GH", name: "Ghana", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GI", name: "Gibraltar", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "GR", name: "Greece", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "GL", name: "Greenland", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GD", name: "Grenada", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GP", name: "Guadeloupe", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GU", name: "Guam", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "GT", name: "Guatemala", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GG", name: "Guernsey", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "GN", name: "Guinea", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GW", name: "Guinea-Bissau", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GY", name: "Guyana", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "HT", name: "Haiti", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "HM",
            name: "Heard Island and McDonald Islands",
            continent: $localize`:@@continents.antarctica:Antarctica` as string
        },
        {
            code: "VA",
            name: "Holy See (Vatican City State)",
            continent: $localize`:@@continents.europe:Europe` as string
        },
        { code: "HN", name: "Honduras", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "HK", name: "Hong Kong", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "HU", name: "Hungary", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "IS", name: "Iceland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "IN", name: "India", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "ID", name: "Indonesia", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "IR", name: "Iran, Islamic Republic of", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "IQ", name: "Iraq", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "IE", name: "Ireland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "IM", name: "Isle of Man", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "IL", name: "Israel", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "IT", name: "Italy", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "JM", name: "Jamaica", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "JP", name: "Japan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "JE", name: "Jersey", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "JO", name: "Jordan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "KZ", name: "Kazakhstan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "KE", name: "Kenya", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "KI", name: "Kiribati", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        {
            code: "KP",
            name: "Korea, Democratic People's Republic of",
            continent: $localize`:@@continents.asia:Asia` as string
        },
        { code: "KR", name: "Korea, Republic of", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "XK", name: "Kosovo", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "KW", name: "Kuwait", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "KG", name: "Kyrgyzstan", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "LA",
            name: "Lao People's Democratic Republic",
            continent: $localize`:@@continents.asia:Asia` as string
        },
        { code: "LV", name: "Latvia", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "LB", name: "Lebanon", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "LS", name: "Lesotho", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "LR", name: "Liberia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "LY", name: "Libyan Arab Jamahiriya", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "LI", name: "Liechtenstein", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "LT", name: "Lithuania", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "LU", name: "Luxembourg", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "MO", name: "Macao", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "MK",
            name: "Macedonia, the Former Yugoslav Republic of",
            continent: $localize`:@@continents.europe:Europe` as string
        },
        { code: "MG", name: "Madagascar", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MW", name: "Malawi", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MY", name: "Malaysia", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "MV", name: "Maldives", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "ML", name: "Mali", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MT", name: "Malta", continent: $localize`:@@continents.europe:Europe` as string },
        {
            code: "MH",
            name: "Marshall Islands",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "MQ", name: "Martinique", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "MR", name: "Mauritania", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MU", name: "Mauritius", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "YT", name: "Mayotte", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MX", name: "Mexico", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "FM",
            name: "Micronesia, Federated States of",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "MD", name: "Moldova, Republic of", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "MC", name: "Monaco", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "MN", name: "Mongolia", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "ME", name: "Montenegro", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "MS", name: "Montserrat", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "MA", name: "Morocco", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MZ", name: "Mozambique", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MM", name: "Myanmar", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "NA", name: "Namibia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "NR", name: "Nauru", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "NP", name: "Nepal", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "NL", name: "Netherlands", continent: $localize`:@@continents.europe:Europe` as string },
        {
            code: "AN",
            name: "Netherlands Antilles",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "NC", name: "New Caledonia", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "NZ", name: "New Zealand", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "NI", name: "Nicaragua", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "NE", name: "Niger", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "NG", name: "Nigeria", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "NU", name: "Niue", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "NF", name: "Norfolk Island", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        {
            code: "MP",
            name: "Northern Mariana Islands",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "NO", name: "Norway", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "OM", name: "Oman", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "PK", name: "Pakistan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "PW", name: "Palau", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        {
            code: "PS",
            name: "Palestinian Territory, Occupied",
            continent: $localize`:@@continents.asia:Asia` as string
        },
        { code: "PA", name: "Panama", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "PG",
            name: "Papua New Guinea",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "PY", name: "Paraguay", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "PE", name: "Peru", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "PH", name: "Philippines", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "PN", name: "Pitcairn", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "PL", name: "Poland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "PT", name: "Portugal", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "PR", name: "Puerto Rico", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "QA", name: "Qatar", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "RE", name: "Reunion", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "RO", name: "Romania", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "RU", name: "Russian Federation", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "RW", name: "Rwanda", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "BL",
            name: "Saint Barthelemy",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "SH", name: "Saint Helena", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "KN",
            name: "Saint Kitts and Nevis",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "LC", name: "Saint Lucia", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "MF", name: "Saint Martin", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "PM",
            name: "Saint Pierre and Miquelon",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        {
            code: "VC",
            name: "Saint Vincent and the Grenadines",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "WS", name: "Samoa", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "SM", name: "San Marino", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "ST", name: "Sao Tome and Principe", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SA", name: "Saudi Arabia", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "SN", name: "Senegal", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "RS", name: "Serbia", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "CS", name: "Serbia and Montenegro", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "SC", name: "Seychelles", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SL", name: "Sierra Leone", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SG", name: "Singapore", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "SX", name: "St Martin", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "SK", name: "Slovakia", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "SI", name: "Slovenia", continent: $localize`:@@continents.europe:Europe` as string },
        {
            code: "SB",
            name: "Solomon Islands",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "SO", name: "Somalia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "ZA", name: "South Africa", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "GS",
            name: "South Georgia and the South Sandwich Islands",
            continent: $localize`:@@continents.antarctica:Antarctica` as string
        },
        { code: "SS", name: "South Sudan", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "ES", name: "Spain", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "LK", name: "Sri Lanka", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "SD", name: "Sudan", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SR", name: "Suriname", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "SJ", name: "Svalbard and Jan Mayen", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "SZ", name: "Swaziland", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SE", name: "Sweden", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "CH", name: "Switzerland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "SY", name: "Syrian Arab Republic", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TW", name: "Taiwan, Province of China", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TJ", name: "Tajikistan", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "TZ",
            name: "Tanzania, United Republic of",
            continent: $localize`:@@continents.africa:Africa` as string
        },
        { code: "TH", name: "Thailand", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TL", name: "Timor-Leste", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TG", name: "Togo", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "TK", name: "Tokelau", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "TO", name: "Tonga", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        {
            code: "TT",
            name: "Trinidad and Tobago",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "TN", name: "Tunisia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "TR", name: "Turkey", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TM", name: "Turkmenistan", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "TC",
            name: "Turks and Caicos Islands",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "TV", name: "Tuvalu", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "UG", name: "Uganda", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "UA", name: "Ukraine", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "AE", name: "United Arab Emirates", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "GB", name: "United Kingdom", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "US", name: "United States", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "UM",
            name: "United States Minor Outlying Islands",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "UY", name: "Uruguay", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "UZ", name: "Uzbekistan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "VU", name: "Vanuatu", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "VE", name: "Venezuela", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "VN", name: "Viet Nam", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "VG",
            name: "Virgin Islands, British",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        {
            code: "VI",
            name: "Virgin Islands, U.s.",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        {
            code: "WF",
            name: "Wallis and Futuna",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "EH", name: "Western Sahara", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "YE", name: "Yemen", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "ZM", name: "Zambia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "ZW", name: "Zimbabwe", continent: $localize`:@@continents.africa:Africa` as string }
    ];

    public countriesDeDe: Array<CountryListItem> = [
        { code: "DE", name: "Deutschland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "AT", name: "Österreich", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "CH", name: "Schweiz", continent: $localize`:@@continents.europe:Europe` as string },

        { code: "AF", name: "Afghanistan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "AX", name: "Aland Islands", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "AL", name: "Albanien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "DZ", name: "Algerien", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "AS",
            name: "Amerikanischen Samoa-Inseln",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "AD", name: "Andorra", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "AO", name: "Angola", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "AI", name: "Anguilla", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "AQ", name: "Antarktis", continent: $localize`:@@continents.antarctica:Antarctica` as string },
        {
            code: "AG",
            name: "Antigua und Barbuda",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "AR", name: "Argentinien", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "AM", name: "Armenien", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "AW", name: "Aruba", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "AU", name: "Australien", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "AT", name: "Österreich", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "AZ", name: "Aserbaidschan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BS", name: "Bahamas", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "BH", name: "Bahrain", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BD", name: "Bangladesch", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BB", name: "Barbados", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "BY", name: "Weißrussland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "BE", name: "Belgien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "BZ", name: "Belize", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "BJ", name: "Benin", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "BM", name: "Bermuda", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "BT", name: "Bhutan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BO", name: "Bolivien", continent: $localize`:@@continents.southAmerica:South America` as string },
        {
            code: "BQ",
            name: "Bonaire, Sint Eustatius und Saba",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "BA", name: "Bosnien und Herzegowina", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "BW", name: "Botswana", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "BV", name: "Bouvet Island", continent: $localize`:@@continents.antarctica:Antarctica` as string },
        { code: "BR", name: "Brasilien", continent: $localize`:@@continents.southAmerica:South America` as string },
        {
            code: "IO",
            name: "Britisches Territorium des Indischen Ozeans",
            continent: $localize`:@@continents.asia:Asia` as string
        },
        { code: "BN", name: "Brunei Darussalam", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "BG", name: "Bulgarien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "BF", name: "Burkina Faso", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "BI", name: "Burundi", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "KH", name: "Kambodscha", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CM", name: "Kamerun", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "CA", name: "Kanada", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "CV", name: "Kap Verde", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "KY", name: "Cayman Inseln", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "CF",
            name: "Zentralafrikanische Republik",
            continent: $localize`:@@continents.africa:Africa` as string
        },
        { code: "TD", name: "Tschad", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "CL", name: "Chile", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "CN", name: "China", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CX", name: "Weihnachtsinsel", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CC", name: "Kokosinseln (Keelinginseln)", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CO", name: "Kolumbien", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "KM", name: "Komoren", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "CG", name: "Kongo", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "CD",
            name: "Kongo, Demokratische Republik Kongo",
            continent: $localize`:@@continents.africa:Africa` as string
        },
        { code: "CK", name: "Cookinseln", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "CR", name: "Costa Rica", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "CI", name: "Elfenbeinküste", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "HR", name: "Kroatien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "CU", name: "Kuba", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "CW", name: "Curacao", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "CY", name: "Zypern", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "CZ", name: "Tschechien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "DK", name: "Dänemark", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "DJ", name: "Dschibuti", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "DM", name: "Dominica", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "DO",
            name: "Dominikanische Republik",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "EC", name: "Ecuador", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "EG", name: "Ägypten", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SV", name: "El Salvador", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GQ", name: "Äquatorialguinea", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "ER", name: "Eritrea", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "EE", name: "Estland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "ET", name: "Äthiopien", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "FK",
            name: "Falklandinseln (Malvinas)",
            continent: $localize`:@@continents.southAmerica:South America` as string
        },
        { code: "FO", name: "Färöer Inseln", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "FJ", name: "Fidschi", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "FI", name: "Finnland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "FR", name: "Frankreich", continent: $localize`:@@continents.europe:Europe` as string },
        {
            code: "GF",
            name: "Französisch-Guayana",
            continent: $localize`:@@continents.southAmerica:South America` as string
        },
        {
            code: "PF",
            name: "Französisch Polynesien",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        {
            code: "TF",
            name: "Südfranzösische Territorien",
            continent: $localize`:@@continents.antarctica:Antarctica` as string
        },
        { code: "GA", name: "Gabun", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GM", name: "Gambia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GE", name: "Georgia", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "DE", name: "Deutschland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "GH", name: "Ghana", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GI", name: "Gibraltar", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "GR", name: "Griechenland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "GL", name: "Grönland", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GD", name: "Grenada", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GP", name: "Guadeloupe", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GU", name: "Guam", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "GT", name: "Guatemala", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "GG", name: "Guernsey", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "GN", name: "Guinea", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GW", name: "Guinea-Bissau", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "GY", name: "Guyana", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "HT", name: "Haiti", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "HM",
            name: "Heard Island und McDonald Islands",
            continent: $localize`:@@continents.antarctica:Antarctica` as string
        },
        {
            code: "VA",
            name: "Heiliger Stuhl (Staat der Vatikanstadt)",
            continent: $localize`:@@continents.europe:Europe` as string
        },
        { code: "HN", name: "Honduras", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "HK", name: "Hongkong", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "HU", name: "Ungarn", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "IS", name: "Island", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "IN", name: "Indien", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "ID", name: "Indonesien", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "IR", name: "Iran, Islamische Republik", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "IQ", name: "Irak", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "IE", name: "Irland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "IM", name: "Isle of Man", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "IL", name: "Israel", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "IT", name: "Italien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "JM", name: "Jamaika", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "JP", name: "Japan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "JE", name: "Jersey", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "JO", name: "Jordanien", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "KZ", name: "Kasachstan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "KE", name: "Kenia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "KI", name: "Kiribati", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        {
            code: "KP",
            name: "Korea, Demokratische Volksrepublik",
            continent: $localize`:@@continents.asia:Asia` as string
        },
        { code: "KR", name: "Korea, Republik von", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "XK", name: "Kosovo", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "KW", name: "Kuwait", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "KG", name: "Kirgisistan", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "LA",
            name: "Demokratische Volksrepublik Laos",
            continent: $localize`:@@continents.asia:Asia` as string
        },
        { code: "LV", name: "Lettland", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "LB", name: "Libanon", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "LS", name: "Lesotho", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "LR", name: "Liberia", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "LY",
            name: "Libyscher arabischer Jamahiriya",
            continent: $localize`:@@continents.africa:Africa` as string
        },
        { code: "LI", name: "Liechtenstein", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "LT", name: "Litauen", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "LU", name: "Luxemburg", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "MO", name: "Macao", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "MK",
            name: "Mazedonien, die ehemalige jugoslawische Republik",
            continent: $localize`:@@continents.europe:Europe` as string
        },
        { code: "MG", name: "Madagaskar", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MW", name: "Malawi", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MY", name: "Malaysia", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "MV", name: "Malediven", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "ML", name: "Mali", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MT", name: "Malta", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "MH", name: "Marshallinseln", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "MQ", name: "Martinique", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "MR", name: "Mauretanien", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MU", name: "Mauritius", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "YT", name: "Mayotte", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MX", name: "Mexiko", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "FM",
            name: "Mikronesien, Föderierte Staaten von",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "MD", name: "Moldawien, Republik", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "MC", name: "Monaco", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "MN", name: "Mongolei", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "ME", name: "Montenegro", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "MS", name: "Montserrat", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "MA", name: "Marokko", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MZ", name: "Mosambik", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "MM", name: "Myanmar", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "NA", name: "Namibia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "NR", name: "Nauru", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "NP", name: "Nepal", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "NL", name: "Niederlande", continent: $localize`:@@continents.europe:Europe` as string },
        {
            code: "AN",
            name: "Niederländische Antillen",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "NC", name: "Neu-Kaledonien", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "NZ", name: "Neuseeland", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "NI", name: "Nicaragua", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "NE", name: "Niger", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "NG", name: "Nigeria", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "NU", name: "Niue", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "NF", name: "Norfolkinsel", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        {
            code: "MP",
            name: "Nördliche Marianneninseln",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "NO", name: "Norwegen", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "OM", name: "Oman", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "PK", name: "Pakistan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "PW", name: "Palau", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        {
            code: "PS",
            name: "Besetzte palästinensische Gebiete",
            continent: $localize`:@@continents.asia:Asia` as string
        },
        { code: "PA", name: "Panama", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "PG",
            name: "Papua Neu-Guinea",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "PY", name: "Paraguay", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "PE", name: "Peru", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "PH", name: "Philippinen", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "PN", name: "Pitcairn", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "PL", name: "Polen", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "PT", name: "Portugal", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "PR", name: "Puerto Rico", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "QA", name: "Katar", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "RE", name: "Wiedervereinigung", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "RO", name: "Rumänien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "RU", name: "Russische Föderation", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "RW", name: "Ruanda", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "BL",
            name: "Heiliger Barthelemy",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "SH", name: "Heilige Helena", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "KN",
            name: "St. Kitts und Nevis",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "LC", name: "St. Lucia", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "MF", name: "Sankt Martin", continent: $localize`:@@continents.northAmerica:North America` as string },
        {
            code: "PM",
            name: "Saint Pierre und Miquelon",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        {
            code: "VC",
            name: "St. Vincent und die Grenadinen",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "WS", name: "Samoa", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "SM", name: "San Marino", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "ST", name: "Sao Tome und Principe", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SA", name: "Saudi-Arabien", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "SN", name: "Senegal", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "RS", name: "Serbien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "CS", name: "Serbien und Montenegro", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "SC", name: "Seychellen", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SL", name: "Sierra Leone", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SG", name: "Singapur", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "SX", name: "St. Martin", continent: $localize`:@@continents.northAmerica:North America` as string },
        { code: "SK", name: "Slowakei", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "SI", name: "Slowenien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "SB", name: "Salomon-Inseln", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "SO", name: "Somalia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "ZA", name: "Südafrika", continent: $localize`:@@continents.africa:Africa` as string },
        {
            code: "GS",
            name: "Süd-Georgien und die südlichen Sandwich-Inseln",
            continent: $localize`:@@continents.antarctica:Antarctica` as string
        },
        { code: "SS", name: "Südsudan", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "ES", name: "Spanien", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "LK", name: "Sri Lanka", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "SD", name: "Sudan", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SR", name: "Suriname", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "SJ", name: "Spitzbergen und Jan Mayen", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "SZ", name: "Swasiland", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "SE", name: "Schweden", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "CH", name: "Schweiz", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "SY", name: "Syrische Arabische Republik", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TW", name: "Taiwan, Provinz Chinas", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TJ", name: "Tadschikistan", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "TZ",
            name: "Tansania, Vereinigte Republik",
            continent: $localize`:@@continents.africa:Africa` as string
        },
        { code: "TH", name: "Thailand", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TL", name: "Timor-Leste", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TG", name: "Gehen", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "TK", name: "Tokelau", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "TO", name: "Tonga", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        {
            code: "TT",
            name: "Trinidad und Tobago",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "TN", name: "Tunesien", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "TR", name: "Truthahn", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "TM", name: "Turkmenistan", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "TC",
            name: "Turks- und Caicosinseln",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "TV", name: "Tuvalu", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "UG", name: "Uganda", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "UA", name: "Ukraine", continent: $localize`:@@continents.europe:Europe` as string },
        { code: "AE", name: "Vereinigte Arabische Emirate", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "GB", name: "Vereinigtes Königreich", continent: $localize`:@@continents.europe:Europe` as string },
        {
            code: "US",
            name: "Vereinigte Staaten",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        {
            code: "UM",
            name: "Kleinere abgelegene Inseln der Vereinigten Staaten",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        { code: "UY", name: "Uruguay", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "UZ", name: "Usbekistan", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "VU", name: "Vanuatu", continent: $localize`:@@continents.oceania:Oceania/Australia` as string },
        { code: "VE", name: "Venezuela", continent: $localize`:@@continents.southAmerica:South America` as string },
        { code: "VN", name: "Vietnam", continent: $localize`:@@continents.asia:Asia` as string },
        {
            code: "VG",
            name: "Virgin Inseln, Britisch",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        {
            code: "VI",
            name: "Jungferninseln, USA",
            continent: $localize`:@@continents.northAmerica:North America` as string
        },
        {
            code: "WF",
            name: "Wallis und Futuna",
            continent: $localize`:@@continents.oceania:Oceania/Australia` as string
        },
        { code: "EH", name: "Westsahara", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "YE", name: "Jemen", continent: $localize`:@@continents.asia:Asia` as string },
        { code: "ZM", name: "Sambia", continent: $localize`:@@continents.africa:Africa` as string },
        { code: "ZW", name: "Zimbabwe", continent: $localize`:@@continents.africa:Africa` as string }
    ];

    private initialized: boolean = false;

    public get country(): string|undefined {
        return this.countryField;
    }

    @Input()
    public set country(value: string|null|undefined) {
        const valueChanged: boolean = this.countryField != value;
        this.countryField = value ?? undefined;
        if (valueChanged && this.initialized) {
            this.initialize();
        }
    }

    protected componentInit(): void {
        this.initialize();
        this.subscribe(this.formData.statusChanges, this.formChanged);
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    private formChanged(): void {
        this.countryField = this.countryControl.value as string;

        if (!this.initialized) {
            return;
        }

        this.updateValidState();
    }

    public updateValidState(): void {
        this.countryChanged.emit(this.countryField);
    }

    private initialize(): void {
        this.initialized = false;

        switch (this.localizationService.localeId) {
            case "de-DE":
                this.countries = this.countriesDeDe;
                break;
            default:
                this.countries = this.countriesEnUs;
                break;
        }

        this.assignValues();
        this.initialized = true;
    }

    public assignValues(): void {
        FormHelper.assignIfChanged(this.countryControl, this.countryField);
    }

    public focus(): void {
        if (this.firstInput?.nativeElement && ("focus" in this.firstInput.nativeElement)) {
            this.firstInput.nativeElement.focus();
        }
    }
}
