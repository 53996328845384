import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { EntityIdentifier } from "../../../identifiers/entity-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { Room } from "./room";

/**
 * Basic class of a floor.
 */
export class Floor {
    constructor(init?: Partial<Floor>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public identifier: EntityIdentifier = Identifier.empty();
    public floorNumber?: string;
    public rooms: Array<Room> = [];
}
