import { EventEmitter, Injectable } from "@angular/core";

import { EventHelper } from "../../../base/helpers/event-helper";
import { FileHelper } from "../../../base/helpers/file-helper";
import { WebHelper } from "../../../base/helpers/web-helper";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { LoadStrategies } from "../../common/load-strategies";
import { SafeResult, successResult } from "../../common/safe-result";
import { AttachmentFactory } from "../../entities/attachments/attachment-factory";
import { AttachmentTypes } from "../../entities/attachments/attachment-types";
import { FileAttachment } from "../../entities/attachments/file-attachment";
import { ImageAttachment } from "../../entities/attachments/image-attachment";
import { AppException } from "../../entities/exceptions/app-exception";
import { AttachmentTechnicalIdentifier } from "../../identifiers/attachment-technical-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { AttachmentLoaderService } from "./attachment-loader.service";

/**
 * Service to handle attachments.
 */
@Injectable({
    providedIn: "root"
})
export class AttachmentsService {
    constructor(
        private readonly attachmentLoaderService: AttachmentLoaderService,
        private readonly dialogService: DialogService
    ) {
        EventHelper.subscribe(this.attachmentLoaderService.attachmentReplacedWithExistingAttachment, (data: { oldAttachment: FileAttachment; newAttachment: FileAttachment }) => this.attachmentReplacedWithExistingAttachment.emit(data), this);
        EventHelper.subscribe(this.attachmentLoaderService.attachmentDeleted, (attachment: FileAttachment) => this.attachmentDeleted.emit(attachment), this);
    }

    public attachmentReplacedWithExistingAttachment: EventEmitter<{ oldAttachment: FileAttachment; newAttachment: FileAttachment }> = new EventEmitter<{ oldAttachment: FileAttachment; newAttachment: FileAttachment }>();

    public attachmentDeleted: EventEmitter<FileAttachment> = new EventEmitter<FileAttachment>();

    public async addImage(documentIdentifier: DocumentIdentifier|undefined, resourceUrl: string, mimeType?: string, filename?: string, description?: string, exif?: any): Promise<ImageAttachment> {
        const image: ImageAttachment = AttachmentFactory.createImageAttachment();
        image.localUrl = resourceUrl;
        image.mimeType = this.sanitizeMimeType(mimeType);
        image.filename = filename;
        image.description = description;
        image.metaData = exif as unknown;

        if (documentIdentifier) {
            image.linkedDocuments.push(documentIdentifier);
        }

        const blob: Blob = await FileHelper.blobUrlToBlob(image.localUrl);
        image.bytes = blob.size;
        await this.attachmentLoaderService.saveAttachment(image, blob);

        return image;
    }

    public async deleteImage(imageAttachment: ImageAttachment): Promise<boolean> {
        return this.attachmentLoaderService.deleteImage(imageAttachment);
    }

    private sanitizeMimeType(mimeType?: string): string {
        if (!mimeType) {
            return "application/octet-stream";
        }
        return mimeType.includes("/") ? mimeType : `image/${mimeType}`;
    }

    public async getAll(documentIdentifier: DocumentIdentifier, type: AttachmentTypes, loadStrategy: LoadStrategies): Promise<SafeResult<Array<FileAttachment>, AppException>> {
        const allItems: SafeResult<Array<FileAttachment>, AppException> = await this.attachmentLoaderService.getDocumentAttachments(documentIdentifier, type, loadStrategy);
        if (allItems.isError()) {
            return allItems;
        }
        return successResult(allItems.result.filter((fileAttachment: FileAttachment) => !type || fileAttachment.type == type));
    }

    public updateMetaData(attachment: FileAttachment): Promise<void> {
        return this.attachmentLoaderService.updateMetaData(attachment);
    }

    public loadOriginalAttachmentBlob(technicalIdentifier: AttachmentTechnicalIdentifier, attachmentType: AttachmentTypes): Promise<SafeResult<Blob, AppException>> {
        return this.attachmentLoaderService.loadOriginalAttachmentBlob(technicalIdentifier, attachmentType);
    }

    public async downloadOriginalAttachment(technicalIdentifier: AttachmentTechnicalIdentifier, attachmentType: AttachmentTypes): Promise<void> {
        const result: SafeResult<Blob, AppException> = await this.attachmentLoaderService.loadOriginalAttachmentBlob(technicalIdentifier, attachmentType);
        if (result.isError()) {
            return this.dialogService.showError(result.error);
        }

        let extension: string = FileHelper.mimeTypeToExtension(result.result.type) ?? "dat";
        if (extension == "octet-stream" && attachmentType == AttachmentTypes.image) {
            // TODO: check why there's no information about the type
            extension = "webp";
        }
        WebHelper.downloadBlob(result.result, `${technicalIdentifier}.${extension}`);
    }
}
