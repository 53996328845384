import { Subscription } from "rxjs";

import { AuditDocument } from "../../entities/documents/audit-document";

/**
 * Subscription references for documents.
 */
export class DocumentSubscriptions {
    constructor(document: AuditDocument) {
        this.document = document;
    }

    public refreshInBackground: boolean = false;

    public document: AuditDocument;

    public questionUpdated?: Subscription;

    public attachmentLinksUpdated?: Subscription;

    public repeatableSectionUpdated?: Subscription;
}
