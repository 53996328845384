import { Injectable } from "@angular/core";
import { NullValidationHandler, OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { Subject } from "rxjs";

import { environment } from "../../../../environments/environment";
import { LocalizationService } from "../../../business/services/localization/localization.service";
import { StorageService } from "../../../business/services/storage/storage.service";
import { StorageKeys } from "../../../business/services/storage/storage-keys";
import { WebHelper } from "../../helpers/web-helper";

/**
 * Service for OAuth/OIDC.
 */
@Injectable({
    providedIn: "root"
})
export class AuthService {
    constructor(
        private readonly oauthService: OAuthService,
        private readonly storageService: StorageService,
        private readonly localizationService: LocalizationService
    ) {
    }

    public isAuthenticated: boolean = false;

    private authenticationHasBeenUpdated: boolean = false;

    public isAuthenticatedCheckedInitially: boolean = false;

    public isAuthenticatedChanged: Subject<boolean> = new Subject<boolean>();

    public initialized: boolean = false;

    public async initialize(): Promise<void> {
        this.oauthService.events.subscribe((authEvent: OAuthEvent) => this.authEventReceived.call(this, authEvent));
        const isAuthenticatedStorage: boolean = await this.storageService.get(StorageKeys.isAuthenticated) ?? false;
        if (!this.authenticationHasBeenUpdated && isAuthenticatedStorage) {
            this.isAuthenticated = isAuthenticatedStorage;
        }

        this.oauthService.tokenValidationHandler = new NullValidationHandler();

        try {
            this.oauthService.configure(environment.auth);
            await this.oauthService.loadDiscoveryDocumentAndTryLogin();
            this.oauthService.setupAutomaticSilentRefresh();
        } catch (error) {
            if (!WebHelper.isNoInternetError(error)) {
                await this.logIn();
                return;
            }
        }

        this.initialized = true;

        if (this.isAuthenticated) {
            this.isAuthenticatedChanged.next(this.isAuthenticated);
        }
    }

    private authEventReceived(authEvent: OAuthEvent): void {
        this.updateAuthorizationInformation();

        switch (authEvent.type) {
            case "logout":
                const notifyLoggedOut: boolean = !this.isAuthenticatedCheckedInitially;
                this.isAuthenticatedCheckedInitially = true;
                if (notifyLoggedOut) {
                    this.isAuthenticated = false;
                    this.isAuthenticatedChanged.next(this.isAuthenticated);
                }
                break;
        }
    }

    public updateAuthorizationInformation(): boolean {
        this.authenticationHasBeenUpdated = true;
        const oldIsAuthenticated: boolean = this.isAuthenticated;
        this.isAuthenticated = this.oauthService.hasValidAccessToken();
        if (this.isAuthenticated != oldIsAuthenticated) {
            this.isAuthenticatedCheckedInitially = true;
            this.isAuthenticatedChanged.next(this.isAuthenticated);
            this.storageService.set(StorageKeys.isAuthenticated, this.isAuthenticated).then();
        }
        return this.isAuthenticated;
    }

    public async logIn(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.oauthService.initCodeFlow(undefined, { ui_locales: this.localizationService.languageTwoLetters as string });
    }

    public async logOut(): Promise<void> {
        this.oauthService.logOut();
    }

    public async refreshToken(): Promise<void> {
        await this.oauthService.refreshToken();
        this.updateAuthorizationInformation();
        this.isAuthenticatedCheckedInitially = true;
    }

    public async getAccessToken(): Promise<string|undefined> {
        this.updateAuthorizationInformation();
        return this.oauthService.getAccessToken();
    }

    public async changePassword(): Promise<void> {
        this.oauthService.initCodeFlow(undefined, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ui_locales: this.localizationService.languageTwoLetters as string,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            kc_action: "UPDATE_PASSWORD"
        });
    }
}
