import { Component } from "@angular/core";

/**
 * A loading component, mainly used to show indication for long-running processes.
 */
@Component({
    selector: "base-loading",
    templateUrl: "./loading.component.html",
    styleUrls: ["./loading.component.scss"]
})
export class LoadingComponent {
}
