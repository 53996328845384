import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { environment } from "../../../../../environments/environment";
import { ManualComponentOptions } from "./manual.component.options";

/**
 * Component to show the manual.
 */
@Component({
    selector: "app-manual",
    templateUrl: "./manual.component.html",
    styleUrls: ["./manual.component.scss"]
})
export class ManualComponent implements OnInit {
    constructor(
        private domSanitizer: DomSanitizer
    ) {
        // Do nothing for now
    }

    private baseUrl: string = environment.manualBaseUrl;

    @Input()
    public options!: ManualComponentOptions;

    public loading: boolean = true;

    public url?: string;

    public safeResourceUrl?: SafeResourceUrl;

    public ngOnInit(): void {
        this.options = this.options ?? new ManualComponentOptions();

        this.url = this.baseUrl;

        if (this.options.language && this.options.language != "en-US") {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            this.url += `/${this.options.language.toLowerCase().substring(0, 2)}`;
        }

        if (this.options.tag) {
            this.url += `/search?q=${this.options.tag}&redirect=true&ts=${Date.now()}`;
        } else {
            this.url += "/docs";
            if (this.options.uri) {
                this.url += `/${this.options.uri}`;
            } else {
                this.url += "/intro";
            }
        }

        this.safeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url); // NOSONAR
    }

    public contentLoaded(_event: Event): void {
        this.loading = false;
    }
}
