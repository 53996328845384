import { DialogService } from "../../../base/services/dialog/dialog.service";
import { ModalModes } from "../../../base/services/dialog/modal-modes";
import { Intent } from "../../../base/services/intents/intent";
import { IocService } from "../../../base/services/ioc/ioc.service";
import { PrivacyPolicyComponent } from "../../components/privacy-policy/privacy-policy.component";
import { PrivacyPolicyComponentOptions } from "../../components/privacy-policy/privacy-policy.component.options";
import { ShowPrivacyPolicyIntentOptions } from "./show-privacy-policy-intent-options";

/**
 * Intent to show the privacy policy.
 */
export class ShowPrivacyPolicyIntent implements Intent {
    constructor(
        private readonly iocService: IocService) {
    }

    private readonly dialogService: DialogService = this.iocService.resolve(DialogService);

    public get name(): string {
        return ShowPrivacyPolicyIntent.name;
    }

    public async execute(intentOptions?: ShowPrivacyPolicyIntentOptions): Promise<boolean> {
        if (!intentOptions) {
            return true;
        }
        const dialogOptions: PrivacyPolicyComponentOptions = new PrivacyPolicyComponentOptions(intentOptions.content);
        await this.dialogService.showDefaultModal($localize`:@@privacyPolicy.title:Privacy policy`, {
            component: PrivacyPolicyComponent,
            options: dialogOptions
        }, { mode: ModalModes.large });

        return true;
    }

    public async cancel(): Promise<void> {
        // Do nothing
    }
}
