import { Injectable } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { ProjectDto } from "../../../generated/api";
import { LoadStrategies } from "../../common/load-strategies";
import { errorResult, SafeResult, successResult } from "../../common/safe-result";
import { Company } from "../../entities/core-data/company";
import { EntityTypes } from "../../entities/entity-types";
import { AppException } from "../../entities/exceptions/app-exception";
import { Project } from "../../entities/projects-processes/project";
import { FrontendErrors } from "../../global/frontend-errors";
import { Identifier } from "../../identifiers/identifier";
import { ProjectBusinessIdentifier } from "../../identifiers/project-business-identifier";
import { ProjectIdentifier } from "../../identifiers/project-identifier";
import { ProjectsApiService } from "../api/projects-api.service";
import { CoreDataService } from "../master-data/core-data-service/core-data.service";

/**
 * Service to work with PDFs.
 */
@Injectable({
    providedIn: "root"
})
export class ProjectsService {
    constructor(
        private readonly projectsApiService: ProjectsApiService,
        private readonly coreDataService: CoreDataService
    ) {
    }

    public async getProject(projectIdentifier: ProjectIdentifier, loadStrategy: LoadStrategies): Promise<SafeResult<Project, AppException>> {
        try {
            const project: Project|undefined = await this.coreDataService.loadEntity<Project>(projectIdentifier, EntityTypes.project, loadStrategy);
            if (!project) {
                return errorResult(new AppException(FrontendErrors.FE51ProjectDoesNotExistOrPermissionsMissing, $localize`:@@exception.fe51ProjectDoesNotExistOrPermissionsMissing:The project with the identifier ${Identifier.identifierToReadableString(projectIdentifier)}:identifier: does not exist or the permission required to view the project is missing.`));
            }
            return successResult(project);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE50UnableToLoadProject, $localize`:@@exception.fe50UnableToLoadProject:Unable to load the specified project.`, error));
        }
    }

    public async getProjects(searchQuery?: string, limit: number = environment.searchDefaultResultsCount, loadStrategy: LoadStrategies = LoadStrategies.preferServer, childrenLoadStrategy: LoadStrategies = loadStrategy): Promise<SafeResult<Array<Project>, AppException>> {
        try {
            const dtos: Array<ProjectDto>|undefined = await this.projectsApiService.getProjects(searchQuery, limit);
            const result: Array<Project> = [];
            for (const dto of dtos ?? []) {
                const project: Project = Api1Converter.dtoToProject(dto);
                await this.updateLinkedCompany(project, childrenLoadStrategy);
                await this.coreDataService.updateEntityCache(project);
                result.push(project);
            }

            return successResult(result);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE48UnableToLoadProjects, $localize`:@@exception.fe48UnableToLoadProjects:Unable to load the projects.`, error as Error));
        }
    }

    public async updateLinkedCompany(project: Project, loadStrategy: LoadStrategies): Promise<void> {
        if (project.companyLink?.identifier.technicalIdentifier && !project.companyLink.entity) {
            project.companyLink.entity = await this.coreDataService.loadEntity<Company>(project.companyLink.identifier, EntityTypes.company, loadStrategy);
            if (project.companyLink.entity) {
                project.companyLink.identifier = project.companyLink.entity.identifier;
            }
        }
    }

    public async deleteProject(businessIdentifier: ProjectBusinessIdentifier): Promise<SafeResult<void, AppException>> {
        try {
            await this.projectsApiService.deleteProject(businessIdentifier);
            return successResult(undefined);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE62UnableToDeleteProject, $localize`:@@exception.fe62UnableToDeleteProject:Unable to delete project with identifier "${businessIdentifier}".`, error));
        }
    }
}
