import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { IonInput, ModalController } from "@ionic/angular";
import { AppIcons } from "src/app/business/services/icons/app-icons";
import { Swiper } from "swiper";

import { environment } from "../../../../../environments/environment";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { DialogService } from "../../../../base/services/dialog/dialog.service";
import { ExtendedValidators } from "../../../../base/validators/extended-validators";
import { SessionService } from "../../../services/session/session.service";
import { FormValidationErrorMessages } from "../../forms/form-validation-error/form-validation-error-messages";
import { RegistrationOptions } from "./registration-options";
import { RegistrationSwiperPages } from "./registration-swiper-pages";

/**
 * Component to register a new user.
 */
@Component({
    selector: "app-registration",
    templateUrl: "./registration.component.html",
    styleUrls: ["./registration.component.scss"]
})
export class RegistrationComponent implements OnInit, AfterViewInit {
    constructor(
        private readonly sessionService: SessionService,
        private readonly dialogService: DialogService,
        private readonly formBuilder: FormBuilder,
        private readonly modalController: ModalController
    ) {
        this.invitationCodeControl = this.formData.get("invitationCode")!;
        this.emailControl = this.formData.get("email")!;
        this.passwordControl = this.formData.get("password")!;
        this.passwordRepeatControl = this.formData.get("passwordRepeat")!;
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly formHelper: typeof FormHelper = FormHelper;

    public options?: RegistrationOptions;

    private readonly passwordMinLength: number = environment.passwordMinLength;

    private readonly invitationCodeMinLength: number = environment.invitationCodeMinLength;

    public swiper?: Swiper;

    private eventsAttached: boolean = false;

    public pages: typeof RegistrationSwiperPages = RegistrationSwiperPages;

    public currentPage: RegistrationSwiperPages = RegistrationSwiperPages.askInvitationCode;

    public pageHistory: Array<number> = [];

    private focusElements: Map<RegistrationSwiperPages, IonInput> = new Map<RegistrationSwiperPages, IonInput>();

    public validationMessages: FormValidationErrorMessages = new FormValidationErrorMessages(
        [
            {
                controlName: "invitationCode", messages: [
                    {
                        type: "minlength",
                        message: $localize`:@@registration.validation.invitationCodeMinLength:Please enter a valid invitation code (minimum length ${this.invitationCodeMinLength}:minLength:) or leave empty to continue without an invitation code.` as string
                    }
                ]
            },
            {
                controlName: "email", messages: [
                    {
                        type: "required",
                        message: $localize`:@@entity.validation.emailRequired:The e-mail is required.` as string
                    },
                    {
                        type: "email",
                        message: $localize`:@@entity.validation.emailFormat:The e-mail is not in the correct format (e.g. name@example.com).` as string
                    }
                ]
            },
            {
                controlName: "password", messages: [
                    {
                        type: "required",
                        message: $localize`:@@registration.validation.passwordRequired:The password is required.` as string
                    },
                    {
                        type: "minlength",
                        message: $localize`:@@registration.validation.passwordMinLength:The minimum length of a password is ${this.passwordMinLength}:minLength:.` as string
                    },
                    {
                        type: "passwordLowercaseLetter",
                        message: $localize`:@@registration.validation.passwordLowercaseLetter:The password should include at least one lowercase letter (a-z).` as string
                    },
                    {
                        type: "passwordUppercaseLetter",
                        message: $localize`:@@registration.validation.passwordUppercaseLetter:The password should include at least one uppercase letter (A-Z).` as string
                    },
                    {
                        type: "passwordNumber",
                        message: $localize`:@@registration.validation.passwordNumber:The password should include at least one number (0-9).` as string
                    }
                ]
            },
            {
                controlName: "passwordRepeat", messages: [
                    {
                        type: "required",
                        message: $localize`:@@registration.validation.passwordRepeatRequired:Please repeat the password entered before.` as string
                    },
                    {
                        type: "match",
                        message: $localize`:@@registration.validation.passwordRepeatMatch:The password does not match the one entered before.` as string
                    }
                ]
            }
        ]
    );

    @ViewChild("invitationCode")
    public invitationCodeViewChild!: IonInput;

    @ViewChild("email")
    public emailViewChild!: IonInput;

    @ViewChild("password")
    public passwordViewChild!: IonInput;

    @ViewChild("createAccountButton", { read: ElementRef })
    public createAccountButton?: ElementRef<HTMLIonButtonElement>;

    public readonly emailControl: AbstractControl;

    private readonly invitationCodeControl: AbstractControl;

    public readonly passwordControl: AbstractControl;

    public formData: FormGroup = this.formBuilder.group({
        invitationCode: ["", [Validators.minLength(this.invitationCodeMinLength)]],
        email: ["", [Validators.required, Validators.email], [
            (): Promise<ValidationErrors|undefined> => ExtendedValidators.regexValidator("email", "(.*)@(.*)[\.](.*)", this.formData.controls.email)
        ]],

        password: ["", [Validators.required, Validators.minLength(this.passwordMinLength)],
            [
                (): Promise<ValidationErrors|undefined> => ExtendedValidators.regexValidator("passwordLowercaseLetter", "(.*)[a-z](.*)", this.passwordControl),
                (): Promise<ValidationErrors|undefined> => ExtendedValidators.regexValidator("passwordUppercaseLetter", "(.*)[A-Z](.*)", this.passwordControl),
                (): Promise<ValidationErrors|undefined> => ExtendedValidators.regexValidator("passwordNumber", "(.*)[0-9](.*)", this.passwordControl)
            ]
        ],

        passwordRepeat: ["", [Validators.required], (): Promise<ValidationErrors|undefined> => {
            const passwordsMatch: boolean = this.formData.get("password")?.value == this.formData.get("passwordRepeat")?.value;
            return Promise.resolve(passwordsMatch ? undefined : { match: false });
        }]
    });

    private readonly passwordRepeatControl: AbstractControl;

    public ngOnInit(): void {
        if (this.options?.email) {
            this.emailControl.setValue(this.options.email);
        }
        if (this.options?.invitationCode) {
            this.emailControl.setValue(this.options.invitationCode);
        }
    }

    public ngAfterViewInit(): void {
        // Do nothing for now
    }

    private attachEvents(): void {
        if (this.eventsAttached) {
            return;
        }

        this.swiper!.on("slideChangeTransitionEnd", this.slideChanged.bind(this));
        this.eventsAttached = true;
    }

    private updateFocusElements(): void {
        this.focusElements.set(RegistrationSwiperPages.enterInvitationCode, this.invitationCodeViewChild);
        this.focusElements.set(RegistrationSwiperPages.enterEmail, this.emailViewChild);
        this.focusElements.set(RegistrationSwiperPages.enterPassword, this.passwordViewChild);
    }

    public setSwiperInstance(swiper: Swiper): void {
        this.swiper = swiper;
    }

    private slideChanged(): void {
        this.updateFocusElements();

        const focusElement: IonInput|undefined = this.focusElements.get(this.currentPage);
        if (focusElement) {
            focusElement.setFocus().then();
        }
    }

    public swipeTo(page: RegistrationSwiperPages): void {
        if (!this.swiper) {
            return;
        }
        this.attachEvents();

        this.currentPage = page;

        if (!this.pageHistory.includes(this.swiper.activeIndex)) {
            this.pageHistory.push(this.swiper.activeIndex);
        }

        this.swiper.slideTo(page);
    }

    public checkInvitationCode(): void {
        this.swipeTo(RegistrationSwiperPages.enterEmail);
    }

    public checkEmail(): void {
        this.swipeTo(RegistrationSwiperPages.enterPassword);
    }

    public checkPasswordSubmit(): void {
        this.createAccountButton?.nativeElement.click();
    }

    public checkPassword(): void {
        if (this.passwordRepeatControl.value != this.passwordControl.value) {
            // TODO
            console.error("Passwords do not match.");
        }
    }

    public goBack(jumpTo?: RegistrationSwiperPages): void {
        if (this.pageHistory.length > 0) {
            if (jumpTo != undefined) {
                const difference: number = this.currentPage > jumpTo ? (this.currentPage - jumpTo) : 0;
                if ((this.pageHistory.length - 1) > 0 && (this.pageHistory.length - difference) > 0 && (this.pageHistory.length - difference) < this.pageHistory.length) {
                    this.pageHistory.splice(this.pageHistory.length - difference, this.pageHistory.length - 1);
                }

                this.currentPage = jumpTo;
                this.swiper?.slideTo(jumpTo);
            } else {
                const page: number|undefined = this.pageHistory.pop();
                if (page !== undefined) {
                    this.currentPage = page;
                    this.swiper?.slideTo(page);
                }
            }
        }
    }

    public close(successful?: boolean): void {
        this.modalController.dismiss(successful).then();
    }
}
