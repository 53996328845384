/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SampleTypeEnumDto = 'legionella' | 'microbiology' | 'chemical';

export const SampleTypeEnumDto = {
    Legionella: 'legionella' as SampleTypeEnumDto,
    Microbiology: 'microbiology' as SampleTypeEnumDto,
    Chemical: 'chemical' as SampleTypeEnumDto
};

