import { Location } from "@angular/common";
import { EventEmitter, Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Params, Router, RouterEvent } from "@angular/router";

import { EventHelper } from "../../../base/helpers/event-helper";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { Pages } from "../../pages/pages";
import { AppService } from "../app/app.service";
import { LoggingService } from "../logging/logging.service";
import { NavigatedEventData } from "./navigated-event-data";

/**
 * The service to navigate within the application.
 */
@Injectable({
    providedIn: "root"
})
export class NavigationService {
    constructor(
        private readonly loggingService: LoggingService,
        private readonly appService: AppService,
        private readonly location: Location,
        private readonly router: Router,
        private readonly dialogService: DialogService
    ) {
        if (this.router.events) {
            EventHelper.subscribe(this.router.events, this.routerEvent, this);
        }
    }

    public currentPage?: string = undefined;

    public leavingPage: EventEmitter<NavigatedEventData> = new EventEmitter<NavigatedEventData>();

    public navigated: EventEmitter<NavigatedEventData> = new EventEmitter<NavigatedEventData>();

    private lastRouterEvent?: RouterEvent;

    private activeNavigationCheckMethod?: NavigationCheckFunction;

    private routerEvent(routerEvent: NavigationStart|NavigationEnd|any): void {
        if (!(routerEvent instanceof NavigationStart) && !(routerEvent instanceof NavigationEnd)) {
            // We only process the start and end navigation event, return if it is something else.
            return;
        }
        if (routerEvent === this.lastRouterEvent) {
            // Prevent double events
            this.lastRouterEvent = routerEvent;
            return;
        }
        this.lastRouterEvent = routerEvent;

        const navigationStart: NavigationStart = routerEvent as NavigationStart;
        let navigationEnd: NavigationEnd|undefined = routerEvent as NavigationEnd;
        if (navigationStart && navigationStart.navigationTrigger) {
            navigationEnd = undefined;
        }

        const url: URL = new URL(navigationStart.url, window.location.origin);
        const rawRoutes: Array<string> = url.pathname?.split("/");
        if (!rawRoutes || rawRoutes.length <= 0) {
            return;
        }
        let routes: Array<any> = rawRoutes[0] ? rawRoutes : rawRoutes.slice(1);
        const pageRouteName: string = routes[0] as string;
        routes = routes.slice(1);
        const newPage: string|undefined = pageRouteName || undefined;

        if (navigationStart) {
            if (newPage != this.currentPage) {
                this.leavingPage.emit({
                    previousPage: this.currentPage,
                    page: newPage,
                    route: routes,
                    params: url.searchParams,
                    sourceEvent: routerEvent
                });
            }
        }

        if (navigationEnd) {
            this.navigated.emit({
                previousPage: this.currentPage,
                page: newPage,
                route: routes,
                params: url.searchParams,
                sourceEvent: routerEvent
            });
            this.currentPage = newPage;
        }
    }

    private generateParameterUrl(parameters: Map<string, string>): string|undefined {
        const result: Array<string> = [];
        for (const key of parameters.keys()) {
            result.push(`${encodeURIComponent(key)}=${encodeURIComponent(parameters.get(key)!)}`);
        }

        return result.join("&");
    }

    public async navigateWithParameters(page: Pages, additionalRoutes: Array<string>, parameters?: Map<string, string>): Promise<boolean> {
        this.loggingService.navigation(page, ...additionalRoutes);

        if (this.appService.needsRestart) {
            this.appService.restartApp(true).then();
            return Promise.resolve(false);
        }

        if (this.currentPage == page) {
            await this.changeUrlWithParameters(page, additionalRoutes, parameters);
            return true;
        } else {
            const parameterUri: string|undefined = parameters ? this.generateParameterUrl(parameters) : undefined;
            const uri: string = `${page ? `/${page}` : ""}${additionalRoutes?.length > 0 ? `/${additionalRoutes.join("/")}` : ""}${parameterUri ? `?${parameterUri}` : ""}`;

            const currentUri: string = this.location.path();
            if (uri == currentUri) {
                return false;
            }

            if (this.activeNavigationCheckMethod) {
                const allowNavigate: boolean = await this.activeNavigationCheckMethod(page, additionalRoutes, parameters);
                if (!allowNavigate) {
                    return false;
                }
            }

            return this.router.navigateByUrl(uri);
        }
    }

    public navigate(page: Pages, ...additionalRoutes: Array<string>): Promise<boolean> {
        this.dialogService.closeToast("documentToast");
        return this.navigateWithParameters(page, additionalRoutes);
    }

    public createRoute(page: Pages, ...additionalRoutes: Array<string>): string {
        return this.router.createUrlTree([page, ...additionalRoutes]).toString();
    }

    public createRouteWithParameters(page: Pages, additionalRoutes: Array<string>, parameters?: Params): string {
        return this.router.createUrlTree([page, ...additionalRoutes], { queryParams: parameters }).toString();
    }

    public async changeUrlWithParameters(page: Pages, additionalRoutes: Array<string>, parameters?: Map<string, string>): Promise<void> {
        const parameterUri: string|undefined = parameters != null && parameters?.size > 0 ? `?${this.generateParameterUrl(parameters)}` : undefined;
        const uri: string = parameterUri ? this.createRoute(page, ...additionalRoutes) + parameterUri : this.createRoute(page, ...additionalRoutes);
        const currentUri: string = this.location.path();
        if (currentUri == uri) {
            return;
        }

        if (this.activeNavigationCheckMethod) {
            const allowNavigate: boolean = await this.activeNavigationCheckMethod(page, additionalRoutes, parameters);
            if (!allowNavigate) {
                return;
            }
        }

        this.location.go(uri);
        this.navigated.emit({ previousPage: this.currentPage, page: page, route: additionalRoutes });
    }

    public async changeUrl(page: Pages, ...additionalRoutes: Array<string>): Promise<void> {
        return this.changeUrlWithParameters(page, additionalRoutes);
    }

    public registerNavigationCheck(checkMethod: NavigationCheckFunction|undefined): void {
        this.activeNavigationCheckMethod = checkMethod;
    }

    public unregisterNavigationCheck(): void {
        this.activeNavigationCheckMethod = undefined;
    }
}

export type NavigationCheckFunction = (page: Pages, additionalRoutes: Array<string>, parameters?: Map<string, string>) => Promise<boolean>;
