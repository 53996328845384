/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * If the entity is in status draft, it can be updated within the same version, i.e. the update request will apply the modification within the same technicalId. If the entity is archived, a new version will be created as soon as a update occurs, i.e. the response will contain a new technicalId for the same businessId. If the entity status is deleted, then a delete was issued previously but the entity could not be deleted since it is referenced by another archived entity
 */
export type EntityStatusEnumDto = 'draft' | 'archived' | 'deleted';

export const EntityStatusEnumDto = {
    Draft: 'draft' as EntityStatusEnumDto,
    Archived: 'archived' as EntityStatusEnumDto,
    Deleted: 'deleted' as EntityStatusEnumDto
};

