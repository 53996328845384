import { HttpErrorResponse } from "@angular/common/http";

import { ErrorMessageDto } from "../../generated/api";
import { AppException } from "../entities/exceptions/app-exception";
import { BackendErrors } from "../global/backend-errors";

/**
 * Various helper for the backend communication.
 */
export class BackendHelper {
    public static isBackendError(error: any, backendError?: BackendErrors): boolean {
        let errorObject: any = error as unknown;
        const appException: AppException = error as AppException;
        if (appException?.innerException) {
            if (appException.innerErrorCode) {
                return backendError ? appException.innerErrorCode == backendError : true;
            }
            errorObject = appException.innerException as unknown;
        }
        if (!errorObject) {
            return false;
        }

        let errorDto: ErrorMessageDto|undefined;
        if (errorObject instanceof HttpErrorResponse) {
            errorDto = errorObject.error as ErrorMessageDto;
        } else if (errorObject["code"] && errorObject["name"] && errorObject["status"] && errorObject["details"]) {
            errorDto = errorObject as ErrorMessageDto;
        } else {
            return false;
        }

        if (!errorDto?.code) {
            return false;
        }

        return backendError ? errorDto.code == backendError : true;
    }

    public static getBackendError(error: any): ErrorMessageDto|undefined {
        if ("code" in error && "status" in error && "name" in error && "details" in error) {
            return error as ErrorMessageDto;
        }
        if ("error" in error) {
            const innerError: any = error.error as unknown;
            if ("code" in innerError && "status" in innerError && "name" in innerError && "details" in innerError) {
                return innerError as ErrorMessageDto;
            }
        }
        return undefined;
    }
}
