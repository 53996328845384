/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressDto } from './address';
import { EntityStatusEnumDto } from './entityStatusEnum';
import { FloorDto } from './floor';


export interface BuildingDto { 
    businessId?: string;
    technicalId?: number;
    created?: string;
    updated?: string;
    updatedClient?: string;
    entityStatus?: EntityStatusEnumDto;
    /**
     * An optional name to better identify the building.
     */
    name?: string;
    externalReference?: string;
    addresses?: Array<AddressDto>;
    photoIds?: Array<number>;
    constructionDate?: string;
    buildingUsage?: BuildingDto.BuildingUsageEnum;
    publicUsage?: boolean;
    usageDescription?: string;
    numberOfFlats?: number;
    numberOfOffices?: number;
    numberOfUnusedFlatsAndOffices?: number;
    installationPlansExist?: boolean;
    visibleAscendingPipes?: number;
    ascendingPipesEnds?: string;
    floors?: Array<FloorDto>;
    drinkingWaterHeatingType?: BuildingDto.DrinkingWaterHeatingTypeEnum;
    drinkingWaterHeatingLocation?: string;
    locationHouseConnection?: string;
    additionalInfo?: string;
    buildingComplexIds?: Array<number>;
}
export namespace BuildingDto {
    export type BuildingUsageEnum = 'unknown' | 'residential' | 'commercial' | 'residential-and-commercial';
    export const BuildingUsageEnum = {
        Unknown: 'unknown' as BuildingUsageEnum,
        Residential: 'residential' as BuildingUsageEnum,
        Commercial: 'commercial' as BuildingUsageEnum,
        ResidentialAndCommercial: 'residential-and-commercial' as BuildingUsageEnum
    };
    export type DrinkingWaterHeatingTypeEnum = 'centralized' | 'decentralized' | 'mixed' | 'small-centralized';
    export const DrinkingWaterHeatingTypeEnum = {
        Centralized: 'centralized' as DrinkingWaterHeatingTypeEnum,
        Decentralized: 'decentralized' as DrinkingWaterHeatingTypeEnum,
        Mixed: 'mixed' as DrinkingWaterHeatingTypeEnum,
        SmallCentralized: 'small-centralized' as DrinkingWaterHeatingTypeEnum
    };
}


