<div class="root contentPadding">
    <h2 class="pageTitle" i18n="@@share.shareCurrentPage">Share current page</h2>
    <div class="urlInput">{{url}}</div>
    <div *ngIf="canShare" class="shareButton">
        <base-process-button (triggered)="share($event)" [icon]="appIcons.share" buttonStyle="stroked" i18n="@@share.shareButton">Share</base-process-button>
    </div>
    <div>
        <button (click)="copyAnimation()" [cdkCopyToClipboard]="url" class="clipboardButton" color="primary" mat-stroked-button>
            <fa-icon [icon]="copied ? appIcons.genericCheck : appIcons.copyToClipboard"></fa-icon>
            <label *ngIf="!copied" i18n="@@share.copyAddressToClipboard">Copy address to clipboard</label>
            <label *ngIf="copied" i18n="@@share.copiedAddressToClipboard">Saved to clipboard</label>
        </button>
    </div>

    <qr-code [value]="url" class="shareUrlQrCode"></qr-code>
</div>
