import { Component, ViewChild } from "@angular/core";
import { MatTable } from "@angular/material/table";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { IonSearchbar, ModalController } from "@ionic/angular";
import { InvitePersonIntent } from "src/app/business/intents/master-data/invite-person-intent";

import { environment } from "../../../../../environments/environment";
import { BaseComponent } from "../../../../base/components/base-component";
import { ContextMenuItem } from "../../../../base/components/context-menu/context-menu-item";
import { ArrayHelper } from "../../../../base/helpers/array-helper";
import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { ProcessElementTriggeredEventArguments } from "../../../../base/interfaces/process-element-triggered-event-arguments";
import { DialogService } from "../../../../base/services/dialog/dialog.service";
import { IntentsService } from "../../../../base/services/intents/intents.service";
import { LoadStrategies } from "../../../common/load-strategies";
import { SafeResult } from "../../../common/safe-result";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { Person } from "../../../entities/core-data/person";
import { EntityTypes } from "../../../entities/entity-types";
import { AppException } from "../../../entities/exceptions/app-exception";
import { EntityHelper } from "../../../helpers/entity-helper";
import { PermissionsHelper } from "../../../helpers/permissions-helper";
import { DeleteEntityIntent } from "../../../intents/master-data/delete-entity-intent";
import { DeleteEntityIntentOptions } from "../../../intents/master-data/delete-entity-intent-options";
import { EditCoreDataIntent } from "../../../intents/master-data/edit-core-data-intent";
import { EditCoreDataIntentOptions } from "../../../intents/master-data/edit-core-data-intent-options";
import { AppIcons } from "../../../services/icons/app-icons";
import { BuildingsService } from "../../../services/master-data/buildings/buildings.service";
import { CompaniesService } from "../../../services/master-data/companies/companies.service";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { PersonsService } from "../../../services/master-data/persons/persons.service";
import { NavigatedEventData } from "../../../services/navigation/navigated-event-data";
import { NavigationService } from "../../../services/navigation/navigation.service";
import { ProjectsService } from "../../../services/projects-processes/projects.service";
import { SessionService } from "../../../services/session/session.service";
import { Pages } from "../../pages";
import { CoreDataListOptions } from "./core-data-list-options";
import { CoreDataTableEntry } from "./core-data-table-entry";

/**
 * A page to search and list buildings.
 */
@Component({
    selector: "business-core-data-list",
    templateUrl: "./core-data-list-page.component.html",
    styleUrls: ["./core-data-list-page.component.scss"]
})
export class CoreDataListPage extends BaseComponent {
    constructor(
        private readonly intentsService: IntentsService,
        private readonly sessionService: SessionService,
        private readonly projectsService: ProjectsService,
        private readonly buildingsService: BuildingsService,
        private readonly personsService: PersonsService,
        private readonly companiesService: CompaniesService,
        private readonly navigationService: NavigationService,
        private readonly modalController: ModalController,
        private readonly dialogService: DialogService
    ) {
        super();
    }

    public readonly entityTypes: typeof EntityTypes = EntityTypes;

    public readonly appIcons: typeof AppIcons = AppIcons;

    public options!: CoreDataListOptions;

    public searchInputDebounce: number = environment.searchInputDebounce;

    public pageSize: number = environment.searchDefaultResultsCount;

    public searching: boolean = false;

    public forceLoadActive: boolean = false;

    public lastSearchQuery: string = "";

    public entities: Array<GenericCoreDataEntity> = [];

    public sortedEntities: Array<GenericCoreDataEntity> = [];

    public hasMoreResults: boolean = false;

    public hasDeletePermission: boolean = false;

    public hasCreatePermission: boolean = true;

    public requiredCreatePermissions?: Array<string>;

    @ViewChild("searchbar")
    public searchbar?: IonSearchbar;

    public rows: Array<CoreDataTableEntry> = [];

    public displayedColumns: Array<string> = [];

    @ViewChild("table")
    public tableComponent?: MatTable<CoreDataTableEntry>;

    private searchQueryField: string = "";

    public get searchQuery(): string {
        return this.searchQueryField;
    }

    public set searchQuery(value: string) {
        if (value && this.searchQueryField == value) {
            return;
        }
        this.searchQueryField = value;

        this.updateUrl().then();
        if (!this.searching) {
            this.loadList().then();
        }
    }

    protected componentInit(): void {
        this.options = this.options ?? new CoreDataListOptions({ allowCreate: true, allowInvite: true });

        this.applyPermissions();

        if (this.options.dialogMode) {
            this.loadList().then();
        } else {
            this.subscribe(this.navigationService.navigated, this.routeChanged);
        }
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    private applyPermissions(): void {
        this.requiredCreatePermissions = PermissionsHelper.permissionForCreate(this.options.entityType);
        this.hasCreatePermission = PermissionsHelper.canCreate(this.options.entityType, this.sessionService.activePermissions);
        this.hasDeletePermission = PermissionsHelper.canDelete(this.options.entityType, this.sessionService.activePermissions);

        if (this.hasDeletePermission) {
            this.displayedColumns = ["badge", "title", "subTitle", "actions"];
        } else {
            this.displayedColumns = ["badge", "title", "subTitle"];
        }
    }

    public async entityClicked(entity: GenericCoreDataEntity): Promise<void> {
        if (this.options.selectMode) {
            this.modalController.dismiss([entity]).then();
            return;
        }

        if (entity.entityType == EntityTypes.project) {
            await this.navigationService.navigate(Pages.project, ...ArrayHelper.createArrayAndStopAtFirstEmptyValue(entity.identifier.businessIdentifier, entity.identifier.technicalIdentifier ? `${entity.identifier.technicalIdentifier}` : undefined));
        } else {
            const intentOptions: EditCoreDataIntentOptions<GenericCoreDataEntity> = new EditCoreDataIntentOptions<GenericCoreDataEntity>(entity);
            await this.intentsService.executeIntentAndWait(EditCoreDataIntent, intentOptions);
            if (intentOptions.resultEntity) {
                this.applyUpdatedEntity(intentOptions.resultEntity);
            }

            if (entity.entityType == EntityTypes.person && entity.identifier.businessIdentifier == this.sessionService.activePersonBusinessId) {
                this.sessionService.refreshActivePerson().then();
            }
        }
    }

    private applyUpdatedEntity(updatedEntity: GenericCoreDataEntity): void {
        for (const entity of this.entities) {
            if (entity.identifier.businessIdentifier == updatedEntity.identifier.businessIdentifier) {
                entity.fromStorageDto(updatedEntity.toStorageDto());
            }
        }
    }

    public async createEntity(triggeredEventData?: ProcessElementTriggeredEventArguments): Promise<void> {
        const entity: GenericCoreDataEntity|undefined = CoreDataFactory.createFromEntityType(this.options.entityType);
        if (entity) {
            const intentOptions: EditCoreDataIntentOptions<GenericCoreDataEntity> = new EditCoreDataIntentOptions<GenericCoreDataEntity>(entity);
            await this.intentsService.executeIntentAndWait(EditCoreDataIntent, intentOptions);
            triggeredEventData?.finishedReceiver.showLoadingIndicator();
            if (intentOptions.resultEntity) {
                this.entities.unshift(intentOptions.resultEntity);
                this.sortedEntities = [...this.entities];
                this.rows.unshift(new CoreDataTableEntry(intentOptions.resultEntity));
                this.tableComponent?.renderRows();
            }
            triggeredEventData?.finishedReceiver.finished();
        }
    }

    public async switchToEntity(entityType: EntityTypes): Promise<void> {
        await this.navigationService.changeUrl(Pages.coreDataList, EntityHelper.entityTypeToUrlComponent(entityType));
    }

    public invitePerson(): void {
        this.intentsService.executeIntent(InvitePersonIntent);
    }

    public focusSearch(): void {
        this.searchbar?.setFocus();
    }

    public itemIcon(item: GenericCoreDataEntity): IconDefinition|undefined {
        switch (item.entityType) {
            case EntityTypes.person:
                const person: Person = item as Person;
                switch (person.status) {
                    case "EMPLOYEE":
                        return AppIcons.entityPersonEmployeeSolid;
                    default:
                        return AppIcons.entityPersonRegular;
                }
            default:
                return undefined;
        }
    }

    private async routeChanged(eventData: NavigatedEventData): Promise<void> {
        if (this.options.dialogMode || eventData.page != Pages.coreDataList) {
            return;
        }

        const entityTypeString: string|undefined = EntityHelper.urlComponentToEntityTypeString(eventData.route[0] as string);
        this.options.entityType = TypeScriptHelper.hasEnumValue(EntityTypes, entityTypeString) ? entityTypeString as EntityTypes : EntityTypes.all;
        this.searchQuery = eventData.route[1] as string;
        this.applyPermissions();
    }

    private async updateUrl(): Promise<void> {
        if (this.options.dialogMode) {
            return;
        }

        if (this.searchQuery) {
            await this.navigationService.changeUrl(Pages.coreDataList, EntityHelper.entityTypeToUrlComponent(this.options.entityType), this.searchQuery);
        } else {
            await this.navigationService.changeUrl(Pages.coreDataList, EntityHelper.entityTypeToUrlComponent(this.options.entityType));
        }
    }

    private async loadList(forceLoad: boolean = false): Promise<void> {
        this.searching = true;
        this.hasMoreResults = false;
        this.forceLoadActive = forceLoad;
        this.lastSearchQuery = this.searchQuery;

        let queryResult: SafeResult<Array<GenericCoreDataEntity>, AppException>|undefined;
        switch (this.options.entityType) {
            case EntityTypes.project:
                queryResult = await this.projectsService.getProjects(this.searchQuery, this.pageSize + 1, LoadStrategies.preferServer, forceLoad ? LoadStrategies.preferServer : LoadStrategies.preferCached);
                break;
        }

        // TODO: Proper exception handling?
        if (queryResult?.isError()) {
            this.dialogService.showError(queryResult.error).then();
            return;
        }

        if (queryResult?.isSuccess()) {
            this.entities = queryResult.result;
        } else {
            switch (this.options.entityType) {
                case EntityTypes.buildingComplex:
                    this.entities = await this.buildingsService.getBuildingComplexes(this.searchQuery, this.pageSize + 1);
                    break;
                case EntityTypes.person:
                    this.entities = this.options.auditorsOnly
                        ? await this.personsService.getAllAuditors(forceLoad ? LoadStrategies.preferServer : LoadStrategies.preferCached)
                        : await this.personsService.getPersons(this.searchQuery, this.pageSize + 1);
                    break;
                case EntityTypes.company:
                    this.entities = await this.companiesService.getCompanies(this.searchQuery, this.pageSize + 1);
                    break;
                default:
                    this.entities = [];
                    break;
            }
        }

        this.sortedEntities = this.entities;

        this.rows = [];
        for (const entity of this.sortedEntities) {
            this.rows.push(new CoreDataTableEntry(entity));
        }
        this.tableComponent?.renderRows();

        if (this.entities.length > this.pageSize) {
            this.hasMoreResults = true;
            this.entities.length = this.pageSize;
            this.rows.length = this.pageSize;
        }

        // Restart search if the query changed meanwhile
        if (this.searchQuery != this.lastSearchQuery) {
            await this.loadList();
        } else {
            this.searching = false;
        }
    }

    public externalReference(entity: GenericCoreDataEntity): string {
        return entity?.externalReference ? `${entity.externalReference}, ` : "";
    }

    public async refresh(triggeredEventArguments: ProcessElementTriggeredEventArguments): Promise<void> {
        await this.loadList(true);
        triggeredEventArguments.finishedReceiver.finished();
    }

    public async openActions(event: MouseEvent, entity: GenericCoreDataEntity): Promise<void> {
        event.stopPropagation();

        enum Actions {
            delete = "delete"
        }

        const menuItems: Array<ContextMenuItem> = [];
        menuItems.push(new ContextMenuItem(entity.displayTitle, {
            isLabel: true
        }));
        menuItems.push(new ContextMenuItem("", {
            isDivider: true
        }));
        if (this.hasDeletePermission) {
            menuItems.push(new ContextMenuItem($localize`:@@coreData.deleteContextMenuItem:Delete` as string, {
                icon: AppIcons.genericDelete,
                tag: Actions.delete
            }));
        }
        const result: ContextMenuItem|undefined = await this.dialogService.showContextMenu(menuItems, event, false);
        switch (result?.tag) {
            case Actions.delete:
                await this.deleteEntity(entity);
                break;
        }
    }

    private async deleteEntity(entity: GenericCoreDataEntity): Promise<void> {
        const options: DeleteEntityIntentOptions = new DeleteEntityIntentOptions(entity);
        await this.intentsService.executeIntentAndWait(DeleteEntityIntent, options);
        if (options.result) {
            this.entities = this.entities.filter((item: GenericCoreDataEntity) => item.identifier.businessIdentifier != entity.identifier.businessIdentifier);
            this.sortedEntities = this.sortedEntities.filter((item: GenericCoreDataEntity) => item.identifier.businessIdentifier != entity.identifier.businessIdentifier);
            this.rows = this.rows.filter((row: CoreDataTableEntry) => row.entity.identifier.businessIdentifier != entity.identifier.businessIdentifier);
            this.tableComponent?.renderRows();
        }
    }
}
