export enum ProcessStatus {
    unknown = "unknown",
    orderEntry = "orderEntry",
    scheduled = "scheduled",
    inProgress = "inProgress",
    inReview = "inReview",
    reviewAccepted = "reviewAccepted",
    reviewRejected = "reviewRejected",
    done = "done"
}
