/* eslint-disable @typescript-eslint/naming-convention */
export enum FrontendErrors {
    FE0TestException = "FE0",
    FE1UnableToAccessGallery = "FE1",
    FE2UnableToAccessCamera = "FE2",
    FE4SectionNotFound = "FE4",
    FE5ItemNotFound = "FE5",
    FE6UnableToSaveTypeMismatch = "FE6",
    FE7UnhandledPromiseException = "FE7",
    FE8UnableToCreateUser = "FE8",
    FE9FeatureNotSupported = "FE9",
    FE10AccountNotAvailable = "FE10",
    FE11UnableToRegisterAccount = "FE11",
    FE12UnhandledServerException = "FE12",
    FE13UnableToFetchPersons = "FE13",
    FE14UnableToTalkToServerAccountNotSet = "FE14",
    FE15UnableToFetchTemplates = "FE15",
    FE16UnableToCreateAudit = "FE16",
    FE17UnableGetAuditWithId = "FE17",
    FE18UnableGetTemplateWithId = "FE18",
    FE19DocumentIsMissingTemplateId = "FE19",
    FE20UnableToGetWalkthroughInspections = "FE20",
    FE21UnableGetDocumentWithId = "FE21",
    FE22QuestionSynchronizationError = "FE22",
    FE23UpdatedCommentForNonExistingQuestion = "FE23",
    FE24EnumDoesNotHaveAnIcon = "FE24",
    FE25EnumDoesNotHaveADescription = "FE25",
    FE26UnableToSaveValueContainerDoesNotExist = "FE26",
    FE27QuestionRequiredIdsMissing = "FE27",
    FE28SectionDoesNotHaveATemplateId = "FE28",
    FE29SectionDoesNotHaveAnId = "FE29",
    FE30UnableToGetValueContainerDoesNotExist = "FE30",
    FE31PersonNotFound = "FE31",
    FE32UnableToInvitePerson = "FE32",
    FE33EntityLinkPropertyTargetIsNotAnIdentifierPair = "FE33",
    FE34DocumentTypeIsUnknown = "FE34",
    FE35UnableToPersistDocumentTypeIsUnknown = "FE35",
    FE36UnableToInstantiateAttachmentTypeUnknown = "FE36",
    FE37UnablePersistEntityTypeUnknown = "FE37",
    FE38ImageNotFound = "FE38",
    FE39UnableToTalkToServerNotLoggedIn = "FE39",
    FE40UnableToCloneEntityTypeUnknown = "FE40",
    FE41HttpError = "FE41",
    FE42UnableToSaveCoreDataEntity = "FE42",
    FE43UnableToLoadAttachments = "FE43",
    FE44UnableToDeleteDocument = "FE44",
    FE45HealthReport = "FE45",
    FE46AttachmentTypeUnknown = "FE46",
    FE47UnableToLoadQualityAssuranceInformation = "FE47",
    FE48UnableToLoadProjects = "FE48",
    FE49UnableToLoadProcesses = "FE49",
    FE50UnableToLoadProject = "FE50",
    FE51ProjectDoesNotExistOrPermissionsMissing = "FE51",
    FE52SamplingPlanDoesNotExistOrPermissionsMissing = "FE52",
    FE53UnableToLoadSamplingPlan = "FE53",
    FE54EntityTypeIsUnknown = "FE54",
    FE55UnableToSaveSamplingPlan = "FE55",
    FE56UnableToLoadDashboard = "FE56",
    FE57UnableToSaveProcess = "FE57",
    FE58UnableToDeleteBuilding = "FE58",
    FE59UnableToDeleteBuildingComplex = "FE59",
    FE60UnableToDeleteCompany = "FE60",
    FE61UnableToDeletePerson = "FE61",
    FE62UnableToDeleteProject = "FE62",
    FE63UnableToDeleteProcess = "FE63",
    FE64UnableToDeleteMyself = "FE64",
    FE65UnhandledTextMessageException = "FE65",
    FE66UnableToLoadBuildingsFromBuildingComplex = "FE66",
    FE67UnableToSaveNotAllowedToSetEnabled = "FE67",
}
