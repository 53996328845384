/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CommentTemplateDto } from './commentTemplate';


export interface AnswerTemplateDto { 
    readonly id?: number;
    readonly created?: string;
    readonly updated?: string;
    answerKey?: string;
    unit?: AnswerTemplateDto.UnitEnum;
    scoring?: AnswerTemplateDto.ScoringEnum;
    commentTemplates?: Array<CommentTemplateDto>;
    commentMandatory?: boolean;
}
export namespace AnswerTemplateDto {
    export type UnitEnum = 'KBE' | 'YEAR' | 'LITER' | 'CELSIUS' | 'KELVIN' | 'MILLIMETER';
    export const UnitEnum = {
        Kbe: 'KBE' as UnitEnum,
        Year: 'YEAR' as UnitEnum,
        Liter: 'LITER' as UnitEnum,
        Celsius: 'CELSIUS' as UnitEnum,
        Kelvin: 'KELVIN' as UnitEnum,
        Millimeter: 'MILLIMETER' as UnitEnum
    };
    export type ScoringEnum = 'GOOD' | 'NEUTRAL' | 'WARNING' | 'CRITICAL';
    export const ScoringEnum = {
        Good: 'GOOD' as ScoringEnum,
        Neutral: 'NEUTRAL' as ScoringEnum,
        Warning: 'WARNING' as ScoringEnum,
        Critical: 'CRITICAL' as ScoringEnum
    };
}


