import { FrontendErrors } from "../../global/frontend-errors";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";
import { TemplateVersionTechnicalIdentifier } from "../../identifiers/template-version-technical-identifier";
import { AppException } from "../exceptions/app-exception";
import { StorableEntity } from "../storable-entity";
import { DocumentQuestion } from "./document-question";
import { DocumentQuestionTypes } from "./document-question-types";
import { DocumentSection } from "./document-section";
import { DocumentSectionStorageDto } from "./document-section-storage-dto";
import { DocumentTemplateStorageDto } from "./document-template-storage-dto";
import { DocumentTypes } from "./document-types";

/**
 * The scheme of a document. It is the template used to display and to generate the UI from.
 */
export class DocumentTemplate implements StorableEntity<DocumentTemplateStorageDto> {
    constructor(technicalIdentifier: TemplateVersionTechnicalIdentifier, documentType: DocumentTypes) {
        this.technicalIdentifier = technicalIdentifier;
        this.documentType = documentType;
    }

    public technicalIdentifier: TemplateVersionTechnicalIdentifier;

    public documentType: DocumentTypes;

    public name?: string;

    public sections: Array<DocumentSection> = [];

    private sectionsIndex: Map<SectionTemplateIdentifier, DocumentSection> = new Map<SectionTemplateIdentifier, DocumentSection>();

    private questionsIndex: Map<QuestionTemplateIdentifier, DocumentQuestion> = new Map<QuestionTemplateIdentifier, DocumentQuestion>();

    private bulkUpdateActive: boolean = false;

    public isSection(sectionIdentifier: SectionTemplateIdentifier): boolean {
        return this.sectionsIndex.has(sectionIdentifier);
    }

    public getSection(sectionTemplateIdentifier: SectionTemplateIdentifier): DocumentSection {
        const section: DocumentSection|undefined = this.sectionsIndex.get(sectionTemplateIdentifier);
        if (!section) {
            throw new AppException(FrontendErrors.FE4SectionNotFound, $localize`:@@exception.sectionNotFound:The section with the ID ${sectionTemplateIdentifier}:sectionIdentifier: cannot be found.`);
        }
        return section;
    }

    public addPartialSection(templateIdentifier: SectionTemplateIdentifier, partialSection: Partial<DocumentSection>, parentSectionIdentifier?: SectionTemplateIdentifier): DocumentSection {
        const section: DocumentSection = new DocumentSection(templateIdentifier, partialSection);
        this.addSection(section, parentSectionIdentifier);
        return section;
    }

    public addSection(section: DocumentSection, parentSectionIdentifier?: SectionTemplateIdentifier): DocumentSection {
        const parent: DocumentSection|undefined = parentSectionIdentifier && parentSectionIdentifier > 0 ? this.getSection(parentSectionIdentifier) : undefined;
        if (parent) {
            parent.addSection(section);
        } else {
            this.sections.push(section);
        }
        this.sectionsIndex.set(section.templateIdentifier, section);
        this.reindex();
        return section;
    }

    public getAllSections(): Map<SectionTemplateIdentifier, DocumentSection> {
        return this.sectionsIndex;
    }

    public getAllQuestions(): Map<QuestionTemplateIdentifier, DocumentQuestion> {
        return this.questionsIndex;
    }

    public getQuestion(questionTemplateIdentifier: QuestionTemplateIdentifier): DocumentQuestion {
        const item: DocumentQuestion|undefined = this.questionsIndex.get(questionTemplateIdentifier);
        if (!item) {
            throw new AppException(FrontendErrors.FE5ItemNotFound, $localize`:@@exception.itemNotFound:The document item with the ID ${questionTemplateIdentifier}:itemIdentifier: cannot be found.`);
        }
        return item;
    }

    public getQuestionType(questionTemplateIdentifier: QuestionTemplateIdentifier): DocumentQuestionTypes {
        const item: DocumentQuestion|undefined = this.questionsIndex.get(questionTemplateIdentifier);
        if (!item) {
            return DocumentQuestionTypes.unknown;
        }
        return item.type;
    }

    public addPartialQuestion(identifier: QuestionTemplateIdentifier, partialQuestion: Partial<DocumentQuestion>, parentSectionIdentifier: SectionTemplateIdentifier): DocumentQuestion {
        const item: DocumentQuestion = new DocumentQuestion(identifier, parentSectionIdentifier, partialQuestion);
        this.addQuestion(parentSectionIdentifier, item);
        return item;
    }

    public addQuestion(sectionIdentifier: SectionTemplateIdentifier, question: DocumentQuestion): DocumentQuestion {
        const section: DocumentSection = this.getSection(sectionIdentifier);
        section.questions.push(question);
        this.reindex();
        return question;
    }

    public beginBulkUpdate(): void {
        this.bulkUpdateActive = true;
    }

    public endBulkUpdate(): void {
        this.bulkUpdateActive = false;
        this.reindex();
    }

    private reindex(): void {
        if (this.bulkUpdateActive) {
            return;
        }

        this.sectionsIndex.clear();
        this.questionsIndex.clear();

        for (const section of this.sections) {
            this.reindexSection(section);
        }
    }

    private reindexSection(section: DocumentSection): void {
        this.sectionsIndex.set(section.templateIdentifier, section);

        this.reindexQuestions(section.questions);

        for (const childSection of section.sections) {
            childSection.parentTemplateIdentifier = section.templateIdentifier;
            this.reindexSection(childSection);
        }
    }

    private reindexQuestions(questions: Array<DocumentQuestion>): void {
        for (const question of questions) {
            this.questionsIndex.set(question.templateIdentifier, question);
            if (question.questions) {
                this.reindexQuestions(question.questions);
            }
        }
    }

    public toStorageDto(): DocumentTemplateStorageDto {
        const dto: DocumentTemplateStorageDto = new DocumentTemplateStorageDto();

        dto.technicalIdentifier = this.technicalIdentifier;
        dto.documentType = DocumentTypes[this.documentType];
        dto.name = this.name;

        for (const section of this.sections) {
            const sectionDto: DocumentSectionStorageDto = section.toStorageDto();
            dto.sections.push(sectionDto);
        }

        return dto;
    }

    public fromStorageDto(dto: DocumentTemplateStorageDto|undefined): void {
        this.technicalIdentifier = dto?.technicalIdentifier as TemplateVersionTechnicalIdentifier;
        this.documentType = dto?.documentType ? dto.documentType as DocumentTypes : DocumentTypes.unknown;
        this.name = dto?.name;

        for (const sectionDto of dto?.sections ?? []) {
            const existingSection: DocumentSection|undefined = this.sectionsIndex.get(sectionDto.templateId as SectionTemplateIdentifier);
            if (existingSection) {
                existingSection.fromStorageDto(sectionDto);
            } else {
                const section: DocumentSection = new DocumentSection(sectionDto.templateId as SectionTemplateIdentifier);
                section.fromStorageDto(sectionDto);
                this.sections.push(section);
            }
        }

        this.reindex();
    }
}
