import { DialogService } from "../../base/services/dialog/dialog.service";
import { Intent } from "../../base/services/intents/intent";
import { IntentsService } from "../../base/services/intents/intents.service";
import { IocService } from "../../base/services/ioc/ioc.service";
import { RegistrationComponent } from "../components/user/registration/registration.component";
import { RegistrationOptions } from "../components/user/registration/registration-options";
import { LoginIntent } from "./login-intent";
import { RegisterIntentOptions } from "./register-intent-options";

/**
 * Intent to register myself as a new user.
 */
export class RegisterIntent implements Intent {
    public name: string = "RegisterIntent";
    public identifier?: number;

    constructor(
        private readonly iocService: IocService) {
    }

    public async execute(intentOptions?: RegisterIntentOptions): Promise<boolean> {
        const intentsService: IntentsService = this.iocService.resolve(IntentsService);
        const dialogService: DialogService = this.iocService.resolve(DialogService);

        const result: boolean|undefined = await dialogService.showModal<boolean>({
            component: RegistrationComponent,
            payload: { options: new RegistrationOptions({ email: intentOptions?.email, invitationCode: intentOptions?.invitationCode }) }
        });
        if (!result) {
            return true;
        }
        return intentsService.executeIntentAndWait(LoginIntent);
    }

    public cancel(): Promise<void> {
        return Promise.resolve();
    }
}
