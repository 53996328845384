import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { ArrayHelper } from "../../../base/helpers/array-helper";
import { AsyncHelper } from "../../../base/helpers/async-helper";
import { EventHelper } from "../../../base/helpers/event-helper";
import { PerformanceMeasurement } from "../../../base/helpers/performance-measurement";
import { WebHelper } from "../../../base/helpers/web-helper";
import { TimeService } from "../../../base/services/time/time.service";
import { Timer } from "../../../base/services/time/timer";
import { ErrorMessageDto, PhotoDto } from "../../../generated/api";
import { LoadStrategies } from "../../common/load-strategies";
import { errorResult, SafeResult, successResult } from "../../common/safe-result";
import { Attachment } from "../../entities/attachments/attachment";
import { AttachmentFactory } from "../../entities/attachments/attachment-factory";
import { AttachmentStates } from "../../entities/attachments/attachment-states";
import { AttachmentStorageDto } from "../../entities/attachments/attachment-storage-dto";
import { AttachmentTypes } from "../../entities/attachments/attachment-types";
import { FileAttachment } from "../../entities/attachments/file-attachment";
import { FileAttachmentStorageDto } from "../../entities/attachments/file-attachment-storage-dto";
import { ImageAttachment } from "../../entities/attachments/image-attachment";
import { ImageAttachmentStorageDto } from "../../entities/attachments/image-attachment-storage-dto";
import { AppException } from "../../entities/exceptions/app-exception";
import { BackendErrors } from "../../global/backend-errors";
import { FrontendErrors } from "../../global/frontend-errors";
import { BackendHelper } from "../../helpers/backend-helper";
import { AttachmentBusinessIdentifier } from "../../identifiers/attachment-business-identifier";
import { AttachmentIdentifier } from "../../identifiers/attachment-identifier";
import { AttachmentTechnicalIdentifier } from "../../identifiers/attachment-technical-identifier";
import { BusinessTechnicalIdentifierPair } from "../../identifiers/business-technical-identifier-pair";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { DocumentTechnicalIdentifier } from "../../identifiers/document-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { SessionService } from "../session/session.service";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";
import { AttachmentQueueItemStorageDto } from "./attachment-queue-item-storage-dto";
import { ImageService } from "./image.service";
import { LocalFileService } from "./local-file.service";
import { LocalFileStorageIdentifier } from "./local-file-storage-identifier";
import { LocaleFileStorageItem } from "./locale-file-storage-item";

/**
 * Up- and download service for attachments, like photos or audio files.
 */
@Injectable({
    providedIn: "root"
})
export class AttachmentLoaderService {
    constructor(
        private readonly photoApiService: ImageService,
        private readonly storageService: StorageService,
        private readonly localFileService: LocalFileService,
        private readonly sessionService: SessionService,
        private readonly timeService: TimeService
    ) {
        this.uploadQueueCheckTimer = this.timeService.spawnTimer(this.checkPendingTimerInterval);
        EventHelper.subscribe(this.uploadQueueCheckTimer.elapsed, this.checkTimerElapsed, this);
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private readonly checkPendingTimerInterval: number = 60 * 1000;

    private readonly attachmentFailedUploadDelay: number = environment.attachmentFailedUploadDelay;

    private readonly attachmentSuccessfulUploadDelay: number = environment.attachmentSuccessfulUploadDelay;

    private uploadingField: boolean = false;

    private lastMetaUpdateFakeStorageId: LocalFileStorageIdentifier = -1 as LocalFileStorageIdentifier;

    private lastUpload: number = 0;

    private lastUploadFailed: boolean = false;

    private uploadQueue: Map<LocalFileStorageIdentifier, FileAttachment> = new Map<LocalFileStorageIdentifier, FileAttachment>();

    private storedAttachments: Map<AttachmentBusinessIdentifier, FileAttachment> = new Map<AttachmentBusinessIdentifier, FileAttachment>();

    private businessIdFileMappingCache: Map<string, LocalFileStorageIdentifier> = new Map<string, LocalFileStorageIdentifier>();

    private rescheduleQueueTimeoutHandle?: any;

    public uploadQueueLoaded: EventEmitter<void> = new EventEmitter<void>();

    public uploadQueueChanged: EventEmitter<number> = new EventEmitter<number>();

    public attachmentStateChanged: EventEmitter<FileAttachment> = new EventEmitter<FileAttachment>();

    public attachmentDeleted: EventEmitter<FileAttachment> = new EventEmitter<FileAttachment>();

    public unrecoverableUploadError: EventEmitter<FileAttachment> = new EventEmitter<FileAttachment>();

    public attachmentReplacedWithExistingAttachment: EventEmitter<{
        oldAttachment: FileAttachment;
        newAttachment: FileAttachment;
    }> = new EventEmitter<{
            oldAttachment: FileAttachment;
            newAttachment: FileAttachment;
        }>();

    public uploadQueueCheckTimer!: Timer;

    public loadingStoredAttachments: boolean = true;

    private get uploading(): boolean { return this.uploadingField; }

    private set uploading(value: boolean) {
        this.uploadingField = value;
        if (value) {
            this.lastUploadFailed = false;
        } else {
            this.lastUpload = this.timeService.currentTimestamp;
        }
    }

    public get queueItemsCount(): number {
        return this.uploadQueue.size;
    }

    public async initialize(): Promise<void> {
        this.initializeStoredAndPendingAttachments().then(() => {
            this.uploadQueueCheckTimer.start();
        });
    }

    private async initializeStoredAndPendingAttachments(): Promise<void> {
        PerformanceMeasurement.start("initializeStoredAndPendingAttachments");
        this.loadingStoredAttachments = true;
        try {
            const filteredForUploadQueue: Array<FileAttachment> = [];
            let merged: Array<ImageAttachmentStorageDto> = [];

            const storedQueue: Array<AttachmentQueueItemStorageDto>|undefined = await this.storageService.get(StorageKeys.attachmentUploadQueue);
            if (storedQueue) {
                // Take the stored queue
                for (const storedQueueItem of storedQueue) {
                    let attachmentDto: ImageAttachmentStorageDto|undefined;
                    if (storedQueueItem.businessId) {
                        attachmentDto = await this.storageService.getGroupItem(StorageKeys.groupAttachmentsServer, Identifier.identifierToString(Identifier.fromDto(storedQueueItem)));
                    } else if (storedQueueItem.storageId) {
                        attachmentDto = await this.storageService.getGroupItem(StorageKeys.groupAttachmentsLocal, `${storedQueueItem.storageId}`);
                    }
                    if (attachmentDto) {
                        const attachment: FileAttachment|undefined = AttachmentFactory.createFromStorageDto(attachmentDto) as FileAttachment|undefined;
                        if (attachment) {
                            if (attachment.identifier.businessIdentifier) {
                                this.storedAttachments.set(attachment.identifier.businessIdentifier, attachment);
                            }

                            filteredForUploadQueue.push(attachment);
                        }
                    }
                }
            } else {
                // Stored queue is not available, so we have to check every item
                const attachmentsOnServer: Array<ImageAttachmentStorageDto> = await this.storageService.getAllByGroupKey<ImageAttachmentStorageDto>(StorageKeys.groupAttachmentsServer);
                const attachmentsLocal: Array<ImageAttachmentStorageDto> = await this.storageService.getAllByGroupKey<ImageAttachmentStorageDto>(StorageKeys.groupAttachmentsLocal);
                merged = attachmentsOnServer.concat(attachmentsLocal);

                for (const attachmentDto of merged) {
                    const attachment: ImageAttachment = AttachmentFactory.createFromStorageDto(attachmentDto) as ImageAttachment;
                    if (attachment.identifier.businessIdentifier) {
                        this.storedAttachments.set(attachment.identifier.businessIdentifier, attachment);
                    }
                    if (attachment.state != AttachmentStates.metaUpdated && !attachment.hasError) {
                        filteredForUploadQueue.push(attachment);
                    }
                }
            }

            for (const attachment of filteredForUploadQueue) {
                if (attachment?.localStorageId) {
                    // Check if there is a newer version of this entity
                    const otherVersions: Array<ImageAttachmentStorageDto> = merged.filter((dto: ImageAttachmentStorageDto) => dto.businessId && dto.businessId == attachment.identifier.businessIdentifier);
                    ArrayHelper.sortByCreated(otherVersions);
                    if (otherVersions.length > 1 && otherVersions[0].technicalId && attachment.identifier.technicalIdentifier && otherVersions[0].technicalId != attachment.identifier.technicalIdentifier) {
                        await this.storageService.deleteGroupItem(StorageKeys.groupAttachmentsLocal, `${attachment.localStorageId}`);
                        await this.storageService.deleteGroupItem(StorageKeys.groupAttachmentsServer, Identifier.identifierToString(attachment.identifier));
                    } else {
                        await this.setQueueItem(attachment);
                    }
                }
            }

            if (this.uploadQueue.size > 0) {
                this.processQueue().then();
            } else {
                await this.saveQueueToStorage();
            }

            this.uploadQueueChanged.emit(this.uploadQueue.size);
            PerformanceMeasurement.stop("initializeStoredAndPendingAttachments", `count: ${merged.length}, pending: ${filteredForUploadQueue.length}, loadedFromStoredQueue: ${!!storedQueue}`);
        } finally {
            this.loadingStoredAttachments = false;
            this.uploadQueueLoaded.emit();
        }
    }

    private checkTimerElapsed(): void {
        this.processQueue().then();
    }

    public getQueuedItems(type?: AttachmentTypes): Array<FileAttachment> {
        if (type) {
            return Array.from(this.uploadQueue.values()).filter((attachment: FileAttachment) => attachment.type == type);
        } else {
            return Array.from(this.uploadQueue.values());
        }
    }

    public hasQueuedItems(documentBusinessIdentifier: DocumentBusinessIdentifier): number {
        return Array.from(this.uploadQueue.values())
            .filter((attachment: FileAttachment) => attachment.linkedDocuments
                .map((identifier: BusinessTechnicalIdentifierPair<DocumentBusinessIdentifier, DocumentTechnicalIdentifier>) => identifier.businessIdentifier)
                .includes(documentBusinessIdentifier)).length;
    }

    public async saveAttachment(attachment: FileAttachment, blob: Blob): Promise<LocalFileStorageIdentifier> {
        if (attachment instanceof ImageAttachment) {
            attachment.storedFileIsFromLocalDevice = true;
        }
        await this.addToCache(attachment);

        const storageId: LocalFileStorageIdentifier = await this.storeLocally(attachment, blob);
        await this.addToUploadQueue(attachment);
        return storageId;
    }

    public async updateMetaData(attachment: FileAttachment): Promise<void> {
        await this.updateCache(attachment);

        const queuedItem: FileAttachment|undefined = this.getQueuedItems().find((queued: FileAttachment) =>
            (attachment.localStorageId && (queued.localStorageId == attachment.localStorageId))
            || attachment.identifier.technicalIdentifier && (queued.identifier.technicalIdentifier == attachment.identifier.technicalIdentifier));

        if (queuedItem) {
            const oldState: AttachmentStates = queuedItem.state;
            queuedItem.fromStorageDto(attachment.toStorageDto());
            if (queuedItem.state == AttachmentStates.errorUpdatingMeta || queuedItem.state == AttachmentStates.noInternetUpdatingMeta) {
                queuedItem.state = AttachmentStates.updatingMeta;
            } else {
                queuedItem.state = oldState;
            }
        } else {
            this.lastMetaUpdateFakeStorageId--;
            attachment.localStorageId = this.lastMetaUpdateFakeStorageId;
            attachment.state = AttachmentStates.updatingMeta;
            await this.addToUploadQueue(attachment);
        }
    }

    private async setQueueItem(attachment: FileAttachment): Promise<void> {
        if (attachment.localStorageId) {
            this.uploadQueue.set(attachment.localStorageId, attachment);
            await this.saveQueueToStorage();
        }
    }

    private async deleteQueueItem(attachment: FileAttachment): Promise<void> {
        if (attachment.localStorageId) {
            this.uploadQueue.delete(attachment.localStorageId);
            await this.saveQueueToStorage();
        }
    }

    private async saveQueueToStorage(): Promise<void> {
        const storedQueue: Array<AttachmentQueueItemStorageDto> = [];
        for (const value of this.uploadQueue.values()) {
            const dto: AttachmentQueueItemStorageDto = new AttachmentQueueItemStorageDto({
                storageId: value.localStorageId,
                businessId: value.identifier.businessIdentifier,
                technicalId: value.identifier.technicalIdentifier
            });
            storedQueue.push(dto);
        }
        await this.storageService.set(StorageKeys.attachmentUploadQueue, storedQueue);
    }

    private async addToUploadQueue(attachment: FileAttachment): Promise<void> {
        await this.setQueueItem(attachment);

        this.processQueue().then();

        this.uploadQueueChanged.emit(this.uploadQueue.size);
    }

    private clearRescheduleTimer(): void {
        if (this.rescheduleQueueTimeoutHandle) {
            clearTimeout(this.rescheduleQueueTimeoutHandle);
            this.rescheduleQueueTimeoutHandle = undefined;
        }
    }

    private rescheduleQueue(delay: number): void {
        this.clearRescheduleTimer();
        this.rescheduleQueueTimeoutHandle = setTimeout(this.processQueue.bind(this), delay);
    }

    private async processQueue(): Promise<void> {
        this.clearRescheduleTimer();

        if (this.uploading || this.uploadQueue.size <= 0) {
            return;
        }
        if (this.lastUploadFailed && this.timeService.currentTimestamp < this.lastUpload + this.attachmentFailedUploadDelay) {
            // Retry a little later
            this.rescheduleQueue(this.attachmentFailedUploadDelay);
            return;
        }
        if (!this.sessionService.activeAccountId) {
            console.info("No account selected, rescheduling upload queue.");
            this.rescheduleQueue(this.attachmentFailedUploadDelay);
            return;
        }

        // Take all items that are missing meta updates
        let possibleAttachments: Array<FileAttachment> = Array.from(this.uploadQueue.values()).filter((value: FileAttachment) => value.state == AttachmentStates.uploaded);
        // Take all items that need to be uploaded the first time
        if (possibleAttachments.length <= 0) {
            possibleAttachments = Array.from(this.uploadQueue.values()).filter((value: FileAttachment) => value.state == AttachmentStates.storedLocally);
        }
        // Take all items that has been pending due to lost internet connection and are missing meta updates or are stuck in uploading state (this happens if the browser gets closed while the upload is running)
        if (possibleAttachments.length <= 0) {
            possibleAttachments = Array.from(this.uploadQueue.values()).filter((value: FileAttachment) => value.state == AttachmentStates.noInternetUpdatingMeta || AttachmentStates.updatingMeta);
        }
        // Take all items that has been pending due to lost internet connection and are not yet uploaded or are stuck in uploading state (this happens if the browser gets closed while the upload is running)
        if (possibleAttachments.length <= 0) {
            possibleAttachments = Array.from(this.uploadQueue.values()).filter((value: FileAttachment) => value.state == AttachmentStates.noInternetUploading || AttachmentStates.uploading);
        }

        // None found
        if (possibleAttachments.length <= 0) {
            return;
        }

        const oldestQueueItem: FileAttachment = possibleAttachments.reduce((previousValue: FileAttachment, currentValue: FileAttachment) => currentValue.createdClient < previousValue.createdClient ? currentValue : previousValue);

        this.uploading = true;
        let processed: boolean = false;
        try {
            switch (oldestQueueItem.state) {
                case AttachmentStates.uploaded:
                case AttachmentStates.updatingMeta:
                case AttachmentStates.noInternetUpdatingMeta:
                    await this.processMetaUpdate(oldestQueueItem);
                    processed = true;
                    break;
                case AttachmentStates.storedLocally:
                case AttachmentStates.uploading:
                case AttachmentStates.noInternetUploading:
                    await this.processUpload(oldestQueueItem);
                    processed = true;
                    break;
            }
        } catch (error) {
            this.lastUploadFailed = true;
        } finally {
            this.uploading = false;
        }

        if (processed) {
            this.rescheduleQueue(this.attachmentSuccessfulUploadDelay);
        }
    }

    private async processMetaUpdate(attachment: FileAttachment): Promise<void> {
        if (!attachment.identifier.technicalIdentifier) {
            return;
        }

        await this.setState(attachment, AttachmentStates.updatingMeta);

        let successful: boolean = false;
        try {
            switch (attachment.type) {
                case AttachmentTypes.image:
                    await this.photoApiService.updateImageMetadata(attachment as ImageAttachment);
                    successful = true;
                    break;
            }
        } catch (error) {
            if (WebHelper.isNoInternetError(error)) {
                attachment.state = AttachmentStates.noInternetUpdatingMeta;
            } else {
                attachment.state = AttachmentStates.errorUpdatingMeta;
            }
            this.lastUploadFailed = true;
        }

        if (successful) {
            attachment.state = AttachmentStates.metaUpdated;
            await this.deleteQueueItem(attachment);
        }
        await this.setState(attachment, attachment.state);
        this.uploadQueueChanged.emit(this.uploadQueue.size);
    }

    private async processUpload(attachment: FileAttachment): Promise<void> {
        if (!attachment.localStorageId) {
            return;
        }

        const storeItem: LocaleFileStorageItem|undefined = await this.localFileService.loadFromStorage(attachment.localStorageId);
        if (storeItem?.blob) {
            await this.uploadFile(attachment, storeItem.blob);
        } else {
            this.lastUploadFailed = true;
            await this.removeFailedAttachment(attachment);
        }
    }

    private addToCache(attachment: FileAttachment): Promise<void> {
        this.storedAttachments.set(attachment.identifier.businessIdentifier, attachment);
        return this.updateCache(attachment);
    }

    private updateAttachmentDataByStorageDtoKeepLocalUrls(attachment: FileAttachment, update: FileAttachmentStorageDto): void {
        const localUrl: string|undefined = attachment.localUrl;
        const localUrlPreview: string|undefined = attachment.localUrlPreview;
        attachment.fromStorageDto(update);
        attachment.localUrl = localUrl;
        attachment.localUrlPreview = localUrlPreview;
    }

    private updateAttachmentDataKeepLocalUrls(attachment: FileAttachment, update: FileAttachment): void {
        this.updateAttachmentDataByStorageDtoKeepLocalUrls(attachment, update.toStorageDto());
    }

    private async updateCache(attachment: FileAttachment): Promise<void> {
        const attachmentStorageDto: AttachmentStorageDto = attachment.toStorageDto();

        // Update item in queue if it exists
        if (attachment.localStorageId) {
            const queueItem: FileAttachment|undefined = this.uploadQueue.get(attachment.localStorageId);
            if (queueItem && queueItem != attachment) {
                this.updateAttachmentDataByStorageDtoKeepLocalUrls(queueItem, attachmentStorageDto);
            }
        }

        if (attachment.identifier.businessIdentifier) {
            // Delete the storage-only item
            if (attachment.localStorageId) {
                await this.storageService.deleteGroupItem(StorageKeys.groupAttachmentsLocal, `${attachment.localStorageId}`);
            }

            // It is already saved to the server
            await this.storageService.setGroupItem(StorageKeys.groupAttachmentsServer, Identifier.identifierToString(attachment.identifier), attachmentStorageDto);
        } else if (attachment.localStorageId) {
            // It is only stored locally
            await this.storageService.setGroupItem(StorageKeys.groupAttachmentsLocal, `${attachment.localStorageId}`, attachmentStorageDto);
        }

        await this.saveQueueToStorage();
    }

    private async storeLocally(attachment: FileAttachment, blob: Blob): Promise<LocalFileStorageIdentifier> {
        await this.setState(attachment, AttachmentStates.storingLocally);
        attachment.localStorageId = await this.localFileService.storeFile(blob);
        await this.setState(attachment, AttachmentStates.storedLocally);

        return attachment.localStorageId;
    }

    private async setState(attachment: FileAttachment, state: AttachmentStates): Promise<void> {
        attachment.state = state;
        await this.updateCache(attachment);
        this.attachmentStateChanged.emit(attachment);
    }

    private async uploadFile(attachment: FileAttachment, blob: Blob): Promise<void> {
        let successful: boolean = false;
        try {
            await this.setState(attachment, AttachmentStates.uploading);

            switch (attachment.type) {
                case AttachmentTypes.image:
                    await this.uploadPhoto(attachment as ImageAttachment, blob);
                    successful = !!attachment.identifier.businessIdentifier;
                    break;
            }
        } catch (error) {
            successful = false;
            if (WebHelper.isNoInternetError(error)) {
                attachment.state = AttachmentStates.noInternetUploading;
                return;
            }
            if (BackendHelper.isBackendError(error, BackendErrors.BE51PhotoAlreadyExists)) {
                const backendError: ErrorMessageDto|undefined = BackendHelper.getBackendError(error);
                if (backendError && backendError.details?.includes(":")) {
                    const existingImageId: AttachmentIdentifier = Identifier.stringToIdentifier(backendError.details);
                    if (!Identifier.isEmpty(existingImageId)) {
                        await this.replaceAttachmentWithExistingAttachment(attachment, existingImageId);
                        return;
                    }
                }
            }
            await this.handleUploadError(attachment, error);
        } finally {
            if (successful) {
                attachment.state = AttachmentStates.uploaded;
            }
            await this.setState(attachment, attachment.state);
            this.lastUploadFailed = !successful;
        }
    }

    public async getStoredAttachment(identifier: AttachmentIdentifier): Promise<FileAttachment> {
        let attachment: FileAttachment|undefined = identifier.businessIdentifier ? this.storedAttachments.get(identifier.businessIdentifier) : undefined;
        if (attachment) {
            return attachment;
        }
        const attachmentDto: ImageAttachmentStorageDto|undefined = await this.storageService.getGroupItem(StorageKeys.groupAttachmentsServer, Identifier.identifierToString(identifier));
        if (attachmentDto) {
            attachment = AttachmentFactory.createFromStorageDto(attachmentDto) as FileAttachment|undefined;
            if (attachment) {
                return attachment;
            }
        }
        return AttachmentFactory.createImageAttachment({ identifier: identifier });
    }

    private async replaceAttachmentWithExistingAttachment(attachment: FileAttachment, existingImageIdentifier: AttachmentIdentifier): Promise<void> {
        const newAttachment: FileAttachment = await this.getStoredAttachment(existingImageIdentifier);
        await this.handleUploadError(attachment, `Duplicate: ${Identifier.identifierToReadableString(existingImageIdentifier)}`);
        attachment.replacedByExistingAttachment = newAttachment;

        this.attachmentReplacedWithExistingAttachment.emit({ oldAttachment: attachment, newAttachment: newAttachment });
    }

    private async handleUploadError(attachment: FileAttachment, errorResponse: any): Promise<void> {
        attachment.state = AttachmentStates.errorUploading;
        attachment.uploadError = (errorResponse instanceof HttpErrorResponse && errorResponse.error?.code ? errorResponse.error : errorResponse) as unknown;

        await this.removeFailedAttachment(attachment);

        this.unrecoverableUploadError.emit(attachment);
    }

    private async removeFailedAttachment(attachment: FileAttachment): Promise<void> {
        if (attachment.localStorageId) {
            await this.storageService.deleteGroupItem(StorageKeys.groupAttachmentsLocal, `${attachment.localStorageId}`);
            await this.deleteQueueItem(attachment);
            await this.deleteLocalFiles(attachment);
            this.uploadQueueChanged.emit(this.uploadQueue.size);
        }
    }

    private async uploadPhoto(attachment: ImageAttachment, blob: Blob): Promise<void> {
        await this.photoApiService.uploadImage(attachment, blob, attachment.linkedDocuments.length > 0 ? attachment.linkedDocuments[0].businessIdentifier || undefined : undefined);
    }

    private async getStorageId(attachmentBusinessIdentifier: AttachmentBusinessIdentifier, preview: boolean): Promise<LocalFileStorageIdentifier|undefined> {
        if (!attachmentBusinessIdentifier) {
            return undefined;
        }
        const cacheKey: string = `${attachmentBusinessIdentifier}-${(preview ? "preview" : "full")}`;
        const cachedId: LocalFileStorageIdentifier|undefined = this.businessIdFileMappingCache.get(cacheKey);
        if (cachedId) {
            return cachedId;
        }
        return this.storageService.getGroupItem(preview ? StorageKeys.groupAttachmentPreviewStorageMapping : StorageKeys.groupAttachmentStorageMapping, attachmentBusinessIdentifier);
    }

    private async setStorageId(attachmentBusinessIdentifier: AttachmentBusinessIdentifier, preview: boolean, storageId: LocalFileStorageIdentifier): Promise<void> {
        const cacheKey: string = `${attachmentBusinessIdentifier}-${(preview ? "preview" : "full")}`;
        this.businessIdFileMappingCache.set(cacheKey, storageId);
        await this.storageService.setGroupItem(preview ? StorageKeys.groupAttachmentPreviewStorageMapping : StorageKeys.groupAttachmentStorageMapping, attachmentBusinessIdentifier, storageId);
    }

    public async loadAttachment(attachment: FileAttachment, previewImage: boolean, throwException: boolean = false): Promise<void> {
        let storageId: LocalFileStorageIdentifier|undefined = previewImage ? attachment.localStorageIdPreview : attachment.localStorageId;
        storageId = storageId ?? await this.getStorageId(attachment.identifier.businessIdentifier, previewImage);
        let hasLocalFileFallback: boolean = false;
        let loadedFromStorage: boolean = false;
        if (storageId) {
            const file: LocaleFileStorageItem|undefined = await this.localFileService.loadFromStorage(storageId);
            if (file?.blob) {
                loadedFromStorage = true;
                if (previewImage) {
                    attachment.localUrlPreview = URL.createObjectURL(file.blob);
                    return;
                } else {
                    attachment.localUrl = URL.createObjectURL(file.blob);
                    hasLocalFileFallback = attachment instanceof ImageAttachment && attachment.storedFileIsFromLocalDevice;
                    if (!hasLocalFileFallback) {
                        return;
                    }
                }
            }
        }

        try {
            if (attachment.identifier.technicalIdentifier) {
                const photoBlob: Blob = await this.photoApiService.getPhotoBlob(attachment.identifier.technicalIdentifier, previewImage);

                if (photoBlob) {
                    if (previewImage) {
                        attachment.localUrlPreview = URL.createObjectURL(photoBlob);
                        const newStorageId: LocalFileStorageIdentifier = await this.localFileService.storeFile(photoBlob);
                        await this.setStorageId(attachment.identifier.businessIdentifier, previewImage, newStorageId);
                    } else {
                        if (attachment.storedFileIsFromLocalDevice && attachment.localStorageId) {
                            await this.localFileService.deleteFile(attachment.localStorageId);
                            attachment.storedFileIsFromLocalDevice = false;
                        }

                        attachment.localUrl = URL.createObjectURL(photoBlob);
                        const newStorageId: LocalFileStorageIdentifier = await this.localFileService.storeFile(photoBlob);
                        await this.setStorageId(attachment.identifier.businessIdentifier, previewImage, newStorageId);
                    }
                    await this.updateCache(attachment);
                }
            }
        } catch (error) {
            if (throwException && !loadedFromStorage) {
                throw error;
            }
            if (hasLocalFileFallback) {
                console.warn("Unable to get image from server, using local device fallback.", error);
            } else {
                console.warn(error);
            }
        }
    }

    public async loadOriginalAttachmentBlob(technicalIdentifier: AttachmentTechnicalIdentifier, attachmentType: AttachmentTypes): Promise<SafeResult<Blob, AppException>> {
        try {
            switch (attachmentType) {
                case AttachmentTypes.image:
                    const photoBlob: Blob = await this.photoApiService.getPhotoBlob(technicalIdentifier, false);
                    if (!photoBlob) {
                        return errorResult(new AppException(FrontendErrors.FE38ImageNotFound, $localize`:@@exception.fe38ImageNotFound:The image with the identifier "${technicalIdentifier}:id:" cannot be found.`));
                    }
                    return successResult(photoBlob);
                default:
                    return errorResult(new AppException(FrontendErrors.FE46AttachmentTypeUnknown, $localize`:@@exception.fe46AttachmentTypeUnknown:The attachment type "${attachmentType}:type:" is not known. Please check for application updates.`));
            }
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE43UnableToLoadAttachments, $localize`:@@exception.fe43UnableToLoadAttachments:Unable to load the attachments.`, error as Error));
        }
    }

    public async deleteLocalFiles(attachment: FileAttachment): Promise<void> {
        if (attachment.localStorageId) {
            await this.localFileService.deleteFile(attachment.localStorageId);
        }
        if (attachment.localStorageIdPreview) {
            await this.localFileService.deleteFile(attachment.localStorageIdPreview);
        }
        attachment.localStorageId = undefined;
        attachment.localStorageIdPreview = undefined;
        await this.updateCache(attachment);
    }

    private async waitUntilStoredAttachmentsAreLoaded(): Promise<void> {
        while (this.loadingStoredAttachments) {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            await AsyncHelper.sleep(100);
        }
    }

    public async getDocumentAttachments(documentIdentifier: DocumentIdentifier, attachmentType: AttachmentTypes, loadStrategy: LoadStrategies): Promise<SafeResult<Array<FileAttachment>, AppException>> {
        await this.waitUntilStoredAttachmentsAreLoaded();

        const result: Array<FileAttachment> = [];

        // Pending for upload
        for (const queuedItem of this.uploadQueue.values()) {
            if (!queuedItem.identifier.businessIdentifier && queuedItem.linkedDocuments.some((identifier: DocumentIdentifier) => identifier.businessIdentifier == documentIdentifier.businessIdentifier || identifier.technicalIdentifier == documentIdentifier.technicalIdentifier)) {
                result.push(queuedItem);
            }
        }

        const locallyStoredAttachments: Array<FileAttachment> = Array.from(this.storedAttachments.values()).filter((value: FileAttachment) => value.linkedDocuments.some((identifier: DocumentIdentifier) => identifier.technicalIdentifier == documentIdentifier.technicalIdentifier));
        if (loadStrategy == LoadStrategies.cachedOnly) {
            result.push(...locallyStoredAttachments);
            return successResult(result);
        }
        if (locallyStoredAttachments.length > 0 && loadStrategy == LoadStrategies.preferCached) {
            result.push(...locallyStoredAttachments);
            return successResult(result);
        }

        try {
            if (!attachmentType || attachmentType == AttachmentTypes.image || attachmentType == AttachmentTypes.all) {
                const photoDtos: Array<PhotoDto> = await this.photoApiService.getDocumentPhotos(documentIdentifier.technicalIdentifier!);
                for (const photoDto of photoDtos) {
                    const localImage: FileAttachment|undefined = this.storedAttachments.get(photoDto.businessId as AttachmentBusinessIdentifier);
                    if (localImage) {
                        if (localImage.created != photoDto.created || localImage.updated != photoDto.updated || !ArrayHelper.arraysEqual(localImage.linkedDocuments.map((id: DocumentIdentifier) => id.technicalIdentifier).sort(ArrayHelper.compareNumeric), (photoDto.documentIds ?? []).sort(ArrayHelper.compareNumeric))) {
                            const imageAttachment: ImageAttachment = Api1Converter.dtoToImageAttachment(photoDto);
                            this.updateAttachmentDataKeepLocalUrls(localImage, imageAttachment);
                            await this.addToCache(imageAttachment);
                        }
                        result.push(localImage);
                    } else {
                        const imageAttachment: ImageAttachment = Api1Converter.dtoToImageAttachment(photoDto);
                        result.push(imageAttachment);
                        await this.addToCache(imageAttachment);
                    }
                }
                return successResult(result);
            }
            return successResult([]);
        } catch (error) {
            const isNoInternetError: boolean = WebHelper.isNoInternetError(error);
            if (loadStrategy == LoadStrategies.preferCached && isNoInternetError) {
                return successResult(locallyStoredAttachments);
            }
            return errorResult(new AppException(FrontendErrors.FE43UnableToLoadAttachments, $localize`:@@exception.fe43UnableToLoadAttachments:Unable to load the attachments.`, error as Error));
        }
    }

    // eslint-disable-next-line complexity
    public async clearCache(infoMessageUpdate: (message: string) => void): Promise<void> { // NOSONAR typescript:S3776 Cognitive Complexity (Tom Riedl)
        infoMessageUpdate("Fetching all server-saved attachments...");
        const attachmentsServer: Array<ImageAttachmentStorageDto> = await this.storageService.getAllByGroupKey<ImageAttachmentStorageDto>(StorageKeys.groupAttachmentsServer);
        infoMessageUpdate(`Server attachments: ${attachmentsServer.length}. Deleting...`);
        let counter: number = 0;
        for (const imageAttachmentStorageDto of attachmentsServer) {
            counter++;
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (counter % 20 == 0) {
                infoMessageUpdate(`Processing server attachment ${counter}/${attachmentsServer.length}...`);
            }
            const attachment: Attachment = AttachmentFactory.createFromStorageDto(imageAttachmentStorageDto);
            if (attachment.type == AttachmentTypes.image) {
                const imageAttachment: ImageAttachment = attachment as ImageAttachment;

                if (imageAttachment.localStorageId) {
                    await this.localFileService.deleteFile(imageAttachment.localStorageId);
                } else {
                    const storageId: number|undefined = await this.storageService.getGroupItem(StorageKeys.groupAttachmentStorageMapping, imageAttachmentStorageDto.businessId as AttachmentBusinessIdentifier);
                    if (storageId) {
                        await this.localFileService.deleteFile(storageId as LocalFileStorageIdentifier);
                    }
                }

                if (imageAttachment.localStorageIdPreview) {
                    await this.localFileService.deleteFile(imageAttachment.localStorageIdPreview);
                } else {
                    const storageId: number|undefined = await this.storageService.getGroupItem(StorageKeys.groupAttachmentPreviewStorageMapping, imageAttachmentStorageDto.businessId as AttachmentBusinessIdentifier);
                    if (storageId) {
                        await this.localFileService.deleteFile(storageId as LocalFileStorageIdentifier);
                    }
                }

                await this.storageService.deleteGroupItem(StorageKeys.groupAttachmentStorageMapping, imageAttachmentStorageDto.businessId as AttachmentBusinessIdentifier);
                await this.storageService.deleteGroupItem(StorageKeys.groupAttachmentPreviewStorageMapping, imageAttachmentStorageDto.businessId as AttachmentBusinessIdentifier);
                await this.storageService.deleteGroupItem(StorageKeys.groupAttachmentsServer, Identifier.identifierToString(Identifier.fromDto(imageAttachmentStorageDto)));
            }
        }

        infoMessageUpdate("Fetching all local attachments...");
        const attachmentsLocal: Array<ImageAttachmentStorageDto> = await this.storageService.getAllByGroupKey<ImageAttachmentStorageDto>(StorageKeys.groupAttachmentsLocal);
        infoMessageUpdate(`Local attachments: ${attachmentsLocal.length}. Deleting...`);
        counter = 0;
        for (const imageAttachmentStorageDto of attachmentsLocal) {
            counter++;
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (counter % 20 == 0) {
                infoMessageUpdate(`Processing local attachment ${counter}/${attachmentsLocal.length}...`);
            }

            if (imageAttachmentStorageDto.state == AttachmentStates.metaUpdated && imageAttachmentStorageDto.localStorageId) {
                await this.storageService.deleteGroupItem(StorageKeys.groupAttachmentsLocal, `${imageAttachmentStorageDto.localStorageId}`);

                if (imageAttachmentStorageDto.localStorageId) {
                    await this.localFileService.deleteFile(imageAttachmentStorageDto.localStorageId as LocalFileStorageIdentifier);
                }
                if (imageAttachmentStorageDto.localStorageIdPreview) {
                    await this.localFileService.deleteFile(imageAttachmentStorageDto.localStorageIdPreview as LocalFileStorageIdentifier);
                }
            }
        }

        infoMessageUpdate("Fetching preview mappings...");
        let mappings: Map<string, LocalFileStorageIdentifier> = await this.storageService.getAllByGroupKeyWithKeys<LocalFileStorageIdentifier>(StorageKeys.groupAttachmentPreviewStorageMapping);
        infoMessageUpdate(`Preview mappings: ${mappings.size}. Deleting...`);
        counter = 0;
        for (const mappingKey of mappings.keys()) {
            counter++;
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (counter % 20 == 0) {
                infoMessageUpdate(`Processing preview mapping ${counter}/${mappings.size}...`);
            }
            const localStorageId: LocalFileStorageIdentifier|undefined = mappings.get(mappingKey);
            const exists: boolean = !!localStorageId && !!(await this.localFileService.loadFromStorage(localStorageId));
            if (!exists) {
                await this.storageService.deleteByRawKey(mappingKey);
            }
        }

        infoMessageUpdate("Fetching image mappings...");
        mappings = await this.storageService.getAllByGroupKeyWithKeys<LocalFileStorageIdentifier>(StorageKeys.groupAttachmentStorageMapping);
        infoMessageUpdate(`Image mappings: ${mappings.size}. Deleting...`);
        counter = 0;
        for (const mappingKey of mappings.keys()) {
            counter++;
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (counter % 20 == 0) {
                infoMessageUpdate(`Processing image mapping ${counter}/${mappings.size}...`);
            }
            const localStorageId: LocalFileStorageIdentifier|undefined = mappings.get(mappingKey);
            const exists: boolean = !!localStorageId && !!(await this.localFileService.loadFromStorage(localStorageId));
            if (!exists) {
                await this.storageService.deleteByRawKey(mappingKey);
            }
        }

        infoMessageUpdate("All attachments cleared.");
    }

    public async deleteImage(attachment: ImageAttachment): Promise<boolean> {
        const oldDeletedValue: boolean = attachment.deleted;
        try {
            attachment.deleted = true;
            const deleted: boolean = await this.photoApiService.deletePhoto(attachment.identifier.businessIdentifier);
            if (deleted) {
                await this.updateCache(attachment);
                this.attachmentDeleted.emit(attachment);
            }
        } catch (error) {
            attachment.deleted = oldDeletedValue;
            throw error;
        }
        return true;
    }
}
