import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { EntityBusinessIdentifier } from "../../identifiers/entity-business-identifier";
import { EntityTechnicalIdentifier } from "../../identifiers/entity-technical-identifier";

/**
 * Storage DTO for {@link EntityLink}.
 */
export class EntityLinkStorageDto {
    constructor(init?: Partial<EntityLinkStorageDto>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public linkDescription?: string;
    public identifierPairPropertyName?: string;

    public entityType?: string;
    public entityBusinessId?: EntityBusinessIdentifier;
    public entityTechnicalId?: EntityTechnicalIdentifier;
}
