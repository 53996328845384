import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

import { ArrayHelper } from "../../../../base/helpers/array-helper";
import { EventHelper } from "../../../../base/helpers/event-helper";
import { AppIcons } from "../../../services/icons/app-icons";
import { FormValidationErrorMessages } from "./form-validation-error-messages";

/**
 * Hint message if a form input failed validation.
 */
@Component({
    selector: "app-form-validation-error",
    templateUrl: "./form-validation-error.component.html",
    styleUrls: ["./form-validation-error.component.scss"]
})
export class FormValidationErrorComponent implements OnInit, OnDestroy {
    @Input()
    public formGroup?: FormGroup;

    @Input()
    public validationMessages?: FormValidationErrorMessages;

    @Input()
    public itemName?: string;

    public errorMessages: Array<string> = [];

    private statusChangeSubscription?: Subscription;

    public appIcons: typeof AppIcons = AppIcons;

    public ngOnInit(): void {
        if (this.formGroup && this.itemName && this.validationMessages) {
            this.statusChangeSubscription = EventHelper.subscribe(this.formGroup.statusChanges, this.statusChange, this);
            this.statusChange();
        }
    }

    public ngOnDestroy(): void {
        this.statusChangeSubscription = EventHelper.unsubscribe(this.statusChangeSubscription);
    }

    private statusChange(): void {
        const newErrors: Array<string> = [];
        const control: AbstractControl|null = this.formGroup!.get(this.itemName!);
        if (control) {
            const errorMessages: Map<string, string>|undefined = this.validationMessages!.controlMessages.get(this.itemName!);
            if (errorMessages && (control.dirty || control.touched)) {
                const errorTypes: Array<string> = control.errors ? Object.getOwnPropertyNames(control.errors) : [];
                for (const errorType of errorTypes) {
                    const message: string|undefined = errorMessages.get(errorType);
                    if (message) {
                        newErrors.push(message);
                    } else {
                        console.warn(`No error message for validation type ${errorType} and control ${this.itemName}.`);
                    }
                }
            }
        }
        if (!ArrayHelper.arraysEqual(newErrors, this.errorMessages)) {
            this.errorMessages = newErrors;
        }
    }
}
