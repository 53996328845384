/**
 * Helper class for images.
 */
import { TypeScriptHelper } from "./type-script-helper";

/**
 *
 */
export class ImageHelper {

    /**
     * Converts a PNG Base64 to as Jpeg Base64.
     *
     * @param pngData - The Base64 data of the image.
     * @param quality - Value between 0.0 and 1.0.
     */
    public static async base64PngToJpeg(pngData: string, quality: number): Promise<string> {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const context: CanvasRenderingContext2D = canvas.getContext("2d")!;
        const pngImage: HTMLImageElement = await this.getImageElement(pngData);

        canvas.width = pngImage.width;
        canvas.height = pngImage.height;

        context.drawImage(pngImage, 0, 0);

        return canvas.toDataURL("image/jpeg", quality);
    }

    /**
     * Converts a canvas to as Jpeg Base64.
     *
     * @param canvas - The {@link HTMLCanvasElement}.
     * @param quality - Value between 0.0 and 1.0.
     */
    public static async canvasToJpeg(canvas: HTMLCanvasElement, quality: number): Promise<string> {
        return canvas.toDataURL("image/jpeg", quality);
    }

    /**
     * Converts an image blob to a Jpeg Blob.
     *
     * @param blob - The source blob.
     * @param quality - Value between 0.0 and 1.0.
     */
    public static async blobToJpegBlob(blob: Blob, quality: number): Promise<Blob> {
        const canvas: HTMLCanvasElement = await ImageHelper.blobToCanvas(blob);
        const jpeg: string = await ImageHelper.canvasToJpeg(canvas, quality);
        return TypeScriptHelper.base64StringToBlob(jpeg);
    }

    public static getImageElement(url: string): Promise<HTMLImageElement> {
        return new Promise((resolve: (value: (PromiseLike<HTMLImageElement>|HTMLImageElement)) => void) => {
            const backgroundImage: HTMLImageElement = new Image();
            backgroundImage.crossOrigin = "anonymous";
            backgroundImage.src = url;
            backgroundImage.onload = (): void => {
                resolve(backgroundImage);
            };
        });
    }

    public static canvasToBlob(canvas: HTMLCanvasElement, mimeType: string, quality: number): Promise<Blob> {
        return new Promise((resolve: (value: (PromiseLike<Blob>|Blob)) => void, reject: (reason?: any) => void) => {
            const ready: (blob: Blob|null) => void = (blob: Blob|null) => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject();
                }
            };
            canvas.toBlob(ready, mimeType, quality);
        });
    }

    public static blobToCanvas(blob: Blob): Promise<HTMLCanvasElement> {
        return new Promise((resolve: (value: (PromiseLike<HTMLCanvasElement>|HTMLCanvasElement)) => void) => {
            const canvas: HTMLCanvasElement = document.createElement("canvas");
            const context: CanvasRenderingContext2D = canvas.getContext("2d")!;
            const image: HTMLImageElement = new Image();
            image.src = URL.createObjectURL(blob);
            image.onload = (): void => {
                canvas.width = image.width;
                canvas.height = image.height;
                context.drawImage(image, 0, 0);
                resolve(canvas);
            };
        });
    }
}
