<ion-header *ngIf="options" class="modalHeader ion-no-border">
    <ion-toolbar>
        <ion-title>{{options.dialogTitle}}</ion-title>
        <ion-buttons slot="end">
            <business-identifier-badge *ngFor="let entity of entities" [canOpen]="false" [entity]="entity" [hideIfEmpty]="true"></business-identifier-badge>

            <button (click)="showPermissions($event)" *ngIf="options.readonlyDueToPermissions" mat-flat-button>
                <fa-icon [icon]="appIcons.noPermission"></fa-icon>
            </button>

            <ion-button (click)="cancel()" *ngIf="options.allowClose">
                <ion-label color="medium">
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="loading" class="modalContent ion-padding loading">
    <ion-list>
        <ion-item>
            <ion-label>
                <ion-skeleton-text animated="true" style="width: 50%"></ion-skeleton-text>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <ion-skeleton-text animated="true" style="width: 100%"></ion-skeleton-text>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <ion-skeleton-text animated="true" style="width: 50%"></ion-skeleton-text>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <ion-skeleton-text animated="true" style="width: 50%"></ion-skeleton-text>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>

<ion-content *ngIf="!loading && entities && entities.length" class="modalContent ion-padding">
    <div *ngIf="options.readonlyDueToPermissions" class="permissionMissing">
        <fa-icon [icon]="appIcons.noPermission"></fa-icon>
        <ng-container>The required permission to edit this entity is missing.</ng-container>
        <button (click)="showPermissions($event)" mat-flat-button>
            <fa-icon [icon]="appIcons.informationCircle"></fa-icon>
        </button>
    </div>

    <ng-container *ngFor="let entity of entities">
        <business-project-form *ngIf="entity.entityType == entityTypes.project" [(isValid)]="isValid" [project]="entityHelper.asProject(entity)" [readonly]="options.readonly"></business-project-form>
        <business-process-form *ngIf="entity.entityType == entityTypes.process" [(isValid)]="isValid" [process]="entityHelper.asProcess(entity)" [readonly]="options.readonly"></business-process-form>
        <business-person-form *ngIf="entity.entityType == entityTypes.person" [(isValid)]="isValid" [person]="entityHelper.asPerson(entity)" [readonly]="options.readonly"></business-person-form>
        <business-address-form *ngIf="entity.entityType == entityTypes.address" [(isValid)]="isValid" [address]="entityHelper.asAddress(entity)" [autocomplete]="entityHelper.asAddressAutocomplete(options.autocomplete)" [mandatoryProperties]="entityHelper.asAddressProperties(options.mandatoryProperties)" [propertiesExclude]="entityHelper.asAddressProperties(options.propertiesFilterExclude)" [propertiesInclude]="entityHelper.asAddressProperties(options.propertiesFilterInclude)" [readonly]="options.readonly"></business-address-form>
        <business-building-complex-form *ngIf="entity.entityType == entityTypes.buildingComplex" [(buildings)]="additionalBuildings" [(isValid)]="isValid" [buildingComplex]="entityHelper.asBuildingComplex(entity)" [readonlyPermissions]="options.readonlyDueToPermissions" [readonly]="options.readonly"></business-building-complex-form>
        <business-building-form *ngIf="entity.entityType == entityTypes.building" [(isValid)]="isValid" [building]="entityHelper.asBuilding(entity)" [readonly]="options.readonly"></business-building-form>
        <business-company-form *ngIf="entity.entityType == entityTypes.company" [(isValid)]="isValid" [company]="entityHelper.asCompany(entity)" [readonly]="options.readonly"></business-company-form>
        <business-sample-form *ngIf="entity.entityType == entityTypes.sample" [(isValid)]="isValid" [readonly]="options.readonly" [sample]="entityHelper.asSample(entity)"></business-sample-form>
    </ng-container>
</ion-content>

<ion-footer class="modalFooter ion-no-border">
    <ion-toolbar>
        <ion-buttons *ngIf="!options.readonly" slot="end">
            <ion-button (click)="cancel()" *ngIf="options.allowClose" color="dark" fill="solid">
                <fa-icon [icon]="appIcons.genericCancel"></fa-icon>&nbsp;<ng-container i18n="@@dialog.genericCancelButton">Cancel</ng-container>
            </ion-button>
            <ion-button (click)="save()" [disabled]="!isValid" color="success" fill="solid">
                <fa-icon [icon]="appIcons.genericSave"></fa-icon>&nbsp;<ng-container i18n="@@dialog.genericSaveButton">Save</ng-container>
            </ion-button>
        </ion-buttons>
        <ion-buttons *ngIf="options.readonly" slot="end">
            <ion-button (click)="cancel()" color="dark" fill="solid">
                <fa-icon [icon]="appIcons.genericClose"></fa-icon>&nbsp;<ng-container i18n="@@dialog.genericCloseButton">Close</ng-container>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
