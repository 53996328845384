import { ArrayHelper } from "../../base/helpers/array-helper";
import { StringHelper } from "../../base/helpers/string-helper";
import { Address } from "../entities/core-data/address";
import { Building } from "../entities/core-data/buildings/building";
import { BuildingComplex } from "../entities/core-data/buildings/building-complex";
import { Company } from "../entities/core-data/company";
import { GenericCoreDataEntity } from "../entities/core-data/generic-core-data-entity";
import { Person } from "../entities/core-data/person";
import { EntityTypes } from "../entities/entity-types";
import { AppException } from "../entities/exceptions/app-exception";
import { Process } from "../entities/projects-processes/process";
import { Project } from "../entities/projects-processes/project";
import { Sample } from "../entities/samplings/sample";
import { FrontendErrors } from "../global/frontend-errors";
import { BusinessTechnicalIdentifierPair } from "../identifiers/business-technical-identifier-pair";
import { AddressFormats } from "./address-formats";

/**
 * Helper to assist working with business entities.
 */
export class EntityHelper {
    public static typeAssertIdentifierPropertyName<TOwner>(owner: TOwner, identifierPairPropertyName: string|undefined): void {
        if (!this.isIdentifierPairProperty(owner, identifierPairPropertyName)) {
            throw new AppException(FrontendErrors.FE33EntityLinkPropertyTargetIsNotAnIdentifierPair, $localize`:@@exception.fe33EntityLinkPropertyTargetIsNotAnIdentifierPair:For the entity link targets only BusinessTechnicalIdentifierPair types can be used. Specified property name is "${identifierPairPropertyName}:propertyName:".`);
        }
    }

    public static isIdentifierPairProperty<TOwner>(owner: TOwner, identifierPairPropertyName: string|undefined): boolean {
        return !!identifierPairPropertyName && ((owner[identifierPairPropertyName as keyof TOwner] as unknown as BusinessTechnicalIdentifierPair<any, any>) !== undefined);
    }

    public static getLatestByCreatedAndUpdatedTime<TEntity extends {
        created?: string;
        updated?: string;
    }>(entities: Array<TEntity>): TEntity|undefined {
        let latestEntity: TEntity|undefined = undefined;
        let latestEntityDate: string|undefined;
        for (const entity of entities) {
            const entityDate: string|undefined = entity.created && entity.updated
                ? entity.created > entity.updated
                    ? entity.created
                    : entity.updated
                : entity.created
                    ? entity.created
                    : entity.updated;

            if (!latestEntityDate || (entityDate && (latestEntity && latestEntity!.created && entityDate > latestEntity!.created)) || (entityDate && (latestEntity && latestEntity!.updated && entityDate > latestEntity!.updated))) {
                latestEntity = entity;
                latestEntityDate = (latestEntity.created || "0") > (latestEntity.updated || "0") ? latestEntity.created : latestEntity.updated;
            }
        }
        return latestEntity;
    }

    public static entityTypeToUrlComponent(entityType: EntityTypes): string {
        return StringHelper.toHyphenCase(`${entityType}`) ?? "";
    }

    public static urlComponentToEntityTypeString(urlComponent: string): string {
        return StringHelper.toCamelCase(urlComponent) as EntityTypes;
    }

    public static asProcess(entity: GenericCoreDataEntity): Process {
        return entity as Process;
    }

    public static asProject(entity: GenericCoreDataEntity): Project {
        return entity as Project;
    }

    public static asPerson(entity: GenericCoreDataEntity): Person {
        return entity as Person;
    }

    public static asAddress(entity: GenericCoreDataEntity): Address {
        return entity as Address;
    }

    public static asBuilding(entity: GenericCoreDataEntity): Building {
        return entity as Building;
    }

    public static asBuildingComplex(entity: GenericCoreDataEntity): BuildingComplex {
        return entity as BuildingComplex;
    }

    public static asCompany(entity: GenericCoreDataEntity): Company {
        return entity as Company;
    }

    public static asSample(entity: GenericCoreDataEntity): Sample {
        return entity as Sample;
    }

    public static multipleAddressesToString(addresses: Array<Address>, includeCity?: boolean, includeCountry?: boolean, addressFormat: AddressFormats = AddressFormats.streetNumberStreet): string { // NOSONAR
        const separateAddressStrings: Array<string> = [];

        const groupedByCountries: Map<string|undefined, Array<Address>> = ArrayHelper.groupBy<Address, string|undefined>(addresses, "country");
        for (const countryAddresses of groupedByCountries.values()) {
            const groupedByCities: Map<string|undefined, Array<Address>> = ArrayHelper.groupBy<Address, string|undefined>(countryAddresses, "city");
            for (const cityAddresses of groupedByCities.values()) {
                const groupedByZipCodes: Map<string|undefined, Array<Address>> = ArrayHelper.groupBy<Address, string|undefined>(cityAddresses, "zipCode");
                for (const zipCodeAddresses of groupedByZipCodes.values()) {
                    const groupedByStreets: Map<string|undefined, Array<Address>> = ArrayHelper.groupBy<Address, string|undefined>(zipCodeAddresses, "street");
                    for (const streetName of groupedByStreets.keys()) {
                        const groupedAddresses: Array<Address> = groupedByStreets.get(streetName) ?? [];
                        if (groupedAddresses.length) {
                            const streetNumbers: Array<string> = groupedAddresses
                                .filter((value: Address) => value.streetNumber)
                                .map((value: Address) => value.streetNumber).sort(this.streetNumberSorter) as Array<string>;
                            const firstAddress: Address = groupedAddresses[0];
                            let result: string;
                            if (streetName) {
                                result = addressFormat == AddressFormats.streetNumberStreet ? `${streetNumbers.join("+")}, ${streetName}` : `${streetName} ${streetNumbers.join("+")}`;
                            } else {
                                result = streetNumbers.join("+");
                            }

                            // Add city
                            if (includeCity && (firstAddress.zipCode || firstAddress.city)) {
                                result += `${result.length > 0 ? ", " : ""}${ArrayHelper.removeEmpty([firstAddress.zipCode, firstAddress.city]).join(" ")}`;
                            }
                            // Add country
                            result += includeCountry && firstAddress.country ? `${result.length > 0 ? ", " : ""}${firstAddress.country}` : "";
                            separateAddressStrings.push(result);
                        }
                    }
                }
            }
        }

        return separateAddressStrings.join("; ");
    }

    public static streetNumberSorter(numberA: string|undefined, numberB: string|undefined): number {
        if (numberA == numberB) {
            return 0;
        }
        if (!numberA) {
            return 1;
        }
        if (!numberB) {
            return -1;
        }
        const numberPartA: number = parseInt(numberA, 10);
        const numberPartB: number = parseInt(numberB, 10);
        if (isNaN(numberPartA) || isNaN(numberPartB)) {
            return numberA.localeCompare(numberB);
        }
        if (numberPartA > numberPartB) {
            return 1;
        }
        if (numberPartA < numberPartB) {
            return -1;
        }
        return numberA.localeCompare(numberB);
    }

    public static addressTitle(building: Building|undefined, address: Address|undefined): string {
        return address?.street ? EntityHelper.multipleAddressesToString([address]) : EntityHelper.multipleAddressesToString(building?.addresses ?? []) || address?.displayTitle || building?.displayTitle || "";
    }

    public static asAddressProperties(items: Array<string>): Array<keyof Address> {
        return items as Array<keyof Address>;
    }

    public static asAddressAutocomplete(autocomplete: Map<string, Array<string>>|undefined): Map<keyof Address, Array<string>>|undefined {
        return autocomplete as Map<keyof Address, Array<string>>|undefined;
    }

    public static asBuildingComplexProperties(items: Array<string>): Array<keyof BuildingComplex> {
        return items as Array<keyof BuildingComplex>;
    }
}
