<form *ngIf="company" [formGroup]="formData" class="coreDataGroup" enter-to-tab>
    <mat-form-field [appearance]="formHelper.appearance(readonly || !externalReferenceEditable)" class="externalReferenceField">
        <mat-label>
            <fa-icon [icon]="appIcons.externalReference"></fa-icon>&nbsp;<ng-container i18n="@@company.externalReference">Reference (Company ID, Customer number)</ng-container>
        </mat-label>
        <input #nextTab [formControl]="externalReferenceControl" [maxlength]="formSettings.maxLengthReference" [readonly]="readonly || !externalReferenceEditable" autocapitalize="on" autocomplete="new-id" matInput type="text">
        <button (click)="externalReferenceEditable = true" *ngIf="!readonly && !externalReferenceEditable" mat-button matSuffix>
            <fa-icon [icon]="appIcons.genericLocked"></fa-icon>
            <label i18n="@@form.unlockField">Unlock</label>
        </button>
        <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(externalReferenceControl, formSettings.maxLengthReference)}}</mat-hint>
        <mat-error *ngIf="externalReferenceControl.invalid">{{getErrorMessage(externalReferenceControl)}}</mat-error>
    </mat-form-field>

    <mat-form-field [appearance]="formHelper.appearance(readonly)" class="nameField">
        <mat-label>
            <fa-icon [icon]="appIcons.companyName"></fa-icon>&nbsp;<ng-container i18n="@@company.name">Company Name</ng-container>
        </mat-label>
        <input #nextTab [formControl]="nameControl" [maxlength]="formSettings.maxLengthName" [readonly]="readonly" autocapitalize="on" autocomplete="new-organization" matInput type="text">
        <mat-hint *ngIf="!readonly" align="end">{{formHelper.maxLengthText(nameControl, formSettings.maxLengthName)}}</mat-hint>
        <mat-error *ngIf="nameControl.invalid">{{getErrorMessage(nameControl)}}</mat-error>
    </mat-form-field>

    <business-address-form #nextTab (isValidChange)="updateValidState()" *ngIf="company.address" [address]="company.address" [readonly]="readonly"></business-address-form>
    <business-email-form #nextTab (emailsChange)="emailsChanged($event)" (isValidChange)="updateValidStateEmail($event)" [emails]="company.email" [readonly]="readonly"></business-email-form>

    <div *ngIf="allowEntityLinking" class="coreDataGroup">
        <ion-list-header>
            <ion-label i18n="@@building.linkedPersons">Personen</ion-label>
            <ion-button (click)="linkPerson()" [disabled]="readonly" fill="outline">
                <fa-icon [icon]="appIcons.linkPerson"></fa-icon>&nbsp;<ng-container i18n="@@company.linkPersonButton">Link person</ng-container>
            </ion-button>
        </ion-list-header>

        <business-entity-links (entityLinksChange)="personLinksChanged($event)" [entityLinks]="company.personLinks" [readonly]="readonly"></business-entity-links>
    </div>
</form>
