<mat-selection-list *ngIf="options" class="mat-menu-panel">
    <div *ngFor="let item of options.items; let index = index" [class.faded]="item.isFaded">
        <mat-divider *ngIf="item.isDivider"></mat-divider>
        <mat-list-item *ngIf="item.isLabel" class="label">
            <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
            {{item.title}}
        </mat-list-item>
        <mat-list-option (selectedChange)="checkboxChanged(item, $event)" *ngIf="item.hasCheckbox && !item.isDivider && !item.isLabel" [selected]="item.isChecked" color="primary">
            {{item.title}}
        </mat-list-option>
        <button (click)="regularItemClick(item)" *ngIf="!item.hasCheckbox && !item.isDivider && !item.isLabel" class="mat-list-option" mat-list-item>
            <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
            <label>{{item.title}}</label>
        </button>
    </div>
</mat-selection-list>
