import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { BaseComponent } from "../../../../base/components/base-component";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { ExtendedValidators } from "../../../../base/validators/extended-validators";
import { AppIcons } from "../../../services/icons/app-icons";

/**
 * A form to view or edit an email.
 */
@Component({
    selector: "business-email-form",
    templateUrl: "./email-form.component.html",
    styleUrls: ["./email-form.component.scss"]
})
export class EmailFormComponent extends BaseComponent {
    constructor(
        private readonly formBuilder: FormBuilder
    ) {
        super();
    }

    private readonly emailSeparator: RegExp = /[;,\r\n\t]/;

    public appIcons: typeof AppIcons = AppIcons;

    public formHelper: typeof FormHelper = FormHelper;

    public emailControl: FormControl = new FormControl("", [Validators.required]);

    public emailFormArray: Array<FormControl<string|null>> = [];

    public emailString: string|undefined|null;

    @Input()
    public isValid: boolean = false;

    private isReadonly: boolean = false;

    @Output()
    public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public emailsChange: EventEmitter<string> = new EventEmitter<string>();

    public formGroup: FormGroup = this.formBuilder.group([
        this.emailControl
    ]);

    public get readonly(): boolean {
        return this.isReadonly;
    }

    @Input()
    public set readonly(value: boolean) {
        this.isReadonly = value;
        this.checkEmptyInput();
    }

    public get emails(): string|undefined {
        return this.emailString ?? undefined;
    }

    @Input()
    public set emails(value: string|undefined|null) {
        if (value != this.emailString) {
            this.emailString = value;
            this.emailsUpdated();
        }
    }

    protected componentInit(): void {
        this.checkEmptyInput();
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public inputValueChanged(index: number): void {
        this.splitInput(index);
        this.checkEmptyInput();
        this.formChanged();
    }

    private inputIsEmpty(index: number): boolean {
        if (index >= this.emailFormArray.length) {
            return true;
        }
        return !this.emailFormArray[index].value;
    }

    private checkEmptyInput(): void {
        const mustAddInput: boolean = this.emailFormArray.length <= 0 || !this.inputIsEmpty(this.emailFormArray.length - 1);
        if (mustAddInput && !this.isReadonly) {
            const input: FormControl<string|null> = this.createInput("");
            this.emailFormArray.push(input);
        }

        if (this.emailFormArray.length > 1) {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            let mustDeleteInput: boolean = this.inputIsEmpty(this.emailFormArray.length - 1) && this.inputIsEmpty(this.emailFormArray.length - 2);
            if (mustDeleteInput) {
                this.emailFormArray.splice(this.emailFormArray.length - 1, 1);
            }

            if (this.isReadonly && this.emailFormArray.length > 1) {
                mustDeleteInput = this.inputIsEmpty(this.emailFormArray.length - 1);
                if (mustDeleteInput) {
                    this.emailFormArray.splice(this.emailFormArray.length - 1, 1);
                }
            }
        }
    }

    private splitInput(index: number): void {
        const sourceInput: FormControl<string|null> = this.emailFormArray[index];
        const sourceText: string = (sourceInput.value ?? "").trim();

        if (!this.emailSeparator.test(sourceText)) {
            return;
        }

        const lastCharacterIsSeparator: boolean = sourceText.length > 0 && this.emailSeparator.test(sourceText[sourceText.length - 1]);
        const mails: Array<string> = sourceText.split(this.emailSeparator).filter((value: string) => !!value.trim());
        if (lastCharacterIsSeparator && !this.inputIsEmpty(index + 1)) {
            mails.push("");
        }
        if (mails.length > 0) {
            sourceInput.setValue(mails[0]);

            for (let mailIndex: number = 1; mailIndex < mails.length; mailIndex++) {
                const newInput: FormControl<string|null> = this.createInput(mails[mailIndex]);
                this.emailFormArray.splice(index + mailIndex, 0, newInput);
            }

            this.setFocus(index + mails.length);
        }
    }

    private createInput(initialValue: string): FormControl<string|null> {
        const input: FormControl<string|null> = new FormControl<string|null>(initialValue, [ExtendedValidators.extendedEmailValidator]);
        input.markAllAsTouched();
        return input;
    }

    private setFocus(index: number): void {
        if (index >= this.emailFormArray.length) {
            return;
        }

        // TODO: Set focus
    }

    private emailsUpdated(): void {
        this.emailFormArray = [];
        this.checkEmptyInput();

        this.emailFormArray[0].setValue(this.emailString ?? "");
        this.splitInput(0);
        this.checkEmptyInput();
    }

    private formChanged(): void {
        const newValue: string = this.getValuesAsString();
        if (this.emailString != newValue) {
            this.emailString = this.getValuesAsString();
            this.emailsChange.emit(this.emailString);
        }
        this.updateValidState();
    }

    public updateValidState(): void {
        let isValid: boolean = true;
        for (const input of this.emailFormArray) {
            isValid = isValid && input.status == "VALID";
        }
        if (isValid != this.isValid) {
            this.isValid = isValid;
            this.isValidChange.emit(this.isValid);
        }
    }

    public getValuesAsString(): string {
        const valuesArray: Array<string> = this.emailFormArray.map((formControl: FormControl<string|null>) => formControl.value?.trim()).filter((value: string|undefined|null) => !!value) as Array<string>;
        return valuesArray.join(";");
    }

    public getErrorMessage(_formControl: FormControl): string|void {
        return $localize`:@@entity.validation.emailFormat:The e-mail is not in the correct format (e.g. name@example.com).` as string;
    }
}
