import { AttachmentStorageDto } from "../attachments/attachment-storage-dto";
import { DocumentAnswerCommentStorageDto } from "./document-answer-comment-storage-dto";
import { DocumentAnswerValueStorageDto } from "./document-answer-value-storage-dto";

/**
 * Storage DTO for {@link DocumentValueContainer}.
 */
export class DocumentValueContainerStorageDto {
    public questionType?: string;
    public questionTemplateIdentifier?: number;
    public questionBusinessIdentifier?: string;
    public questionTechnicalIdentifier?: number;
    public sectionBusinessIdentifier?: string;
    public sectionTechnicalIdentifier?: number;
    public sectionTemplateIdentifier?: number;

    public comment?: DocumentAnswerCommentStorageDto;

    public linkedAttachments?: Array<AttachmentStorageDto> = [];

    public answers?: Array<DocumentAnswerValueStorageDto> = [];

    public saveState?: string;
    public synchronizationError?: string;

}
