<form *ngIf="process" [formGroup]="formData" class="coreDataGroup">
    <!-- process type -->
    <mat-form-field [appearance]="formHelper.appearance(readonly)" [class.readonly]="readonly" class="processTypeField">
        <mat-label>
            <fa-icon [icon]="appIcons.processType"></fa-icon>&nbsp;<ng-container i18n="@@process.type">Process type</ng-container>
        </mat-label>

        <input *ngIf="readonly || !isNew" [readonly]="true" [value]="(selectedProcessTypeLabel ?? '')" matInput />

        <mat-select *ngIf="!readonly && isNew" [formControl]="processTypeControl" [value]="process.processType">
            <mat-option *ngFor="let item of processTypeLabels" [value]="item.id">{{item.label}}</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- auditor -->
    <mat-form-field [appearance]="formHelper.appearance(readonly)" [class.readonly]="readonly" class="auditorFieldControl">
        <mat-label>
            <fa-icon [icon]="appIcons.entityPersonRegular"></fa-icon>&nbsp;<ng-container i18n="@@process.assignee">Assignee</ng-container>
        </mat-label>

        <mat-select [disabled]="readonly" [formControl]="auditorControl">
            <mat-option *ngFor="let auditor of auditors" [value]="auditor.identifier.businessIdentifier">
                <ng-container i18n="@@generic.fullName">{{auditor.firstname}} {{auditor.lastname}}</ng-container>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="groupedInputs">
        <!-- target date -->
        <mat-form-field [appearance]="formHelper.appearance(readonly)" class="visitDateField">
            <mat-label>
                <fa-icon [icon]="appIcons.targetDate"></fa-icon>&nbsp;
                <ng-container i18n="@@process.targetDate">Target date</ng-container>
            </mat-label>
            <input [formControl]="targetDateControl" [matDatepicker]="picker" matInput />
            <mat-datepicker-toggle [disabled]="readonly" [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="targetDateHasTime" [appearance]="formHelper.appearance(readonly)" class="visitTimeField">
            <mat-label>
                <fa-icon [icon]="appIcons.targetTime"></fa-icon>&nbsp;
                <ng-container i18n="@@process.targetTime">Target time</ng-container>
            </mat-label>
            <input [formControl]="targetTimeControl" matInput type="time" />
            <button (click)="setHasTargetTime(false)" mat-icon-button matSuffix>
                <fa-icon [icon]="appIcons.genericDelete"></fa-icon>
            </button>
        </mat-form-field>
        <button (click)="setHasTargetTime(true)" *ngIf="!targetDateHasTime" [disabled]="!targetDateControl.value" mat-stroked-button>
            <fa-icon [icon]="appIcons.targetTime"></fa-icon>
            <label i18n="@@process.addTimeButton">Add time</label>
        </button>
    </div>

    <!-- linked buildings complexes -->
    <div class="coreDataGroup">
        <ion-list-header>
            <ion-label i18n="@@process.linkedBuildingComplex">Building complex</ion-label>
            <ion-button (click)="linkBuildingComplex()" [disabled]="readonly" fill="outline">
                <fa-icon [icon]="appIcons.linkBuildingComplex"></fa-icon>&nbsp;<ng-container i18n="@@process.linkBuildingComplexButton">Link building complex</ng-container>
            </ion-button>
        </ion-list-header>

        <business-entity-selection-list #buildingComplexList (selectedEntitiesChange)="buildingComplexLinksChanged($event)" [canDelete]="!readonly" [canEdit]="!readonly" [canSelect]="false" [entityLinks]="process.linkedEntities"></business-entity-selection-list>
    </div>

    <base-info-box [icon]="appIcons.informationCircle" [marginTop]="true" boxStyle="subtle" header="Additional information" i18n="@@process.informationText" i18n-header="@@dialog.additionalInformationTitle">
        A process is usually a timed task within a project. You can create multiple processes within a project.<br />
        <br />
        A process itself has a specific type that determines which documents can be used within this process. For example, if you are planning an orienting inspection and regarding the result a follow-up audit, then there are two processes, one for the inspection and another one for the follow-up. Both processes can contain one or multiple documents.<br />
        <br />
        Each process type has specific states that the process can transition within.<br />
        <br />
        Usually a process needs something to work with. E.g., if you are auditing a building, the process needs a linked building complex; if you are auditing a machine, the process needs a linked machine.
    </base-info-box>
</form>
