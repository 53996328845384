import { CryptoHelper } from "../../../base/helpers/crypto-helper";
import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { AddressIdentifier } from "../../identifiers/address-identifier";
import { BuildingIdentifier } from "../../identifiers/building-identifier";
import { EntityBusinessIdentifier } from "../../identifiers/entity-business-identifier";
import { EntityIdentifierStorageDto } from "../../identifiers/entity-identifier-storage-dto";
import { EntityTechnicalIdentifier } from "../../identifiers/entity-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { CoreDataEntity } from "../core-data/core-data-entity";
import { EntityTypes } from "../entity-types";
import { SampleLegalReferences } from "./sample-legal-references";
import { SampleMergeStorageDto } from "./sample-merge-storage-dto";
import { SampleTypes } from "./sample-types";

/**
 * Sample that holds additional information for the building floors and rooms.
 */
export class SampleMerge extends CoreDataEntity<EntityBusinessIdentifier, EntityTechnicalIdentifier> {
    constructor(init?: Partial<SampleMerge>) {
        super(EntityTypes.sampleMerge);
        TypeScriptHelper.initObjectFromPartial(this, init);
        if (this.identifier.businessIdentifier) {
            this.uniqueIdentifier = this.identifier.businessIdentifier;
        }
    }

    public index: number = 0;

    public uniqueIdentifier: string = CryptoHelper.getUUID();

    public buildingIdentifier?: BuildingIdentifier;

    public addressIdentifier?: AddressIdentifier;

    public name?: string;

    public sampleType: SampleTypes = SampleTypes.unknown;

    public street?: string;

    public streetNumber?: string;

    public floorNumber?: string;

    public roomLocation?: string;

    public roomName?: string;

    public roomFeatureLocation?: string;

    public roomFeatureName?: string;

    public legalReference?: SampleLegalReferences = SampleLegalReferences.dvgwW551;

    public get displayTitle(): string {
        return Identifier.identifierToString(this.identifier);
    }

    public get isNew(): boolean {
        return !Identifier.isEmpty(this.identifier);
    }

    protected toEntityStorageDto(): SampleMergeStorageDto {
        return new SampleMergeStorageDto({
            uniqueIdentifier: this.uniqueIdentifier,
            index: this.index,
            name: this.name,
            buildingId: EntityIdentifierStorageDto.fromIdentifier(this.buildingIdentifier),
            addressId: EntityIdentifierStorageDto.fromIdentifier(this.addressIdentifier),
            sampleType: this.sampleType,
            street: this.street,
            streetNumber: this.streetNumber,
            floorNumber: this.floorNumber,
            roomLocation: this.roomLocation,
            roomName: this.roomName,
            roomFeatureLocation: this.roomFeatureLocation,
            roomFeatureName: this.roomFeatureName,
            legalReference: this.legalReference
        });
    }

    protected fromEntityStorageDto(dto: SampleMergeStorageDto|undefined): void {
        this.uniqueIdentifier = dto?.businessId || dto?.uniqueIdentifier || CryptoHelper.getUUID();
        this.index = dto?.index ?? 0;
        this.name = dto?.name;
        this.buildingIdentifier = Identifier.create(dto?.buildingId?.bid, dto?.buildingId?.tid);
        this.addressIdentifier = Identifier.create(dto?.addressId?.bid, dto?.addressId?.tid);
        this.sampleType = TypeScriptHelper.dtoToEnum(SampleTypes, dto?.sampleType, SampleTypes.unknown) as SampleTypes;
        this.street = dto?.street;
        this.streetNumber = dto?.streetNumber;
        this.floorNumber = dto?.floorNumber;
        this.roomLocation = dto?.roomLocation;
        this.roomName = dto?.roomName;
        this.roomFeatureLocation = dto?.roomFeatureLocation;
        this.roomFeatureName = dto?.roomFeatureName;
        this.legalReference = TypeScriptHelper.dtoToEnum(SampleLegalReferences, dto?.legalReference, SampleLegalReferences.dvgwW551) as SampleLegalReferences;
    }

    public clone(): SampleMerge {
        return CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
    }
}
