import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { EntityIdentifierStorageDto } from "../../identifiers/entity-identifier-storage-dto";
import { CoreDataEntityStorageDto } from "../core-data/core-data-entity-storage-dto";

/**
 * Storage DTO for {@link SampleMerge}.
 */
export class SampleMergeStorageDto extends CoreDataEntityStorageDto {
    constructor(init?: Partial<SampleMergeStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public uniqueIdentifier?: string;

    public index?: number = 0;

    public buildingId?: EntityIdentifierStorageDto;

    public addressId?: EntityIdentifierStorageDto;

    public name?: string;

    public sampleType?: string;

    public street?: string;

    public streetNumber?: string;

    public floorNumber?: string;

    public roomLocation?: string;

    public roomName?: string;

    public roomFeatureLocation?: string;

    public roomFeatureName?: string;

    public legalReference?: string;
}
