import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { IntentOptions } from "../../../base/services/intents/intent-options";
import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { DeleteEntityIntent } from "./delete-entity-intent";

/**
 * Options for {@link DeleteEntityIntent}.
 */
export class DeleteEntityIntentOptions implements IntentOptions<DeleteEntityIntent> {
    constructor(entity: GenericCoreDataEntity, init?: Partial<DeleteEntityIntentOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.entity = entity;
    }

    public entity: GenericCoreDataEntity;

    public showConfirmMessage: boolean = true;

    public result: boolean = false;
}
