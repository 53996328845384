import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { MatTable } from "@angular/material/table";
import { lastValueFrom } from "rxjs";

import { environment } from "../../../../environments/environment";
import { BaseComponent } from "../../../base/components/base-component";
import { GenericStatus } from "../../../base/helpers/generic-status-enum";
import { ProcessElementTriggeredEventArguments } from "../../../base/interfaces/process-element-triggered-event-arguments";
import { AppIcons } from "../../services/icons/app-icons";
import { OnlineMonitorService } from "../../services/monitors/online-monitor.service";
import { NetworkTestStep } from "./network-test-step";

/**
 * Component to test the network connection.
 */
@Component({
    selector: "app-network-test",
    templateUrl: "./network-test.component.html",
    styleUrls: ["./network-test.component.scss"]
})
export class NetworkTestComponent extends BaseComponent {
    constructor(
        private readonly onlineMonitorService: OnlineMonitorService,
        private readonly httpClient: HttpClient
    ) {
        super();
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly statusEnum: typeof GenericStatus = GenericStatus;

    public testRunning: boolean = false;

    public testSteps: Array<NetworkTestStep> = [];

    public finalTestResult: GenericStatus = GenericStatus.pending;

    public testSummaryText: string = "";

    @ViewChild("table")
    public table?: MatTable<any>;

    public displayedColumns: Array<string> = ["status", "title"];

    public stepIpAddressTest: NetworkTestStep = new NetworkTestStep("ip-address", $localize`:@@networkTest.testIpAddress:IP Address`, { url: environment.networkTestIpAddressUrl });

    public stepBackendConnectionTest: NetworkTestStep = new NetworkTestStep("backend-connection", $localize`:@@networkTest.testBackendConnection:Connection to the server`);

    public stepFrontendConnectionTest: NetworkTestStep = new NetworkTestStep("frontend-connection", $localize`:@@networkTest.testFrontendConnection:Connection to the client version file`, { url: "/version.json" });

    private stepGoogleTest: NetworkTestStep = new NetworkTestStep("google-connection", $localize`:@@networkTest.testGoogleConnection:Test connection to Google*`, { url: environment.networkTestGoogleUrl });

    private stepDownload10Kib: NetworkTestStep = new NetworkTestStep("download-10kib", $localize`:@@networkTest.testDownload10Kib:Download 10 KiB`, { url: environment.networkTestDownload10KibUrl });

    private stepDownload100Kib: NetworkTestStep = new NetworkTestStep("download-100kib", $localize`:@@networkTest.testDownload100Kib:Download 100 KiB`, { url: environment.networkTestDownload100KibUrl });

    protected componentInit(): void {
        this.runTest().then();
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public async runTest(triggeredEventArguments?: ProcessElementTriggeredEventArguments): Promise<void> {
        if (this.testRunning) {
            triggeredEventArguments?.finishedReceiver.finished();
            return;
        }
        this.testRunning = true;

        this.testSteps = [
            this.stepIpAddressTest.reset(),
            this.stepBackendConnectionTest.reset(),
            this.stepFrontendConnectionTest.reset(),
            this.stepGoogleTest.reset(),
            this.stepDownload10Kib.reset(),
            this.stepDownload100Kib.reset()
        ];
        this.updateTable();

        try {
            await Promise.all([
                this.runIpDetection(),
                this.runBackendConnectionTest(),
                this.runFrontendConnectionTest(),
                this.runGoogleTest(),
                this.runTransferTests()
            ]);
            this.finalTestResult = this.testSteps.every((step: NetworkTestStep) => step.status == GenericStatus.success) ? GenericStatus.success : GenericStatus.failed;
        } catch (error) {
            this.finalTestResult = GenericStatus.failed;
        } finally {
            this.updateTestSummary();
            this.testRunning = false;
            triggeredEventArguments?.finishedReceiver.finished();
        }
    }

    private updateTable(): void {
        this.table?.renderRows();
    }

    private async runIpDetection(): Promise<void> {
        this.stepIpAddressTest.status = GenericStatus.active;
        this.updateTable();

        try {
            const content: { ip4?: string; ip6?: string } = await this.requestFile(this.stepIpAddressTest.url!, false) as {
                ip4?: string;
                ip6?: string;
            };

            this.stepIpAddressTest.description = [
                content.ip4 ? `IPv4: ${content.ip4}` : undefined,
                content.ip6 ? `IPv6: ${content.ip6}` : undefined
            ].filter(Boolean).join(", ");

            this.stepIpAddressTest.status = GenericStatus.success;
        } catch (error) {
            this.stepIpAddressTest.status = GenericStatus.failed;
        }

        this.updateTable();
    }

    private async runFrontendConnectionTest(): Promise<void> {
        this.stepFrontendConnectionTest.status = GenericStatus.active;
        this.updateTable();

        try {
            const content: { version?: string } = await this.requestFile(this.stepFrontendConnectionTest.url!, false) as {
                version?: string;
            };

            this.stepFrontendConnectionTest.description = `Version: ${content.version}`;
            this.stepFrontendConnectionTest.status = GenericStatus.success;
        } catch (error) {
            this.stepFrontendConnectionTest.status = GenericStatus.failed;
        }

        this.updateTable();
    }

    private async runBackendConnectionTest(): Promise<void> {
        this.stepBackendConnectionTest.status = GenericStatus.active;
        this.updateTable();

        try {
            const successful: boolean = await this.onlineMonitorService.checkConnection();
            this.stepBackendConnectionTest.description = `Version: ${this.onlineMonitorService.serverVersion}`;
            this.stepBackendConnectionTest.status = successful ? GenericStatus.success : GenericStatus.failed;
        } catch (error) {
            this.stepBackendConnectionTest.status = GenericStatus.failed;
        }

        this.updateTable();
    }

    private async runGoogleTest(): Promise<void> {
        this.stepGoogleTest.status = GenericStatus.active;
        this.updateTable();

        try {
            const content: { status: string } = await this.requestFile(this.stepGoogleTest.url!, false) as {
                status: string;
            };
            this.stepGoogleTest.status = content.status == "ok" ? GenericStatus.success : GenericStatus.failed;
        } catch (error) {
            this.stepGoogleTest.status = GenericStatus.failed;
        }

        this.updateTable();
    }

    private async runTransferTests(): Promise<void> {
        await this.runSpecificTransferTest(this.stepDownload10Kib);
        await this.runSpecificTransferTest(this.stepDownload100Kib);
    }

    private async runSpecificTransferTest(test: NetworkTestStep): Promise<void> {
        test.status = GenericStatus.active;
        this.updateTable();

        try {
            const content: Blob = await this.requestFile(test.url!, true) as Blob;
            test.status = content?.size > 0 ? GenericStatus.success : GenericStatus.failed;
        } catch (error) {
            test.status = GenericStatus.failed;
        }

        this.updateTable();
    }

    private requestFile(url: string, isBinary: boolean): Promise<any> {
        if (isBinary) {
            return lastValueFrom(this.httpClient.get(`${url}?ts=${Date.now()}`, { responseType: "blob" }));
        } else {
            return lastValueFrom(this.httpClient.get(`${url}?ts=${Date.now()}`));
        }
    }

    private updateTestSummary(): void {
        if (this.finalTestResult == GenericStatus.success) {
            this.testSummaryText = "";
            return;
        }

        let serverDomain: string = environment.apiBasePath.replace("https://", "");
        serverDomain = serverDomain.substring(0, serverDomain.indexOf("/"));

        const downloadTestFailed: boolean = this.stepDownload10Kib.status == GenericStatus.failed || this.stepDownload100Kib.status == GenericStatus.failed;

        if (this.stepBackendConnectionTest.status == GenericStatus.failed && this.stepGoogleTest.status == GenericStatus.failed) {
            this.testSummaryText = $localize`:@@networkTest.summaryAllTestsFailed:It is not possible to connect to the server nor connecting to Google* servers. This means that the device does not have internet at all or the device blocks the internet for this app. Please check your network settings and try again afterwards.`;
        } else if (this.stepBackendConnectionTest.status == GenericStatus.failed && this.stepGoogleTest.status == GenericStatus.success && !downloadTestFailed) {
            this.testSummaryText = $localize`:@@networkTest.summaryNoConnectionToServer:The server cannot be reached, but the internet is working. It is possible that the server is offline due to maintenance or the app blocks requests to the domain "${serverDomain}". Please check this issue with your administrator or contact our support.`;
        } else if (this.stepBackendConnectionTest.status == GenericStatus.failed && this.stepGoogleTest.status == GenericStatus.success && downloadTestFailed) {
            this.testSummaryText = $localize`:@@networkTest.summaryNoConnectionToServerDownloadFailed:The server cannot be reached. The internet is working, but it was not possible to download larger data. It looks like your internet connection is very unstable. If you are on a mobile network, try to get a better connection and try again afterwards.`;
        } else if (this.stepBackendConnectionTest.status == GenericStatus.success && this.stepGoogleTest.status == GenericStatus.failed) {
            this.testSummaryText = $localize`:@@networkTest.summaryConnectionToServerNoConnectionToGoogle:The server can be reached, but it is not possible to receive data from Google servers. This happens probably because the connection to Google is blocked. The app does not need Google so you can continue to work normally.`;
        } else if (downloadTestFailed) {
            this.testSummaryText = $localize`:@@networkTest.summaryDownloadFailed:It was not possible to download larger data. It looks like your internet connection is very unstable. If you are on a mobile network, try to get a better connection and try again afterwards.`;
        }
    }
}
