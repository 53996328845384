import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { EntityTypes } from "../../../entities/entity-types";

/**
 * Options for core data list.
 */
export class CoreDataListOptions {
    constructor(init?: Partial<CoreDataListOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public entityType: EntityTypes = EntityTypes.unknown;

    public dialogMode: boolean = false;

    public allowCreate: boolean = false;

    public allowInvite: boolean = false;

    public auditorsOnly: boolean = false;

    public selectMode: boolean = false;
}
