import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { Identifier } from "../../identifiers/identifier";
import { QualityAssuranceIdentifier } from "../../identifiers/quality-assurance-identifier";
import { QuestionTechnicalIdentifier } from "../../identifiers/question-technical-identifier";
import { ChatMessage } from "../communication/chat-message";
import { QualityAssuranceStatus } from "../documents/quality-assurance-status";
import { StorableEntity } from "../storable-entity";
import { QualityAssuranceItemStorageDto } from "./quality-assurance-item-storage-dto";

/**
 * The QA response for a single question item, includes the comments.
 */
export class QualityAssuranceItem implements StorableEntity<QualityAssuranceItemStorageDto> {
    constructor(init?: Partial<QualityAssuranceItem>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public identifier?: QualityAssuranceIdentifier;

    public questionTechnicalIdentifier?: QuestionTechnicalIdentifier;

    public status: QualityAssuranceStatus = QualityAssuranceStatus.unknown;

    public messages: Array<ChatMessage> = [];

    public fromStorageDto(dto: QualityAssuranceItemStorageDto|undefined): void {
        this.identifier = Identifier.create(dto?.businessId, dto?.technicalId);
        this.questionTechnicalIdentifier = dto?.questionTechnicalIdentifier as QuestionTechnicalIdentifier;
        this.status = TypeScriptHelper.dtoToEnum(QualityAssuranceStatus, dto?.status, QualityAssuranceStatus.unknown) as QualityAssuranceStatus;

        this.messages = [];
        for (const messageDto of dto?.messages ?? []) {
            const message: ChatMessage = new ChatMessage(messageDto.text ?? "");
            message.fromStorageDto(messageDto);
            this.messages.push(message);
        }
    }

    public toStorageDto(): QualityAssuranceItemStorageDto {
        const dto: QualityAssuranceItemStorageDto = new QualityAssuranceItemStorageDto({
            businessId: this.identifier?.businessIdentifier,
            technicalId: this.identifier?.technicalIdentifier,
            status: this.status,
            questionTechnicalIdentifier: this.questionTechnicalIdentifier,
            messages: []
        });
        for (const message of this.messages) {
            dto.messages!.push(message.toStorageDto());
        }
        return dto;
    }
}
