import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { DateTimeHelper } from "../../../base/helpers/date-time-helper";
import { EventHelper } from "../../../base/helpers/event-helper";
import { HttpCodes } from "../../../base/helpers/http-codes";
import { AuthService } from "../../../base/services/auth/auth.service";
import { IocService } from "../../../base/services/ioc/ioc.service";
import { TimeService } from "../../../base/services/time/time.service";
import { Timer } from "../../../base/services/time/timer";
import { AccountDto, CompanyDto, PermissionDto } from "../../../generated/api";
import { errorResult, SafeResult, successResult } from "../../common/safe-result";
import { Company } from "../../entities/core-data/company";
import { Person } from "../../entities/core-data/person";
import { EntityTypes } from "../../entities/entity-types";
import { AppException } from "../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../global/frontend-errors";
import { UserPermissions } from "../../global/user-permissions";
import { Identifier } from "../../identifiers/identifier";
import { PersonBusinessIdentifier } from "../../identifiers/person-business-identifier";
import { PersonIdentifier } from "../../identifiers/person-identifier";
import { PersonTechnicalIdentifier } from "../../identifiers/person-technical-identifier";
import { AccountsApiService } from "../api/accounts-api.service";
import { ApiService } from "../api/api.service";
import { UsersApiService } from "../api/users-api.service";
import { PersonsService } from "../master-data/persons/persons.service";
import { CoreDataStorageService } from "../master-data/storage/core-data-storage.service";
import { OnlineMonitorService } from "../monitors/online-monitor.service";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";
import { LoginResults } from "./login-results";
import { SessionChangedEventData } from "./session-changed-event-data";

/**
 * The service to work with sessions, e.g. login, logout or register.
 */
@Injectable({
    providedIn: "root"
})
export class SessionService {
    constructor(
        private readonly authService: AuthService,
        private readonly storageService: StorageService,
        private readonly coreDataStorageService: CoreDataStorageService,
        private readonly onlineMonitorService: OnlineMonitorService,
        private readonly usersApiService: UsersApiService,
        private readonly accountsApiService: AccountsApiService,
        private readonly iocService: IocService,
        private readonly apiService: ApiService,
        private readonly timeService: TimeService
    ) {
        EventHelper.subscribe(this.onlineMonitorService.serverVersionChanged, this.serverVersionChanged, this);

        this.checkSessionExistsTimer = this.timeService.spawnTimer(environment.loginStillExistsCheckInterval);
        EventHelper.subscribe(this.checkSessionExistsTimer.elapsed, this.checkLogin, this);

        EventHelper.subscribe(this.authService.isAuthenticatedChanged, this.isAuthenticatedChanged, this);
    }

    public readonly appSession: string = DateTimeHelper.utcNow();

    public activePerson?: Person = undefined;

    public activeAccount?: AccountDto = undefined;

    public activePermissions: Array<string> = [];

    public accounts: Array<AccountDto> = [];

    public sessionChanged: EventEmitter<SessionChangedEventData> = new EventEmitter<SessionChangedEventData>();

    public loggedIn: boolean = false;

    private checkSessionExistsTimer: Timer;

    private personsServiceField?: PersonsService;

    private get personsService(): PersonsService {
        // Not injected in constructor because PersonsService uses SessionService
        return this.personsServiceField ?? (this.personsServiceField = this.iocService.resolve(PersonsService));
    }

    public get activeAccountId(): number {
        return this.activeAccount?.id ?? 0;
    }

    public get activePersonTechnicalId(): PersonTechnicalIdentifier {
        return this.activePerson?.identifier.technicalIdentifier ?? 0 as PersonTechnicalIdentifier;
    }

    public get activePersonBusinessId(): PersonBusinessIdentifier {
        return this.activePerson?.identifier.businessIdentifier ?? "" as PersonBusinessIdentifier;
    }

    public async initialize(): Promise<void> {
        this.accounts = await this.storageService.get(StorageKeys.accounts) ?? [];
        this.activeAccount = await this.storageService.get(StorageKeys.activeAccount);
        this.activePermissions = await this.storageService.get(StorageKeys.activePermissions) ?? [];

        const activePersonIdentifier: PersonIdentifier = Identifier.stringToIdentifier<PersonIdentifier>(await this.storageService.get(StorageKeys.activePersonIdentifier));
        if (activePersonIdentifier) {
            this.activePerson = await this.coreDataStorageService.get(activePersonIdentifier, EntityTypes.person);
        }

        await this.authService.initialize();

        this.refreshActivePerson().then();
    }

    private async isAuthenticatedChanged(isAuthenticated: boolean): Promise<void> {
        this.loggedIn = isAuthenticated;
        this.notifySessionChanged();
        await this.refreshExistingAccounts();
    }

    public requiresAccount(): void|never {
        if (!this.activeAccount || !this.activeAccount.id) {
            throw new AppException(FrontendErrors.FE14UnableToTalkToServerAccountNotSet, $localize`:@@exception.fe14UnableToTalkToServerAccountNotSet:A request to the server could not be sent because no account has been selected. Please select an account or log-out and log-in again.`);
        }
    }

    public requiresAccountSafeResult(): SafeResult<void, AppException> {
        if (!this.activeAccount || !this.activeAccount.id) {
            return errorResult(new AppException(FrontendErrors.FE14UnableToTalkToServerAccountNotSet, $localize`:@@exception.fe14UnableToTalkToServerAccountNotSet:A request to the server could not be sent because no account has been selected. Please select an account or log-out and log-in again.`));
        }
        return successResult(undefined);
    }

    public requiresLogin(): void|never {
        if (!this.activeAccount || !this.activeAccount.id) {
            throw new AppException(FrontendErrors.FE39UnableToTalkToServerNotLoggedIn, $localize`:@@exception.fe39UnableToTalkToServerNotLoggedIn:A request to the server could not be sent because you are not logged in. Please log-in again.`);
        }
    }

    public async checkLogin(): Promise<void> {
        // Do nothing for now
    }

    public async changePassword(): Promise<void> {
        return this.authService.changePassword();
    }

    public hasPermission(permission: UserPermissions): boolean {
        return this.activePermissions.includes(permission);
    }

    public async logIn(): Promise<LoginResults> {
        await this.authService.logIn();
        return LoginResults.ok;
    }

    private serverVersionChanged(_version: string): void {
        this.refreshExistingAccounts().then();
    }

    private notifySessionChanged(): void {
        const eventData: SessionChangedEventData = {
            loggedIn: this.loggedIn,
            activeAccount: this.activeAccount,
            accounts: this.accounts,
            activePerson: this.activePerson
        };

        this.sessionChanged.emit(eventData);
    }

    private async refreshExistingAccounts(): Promise<void> {
        let accounts: Array<AccountDto>|undefined;

        try {
            accounts = await this.accountsApiService.listAccounts();
        } catch (error) {
            const errorResponse: HttpErrorResponse = error as HttpErrorResponse;
            if (errorResponse && errorResponse.status == HttpCodes.unauthorized) {
                // The user does not exist anymore
                await this.logOut();
            } else {
                // Log other errors but do not anything (could be that the internet connection is bad)
                console.warn(error);
            }
            return;
        }

        if (this.accountsAreEqual(accounts, this.accounts)) {
            return;
        }

        // Accounts have been changed - refresh the accounts
        await this.updateAccounts();
    }

    private accountsAreEqual(accountsA?: Array<AccountDto>, accountsB?: Array<AccountDto>): boolean {
        if (accountsA === accountsB === undefined) {
            return true;
        }
        if (!accountsA || !accountsB) {
            return false;
        }
        if (accountsA.length != accountsB.length) {
            return false;
        }

        for (const accountA of accountsA) {
            const accountB: AccountDto|undefined = accountsB.find((account: AccountDto) => account.id == accountA.id);
            if (accountB?.updated != accountA.updated || accountB?.created != accountA.created) {
                return false;
            }
        }
        for (const accountB of accountsB) {
            const accountA: AccountDto|undefined = accountsA.find((account: AccountDto) => account.id == accountB.id);
            if (accountA?.updated != accountB.updated || accountA?.created != accountB.created) {
                return false;
            }
        }

        return true;
    }

    public async updateAccounts(): Promise<void> {
        const oldAccounts: Array<AccountDto>|undefined = this.accounts;
        const accounts: Array<AccountDto>|undefined = await this.accountsApiService.listAccounts();
        this.setAccounts(accounts);

        let needsNotify: boolean = !this.accountsAreEqual(accounts, oldAccounts);

        if (this.activeAccount) {
            const newActiveAccount: AccountDto|undefined = this.accounts.find((account: AccountDto) => account.id == this.activeAccount!.id);
            if (newActiveAccount && newActiveAccount.id && newActiveAccount.updated != this.activeAccount.updated) {
                await this.setAccount(newActiveAccount.id);
            } else if (!newActiveAccount) {
                await this.setActiveAccount(undefined);
            }
            needsNotify = false;
        } else if (accounts.length == 1 && accounts[0].id) {
            await this.setAccount(accounts[0].id);
        }

        if (needsNotify) {
            this.notifySessionChanged();
        }
    }

    public async refreshActivePerson(): Promise<void> {
        try {
            await this.loadPersonFromServer();
        } catch (error) {
            const errorResponse: HttpErrorResponse = error as HttpErrorResponse;
            if (errorResponse && errorResponse.status == HttpCodes.unauthorized) {
                // The user does not exist anymore
                await this.logOut();
            } else {
                // Log other errors but do not anything (could be that the internet connection is bad)
                console.warn(error);
            }
            return;
        }
    }

    public async updateActivePerson(): Promise<void> {
        if (!this.activeAccount) {
            this.activePerson = undefined;
            this.storageService.delete(StorageKeys.activePersonIdentifier).then();
            this.notifySessionChanged();
            return;
        }

        await this.loadPersonFromServer();
    }

    private async loadPersonFromServer(): Promise<void> {
        const me: Person|undefined = await this.personsService.getMe();
        if (!me) {
            await this.logOut();
            return;
        }

        this.activePerson = me;
        await this.storageService.set(StorageKeys.activePersonIdentifier, Identifier.identifierToString(me.identifier));

        const permissionDtos: Array<PermissionDto> = await this.usersApiService.getMyPermissions(this.activeAccountId);
        this.activePermissions = [];
        for (const permissionDto of permissionDtos) {
            if (permissionDto.name) {
                this.activePermissions.push(permissionDto.name);
            }
        }
        await this.storageService.set(StorageKeys.activePermissions, this.activePermissions);

        this.notifySessionChanged();
    }

    private setAccounts(accounts: Array<AccountDto>|undefined): void {
        this.accounts = accounts ?? [];
        this.storageService.set(StorageKeys.accounts, accounts).then();
    }

    private async setActiveAccount(account: AccountDto|undefined): Promise<void> {
        this.storageService.set(StorageKeys.activeAccount, account).then();

        this.activeAccount = account;
        await this.updateActivePerson();
    }

    public setAccount(accountId: number): Promise<void> {
        if (accountId <= 0) {
            return this.setActiveAccount(undefined);
        }
        const accounts: Array<AccountDto> = this.accounts.filter((acc: AccountDto) => acc.id == accountId);
        const account: AccountDto|undefined = accounts && accounts.length ? accounts[0] : undefined;
        if (!account) {
            throw new AppException(FrontendErrors.FE10AccountNotAvailable, $localize`:@@exception.fe10AccountNotAvailable:The account with the ID ${accountId} is not available. Please reload the application or log in again.`);
        }
        return this.setActiveAccount(account);
    }

    private async clearSession(): Promise<void> {
        this.loggedIn = false;
        this.accounts = [];
        this.activeAccount = undefined;
        this.activePerson = undefined;
        this.activePermissions = [];

        await this.storageService.clearAfterLogout();
    }

    public async logOut(): Promise<void> {
        await this.clearSession();
        this.notifySessionChanged();

        await this.authService.logOut();
    }

    public async createAccount(company: Company): Promise<AccountDto> {
        const companyDto: CompanyDto = Api1Converter.companyToDto(company);
        try {
            const accountDto: AccountDto|undefined = await this.accountsApiService.createAccount(companyDto);
            if (!accountDto) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error("Account creation failed, empty response.");
            }
            await this.updateAccounts();
            return accountDto;
        } catch (error) {
            throw new AppException(FrontendErrors.FE11UnableToRegisterAccount, $localize`:@@exception.fe11UnableToRegisterAccount:Unable to create your account.`, error as Error);
        }
    }
}
