import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { PersonTechnicalIdentifier } from "../../identifiers/person-technical-identifier";
import { StorableEntity } from "../storable-entity";
import { ChatMessageStorageDto } from "./chat-message-storage-dto";

/**
 * A simple chat message used for commenting and communication.
 */
export class ChatMessage implements StorableEntity<ChatMessageStorageDto> {
    constructor(text: string, init?: Partial<ChatMessage>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.text = text;
    }

    public personId?: PersonTechnicalIdentifier;
    public personName?: string;
    public text: string;
    public created?: string;
    public updated?: string;

    public fromStorageDto(dto: ChatMessageStorageDto|undefined): void {
        this.personId = dto?.personId as PersonTechnicalIdentifier;
        this.personName = dto?.personName;
        this.text = dto?.text ?? "";
        this.created = dto?.created;
        this.updated = dto?.updated;
    }

    public toStorageDto(): ChatMessageStorageDto {
        return {
            personId: this.personId,
            personName: this.personName,
            text: this.text,
            created: this.created,
            updated: this.updated
        };
    }
}
