/**
 * A helper class to extend TypeScript functionality.
 */
export class TypeScriptHelper {
    public static nameOf<TOwner>(name: keyof TOwner): string {
        return name as string;
    }

    public static touch(ref: any): void {
        if (ref === undefined || ref === null) {
            console.warn("TypescriptHelper.touch got a undefined/null.");
        }
    }

    public static ignoreResult(_ref: any): void {
        // Do nothing here
    }

    public static getEnumKeys<TEnum extends { [index: string]: string }, TKey extends keyof TEnum = keyof TEnum>(obj: TEnum): Array<TKey> {
        return Object.keys(obj).filter((k: string) => Number.isNaN(+k)) as Array<TKey>;
    }

    public static getEnumValues<TEnum extends { [index: string]: string }, TKey extends keyof TEnum = keyof TEnum>(obj: TEnum): Array<TKey> {
        return Object.values(obj).filter((k: string) => Number.isNaN(+k)) as Array<TKey>;
    }

    public static getEnumKeyByEnumValue<TEnum extends { [index: string]: string }>(myEnum: TEnum, enumValue: string|undefined): keyof TEnum|undefined {
        if (!enumValue) {
            return undefined;
        }
        const keys: Array<string> = Object.keys(myEnum).filter((x: string) => myEnum[x] == enumValue);
        return keys.length > 0 ? keys[0] : undefined;
    }

    public static hasEnumKey<TEnum extends { [index: string]: string }>(myEnum: TEnum, key: string|undefined): boolean {
        return key ? Object.keys(myEnum).includes(key) : false;
    }

    public static hasEnumValue<TEnum extends { [index: string]: string }>(myEnum: TEnum, value: string|undefined): boolean {
        return value ? Object.values(myEnum).includes(value) : false;
    }

    public static stringValueToEnum<TEnum extends { [index: string]: string }>(myEnum: TEnum, value: string|undefined, fallback: keyof TEnum): keyof TEnum {
        if (!value) {
            return fallback;
        }
        const values: Array<string> = Object.values(myEnum);
        for (const enumValue of values) {
            if (value == enumValue) {
                return enumValue as keyof TEnum;
            }
        }

        return fallback;
    }

    public static dtoToEnum<TEnum extends { [index: string]: string }>(myEnum: TEnum, value: string|undefined, fallback: keyof TEnum): keyof TEnum {
        return this.stringValueToEnum(myEnum, value, fallback);
    }

    public static initObjectFromPartial<TObject>(me: TObject, init?: Partial<TObject>): void {
        if (init) {
            Object.assign(me, init);
        }
    }

    public static objectsEqual<T1, T2>(object1: T1, object2: T2): boolean {
        return this.objectsEqualReturnFirstMismatchedProperty(object1, object2) === undefined;
    }

    public static objectsEqualReturnFirstMismatchedProperty<T1, T2>(object1: T1, object2: T2): string|undefined {
        const type1: string = typeof object1;
        const type2: string = typeof object1;
        if (type1 != type2) {
            return "type-mismatch";
        }

        if (type1 != "object") {
            return object1 as unknown == object2 as unknown ? undefined : "basic-type-value";
        }
        if (object1 === null || object2 === null || object1 === undefined || object2 === undefined) {
            return object1 as unknown == object2 as unknown ? undefined : "object-one-empty";
        }

        const propertiesObject1: Array<keyof T1> = Object.keys(object1) as Array<keyof typeof object1>;
        const propertiesObject2: Array<keyof T2> = Object.keys(object2) as Array<keyof typeof object2>;
        if (propertiesObject1.length !== propertiesObject2.length) {
            return "properties-length-mismatch";
        }
        for (const key of propertiesObject1) {
            if (!Object.prototype.hasOwnProperty.call(object2, key)) {
                return key as string;
            }
            const isEqual: boolean = object1[key] as unknown === object2[key as unknown as keyof T2] as unknown;
            if (!isEqual) {
                if (typeof object1[key] == "object") {
                    const subKey: string|undefined = this.objectsEqualReturnFirstMismatchedProperty(object1[key], object2[key as unknown as keyof T2]);
                    if (subKey) {
                        return `${key as string}.${subKey}`;
                    }
                } else {
                    return key as string;
                }
            }
        }
        return undefined;
    }

    public static expectNever(argument: never): void {
        TypeScriptHelper.touch(argument);
    }

    public static async base64StringToBlob(base64: string, mimeType?: string): Promise<Blob> {
        const response: Response = mimeType ? await fetch(`data:${mimeType};base64,${base64}`) : await fetch(base64);
        return response.blob();
    }
}
