/* eslint-disable max-classes-per-file */
import { Directive, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";

import { EventHelper } from "../helpers/event-helper";
import { IocService } from "../services/ioc/ioc.service";
import { KeyboardService } from "../services/keyboard/keyboard.service";
import { ShortcutListener } from "../services/keyboard/shortcut-listener";

/**
 * Component base class with comfort functionality, especially for subscribing to events.
 */
@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy {
    private readonly keyboardService?: KeyboardService = IocService.instance?.resolve(KeyboardService);

    private shortcutListener?: ShortcutListener;

    protected destroySubject: Subject<void> = EventHelper.createDestroySubject();

    private keyboardShortcuts: Array<KeyboardShortcut> = [];

    public ngOnInit(): void {
        this.shortcutListener = this.keyboardService?.registerListener(this.handleShortcut.bind(this));

        this.componentInit();
    }

    public ngOnDestroy(): void {
        EventHelper.triggerDestroy(this.destroySubject);

        this.shortcutListener?.unregister();
        this.componentDestroy();
    }

    protected abstract componentInit(): void;

    protected abstract componentDestroy(): void;

    /**
     * Subscribe to an EventEmitter, Observable or Subject. It gets unsubscribed automatically after the component has been destroyed, so there is no cleanup necessary.
     * It does not matter who's the owner of the Observable/Event, you can subscribe to services, components, etc.
     *
     * @param event - The event.
     * @param handler - The handler has to be a method within the component, because it gets bound to `this`.
     * @returns The subscription.
     */
    protected subscribe<TEventData>(event: EventEmitter<TEventData>|Observable<TEventData>, handler: (eventData: TEventData) => void): Subscription {
        return EventHelper.subscribeUntilDestroyed(event, handler, this.destroySubject, this);
    }

    protected unsubscribe(subscription?: Subscription): undefined {
        return EventHelper.unsubscribe(subscription);
    }

    private handleShortcut(event: KeyboardEvent): void {
        const matches: Array<KeyboardShortcut> = this.keyboardShortcuts.filter((item: KeyboardShortcut) => item.shift == event.shiftKey && item.key == event.key?.toUpperCase());
        for (const match of matches) {
            if (match.handler()) {
                event.stopPropagation();
                event.preventDefault();
                return;
            }
        }
    }

    public registerShortcut(key: string, shift: boolean, handler: () => boolean): void {
        this.keyboardShortcuts.push(new KeyboardShortcut(key.toUpperCase(), shift, handler.bind(this)));
    }
}

class KeyboardShortcut {
    constructor(key: string, shift: boolean, handler: () => boolean) {
        this.key = key;
        this.shift = shift;
        this.handler = handler;
    }

    public key: string;

    public shift: boolean;

    public handler: () => boolean;
}
