import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";

import { environment } from "../../../../environments/environment";
import { AsyncHelper } from "../../../base/helpers/async-helper";
import { PerformanceMeasurement } from "../../../base/helpers/performance-measurement";
import { StorageKeys } from "./storage-keys";

/**
 * Storage service to persist data.
 */
@Injectable({
    providedIn: "root"
})
export class StorageService {
    constructor(
        private readonly storage: Storage
    ) {
    }

    private readonly groupSeparator: string = "/";

    public static readonly keepAfterLogout: Array<StorageKeys> = [StorageKeys.deviceId, StorageKeys.apiUrl, StorageKeys.leftHandedMode];

    private storageInstance!: Storage;

    private keys: Set<string> = new Set<string>();

    private loadingKeys: boolean = false;

    private keysInitialized: boolean = false;

    public async initialize(): Promise<void> {
        this.storageInstance = await this.storage.create();
    }

    public async clearAfterLogout(): Promise<void> {
        if (!environment.removeDataOnLogoutByDefault) {
            await this.delete(StorageKeys.activeAccount);
            await this.delete(StorageKeys.accounts);
            await this.delete(StorageKeys.activePermissions);
            await this.delete(StorageKeys.activePersonIdentifier);
            await this.delete(StorageKeys.sessionProcess);
            await this.delete(StorageKeys.sessionProject);
            await this.delete(StorageKeys.sessionDocument);
            await this.delete(StorageKeys.password);
            await this.delete(StorageKeys.username);
        } else {
            const keys: Array<string> = await this.allKeys();
            for (const key of keys) {
                if (!StorageService.keepAfterLogout.includes(key as StorageKeys)) {
                    await this.delete(key as StorageKeys);
                }
            }
        }
        await this.loadAllKeys();
    }

    public getGroupKey(groupKey: StorageKeys, identifier: string): string {
        return `${groupKey}${this.groupSeparator}${identifier}`;
    }

    public async get<T>(key: StorageKeys, defaultValue: T|undefined = undefined): Promise<T|undefined> {
        return this.getByRawKey(key, defaultValue);
    }

    public async getByRawKey<T>(key: string, defaultValue: T|undefined = undefined): Promise<T|undefined> {
        try {
            const item: any = await this.storageInstance.get(key) as unknown;
            if (!item) {
                return defaultValue;
            }
            return item;
        } catch (error) {
            console.error(error);
            return defaultValue;
        }
    }

    public async getAllByGroupKey<T>(groupKey: StorageKeys): Promise<Array<T>> {
        const keyPrefix: string = this.getGroupKey(groupKey, "");
        return this.getAllByKeyPrefix(keyPrefix);
    }

    public async getAllByGroupKeyWithKeys<T>(groupKey: StorageKeys): Promise<Map<string, T>> {
        const keyPrefix: string = this.getGroupKey(groupKey, "");
        return this.getAllByKeyPrefixWithKeys(keyPrefix);
    }

    public async getAllByKeyPrefix<T>(keyPrefix: string): Promise<Array<T>> {
        const result: Array<T> = [];
        try {
            const keys: Array<string> = (await this.allKeys()).filter((value: string) => value.startsWith(keyPrefix));
            for (const key of keys) {
                const item: T = await this.storageInstance.get(key) as T;
                if (item) {
                    result.push(item);
                }
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    public async getAllByKeyPrefixWithKeys<T>(keyPrefix: string): Promise<Map<string, T>> {
        const result: Map<string, T> = new Map<string, T>();
        try {
            const keys: Array<string> = (await this.allKeys()).filter((value: string) => value.startsWith(keyPrefix));
            for (const key of keys) {
                const item: T = await this.storageInstance.get(key) as T;
                if (item) {
                    result.set(key, item);
                }
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    public async getGroupItem<T>(groupKey: StorageKeys, identifier: string, defaultValue: T|undefined = undefined): Promise<T|undefined> {
        try {
            const key: string = this.getGroupKey(groupKey, identifier);
            const item: any = await this.storageInstance.get(key) as unknown;
            if (!item) {
                return defaultValue;
            }
            return item;
        } catch (error) {
            console.error(error);
            return defaultValue;
        }
    }

    public async getGroupItemsByKeyPrefix<T>(groupKey: StorageKeys, identifier: string): Promise<Array<T>> {
        const keyPrefix: string = this.getGroupKey(groupKey, identifier);
        return this.getAllByKeyPrefix<T>(keyPrefix);
    }

    public async set(key: StorageKeys, value: any): Promise<boolean> {
        try {
            await this.storageInstance.set(key, value);
            this.keys.add(key);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    public async setGroupItem(groupKey: StorageKeys, identifier: string, value: any): Promise<boolean> {
        try {
            const key: string = this.getGroupKey(groupKey, identifier);
            await this.storageInstance.set(key, value);
            this.keys.add(key);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    public async delete(key: StorageKeys, identifier?: string): Promise<void> {
        return this.deleteByRawKey(identifier ? this.getGroupKey(key, identifier) : key);
    }

    public async deleteGroupItem(groupKey: StorageKeys, identifier: string): Promise<void> {
        const key: string = this.getGroupKey(groupKey, identifier);
        return this.deleteByRawKey(key);
    }

    public async deleteByRawKey(key: string): Promise<void> {
        try {
            this.keys.delete(key);
            await this.storageInstance.remove(key);
        } catch (error) {
            console.error(error);
        }
    }

    public async clearAll(): Promise<void> {
        try {
            await this.storageInstance.clear();
            this.keys.clear();
        } catch (error) {
            console.error(error);
        }
    }

    public async clearGroup(groupKey: StorageKeys): Promise<void> {
        const keyPrefix: string = this.getGroupKey(groupKey, "");
        const keys: Array<string> = (await this.allKeys()).filter((value: string) => value.startsWith(keyPrefix));
        try {
            for (const key of keys) {
                this.keys.delete(key);
                await this.deleteByRawKey(key);
            }
        } catch (error) {
            console.error(error);
        }
    }

    public async count(): Promise<number> {
        return this.keys.size;
    }

    private async waitForKeysLoaded(): Promise<void> {
        while (this.loadingKeys) {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            await AsyncHelper.sleep(10);
        }
    }

    public async allKeys(): Promise<Array<string>> {
        await this.waitForKeysLoaded();

        if (!this.keysInitialized) {
            this.keysInitialized = true;
            await this.loadAllKeys();
        }
        return Array.from(this.keys.keys());
    }

    private async loadAllKeys(): Promise<void> {
        this.loadingKeys = true;
        try {
            PerformanceMeasurement.start("loadingStorageKeys");
            const keys: Array<string> = await this.storageInstance.keys();
            const set: Set<string> = new Set<string>();
            for (const key of keys) {
                set.add(key);
            }
            this.keys = set;
            PerformanceMeasurement.stop("loadingStorageKeys", `${keys.length} keys`);
        } finally {
            this.loadingKeys = false;
        }
    }

    public async dump(): Promise<object> {
        const result: any = {};

        const keys: Array<string> = await this.allKeys();
        for (const key of keys) {
            result[key] = await this.getByRawKey<object>(key);
        }

        return result;
    }
}
