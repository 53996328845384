<ion-header>
    <ion-toolbar>
        <ion-title i18n="@@devices.recordAudioTitle">Record audio</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-button (click)="startRecording()" *ngIf="!isRecording"><fa-icon [icon]="appIcons.audioRecordingMic"></fa-icon>&nbsp;<ng-container i18n="@@devices.startRecordAudioButton">Start recording</ng-container></ion-button>
    <ion-button (click)="stopRecording()" *ngIf="isRecording"><fa-icon [icon]="appIcons.audioRecordingStop"></fa-icon>&nbsp;<ng-container i18n="@@devices.stopRecordAudioButton">Stop recording</ng-container></ion-button>
    <ion-button (click)="playRecording()" *ngIf="audioRecording && audioRecording.value"><fa-icon [icon]="appIcons.audioRecordingPlay"></fa-icon>&nbsp;<ng-container i18n="@@devices.playAudioRecordingButton">Play</ng-container></ion-button>
</ion-content>
<ion-footer>
    <ion-buttons>
        <ion-button (click)="close()" i18n="@@dialog.genericCloseButton">Close</ion-button>
    </ion-buttons>
</ion-footer>
