/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type QualityAssuranceStatusDto = 'unknown' | 'accepted' | 'rejected' | 'implicitlyAccepted' | 'rejectionIgnored';

export const QualityAssuranceStatusDto = {
    Unknown: 'unknown' as QualityAssuranceStatusDto,
    Accepted: 'accepted' as QualityAssuranceStatusDto,
    Rejected: 'rejected' as QualityAssuranceStatusDto,
    ImplicitlyAccepted: 'implicitlyAccepted' as QualityAssuranceStatusDto,
    RejectionIgnored: 'rejectionIgnored' as QualityAssuranceStatusDto
};

