import { Injectable } from "@angular/core";

import { TemplateVersionDto } from "../../../generated/api";
import { AppException } from "../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../global/frontend-errors";
import { TemplateVersionTechnicalIdentifier } from "../../identifiers/template-version-technical-identifier";
import { DocumentsApiService } from "../api/documents-api.service";
import { SessionService } from "../session/session.service";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";

/**
 * Service to work with templates.
 */
@Injectable({
    providedIn: "root"
})
export class TemplateService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly documentsApiService: DocumentsApiService,
        private readonly storageService: StorageService
    ) {
    }

    public async getTemplateDtos(cached: boolean): Promise<Array<TemplateVersionDto>>|never {
        this.sessionService.requiresAccount();

        const cachedTemplates: Array<TemplateVersionDto>|undefined = await this.storageService.getAllByGroupKey(StorageKeys.groupTemplateVersion) as Array<TemplateVersionDto>;

        if (cached) {
            return cachedTemplates ?? [];
        }

        const templates: Array<TemplateVersionDto>|undefined = await this.documentsApiService.listTemplateVersions();
        if (!templates || templates.length <= 0) {
            throw new AppException(FrontendErrors.FE15UnableToFetchTemplates, $localize`:@@exception.fe15UnableToFetchTemplates:Unable to fetch templates from server.`);
        }

        // Remove all cached templates that are not in the server list
        for (const cachedTemplate of cachedTemplates) {
            const foundTemplate: TemplateVersionDto|undefined = templates.find((template: TemplateVersionDto) => template.id === cachedTemplate.id);
            if (!foundTemplate) {
                await this.storageService.deleteGroupItem(StorageKeys.groupTemplateVersion, `${cachedTemplate.id}`);
            }
        }

        // Save templates to storage
        for (const template of templates) {
            if (template.id) {
                await this.saveToStorage(template.id as TemplateVersionTechnicalIdentifier, template);
            }
        }
        return templates;
    }

    public async getTemplateDto(identifier: TemplateVersionTechnicalIdentifier): Promise<TemplateVersionDto> {
        let template: TemplateVersionDto|undefined = await this.loadFromStorage(identifier);
        if (!template) {
            await this.getTemplateDtos(false);
            template = await this.loadFromStorage(identifier);
        }
        if (!template) {
            throw new AppException(FrontendErrors.FE18UnableGetTemplateWithId, $localize`:@@exception.fe18UnableGetTemplateWithId:Unable to get the template with id ${identifier} from the server.`);
        }
        return template;
    }

    private async loadFromStorage(identifier: TemplateVersionTechnicalIdentifier): Promise<TemplateVersionDto|undefined> {
        return this.storageService.getGroupItem(StorageKeys.groupTemplateVersion, `${identifier}`);
    }

    private async saveToStorage(identifier: TemplateVersionTechnicalIdentifier, templateVersion: TemplateVersionDto): Promise<void> {
        await this.storageService.setGroupItem(StorageKeys.groupTemplateVersion, `${identifier}`, templateVersion);
    }
}
