import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";

/**
 * Options for {@link ManualComponent}.
 */
export class ManualComponentOptions {
    constructor(init?: Partial<ManualComponentOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public language?: string;
    public tag?: string;
    public uri?: string;
}
