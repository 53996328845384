import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";

import { DialogService } from "../../../base/services/dialog/dialog.service";
import { AccountDto } from "../../../generated/api";
import { AppIcons } from "../../services/icons/app-icons";
import { SessionService } from "../../services/session/session.service";
import { AccountSelectComponent } from "../account-select/account-select.component";
import { AccountSelectOptions } from "../account-select/account-select-options";

/**
 * Component to switch available accounts/companies.
 */
@Component({
    selector: "app-account-switch-popover",
    templateUrl: "./account-switch-popover.component.html",
    styleUrls: ["./account-switch-popover.component.scss"]
})
export class AccountSwitchPopoverComponent implements OnInit {
    constructor(
        private readonly sessionService: SessionService,
        private readonly dialogService: DialogService,
        private readonly popoverController: PopoverController) {
    }
    public appIcons: typeof AppIcons = AppIcons;
    public accounts: Array<AccountDto> = [];
    public selectedAccount?: AccountDto;

    public switchingTo?: AccountDto;

    public ngOnInit(): void {
        this.updateAccounts();
    }

    private updateAccounts(): void {
        this.accounts = this.sessionService.accounts ?? [];
        this.selectedAccount = this.sessionService.activeAccount;
    }

    public manageAccounts(): void {
        const options: AccountSelectOptions = {
            createNew: false
        };
        this.dialogService.showModal({ component: AccountSelectComponent, payload: { options: options } }).then();
        this.close();
    }

    public async selectAccount(account: AccountDto): Promise<void> {
        if (this.switchingTo || !account.id) {
            return;
        }

        this.switchingTo = account;
        try {
            await this.sessionService.setAccount(account.id);
            this.close();
        } catch (error) {
            // TODO: Proper error handling
            const typedError: Error = error as Error;
            await this.dialogService.showError(typedError);
        } finally {
            this.switchingTo = undefined;
        }
    }

    public close(): void {
        this.popoverController.dismiss().then();
    }
}
