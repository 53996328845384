<ion-app [class.focusMode]="appState.focusMode" [class.leftHanded]="leftHanded">
    <business-page-header *ngIf="!appState.focusMode"></business-page-header>
    <ion-split-pane (ionSplitPaneVisible)="menuVisibilityChanged($event)" contentId="main-content">
        <ion-menu (ionWillClose)="menuClosed()" (ionWillOpen)="menuOpened()" contentId="main-content" id="menuElement" type="overlay">
            <ion-content #menuHost>
                <business-menu [menuItem]="mainMenu" [menuOptions]="menuOptions"></business-menu>
                <ion-buttons class="menuActions">
                    <ion-button (click)="swapLeftHandedMode()" *ngIf="!leftHanded">
                        <fa-icon [icon]="appIcons.leftHandedMode"></fa-icon>&nbsp;<ng-container i18n="@@menuActions.switchToLeftHandedMode">Left-handed mode</ng-container>
                    </ion-button>
                    <ion-button (click)="swapLeftHandedMode()" *ngIf="leftHanded">
                        <ng-container i18n="@@menuActions.switchToRightHandedMode">Right-handed mode</ng-container>&nbsp;<fa-icon [icon]="appIcons.rightHandedMode"></fa-icon>
                    </ion-button>
                </ion-buttons>
            </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>

    <button (click)="openFocusMenu($event)" *ngIf="appState.focusMode || appState.followMode" aria-label="Focus menu" class="focusModeButton" color="primary" mat-mini-fab>
        <fa-icon [icon]="appIcons.appViewSettings" size="lg"></fa-icon>
    </button>
</ion-app>
