import { Injectable } from "@angular/core";

import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { ResponseBodyProcessOverviewDto } from "../../../generated/api";
import { LoadStrategies } from "../../common/load-strategies";
import { errorResult, SafeResult, successResult } from "../../common/safe-result";
import { AppException } from "../../entities/exceptions/app-exception";
import { Process } from "../../entities/projects-processes/process";
import { FrontendErrors } from "../../global/frontend-errors";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { ProcessBusinessIdentifier } from "../../identifiers/process-business-identifier";
import { ProjectTechnicalIdentifier } from "../../identifiers/project-technical-identifier";
import { ProcessesApiService } from "../api/processes-api.service";
import { DocumentsService } from "../documents/documents.service";
import { CoreDataService } from "../master-data/core-data-service/core-data.service";

/**
 * Service to work with PDFs.
 */
@Injectable({
    providedIn: "root"
})
export class ProcessesService {
    constructor(
        private readonly coreDataService: CoreDataService,
        private readonly processesApiService: ProcessesApiService,
        private readonly documentsService: DocumentsService
    ) {
    }

    public async getProcessesOverview(loadStrategy: LoadStrategies): Promise<SafeResult<Array<Process>, AppException>> {
        return this.getProcesses(undefined, loadStrategy);
    }

    public async getProcesses(projectTechnicalIdentifier: ProjectTechnicalIdentifier|undefined, loadStrategy: LoadStrategies): Promise<SafeResult<Array<Process>, AppException>> {
        try {
            const dtos: ResponseBodyProcessOverviewDto = await this.processesApiService.getProcesses(projectTechnicalIdentifier);
            const result: Array<Process> = Api1Converter.dtoToProcessesList(dtos);
            for (const process of result) {
                await this.updateLinkedEntities(process, loadStrategy);
                await this.updateLinkedDocuments(process, loadStrategy);
                await this.coreDataService.updateEntityCache(process);
            }
            return successResult(result);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE49UnableToLoadProcesses, $localize`:@@exception.fe49UnableToLoadProcesses:Unable to load the processes.`, error as Error));
        }
    }

    public async updateLinkedEntities(process: Process, loadStrategy: LoadStrategies): Promise<void> {
        if (process.linkedAssignee && !process.linkedAssignee.entity) {
            process.linkedAssignee.entity = await this.coreDataService.loadEntity(process.linkedAssignee.identifier, process.linkedAssignee.entityType, loadStrategy == LoadStrategies.serverOnly ? LoadStrategies.serverOnly : LoadStrategies.preferCached);
        }

        for (const linkedEntity of process.linkedEntities) {
            if (linkedEntity.identifier.technicalIdentifier && !linkedEntity.entity) {
                linkedEntity.entity = await this.coreDataService.loadEntity(linkedEntity.identifier, linkedEntity.entityType, loadStrategy == LoadStrategies.serverOnly ? LoadStrategies.serverOnly : LoadStrategies.preferCached);
                if (linkedEntity.entity) {
                    linkedEntity.identifier = linkedEntity.entity.identifier;
                }
            }
        }
    }

    public async updateLinkedDocuments(process: Process, _loadStrategy: LoadStrategies): Promise<void> {
        for (const linkedDocument of process.linkedDocuments) {
            if (linkedDocument.identifier.technicalIdentifier && !linkedDocument.entity) {
                linkedDocument.entity = await this.documentsService.getDocument(linkedDocument.identifier.businessIdentifier as DocumentBusinessIdentifier);
                if (linkedDocument.entity) {
                    linkedDocument.identifier = linkedDocument.entity.identifier;
                }
            }
        }
    }

    public async updateProcess(process: Process, archive: boolean = false): Promise<SafeResult<Process, AppException>> {
        try {
            const result: Process = await this.coreDataService.saveEntity<Process>(process, archive);
            return successResult(result);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE57UnableToSaveProcess, $localize`:@@exception.fe57UnableToSaveProcess:Unable to save process with id ${process.identifier.businessIdentifier}:id:.`, error));
        }
    }

    public async deleteProcess(businessIdentifier: ProcessBusinessIdentifier): Promise<SafeResult<void, AppException>> {
        try {
            await this.processesApiService.deleteProcess(businessIdentifier);
            return successResult(undefined);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE63UnableToDeleteProcess, $localize`:@@exception.fe63UnableToDeleteProcess:Unable to delete process with identifier "${businessIdentifier}".`, error));
        }
    }
}
