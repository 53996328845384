import { Injectable } from "@angular/core";
import { MenuController } from "@ionic/angular";

import { environment } from "../../../../environments/environment";
import { ArrayHelper } from "../../../base/helpers/array-helper";
import { EventHelper } from "../../../base/helpers/event-helper";
import { AttachmentTypes } from "../../entities/attachments/attachment-types";
import { AuditDocument } from "../../entities/documents/audit-document";
import { DocumentQuestion } from "../../entities/documents/document-question";
import { DocumentSection } from "../../entities/documents/document-section";
import { DocumentTypes } from "../../entities/documents/document-types";
import { DocumentValueContainer } from "../../entities/documents/document-value-container";
import { QualityAssuranceStatus } from "../../entities/documents/quality-assurance-status";
import { EntityTypes } from "../../entities/entity-types";
import { QualityAssuranceItem } from "../../entities/quality-assurance/quality-assurance-item";
import { EntityHelper } from "../../helpers/entity-helper";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { Identifier } from "../../identifiers/identifier";
import { ProcessBusinessIdentifier } from "../../identifiers/process-business-identifier";
import { ProjectBusinessIdentifier } from "../../identifiers/project-business-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";
import { Pages } from "../../pages/pages";
import { DocumentUpdatedEventArguments } from "../documents/document-updated-event-arguments";
import { DocumentsService } from "../documents/documents.service";
import { AppIcons } from "../icons/app-icons";
import { IconsService } from "../icons/icons.service";
import { DocumentNavigationService } from "../navigation/document/document-navigation.service";
import { NavigatedEventData } from "../navigation/navigated-event-data";
import { NavigationService } from "../navigation/navigation.service";
import { QualityAssuranceService } from "../quality-assurance/quality-assurance.service";
import { MenuItem } from "./menu-item";
import { MenuOptions } from "./menu-options";

/**
 * The menu service lets you dynamically adjust the menu structure to add important actions or show a table of contents.
 */
@Injectable({
    providedIn: "root"
})
export class MenuService {
    constructor(
        private readonly navigationService: NavigationService,
        private readonly documentNavigationService: DocumentNavigationService,
        private readonly iconsService: IconsService,
        private readonly qualityAssuranceService: QualityAssuranceService,
        private readonly documentsService: DocumentsService
    ) {
        this.reset();

        EventHelper.subscribe(this.navigationService.navigated, this.navigated, this);
        EventHelper.subscribe(this.documentsService.documentUpdatedInternally, this.documentUpdated, this);
        EventHelper.subscribe(this.documentsService.qualityAssuranceItemsUpdated, this.documentQualityAssuranceItemsUpdated, this);
    }

    public readonly mainMenu: MenuItem = new MenuItem({ isTopItem: true });

    public menuOptions: MenuOptions = new MenuOptions();

    private readonly defaultMainMenu: MenuItem = new MenuItem({
        isTopItem: true,
        children: [
            new MenuItem({
                title: $localize`:@@menu.overview:Overview` as string,
                targetPage: Pages.dashboard,
                icon: AppIcons.menuOverview
            }),
            new MenuItem({
                title: $localize`:@@menu.projectsList:Projects` as string,
                targetPage: Pages.coreDataList,
                icon: AppIcons.menuProjectsList,
                targetEntity: EntityTypes.project,
                targetRoute: [EntityTypes.project]
            }),
            new MenuItem({
                title: $localize`:@@menu.personsList:Persons` as string,
                targetPage: Pages.coreDataList,
                icon: AppIcons.menuPersonsList,
                targetEntity: EntityTypes.person,
                targetRoute: [`${EntityTypes.person}`]
            }),
            new MenuItem({
                title: $localize`:@@menu.companiesList:Companies` as string,
                targetPage: Pages.coreDataList,
                icon: AppIcons.menuCompaniesList,
                targetEntity: EntityTypes.company,
                targetRoute: [`${EntityTypes.company}`]
            }),
            new MenuItem({
                title: $localize`:@@menu.buildingComplexesList:Buildings` as string,
                targetPage: Pages.coreDataList,
                icon: AppIcons.menuBuildingComplexesList,
                targetEntity: EntityTypes.buildingComplex,
                targetRoute: [EntityHelper.entityTypeToUrlComponent(EntityTypes.buildingComplex)]
            })
        ]
    });

    private currentPage?: string = undefined;

    private currentRoute: Array<any> = [];

    private currentDocument?: AuditDocument;

    private menuController!: MenuController;

    public setMenuController(menuController: MenuController): void {
        this.menuController = menuController;
    }

    public showMenu(_visible: boolean): void {
        // For now, we always show the menu
        this.menuOptions.menuVisible = true;
    }

    public applyFromDocument(projectBusinessIdentifier: ProjectBusinessIdentifier, processBusinessIdentifier: ProcessBusinessIdentifier, document: AuditDocument, customTitle?: string): void {
        this.currentDocument = document;

        const rootContainer: MenuItem = new MenuItem({
            title: document.displayTitle,
            isTopItem: true
        });

        const backMenu: MenuItem = new MenuItem({
            title: $localize`:@@menu.dashboard:Dashboard` as string,
            icon: AppIcons.menuGoToOverview,
            isHeadline: false,
            targetPage: Pages.dashboard,
            targetRoute: []
        });
        rootContainer.addChild(backMenu);

        rootContainer.addChild(new MenuItem({
            title: $localize`:@@menu.projectOverview:Project overview` as string,
            icon: AppIcons.entityProjectRegular,
            isHeadline: false,
            targetPage: Pages.project,
            targetRoute: [`${projectBusinessIdentifier}`]
        }));

        rootContainer.addChild(new MenuItem({
            title: $localize`:@@menu.processOverview:Process overview` as string,
            icon: AppIcons.entityProcessRegular,
            isHeadline: false,
            targetPage: Pages.process,
            targetRoute: [`${projectBusinessIdentifier}`, `${processBusinessIdentifier}`]
        }));

        rootContainer.addChild(new MenuItem({
            isSeparator: true
        }));

        const documentMenu: MenuItem = new MenuItem({
            title: customTitle || document.displayTitle,
            isHeadline: true,
            hasSearchBar: false,
            placeholder: $localize`:@@menu.searchDocumentPlaceholder:Search in document` as string,
            targetEntity: EntityTypes.document,
            targetPage: Pages.document,
            targetRoute: this.documentNavigationService.getRouteToDocument(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier)
        });
        rootContainer.addChild(documentMenu);

        const commonMenu: MenuItem = new MenuItem({
            title: $localize`:@@menu.documentCommon:Common data` as string,
            icon: AppIcons.documentCommonData,
            targetPage: Pages.document,
            targetRoute: this.documentNavigationService.getRouteToCommonData(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier)
        });
        documentMenu.addChild(commonMenu);

        if (environment.featureGpt) {
            const aiMenu: MenuItem = new MenuItem({
                title: $localize`:@@menu.aiAnalysis:AI Analysis` as string,
                icon: AppIcons.entityAiAnalysisRegular,
                targetPage: Pages.document,
                targetRoute: this.documentNavigationService.getRouteToAiAnalysis(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier)
            });
            documentMenu.addChild(aiMenu);
        }

        // Add master data items
        if (document.linkedEntities.length > 0) {
            const masterDataMenu: MenuItem = new MenuItem({
                title: $localize`:@@menu.coreData:Core data` as string,
                icon: AppIcons.documentMasterData
            });
            documentMenu.addChild(masterDataMenu);

            for (const entityLink of document.linkedEntities) {
                const propertyName: string|undefined = entityLink.identifierPairPropertyName;
                masterDataMenu.addPartialChild({
                    title: [EntityTypes.building].includes(entityLink.entityType)
                        ? `${entityLink.displayTitle} - ${entityLink.linkDescription}`
                        : entityLink.linkDescription || (entityLink.entity?.displayTitle ?? Identifier.identifierToReadableString(entityLink.identifier)),
                    icon: this.iconsService.getEntityIconDuotone(entityLink.entityType),
                    linkedEntity: entityLink,
                    targetEntity: entityLink.entityType,
                    targetPage: Pages.document,
                    targetRoute: this.documentNavigationService.getRouteToDocumentEntity(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier, entityLink.entityType, propertyName, entityLink.identifier.businessIdentifier)
                });
            }
        }

        // Add sampling plan
        if (document.type == DocumentTypes.buildingWaterWalkthroughInspection) {
            const samplingPlanMenu: MenuItem = new MenuItem({
                title: $localize`:@@menu.SamplingPlan:Sampling plan` as string,
                icon: AppIcons.entitySamplingPlanDuotone,
                targetPage: Pages.document,
                targetRoute: this.documentNavigationService.getRouteToSamplingPlan(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier)
            });
            documentMenu.addChild(samplingPlanMenu);
        }

        for (const chapter of document.template.sections) {
            const chapterMenuItem: MenuItem = documentMenu.addPartialChild({
                identifier: chapter.templateIdentifier,
                title: chapter.title,
                targetPage: Pages.document,
                targetEntity: EntityTypes.chapter,
                targetRoute: this.documentNavigationService.getRouteToDocument(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier, chapter.templateIdentifier),
                icon: this.iconsService.getDocumentSectionIcon(chapter.icon)
            });

            for (const section of chapter.sections) {
                chapterMenuItem.addPartialChild({
                    identifier: section.templateIdentifier,
                    title: section.title,
                    targetPage: Pages.document,
                    targetEntity: EntityTypes.section,
                    targetRoute: this.documentNavigationService.getRouteToDocument(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier, chapter.templateIdentifier, section.templateIdentifier),
                    icon: this.iconsService.getDocumentSectionIcon(section.icon)
                });
            }
        }

        // Photo gallery
        const galleryMenu: MenuItem = new MenuItem({
            title: $localize`:@@menu.imageOverview:Image overview` as string,
            icon: AppIcons.documentImageOverview,
            targetPage: Pages.document,
            targetRoute: this.documentNavigationService.getRouteToAttachments(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier, AttachmentTypes.image),
            allowNewTab: true
        });
        galleryMenu.targetParameters = { focus: "true", follow: "true" };
        documentMenu.addChild(galleryMenu);

        // Review
        const reviewMenu: MenuItem = new MenuItem({
            title: $localize`:@@menu.documentReview:Document review` as string,
            icon: AppIcons.documentReview,
            targetPage: Pages.document,
            targetRoute: this.documentNavigationService.getRouteToReview(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier),
            allowNewTab: false,
            warningBadgeCount: document.reviewSummary ? (document.reviewSummary.match(/\|#\|/g) || []).length : 0
        });
        documentMenu.addChild(reviewMenu);

        // Revisions
        const revisionsMenu: MenuItem = new MenuItem({
            title: $localize`:@@menu.revisions:Document history` as string,
            icon: AppIcons.documentRevisions,
            targetPage: Pages.document,
            targetRoute: this.documentNavigationService.getRouteToRevisions(projectBusinessIdentifier, processBusinessIdentifier, document.identifier.businessIdentifier),
            allowNewTab: true
        });
        documentMenu.addChild(revisionsMenu);

        this.applyMainMenu(rootContainer);
        this.updateDocumentBadgeCount(document);
    }

    public updateDocumentBadgeCount(document: AuditDocument): void {
        const inReview: boolean = document.isInReview;

        const documentMenu: MenuItem = this.mainMenu.children.find((child: MenuItem) => child.targetEntity == EntityTypes.document)!;
        documentMenu.neutralBadgeCount = 0;
        documentMenu.successBadgeCount = 0;
        documentMenu.warningBadgeCount = 0;
        documentMenu.criticalBadgeCount = 0;
        for (const chapterMenuItem of documentMenu.children) {
            if (chapterMenuItem.targetEntity != EntityTypes.chapter) {
                continue;
            }
            chapterMenuItem.neutralBadgeCount = 0;
            chapterMenuItem.successBadgeCount = 0;
            chapterMenuItem.warningBadgeCount = 0;
            chapterMenuItem.criticalBadgeCount = 0;

            const chapter: DocumentSection = document.template.getSection(chapterMenuItem.identifier as SectionTemplateIdentifier);
            this.updateSectionBadgeCount(document, chapter, chapterMenuItem, inReview, true);

            for (const sectionMenuItem of chapterMenuItem.children) {
                if (!sectionMenuItem.identifier) {
                    continue;
                }
                const section: DocumentSection = document.template.getSection(sectionMenuItem.identifier as SectionTemplateIdentifier);
                sectionMenuItem.neutralBadgeCount = 0;
                sectionMenuItem.successBadgeCount = 0;
                sectionMenuItem.warningBadgeCount = 0;
                sectionMenuItem.criticalBadgeCount = 0;

                this.updateSectionBadgeCount(document, section, sectionMenuItem, inReview, true);

                sectionMenuItem.badgeIcon = sectionMenuItem.neutralBadgeCount <= 0 && !inReview ? AppIcons.menuBadgeDone : undefined;
                chapterMenuItem.neutralBadgeCount += sectionMenuItem.neutralBadgeCount;
                chapterMenuItem.successBadgeCount += sectionMenuItem.successBadgeCount;
                chapterMenuItem.warningBadgeCount += sectionMenuItem.warningBadgeCount;
            }
            chapterMenuItem.badgeIcon = chapterMenuItem.neutralBadgeCount <= 0 && !inReview ? AppIcons.menuBadgeDone : undefined;
            documentMenu.neutralBadgeCount += chapterMenuItem.neutralBadgeCount;
            documentMenu.successBadgeCount += chapterMenuItem.successBadgeCount;
            documentMenu.warningBadgeCount += chapterMenuItem.warningBadgeCount;
        }
        documentMenu.badgeIcon = !inReview && documentMenu.neutralBadgeCount <= 0 && !inReview ? AppIcons.menuBadgeDone : undefined;
    }

    /**
     * Resets the menu to the default menu.
     */
    public reset(): void {
        this.currentDocument = undefined;
        this.applyMainMenu(this.defaultMainMenu);
    }

    public applyMainMenu(menu: MenuItem): void {
        this.mainMenu.assign(menu);
        this.markActiveMenuItem(this.mainMenu, this.currentPage, this.currentRoute);
    }

    private navigated(eventData: NavigatedEventData): void {
        this.currentPage = eventData.page;
        this.currentRoute = eventData.route;
        this.markActiveMenuItem(this.mainMenu, eventData.page, eventData.route);

        this.menuController.close().then();
    }

    private documentUpdated(eventArgs: DocumentUpdatedEventArguments): void {
        if (eventArgs.document.identifier.businessIdentifier == this.currentDocument?.identifier.businessIdentifier) {
            this.updateDocumentBadgeCount(eventArgs.document);
        }
    }

    private markActiveMenuItem(menuItem: MenuItem, page: string|undefined, route: Array<any>): void {
        menuItem.open = menuItem.targetPage == page && ArrayHelper.arraysEqual(menuItem.targetRoute, route);
        menuItem.loading = false;
        for (const child of menuItem.children) {
            this.markActiveMenuItem(child, page, route);
        }
    }

    private updateSectionBadgeCount(document: AuditDocument, section: DocumentSection|DocumentQuestion, sectionMenuItem: MenuItem, inReview: boolean, includeAllChildren: boolean): void {
        for (const item of section.questions) {
            const valueContainer: DocumentValueContainer|undefined = document.instance.getValueContainer(item.templateIdentifier);
            const qualityAssuranceItem: QualityAssuranceItem|undefined = valueContainer ? document.qualityAssuranceItems.find((qaItem: QualityAssuranceItem) => valueContainer.questionIdentifier?.technicalIdentifier && qaItem.questionTechnicalIdentifier == valueContainer.questionIdentifier.technicalIdentifier) : undefined;
            switch (qualityAssuranceItem?.status) {
                case QualityAssuranceStatus.accepted:
                    sectionMenuItem.successBadgeCount++;
                    break;
                case QualityAssuranceStatus.rejected:
                    sectionMenuItem.warningBadgeCount++;
                    break;
            }
            sectionMenuItem.neutralBadgeCount += inReview ? 1 : document.instance.hasValue(item.templateIdentifier) ? 0 : 1;

            if (includeAllChildren) {
                this.updateSectionBadgeCount(document, item, sectionMenuItem, inReview, includeAllChildren);
            }
        }
    }

    private documentQualityAssuranceItemsUpdated(documentIdentifier: DocumentIdentifier): void {
        if (this.currentDocument && documentIdentifier.businessIdentifier == this.currentDocument.identifier.businessIdentifier) {
            this.updateDocumentBadgeCount(this.currentDocument);
        }
    }
}
