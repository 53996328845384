import { AuditDocument } from "../../entities/documents/audit-document";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { SectionIdentifier } from "../../identifiers/section-identifier";

/**
 * Event arguments for updated documents.
 */
export class DocumentUpdatedEventArguments {
    constructor(document: AuditDocument) {
        this.document = document;
    }

    public document: AuditDocument;

    public questionTemplateIdentifier?: QuestionTemplateIdentifier;
    public repeatableSectionIdentifier?: SectionIdentifier;
}
