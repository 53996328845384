/**
 * Helper class for files and blobs.
 */
export class FileHelper {
    public static blobUrlToArrayBuffer(blobUrl: string): Promise<ArrayBuffer> {
        return new Promise((resolve: (value: (PromiseLike<ArrayBuffer>|ArrayBuffer)) => void, reject: (reason?: any) => void) => {
            try {
                const request: XMLHttpRequest = new XMLHttpRequest();
                request.open("GET", blobUrl, true);
                request.responseType = "blob";
                request.onload = (): void => {
                    const reader: FileReader = new FileReader();
                    reader.readAsArrayBuffer(request.response);
                    reader.onload = (e: ProgressEvent<FileReader>): void => {
                        resolve(e.target?.result as ArrayBuffer);
                    };
                };
                request.send();
            } catch (error) {
                reject(error);
            }
        });
    }

    public static async blobUrlToBlob(blobUrl: string, mimeType: string = "application/octet-stream"): Promise<Blob> {
        const buffer: ArrayBuffer = await FileHelper.blobUrlToArrayBuffer(blobUrl);
        return new Blob([buffer], { type: mimeType });
    }

    public static mimeTypeToExtension(mimeType: string|undefined): string|undefined {
        switch (mimeType) {
            case "image/jpeg":
            case "image/jpg":
                return "jpg";
            case "image/png":
                return "png";
            case "image/webp":
                return "webp";
            case "application/pdf":
                return "pdf";
            default:
                return mimeType?.includes("/") ? mimeType.split("/").pop() : mimeType;
        }
    }
}
