import { Injectable } from "@angular/core";
import html2canvas from "html2canvas";

/**
 * Service to take screenshots.
 */
@Injectable({
    providedIn: "root"
})
export class ScreenshotService {
    private htmlDocument!: Document;

    public initialize(htmlDocument: Document): void {
        this.htmlDocument = htmlDocument;
    }

    public async takeScreenshotThroughGetDisplayMedia(): Promise<boolean> {
        try {
            const canvas: HTMLCanvasElement = document.createElement("canvas");
            const context: CanvasRenderingContext2D|null = canvas.getContext("2d");
            const video: HTMLVideoElement = document.createElement("video");
            if (!context) {
                return false;
            }

            const captureStream: MediaStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: false
            });
            video.srcObject = captureStream;
            context.drawImage(video, 0, 0, window.outerWidth, window.outerHeight);
            const frame: string = canvas.toDataURL("image/png");
            captureStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
            window.location.href = frame;

            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    public async takeScreenshot(canvas?: HTMLCanvasElement): Promise<HTMLCanvasElement|undefined> {
        try {
            return await html2canvas(this.htmlDocument.querySelector("html")!, {
                logging: false,
                canvas: canvas,
                ignoreElements: (node: Element) => node.nodeName === "IFRAME"
            });
        } catch (error) {
            console.error();
        }
    }
}
