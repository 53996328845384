import { environment } from "../../../environments/environment";
import { DialogButton } from "../../base/components/dialogs/basic-dialog/dialog-button";
import { DialogService } from "../../base/services/dialog/dialog.service";
import { Intent } from "../../base/services/intents/intent";
import { IocService } from "../../base/services/ioc/ioc.service";
import { HelpArticles } from "../global/help-articles";
import { AttachmentLoaderService } from "../services/attachments/attachment-loader.service";
import { DocumentQueuedUpdateService } from "../services/document-update/document-queued-update.service";
import { OnlineHelpService } from "../services/help/online-help.service";
import { SessionService } from "../services/session/session.service";

/**
 * Intent to log out the user.
 */
export class LogoutIntent implements Intent {
    constructor(
        private readonly iocService: IocService) {
    }

    public name: string = "LoginIntent";

    public identifier?: number;

    private readonly attachmentLoaderService: AttachmentLoaderService = this.iocService.resolve(AttachmentLoaderService);

    public cancel(): Promise<void> {
        return Promise.resolve(undefined);
    }

    public async execute(..._intentArguments: Array<any>): Promise<boolean> {
        const sessionService: SessionService = this.iocService.resolve(SessionService);
        const dialogService: DialogService = this.iocService.resolve(DialogService);
        const documentService: DocumentQueuedUpdateService = this.iocService.resolve(DocumentQueuedUpdateService);
        const helpService: OnlineHelpService = this.iocService.resolve(OnlineHelpService);

        const pendingItems: number = documentService.summary.pending + this.attachmentLoaderService.queueItemsCount;
        if (pendingItems && environment.removeDataOnLogoutByDefault) {
            enum Decisions {
                yes = "yes",
                no = "no",
                moreInfo = "moreInfo"
            }

            const newLine: string = "<br />";
            const decision: string|undefined = await dialogService.showAlert(
                $localize`:@@sessions.confirmLogout:Confirm logout`,
                $localize`:@@sessions.askLogoutPendingUpdates:There are pending changes that are not saved to the server. If you log out now, the changes are going to be lost. This cannot be undone.${newLine}:newLine:${newLine}:newLine:Do you really want to log out?`,
                $localize`:@@sessions.pendingUpdates:Pending updates: ${pendingItems}:pendingChanges:`,
                [
                    new DialogButton($localize`:@@sessions.confirmLogoutButton:Yes, lose changes and log out`, Decisions.yes),
                    new DialogButton($localize`:@@sessions.cancelLogoutButton:No, stay logged in`, Decisions.no),
                    new DialogButton($localize`:@@sessions.logoutHelpButton:More information`, Decisions.moreInfo)
                ]);
            if (!decision || decision == Decisions.no) {
                return true;
            }

            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (decision == Decisions.moreInfo) {
                helpService.openArticle(HelpArticles.ADT1LogoutWithPendingChanges);
                return true;
            }
        }

        const loading: HTMLIonLoadingElement = await dialogService.showLoading($localize`:@@app.loggingOut:Logging out, please wait...`);
        try {
            await sessionService.logOut();
        } finally {
            await loading?.dismiss();
        }

        return true;
    }
}
