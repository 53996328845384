import { Identifier } from "../../identifiers/identifier";
import { QuestionIdentifier } from "../../identifiers/question-identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { SectionIdentifier } from "../../identifiers/section-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";
import { Attachment } from "../attachments/attachment";
import { AttachmentFactory } from "../attachments/attachment-factory";
import { StorableEntity } from "../storable-entity";
import { DocumentAnswerComment } from "./document-answer-comment";
import { DocumentAnswerValue } from "./document-answer-value";
import { DocumentQuestionTypes } from "./document-question-types";
import { DocumentValueContainerStorageDto } from "./document-value-container-storage-dto";
import { QuestionSaveStates } from "./question-save-states";

/**
 * The entered/specified value for a document question.
 */
export class DocumentValueContainer implements StorableEntity<DocumentValueContainerStorageDto> {
    constructor(
        questionType: DocumentQuestionTypes,
        questionTemplateIdentifier: QuestionTemplateIdentifier,
        questionIdentifier?: QuestionIdentifier,
        sectionTemplateIdentifier?: SectionTemplateIdentifier,
        sectionIdentifier?: SectionIdentifier
    ) {
        this.questionType = questionType;
        this.questionTemplateIdentifier = questionTemplateIdentifier;
        this.questionIdentifier = questionIdentifier;
        this.sectionIdentifier = sectionIdentifier;
        this.sectionTemplateIdentifier = sectionTemplateIdentifier;
    }

    public questionType: DocumentQuestionTypes;

    public answers: Array<DocumentAnswerValue> = [];

    public questionTemplateIdentifier: QuestionTemplateIdentifier;

    public questionIdentifier?: QuestionIdentifier;

    public sectionTemplateIdentifier?: SectionTemplateIdentifier;

    public sectionIdentifier?: SectionIdentifier;

    public comment?: DocumentAnswerComment;

    public linkedAttachments: Array<Attachment> = [];

    public saveState: QuestionSaveStates = QuestionSaveStates.none;

    public synchronizationError?: string;

    public enabled?: boolean;

    public hasValue(): boolean {
        return this.answers.length > 0;
    }

    public toStorageDto(): DocumentValueContainerStorageDto {
        const dto: DocumentValueContainerStorageDto = new DocumentValueContainerStorageDto();

        dto.questionType = DocumentQuestionTypes[this.questionType];
        dto.questionTemplateIdentifier = this.questionTemplateIdentifier;
        dto.questionBusinessIdentifier = this.questionIdentifier?.businessIdentifier;
        dto.questionTechnicalIdentifier = this.questionIdentifier?.technicalIdentifier;
        dto.sectionTemplateIdentifier = this.sectionTemplateIdentifier;
        dto.sectionBusinessIdentifier = this.sectionIdentifier?.businessIdentifier;
        dto.sectionTechnicalIdentifier = this.sectionIdentifier?.technicalIdentifier;
        dto.saveState = QuestionSaveStates[this.saveState];
        dto.synchronizationError = this.synchronizationError;

        dto.comment = this.comment?.toStorageDto();

        dto.linkedAttachments = [];
        for (const linkedAttachment of this.linkedAttachments) {
            dto.linkedAttachments.push(linkedAttachment.toStorageDto());
        }

        dto.answers = [];
        for (const answer of this.answers) {
            dto.answers.push(answer.toStorageDto());
        }

        return dto;
    }

    public fromStorageDto(dto: DocumentValueContainerStorageDto|undefined): void {
        this.questionType = dto?.questionType ? dto.questionType as DocumentQuestionTypes : DocumentQuestionTypes.unknown;
        this.questionTemplateIdentifier = dto?.questionTemplateIdentifier as QuestionTemplateIdentifier;
        this.questionIdentifier = Identifier.create(dto?.questionBusinessIdentifier, dto?.questionTechnicalIdentifier);
        this.sectionTemplateIdentifier = dto?.sectionTemplateIdentifier as SectionTemplateIdentifier;
        this.sectionIdentifier = Identifier.create(dto?.sectionBusinessIdentifier, dto?.sectionTechnicalIdentifier);
        this.saveState = dto?.saveState ? dto.saveState as QuestionSaveStates : QuestionSaveStates.none;
        this.synchronizationError = dto?.synchronizationError;

        this.comment = this.comment ? this.comment : (dto?.comment ? new DocumentAnswerComment() : undefined);
        this.comment?.fromStorageDto(dto?.comment);

        this.linkedAttachments = [];
        for (const linkedAttachmentDto of dto?.linkedAttachments ?? []) {
            const linkedAttachment: Attachment = AttachmentFactory.createFromStorageDto(linkedAttachmentDto);
            this.linkedAttachments.push(linkedAttachment);
        }

        this.answers = [];
        for (const answerDto of dto?.answers ?? []) {
            const answer: DocumentAnswerValue = new DocumentAnswerValue();
            answer.fromStorageDto(answerDto);
            this.answers.push(answer);
        }
    }
}
