/**
 * Audit Backend API
 * API for the audit backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnswerTemplateDto } from './answerTemplate';


export interface QuestionTemplateDto { 
    readonly id?: number;
    readonly created?: string;
    readonly updated?: string;
    titleKey?: string;
    descriptionKey?: string;
    repeatable?: boolean;
    mandatory?: boolean;
    enablable?: boolean;
    enabled?: boolean;
    type?: QuestionTemplateDto.TypeEnum;
    allowsNewAnswer?: boolean;
    icon?: string;
    answers?: Array<AnswerTemplateDto>;
    questions?: Array<QuestionTemplateDto>;
}
export namespace QuestionTemplateDto {
    export type TypeEnum = 'select-single' | 'select-multi' | 'text' | 'number' | 'container' | 'photo';
    export const TypeEnum = {
        SelectSingle: 'select-single' as TypeEnum,
        SelectMulti: 'select-multi' as TypeEnum,
        Text: 'text' as TypeEnum,
        Number: 'number' as TypeEnum,
        Container: 'container' as TypeEnum,
        Photo: 'photo' as TypeEnum
    };
}


