<div class="content">
    <div class="header">
        <section class="left">
            <button (click)="changeMultiShotMode()" *ngIf="this.options.multiShotCallback" [class.active]="options.multiShotActivated" class="multiShotNotice" mat-button>
                <fa-icon [icon]="appIcons.cameraMultiShot"></fa-icon>
                <label *ngIf="options.multiShotActivated" i18n="@@camera.multiShotActive">Multi-shot active</label>
                <label *ngIf="!options.multiShotActivated" i18n="@@camera.multiShotDeactivated">Multi-shot deactivated</label>
            </button>
        </section>

        <section class="right">
            <button (click)="close(undefined, $event)" (touchstart)="close(undefined, $event)" class="close" mat-icon-button>
                <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
            </button>
        </section>
    </div>

    <div class="main">
        <div *ngIf="!cameraPreviewLoaded" class="infoBox">
            <base-loading></base-loading>
            <p i18n="@@camera.loading">Starting camera...</p>
        </div>

        <div #cameraParent id="cameraParent"></div>
        <img *ngIf="showPreview" [src]="currentMultiShotImage" alt="" class="compressedImagePreview">
        <div [class.active]="showFlash" class="flash"></div>

        <div *ngIf="cameraPermissionDenied && cameraPreviewLoaded" class="infoBox">
            <fa-icon [icon]="appIcons.genericError" class="errorIcon" size="4x"></fa-icon>

            <p i18n="@@camera.permissionDenied">Camera permission denied.</p>
            <button (click)="startCamera()" mat-raised-button>
                <span i18n="@@camera.tryAgain">Try again</span>
            </button>
        </div>
    </div>

    <div class="footer">
        <section class="left">
            <button (click)="rotateCamera()" *ngIf="frontCameraAvailable" [class]="currentCameraMode" class="flip">
                <fa-icon [icon]="appIcons.cameraRotate" size="3x"></fa-icon>
            </button>

            <div *ngIf="currentMultiShotImage" class="multiShotView">
                <div class="image"></div>
                <div class="image"></div>
                <div class="image">
                    <img [src]="currentMultiShotImage" alt="">
                </div>
            </div>
        </section>

        <section>
            <button (click)="capture($event)" [disabled]="!cameraPreviewLoaded || cameraPermissionDenied" class="captureButton">
                <div class="inner"></div>
            </button>
        </section>

        <section>
            <button (click)="toggleFlash()" *ngIf="flashModeSupported">
                <fa-icon *ngIf="!torchActive" [icon]="appIcons.cameraTorchOff" size="3x"></fa-icon>
                <fa-icon *ngIf="torchActive" [icon]="appIcons.cameraTorchOn" size="3x"></fa-icon>
            </button>
        </section>
    </div>
</div>
