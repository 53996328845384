import { EventEmitter } from "@angular/core";

import { Timer } from "./timer";

/**
 * A regular timer that fires elapsed events.
 */
export class ProductionTimer implements Timer {
    constructor(interval: number) {
        this.intervalField = interval;
    }

    public elapsed: EventEmitter<void> = new EventEmitter<void>();

    private timerHandle?: any;

    private intervalField: number;
    public get interval(): number {
        return this.intervalField;
    }
    public set interval(value: number) {
        if (value != this.intervalField) {
            this.intervalField = value;
            this.reset();
        }
    }

    public get active(): boolean {
        return !!this.timerHandle;
    }

    public start(): void {
        if (this.timerHandle) {
            return;
        }

        this.timerHandle = setInterval(this.timerElapsed.bind(this), this.intervalField);
    }

    public stop(): void {
        if (!this.timerHandle) {
            return;
        }

        clearInterval(this.timerHandle);
        this.timerHandle = undefined;
    }

    public reset(): void {
        this.stop();
        this.start();
    }

    protected timerElapsed(): void {
        this.elapsed.emit();
    }
}
