import { AuditDocumentStorageDto } from "src/app/business/entities/documents/audit-document-storage-dto";

import { TypeScriptHelper } from "../../base/helpers/type-script-helper";

/**
 * DTO to persist document to storage.
 */
export class BuildingWaterWalkthroughInspectionDocumentStorageDto extends AuditDocumentStorageDto {
    constructor(init?: Partial<BuildingWaterWalkthroughInspectionDocumentStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }
}
