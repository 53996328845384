export enum StorageKeys {
    apiUrl = "apiUrl",
    accounts = "accounts",
    activeAccount = "activeAccount",
    activePersonIdentifier = "activePersonIdentifier",
    activePermissions = "activePermissions",
    username = "username",
    password = "password",
    isAuthenticated = "isAuthenticated",
    leftHandedMode = "leftHandedMode",
    documentUpdateQueue = "documentUpdateQueue",
    deviceId = "deviceId",
    cachingDisabled = "cachingDisabled",
    errorLog = "errorLog",
    allDocuments = "allDocuments",
    roomFeatures = "roomFeatures",
    gallerySortOrder = "gallerySortOrder",
    imageDownloadFormat = "imageDownloadFormat",
    attachmentUploadQueue = "attachmentUploadQueue",
    processTableExtendedInformation = "processTableExtendedInformation",

    sessionProject = "sessionProject",
    sessionProcess = "sessionProcess",
    sessionDocument = "sessionDocument",

    storedVersion = "storedVersion",
    previousVersion = "previousVersion",

    samplingPlanExtendedView = "samplingPlanExtendedView",

    rawAuditors = "rawAuditors",

    gameArrowHighscores = "gameArrowHighscores",

    // Group keys
    groupDocuments = "document",
    groupCoreData = "cd",
    groupSamplingPlanBackup = "samplingplan-backup",
    groupAttachmentsLocal = "attachment-local",
    groupAttachmentsServer = "attachment",
    groupTemplateVersion = "template",
    groupTranslations = "translations",
    groupChatDraft = "chatDraft",

    groupAttachmentStorageMapping = "attachment-mapping",
    groupAttachmentPreviewStorageMapping = "attachment-preview-mapping"
}
