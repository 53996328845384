<base-page-content-container>
    <div *ngIf="testRunning" class="centerContent summary">
        <fa-icon [icon]="appIcons.genericLoading" [spin]="true"></fa-icon>
        <h2 class="pageTitle" i18n="@@networkTest.runningTest">Running test, please wait...</h2>
    </div>
    <div *ngIf="!testRunning && finalTestResult == statusEnum.success" class="centerContent summary">
        <fa-icon [icon]="appIcons.genericCheck"></fa-icon>
        <h2 class="pageTitle" i18n="@@networkTest.networkIsWorking">All tests passed</h2>
    </div>
    <div *ngIf="!testRunning && finalTestResult != statusEnum.success" class="centerContent summary">
        <fa-icon [icon]="appIcons.genericError"></fa-icon>
        <h2 *ngIf="stepBackendConnectionTest.status == statusEnum.failed" class="pageTitle" i18n="@@networkTest.networkIsNotWorking">Network issues</h2>
        <h2 *ngIf="stepBackendConnectionTest.status == statusEnum.success" class="pageTitle" i18n="@@networkTest.networkIsNotWorkingPartially">Partial network issues</h2>
        <div class="summaryText">{{testSummaryText}}</div>
    </div>

    <table #table [dataSource]="testSteps" mat-table>
        <ng-container matColumnDef="status">
            <td *matCellDef="let row" mat-cell>
                <fa-icon *ngIf="row.status == statusEnum.pending" [icon]="appIcons.genericHourglass"></fa-icon>
                <fa-icon *ngIf="row.status == statusEnum.active" [icon]="appIcons.genericLoading" [spin]="true"></fa-icon>
                <fa-icon *ngIf="row.status == statusEnum.success" [icon]="appIcons.genericCheck"></fa-icon>
                <fa-icon *ngIf="row.status == statusEnum.failed" [icon]="appIcons.genericError"></fa-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <td *matCellDef="let row" mat-cell>
                <span class="title">{{row.title}}</span>
                <span *ngIf="row.description"><br />{{row.description}}</span>
            </td>
        </ng-container>

        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
    <div class="disclaimer" i18n="@@networkTest.testDisclaimer">*The Google server is only used to test the connection and is not used within the app. CyanAudit does not use any Google services, except making one request to a static file to test the internet connection in this dialog.</div>
    <base-process-button (triggered)="runTest($event)" *ngIf="!testRunning" [icon]="appIcons.genericRefresh" i18n="@@networkTest.checkAgain">Check again</base-process-button>
</base-page-content-container>
