import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { AddressStorageDto } from "./address-storage-dto";
import { CoreDataEntityStorageDto } from "./core-data-entity-storage-dto";

/**
 * Storage DTO for {@link Person}.
 */
export class PersonStorageDto extends CoreDataEntityStorageDto {
    public readonly currentStorageVersion: number = 1;
    public storageVersion: number = this.currentStorageVersion;

    constructor(init?: Partial<PersonStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public gender?: string;
    public title?: string;
    public firstname?: string;
    public lastname?: string;
    public email?: string;
    public phone?: string;
    public phoneMobile?: string;
    public status?: string;
    public address?: AddressStorageDto;
}
