import { Injectable } from "@angular/core";

import { DialogService } from "../../../base/services/dialog/dialog.service";
import { ModalModes } from "../../../base/services/dialog/modal-modes";
import { ManualComponent } from "../../components/help/manual/manual.component";
import { ManualComponentOptions } from "../../components/help/manual/manual.component.options";
import { HelpArticles } from "../../global/help-articles";
import { LocalizationService } from "../localization/localization.service";

/**
 * Service to help online.
 */
@Injectable({
    providedIn: "root"
})
export class OnlineHelpService {
    constructor(
        private localizationService: LocalizationService,
        private dialogService: DialogService
    ) {
    }

    public searchSolution(errorCode: string): void {
        const options: ManualComponentOptions = new ManualComponentOptions({
            language: this.localizationService.localeId,
            tag: errorCode
        });
        this.dialogService.showDefaultModal($localize`:@@manual.dialogTitle:Online manual`, {
            component: ManualComponent,
            options: options
        }, { mode: ModalModes.large }).then();
    }

    public openArticle(article: HelpArticles): void {
        const options: ManualComponentOptions = new ManualComponentOptions({
            language: this.localizationService.localeId,
            tag: article
        });
        this.dialogService.showDefaultModal($localize`:@@manual.dialogTitle:Online manual`, {
            component: ManualComponent,
            options: options
        }, { mode: ModalModes.large }).then();
    }

    public openHelp(): void {
        const options: ManualComponentOptions = new ManualComponentOptions({ language: this.localizationService.localeId });
        this.dialogService.showDefaultModal($localize`:@@manual.dialogTitle:Online manual`, {
            component: ManualComponent,
            options: options
        }, { mode: ModalModes.large }).then();
    }

    public searchHelp(query: string): void {
        const options: ManualComponentOptions = new ManualComponentOptions({
            language: this.localizationService.localeId,
            tag: query
        });
        this.dialogService.showDefaultModal($localize`:@@manual.dialogTitle:Online manual`, {
            component: ManualComponent,
            options: options
        }, { mode: ModalModes.large }).then();
    }

    public openUpdateHistory(oldVersion?: string, newVersion?: string): void {
        const options: ManualComponentOptions = new ManualComponentOptions({
            language: this.localizationService.localeId,
            uri: `changelog?ts=${(new Date()).getTime()}`
        });
        this.dialogService.showDefaultModal(oldVersion && newVersion ? $localize`:@@manual.updateHistoryTitle:App updated - from ${oldVersion}:oldVersion: to ${newVersion}:newVersion:` : $localize`:@@manual.changelogTitle:Changelog`, {
            component: ManualComponent,
            options: options
        }, { mode: ModalModes.large }).then();
    }
}
