import { AfterViewInit, ContentChildren, Directive, OnDestroy, QueryList, Renderer2 } from "@angular/core";

/**
 * Directive to use return like tab.
 */
@Directive({
    selector: "[enter-to-tab]"
})
export class EnterToTabDirective implements AfterViewInit, OnDestroy {
    constructor(private renderer: Renderer2) {
    }

    @ContentChildren("nextTab", { descendants: true }) public controls!: QueryList<any>;

    private unlistMethods: Array<() => void> = [];

    public nextTab: any;

    public ngAfterViewInit(): void {
        try {
            this.controls.changes.subscribe((controls: any) => {
                this.createKeydownEnter(controls);
            });
            if (this.controls.length) {
                this.createKeydownEnter(this.controls);
            }
        } catch (error) {
            console.error(error);
        }
    }

    public ngOnDestroy(): void {
        try {
            for (const listener of this.unlistMethods) {
                listener();
            }

        } catch (error) {
            console.error(error);
        }
    }

    private createKeydownEnter(queryControls: QueryList<any>): void {
        try {
            queryControls.forEach((control: any) => {
                if (control.nativeElement && ("addEventListener" in control.nativeElement)) {
                    const unlistDelegate: () => void = this.renderer.listen(control.nativeElement, "keydown.enter", (event: any) => {
                        if (this.controls.last != control) {
                            const controls: Array<any> = queryControls.toArray();
                            const index: number = controls.findIndex((d: any) => d == control);
                            if (index >= 0) {
                                const nextControls: Array<any> = controls.filter((n: any, i: number) => n && !n.nativeElement?.attributes?.disabled && i > index) ?? [];
                                for (const nextControl of nextControls) {
                                    if (nextControl) {
                                        if ("focus" in nextControl) {
                                            event.preventDefault();
                                            nextControl.focus();
                                            break;
                                        } else if (nextControl.nativeElement && "focus" in nextControl.nativeElement) {
                                            event.preventDefault();
                                            nextControl.nativeElement.focus();
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    });
                    this.unlistMethods.push(unlistDelegate);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
}
