import { ComponentType, FlexibleConnectedPositionStrategy, FlexibleConnectedPositionStrategyOrigin, GlobalPositionStrategy, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ComponentRef, Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LoadingController, LoadingOptions, ModalController, ModalOptions, PopoverController, PopoverOptions, ToastController } from "@ionic/angular";
import { Subscription } from "rxjs";

import { DefaultDialogComponent } from "../../../business/components/default-dialog/default-dialog.component";
import { DefaultDialogComponentOptions } from "../../../business/components/default-dialog/default-dialog.component.options";
import { ErrorDialogComponent } from "../../../business/components/error-dialog/error-dialog.component";
import { AppService } from "../../../business/services/app/app.service";
import { ContextMenuComponent } from "../../components/context-menu/context-menu.component";
import { ContextMenuItem } from "../../components/context-menu/context-menu-item";
import { ContextMenuOptions } from "../../components/context-menu/context-menu-options";
import { BasicDialogComponent } from "../../components/dialogs/basic-dialog/basic-dialog.component";
import { BasicDialogOptions } from "../../components/dialogs/basic-dialog/basic-dialog-options";
import { DialogButton } from "../../components/dialogs/basic-dialog/dialog-button";
import { InputDialogComponent } from "../../components/dialogs/input-dialog/input-dialog.component";
import { InputDialogOptions } from "../../components/dialogs/input-dialog/input-dialog-options";
import { InteractiveToastButton } from "../../components/interactive-toast/interactive-toast.button";
import { InteractiveToastComponent } from "../../components/interactive-toast/interactive-toast.component";
import { InteractiveToastOptions } from "../../components/interactive-toast/interactive-toast.options";
import { WebHelper } from "../../helpers/web-helper";
import { OverlayComponent } from "../../interfaces/overlay-component";
import { AlertSizes } from "./alert-sizes";
import { ModalModes } from "./modal-modes";

/**
 * A UI service to show dialogs, popovers and toasts.
 */
@Injectable({
    providedIn: "root"
})
export class DialogService {
    constructor(
        private readonly appService: AppService,
        private readonly toastController: ToastController,
        private readonly modalController: ModalController,
        private readonly popoverController: PopoverController,
        private readonly loadingController: LoadingController,
        private readonly overlay: Overlay,
        public readonly dialog: MatDialog
    ) {
    }

    private readonly defaultToastDuration: number = 3000;

    private openItems: Map<any, boolean> = new Map<any, boolean>();

    private openToasts: Map<string, Array<InteractiveToastComponent>> = new Map<string, Array<InteractiveToastComponent>>();

    private async getTopLayer(): Promise<any> {
        try {
            return await this.modalController.getTop() || document.getElementsByTagName("ion-router-outlet")[0];
        } catch (error) {
            return null;
        }
    }

    public async showDefaultModal<TReturnType = void>(title: string, child: { component: any; options?: any }, options?: { singleInstance?: boolean; mode?: ModalModes; padding?: boolean }): Promise<TReturnType|undefined> {
        const dialogOptions: DefaultDialogComponentOptions = new DefaultDialogComponentOptions(title, child, { padding: options?.padding ?? true });

        return this.showModal({
            component: DefaultDialogComponent,
            payload: { options: dialogOptions },
            singleInstance: options?.singleInstance,
            mode: options?.mode
        });
    }

    public async showModal<TReturnType = void>(options: { component: any; payload?: { [key: string]: any }; singleInstance?: boolean; mode?: ModalModes }): Promise<TReturnType|undefined> {
        if (options.singleInstance) {
            if (this.openItems.has(options.component)) {
                return;
            }
        }

        if (this.appService.needsRestart && this.openItems.size <= 0) {
            await this.appService.restartApp(true);
            return undefined;
        }

        const customClasses: Array<string> = ["modalDialog"];

        if (options.mode == ModalModes.fullscreen) {
            customClasses.push("fullscreen");
        } else if (options.mode == ModalModes.large) {
            customClasses.push("large");
        } else if (options.mode == ModalModes.small) {
            customClasses.push("small");
        } else if (options.mode == ModalModes.tiny) {
            customClasses.push("tiny");
        }

        const modalOptions: ModalOptions<any> = {
            component: options.component as unknown,
            componentProps: options.payload as { [key: string]: any },
            cssClass: customClasses,
            backdropDismiss: false,
            canDismiss: true,
            keyboardClose: false
        };

        if (options.mode != ModalModes.fullscreen) {
            modalOptions.presentingElement = await this.getTopLayer() as HTMLElement;
        }

        this.openItems.set(options.component, true);
        try {
            const modal: HTMLIonModalElement = await this.modalController.create(modalOptions);

            modal.present().then();
            const { data } = await modal.onDidDismiss() as { data: TReturnType };
            return data;
        } finally {
            this.openItems.delete(options.component);
        }
    }

    private async showAlertDialog<TReturn>(component: ComponentType<any>, options: any, size: AlertSizes = AlertSizes.normal): Promise<TReturn|undefined> {
        const dialogRef: MatDialogRef<any> = this.dialog.open(component, {
            width: size == AlertSizes.large ? "600px" : (size == AlertSizes.huge ? "900px" : "350px"),
            restoreFocus: false,
            data: {
                ...options
            } as unknown
        });

        return new Promise((resolve: (result: TReturn|undefined) => void) => {
            dialogRef.afterClosed().subscribe((result?: TReturn) => {
                resolve(result);
            });
        });
    }

    public async showAlert(title: string, message?: string, subtitle?: string, buttons?: Array<DialogButton>, size: AlertSizes = AlertSizes.normal): Promise<string|undefined> {
        const result: DialogButton|undefined = await this.showAlertDialog<DialogButton>(BasicDialogComponent, new BasicDialogOptions(title, subtitle, message, buttons), size);
        return result?.id;
    }

    public showInputPrompt(title: string, message?: string, subTitle?: string, placeholder?: string, defaultValue?: string, multiline: boolean = false, size: AlertSizes = AlertSizes.normal): Promise<string|undefined> {
        return this.showAlertDialog<string>(
            InputDialogComponent,
            new InputDialogOptions(
                title,
                subTitle,
                message,
                [
                    new DialogButton($localize`:@@dialog.genericCancel:Cancel`, undefined, false, "destructive"),
                    new DialogButton($localize`:@@dialog.genericOk:OK`, undefined, true)
                ],
                placeholder,
                defaultValue,
                multiline
            ),
            size
        );
    }

    public async showPopover<TReturnType = void>(options: { component: any; payload?: { [key: string]: any }; event: Event }): Promise<TReturnType> {
        const popoverOptions: PopoverOptions<any> = {
            component: options.component as unknown,
            componentProps: options.payload as { [key: string]: any },
            event: options.event
        };

        const popover: HTMLIonPopoverElement = await this.popoverController.create(popoverOptions);

        popover.present().then();
        const { data } = await popover.onDidDismiss() as { data: TReturnType };
        return data;
    }

    public async openOverlay<TResult>(component: ComponentType<any>, payload?: any, event?: Event, atMousePosition?: boolean, hasBackdrop: boolean = true, waitForResult: boolean = true): Promise<TResult|OverlayComponent<TResult|undefined>|undefined> {
        const pointerEvent: PointerEvent = event as PointerEvent;
        const positionStrategy: FlexibleConnectedPositionStrategy|GlobalPositionStrategy =
            atMousePosition && pointerEvent
                ? this.overlay.position().global().left(`${pointerEvent.clientX + 1}px`)
                    .top(`${pointerEvent.clientY + 1}px`)
                : event?.target
                    ? this.overlay.position().flexibleConnectedTo(event.target as FlexibleConnectedPositionStrategyOrigin)
                        .withPositions([{
                            originX: "start",
                            originY: "bottom",
                            overlayX: "start",
                            overlayY: "top"
                        }])
                    : this.overlay.position().global().centerVertically().centerHorizontally();
        const overlayRef: OverlayRef = this.overlay.create({
            positionStrategy: positionStrategy,
            hasBackdrop: hasBackdrop
        });

        let finishedPromiseResolve: (value: TResult|PromiseLike<TResult|undefined>|undefined) => void;

        const backdropClickSubscription: Subscription = overlayRef.backdropClick().subscribe(() => {
            backdropClickSubscription.unsubscribe();
            overlayRef.dispose();
            if (finishedPromiseResolve) {
                finishedPromiseResolve(undefined);
            }
        });

        const componentPortal: ComponentPortal<unknown> = new ComponentPortal<unknown>(component);
        const componentRef: ComponentRef<unknown> = overlayRef.attach(componentPortal);
        const componentInstance: any = componentRef.instance as unknown;
        componentInstance.options = payload as unknown;
        componentInstance.overlayRef = overlayRef;

        const overlayComponent: OverlayComponent<TResult|undefined> = componentRef.instance as OverlayComponent<TResult|undefined>;

        if (waitForResult) {
            if (overlayComponent && overlayComponent.dismissed) {
                return new Promise((resolve: (value: (PromiseLike<TResult|undefined>|TResult|undefined)) => void) => {
                    finishedPromiseResolve = resolve;
                    const dismissSubscription: Subscription = overlayComponent.dismissed.subscribe((value: TResult|undefined) => {
                        dismissSubscription.unsubscribe();
                        resolve(value);
                    });
                });
            }
        } else {
            const dismissSubscription: Subscription = overlayComponent.dismissed.subscribe(() => {
                dismissSubscription.unsubscribe();
            });

            return overlayComponent;
        }
    }

    public async showContextMenu(items: Array<ContextMenuItem>, event: Event, atMousePosition: boolean): Promise<ContextMenuItem|undefined> {
        return await this.openOverlay(ContextMenuComponent, new ContextMenuOptions(items), event, atMousePosition) as ContextMenuItem|undefined;
    }

    public async showInteractiveToast(title: string, message: string, buttons: Array<InteractiveToastButton>, id?: string): Promise<OverlayComponent<void>|undefined> {
        const toast: OverlayComponent<void>|undefined = await this.openOverlay(
            InteractiveToastComponent,
            new InteractiveToastOptions(title, message, buttons),
            undefined,
            false,
            false,
            false
        ) as OverlayComponent<void>|undefined;

        if (toast && id) {
            const existingToastsWithSameId: Array<InteractiveToastComponent> = this.openToasts.get(id) || [];
            existingToastsWithSameId.push(toast as InteractiveToastComponent);

            this.openToasts.set(id, existingToastsWithSameId);
        }

        return toast;
    }

    public closeToast(id: string): void {
        const toasts: Array<InteractiveToastComponent>|undefined = this.openToasts.get(id);

        if (toasts) {
            for (const toast of toasts) {
                toast.close();
            }
        }
    }

    public showToast(message: string, position: "bottom"|"top"|"middle" = "top", duration: number = this.defaultToastDuration): void {
        this.showToastImplementation(message, position, duration).then();
    }

    public async showToastImplementation(message: string, position: "bottom"|"top"|"middle" = "top", duration: number = this.defaultToastDuration): Promise<void> {
        const toast: HTMLIonToastElement = await this.toastController.create(
            {
                message: message,
                position: position,
                duration: duration
            });
        await toast.present();
    }

    public async showError(error: any): Promise<void> {
        if (!error) {
            return;
        }

        if (WebHelper.isNoInternetError(error)) {
            await this.showAlert($localize`:@@dialog.noInternetErrorTitle:No Internet`, $localize`:@@dialog.noInternetErrorText:This operation requires internet. Due to an unstable internet connection the request did not work. Please try again.`);
            return;
        }

        return this.showModal({ component: ErrorDialogComponent, payload: { error: error as unknown } });
    }

    public async showLoading(message?: string): Promise<HTMLIonLoadingElement> {
        const options: LoadingOptions = {
            message: message
        };
        const loadingElement: HTMLIonLoadingElement = await this.loadingController.create(options);
        await loadingElement.present();
        return loadingElement;
    }
}
