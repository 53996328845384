import { AnswerScorings } from "../../../business/entities/documents/answer-scorings";
import { DocumentAnswer } from "../../../business/entities/documents/document-answer";
import { DocumentAnswerComment } from "../../../business/entities/documents/document-answer-comment";
import { DocumentQuestion } from "../../../business/entities/documents/document-question";
import { DocumentQuestionTypes } from "../../../business/entities/documents/document-question-types";
import { DocumentSection } from "../../../business/entities/documents/document-section";
import { DocumentTemplate } from "../../../business/entities/documents/document-template";
import { DocumentTypes } from "../../../business/entities/documents/document-types";
import { AnswerTemplateIdentifier } from "../../../business/identifiers/answer-template-identifier";
import { CommentTemplateIdentifier } from "../../../business/identifiers/comment-template-identifier";
import { QuestionTemplateIdentifier } from "../../../business/identifiers/question-template-identifier";
import { SectionTemplateIdentifier } from "../../../business/identifiers/section-template-identifier";
import { TemplateVersionTechnicalIdentifier } from "../../../business/identifiers/template-version-technical-identifier";
import { DocumentSettings } from "../../../business/services/documents/document-settings";
import { AnswerTemplateDto, CommentTemplateDto, QuestionTemplateDto, TemplateVersionDto } from "../../../generated/api";

/**
 * The parser service that takes JSON files and creates class instances.
 */
export class DocumentParser {
    public static dtoToDocumentType(documentType?: TemplateVersionDto.DocumentTypeEnum): DocumentTypes {
        switch (documentType) {
            case TemplateVersionDto.DocumentTypeEnum.WalkthroughInspection:
                return DocumentTypes.buildingWaterWalkthroughInspection;
            default:
                return DocumentTypes.unknown;
        }
    }

    public static dtoToTemplateVersion(templateDto: TemplateVersionDto): DocumentTemplate {
        const documentType: DocumentTypes = this.dtoToDocumentType(templateDto.documentType);
        const template: DocumentTemplate = new DocumentTemplate(templateDto.id as TemplateVersionTechnicalIdentifier, documentType);
        template.name = templateDto.name;
        template.beginBulkUpdate();

        for (const sectionDto of templateDto.questions ?? []) {
            const section: DocumentSection = this.dtoToDocumentSection(sectionDto);
            template.addSection(section);
        }

        template.endBulkUpdate();
        return template;
    }

    public static dtoToDocumentSection(sectionTemplateDto: QuestionTemplateDto): DocumentSection {
        const section: DocumentSection = new DocumentSection((sectionTemplateDto.id ?? 0) as SectionTemplateIdentifier, {
            title: sectionTemplateDto.titleKey as string,
            repeatable: !!sectionTemplateDto.repeatable,
            icon: sectionTemplateDto.icon,
            enablable: sectionTemplateDto.enablable,
            enabled: sectionTemplateDto.enabled
        });

        for (const child of sectionTemplateDto.questions ?? []) {
            if (child.type == QuestionTemplateDto.TypeEnum.Container) {
                const childSection: DocumentSection = this.dtoToDocumentSection(child);
                section.addSection(childSection);
            } else {
                const childQuestion: DocumentQuestion = this.dtoToDocumentQuestion(child, section.templateIdentifier);
                section.addQuestion(childQuestion);
            }
        }

        return section;
    }

    private static dtoTypeToQuestionType(type?: QuestionTemplateDto.TypeEnum): DocumentQuestionTypes {
        switch (type) {
            case QuestionTemplateDto.TypeEnum.SelectSingle:
                return DocumentQuestionTypes.choice;
            case QuestionTemplateDto.TypeEnum.SelectMulti:
                return DocumentQuestionTypes.multipleChoice;
            case QuestionTemplateDto.TypeEnum.Text:
                return DocumentQuestionTypes.text;
            case QuestionTemplateDto.TypeEnum.Number:
                return DocumentQuestionTypes.numeric;
            case QuestionTemplateDto.TypeEnum.Photo:
                return DocumentQuestionTypes.singleImage;

            case QuestionTemplateDto.TypeEnum.Container:
                return DocumentQuestionTypes.container;

            default:
                return DocumentQuestionTypes.unknown;
        }
    }

    private static dtoToAnswerScoring(scoring?: AnswerTemplateDto.ScoringEnum): AnswerScorings {
        switch (scoring) {
            case AnswerTemplateDto.ScoringEnum.Neutral:
                return AnswerScorings.neutral;
            case AnswerTemplateDto.ScoringEnum.Good:
                return AnswerScorings.good;
            case AnswerTemplateDto.ScoringEnum.Warning:
                return AnswerScorings.warning;
            case AnswerTemplateDto.ScoringEnum.Critical:
                return AnswerScorings.critical;
            default:
                return AnswerScorings.unknown;
        }
    }

    public static dtoToDocumentQuestion(questionTemplateDto: QuestionTemplateDto, sectionTemplateId: SectionTemplateIdentifier): DocumentQuestion {
        const type: DocumentQuestionTypes = this.dtoTypeToQuestionType(questionTemplateDto.type);
        const question: DocumentQuestion = new DocumentQuestion((questionTemplateDto.id ?? 0) as QuestionTemplateIdentifier, sectionTemplateId, {
            title: questionTemplateDto.titleKey,
            description: questionTemplateDto.descriptionKey,
            type: type,
            typeRaw: questionTemplateDto.type,
            allowsNewEntry: !!questionTemplateDto.allowsNewAnswer,
            hasComments: type != DocumentQuestionTypes.singleImage,
            hasImages: true,
            hasFiles: false,
            hasAudio: false,
            hasInternalNotes: false,
            enablable: questionTemplateDto.enablable ?? false,
            enabled: questionTemplateDto.enabled ?? true
        });

        question.simpleItem = DocumentSettings.isSimpleQuestion(question.type);

        for (const answerDto of questionTemplateDto.answers ?? []) {
            const answer: DocumentAnswer = this.dtoToDocumentAnswer(answerDto);
            question.addValue(answer);
        }

        for (const childDto of questionTemplateDto.questions ?? []) {
            const child: DocumentQuestion = this.dtoToDocumentQuestion(childDto, sectionTemplateId);
            question.addQuestion(child);
        }

        return question;
    }

    public static dtoToDocumentAnswer(answerTemplateDto: AnswerTemplateDto): DocumentAnswer {
        const answer: DocumentAnswer = new DocumentAnswer((answerTemplateDto.id ?? 0) as AnswerTemplateIdentifier, {
            label: answerTemplateDto.answerKey,
            unit: answerTemplateDto.unit,
            scoring: this.dtoToAnswerScoring(answerTemplateDto.scoring),
            comments: this.dtoToComments(answerTemplateDto.commentTemplates),
            commentMandatory: answerTemplateDto.commentMandatory ?? false
        });
        answer.value.value = answerTemplateDto.answerKey;

        return answer;
    }

    public static dtoToComments(commentTemplates: Array<CommentTemplateDto>|undefined): Array<DocumentAnswerComment> {
        const result: Array<DocumentAnswerComment> = [];
        for (const commentTemplate of commentTemplates ?? []) {

            const comment: DocumentAnswerComment = new DocumentAnswerComment({
                identifier: commentTemplate.id as CommentTemplateIdentifier,
                textKey: commentTemplate.commentKey,
                text: commentTemplate.commentKey
            });
            result.push(comment);
        }
        return result;
    }
}
