import { Injectable } from "@angular/core";

import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { BuildingUsageTypes } from "../../entities/core-data/buildings/building-usage-types";
import { DrinkingWaterHeatingTypes } from "../../entities/core-data/buildings/drinking-water-heating-types";
import { DocumentStatus } from "../../entities/documents/document-status";
import { DocumentTypes } from "../../entities/documents/document-types";
import { QuestionSaveStates } from "../../entities/documents/question-save-states";
import { EntityTypes } from "../../entities/entity-types";
import { ProcessStatus } from "../../entities/projects-processes/process-status";
import { ProcessTypes } from "../../entities/projects-processes/process-types";
import { SampleLegalReferences } from "../../entities/samplings/sample-legal-references";
import { SampleTypes } from "../../entities/samplings/sample-types";
import { Units } from "../../global/units";
import { DocumentUpdateTypes } from "../documents/document-update-types";

/**
 * A localization helper service mainly to get enumerations translated.
 */
@Injectable({
    providedIn: "root"
})
export class I18nTextService {
    public booleanToYesNo(value: boolean): string {
        return value ? $localize`:@@generic.yes:Yes` : $localize`:@@generic.no:No`;
    }

    public getProcessTypeText(type: ProcessTypes): string {
        switch (type) {
            case ProcessTypes.unknown:
                return $localize`:@@enum.processTypes.unknown:Unknown`;
            case ProcessTypes.buildingWaterWalkthroughInspection:
                return $localize`:@@enum.processTypes.buildingWaterWalkthroughInspection:Walkthrough Inspection`;
            default:
                TypeScriptHelper.expectNever(type);
                return $localize`:@@enum.processTypes.unknown:Unknown`;
        }
    }

    public getDocumentTypeText(type: DocumentTypes): string {
        switch (type) {
            case DocumentTypes.unknown:
                return $localize`:@@enum.documentTypes.unknown:Unknown`;
            case DocumentTypes.buildingWaterWalkthroughInspection:
                return $localize`:@@enum.documentTypes.buildingWaterWalkthroughInspection:Walkthrough Inspection`;
            case DocumentTypes.simpleDocument:
                return $localize`:@@enum.documentTypes.simpleDocument:Simple document`;
            default:
                TypeScriptHelper.expectNever(type);
                return $localize`:@@enum.documentTypes.unknown:Unknown`;
        }
    }

    public getDocumentUpdateTypeText(type: DocumentUpdateTypes): string {
        switch (type) {
            case DocumentUpdateTypes.unknown:
                return $localize`:@@enum.documentUpdateTypes.unknown:Unknown`;
            case DocumentUpdateTypes.question:
                return $localize`:@@enum.documentUpdateTypes.question:Question`;
            case DocumentUpdateTypes.attachmentLink:
                return $localize`:@@enum.documentUpdateTypes.attachmentLink:Attachment link`;
            case DocumentUpdateTypes.samplingPlan:
                return $localize`:@@enum.documentUpdateTypes.samplingPlan:Sampling plan`;
            case DocumentUpdateTypes.repeatableQuestion:
                return $localize`:@@enum.documentUpdateTypes.repeatableQuestion:Repeatable question`;
            case DocumentUpdateTypes.newRevision:
                return $localize`:@@enum.documentUpdateTypes.newRevision:New revision`;
            default:
                TypeScriptHelper.expectNever(type);
                return $localize`:@@enum.documentUpdateTypes.unknown:Unknown`;
        }
    }

    public getProcessStatusText(state: ProcessStatus): string {
        switch (state) {
            case ProcessStatus.unknown:
                return $localize`:@@enum.processStatus.unknown:Unknown`;
            case ProcessStatus.orderEntry:
                return $localize`:@@enum.processStatus.orderEntry:Order Entry`;
            case ProcessStatus.scheduled:
                return $localize`:@@enum.processStatus.scheduled:Scheduled`;
            case ProcessStatus.inProgress:
                return $localize`:@@enum.processStatus.inProgress:In Progress`;
            case ProcessStatus.inReview:
                return $localize`:@@enum.processStatus.inReview:In Review`;
            case ProcessStatus.reviewAccepted:
                return $localize`:@@enum.processStatus.reviewAccepted:Review accepted`;
            case ProcessStatus.reviewRejected:
                return $localize`:@@enum.processStatus.reviewRejected:Review rejected`;
            case ProcessStatus.done:
                return $localize`:@@enum.processStatus.done:Done`;
            default:
                TypeScriptHelper.expectNever(state);
                return $localize`:@@enum.processStatus.unknown:Unknown`;
        }
    }

    public getProcessStatusDescription(state: ProcessStatus): string {
        switch (state) {
            case ProcessStatus.unknown:
                return $localize`:@@enum.processStatus.unknownDescription:This is an unknown process state. Please check with your manager or administrator.`;
            case ProcessStatus.orderEntry:
                return $localize`:@@enum.processStatus.orderEntryDescription:Processes with active order entry.`;
            case ProcessStatus.scheduled:
                return $localize`:@@enum.processStatus.scheduledDescription:Processes that are scheduled and prepared for conduct.`;
            case ProcessStatus.inProgress:
                return $localize`:@@enum.processStatus.inProgressDescription:Processes that are being worked on.`;
            case ProcessStatus.inReview:
                return $localize`:@@enum.processStatus.inReviewDescription:Finished processes that are in review through QA or any other authority.`;
            case ProcessStatus.reviewAccepted:
                return $localize`:@@enum.processStatus.reviewAcceptedDescription:Processes with accepted QA.`;
            case ProcessStatus.reviewRejected:
                return $localize`:@@enum.processStatus.reviewRejectedDescription:Processes with rejections from QA.`;
            case ProcessStatus.done:
                return $localize`:@@enum.processStatus.doneDescription:Finished and closed processes.`;
            default:
                TypeScriptHelper.expectNever(state);
                return $localize`:@@enum.processStatus.unknownDescription:This is an unknown process state. Please check with your manager or administrator.`;
        }
    }

    public getAuditDocumentStatusText(state: DocumentStatus): string {
        switch (state) {
            case DocumentStatus.unknown:
                return $localize`:@@enum.auditStates.unknown:Unknown`;
            case DocumentStatus.orderEntry:
                return $localize`:@@enum.auditStates.orderEntry:Order Entry`;
            case DocumentStatus.scheduled:
                return $localize`:@@enum.auditStates.scheduled:Scheduled`;
            case DocumentStatus.inProgress:
                return $localize`:@@enum.auditStates.inProgress:In Progress`;
            case DocumentStatus.inReview:
                return $localize`:@@enum.auditStates.inReview:In Review`;
            case DocumentStatus.reviewAccepted:
                return $localize`:@@enum.auditStates.reviewAccepted:Review accepted`;
            case DocumentStatus.reviewRejected:
                return $localize`:@@enum.auditStates.reviewRejected:Review rejected`;
            case DocumentStatus.done:
                return $localize`:@@enum.auditStates.done:Done`;
            default:
                TypeScriptHelper.expectNever(state);
                return $localize`:@@enum.auditStates.unknown:Unknown`;
        }
    }

    public getAuditDocumentStatusDescription(state: DocumentStatus): string {
        switch (state) {
            case DocumentStatus.unknown:
                return $localize`:@@enum.auditStates.unknownDescription:This is an unknown audit state. Please check with your manager or administrator.`;
            case DocumentStatus.orderEntry:
                return $localize`:@@enum.auditStates.orderEntryDescription:Audits with active order entry.`;
            case DocumentStatus.scheduled:
                return $localize`:@@enum.auditStates.scheduledDescription:Scheduled audits ready to be worked on.`;
            case DocumentStatus.inProgress:
                return $localize`:@@enum.auditStates.inProgressDescription:Audits that are being worked on.`;
            case DocumentStatus.inReview:
                return $localize`:@@enum.auditStates.inReviewDescription:Finished audits that are in review through QA or any other authority.`;
            case DocumentStatus.reviewAccepted:
                return $localize`:@@enum.auditStates.reviewAcceptedDescription:Audits with accepted QA.`;
            case DocumentStatus.reviewRejected:
                return $localize`:@@enum.auditStates.reviewRejectedDescription:Audits with rejections from QA.`;
            case DocumentStatus.done:
                return $localize`:@@enum.auditStates.doneDescription:Finished and published audits.`;
            default:
                TypeScriptHelper.expectNever(state);
                return $localize`:@@enum.auditStates.unknownDescription:This is an unknown audit state. Please check with your manager or administrator.`;
        }
    }

    public getQuestionSaveStateDescription(saveState: QuestionSaveStates): string {
        switch (saveState) {
            case QuestionSaveStates.none:
                return $localize`:@@enum.questionSaveStates.none:There are no pending updates to synchronize.`;
            case QuestionSaveStates.updateCreated:
                return $localize`:@@enum.questionSaveStates.updateCreated:The pending update has been prepared.`;
            case QuestionSaveStates.persistedLocally:
                return $localize`:@@enum.questionSaveStates.persistedLocally:The pending update has been saved locally.`;
            case QuestionSaveStates.sendingToServer:
                return $localize`:@@enum.questionSaveStates.sendingToServer:The pending update is being sent to the server.`;
            case QuestionSaveStates.acceptedByServer:
                return $localize`:@@enum.questionSaveStates.acceptedByServer:The server accepted the update.`;
            case QuestionSaveStates.rejectedByServer:
                return $localize`:@@enum.questionSaveStates.refusedByServer:The server rejected the update.`;
            default:
                TypeScriptHelper.expectNever(saveState);
                return $localize`:@@enum.questionSaveStates.none:There are no pending updates to synchronize.`;
        }
    }

    public getSampleTypeText(sampleType: SampleTypes): string {
        switch (sampleType) {
            case SampleTypes.unknown:
                return $localize`:@@enum.sampleTypes.unknown:Unknown`;
            case SampleTypes.legionella:
                return $localize`:@@enum.sampleTypes.legionella:Legionella`;
            case SampleTypes.microbiology:
                return $localize`:@@enum.sampleTypes.microbiology:Microbiology`;
            case SampleTypes.chemical:
                return $localize`:@@enum.sampleTypes.chemical:Chemical`;
            default:
                TypeScriptHelper.expectNever(sampleType);
                return $localize`:@@enum.sampleTypes.unknown:Unknown`;
        }
    }

    public getSampleLegalReferenceText(legalReference: SampleLegalReferences): string {
        switch (legalReference) {
            case SampleLegalReferences.dvgwW551:
                return $localize`:@@enum.sampleLegalReferences.dvgwW551:DVGW W 551`;
            case SampleLegalReferences.ubaEmpfehlung18122018:
                return $localize`:@@enum.sampleLegalReferences.ubaEmpfehlung18122018:UBA-Empfehlung`;
            default:
                TypeScriptHelper.expectNever(legalReference);
                return $localize`:@@enum.sampleLegalReferences.dvgwW551:DVGW W 551`;
        }
    }

    public getBuildingUsageTypeText(usageType: BuildingUsageTypes): string {
        switch (usageType) {
            case BuildingUsageTypes.unknown:
                return $localize`:@@enum.buildingUsage.unknown:Unknown`;
            case BuildingUsageTypes.residential:
                return $localize`:@@enum.buildingUsage.residential:Residential`;
            case BuildingUsageTypes.commercial:
                return $localize`:@@enum.buildingUsage.commercial:Commercial`;
            case BuildingUsageTypes.residentialAndCommercial:
                return $localize`:@@enum.buildingUsage.residentialAndCommercial:Residential and commercial`;
            default:
                TypeScriptHelper.expectNever(usageType);
                return $localize`:@@enum.buildingUsage.unknown:Unknown`;
        }
    }

    public getBuildingDrinkingWaterHeatingTypeText(heatingType: DrinkingWaterHeatingTypes): string {
        switch (heatingType) {
            case DrinkingWaterHeatingTypes.unknown:
                return $localize`:@@enum.drinkingWaterHeatingTypes.unknown:Unknown`;
            case DrinkingWaterHeatingTypes.centralized:
                return $localize`:@@enum.drinkingWaterHeatingTypes.centralized:Centralized`;
            case DrinkingWaterHeatingTypes.decentralized:
                return $localize`:@@enum.drinkingWaterHeatingTypes.decentralized:Decentralized`;
            case DrinkingWaterHeatingTypes.mixed:
                return $localize`:@@enum.drinkingWaterHeatingTypes.mixed:Mixed (Centralized + Decentralized)`;
            case DrinkingWaterHeatingTypes.smallCentralized:
                return $localize`:@@enum.drinkingWaterHeatingTypes.smallCentralized:Small-Centralized`;
            default:
                TypeScriptHelper.expectNever(heatingType);
                return $localize`:@@enum.drinkingWaterHeatingTypes.unknown:Unknown`;
        }
    }

    // eslint-disable-next-line complexity
    public getEntityTypeText(entityType: EntityTypes): string {
        switch (entityType) {
            case EntityTypes.unknown:
                return $localize`:@@entityType.unknown:Unknown`;
            case EntityTypes.all:
                return $localize`:@@entityType.all:All`;
            case EntityTypes.project:
                return $localize`:@@entityType.project:Project`;
            case EntityTypes.process:
                return $localize`:@@entityType.process:Process`;
            case EntityTypes.document:
                return $localize`:@@entityType.document:Document`;
            case EntityTypes.documentCommon:
                return $localize`:@@entityType.documentCommon:Common data`;
            case EntityTypes.chapter:
                return $localize`:@@entityType.chapter:Chapter`;
            case EntityTypes.section:
                return $localize`:@@entityType.section:Section`;
            case EntityTypes.question:
                return $localize`:@@entityType.question:Question`;
            case EntityTypes.coreData:
                return $localize`:@@entityType.coreData:Core data`;
            case EntityTypes.person:
                return $localize`:@@entityType.person:Person`;
            case EntityTypes.address:
                return $localize`:@@entityType.address:Address`;
            case EntityTypes.buildingComplex:
                return $localize`:@@entityType.buildingComplex:Building complex`;
            case EntityTypes.building:
                return $localize`:@@entityType.building:Building`;
            case EntityTypes.buildingFeatures:
                return $localize`:@@entityType.buildingFeatures:Building features`;
            case EntityTypes.company:
                return $localize`:@@entityType.company:Company`;
            case EntityTypes.image:
                return $localize`:@@entityType.image:Image`;
            case EntityTypes.attachment:
                return $localize`:@@entityType.attachment:Attachment`;
            case EntityTypes.attachments:
                return $localize`:@@entityType.attachments:Attachments`;
            case EntityTypes.revisions:
                return $localize`:@@entityType.revisions:Revisions`;
            case EntityTypes.review:
                return $localize`:@@entityType.review:Review`;
            case EntityTypes.sample:
            case EntityTypes.sampleMerge:
                return $localize`:@@entityType.sample:Sample`;
            case EntityTypes.samplingPlan:
            case EntityTypes.samplingPlanMerge:
                return $localize`:@@entityType.samplingPlan:Sampling plan`;
            case EntityTypes.dashboardProcesses:
                return $localize`:@@entityType.dashboardProcesses:Dashboard`;
            case EntityTypes.roomFeature:
                return $localize`:@@entityType.roomFeature:Room feature`;
            case EntityTypes.aiAnalysis:
                return $localize`:@@entityType.aiAnalysis:AI Analysis`;
            default:
                TypeScriptHelper.expectNever(entityType);
                return $localize`:@@entityType.unknown:Unknown`;
        }
    }

    public getUnitSingularText(unit: string): string {
        switch (unit) {
            case Units.year:
                return $localize`:@@units.year:Year`;
            case Units.month:
                return $localize`:@@units.month:Month`;
            case Units.day:
                return $localize`:@@units.day:Day`;
            case Units.celsius:
                return $localize`:@@units.celsius:° Celsius`;
            case Units.kelvin:
                return $localize`:@@units.kelvin:Kelvin`;
            case Units.liter:
                return $localize`:@@units.liter:Liter`;
            case Units.cfu:
                return $localize`:@@units.cfu:CFU`;
            case Units.meter:
                return $localize`:@@units.meter:Meter`;
            case Units.centimeter:
                return $localize`:@@units.centimeter:Centimeter`;
            case Units.millimeter:
                return $localize`:@@units.millimeter:Millimeter`;
            default:
                return unit;
        }
    }

    public getUnitPluralText(unit: string): string {
        if (!unit) {
            return "";
        }

        switch (unit.toLowerCase()) {
            case Units.year:
                return $localize`:@@units.yearPlural:Years`;
            case Units.month:
                return $localize`:@@units.monthPlural:Months`;
            case Units.day:
                return $localize`:@@units.dayPlural:Days`;
            case Units.celsius:
                return $localize`:@@units.celsiusPlural:° Celsius`;
            case Units.kelvin:
                return $localize`:@@units.kelvinPlural:Kelvin`;
            case Units.liter:
                return $localize`:@@units.literPlural:Liter`;
            case Units.cfu:
                return $localize`:@@units.cfuPlural:CFU`;
            case Units.meter:
                return $localize`:@@units.meter:Meter`;
            case Units.centimeter:
                return $localize`:@@units.centimeter:Centimeter`;
            case Units.millimeter:
                return $localize`:@@units.millimeter:Millimeter`;
            default:
                return unit;
        }
    }
}
