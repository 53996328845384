import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { EntityIdentifierStorageDto } from "../../identifiers/entity-identifier-storage-dto";
import { CoreDataEntityStorageDto } from "../core-data/core-data-entity-storage-dto";

/**
 * Storage DTO for {@link Sample}.
 */
export class SampleStorageDto extends CoreDataEntityStorageDto {
    constructor(init?: Partial<SampleStorageDto>) {
        super();
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public readonly currentStorageVersion: number = 1;

    public storageVersion: number = this.currentStorageVersion;

    public name?: string;

    public sampleType?: string;

    public roomRoomFeatureMappingTId?: number;

    public buildingId?: EntityIdentifierStorageDto;

    public addressTid?: number;

    public floorTid?: number;

    public roomTid?: number;

    public roomFeatureTid?: number;

    public legalReference?: string;
}
