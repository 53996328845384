import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

import { environment } from "../../../../../environments/environment";
import { EventHelper } from "../../../../base/helpers/event-helper";
import { FormHelper } from "../../../../base/helpers/form-helper";
import { IntentsService } from "../../../../base/services/intents/intents.service";
import { Company } from "../../../entities/core-data/company";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../../entities/documents/entity-link";
import { EntityTypes } from "../../../entities/entity-types";
import { Project } from "../../../entities/projects-processes/project";
import { Identifier } from "../../../identifiers/identifier";
import { SelectEntityIntent } from "../../../intents/master-data/select-entity-intent";
import { SelectEntityIntentOptions } from "../../../intents/master-data/select-entity-intent-options";
import { I18nTextService } from "../../../services/i18n/i18n-text.service";
import { AppIcons } from "../../../services/icons/app-icons";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { EntitySelectionListComponent } from "../../core-data/entity-selection-list/entity-selection-list.component";
import { FormValidationErrorMessages } from "../form-validation-error/form-validation-error-messages";

/**
 * Form to edit projects.
 */
@Component({
    selector: "business-project-form",
    templateUrl: "./project-form.component.html",
    styleUrls: ["./project-form.component.scss"]
})
export class ProjectFormComponent implements OnInit {
    constructor(
        private readonly i18nTextService: I18nTextService,
        private readonly intentsService: IntentsService,
        private readonly formBuilder: FormBuilder
    ) {
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public readonly formHelper: typeof FormHelper = FormHelper;

    private projectField!: Project;

    public readonly formSettings: {
        maxLengthTitle: number;
    } = environment.formSettings.projects;

    @Input()
    public readonly: boolean = false;

    @Input()
    public isValid: boolean = false;

    @Output()
    public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild("companyList")
    public companyListComponent?: EntitySelectionListComponent;

    private formUpdateSubscription?: Subscription;

    private initialized: boolean = false;

    public companyLinks: Array<EntityLink<Company>> = [];

    public validationMessages: FormValidationErrorMessages = new FormValidationErrorMessages(
        [
            {
                controlName: "title", messages: [
                    {
                        type: "required",
                        message: $localize`:@@project.validation.titleRequired:The project title is required.` as string
                    }
                ]
            }
        ]
    );

    public titleControl: FormControl<string|null> = new FormControl<string|null>("");

    public formData: FormGroup = this.formBuilder.group([this.titleControl]);

    public get project(): Project {
        return this.projectField;
    }

    @Input()
    public set project(value: Project) {
        const projectChanged: boolean = this.projectField != value || !Identifier.isEqual(this.projectField?.identifier, value?.identifier);
        this.projectField = value;
        if (projectChanged && this.initialized) {
            this.initialize();
        }
    }

    public ngOnInit(): void {
        this.initialize();
        this.formUpdateSubscription = EventHelper.subscribe(this.formData.statusChanges, this.formChanged, this);
    }

    public ngOnDestroy(): void {
        this.formUpdateSubscription = EventHelper.unsubscribe(this.formUpdateSubscription);
    }

    private initialize(): void {
        this.initialized = false;
        this.projectField = this.projectField ?? CoreDataFactory.createProject();
        this.companyLinks = this.projectField.companyLink ? [this.projectField.companyLink] : [];
        this.assignValues();
        this.initialized = true;
    }

    public getErrorMessage(formControl: FormControl): string|void {
        switch (true) {
            case formControl == this.titleControl && formControl.hasError("required"):
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                return $localize`:@@project.validation.titleRequired:The project title is required.` as string;
        }
    }

    public assignValues(): void {
        this.assignIfChanged(this.titleControl, this.project.title);
    }

    private assignIfChanged(control: AbstractControl, value: any): void {
        if (control.value != value) {
            control.setValue(value);
        }
    }

    public updateValidState(): void {
        this.formData.markAllAsTouched();

        this.isValid = this.formData.status == "VALID" && this.companyLinks.length == 1;
        this.isValidChange.emit(this.isValid);
    }

    private formChanged(): void {
        if (!this.initialized) {
            return;
        }

        this.project.title = FormHelper.formValueToData<string>(this.titleControl.value) || undefined;
        this.project.companyLink = this.companyLinks.length > 0 ? this.companyLinks[0] : undefined;

        this.updateValidState();
        this.companyListComponent?.updateTable();
    }

    public async linkCompany(): Promise<void> {
        const options: SelectEntityIntentOptions = new SelectEntityIntentOptions(
            EntityTypes.company,
            this.project.title ? $localize`:@@project.linkCompanyToProjectWithNameDialogTitle:Link a company to project ${this.project.displayTitle}` as string : $localize`:@@project.linkCompanyToProjectDialogTitle:Link company to project` as string,
            { allowCreate: true });
        await this.intentsService.executeIntentAndWait(SelectEntityIntent, options);

        const companies: Array<Company>|undefined = options.resultEntities as Array<Company>;

        if (companies && companies.length > 0) {
            const company: Company = companies[0];
            const entityLink: EntityLink<Company> = CoreDataFactory.createEntityLinkFromEntity(company);
            this.companyLinks = [entityLink];
        }
        this.formChanged();
    }

    public companyLinksChanged(companyLinks: Array<EntityLink<GenericCoreDataEntity>>): void {
        this.companyLinks = companyLinks as Array<EntityLink<Company>>;
        this.formChanged();
    }
}
