import { EventEmitter, Injectable } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { Api1Entity } from "../../../api/v1/api1-entity";
import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { AsyncHelper } from "../../../base/helpers/async-helper";
import { DateTimeHelper } from "../../../base/helpers/date-time-helper";
import { EventHelper } from "../../../base/helpers/event-helper";
import { TimeService } from "../../../base/services/time/time.service";
import { Timer } from "../../../base/services/time/timer";
import { ResponseBodyListWalkthroughInspectionsDto, TemplateVersionDto, WalkthroughInspectionDto } from "../../../generated/api";
import { LoadStrategies } from "../../common/load-strategies";
import { errorResult, SafeResult, successResult } from "../../common/safe-result";
import { Attachment } from "../../entities/attachments/attachment";
import { AttachmentTypes } from "../../entities/attachments/attachment-types";
import { FileAttachment } from "../../entities/attachments/file-attachment";
import { Building } from "../../entities/core-data/buildings/building";
import { BuildingComplex } from "../../entities/core-data/buildings/building-complex";
import { Company } from "../../entities/core-data/company";
import { GenericCoreDataEntity } from "../../entities/core-data/generic-core-data-entity";
import { Person } from "../../entities/core-data/person";
import { AttachmentsUpdatedEventArguments } from "../../entities/documents/attachments-updated-event-arguments";
import { AuditDocument } from "../../entities/documents/audit-document";
import { AuditDocumentConflict } from "../../entities/documents/audit-document-conflict";
import { DocumentAnswerComment } from "../../entities/documents/document-answer-comment";
import { DocumentSection } from "../../entities/documents/document-section";
import { DocumentValueContainer } from "../../entities/documents/document-value-container";
import { EntityLink } from "../../entities/documents/entity-link";
import { RepeatableSectionUpdateEventArguments } from "../../entities/documents/repeatable-section-update-event-arguments";
import { EntityTypes } from "../../entities/entity-types";
import { AppException } from "../../entities/exceptions/app-exception";
import { QualityAssuranceItem } from "../../entities/quality-assurance/quality-assurance-item";
import { FrontendErrors } from "../../global/frontend-errors";
import { EntityHelper } from "../../helpers/entity-helper";
import { BuildingBusinessIdentifier } from "../../identifiers/building-business-identifier";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { Identifier } from "../../identifiers/identifier";
import { PersonBusinessIdentifier } from "../../identifiers/person-business-identifier";
import { ProcessBusinessIdentifier } from "../../identifiers/process-business-identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { SectionIdentifier } from "../../identifiers/section-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";
import { TemplateVersionTechnicalIdentifier } from "../../identifiers/template-version-technical-identifier";
import { DocumentsApiService } from "../api/documents-api.service";
import { AttachmentLoaderService } from "../attachments/attachment-loader.service";
import { AttachmentsService } from "../attachments/attachments.service";
import { DocumentQueuedUpdateService } from "../document-update/document-queued-update.service";
import { DocumentQuestionUpdate } from "../document-update/update-containers/document-question-update";
import { PartialDocumentUpdate } from "../document-update/update-containers/partial-document-update";
import { BackendTranslationService } from "../localization/backend-translation.service";
import { CoreDataFactory } from "../master-data/core-data-factory";
import { CoreDataService } from "../master-data/core-data-service/core-data.service";
import { CoreDataStorageService } from "../master-data/storage/core-data-storage.service";
import { QualityAssuranceService } from "../quality-assurance/quality-assurance.service";
import { SessionService } from "../session/session.service";
import { StorageService } from "../storage/storage.service";
import { DocumentBackgroundUpdater } from "./document-background-updater";
import { DocumentStorage } from "./document-storage";
import { DocumentSubscriptions } from "./document-subscriptions";
import { DocumentUpdateTypes } from "./document-update-types";
import { DocumentUpdatedEventArguments } from "./document-updated-event-arguments";
import { TemplateService } from "./template.service";

/**
 * Service to work with documents.
 */
@Injectable({
    providedIn: "root"
})
export class DocumentsService {
    constructor(
        private readonly documentQueuedUpdateService: DocumentQueuedUpdateService,
        private readonly sessionService: SessionService,
        private readonly templateService: TemplateService,
        private readonly storageService: StorageService,
        private readonly backendTranslationService: BackendTranslationService,
        private readonly documentsApiService: DocumentsApiService,
        private readonly qualityAssuranceService: QualityAssuranceService,
        private readonly coreDataService: CoreDataService,
        private readonly coreDataStorageService: CoreDataStorageService,
        private readonly attachmentsService: AttachmentsService,
        private readonly attachmentLoaderService: AttachmentLoaderService,
        private readonly timeService: TimeService
    ) {
        this.documentStorage = new DocumentStorage(this.storageService);

        this.documentUpdateTimer = this.timeService.spawnTimer(environment.documentBackgroundUpdateInterval);

        EventHelper.subscribe(this.documentQueuedUpdateService.pendingUpdateStateUpdated, this.pendingUpdateStateUpdated, this);
        EventHelper.subscribe(this.documentQueuedUpdateService.updatedDocumentReceived, this.updateDocumentReceived, this);
        EventHelper.subscribe(this.documentUpdateTimer.elapsed, this.documentUpdateTimerElapsed, this);

        EventHelper.subscribe(this.attachmentsService.attachmentDeleted, this.attachmentDeleted, this);
    }

    public documentUpdatedInternally: EventEmitter<DocumentUpdatedEventArguments> = new EventEmitter<DocumentUpdatedEventArguments>();

    public documentUpdatedExternally: EventEmitter<DocumentUpdatedEventArguments> = new EventEmitter<DocumentUpdatedEventArguments>();

    public qualityAssuranceItemsUpdated: EventEmitter<DocumentIdentifier> = new EventEmitter<DocumentIdentifier>();

    private readonly documentStorage: DocumentStorage;

    private subscriptions: Map<DocumentBusinessIdentifier, DocumentSubscriptions> = new Map<DocumentBusinessIdentifier, DocumentSubscriptions>();

    private documentUpdateTimer: Timer;

    private checkingForUpdates: boolean = false;

    private backgroundUpdater: DocumentBackgroundUpdater = new DocumentBackgroundUpdater(this, this.qualityAssuranceService, this.documentsApiService);

    private livingDocumentInstances: Map<DocumentBusinessIdentifier, AuditDocument> = new Map<DocumentBusinessIdentifier, AuditDocument>();

    private documentsLoadingQueue: Set<DocumentBusinessIdentifier> = new Set<DocumentBusinessIdentifier>();

    private documentUpdateTimerElapsed(): void {
        if (!this.sessionService.activeAccountId) {
            return;
        }
        this.checkSubscriptionsForServerUpdates().then();
    }

    public async checkSubscriptionsForServerUpdates(onlyThisDocument?: DocumentBusinessIdentifier): Promise<number> {
        if (this.checkingForUpdates) {
            return this.subscriptions.size;
        }
        this.checkingForUpdates = true;

        try {
            for (const subscription of this.subscriptions.values()) {
                if (onlyThisDocument && subscription.document.identifier.businessIdentifier != onlyThisDocument || !subscription.refreshInBackground) {
                    continue;
                }
                await this.updateDocument(subscription.document);
            }
        } finally {
            this.checkingForUpdates = false;
        }
        return this.subscriptions.size;
    }

    private async updateDocument(document: AuditDocument): Promise<void> {
        const documentUpdate: ResponseBodyListWalkthroughInspectionsDto|undefined = await this.backgroundUpdater.checkForUpdates(document);
        if (documentUpdate) {
            await this.applyUpdatedDocument(document, documentUpdate);
        }
    }

    public subscribeToDocument(document: AuditDocument, activateBackgroundRefresh: boolean): void {
        const existingSubscriptions: DocumentSubscriptions|undefined = this.subscriptions.get(document.identifier.businessIdentifier);
        if (existingSubscriptions && existingSubscriptions.document == document) {
            // We already have a subscription, check if we have to activate background refresh
            if (activateBackgroundRefresh && !existingSubscriptions.refreshInBackground) {
                existingSubscriptions.refreshInBackground = true;
                this.documentUpdateTimer.start();
            }
            return;
        }
        if (existingSubscriptions?.document) {
            this.unsubscribeFromDocument(existingSubscriptions?.document);
        }

        this.documentUpdateTimer.start();

        const subscriptions: DocumentSubscriptions = new DocumentSubscriptions(document);
        subscriptions.refreshInBackground = activateBackgroundRefresh;
        subscriptions.questionUpdated = EventHelper.subscribe(document.instance.questionUpdated, (questionTemplateIdentifier: QuestionTemplateIdentifier) => { this.questionUpdated(document, questionTemplateIdentifier); }, this);
        subscriptions.attachmentLinksUpdated = EventHelper.subscribe(document.instance.attachmentLinksUpdated, (eventData: AttachmentsUpdatedEventArguments) => { this.attachmentLinksUpdated(document, eventData); }, this);
        subscriptions.repeatableSectionUpdated = EventHelper.subscribe(document.instance.repeatableSectionUpdated, (eventArgs: RepeatableSectionUpdateEventArguments) => { this.repeatableSectionUpdated(document, eventArgs.sectionTemplateIdentifier, eventArgs.sectionIdentifier); }, this);
        this.subscriptions.set(document.identifier.businessIdentifier, subscriptions);
    }

    public unsubscribeFromDocument(document: AuditDocument|undefined): void {
        if (!document) {
            return;
        }
        const subscriptions: DocumentSubscriptions|undefined = this.subscriptions.get(document.identifier.businessIdentifier);
        if (!subscriptions) {
            return;
        }

        subscriptions.questionUpdated = EventHelper.unsubscribe(subscriptions.questionUpdated);
        subscriptions.attachmentLinksUpdated = EventHelper.unsubscribe(subscriptions.attachmentLinksUpdated);
        subscriptions.repeatableSectionUpdated = EventHelper.unsubscribe(subscriptions.repeatableSectionUpdated);
        this.subscriptions.delete(document.identifier.businessIdentifier);

        if (this.subscriptions.size <= 0) {
            this.documentUpdateTimer.stop();
        }
    }

    public questionUpdated(document: AuditDocument, questionTemplateIdentifier: QuestionTemplateIdentifier): void {
        this.documentStorage.updateDocument(document).then();

        const valueContainer: DocumentValueContainer|undefined = document.instance.getValueContainer(questionTemplateIdentifier);
        if (valueContainer) {
            const pendingUpdate: PartialDocumentUpdate = this.documentQueuedUpdateService.saveChangedQuestion(document.identifier, valueContainer.sectionIdentifier, valueContainer.questionTemplateIdentifier, valueContainer.questionIdentifier, document.template.documentType, valueContainer);
            valueContainer.saveState = pendingUpdate.saveState;
        }

        const eventArguments: DocumentUpdatedEventArguments = new DocumentUpdatedEventArguments(document);
        eventArguments.questionTemplateIdentifier = questionTemplateIdentifier;
        this.documentUpdatedInternally.emit(eventArguments);
    }

    private attachmentLinksUpdated(document: AuditDocument, eventData: AttachmentsUpdatedEventArguments): void {
        this.documentStorage.updateDocument(document).then();
        if (!eventData.questionTemplateIdentifier) {
            return;
        }

        const valueContainer: DocumentValueContainer|undefined = document.instance.getValueContainer(eventData.questionTemplateIdentifier);
        if (valueContainer) {
            const pendingUpdate: PartialDocumentUpdate = this.documentQueuedUpdateService.saveChangedAttachments(document.identifier, valueContainer.sectionIdentifier, valueContainer.questionTemplateIdentifier, valueContainer.questionIdentifier, document.template.documentType, valueContainer);
            valueContainer.saveState = pendingUpdate.saveState;
        }

        const eventArguments: DocumentUpdatedEventArguments = new DocumentUpdatedEventArguments(document);
        eventArguments.questionTemplateIdentifier = eventData.questionTemplateIdentifier;
        this.documentUpdatedInternally.emit(eventArguments);
    }

    private repeatableSectionUpdated(document: AuditDocument, sectionTemplateIdentifier: SectionTemplateIdentifier, sectionIdentifier: SectionIdentifier): void {
        this.documentStorage.updateDocument(document).then();

        const rows: Array<DocumentSection>|undefined = document.instance.getRepeatableQuestion(sectionTemplateIdentifier);
        if (rows) {
            this.documentQueuedUpdateService.saveChangedRepeatableSection(document.identifier, sectionTemplateIdentifier, sectionIdentifier, document.template.documentType, rows);
        }

        const eventArguments: DocumentUpdatedEventArguments = new DocumentUpdatedEventArguments(document);
        eventArguments.repeatableSectionIdentifier = sectionIdentifier;
        this.documentUpdatedInternally.emit(eventArguments);
    }

    private async pendingUpdateStateUpdated(pendingQuestion: PartialDocumentUpdate): Promise<void> {
        if (pendingQuestion.updateType != DocumentUpdateTypes.question && pendingQuestion.updateType != DocumentUpdateTypes.attachmentLink) {
            return;
        }
        const questionUpdate: DocumentQuestionUpdate = pendingQuestion as DocumentQuestionUpdate;

        const document: AuditDocument|undefined = await this.getDocument(questionUpdate.documentIdentifier.businessIdentifier, false);
        if (!document) {
            return;
        }

        const valueContainer: DocumentValueContainer|undefined = questionUpdate.questionTemplateIdentifier ? document.instance.getValueContainer(questionUpdate.questionTemplateIdentifier) : undefined;
        if (!valueContainer) {
            return;
        }

        valueContainer.saveState = questionUpdate.saveState;
        valueContainer.synchronizationError = questionUpdate.synchronizationErrorString;
    }

    private async updateDocumentReceived(updatedDocumentDto: WalkthroughInspectionDto|ResponseBodyListWalkthroughInspectionsDto): Promise<void> {
        let businessId: DocumentBusinessIdentifier|undefined;
        if ("walkthroughInspections" in updatedDocumentDto) {
            if (updatedDocumentDto.walkthroughInspections && updatedDocumentDto.walkthroughInspections?.length > 0) {
                businessId = updatedDocumentDto.walkthroughInspections[0].businessId as DocumentBusinessIdentifier;
            }
        } else if ("businessId" in updatedDocumentDto) {
            businessId = updatedDocumentDto.businessId as DocumentBusinessIdentifier;
        }

        if (businessId) {
            const document: AuditDocument|undefined = await this.getLivingDocument(businessId as DocumentBusinessIdentifier);
            if (document) {
                await this.applyUpdatedDocument(document, updatedDocumentDto);
            }
        }
    }

    public async createDocument(processBusinessIdentifier: ProcessBusinessIdentifier, templateId: TemplateVersionTechnicalIdentifier, auditorBusinessIdentifier?: PersonBusinessIdentifier, visitDate?: string, buildingIds?: Array<BuildingBusinessIdentifier>): Promise<DocumentIdentifier>|never {
        this.sessionService.requiresAccount();

        try {
            const inspection: ResponseBodyListWalkthroughInspectionsDto|undefined = await this.documentsApiService.createWalkthroughInspection({
                templateVersionId: templateId,
                processBusinessId: processBusinessIdentifier,
                auditorBusinessId: auditorBusinessIdentifier || undefined,
                buildingBusinessIds: buildingIds,
                visitDate: visitDate ? DateTimeHelper.sanitizeDateTime(visitDate) : undefined
            });
            if (!inspection?.walkthroughInspections?.length) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error("Unable to create audit, server response was empty.");
            }

            return Identifier.create<DocumentIdentifier>(inspection.walkthroughInspections[0].businessId, inspection.walkthroughInspections[0].technicalId);
        } catch (error) {
            throw new AppException(FrontendErrors.FE16UnableToCreateAudit, $localize`:@@exception.fe16UnableToCreateAudit:The audit could no be created.`, error as Error);
        }
    }

    private async getCachedDocumentByBusinessIdentifier(documentBusinessIdentifier: DocumentBusinessIdentifier): Promise<AuditDocument|undefined> {
        const cachedDocument: AuditDocument|undefined = await this.documentStorage.getLatestByBusinessIdentifier(documentBusinessIdentifier);
        if (!cachedDocument) {
            return undefined;
        }

        for (const linkedEntity of cachedDocument.linkedEntities) {
            const newLinkedEntity: GenericCoreDataEntity|undefined = await this.coreDataStorageService.get(linkedEntity.identifier, linkedEntity.entityType);
            if (newLinkedEntity) {
                linkedEntity.entity = newLinkedEntity;
            }
        }

        return cachedDocument;
    }

    private async waitForDocumentLoad(documentBusinessIdentifier: DocumentBusinessIdentifier): Promise<AuditDocument> {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const documentLoadTimeout: number = environment.documentLoadTimeout * 1000;
        const timeoutEndTime: number = Date.now() + documentLoadTimeout;
        while (this.documentsLoadingQueue.has(documentBusinessIdentifier) && Date.now() < timeoutEndTime) {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            await AsyncHelper.sleep(100);
        }
        const document: AuditDocument|undefined = this.livingDocumentInstances.get(documentBusinessIdentifier);
        if (!document) {
            throw new AppException(FrontendErrors.FE21UnableGetDocumentWithId, $localize`:@@exception.fe21UnableGetDocumentWithId:The document with the id ${documentBusinessIdentifier}:businessId: could not be found.`);
        }
        return document;
    }

    private async getLivingDocument(documentBusinessIdentifier: DocumentBusinessIdentifier, activateBackgroundRefresh: boolean = false, forceRefresh: boolean = false): Promise<AuditDocument|undefined> {
        let livingDocument: AuditDocument|undefined = this.livingDocumentInstances.get(documentBusinessIdentifier);
        if (livingDocument) {
            this.subscribeToDocument(livingDocument, activateBackgroundRefresh);
            if (forceRefresh) {
                await this.updateDocument(livingDocument);
            }
            return livingDocument;
        }

        if (this.documentsLoadingQueue.has(documentBusinessIdentifier)) {
            livingDocument = await this.waitForDocumentLoad(documentBusinessIdentifier);
            if (forceRefresh) {
                await this.updateDocument(livingDocument);
            }
            return livingDocument!;
        }

        this.documentsLoadingQueue.add(documentBusinessIdentifier);

        const cachedDocument: AuditDocument|undefined = await this.getCachedDocumentByBusinessIdentifier(documentBusinessIdentifier);
        if (cachedDocument) {
            await this.postLoadMissingLinkedEntities(cachedDocument, LoadStrategies.preferCached);
            this.subscribeToDocument(cachedDocument, activateBackgroundRefresh);
            this.livingDocumentInstances.set(documentBusinessIdentifier, cachedDocument);
            this.documentsLoadingQueue.delete(documentBusinessIdentifier);
            if (forceRefresh) {
                await this.updateDocument(cachedDocument);
            }
            return cachedDocument;
        }

        return undefined;
    }

    public async getDocument(documentBusinessIdentifier: DocumentBusinessIdentifier, activateBackgroundRefresh: boolean = false, forceRefresh: boolean = false): Promise<AuditDocument>|never {
        this.sessionService.requiresAccount();

        const livingDocument: AuditDocument|undefined = await this.getLivingDocument(documentBusinessIdentifier, activateBackgroundRefresh, forceRefresh);
        if (livingDocument) {
            return livingDocument;
        }

        const documentResponse: ResponseBodyListWalkthroughInspectionsDto|undefined = await this.documentsApiService.getWalkthroughInspection(documentBusinessIdentifier);
        if (!documentResponse.walkthroughInspections || documentResponse.walkthroughInspections.length <= 0) {
            this.documentsLoadingQueue.delete(documentBusinessIdentifier);
            throw new AppException(FrontendErrors.FE21UnableGetDocumentWithId, $localize`:@@exception.fe21UnableGetDocumentWithId:The document with the id ${documentBusinessIdentifier}:businessId: could not be found.`);
        }

        const inspection: WalkthroughInspectionDto = EntityHelper.getLatestByCreatedAndUpdatedTime(documentResponse.walkthroughInspections) ?? documentResponse.walkthroughInspections[0];

        // Get attachments
        const attachmentsResult: SafeResult<Array<FileAttachment>, AppException> = await this.attachmentsService.getAll(Identifier.create(inspection.businessId, inspection.technicalId) as DocumentIdentifier, AttachmentTypes.all, LoadStrategies.preferServer);
        const attachments: Array<FileAttachment> = attachmentsResult.isSuccess() ? attachmentsResult.result : [];

        const buildingComplexes: Array<BuildingComplex> = await this.dtosToEntities<BuildingComplex>(documentResponse.buildingComplexes, EntityTypes.buildingComplex);
        const buildings: Array<Building> = [];
        const companies: Array<Company> = await this.dtosToEntities<Company>(documentResponse.companies, EntityTypes.company);
        const persons: Array<Person> = await this.dtosToEntities<Person>(documentResponse.persons, EntityTypes.person);
        const document: AuditDocument = await this.walkthroughInspectionToAuditDocument(inspection, buildingComplexes, buildings, companies, persons, attachments);

        // Get review data
        const reviewData: SafeResult<Array<QualityAssuranceItem>, AppException> = await this.qualityAssuranceService.getItems(document.identifier);
        if (reviewData.isSuccess()) {
            document.qualityAssuranceItems = reviewData.result ?? [];
        }

        await this.postLoadMissingLinkedEntities(document, LoadStrategies.preferCached);

        this.subscribeToDocument(document, activateBackgroundRefresh);
        await this.documentStorage.updateDocument(document);

        this.livingDocumentInstances.set(documentBusinessIdentifier, document);
        this.documentsLoadingQueue.delete(documentBusinessIdentifier);

        return document;
    }

    public async getDocumentRevisions(documentBusinessIdentifier: DocumentBusinessIdentifier): Promise<SafeResult<Array<AuditDocument>, AppException>> {
        const accountStatus: SafeResult<void, AppException> = this.sessionService.requiresAccountSafeResult();
        if (accountStatus.isError()) {
            return errorResult(accountStatus.error);
        }

        let documentResponse: ResponseBodyListWalkthroughInspectionsDto|undefined;
        try {
            documentResponse = await this.documentsApiService.getWalkthroughInspection(documentBusinessIdentifier);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE21UnableGetDocumentWithId, $localize`:@@exception.fe21UnableGetDocumentWithId:The document with the id ${documentBusinessIdentifier}:businessId: could not be found.`, error as Error));
        }

        if (!documentResponse.walkthroughInspections || documentResponse.walkthroughInspections.length <= 0) {
            this.documentsLoadingQueue.delete(documentBusinessIdentifier);
            return errorResult(new AppException(FrontendErrors.FE21UnableGetDocumentWithId, $localize`:@@exception.fe21UnableGetDocumentWithId:The document with the id ${documentBusinessIdentifier}:businessId: could not be found.`));
        }

        const result: Array<AuditDocument> = [];

        const buildingComplexes: Array<BuildingComplex> = await this.dtosToEntities<BuildingComplex>(documentResponse.buildingComplexes, EntityTypes.buildingComplex);
        const buildings: Array<Building> = [];
        const companies: Array<Company> = await this.dtosToEntities<Company>(documentResponse.companies, EntityTypes.company);
        const persons: Array<Person> = await this.dtosToEntities<Person>(documentResponse.persons, EntityTypes.person);

        for (const documentDto of documentResponse.walkthroughInspections) {
            const document: AuditDocument = await this.walkthroughInspectionToAuditDocument(documentDto, buildingComplexes, buildings, companies, persons, []);
            result.push(document);
        }

        return successResult(result);
    }

    private async walkthroughInspectionToAuditDocument(inspection: WalkthroughInspectionDto, buildingComplexes: Array<BuildingComplex>, buildings: Array<Building>, companies: Array<Company>, persons: Array<Person>, attachments: Array<Attachment>): Promise<AuditDocument>|never {
        if (!inspection.templateVersionId) {
            throw new AppException(FrontendErrors.FE19DocumentIsMissingTemplateId, $localize`:@@exception.fe19DocumentIsMissingTemplateId:The document with the ID ${inspection.businessId}:businessId:\:${inspection.technicalId}:technicalId: is missing a template id.`);
        }
        const template: TemplateVersionDto = await this.templateService.getTemplateDto(inspection.templateVersionId as TemplateVersionTechnicalIdentifier);
        const document: AuditDocument = Api1Converter.walkthroughInspectionDtoToDocument(inspection, template, buildingComplexes, buildings, companies, persons, attachments);
        await this.loadLinkedEntitiesWithoutBusinessIdentifier(document);
        await this.loadCachedAttachments(document);
        await this.backendTranslationService.translateDocument(document);
        this.applyPendingQuestionChanges(document);

        return document;
    }

    private async loadLinkedEntitiesWithoutBusinessIdentifier(document: AuditDocument): Promise<void> {
        let updated: boolean = false;
        for (const linkedEntity of document.linkedEntities) {
            if (!linkedEntity.identifier.businessIdentifier && linkedEntity.identifier.technicalIdentifier) {
                try {
                    linkedEntity.entity = await this.coreDataService.loadEntity(linkedEntity.identifier, linkedEntity.entityType, LoadStrategies.preferCached);
                    if (linkedEntity.entity) {
                        linkedEntity.identifier = linkedEntity.entity.identifier;
                        updated = true;
                    }
                } catch (error) {
                    // Did not work, possibly because the internet connection is bad
                    console.warn(error);
                }
            }
        }

        if (updated) {
            await this.documentStorage.updateDocument(document);
        }
    }

    private async loadCachedAttachments(document: AuditDocument): Promise<void> {
        for (const valueContainer of document.instance.getAllValueContainers()) {
            for (let attachmentIndex: number = 0; attachmentIndex < valueContainer.linkedAttachments.length; attachmentIndex++) {
                const fileAttachment: FileAttachment = valueContainer.linkedAttachments[attachmentIndex] as FileAttachment;
                if (fileAttachment) {
                    valueContainer.linkedAttachments[attachmentIndex] = await this.attachmentLoaderService.getStoredAttachment(fileAttachment.identifier);
                }
            }
        }
    }

    private applyPendingQuestionChanges(document: AuditDocument): void {
        const relevantUpdates: Array<DocumentQuestionUpdate> = this.documentQueuedUpdateService.pendingUpdates.filter((update: PartialDocumentUpdate) =>
            (update.updateType == DocumentUpdateTypes.question || update.updateType == DocumentUpdateTypes.attachmentLink)
            && update.documentIdentifier.businessIdentifier == document.identifier.businessIdentifier
        ) as Array<DocumentQuestionUpdate>;

        for (const update of relevantUpdates) {
            const valueContainer: DocumentValueContainer|undefined = update.questionTemplateIdentifier
                ? document.instance.getValueContainer(update.questionTemplateIdentifier) ?? document.instance.createValueContainer(update.questionTemplateIdentifier!, update.questionIdentifier, update.sectionTemplateIdentifier, update.sectionIdentifier)
                : undefined;
            if (valueContainer) {
                valueContainer.saveState = update.saveState;
                valueContainer.answers = update.answers;
                valueContainer.linkedAttachments = update.linkedAttachments;
                if (update.commentTemplateIdentifier || update.commentValue) {
                    valueContainer.comment = valueContainer.comment ?? new DocumentAnswerComment();
                    valueContainer.comment.identifier = update.commentTemplateIdentifier;
                    valueContainer.comment.text = update.commentValue;
                }
            }
        }
    }

    public async getAllWalkthroughInspections(): Promise<ResponseBodyListWalkthroughInspectionsDto>|never {
        if (!this.sessionService.activeAccount) {
            throw new AppException(FrontendErrors.FE14UnableToTalkToServerAccountNotSet, $localize`:@@exception.fe14UnableToTalkToServerAccountNotSet:A request to the server could not be sent because no account has been selected. Please select an account or log-out and log-in again.`);
        }

        const inspections: ResponseBodyListWalkthroughInspectionsDto|undefined = await this.documentsApiService.listWalkthroughInspections();
        if (!inspections) {
            throw new AppException(FrontendErrors.FE20UnableToGetWalkthroughInspections, $localize`:@@exception.fe20UnableToGetWalkthroughInspections:The list of walkthrough inspections could not be fetched.`);
        }

        return inspections;
    }

    public async dtosToEntities<TEntity extends GenericCoreDataEntity>(dtos: Array<Api1Entity>|undefined, entityType: EntityTypes): Promise<Array<TEntity>> {
        const result: Array<TEntity> = [];
        for (const dto of dtos ?? []) {
            const entity: TEntity|undefined = CoreDataFactory.createFromServerDto(dto, entityType);
            if (entity) {
                await this.coreDataService.updateEntityCache(entity);
                result.push(entity);
            }
        }
        return result;
    }

    public activateCaching(active: boolean): void {
        this.documentStorage.activateCaching(active);
    }

    public async saveDocument(document: AuditDocument): Promise<void>|never {
        const updatedDocumentDto: ResponseBodyListWalkthroughInspectionsDto|undefined = await this.documentsApiService.saveDocument(document);
        if (updatedDocumentDto) {
            await this.applyUpdatedDocument(document, updatedDocumentDto);
        }
    }

    public async saveEntityUpdateDocument<TDocument extends AuditDocument>(entity: GenericCoreDataEntity, document: TDocument, propertyName: keyof TDocument, archive: boolean): Promise<GenericCoreDataEntity> {
        EntityHelper.typeAssertIdentifierPropertyName(document, propertyName as string);

        const updatedEntity: GenericCoreDataEntity = await this.coreDataService.saveEntity(entity, archive);

        if (Array.isArray(document[propertyName])) {
            const entityLinkArray: Array<EntityLink<GenericCoreDataEntity>> = document[propertyName] as unknown as Array<EntityLink<GenericCoreDataEntity>>;
            if (entityLinkArray) {
                const itemToBeUpdated: EntityLink<GenericCoreDataEntity>|undefined = entityLinkArray.find((link: EntityLink<GenericCoreDataEntity>) => link.entityType == entity.entityType && link.identifier.businessIdentifier == entity.identifier.businessIdentifier);
                if (itemToBeUpdated) {
                    itemToBeUpdated.entity = updatedEntity;
                    itemToBeUpdated.identifier = updatedEntity.identifier;
                    itemToBeUpdated.latestEntity = undefined;
                }
            }
        } else {
            document[propertyName] = updatedEntity.identifier as unknown as TDocument[keyof TDocument];
        }

        await this.saveDocument(document);

        return updatedEntity;
    }

    public async switchEntityUpdateDocument<TDocument extends AuditDocument>(oldEntity: GenericCoreDataEntity|undefined, newEntity: GenericCoreDataEntity, document: TDocument, propertyName: keyof TDocument): Promise<GenericCoreDataEntity> {
        EntityHelper.typeAssertIdentifierPropertyName(document, propertyName as string);

        if (Array.isArray(document[propertyName])) {
            // Find item to be replaced
            const entityLinkArray: Array<EntityLink<GenericCoreDataEntity>> = document[propertyName] as unknown as Array<EntityLink<GenericCoreDataEntity>>;
            if (entityLinkArray) {
                const itemToBeUpdated: EntityLink<GenericCoreDataEntity>|undefined = oldEntity ? entityLinkArray.find((link: EntityLink<GenericCoreDataEntity>) => link.entityType == oldEntity.entityType && link.identifier.businessIdentifier == oldEntity.identifier.businessIdentifier) : undefined;
                if (itemToBeUpdated) {
                    itemToBeUpdated.entity = newEntity;
                    itemToBeUpdated.identifier = newEntity.identifier;
                    itemToBeUpdated.latestEntity = undefined;
                } else {
                    entityLinkArray.push(CoreDataFactory.createEntityLinkFromEntity(newEntity));
                }
            }
        } else {
            document[propertyName] = newEntity.identifier as unknown as TDocument[keyof TDocument];
        }

        await this.saveDocument(document);

        return newEntity;
    }

    private async applyUpdatedDocument(existingDocument: AuditDocument, updateDto: ResponseBodyListWalkthroughInspectionsDto|WalkthroughInspectionDto): Promise<void> {
        let buildingComplexes: Array<BuildingComplex>;
        let buildings: Array<Building>;
        let companies: Array<Company>;
        let persons: Array<Person>;
        let documentDto: WalkthroughInspectionDto;
        if ("walkthroughInspections" in updateDto) {
            buildingComplexes = await this.dtosToEntities<BuildingComplex>(updateDto.buildingComplexes, EntityTypes.buildingComplex);
            buildings = [];
            companies = await this.dtosToEntities<Company>(updateDto.companies, EntityTypes.company);
            persons = await this.dtosToEntities<Person>(updateDto.persons, EntityTypes.person);
            documentDto = EntityHelper.getLatestByCreatedAndUpdatedTime(updateDto.walkthroughInspections!) || updateDto.walkthroughInspections![0];
        } else {
            buildingComplexes = existingDocument.linkedEntities.filter((entityLink: EntityLink<GenericCoreDataEntity>) => entityLink.entityType == EntityTypes.buildingComplex).map((entityLink: EntityLink<GenericCoreDataEntity>) => entityLink.entity as BuildingComplex);
            buildings = existingDocument.linkedEntities.filter((entityLink: EntityLink<GenericCoreDataEntity>) => entityLink.entityType == EntityTypes.building).map((entityLink: EntityLink<GenericCoreDataEntity>) => entityLink.entity as Building);
            companies = existingDocument.linkedEntities.filter((entityLink: EntityLink<GenericCoreDataEntity>) => entityLink.entityType == EntityTypes.company).map((entityLink: EntityLink<GenericCoreDataEntity>) => entityLink.entity as Company);
            persons = existingDocument.linkedEntities.filter((entityLink: EntityLink<GenericCoreDataEntity>) => entityLink.entityType == EntityTypes.person).map((entityLink: EntityLink<GenericCoreDataEntity>) => entityLink.entity as Person);
            documentDto = updateDto as WalkthroughInspectionDto;
        }

        const existingQualityAssuranceItems: Array<QualityAssuranceItem> = existingDocument.qualityAssuranceItems;
        const updatedDocument: AuditDocument = await this.walkthroughInspectionToAuditDocument(documentDto, buildingComplexes, buildings, companies, persons, []);
        this.applyPendingQuestionChanges(updatedDocument);
        await this.postLoadMissingLinkedEntities(updatedDocument, LoadStrategies.preferCached);
        if (updatedDocument.qualityAssuranceItems.length <= 0) {
            updatedDocument.qualityAssuranceItems = existingQualityAssuranceItems;
        }

        const conflicts: Array<AuditDocumentConflict> = existingDocument.applyFromUpdatedDocument(updatedDocument, true);
        if (conflicts.length > 0) {
            // With the current implementation to conflicts can occur, this can change later
            console.warn("CONFLICT (applyFromUpdatedDocument)", conflicts.length, conflicts);
        } else {
            await this.documentStorage.updateDocument(existingDocument);
            this.documentUpdatedExternally.emit(new DocumentUpdatedEventArguments(existingDocument));
        }
    }

    private async postLoadMissingLinkedEntities(document: AuditDocument, loadStrategy: LoadStrategies): Promise<void> {
        for (const entityLink of document.linkedEntities) {
            if (Identifier.isEmpty(entityLink.identifier)) {
                entityLink.entity = CoreDataFactory.createFromEntityType(entityLink.entityType);
                continue;
            }
            try {
                const loadedEntity: GenericCoreDataEntity|undefined = await this.coreDataService.loadEntity(entityLink.identifier, entityLink.entityType, loadStrategy);
                entityLink.entity = loadedEntity ?? entityLink.entity;
            } catch (error) {
                console.error(error);
            }
        }

        await this.loadLinkedEntitiesWithoutBusinessIdentifier(document);
    }

    public async deleteDocument(documentBusinessIdentifier: DocumentBusinessIdentifier): Promise<SafeResult<void, AppException>> {
        const result: SafeResult<void, Error> = await this.documentsApiService.deleteDocument(documentBusinessIdentifier);
        if (result.isError()) {
            return errorResult(new AppException(FrontendErrors.FE44UnableToDeleteDocument, $localize`:@@exception.fe44UnableToDeleteDocument:Unable to delete document "${documentBusinessIdentifier}".`, result.error));
        }
        return successResult(undefined);
    }

    public async applyUpdatedQualityAssuranceItems(document: AuditDocument, newItems: Array<QualityAssuranceItem>): Promise<void> {
        document.qualityAssuranceItems = newItems;
        await this.documentStorage.updateDocument(document);
        this.qualityAssuranceItemsUpdated.emit(document.identifier);
    }

    private async attachmentDeleted(attachment: FileAttachment): Promise<void> {
        for (const livingDocument of this.livingDocumentInstances.values()) {
            if (attachment.linkedDocuments.some((link: DocumentIdentifier) => link.businessIdentifier && link.businessIdentifier == livingDocument.identifier.businessIdentifier || link.technicalIdentifier && link.technicalIdentifier == livingDocument.identifier.technicalIdentifier)) {
                await this.updateDocument(livingDocument);
                return;
            }
        }
    }
}
