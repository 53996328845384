<table #table [dataSource]="rows" mat-table>
    <ng-container matColumnDef="checked">
        <td (click)="stopPropagation($event)" *matCellDef="let row" mat-cell>
            <mat-checkbox (change)="rowChanged($event)" [(ngModel)]="row.checked" color="primary"></mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="title">
        <td *matCellDef="let row" mat-cell>
            {{row.title}}
        </td>
    </ng-container>

    <ng-container matColumnDef="badge">
        <td *matCellDef="let row" mat-cell>
            <business-identifier-badge [canOpen]="false" [entityLink]="row.entityLink"></business-identifier-badge>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <td *matCellDef="let row" mat-cell>
            <button (click)="edit(row, $event)" *ngIf="canEdit && row.entityLink?.entity" class="actionButton" color="primary" mat-stroked-button>
                <fa-icon [icon]="appIcons.genericEdit"></fa-icon>
            </button>
            <button (click)="edit(row, $event)" *ngIf="canOpen && !canEdit && row.entityLink?.entity" class="actionButton" color="primary" mat-stroked-button>
                <fa-icon [icon]="appIcons.genericOpenDialog"></fa-icon>
            </button>
            <button (click)="delete(row, $event)" *ngIf="canDelete" class="actionButton" color="warn" mat-stroked-button>
                <fa-icon [icon]="appIcons.genericDelete"></fa-icon>
            </button>
        </td>
    </ng-container>

    <tr (click)="toggle(row)" *matRowDef="let row; columns: displayedColumns;" [class.clickable]="canSelect" [class.hoverHighlight]="canSelect" mat-row></tr>
</table>
