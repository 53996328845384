import { CryptoHelper } from "../../../../base/helpers/crypto-helper";
import { DateTimeHelper } from "../../../../base/helpers/date-time-helper";
import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { DocumentTypes } from "../../../entities/documents/document-types";
import { QuestionSaveStates } from "../../../entities/documents/question-save-states";
import { StorableEntity } from "../../../entities/storable-entity";
import { DocumentIdentifier } from "../../../identifiers/document-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { PersonBusinessIdentifier } from "../../../identifiers/person-business-identifier";
import { DocumentUpdateTypes } from "../../documents/document-update-types";
import { PartialDocumentUpdateStorageDto } from "./partial-document-update-storage-dto";

/**
 * Container to hold the information necessary to update document-update.
 */
export abstract class PartialDocumentUpdate implements StorableEntity<PartialDocumentUpdateStorageDto> {
    protected constructor(accountIdentifier: number, personBusinessIdentifier: PersonBusinessIdentifier, documentId: DocumentIdentifier, documentType: DocumentTypes, init?: Partial<PartialDocumentUpdate>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.accountIdentifier = accountIdentifier;
        this.personBusinessIdentifier = personBusinessIdentifier;
        this.documentIdentifier = documentId;
        this.documentType = documentType;
    }

    public classId: string = CryptoHelper.getUUID();

    public abstract updateType: DocumentUpdateTypes;

    public saveState: QuestionSaveStates = QuestionSaveStates.updateCreated;

    public updateCreated: string = DateTimeHelper.utcNow();

    public accountIdentifier: number;

    public personBusinessIdentifier: PersonBusinessIdentifier;

    public documentIdentifier: DocumentIdentifier;

    public documentType: DocumentTypes;

    public synchronizationErrorString?: string;

    // If this is true, the update is not processed within a batch of multiple updates.
    public noBatchUpdate: boolean = false;

    protected abstract partialUpdateFromStorageDto(dto: PartialDocumentUpdateStorageDto|undefined): void;

    protected abstract partialUpdateToStorageDto(): PartialDocumentUpdateStorageDto;

    public fromStorageDto(dto: PartialDocumentUpdateStorageDto|undefined): void {
        this.partialUpdateFromStorageDto(dto);

        this.classId = dto?.classId || CryptoHelper.getUUID();

        this.updateType = TypeScriptHelper.dtoToEnum(DocumentUpdateTypes, dto?.updateType, DocumentUpdateTypes.unknown) as DocumentUpdateTypes;
        this.saveState = TypeScriptHelper.dtoToEnum(QuestionSaveStates, dto?.updateType, QuestionSaveStates.none) as QuestionSaveStates;

        this.updateCreated = dto?.updateCreated ?? DateTimeHelper.utcNow();
        this.accountIdentifier = dto?.accountIdentifier ?? 0;
        this.personBusinessIdentifier = (dto?.personBusinessIdentifier ?? "") as PersonBusinessIdentifier;
        this.documentIdentifier = Identifier.create(dto?.documentBusinessIdentifier, dto?.documentTechnicalIdentifier);
        this.documentType = TypeScriptHelper.dtoToEnum(DocumentTypes, dto?.documentType, DocumentTypes.unknown) as DocumentTypes;

        this.noBatchUpdate = dto?.noBatchUpdate ?? false;
    }

    public toStorageDto(): PartialDocumentUpdateStorageDto {
        const dto: PartialDocumentUpdateStorageDto = this.partialUpdateToStorageDto();

        dto.classId = this.classId;

        dto.updateType = this.updateType;
        dto.saveState = this.saveState;

        dto.updateCreated = this.updateCreated;
        dto.accountIdentifier = this.accountIdentifier;
        dto.personBusinessIdentifier = this.personBusinessIdentifier;
        dto.documentBusinessIdentifier = this.documentIdentifier.businessIdentifier;
        dto.documentTechnicalIdentifier = this.documentIdentifier.technicalIdentifier;
        dto.documentType = DocumentTypes[this.documentType];

        dto.noBatchUpdate = this.noBatchUpdate;

        return dto;
    }
}
