import { AppModes } from "./app-modes";
import { ViewModes } from "./view-modes";

/**
 * The container for various states of the application.
 */
export class AppState {
    public appMode: AppModes = AppModes.none;

    public inspectionMode: boolean = false;

    public focusMode: boolean = false;

    public followMode: boolean = false;

    public viewMode: ViewModes = ViewModes.mobile;

    public frontendUpdateAvailable: boolean = false;

    public backendUpdateAvailable: boolean = false;

    public oldBackendVersion?: string;

    public newBackendVersion?: string;

    public appLoadingStage?: string;

    public pwaInstalled: boolean = true;
}
