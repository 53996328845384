import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

import { AsyncHelper } from "../../base/helpers/async-helper";
import { AuthService } from "../../base/services/auth/auth.service";
import { IntentsService } from "../../base/services/intents/intents.service";
import { LoginIntent } from "../intents/login-intent";
import { AppService } from "../services/app/app.service";
import { SessionService } from "../services/session/session.service";

/**
 * Navigation guard to check whether the user is logged in.
 */
@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(
        private readonly stateService: AppService,
        private readonly intentsService: IntentsService,
        private readonly authService: AuthService,
        private readonly sessionService: SessionService
    ) {
    }

    private waitAppInitialized(): Promise<void> {
        const checkDelay: number = 25;
        return new Promise(async (accept: (value: void) => void) => {
            while (!this.stateService.appLoaded) {
                await AsyncHelper.sleep(checkDelay);
            }
            accept();
        });
    }

    public async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        await this.waitAppInitialized();

        if (!this.authService.initialized || this.authService.isAuthenticatedCheckedInitially) {
            // OAuth check is not finished or not started
            await this.refreshToken();
        }

        if (!this.sessionService.activePerson) {
            await this.intentsService.executeIntentAndWait(LoginIntent);
        }

        return !!this.sessionService.activePerson;
    }

    private async refreshToken(): Promise<void> {
        if (this.authService.isAuthenticatedCheckedInitially) {
            return;
        }

        const checkDelay: number = 25;
        return new Promise(async (accept: (value: void) => void) => {
            while (!this.authService.initialized) {
                await AsyncHelper.sleep(checkDelay);
            }
            await this.authService.refreshToken();
            accept();
        });
    }
}
