<h1 *ngIf="options?.title" mat-dialog-title>{{options?.title}}</h1>
<div mat-dialog-content>
    <h5 *ngIf="options?.subtitle">{{options?.subtitle}}</h5>
    <p *ngIf="options?.message" [innerHTML]="options?.message"></p>

    <mat-form-field appearance="fill">
        <input
            #input
            (keydown.enter)="submit()"
            *ngIf="!options?.multiline"
            [placeholder]="options?.inputPlaceholder || ''"
            [value]="options?.inputValue || ''"
            matInput
            type="text" />

        <textarea
            #input
            *ngIf="options?.multiline"
            [placeholder]="options?.inputPlaceholder || ''"
            [value]="options?.inputValue || ''"
            cdkTextareaAutosize
            matInput
            type="text">
        </textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button
        (click)="onClick(button)"
        *ngFor="let button of options?.buttons"
        [class]="button.role"
        mat-button>
        <label *ngIf="button.text">{{button.text}}</label>
    </button>

    <button
        (click)="submit()"
        *ngIf="!options?.buttons || options?.buttons?.length == 0"
        mat-button>
        <label i18n="@@dialog.genericOk">OK</label>
    </button>
</div>
