import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { CommentTemplateIdentifier } from "../../identifiers/comment-template-identifier";
import { StorableEntity } from "../storable-entity";
import { DocumentAnswerCommentStorageDto } from "./document-answer-comment-storage-dto";

/**
 * A comment to an answer.
 */
export class DocumentAnswerComment implements StorableEntity<DocumentAnswerCommentStorageDto> {
    constructor(init?: Partial<DocumentAnswerComment>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public identifier?: CommentTemplateIdentifier;
    public textKey?: string;
    public text?: string;

    public toStorageDto(): DocumentAnswerCommentStorageDto {
        return {
            identifier: this.identifier,
            textKey: this.textKey,
            text: this.text
        };
    }

    public fromStorageDto(dto: DocumentAnswerCommentStorageDto|undefined): void {
        this.identifier = dto?.identifier as CommentTemplateIdentifier;
        this.textKey = dto?.textKey;
        this.text = dto?.text;
    }
}
