import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatTable } from "@angular/material/table";

import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { IntentsService } from "../../../../base/services/intents/intents.service";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../../entities/documents/entity-link";
import { EditCoreDataIntent } from "../../../intents/master-data/edit-core-data-intent";
import { EditCoreDataIntentOptions } from "../../../intents/master-data/edit-core-data-intent-options";
import { AppIcons } from "../../../services/icons/app-icons";
import { EntityListTableRow } from "./entity-list-table-row";

/**
 * Component to allow the selection of entities.
 */
@Component({
    selector: "business-entity-selection-list",
    templateUrl: "./entity-selection-list.component.html",
    styleUrls: ["./entity-selection-list.component.scss"]
})
export class EntitySelectionListComponent implements OnInit {
    constructor(
        private readonly intentsService: IntentsService
    ) {
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    private entityLinksField: Array<EntityLink<GenericCoreDataEntity>> = [];

    @Input()
    public selectedEntities: Array<EntityLink<GenericCoreDataEntity>> = [];

    @Input()
    public canSelect: boolean = true;

    @Input()
    public canOpen: boolean = true;

    @Input()
    public canEdit: boolean = false;

    @Input()
    public canDelete: boolean = false;

    @Input()
    public parentEntity?: GenericCoreDataEntity;

    @Output()
    public selectedEntitiesChange: EventEmitter<Array<EntityLink<GenericCoreDataEntity>>> = new EventEmitter<Array<EntityLink<GenericCoreDataEntity>>>();

    @Output()
    public deleteEntityRequested: EventEmitter<EntityLink<GenericCoreDataEntity>> = new EventEmitter<EntityLink<GenericCoreDataEntity>>();

    @Output()
    public entityModified: EventEmitter<GenericCoreDataEntity> = new EventEmitter<GenericCoreDataEntity>();

    public rows: Array<EntityListTableRow> = [];

    public displayedColumns: Array<string> = [];

    @ViewChild("table")
    public table?: MatTable<EntityListTableRow>;

    private initialized: boolean = false;

    @Input()
    public set entityLinks(value: Array<EntityLink<GenericCoreDataEntity>>) {
        const needsUpdate: boolean = this.initialized && (value != this.entityLinksField || !TypeScriptHelper.objectsEqual(this.entityLinksField, value));
        this.entityLinksField = value;
        if (needsUpdate) {
            this.refresh();
        }
    }

    @Input()
    public updateTable(entities?: Array<EntityLink<GenericCoreDataEntity>>): void {
        if (entities) {
            this.entityLinks = entities;
        }
        this.refresh();
    }

    public ngOnInit(): void {
        this.initialized = false;

        if (this.canSelect) {
            this.displayedColumns.push("checked");
        }
        this.displayedColumns.push("badge", "title");
        if (this.canOpen || this.canEdit || this.canDelete) {
            this.displayedColumns.push("actions");
        }

        this.refresh();
        this.initialized = true;
    }

    private refresh(): void {
        this.rows = [];
        for (const entityLink of this.entityLinksField) {
            const row: EntityListTableRow = new EntityListTableRow(entityLink);
            row.checked = this.selectedEntities.some((link: EntityLink<GenericCoreDataEntity>) => link.identifier.businessIdentifier == entityLink.identifier.businessIdentifier);
            this.rows.push(row);
        }

        if (this.initialized) {
            this.table?.renderRows();
        }
    }

    public stopPropagation(event: Event): void {
        event.stopPropagation();
    }

    public toggle(row: EntityListTableRow): void {
        if (this.canSelect) {
            row.checked = !row.checked;
            this.updateSelectionList();
        }
    }

    public rowChanged(_event: MatCheckboxChange): void {
        this.updateSelectionList();
    }

    private updateSelectionList(): void {
        this.selectedEntities = [];
        for (const row of this.rows) {
            if (row.checked) {
                this.selectedEntities.push(row.entityLink);
            }
        }
        this.selectedEntitiesChange.emit(this.selectedEntities);
    }

    public async edit(rowToEdit: EntityListTableRow, event: Event): Promise<void> {
        event.stopPropagation();

        if (rowToEdit.entityLink.entity) {
            const intentOptions: EditCoreDataIntentOptions<GenericCoreDataEntity> = new EditCoreDataIntentOptions<GenericCoreDataEntity>(rowToEdit.entityLink.entity, {
                additionalEntities: this.parentEntity ? [this.parentEntity] : [],
                readonly: !this.canEdit
            });
            await this.intentsService.executeIntentAndWait(EditCoreDataIntent, intentOptions);
            if (intentOptions.resultEntity) {
                this.entityModified.emit(intentOptions.resultEntity);
            }
        }
    }

    public delete(rowToDelete: EntityListTableRow, event: Event): void {
        event.stopPropagation();

        this.deleteEntityRequested.emit(rowToDelete.entityLink);
    }
}
