import { TypeScriptHelper } from "../../../base/helpers/type-script-helper";
import { AddressTechnicalIdentifier } from "../../identifiers/address-technical-identifier";
import { BuildingIdentifier } from "../../identifiers/building-identifier";
import { EntityBusinessIdentifier } from "../../identifiers/entity-business-identifier";
import { EntityIdentifierStorageDto } from "../../identifiers/entity-identifier-storage-dto";
import { EntityTechnicalIdentifier } from "../../identifiers/entity-technical-identifier";
import { FloorTechnicalIdentifier } from "../../identifiers/floor-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { RoomTechnicalIdentifier } from "../../identifiers/room-technical-identifier";
import { CoreDataFactory } from "../../services/master-data/core-data-factory";
import { CoreDataEntity } from "../core-data/core-data-entity";
import { EntityTypes } from "../entity-types";
import { SampleLegalReferences } from "./sample-legal-references";
import { SampleStorageDto } from "./sample-storage-dto";
import { SampleTypes } from "./sample-types";

/**
 * A single sample usually found in a sampling plan.
 */
export class Sample extends CoreDataEntity<EntityBusinessIdentifier, EntityTechnicalIdentifier> {
    constructor(init?: Partial<Sample>) {
        super(EntityTypes.sample);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public name?: string;

    public sampleType: SampleTypes = SampleTypes.unknown;

    public roomRoomFeatureMappingTechnicalIdentifier?: EntityTechnicalIdentifier;

    public buildingIdentifier?: BuildingIdentifier;

    public addressTechnicalIdentifier?: AddressTechnicalIdentifier;

    public floorTechnicalIdentifier?: FloorTechnicalIdentifier;

    public roomTechnicalIdentifier?: RoomTechnicalIdentifier;

    public roomFeatureTechnicalIdentifier?: EntityTechnicalIdentifier;

    public legalReference: SampleLegalReferences = SampleLegalReferences.dvgwW551;

    public get displayTitle(): string {
        return this.name || Identifier.identifierToReadableString(this.identifier);
    }

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    protected toEntityStorageDto(): SampleStorageDto {
        return new SampleStorageDto({
            name: this.name,
            sampleType: this.sampleType,

            roomRoomFeatureMappingTId: this.roomRoomFeatureMappingTechnicalIdentifier,
            buildingId: EntityIdentifierStorageDto.fromIdentifier(this.buildingIdentifier),
            addressTid: this.addressTechnicalIdentifier,
            floorTid: this.floorTechnicalIdentifier,
            roomTid: this.roomTechnicalIdentifier,
            roomFeatureTid: this.roomFeatureTechnicalIdentifier,
            legalReference: this.legalReference
        });
    }

    protected fromEntityStorageDto(dto: SampleStorageDto|undefined): void {
        this.name = dto?.name;
        this.sampleType = TypeScriptHelper.dtoToEnum(SampleTypes, dto?.sampleType, SampleTypes.unknown) as SampleTypes;

        this.roomRoomFeatureMappingTechnicalIdentifier = dto?.roomRoomFeatureMappingTId as EntityTechnicalIdentifier;
        this.buildingIdentifier = Identifier.create(dto?.buildingId?.bid, dto?.buildingId?.tid);
        this.addressTechnicalIdentifier = dto?.addressTid as AddressTechnicalIdentifier;
        this.floorTechnicalIdentifier = dto?.floorTid as FloorTechnicalIdentifier;
        this.roomTechnicalIdentifier = dto?.roomTid as RoomTechnicalIdentifier;
        this.roomFeatureTechnicalIdentifier = dto?.roomFeatureTid as EntityTechnicalIdentifier;
        this.legalReference = TypeScriptHelper.dtoToEnum(SampleLegalReferences, dto?.legalReference, SampleLegalReferences.dvgwW551) as SampleLegalReferences;
    }

    public clone(): Sample {
        return CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
    }
}
