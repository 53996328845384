import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { AppIcons } from "../../../business/services/icons/app-icons";
import { OnlineMonitorService } from "../../../business/services/monitors/online-monitor.service";
import { FinishedReceiver } from "../../interfaces/finished-receiver";
import { ProcessElementTriggeredEventArguments } from "../../interfaces/process-element-triggered-event-arguments";
import { BaseComponent } from "../base-component";
import { BasicDialogComponent } from "../dialogs/basic-dialog/basic-dialog.component";

/**
 * This process-button component is used for actions that can take some time. It switches automatically to a loading state that has to be reset manually.
 * It is recommended to the most intents including those that only change a page.
 */
@Component({
    selector: "base-process-button",
    templateUrl: "./process-button.component.html",
    styleUrls: ["./process-button.component.scss"]
})
export class ProcessButtonComponent extends BaseComponent implements FinishedReceiver, OnInit {
    constructor(
        private readonly onlineMonitorService: OnlineMonitorService,
        private readonly dialog: MatDialog,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    @Input()
    public icon?: IconDefinition;

    @Output()
    public triggered: EventEmitter<ProcessElementTriggeredEventArguments> = new EventEmitter<ProcessElementTriggeredEventArguments>();

    @Input()
    public disabled: boolean = false;

    @Input()
    public loading: boolean = false;

    private showLoadingAnimationInitialValue: boolean = true;

    @Input()
    public showLoadingAnimation: boolean = true;

    @Input()
    public buttonStyle?: "default"|"clear"|"stroked"|"flat"|"raised" = "default";

    @Input()
    public color?: ThemePalette|"danger"|"good"|"dark" = "primary";

    @Output()
    public loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly appIcons: typeof AppIcons = AppIcons;

    private requiresInternetField: boolean = false;

    public hasInternet: boolean = true;

    private requiresInternetEventSubscribed: boolean = false;

    @Input()
    public hasPermission: boolean = true;

    @Input()
    public requiredPermissions?: Array<string>;

    public get mappedColor(): ThemePalette|undefined {
        if (this.color == "primary" || this.color == "accent" || this.color == "warn") {
            return this.color;
        }
        return undefined;
    }

    public get requiresInternet(): boolean {
        return this.requiresInternetField;
    }

    @Input()
    public set requiresInternet(value: boolean) {
        this.requiresInternetField = value;
        if (value) {
            this.subscribeToInternetAvailabilityChanged();
        }
    }

    protected componentInit(): void {
        this.showLoadingAnimationInitialValue = this.showLoadingAnimation;

        if (this.requiresInternet) {
            this.subscribeToInternetAvailabilityChanged();
        }
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    private setLoading(value: boolean): void {
        this.loading = value;
        this.changeDetectorRef.detectChanges();
        this.loadingChange.emit(value);
    }

    private subscribeToInternetAvailabilityChanged(): void {
        if (this.requiresInternetEventSubscribed) {
            return;
        }
        this.requiresInternetEventSubscribed = true;

        this.hasInternet = this.onlineMonitorService.online;
        this.subscribe<boolean>(this.onlineMonitorService.onlineStateChanged, this.onlineStateChanged);
    }

    private onlineStateChanged(online: boolean): void {
        this.hasInternet = online;
    }

    /**
     * Gets triggered from the click receiver to reset the loading state of the process-button.
     * See {@link FinishedReceiver}.
     */
    public finished(): void {
        this.loading = false;
        this.showLoadingAnimation = this.showLoadingAnimationInitialValue;
    }

    /**
     * Gets triggered from the click receiver to show the loading icon.
     * See {@link FinishedReceiver}.
     */
    public showLoadingIndicator(): void {
        this.showLoadingAnimation = true;
    }

    private showPermissionRequiredDialog(): Promise<void> {
        const dialogRef: MatDialogRef<any> = this.dialog.open(BasicDialogComponent, {
            width: "350px",
            restoreFocus: false,
            data: {
                title: $localize`:@@permissions.permissionsRequiredInfoDialogTitle:Permissions required`,
                message: this.requiredPermissions?.length
                    ? $localize`:@@permissions.permissionsRequiredInfoDialogTextPermissionsKnown:For this operation, special permissions are required.<br /><br />Please contact the administrator or the person responsible for managing the permissions.<br /><br />The required permissions:<br />• ${this.requiredPermissions.join("<br />• ")}`
                    : $localize`:@@permissions.permissionsRequiredInfoDialogText:For this operation, special permissions are required.<br /><br />Please contact the administrator or the person responsible for managing the permissions.`
            } as unknown
        });

        return new Promise((resolve: () => void) => {
            dialogRef.afterClosed().subscribe(() => {
                resolve();
            });
        });
    }

    private showInternetRequiredDialog(): void {
        this.dialog.open(BasicDialogComponent, {
            width: "350px",
            restoreFocus: false,
            data: {
                title: $localize`:@@internet.internetRequiredInfoDialogTitle:Internet required`,
                message: $localize`:@@internet.internetRequiredInfoDialogText:For this operation an internet connection is required.<br /><br />Please check your network connection and try again.`
            } as unknown
        });
    }

    public async clickReceived(event: Event): Promise<void> {
        event.stopPropagation();
        event.preventDefault();

        if (this.loading) {
            return;
        }

        if (!this.hasPermission) {
            this.showPermissionRequiredDialog().then();
            return;
        }

        this.setLoading(true);

        if (this.requiresInternetField && !this.onlineMonitorService.online) {
            const internetIsAvailable: boolean = await this.onlineMonitorService.checkConnection();
            if (!internetIsAvailable) {
                this.setLoading(false);
                this.showInternetRequiredDialog();
                return;
            }
        }

        this.triggered.emit(new ProcessElementTriggeredEventArguments(this, event));
    }
}
