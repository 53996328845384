import { EventEmitter, Injectable } from "@angular/core";
import { Subscription } from "rxjs";

import { environment } from "../../../../environments/environment";
import { EventHelper } from "../../../base/helpers/event-helper";
import { TimeService } from "../../../base/services/time/time.service";
import { Timer } from "../../../base/services/time/timer";
import { ServerInfoDto } from "../../../generated/api";
import { InfoApiService } from "../api/info-api.service";
import { NetworkConnectionInformation } from "./network-connection-information";

/**
 * Monitors online connection to server.
 */
@Injectable({
    providedIn: "root"
})
export class OnlineMonitorService {
    constructor(
        private readonly infoApiService: InfoApiService,
        private readonly timeService: TimeService
    ) {
        this.updateTimer = this.timeService.spawnTimer(this.onlineCheckDelay);
        EventHelper.subscribe(this.updateTimer.elapsed, this.timerElapsed, this);
    }

    public serverInfo?: ServerInfoDto;

    public onlineStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public latencyChanged: EventEmitter<number> = new EventEmitter<number>();

    public serverVersionChanged: EventEmitter<string> = new EventEmitter<string>();

    public networkInformationChanged: EventEmitter<NetworkConnectionInformation> = new EventEmitter<NetworkConnectionInformation>();

    private onlineCheckDelay: number = environment.onlineCheckDelay;

    private onlineCheckTimeout: number = environment.onlineCheckTimeout;

    public alreadyChecked: boolean = false;

    public online: boolean = false;

    public latency: number = 0;

    public serverVersion?: string;

    private updateTimer: Timer;

    private checkingBackend: boolean = false;

    public networkInformation: NetworkConnectionInformation = new NetworkConnectionInformation();

    public start(): void {
        this.updateTimer.start();
        this.timerElapsed().then();

        this.initializeNetworkInformation();
    }

    private initializeNetworkInformation(): void {
        try {
            if (!navigator || !("connection" in navigator)) {
                return;
            }

            navigator.connection.addEventListener("change", this.readNetworkInformation.bind(this));
            this.readNetworkInformation();
        } catch (error) {
            // Ignore all errors here - browser does not support this feature
        }
    }

    private readNetworkInformation(): void {
        try {
            const anonymized: any = navigator.connection as unknown;

            this.networkInformation.type = "type" in anonymized ? anonymized.type as string : undefined;
            this.networkInformation.downlink = "downlink" in anonymized ? anonymized.downlink as number : 0;
            this.networkInformation.downlinkMax = "downlinkMax" in anonymized ? anonymized.downlinkMax as number : 0;
            this.networkInformation.roundTripTime = "rtt" in anonymized ? anonymized.rtt as number : 0;
            this.networkInformation.effectiveType = "effectiveType" in anonymized ? anonymized.effectiveType as string : undefined;
            this.networkInformation.saveData = "saveData" in anonymized ? !!anonymized.saveData : false;

            if (!Number.isFinite(this.networkInformation.downlinkMax)) {
                this.networkInformation.downlinkMax = 0;
            }

            this.networkInformation.informationAvailable = true;

            this.networkInformationChanged.emit(this.networkInformation);
        } catch (error) {
            // Ignore all errors here - browser does not support this feature
        }
    }

    private updateOnlineState(value: boolean): void {
        if (value != this.online || !this.alreadyChecked) {
            this.online = value;
            this.alreadyChecked = true;
            this.onlineStateChanged.emit(value);
        }

        if (!value) {
            if (this.latency != 0) {
                this.latency = 0;
                this.latencyChanged.emit(0);
            }
        }
    }

    private updateServerVersion(serverInfo: ServerInfoDto): void {
        this.serverInfo = serverInfo;
        if (serverInfo.version != this.serverVersion) {
            if (!this.serverVersion) {
                console.info(`Server Version: ${serverInfo.version}`);
            }
            this.serverVersion = serverInfo.version;
            this.serverVersionChanged.emit(serverInfo.version);
        }
    }

    private async timerElapsed(): Promise<void> {
        this.checkConnection().then();
    }

    public async checkConnection(): Promise<boolean> {
        if (this.checkingBackend) {
            return this.online;
        }

        const self: OnlineMonitorService = this;
        const request: Promise<ServerInfoDto> = this.infoApiService.getInfo();
        let requestRunning: boolean = true;

        const timeoutTimer: Timer = this.timeService.spawnTimer(this.onlineCheckTimeout);
        const timeoutTimerSubscription: Subscription = EventHelper.subscribe(timeoutTimer.elapsed, () => {
            if (requestRunning) {
                self.updateOnlineState.call(self, false);
                requestRunning = false;
            }
        }, {});
        timeoutTimer.start();

        let serverInfo: ServerInfoDto|undefined;
        try {
            const startTime: number = performance.now();
            serverInfo = await request;
            this.latency = performance.now() - startTime;
        } catch (error) {
            // We are offline
            requestRunning = false;

            timeoutTimer.stop();
            EventHelper.unsubscribe(timeoutTimerSubscription);

            this.updateOnlineState(false);
            return false;
        }

        if (!requestRunning) {
            return this.online;
        }
        requestRunning = false;

        timeoutTimer.stop();
        EventHelper.unsubscribe(timeoutTimerSubscription);

        this.updateOnlineState(true);
        this.latencyChanged.emit(this.latency);

        if (serverInfo?.version) {
            this.updateServerVersion(serverInfo);
        }

        return true;
    }

    public notifyOffline(): void {
        this.updateOnlineState(false);
    }

    public notifyOnline(): void {
        this.updateOnlineState(true);
    }
}
