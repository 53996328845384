import { DocumentTypes } from "../../../entities/documents/document-types";
import { Identifier } from "../../../identifiers/identifier";
import { PersonBusinessIdentifier } from "../../../identifiers/person-business-identifier";
import { SectionTemplateIdentifier } from "../../../identifiers/section-template-identifier";
import { DocumentUpdateTypes } from "../../documents/document-update-types";
import { DocumentQuestionUpdate } from "./document-question-update";
import { DocumentRepeatableQuestionUpdate } from "./document-repeatable-question-update";
import { NewDocumentRevisionUpdate } from "./new-document-revision-update";
import { PartialDocumentUpdate } from "./partial-document-update";
import { PartialDocumentUpdateStorageDto } from "./partial-document-update-storage-dto";
import { SamplingPlanUpdate } from "./sampling-plan-update";

/**
 * Factory to create partial updates.
 */
export class UpdateContainerFactory {
    public static createFromStorageDto<TUpdate extends PartialDocumentUpdate>(dto: PartialDocumentUpdateStorageDto): TUpdate|undefined {
        switch (dto.updateType as DocumentUpdateTypes) {
            case DocumentUpdateTypes.question:
            case DocumentUpdateTypes.attachmentLink:
                const questionUpdate: DocumentQuestionUpdate = new DocumentQuestionUpdate(0, "" as PersonBusinessIdentifier, Identifier.empty(), DocumentTypes.unknown);
                questionUpdate.fromStorageDto(dto);
                return questionUpdate as PartialDocumentUpdate as TUpdate;
            case DocumentUpdateTypes.repeatableQuestion:
                const repeatableQuestionUpdate: DocumentRepeatableQuestionUpdate = new DocumentRepeatableQuestionUpdate(0, "" as PersonBusinessIdentifier, Identifier.empty(), DocumentTypes.unknown, 0 as SectionTemplateIdentifier, Identifier.empty());
                repeatableQuestionUpdate.fromStorageDto(dto);
                return repeatableQuestionUpdate as PartialDocumentUpdate as TUpdate;
            case DocumentUpdateTypes.newRevision:
                const newRevisionUpdate: NewDocumentRevisionUpdate = new NewDocumentRevisionUpdate(0, "" as PersonBusinessIdentifier, Identifier.empty(), DocumentTypes.unknown);
                newRevisionUpdate.fromStorageDto(dto);
                return newRevisionUpdate as PartialDocumentUpdate as TUpdate;
            case DocumentUpdateTypes.samplingPlan:
                const samplingPlanUpdate: SamplingPlanUpdate = new SamplingPlanUpdate(0, "" as PersonBusinessIdentifier, Identifier.empty(), DocumentTypes.unknown, undefined!);
                samplingPlanUpdate.fromStorageDto(dto);
                return samplingPlanUpdate as PartialDocumentUpdate as TUpdate;
        }
        return undefined;
    }
}
