import { ArrayHelper } from "src/app/base/helpers/array-helper";

import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { EntityHelper } from "../../../helpers/entity-helper";
import { BuildingBusinessIdentifier } from "../../../identifiers/building-business-identifier";
import { BuildingComplexIdentifier } from "../../../identifiers/building-complex-identifier";
import { BuildingTechnicalIdentifier } from "../../../identifiers/building-technical-identifier";
import { CompanyTechnicalIdentifier } from "../../../identifiers/company-technical-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { PersonTechnicalIdentifier } from "../../../identifiers/person-technical-identifier";
import { CoreDataFactory } from "../../../services/master-data/core-data-factory";
import { EntityTypes } from "../../entity-types";
import { Address } from "../address";
import { CoreDataEntity } from "../core-data-entity";
import { BuildingStorageDto } from "./building-storage-dto";
import { BuildingUsageTypes } from "./building-usage-types";
import { DrinkingWaterHeatingTypes } from "./drinking-water-heating-types";
import { Floor } from "./floor";

/**
 * Basic class for a building.
 */
export class Building extends CoreDataEntity<BuildingBusinessIdentifier, BuildingTechnicalIdentifier> {
    constructor(init?: Partial<Building>) {
        super(EntityTypes.building);
        TypeScriptHelper.initObjectFromPartial(this, init);
    }

    public buildingComplexIdentifier: BuildingComplexIdentifier = Identifier.empty();

    public name?: string;

    public companyIdentifier: CompanyTechnicalIdentifier = 0 as CompanyTechnicalIdentifier;

    public addresses: Array<Address> = [];

    public contactId?: PersonTechnicalIdentifier;

    public photoIds?: Array<number>;

    public constructionDate?: string;

    public buildingUsage: BuildingUsageTypes = BuildingUsageTypes.unknown;

    public usageDescription?: string;

    public numberOfFlats?: number;

    public numberOfOffices?: number;

    public numberOfUnusedFlatsAndOffices?: number;

    public installationPlansExist?: boolean;

    public visibleAscendingPipes?: number;

    public ascendingPipesEnds?: string;

    public floors: Array<Floor> = [];

    public drinkingWaterHeatingType: DrinkingWaterHeatingTypes = DrinkingWaterHeatingTypes.unknown;

    public drinkingWaterHeatingLocation?: string;

    public locationHouseConnection?: string;

    public additionalInfo?: string;

    public publicUsage: boolean = false;

    public get displayTitle(): string {
        return ArrayHelper.removeEmpty([this.externalReference, this.name, EntityHelper.multipleAddressesToString(this.addresses)]).join(", ") || $localize`:@@building.fallbackTitle:${this.identifier.businessIdentifier} (New)`;
    }

    public get isNew(): boolean {
        return !this.identifier.businessIdentifier;
    }

    protected toEntityStorageDto(): BuildingStorageDto {
        const storageDto: BuildingStorageDto = new BuildingStorageDto({
            name: this.name,
            companyIdentifier: this.companyIdentifier,
            contactId: this.contactId,

            buildingComplexBusinessId: this.buildingComplexIdentifier.businessIdentifier,
            buildingComplexTechnicalId: this.buildingComplexIdentifier.technicalIdentifier,

            photoIds: this.photoIds,

            constructionDate: this.constructionDate,
            buildingUsage: this.buildingUsage,
            usageDescription: this.usageDescription,
            numberOfFlats: this.numberOfFlats,
            numberOfOffices: this.numberOfOffices,
            numberOfUnusedFlatsAndOffices: this.numberOfUnusedFlatsAndOffices,
            installationPlansExist: this.installationPlansExist,
            visibleAscendingPipes: this.visibleAscendingPipes,
            ascendingPipesEnds: this.ascendingPipesEnds,
            drinkingWaterHeatingType: this.drinkingWaterHeatingType,
            drinkingWaterHeatingLocation: this.drinkingWaterHeatingLocation,
            locationHouseConnection: this.locationHouseConnection,
            publicUsage: this.publicUsage,

            additionalInfo: this.additionalInfo,

            // Currently, this is not a storage DTO. It is ok for now because it only holds simple properties.
            floors: this.floors
        });

        storageDto.addresses = [];
        for (const address of this.addresses) {
            storageDto.addresses.push(address.toStorageDto());
        }

        return storageDto;
    }

    protected fromEntityStorageDto(dto: BuildingStorageDto|undefined): void {
        this.name = dto?.name;

        this.companyIdentifier = dto?.companyIdentifier as CompanyTechnicalIdentifier;
        this.contactId = dto?.contactId as PersonTechnicalIdentifier;

        this.buildingComplexIdentifier = Identifier.create(dto?.buildingComplexBusinessId, dto?.buildingComplexTechnicalId);

        this.photoIds = dto?.photoIds ?? [];

        this.constructionDate = dto?.constructionDate;
        this.buildingUsage = TypeScriptHelper.dtoToEnum(BuildingUsageTypes, dto?.buildingUsage, BuildingUsageTypes.unknown) as BuildingUsageTypes;
        this.usageDescription = dto?.usageDescription;
        this.numberOfFlats = dto?.numberOfFlats;
        this.numberOfOffices = dto?.numberOfOffices;
        this.numberOfUnusedFlatsAndOffices = dto?.numberOfUnusedFlatsAndOffices;
        this.installationPlansExist = dto?.installationPlansExist;
        this.visibleAscendingPipes = dto?.visibleAscendingPipes;
        this.ascendingPipesEnds = dto?.ascendingPipesEnds;
        this.drinkingWaterHeatingType = TypeScriptHelper.dtoToEnum(DrinkingWaterHeatingTypes, dto?.drinkingWaterHeatingType, DrinkingWaterHeatingTypes.unknown) as DrinkingWaterHeatingTypes;
        this.drinkingWaterHeatingLocation = dto?.drinkingWaterHeatingLocation;
        this.locationHouseConnection = dto?.locationHouseConnection;
        this.publicUsage = !!dto?.publicUsage;

        this.additionalInfo = dto?.additionalInfo;

        this.floors = dto?.floors ?? [];

        this.addresses = [];
        for (const addressDto of dto?.addresses ?? []) {
            const address: Address|undefined = CoreDataFactory.createFromStorageDto(addressDto);
            if (address) {
                this.addresses.push(address);
            }
        }
    }

    public clone(): Building {
        return CoreDataFactory.createFromStorageDto(this.toStorageDto())!;
    }
}
