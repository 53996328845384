/**
 * A single listener for shortcuts.
 */
export class ShortcutListener {
    constructor(index: number, delegate: (event: KeyboardEvent) => void, unregisterListener: (listener: ShortcutListener) => void) {
        this.index = index;
        this.delegate = delegate;
        this.unregisterDelegate = unregisterListener;
    }

    public index: number;

    public unregisterDelegate: (listener: ShortcutListener) => void;

    public unregister(): void {
        this.unregisterDelegate(this);
    }

    public delegate: (event: KeyboardEvent) => void;
}
