import { FrontendErrors } from "../../global/frontend-errors";
import { AppException } from "../exceptions/app-exception";
import { Attachment } from "./attachment";
import { AttachmentStorageDto } from "./attachment-storage-dto";
import { AttachmentTypes } from "./attachment-types";
import { ImageAttachment } from "./image-attachment";

/**
 * Factory to create attachments.
 */
export class AttachmentFactory {
    public static createFromStorageDto(dto: AttachmentStorageDto): Attachment {
        switch (dto.type) {
            case AttachmentTypes.image:
                const attachment: Attachment = new ImageAttachment();
                attachment.fromStorageDto(dto);
                return attachment;
        }
        throw new AppException(FrontendErrors.FE36UnableToInstantiateAttachmentTypeUnknown, $localize`:@@exception.fe36UnableToInstantiateAttachmentTypeUnknown:Unable to instantiate attachment from type "${dto.type}:dtoType:". Please check for application updates.`);
    }

    public static createImageAttachment(init?: Partial<ImageAttachment>): ImageAttachment {
        return new ImageAttachment(init);
    }
}
