import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { ArrayHelper } from "../../../base/helpers/array-helper";
import { EventHelper } from "../../../base/helpers/event-helper";
import { WebHelper } from "../../../base/helpers/web-helper";
import { TimeService } from "../../../base/services/time/time.service";
import { Timer } from "../../../base/services/time/timer";
import { QuestionDto, ResponseBodyListWalkthroughInspectionsDto, WalkthroughInspectionDto } from "../../../generated/api";
import { SafeResult } from "../../common/safe-result";
import { Attachment } from "../../entities/attachments/attachment";
import { AttachmentStates } from "../../entities/attachments/attachment-states";
import { FileAttachment } from "../../entities/attachments/file-attachment";
import { DocumentSection } from "../../entities/documents/document-section";
import { DocumentTypes } from "../../entities/documents/document-types";
import { DocumentValueContainer } from "../../entities/documents/document-value-container";
import { QuestionSaveStates } from "../../entities/documents/question-save-states";
import { AppException } from "../../entities/exceptions/app-exception";
import { SamplingPlan } from "../../entities/samplings/sampling-plan";
import { SamplingPlanMerge } from "../../entities/samplings/sampling-plan-merge";
import { BackendErrors } from "../../global/backend-errors";
import { BackendHelper } from "../../helpers/backend-helper";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { Identifier } from "../../identifiers/identifier";
import { PersonBusinessIdentifier } from "../../identifiers/person-business-identifier";
import { QuestionIdentifier } from "../../identifiers/question-identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { SectionIdentifier } from "../../identifiers/section-identifier";
import { SectionTemplateIdentifier } from "../../identifiers/section-template-identifier";
import { DocumentsApiService } from "../api/documents-api.service";
import { DocumentSynchronizationSummary } from "../documents/document-synchronization-summary";
import { DocumentUpdateTypes } from "../documents/document-update-types";
import { OnlineMonitorService } from "../monitors/online-monitor.service";
import { SamplingsService } from "../samplings/samplings.service";
import { SessionService } from "../session/session.service";
import { SessionChangedEventData } from "../session/session-changed-event-data";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";
import { DocumentQuestionUpdate } from "./update-containers/document-question-update";
import { DocumentRepeatableQuestionUpdate } from "./update-containers/document-repeatable-question-update";
import { NewDocumentRevisionUpdate } from "./update-containers/new-document-revision-update";
import { PartialDocumentUpdate } from "./update-containers/partial-document-update";
import { PartialDocumentUpdateStorageDto } from "./update-containers/partial-document-update-storage-dto";
import { SamplingPlanUpdate } from "./update-containers/sampling-plan-update";
import { UpdateContainerFactory } from "./update-containers/update-container-factory";

/**
 * The service to interact with documents.
 */
@Injectable({
    providedIn: "root"
})
export class DocumentQueuedUpdateService {
    public constructor(
        private readonly sessionService: SessionService,
        private readonly onlineMonitorService: OnlineMonitorService,
        private readonly documentsApiService: DocumentsApiService,
        private readonly samplingsService: SamplingsService,
        private readonly storageService: StorageService,
        private readonly timeService: TimeService
    ) {
        EventHelper.subscribe(this.sessionService.sessionChanged, this.sessionChanged, this);

        this.updateTimer = this.timeService.spawnTimer(this.answerUpdateDelay);
        EventHelper.subscribe(this.updateTimer.elapsed, this.sendPendingChanges, this);
    }

    private readonly maxBatchesToSend: number = environment.maxAnswerBatchesToSend;

    private readonly answerUpdateDelay: number = environment.answerUpdateDelay;

    public synchronizationSummaryUpdated: EventEmitter<DocumentSynchronizationSummary> = new EventEmitter<DocumentSynchronizationSummary>();

    public pendingUpdateStateUpdated: EventEmitter<PartialDocumentUpdate> = new EventEmitter<PartialDocumentUpdate>();

    public updatedDocumentReceived: EventEmitter<WalkthroughInspectionDto|ResponseBodyListWalkthroughInspectionsDto> = new EventEmitter<WalkthroughInspectionDto|ResponseBodyListWalkthroughInspectionsDto>();

    private updateQueue: Array<PartialDocumentUpdate> = [];

    private summaryField: DocumentSynchronizationSummary = new DocumentSynchronizationSummary();

    private savingQueueToStorage: boolean = false;

    private updateTimer: Timer;

    private sendingToServer: boolean = false;

    private lastUpdate: number = this.timeService.currentTimestamp;

    public get pendingUpdates(): Array<PartialDocumentUpdate> {
        return this.updateQueue;
    }

    public get summary(): DocumentSynchronizationSummary {
        return this.summaryField;
    }

    public async initialize(): Promise<void> {
        await this.loadQueueFromStorage();
        this.activateUpdates();
    }

    private sessionChanged(sessionData: SessionChangedEventData): void {
        if (!sessionData.loggedIn) {
            this.clearQueue().then();
        } else {
            this.updateSummary();
        }
    }

    private async clearQueue(): Promise<void> {
        this.updateQueue = [];
        await this.saveQueueToStorage();
        this.updateSummary();
    }

    private async loadQueueFromStorage(): Promise<void> {
        try {
            this.updateQueue = [];
            const updateQueueDto: Array<PartialDocumentUpdateStorageDto> = await this.storageService.get(StorageKeys.documentUpdateQueue, []) ?? [];

            for (const updateDto of updateQueueDto) {
                const partialUpdate: PartialDocumentUpdate|undefined = UpdateContainerFactory.createFromStorageDto(updateDto);
                if (partialUpdate) {
                    if (partialUpdate.saveState == QuestionSaveStates.sendingToServer) {
                        partialUpdate.saveState = QuestionSaveStates.persistedLocally;
                    }

                    this.updateQueue.push(partialUpdate);
                }
            }
        } catch (error) {
            console.error(error);
            this.updateQueue = [];
        }

        this.updateSummary();
        if (this.summary.pending) {
            this.activateUpdates();
        }
    }

    public updateAttachmentLinks(changedAttachments: Array<FileAttachment>): void {
        let linkedAttachmentsUpdated: boolean = false;

        for (const partialDocumentUpdate of this.updateQueue) {
            if (partialDocumentUpdate.updateType == DocumentUpdateTypes.question || partialDocumentUpdate.updateType == DocumentUpdateTypes.attachmentLink) {
                const questionUpdate: DocumentQuestionUpdate = partialDocumentUpdate as DocumentQuestionUpdate;
                if (questionUpdate) {
                    const newAttachmentList: Array<Attachment> = [];
                    let linksForUpdateChanged: boolean = false;
                    for (const attachment of questionUpdate.linkedAttachments ?? []) {
                        const fileAttachment: FileAttachment = attachment as FileAttachment;
                        const changedAttachment: FileAttachment|undefined = changedAttachments.find((item: FileAttachment) => (item.identifier.businessIdentifier && item.identifier.businessIdentifier == fileAttachment.identifier.businessIdentifier) || (item.localStorageId && item.localStorageId == fileAttachment.localStorageId));
                        if (changedAttachment) {
                            linksForUpdateChanged = true;
                            newAttachmentList.push(changedAttachment);
                        } else {
                            newAttachmentList.push(attachment);
                        }
                    }
                    if (linksForUpdateChanged) {
                        questionUpdate.linkedAttachments = newAttachmentList;
                        linkedAttachmentsUpdated = true;
                    }
                }
            }
        }

        if (linkedAttachmentsUpdated) {
            this.saveQueueToStorage().then();
        }
    }

    public attachmentReplacedWithExistingAttachment(oldAttachment: FileAttachment, newAttachment: FileAttachment): void {
        let linkedAttachmentsUpdated: boolean = false;

        for (const partialDocumentUpdate of this.updateQueue) {
            if (partialDocumentUpdate.updateType == DocumentUpdateTypes.question || partialDocumentUpdate.updateType == DocumentUpdateTypes.attachmentLink) {
                const questionUpdate: DocumentQuestionUpdate = partialDocumentUpdate as DocumentQuestionUpdate;
                if (questionUpdate) {
                    const newAttachmentList: Array<Attachment> = [];
                    let linksForUpdateChanged: boolean = false;
                    for (const attachment of questionUpdate.linkedAttachments ?? []) {
                        const fileAttachment: FileAttachment = attachment as FileAttachment;
                        if (fileAttachment == oldAttachment || fileAttachment.identifier.businessIdentifier && (fileAttachment.identifier.businessIdentifier == oldAttachment.identifier.businessIdentifier) || (fileAttachment.localStorageId && fileAttachment.localStorageId == oldAttachment.localStorageId)) {
                            linksForUpdateChanged = true;
                            newAttachmentList.push(newAttachment);
                        } else {
                            newAttachmentList.push(attachment);
                        }
                    }
                    if (linksForUpdateChanged) {
                        questionUpdate.linkedAttachments = newAttachmentList;
                        linkedAttachmentsUpdated = true;
                    }
                }
            }
        }

        if (linkedAttachmentsUpdated) {
            this.saveQueueToStorage().then();
        }
    }

    private async saveQueueToStorage(): Promise<void> {
        try {
            this.savingQueueToStorage = true;

            const queueDtos: Array<PartialDocumentUpdateStorageDto> = [];
            for (const update of this.updateQueue) {
                queueDtos.push(update.toStorageDto());
            }
            await this.storageService.set(StorageKeys.documentUpdateQueue, queueDtos);

            for (const pendingUpdate of this.updateQueue) {
                if (pendingUpdate.saveState == QuestionSaveStates.updateCreated) {
                    this.updateState(pendingUpdate, QuestionSaveStates.persistedLocally);
                }
            }

        } catch (error) {
            // TODO: Decide what to do here
            console.error(error);
        } finally {
            this.savingQueueToStorage = false;
        }
    }

    private updateState(pendingUpdate: PartialDocumentUpdate, newState: QuestionSaveStates): void {
        pendingUpdate.saveState = newState;

        this.pendingUpdateStateUpdated.emit(pendingUpdate);
    }

    public createRevision(documentIdentifier: DocumentIdentifier, documentType: DocumentTypes): PartialDocumentUpdate|never {
        this.sessionService.requiresAccount();

        const revisionUpdate: NewDocumentRevisionUpdate = new NewDocumentRevisionUpdate(this.sessionService.activeAccountId, this.sessionService.activePersonBusinessId, documentIdentifier, documentType);
        this.enqueueUpdate(revisionUpdate, false);

        return revisionUpdate;
    }

    public updateSamplingPlan(documentIdentifier: DocumentIdentifier, documentType: DocumentTypes, samplingPlanMerge: SamplingPlanMerge, hasErrors: boolean): PartialDocumentUpdate|never {
        this.sessionService.requiresAccount();

        const clonedSamplingPlanMerge: SamplingPlanMerge = samplingPlanMerge.clone();
        if (hasErrors) {
            clonedSamplingPlanMerge.samples = this.samplingsService.validateSamplingPlanMergeGetValidItems(samplingPlanMerge);
        }

        let pendingUpdate: SamplingPlanUpdate|undefined = this.updateQueue.find((existingUpdate: PartialDocumentUpdate) =>
            existingUpdate.updateType == DocumentUpdateTypes.samplingPlan
            && existingUpdate instanceof SamplingPlanUpdate
            && Identifier.isSameEntity(existingUpdate.documentIdentifier, documentIdentifier)) as SamplingPlanUpdate;
        const updated: boolean = !!pendingUpdate;
        pendingUpdate = pendingUpdate ?? new SamplingPlanUpdate(this.sessionService.activeAccountId, this.sessionService.activePersonBusinessId, documentIdentifier, documentType, clonedSamplingPlanMerge);
        pendingUpdate.samplingPlan = clonedSamplingPlanMerge;

        this.enqueueUpdate(pendingUpdate, updated);

        return pendingUpdate;
    }

    public saveChangedRepeatableSection(documentIdentifier: DocumentIdentifier, sectionTemplateIdentifier: SectionTemplateIdentifier, sectionIdentifier: SectionIdentifier, documentType: DocumentTypes, rows: Array<DocumentSection>): PartialDocumentUpdate|never {
        this.sessionService.requiresAccount();

        let pendingUpdate: DocumentRepeatableQuestionUpdate|undefined = this.updateQueue.find((existingUpdate: PartialDocumentUpdate) =>
            existingUpdate.updateType == DocumentUpdateTypes.repeatableQuestion
            && existingUpdate instanceof DocumentRepeatableQuestionUpdate
            && Identifier.isSameEntity(existingUpdate.documentIdentifier, documentIdentifier)
            && existingUpdate.sectionIdentifier?.businessIdentifier == sectionIdentifier.businessIdentifier) as DocumentRepeatableQuestionUpdate;
        const updated: boolean = !!pendingUpdate;
        pendingUpdate = pendingUpdate ?? new DocumentRepeatableQuestionUpdate(this.sessionService.activeAccountId, this.sessionService.activePersonBusinessId, documentIdentifier, documentType, sectionTemplateIdentifier, sectionIdentifier);

        pendingUpdate.repeatableQuestion = rows;
        this.enqueueUpdate(pendingUpdate, updated);

        return pendingUpdate;
    }

    private enqueueQuestionUpdate(updateType: DocumentUpdateTypes, documentIdentifier: DocumentIdentifier, sectionIdentifier: SectionIdentifier|undefined, questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, documentType: DocumentTypes, valueContainer: DocumentValueContainer): PartialDocumentUpdate|never {
        if (!this.sessionService.activeAccountId) {
            this.sessionService.requiresAccount();
        }

        let pendingUpdate: DocumentQuestionUpdate|undefined = this.updateQueue.find((existingUpdate: PartialDocumentUpdate) =>
            (existingUpdate.updateType == DocumentUpdateTypes.question || existingUpdate.updateType == DocumentUpdateTypes.attachmentLink)
            && existingUpdate instanceof DocumentQuestionUpdate
            && Identifier.isSameEntity(existingUpdate.documentIdentifier, documentIdentifier)
            && existingUpdate.questionTemplateIdentifier == questionTemplateIdentifier
        ) as DocumentQuestionUpdate;

        const updated: boolean = !!pendingUpdate;
        const existingUpdateType: DocumentUpdateTypes = pendingUpdate ? pendingUpdate.updateType : DocumentUpdateTypes.unknown;
        pendingUpdate = pendingUpdate ?? new DocumentQuestionUpdate(this.sessionService.activeAccountId, this.sessionService.activePersonBusinessId, documentIdentifier, documentType, {
            sectionIdentifier: sectionIdentifier,
            questionTemplateIdentifier: questionTemplateIdentifier,
            questionIdentifier: questionIdentifier
        });
        if ((updated && updateType == DocumentUpdateTypes.question) || existingUpdateType == DocumentUpdateTypes.question) {
            pendingUpdate.updateType = DocumentUpdateTypes.question;
        } else {
            pendingUpdate.updateType = updateType;
        }

        this.valueContainerToUpdate(pendingUpdate, valueContainer);

        this.enqueueUpdate(pendingUpdate, updated);

        return pendingUpdate;
    }

    public saveChangedQuestion(documentIdentifier: DocumentIdentifier, sectionIdentifier: SectionIdentifier|undefined, questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, documentType: DocumentTypes, valueContainer: DocumentValueContainer): PartialDocumentUpdate|never {
        return this.enqueueQuestionUpdate(DocumentUpdateTypes.question, documentIdentifier, sectionIdentifier, questionTemplateIdentifier, questionIdentifier, documentType, valueContainer);
    }

    public saveChangedAttachments(documentIdentifier: DocumentIdentifier, sectionIdentifier: SectionIdentifier|undefined, questionTemplateIdentifier: QuestionTemplateIdentifier, questionIdentifier: QuestionIdentifier|undefined, documentType: DocumentTypes, valueContainer: DocumentValueContainer): PartialDocumentUpdate|never {
        return this.enqueueQuestionUpdate(DocumentUpdateTypes.attachmentLink, documentIdentifier, sectionIdentifier, questionTemplateIdentifier, questionIdentifier, documentType, valueContainer);
    }

    private valueContainerToUpdate(pendingUpdate: DocumentQuestionUpdate, valueContainer: DocumentValueContainer): void {
        pendingUpdate.answers = valueContainer.answers;
        pendingUpdate.commentTemplateIdentifier = valueContainer.comment?.identifier;
        pendingUpdate.commentValue = valueContainer.comment?.text;
        pendingUpdate.linkedAttachments = [];
        pendingUpdate.enabled = valueContainer.enabled;
        for (const linkedAttachment of valueContainer.linkedAttachments) {
            pendingUpdate.linkedAttachments.push(linkedAttachment);
        }
    }

    private enqueueUpdate(update: PartialDocumentUpdate, isUpdatedItem: boolean): void {
        if (!isUpdatedItem) {
            this.updateQueue.push(update);
        }
        this.updateState(update, QuestionSaveStates.updateCreated);

        this.saveQueueToStorage().then(() => {
            this.updateSummary();
            this.activateUpdates();
        });
    }

    private activateUpdates(): void {
        if (this.updateTimer.active) {
            return;
        }

        this.updateTimer.start();

        const diff: number = this.timeService.currentTimestamp - this.lastUpdate;
        if (diff > this.answerUpdateDelay) {
            this.sendPendingChanges().then();
        }
    }

    private deactivateUpdates(): void {
        // We don't want to stop the timer
    }

    public forceSendingPendingChanges(): Promise<void> {
        return this.sendPendingChanges();
    }

    private async sendPendingChanges(): Promise<void> {
        if (this.sendingToServer || this.savingQueueToStorage || !this.sessionService.activeAccount?.id) {
            return;
        }

        const accountId: number = this.sessionService.activeAccount.id;
        const personBusinessIdentifier: PersonBusinessIdentifier = this.sessionService.activePersonBusinessId;

        try {
            this.sendingToServer = true;

            const documentQueues: Map<DocumentTypes, Array<PartialDocumentUpdate>> = new Map<DocumentTypes, Array<PartialDocumentUpdate>>();
            for (const queueItem of this.updateQueue) {
                if (queueItem.accountIdentifier != accountId || (queueItem.personBusinessIdentifier && queueItem.personBusinessIdentifier != personBusinessIdentifier)) {
                    // This update belongs to a different account.
                    continue;
                }

                if (!documentQueues.has(queueItem.documentType)) {
                    documentQueues.set(queueItem.documentType, []);
                }
                const subQueue: Array<PartialDocumentUpdate>|undefined = documentQueues.get(queueItem.documentType);
                subQueue!.push(queueItem);
            }

            for (const documentType of documentQueues.keys()) {
                const queue: Array<PartialDocumentUpdate>|undefined = documentQueues.get(documentType);
                if (queue) {
                    await this.sendQueue(documentType, queue);
                }
            }
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this.sendingToServer = false;
            this.lastUpdate = this.timeService.currentTimestamp;

            this.updateSummary();
        }

        if (this.summary.pending <= 0) {
            this.deactivateUpdates();
        }
    }

    private async sendQueue(documentType: DocumentTypes, queue: Array<PartialDocumentUpdate>): Promise<void> {
        const documentUpdates: Map<DocumentIdentifier, Array<PartialDocumentUpdate>> = new Map<DocumentIdentifier, Array<PartialDocumentUpdate>>();
        for (const answerUpdate of queue) {
            const list: Array<PartialDocumentUpdate> = documentUpdates.get(answerUpdate.documentIdentifier) ?? [];
            if (list.length <= 0) {
                documentUpdates.set(answerUpdate.documentIdentifier, list);
            }
            list.push(answerUpdate);
        }

        for (const documentIdentifier of documentUpdates.keys()) {
            const documentQueue: Array<PartialDocumentUpdate> = documentUpdates.get(documentIdentifier) ?? [];
            const revisionBatches: Array<Array<PartialDocumentUpdate>> = this.splitQueueIntoRevisionBatches(documentQueue);
            for (const revisionBatch of revisionBatches) {
                await this.sendDocumentQueue(documentType, documentIdentifier, revisionBatch);
            }
        }
    }

    private splitQueueIntoRevisionBatches(documentQueue: Array<PartialDocumentUpdate>): Array<Array<PartialDocumentUpdate>> {
        // Sort by client creation date
        documentQueue.sort((a: PartialDocumentUpdate, b: PartialDocumentUpdate) => a.updateCreated?.localeCompare(b.updateCreated));

        const revisionBatches: Array<Array<PartialDocumentUpdate>> = [];

        // Split after new revision-update
        let currentBatch: Array<PartialDocumentUpdate> = [];
        for (const documentQuestionUpdate of documentQueue) {
            if (revisionBatches.length >= this.maxBatchesToSend) {
                // Do not process any more batches, the limit has been reached.
                continue;
            }

            // Create single batches for the update if it is marked to not be included in a batch with more than 1 update.
            if (documentQuestionUpdate.noBatchUpdate) {
                if (currentBatch.length > 0) {
                    revisionBatches.push(currentBatch);
                    currentBatch = [];
                }
                revisionBatches.push([documentQuestionUpdate]);
                continue;
            }

            currentBatch.push(documentQuestionUpdate);
            if (documentQuestionUpdate.updateType == DocumentUpdateTypes.newRevision) {
                revisionBatches.push(currentBatch);
                currentBatch = [];
            }
        }
        if (currentBatch.length > 0) {
            revisionBatches.push(currentBatch);
        }

        return revisionBatches;
    }

    private answerUpdateToDto(update: DocumentQuestionUpdate): QuestionDto {
        const result: QuestionDto = {
            businessId: update.questionIdentifier?.businessIdentifier || undefined,
            questionTemplateId: update.questionTemplateIdentifier,
            answer: [],
            photoIds: [],
            enabled: update.enabled,
            comment: update.commentTemplateIdentifier ? `${update.commentTemplateIdentifier}` : update.commentValue
        };

        for (const linkedAttachment of update.linkedAttachments) {
            if (linkedAttachment.identifier.businessIdentifier) {
                result.photoIds!.push({
                    businessId: linkedAttachment.identifier.businessIdentifier,
                    technicalId: linkedAttachment.identifier.technicalIdentifier
                });
            }
        }

        for (const answer of update.answers) {
            result.answer!.push({
                businessId: answer.answerIdentifier?.businessIdentifier || undefined,
                technicalId: answer.answerIdentifier?.technicalIdentifier || undefined,
                value: answer.value !== undefined ? `${answer.value}` : undefined
            });
        }

        return result;
    }

    private sectionUpdateToDto(update: DocumentRepeatableQuestionUpdate): QuestionDto {
        const result: QuestionDto = {
            businessId: update.sectionIdentifier?.businessIdentifier || undefined,
            questionTemplateId: update.sectionTemplateIdentifier,
            repeatable: true
        };
        result.questions = [];

        for (const section of update.repeatableQuestion) {
            const dto: QuestionDto = Api1Converter.documentSectionToSectionDto(section);
            delete dto.repeatable;
            result.questions.push(dto);
        }

        return result;
    }

    // eslint-disable-next-line complexity
    private async sendDocumentQueue(documentType: DocumentTypes, documentId: DocumentIdentifier, queue: Array<PartialDocumentUpdate>): Promise<void> { // NOSONAR typescript:S3776 Cognitive Complexity (Tom Riedl)
        const { questionUpdates, sectionUpdates, createRevision, otherUpdates } = this.prepareUpdateDtos(queue);

        try {
            let sentUpdates: number = 0;
            let revisionUpdateProcessed: boolean = !createRevision;
            if (questionUpdates.length > 0) {
                sentUpdates += await this.sendQuestionUpdates(questionUpdates, documentType, documentId, createRevision);
                revisionUpdateProcessed = true;
            }
            if (sectionUpdates.length > 0) {
                sentUpdates += await this.sendSectionUpdates(sectionUpdates, documentType, documentId, createRevision);
                revisionUpdateProcessed = true;
            }
            if (createRevision && !revisionUpdateProcessed) {
                sentUpdates += await this.sendQuestionUpdates([], documentType, documentId, true);
            }

            sentUpdates += await this.sendDocumentRelatedUpdates(otherUpdates);

            for (const partialUpdate of queue) {
                if (partialUpdate.saveState == QuestionSaveStates.sendingToServer) {
                    // Check if there are pending attachment updates
                    let keepInQueue: boolean = false;
                    if (partialUpdate.updateType == DocumentUpdateTypes.question || partialUpdate.updateType == DocumentUpdateTypes.attachmentLink) {
                        const questionUpdate: DocumentQuestionUpdate = partialUpdate as DocumentQuestionUpdate;
                        const hasPendingAttachments: boolean = this.hasPendingAttachments(questionUpdate);
                        if (hasPendingAttachments || questionUpdate.allUpdatedExceptPendingAttachments) {
                            keepInQueue = true;
                            this.updateState(partialUpdate, QuestionSaveStates.persistedLocally);
                        }
                    }

                    if (!keepInQueue) {
                        ArrayHelper.removeElement(this.updateQueue, partialUpdate);
                        this.updateState(partialUpdate, QuestionSaveStates.acceptedByServer);
                    }
                }
            }

            if (sentUpdates > 0) {
                this.onlineMonitorService.notifyOnline();
            }
        } catch (error) {
            const httpError: HttpErrorResponse = error as HttpErrorResponse;
            if (WebHelper.isNoInternetError(httpError)) {
                // Error code zero means, there is no connection to the server. We retry it a little later.
                for (const questionUpdate of queue) {
                    this.updateState(questionUpdate, QuestionSaveStates.persistedLocally);
                }
                this.onlineMonitorService.notifyOffline();
                return;
            }

            if (queue.length > 1) {
                // Mark all updates to be handled separately and retry
                for (const questionUpdate of queue) {
                    questionUpdate.noBatchUpdate = true;
                    this.updateState(questionUpdate, QuestionSaveStates.persistedLocally);
                }
                return;
            }

            if (BackendHelper.isBackendError(error, BackendErrors.BE48Forbidden)) {
                // We are not allowed to update this document. Therefore, all changes have to be ignored.
                for (const questionUpdate of queue) {
                    ArrayHelper.removeElement(this.updateQueue, questionUpdate);
                    this.updateState(questionUpdate, QuestionSaveStates.acceptedByServer);
                }
                return;
            }

            // There is another error, mark it as failed.
            for (const questionUpdate of queue) {
                questionUpdate.synchronizationErrorString = Api1Converter.errorDtoToString(httpError && httpError.error ? httpError.error : error);
                this.updateState(questionUpdate, QuestionSaveStates.rejectedByServer);
            }
        } finally {
            await this.saveQueueToStorage();
        }
    }

    private async sendDocumentRelatedUpdates(queue: Array<PartialDocumentUpdate>): Promise<number> {
        let processedUpdates: number = 0;
        for (const partialDocumentUpdate of queue) {
            switch (partialDocumentUpdate.updateType) {
                case DocumentUpdateTypes.samplingPlan:
                    processedUpdates += await this.sendSamplingPlanUpdate(partialDocumentUpdate as SamplingPlanUpdate) ? 1 : 0;
                    break;
            }
        }
        return processedUpdates;
    }

    private prepareUpdateDtos(queue: Array<PartialDocumentUpdate>): {
        questionUpdates: Array<QuestionDto>;
        sectionUpdates: Array<QuestionDto>;
        createRevision: boolean;
        otherUpdates: Array<PartialDocumentUpdate>;
    } {
        const questionUpdates: Array<QuestionDto> = [];
        const sectionUpdates: Array<QuestionDto> = [];
        const otherUpdates: Array<PartialDocumentUpdate> = [];
        let createRevision: boolean = false;
        for (const pendingUpdate of queue) {
            if (pendingUpdate.saveState == QuestionSaveStates.rejectedByServer) {
                continue;
            }

            switch (pendingUpdate.updateType) {
                case DocumentUpdateTypes.question:
                case DocumentUpdateTypes.attachmentLink:
                    const questionUpdate: DocumentQuestionUpdate = pendingUpdate as DocumentQuestionUpdate;
                    const hasPendingAttachments: boolean = this.hasPendingAttachments(questionUpdate);
                    if (!questionUpdate.allUpdatedExceptPendingAttachments || !hasPendingAttachments) {
                        questionUpdates.push(this.answerUpdateToDto(pendingUpdate as DocumentQuestionUpdate));
                        questionUpdate.allUpdatedExceptPendingAttachments = hasPendingAttachments;
                        this.updateState(pendingUpdate, QuestionSaveStates.sendingToServer);
                    }
                    break;
                case DocumentUpdateTypes.repeatableQuestion:
                    sectionUpdates.push(this.sectionUpdateToDto(pendingUpdate as DocumentRepeatableQuestionUpdate));
                    this.updateState(pendingUpdate, QuestionSaveStates.sendingToServer);
                    break;
                case DocumentUpdateTypes.newRevision:
                    createRevision = true;
                    this.updateState(pendingUpdate, QuestionSaveStates.sendingToServer);
                    break;
                default:
                    otherUpdates.push(pendingUpdate);
                    this.updateState(pendingUpdate, QuestionSaveStates.sendingToServer);
                    break;
            }
        }
        return { questionUpdates, sectionUpdates, createRevision, otherUpdates };
    }

    private hasPendingAttachments(questionUpdate: DocumentQuestionUpdate): boolean {
        return questionUpdate.linkedAttachments.some((attachment: Attachment) => !attachment.identifier.businessIdentifier && attachment.state != AttachmentStates.errorUploading && attachment.state != AttachmentStates.errorUpdatingMeta);
    }

    private async fetchUpdatedDocument(partialUpdate: PartialDocumentUpdate): Promise<void> {
        switch (partialUpdate.documentType) {
            case DocumentTypes.buildingWaterWalkthroughInspection:
                const walkthroughInspectionDto: ResponseBodyListWalkthroughInspectionsDto = await this.documentsApiService.getWalkthroughInspection(partialUpdate.documentIdentifier.businessIdentifier);
                this.updatedDocumentReceived.emit(walkthroughInspectionDto);
                break;
        }
    }

    private async sendSamplingPlanUpdate(partialUpdate: SamplingPlanUpdate): Promise<boolean> {
        try {
            const result: SafeResult<SamplingPlan, AppException> = await this.samplingsService.saveSamplingPlanMerge(partialUpdate.samplingPlan);
            if (result.isSuccess()) {
                ArrayHelper.removeElement(this.updateQueue, partialUpdate);
                this.updateState(partialUpdate, QuestionSaveStates.acceptedByServer);

                // Get updated document afterwards
                await this.fetchUpdatedDocument(partialUpdate);

                return true;
            } else {
                if (WebHelper.isNoInternetError(result.error)) {
                    this.updateState(partialUpdate, QuestionSaveStates.persistedLocally);
                    this.onlineMonitorService.notifyOffline();
                    return false;
                }

                // Other error occurred
                partialUpdate.synchronizationErrorString = result.error.toString();
                this.updateState(partialUpdate, QuestionSaveStates.rejectedByServer);
            }
        } finally {
            await this.saveQueueToStorage();
        }
        return false;
    }

    private async sendSectionUpdates(sectionUpdates: Array<QuestionDto>, documentType: DocumentTypes, documentId: DocumentIdentifier, createRevision: boolean): Promise<number> {
        if (sectionUpdates.length > 0 || createRevision) {
            switch (documentType) {
                case DocumentTypes.buildingWaterWalkthroughInspection:
                    const walkthroughInspectionDto: ResponseBodyListWalkthroughInspectionsDto = await this.documentsApiService.updateWalkthroughInspectionQuestions(documentId, sectionUpdates, createRevision);
                    if (walkthroughInspectionDto) {
                        this.updatedDocumentReceived.emit(walkthroughInspectionDto);
                    }
                    break;
            }
        }

        return sectionUpdates.length;
    }

    private async sendQuestionUpdates(questionUpdates: Array<QuestionDto>, documentType: DocumentTypes, documentId: DocumentIdentifier, createRevision: boolean): Promise<number> {
        if (questionUpdates.length > 0 || createRevision) {
            switch (documentType) {
                case DocumentTypes.buildingWaterWalkthroughInspection:
                    const walkthroughInspectionDto: ResponseBodyListWalkthroughInspectionsDto = await this.documentsApiService.updateWalkthroughInspectionQuestions(documentId, questionUpdates, createRevision);
                    if (walkthroughInspectionDto) {
                        this.updatedDocumentReceived.emit(walkthroughInspectionDto);
                    }
                    break;
            }
        }

        return questionUpdates.length;
    }

    private updateSummary(): void {
        let pending: number = 0;
        let error: number = 0;

        const accountId: number|undefined = this.sessionService.activeAccount?.id;
        const personBusinessIdentifier: PersonBusinessIdentifier = this.sessionService.activePersonBusinessId;

        if (accountId) {
            for (const pendingUpdate of this.updateQueue) {
                if (pendingUpdate.accountIdentifier == accountId && (!pendingUpdate.personBusinessIdentifier || pendingUpdate.personBusinessIdentifier == personBusinessIdentifier)) {
                    if (pendingUpdate.saveState == QuestionSaveStates.rejectedByServer) {
                        error++;
                    } else {
                        pending++;
                    }
                }
            }
        }

        this.summaryField.pending = pending;
        this.summaryField.error = error;
        this.synchronizationSummaryUpdated.emit(this.summaryField);
    }

    public retry(update: PartialDocumentUpdate): void {
        this.enqueueUpdate(update, true);
    }

    public async delete(update: PartialDocumentUpdate): Promise<void> {
        ArrayHelper.removeElement(this.pendingUpdates, update);
        await this.saveQueueToStorage();
        this.updateSummary();
    }

    public hasPendingUpdates(documentBusinessIdentifier: DocumentBusinessIdentifier): number {
        return this.updateQueue.filter((update: PartialDocumentUpdate) => update.documentIdentifier.businessIdentifier == documentBusinessIdentifier).length;
    }

    public getQueuedItems(updateType: DocumentUpdateTypes, documentBusinessIdentifier: DocumentBusinessIdentifier): Array<PartialDocumentUpdate> {
        return this.updateQueue.filter((item: PartialDocumentUpdate) => (item.updateType == updateType || updateType == DocumentUpdateTypes.unknown) && item.documentIdentifier.businessIdentifier == documentBusinessIdentifier);
    }
}
