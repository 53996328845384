import { HttpErrorResponse } from "@angular/common/http";

import { FrontendErrors } from "../../global/frontend-errors";

/**
 * The exception base class for app exceptions. The message should already be localized and no internal information should be exposed through `message` but it is ok to provide internal information in `internalMessage`.
 */
export class AppException extends Error {
    public constructor(
        public errorCode: FrontendErrors,
        message: string,
        public innerException?: any,
        public innerPayload?: any
    ) {
        super(message);
        Object.setPrototypeOf(this, AppException.prototype);

        if (innerException instanceof HttpErrorResponse) {
            const httpError: HttpErrorResponse = innerException as HttpErrorResponse;
            this.innerErrorCode = httpError.error?.code as string;
            this.innerErrorDetails = httpError.error?.details as string;
            this.innerStatusCode = httpError.error?.status as number;
            this.innerErrorName = httpError.error?.name as string;
        }
    }

    public innerErrorCode?: string;

    public innerErrorDetails?: string;

    public innerStatusCode?: number;

    public innerErrorName?: string;

    public override toString(): string {
        if (this.innerErrorCode) {
            return `${this.errorCode}: ${this.message}\n${this.innerErrorCode} (${this.innerStatusCode}) ${this.innerErrorName}: ${this.innerErrorDetails}`;
        } else {
            return `${this.errorCode}: ${this.message}\n${this.innerException?.message ? this.innerException.message : ""}`;
        }
    }
}
