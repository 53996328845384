import { Directive, ViewContainerRef } from "@angular/core";

/**
 * Directive for dynamically inserting components into other components.
 */
@Directive({
    selector: "[dynamicComponent]"
})
export class DynamicComponentDirective {
    constructor(public viewContainerRef: ViewContainerRef) { }
}
