/**
 * A cell of the arrow game.
 */
export class ArrowGameCell {
    public value: number = 0;

    public get rotation(): number {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return this.value * 90;
    }

    constructor(initialValue?: number) {
        if (initialValue) {
            this.value = initialValue;
        }
    }
}
