import { Injectable } from "@angular/core";

import { Api1Converter } from "../../../api/v1/converters/api1-converter";
import { QualityAssuranceDto } from "../../../generated/api";
import { errorResult, SafeResult, successResult } from "../../common/safe-result";
import { Person } from "../../entities/core-data/person";
import { QualityAssuranceStatus } from "../../entities/documents/quality-assurance-status";
import { AppException } from "../../entities/exceptions/app-exception";
import { QualityAssuranceItem } from "../../entities/quality-assurance/quality-assurance-item";
import { UserPermissions } from "../../global/user-permissions";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { Identifier } from "../../identifiers/identifier";
import { QualityAssuranceIdentifier } from "../../identifiers/quality-assurance-identifier";
import { QuestionIdentifier } from "../../identifiers/question-identifier";
import { QualityAssuranceApiService } from "../api/quality-assurance-api.service";
import { PersonsService } from "../master-data/persons/persons.service";
import { SessionService } from "../session/session.service";

/**
 * Service to handle all QA related jobs.
 */
@Injectable({
    providedIn: "root"
})
export class QualityAssuranceService {
    constructor(
        private readonly qualityAssuranceApiService: QualityAssuranceApiService,
        private readonly personsService: PersonsService,
        private readonly sessionService: SessionService
    ) {
    }

    public async getItem(documentIdentifier: DocumentIdentifier, questionIdentifier: QuestionIdentifier): Promise<QualityAssuranceItem|undefined> {
        const dto: QualityAssuranceDto|undefined = await this.qualityAssuranceApiService.getItem(documentIdentifier, questionIdentifier);
        if (!dto) {
            return undefined;
        }
        const item: QualityAssuranceItem = Api1Converter.dtoToQualityAssuranceItem(dto);
        await this.updatePersonNames(item);
        return item;
    }

    public async updatePersonNames(item: QualityAssuranceItem): Promise<void> {
        for (const message of item.messages) {
            if (message.personId) {
                try {
                    const person: Person = await this.personsService.getPerson(Identifier.create(undefined, message.personId));
                    message.personName = person.displayTitle;
                } catch (error) {
                    console.warn(error);
                }
            }
        }
    }

    public async createState(documentIdentifier: DocumentIdentifier, questionIdentifier: QuestionIdentifier, state: QualityAssuranceStatus, initialMessage?: string): Promise<{ item: QualityAssuranceItem; all: Array<QualityAssuranceItem> }>|never {
        const dto: QualityAssuranceDto = await this.qualityAssuranceApiService.createState(documentIdentifier, questionIdentifier, state, initialMessage);
        const item: QualityAssuranceItem = Api1Converter.dtoToQualityAssuranceItem(dto);
        await this.updatePersonNames(item);

        const allItems: SafeResult<Array<QualityAssuranceItem>, AppException> = await this.getItems(documentIdentifier);
        if (allItems.isError()) {
            throw allItems.error;
        }

        return { item: item, all: allItems.result };
    }

    public async getItems(documentIdentifier: DocumentIdentifier): Promise<SafeResult<Array<QualityAssuranceItem>, AppException>> {
        if (!this.sessionService.hasPermission(UserPermissions.qualityAssuranceRead)) {
            return successResult([]);
        }

        try {
            const dtos: SafeResult<Array<QualityAssuranceDto>, AppException> = await this.qualityAssuranceApiService.getItems(documentIdentifier);
            if (dtos.isError()) {
                return errorResult(dtos.error);
            }
            const result: Array<QualityAssuranceItem> = [];
            for (const dto of dtos.result) {
                const item: QualityAssuranceItem = Api1Converter.dtoToQualityAssuranceItem(dto);
                await this.updatePersonNames(item);
                result.push(item);
            }
            return successResult(result);
        } catch (error) {
            return errorResult(error as AppException);
        }
    }

    public async addMessage(documentIdentifier: DocumentIdentifier, qualityAssuranceIdentifier: QualityAssuranceIdentifier, messageText: string, status: QualityAssuranceStatus): Promise<QualityAssuranceItem>|never {
        const dto: QualityAssuranceDto = await this.qualityAssuranceApiService.addMessage(documentIdentifier, qualityAssuranceIdentifier, messageText, status);
        const item: QualityAssuranceItem = Api1Converter.dtoToQualityAssuranceItem(dto);
        await this.updatePersonNames(item);
        return item;
    }
}
