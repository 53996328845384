import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { EntityLink } from "../../../entities/documents/entity-link";

/**
 * Options for the form dialog.
 */
export class MasterDataFormDialogOptions {
    constructor(dialogTitle: string, init?: Partial<MasterDataFormDialogOptions>) {
        TypeScriptHelper.initObjectFromPartial(this, init);
        this.dialogTitle = dialogTitle;
    }

    public dialogTitle: string;

    public allowClose: boolean = true;

    public allowEntityLinking: boolean = true;

    public readonly: boolean = false;

    public readonlyDueToPermissions: boolean = false;

    public permissionCreate: boolean = false;

    public permissionUpdate: boolean = false;

    public entities: Array<GenericCoreDataEntity> = [];

    public additionalEntityLinks?: Array<EntityLink<GenericCoreDataEntity>>;

    public mandatoryFields: Array<string> = [];

    public propertiesFilterInclude: Array<string> = [];

    public propertiesFilterExclude: Array<string> = [];

    public mandatoryProperties: Array<string> = [];

    public autocomplete?: Map<string, Array<string>>;
}
