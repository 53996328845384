import { Injectable } from "@angular/core";
import { CoreDataEntityStorageDto } from "src/app/business/entities/core-data/core-data-entity-storage-dto";

import { GenericCoreDataEntity } from "../../../entities/core-data/generic-core-data-entity";
import { EntityTypes } from "../../../entities/entity-types";
import { EntityHelper } from "../../../helpers/entity-helper";
import { BusinessTechnicalIdentifierPair } from "../../../identifiers/business-technical-identifier-pair";
import { EntityBusinessIdentifier } from "../../../identifiers/entity-business-identifier";
import { EntityIdentifier } from "../../../identifiers/entity-identifier";
import { EntityTechnicalIdentifier } from "../../../identifiers/entity-technical-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { StorageService } from "../../storage/storage.service";
import { StorageKeys } from "../../storage/storage-keys";
import { CoreDataFactory } from "../core-data-factory";

/**
 * Service to load and store master data entities.
 */
@Injectable({
    providedIn: "root"
})
export class CoreDataStorageService {
    constructor(
        private readonly storageService: StorageService
    ) {
    }

    private cachingDisabled: boolean = false;

    private cachedEntities: Map<EntityTypes, Map<EntityBusinessIdentifier, Map<EntityTechnicalIdentifier, GenericCoreDataEntity>>> = new Map<EntityTypes, Map<EntityBusinessIdentifier, Map<EntityTechnicalIdentifier, GenericCoreDataEntity>>>();

    public async getLatestByBusinessIdentifier<TEntity extends GenericCoreDataEntity>(businessIdentifier: EntityBusinessIdentifier, entityType: EntityTypes): Promise<TEntity|undefined> {
        if (this.cachingDisabled) {
            return undefined;
        }

        return this.loadLatestFromStorage<TEntity>(businessIdentifier, entityType);
    }

    private async loadLatestFromStorage<TEntity extends GenericCoreDataEntity>(businessIdentifier: EntityBusinessIdentifier, entityType: EntityTypes): Promise<TEntity|undefined> {
        const keys: Array<string> = await this.storageService.allKeys();
        const groupKeyPrefix: string = this.getStorageKey({ businessIdentifier: businessIdentifier }, entityType);
        const entityKeys: Array<string> = [];
        for (const key of keys) {
            if (key.startsWith(groupKeyPrefix)) {
                entityKeys.push(key);
            }
        }

        let latestEntity: CoreDataEntityStorageDto|undefined;
        for (const entityKey of entityKeys) {
            const dto: CoreDataEntityStorageDto|undefined = await this.storageService.getByRawKey(entityKey);
            if (!latestEntity?.created || dto?.created && dto.created > latestEntity?.created) {
                latestEntity = dto;
            }
        }

        if (!latestEntity) {
            return undefined;
        }

        const entity: TEntity|undefined = CoreDataFactory.createFromStorageDto(latestEntity);
        if (!entity) {
            return undefined;
        }
        this.updateCache(entity);
        return entity;
    }

    public async get<TEntity extends GenericCoreDataEntity>(identifier: EntityIdentifier, entityType: EntityTypes): Promise<TEntity|undefined> {
        if (this.cachingDisabled) {
            return undefined;
        }

        const entitiesByType: Map<EntityBusinessIdentifier, Map<EntityTechnicalIdentifier, GenericCoreDataEntity>>|undefined = this.cachedEntities.get(entityType);
        const entities: Map<EntityTechnicalIdentifier, GenericCoreDataEntity>|undefined = entitiesByType && identifier.businessIdentifier ? entitiesByType.get(identifier.businessIdentifier) : undefined;
        if (entities && identifier.technicalIdentifier) {
            const cachedItem: TEntity|undefined = entities.get(identifier.technicalIdentifier) as TEntity;
            if (cachedItem) {
                return cachedItem;
            }
        }

        // Find only by technical id
        if (entitiesByType && identifier.technicalIdentifier && !identifier.businessIdentifier) {
            for (const entitiesByTechnicalId of entitiesByType.values()) {
                for (const entityById of entitiesByTechnicalId.values()) {
                    if (entityById.identifier.technicalIdentifier == identifier.technicalIdentifier) {
                        return entityById as TEntity;
                    }
                }
            }
        }

        const key: string = this.getStorageKey(identifier, entityType);
        let dto: CoreDataEntityStorageDto|undefined;
        if (identifier.technicalIdentifier) {
            dto = await this.storageService.getGroupItem(StorageKeys.groupCoreData, key);
        } else {
            // Get all items by the business id and use the latest one
            const dtos: Array<CoreDataEntityStorageDto> = await this.storageService.getGroupItemsByKeyPrefix(StorageKeys.groupCoreData, key);
            dto = EntityHelper.getLatestByCreatedAndUpdatedTime(dtos);
        }
        if (!dto) {
            return undefined;
        }

        if (dto.storageVersion != dto.currentStorageVersion) {
            // Delete if stored version is not compatible to the current one
            await this.storageService.delete(StorageKeys.groupCoreData, key);
            return undefined;
        }

        const entity: TEntity|undefined = CoreDataFactory.createFromStorageDto(dto);
        if (!entity) {
            return undefined;
        }
        this.updateCache(entity);
        return entity;
    }

    private updateCache(entity: GenericCoreDataEntity): void {
        if (entity.isNew) {
            return;
        }

        let entitiesByType: Map<EntityBusinessIdentifier, Map<EntityTechnicalIdentifier, GenericCoreDataEntity>>|undefined = this.cachedEntities.get(entity.entityType);
        let entities: Map<EntityTechnicalIdentifier, GenericCoreDataEntity>|undefined = entitiesByType ? entitiesByType.get(entity.identifier.businessIdentifier) : undefined;
        if (!entities) {
            if (!entitiesByType) {
                entitiesByType = new Map<EntityBusinessIdentifier, Map<EntityTechnicalIdentifier, GenericCoreDataEntity>>();
                this.cachedEntities.set(entity.entityType, entitiesByType);
            }
            entities = new Map<EntityTechnicalIdentifier, GenericCoreDataEntity>();
            entitiesByType.set(entity.identifier.businessIdentifier, entities);
        }
        entities.set(entity.identifier.technicalIdentifier ?? 0 as EntityTechnicalIdentifier, entity);
    }

    private getStorageKeyFromEntity(entity: GenericCoreDataEntity): string {
        return this.getStorageKey(entity.identifier, entity.entityType);
    }

    public getStorageKey(identifier: BusinessTechnicalIdentifierPair<any, any>, entityType: EntityTypes): string {
        return `${EntityTypes[entityType]}-${Identifier.identifierToString(identifier)}`;
    }

    public async updateEntity(entity: GenericCoreDataEntity): Promise<void> {
        await this.persistEntity(entity);
        this.updateCache(entity);
    }

    private async persistEntity(entity: GenericCoreDataEntity): Promise<void> {
        if (entity.isNew) {
            return;
        }

        const key: string = this.getStorageKeyFromEntity(entity);

        const cachedEntity: GenericCoreDataEntity|undefined = await this.get(entity.identifier, entity.entityType);
        if (cachedEntity
            && cachedEntity.identifier.technicalIdentifier == entity.identifier.technicalIdentifier
            && cachedEntity.created == entity.created
            && cachedEntity.updated == entity.updated
            && cachedEntity.updatedClient == entity.updatedClient
        ) {
            // The item has not been updated, there is no need to write into the storage.
            return;
        }

        const dto: CoreDataEntityStorageDto|undefined = entity.toStorageDto();

        await this.storageService.setGroupItem(StorageKeys.groupCoreData, key, dto);
    }

    public activateCaching(active: boolean): void {
        this.cachingDisabled = !active;
    }
}
