/**
 * Options for the camera component.
 */
export class CameraOptions {

    /**
     * CameraOptions.
     *
     * @param multiShotCallback - Executed when multi-shot is enabled and a new photo is shot.
     * @param multiShotActivated - Whether multi-shot should be activated by default.
     */
    public constructor(multiShotCallback?: (base64EncodedPhoto: string) => void, multiShotActivated: boolean = false) {
        this.multiShotCallback = multiShotCallback;
        this.multiShotActivated = multiShotActivated;
    }

    public multiShotCallback?: (base64EncodedPhoto: string) => void;
    public multiShotActivated: boolean;
}
