import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { environment } from "../../../../environments/environment";
import { DialogService } from "../../../base/services/dialog/dialog.service";
import { IntentsService } from "../../../base/services/intents/intents.service";
import { ShowSoftwareLicensesIntent } from "../../../base/services/intents/legal/show-software-licenses-intent";
import { PrivacyPolicyContents } from "../../intents/legal/privacy-policy-contents";
import { ShowPrivacyPolicyIntent } from "../../intents/legal/show-privacy-policy-intent";
import { ShowPrivacyPolicyIntentOptions } from "../../intents/legal/show-privacy-policy-intent-options";
import { AppIcons } from "../../services/icons/app-icons";
import { OnlineMonitorService } from "../../services/monitors/online-monitor.service";
import { ArrowGameComponent } from "../games/arrow-game/arrow-game.component";

/**
 * About dialog component.
 */
@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.scss"]
})
export class AboutComponent implements OnInit {
    constructor(
        private readonly onlineMonitorService: OnlineMonitorService,
        private readonly dialogService: DialogService,
        private readonly modalController: ModalController,
        private readonly intentsService: IntentsService
    ) {
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private requiredLogoClicks: number = 10;

    public serverVersion?: string;

    public serverBuildTime?: string;

    public emailChunks: Array<string> = environment.supportEmailChunks;

    public emailChunkPositions: Array<number> = environment.supportEmailChunkPositions;

    private logoClicks: number = 0;

    public ngOnInit(): void {
        this.serverVersion = this.onlineMonitorService.serverVersion;
        this.serverBuildTime = this.onlineMonitorService.serverInfo?.buildTime;
    }

    public openLink(event: Event): boolean {
        event.stopPropagation();
        const element: HTMLAnchorElement = event.target as HTMLAnchorElement;
        const url: string = element.href;
        window.open(url, "_blank");
        return false;
    }

    public logoClick(): void {
        this.logoClicks++;
        if (this.logoClicks >= this.requiredLogoClicks) {
            this.dialogService.showModal({ component: ArrowGameComponent }).then();
            this.logoClicks = 0;
        }
    }

    public openPrivacyPolicy(): void {
        this.intentsService.executeIntent(ShowPrivacyPolicyIntent, new ShowPrivacyPolicyIntentOptions(PrivacyPolicyContents.privacyPolicy));
    }

    public openFirstContactPrivacyPolicy(): void {
        this.intentsService.executeIntent(ShowPrivacyPolicyIntent, new ShowPrivacyPolicyIntentOptions(PrivacyPolicyContents.firstContactPrivacyPolicy));
    }

    public openSoftwareLicenses(): void {
        this.intentsService.executeIntent(ShowSoftwareLicensesIntent);
    }

    public close(): void {
        this.modalController.dismiss().then();
    }
}
