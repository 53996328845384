import { Injectable } from "@angular/core";

import { environment } from "../../../../../environments/environment";
import { Api1Converter } from "../../../../api/v1/converters/api1-converter";
import { CompanyDto } from "../../../../generated/api";
import { errorResult, SafeResult, successResult } from "../../../common/safe-result";
import { Company } from "../../../entities/core-data/company";
import { Person } from "../../../entities/core-data/person";
import { EntityTypes } from "../../../entities/entity-types";
import { AppException } from "../../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../../global/frontend-errors";
import { CompanyBusinessIdentifier } from "../../../identifiers/company-business-identifier";
import { CompaniesApiService } from "../../api/companies-api.service";
import { CoreDataService } from "../core-data-service/core-data.service";

/**
 * Service to work with companies.
 */
@Injectable({
    providedIn: "root"
})
export class CompaniesService {
    constructor(
        private readonly companiesApiService: CompaniesApiService,
        private readonly coreDataService: CoreDataService
    ) {
    }

    public async getCompanies(searchQuery?: string, limit: number = environment.searchDefaultResultsCount): Promise<Array<Company>>|never {
        const companyDtos: Array<CompanyDto> = await this.companiesApiService.getCompanies(searchQuery, limit);
        const result: Array<Company> = [];
        for (const companyDto of companyDtos) {
            const company: Company = Api1Converter.dtoToCompany(companyDto);
            await this.updateLinkedPersons(company);
            await this.coreDataService.updateEntityCache(company);
            result.push(company);
        }
        return result;
    }

    public async updateLinkedPersons(company: Company): Promise<void> {
        for (const personLink of company.personLinks) {
            if (!personLink.entity) {
                personLink.entity = await this.coreDataService.loadEntity<Person>(personLink.identifier, EntityTypes.person);
            }
        }
    }

    public async deleteCompany(businessIdentifier: CompanyBusinessIdentifier): Promise<SafeResult<void, AppException>> {
        try {
            await this.companiesApiService.deleteCompany(businessIdentifier);
            return successResult(undefined);
        } catch (error) {
            return errorResult(new AppException(FrontendErrors.FE60UnableToDeleteCompany, $localize`:@@exception.fe60UnableToDeleteCompany:Unable to delete company with identifier "${businessIdentifier}".`, error));
        }
    }
}
