import { DOCUMENT } from "@angular/common";
import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import html2canvas from "html2canvas";

import { AppException } from "../../../business/entities/exceptions/app-exception";
import { FrontendErrors } from "../../../business/global/frontend-errors";
import { UsersApiService } from "../../../business/services/api/users-api.service";
import { DeviceService } from "../../../business/services/device/device.service";
import { ErrorReportingService } from "../../../business/services/health/error-reporting.service";
import { AppIcons } from "../../../business/services/icons/app-icons";
import { LoggingService } from "../../../business/services/logging/logging.service";
import { SessionService } from "../../../business/services/session/session.service";
import { StorageService } from "../../../business/services/storage/storage.service";
import { DateTimeHelper } from "../../helpers/date-time-helper";
import { DialogService } from "../../services/dialog/dialog.service";
import { ScreenshotService } from "../../services/screenshot/screenshot.service";

/**
 * This component shows all messages collected through the logging service.
 */
@Component({
    selector: "base-debug-dialog",
    templateUrl: "./debug-dialog.component.html",
    styleUrls: ["./debug-dialog.component.scss"]
})
export class DebugDialogComponent {
    constructor(
        private readonly usersApiService: UsersApiService,
        private readonly modalController: ModalController,
        private readonly loggingService: LoggingService,
        private readonly screenshotService: ScreenshotService,
        private readonly dialogService: DialogService,
        private readonly storageService: StorageService,
        private readonly errorReportingService: ErrorReportingService,
        private readonly sessionService: SessionService,
        private readonly deviceService: DeviceService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.logs = this.loggingService.messages;
    }

    public appIcons: typeof AppIcons = AppIcons;

    public logs: Array<any> = [];

    @ViewChild("canvasDiv")
    public canvasDiv?: ElementRef;

    public refresh(): void {
        this.logs = this.loggingService.messages;
    }

    public async sendDump(): Promise<void> {
        const loading: HTMLIonLoadingElement = await this.dialogService.showLoading("Please wait...");

        try {
            const dump: any = {
                clientConsole: this.logs,
                storage: await this.storageService.dump()
            };
            const json: string = JSON.stringify(dump);
            await this.errorReportingService.addLogItem({
                error: "Health Report",
                code: FrontendErrors.FE45HealthReport,
                deviceId: this.deviceService.deviceIdentifier,
                sessionId: this.sessionService.appSession,
                time: DateTimeHelper.utcNow(),
                innerError: json
            });
            await this.errorReportingService.sendToServer();
            this.dialogService.showToast("Thank you for your report");
        } catch (error) {
            console.error(error);
            this.refresh();
        } finally {
            await loading?.dismiss();
        }
    }

    private exceptionPromise(appException: boolean): Promise<void> {
        if (appException) {
            throw new AppException(FrontendErrors.FE0TestException, "Test exception");
        } else {
            throw new Error("Test exception");
        }
    }

    public async provokeError(appException: boolean): Promise<void> {
        await this.exceptionPromise(appException);
    }

    public async takeScreenshot(): Promise<void> {
        const canvas: HTMLCanvasElement = await html2canvas(this.document.querySelector("body")!);
        this.canvasDiv?.nativeElement.appendChild(canvas);

        await this.screenshotService.takeScreenshotThroughGetDisplayMedia();
    }

    public close(): void {
        this.modalController.dismiss().then();
    }
}
