import { Injectable } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { DateTimeHelper } from "../../../base/helpers/date-time-helper";
import { EventHelper } from "../../../base/helpers/event-helper";
import { WebHelper } from "../../../base/helpers/web-helper";
import { TimeService } from "../../../base/services/time/time.service";
import { Timer } from "../../../base/services/time/timer";
import { AppException } from "../../entities/exceptions/app-exception";
import { FrontendErrors } from "../../global/frontend-errors";
import { ReportingApiService } from "../api/reporting-api.service";
import { DeviceService } from "../device/device.service";
import { LoggingService } from "../logging/logging.service";
import { SessionService } from "../session/session.service";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";
import { ErrorLogItem } from "./error-log-item";

/**
 * Service to report client errors to the backend.
 */
@Injectable({
    providedIn: "root"
})
export class ErrorReportingService {
    constructor(
        private readonly loggingService: LoggingService,
        private readonly sessionService: SessionService,
        private readonly deviceService: DeviceService,
        private readonly storageService: StorageService,
        private readonly timeService: TimeService,
        private readonly reportingApiService: ReportingApiService
    ) {
    }

    private readonly maxErrorReportsPerSession: number = environment.maxErrorReportsPerSession;

    private readonly errorReportingInterval: number = environment.errorReportingInterval;

    private readonly maxFailedTriesSendingToServer: number = 10;

    private errorCount: number = 0;

    private failedTriesSendingToServer: number = 0;

    private cachedErrors: Array<ErrorLogItem> = [];

    private sendToServerTimer?: Timer;

    public async initialize(): Promise<void> {
        this.cachedErrors = await this.storageService.get(StorageKeys.errorLog) ?? [];

        EventHelper.subscribe(this.loggingService.unhandledPromiseException, this.receivedGenericError, this);
        EventHelper.subscribe(this.loggingService.unhandledServerException, this.receivedAppException, this);
        EventHelper.subscribe(this.loggingService.unhandledAppException, this.receivedAppException, this);
        EventHelper.subscribe(this.loggingService.unhandledTextMessageException, this.receivedTextMessageException, this);

        this.sendToServerTimer = this.timeService.spawnTimer(this.errorReportingInterval);
        EventHelper.subscribe(this.sendToServerTimer.elapsed, this.sendToServer, this);
        this.sendToServerTimer.start();

        this.sendToServer().then();
    }

    private receivedTextMessageException(errorMessage: string): void {
        try {
            const errorItem: ErrorLogItem = new ErrorLogItem({
                code: FrontendErrors.FE65UnhandledTextMessageException,
                error: errorMessage
            });
            this.addLogItem(errorItem).then();
        } catch (error) {
            console.warn(error);
        }
    }

    private unknownErrorToString(error: any): string {
        if (typeof error === "string") {
            return error;
        }
        if (error instanceof Error || "message" in error) {
            return "stack" in error && error.stack ? `${error.message} - ${error.stack} - ${JSON.stringify(error)}` : `${error.message} - ${JSON.stringify(error)}`;
        }
        return JSON.stringify(error);
    }

    private receivedAppException(exception: AppException): void {
        try {
            const errorItem: ErrorLogItem = new ErrorLogItem({
                code: exception.errorCode,
                error: this.unknownErrorToString(exception.message),
                innerError: this.unknownErrorToString(exception.innerException)
            });
            this.addLogItem(errorItem).then();
        } catch (error) {
            console.warn(error);
        }
    }

    private receivedGenericError(genericError: Error): void {
        try {
            const errorItem: ErrorLogItem = new ErrorLogItem({
                error: this.unknownErrorToString(genericError)
            });
            this.addLogItem(errorItem).then();
        } catch (error) {
            console.warn(error);
        }
    }

    public async addLogItemString(message: string): Promise<void> {
        try {
            const errorItem: ErrorLogItem = new ErrorLogItem({
                error: message
            });
            this.addLogItem(errorItem).then();
        } catch (error) {
            console.warn(error);
        }
    }

    public async addLogItem(logItem: ErrorLogItem): Promise<void> {
        if (this.errorCount > this.maxErrorReportsPerSession) {
            return;
        }
        this.errorCount++;

        if (!environment.production) {
            // return;
        }

        logItem.time = DateTimeHelper.utcNow();
        logItem.activeUrl = window.location.href;
        logItem.personId = this.sessionService.activePersonBusinessId;
        logItem.deviceId = this.deviceService.deviceIdentifier;
        logItem.sessionId = this.sessionService.appSession;

        this.cachedErrors.push(logItem);
        await this.storageService.set(StorageKeys.errorLog, this.cachedErrors);
    }

    public async sendToServer(): Promise<void> {
        if (this.failedTriesSendingToServer >= this.maxFailedTriesSendingToServer || this.cachedErrors.length <= 0) {
            return;
        }

        let errorsToSend: Array<ErrorLogItem> = this.cachedErrors;
        this.cachedErrors = [];

        try {
            console.info(`Sending ${errorsToSend.length} errors to server...`);
            await this.reportingApiService.reportErrors(errorsToSend);

            errorsToSend = [];
            await this.storageService.set(StorageKeys.errorLog, this.cachedErrors);
        } catch (error) {
            if (!WebHelper.isNoInternetError(error)) {
                console.warn(error);
                this.failedTriesSendingToServer++;
            }
        } finally {
            try {
                if (errorsToSend.length > 0) {
                    this.cachedErrors.unshift(...errorsToSend);
                    await this.storageService.set(StorageKeys.errorLog, this.cachedErrors);
                }
            } catch (error) {
                console.warn(error);
            }
        }
    }
}
