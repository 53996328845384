import { DateTimeHelper } from "../../../base/helpers/date-time-helper";
import { AttachmentIdentifier } from "../../identifiers/attachment-identifier";
import { DocumentBusinessIdentifier } from "../../identifiers/document-business-identifier";
import { DocumentIdentifier } from "../../identifiers/document-identifier";
import { DocumentTechnicalIdentifier } from "../../identifiers/document-technical-identifier";
import { Identifier } from "../../identifiers/identifier";
import { QuestionTechnicalIdentifier } from "../../identifiers/question-technical-identifier";
import { QuestionTemplateIdentifier } from "../../identifiers/question-template-identifier";
import { StorableEntity } from "../storable-entity";
import { AttachmentStates } from "./attachment-states";
import { AttachmentStorageDto } from "./attachment-storage-dto";
import { AttachmentTypes } from "./attachment-types";

/**
 * Base class for an attachment.
 */
export abstract class Attachment implements StorableEntity<AttachmentStorageDto> {
    public identifier: AttachmentIdentifier = Identifier.empty();

    public readonly abstract type: AttachmentTypes;

    public state: AttachmentStates = AttachmentStates.unknown;

    public created?: string;

    public updated?: string;

    public createdClient: string = DateTimeHelper.utcNow();

    public deleted: boolean = false;

    public uploadProgress: number = 0;

    public linkedDocuments: Array<DocumentIdentifier> = [];

    public linkedQuestionTemplates: Array<QuestionTemplateIdentifier> = [];

    public linkedQuestions: Array<QuestionTechnicalIdentifier> = [];

    public bytes?: number;

    public description?: string;

    public metaData?: any;

    public showInPdf: boolean = false;

    public uploadError?: any;

    public replacedByExistingAttachment?: Attachment;

    public get hasError(): boolean {
        return this.state == AttachmentStates.errorUploading || this.state == AttachmentStates.errorUpdatingMeta;
    }

    protected abstract toStorageDtoSpecificProperties(): AttachmentStorageDto;

    protected abstract fromStorageDtoSpecificProperties(dto: AttachmentStorageDto|undefined): void;

    public toStorageDto(): AttachmentStorageDto {
        const dto: AttachmentStorageDto = this.toStorageDtoSpecificProperties();
        dto.businessId = this.identifier?.businessIdentifier;
        dto.technicalId = this.identifier?.technicalIdentifier;

        dto.linkedQuestionTemplates = this.linkedQuestionTemplates;
        dto.linkedQuestions = this.linkedQuestions;

        dto.type = AttachmentTypes[this.type];
        dto.state = AttachmentStates[this.state];
        dto.created = this.created;
        dto.updated = this.updated;
        dto.createdClient = this.createdClient;
        dto.deleted = this.deleted;
        dto.bytes = this.bytes;
        dto.description = this.description;
        dto.metaData = this.metaData ? JSON.stringify(this.metaData) : undefined;

        dto.showInPdf = this.showInPdf;

        if (this.uploadError) {
            dto.uploadError = typeof this.uploadError == "object" ? JSON.stringify(this.uploadError) : `${this.uploadError}`;
        }

        dto.linkedDocuments = [];
        for (const linkedDocument of this.linkedDocuments) {
            dto.linkedDocuments.push({
                bid: linkedDocument.businessIdentifier,
                tid: linkedDocument.technicalIdentifier
            });
        }

        return dto;
    }

    public fromStorageDto(dto: AttachmentStorageDto|undefined): void {
        this.fromStorageDtoSpecificProperties(dto);

        this.identifier = Identifier.create(dto?.businessId, dto?.technicalId);

        this.linkedQuestionTemplates = dto?.linkedQuestionTemplates as Array<QuestionTemplateIdentifier> ?? [];
        this.linkedQuestions = dto?.linkedQuestions as Array<QuestionTechnicalIdentifier> ?? [];

        this.state = dto?.state ? dto.state as AttachmentStates : AttachmentStates.unknown;
        this.created = dto?.created;
        this.updated = dto?.updated;
        this.createdClient = dto?.createdClient ?? DateTimeHelper.utcNow();
        this.deleted = !!dto?.deleted;
        this.bytes = dto?.bytes;
        this.description = dto?.description;
        this.metaData = dto?.metaData ? JSON.parse(dto.metaData) as unknown : undefined;

        this.showInPdf = dto?.showInPdf || false;

        this.uploadError = dto?.uploadError;

        this.linkedDocuments = [];
        for (const linkedDocumentDto of dto?.linkedDocuments ?? []) {
            this.linkedDocuments.push({
                businessIdentifier: (linkedDocumentDto.bid ?? "") as DocumentBusinessIdentifier,
                technicalIdentifier: linkedDocumentDto.tid as DocumentTechnicalIdentifier
            });
        }
    }
}
