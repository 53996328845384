import { DebugDialogComponent } from "../../base/components/debug/debug-dialog.component";
import { DialogService } from "../../base/services/dialog/dialog.service";
import { Intent } from "../../base/services/intents/intent";
import { IocService } from "../../base/services/ioc/ioc.service";

/**
 * The intent to show the debug dialog.
 */
export class ShowDebugDialogIntent implements Intent {
    public name: string = "ShowDebugDialogIntent";
    public identifier?: number;

    constructor(
        private readonly iocService: IocService
    ) {
    }

    public execute(): Promise<boolean> {
        const dialogService: DialogService = this.iocService.resolve(DialogService);
        dialogService.showModal({ component: DebugDialogComponent }).then();
        return Promise.resolve(true);
    }

    public cancel(): Promise<void> {
        return Promise.resolve();
    }
}
