import { ClipboardModule } from "@angular/cdk/clipboard";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IonicModule } from "@ionic/angular";
import { QrCodeModule } from "ng-qrcode";
import { SwiperModule } from "swiper/angular";

import { AvatarComponent } from "./avatar/avatar.component";
import { CameraComponent } from "./camera/camera.component";
import { ContextMenuComponent } from "./context-menu/context-menu.component";
import { CountrySelectionComponent } from "./country-selection/country-selection.component";
import { DebugComponent } from "./debug/debug.component";
import { DebugDialogComponent } from "./debug/debug-dialog.component";
import { BasicDialogComponent } from "./dialogs/basic-dialog/basic-dialog.component";
import { InputDialogComponent } from "./dialogs/input-dialog/input-dialog.component";
import { DynamicComponent } from "./dynamic/dynamic.component";
import { DynamicComponentDirective } from "./dynamic-component-directive";
import { EmailComponent } from "./email/email.component";
import { EnterToTabDirective } from "./enter-to-tab.directive";
import { FlowArrowsComponent } from "./flow-arrows/flow-arrows.component";
import { InfoBoxComponent } from "./info-box/info-box.component";
import { InteractiveToastComponent } from "./interactive-toast/interactive-toast.component";
import { LicensesComponent } from "./licenses/licenses.component";
import { LoadingComponent } from "./loading/loading.component";
import { PageContentContainerComponent } from "./page-content-container/page-content-container.component";
import { ProcessButtonComponent } from "./process-button/process-button.component";
import { ShareUrlComponent } from "./share-url/share-url.component";

/**
 * The components-module that includes all globally used components.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SwiperModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        ScrollingModule,
        MatMenuModule,
        MatListModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        QrCodeModule,
        ClipboardModule,
        MatSelectModule
    ],
    declarations: [
        // Directives
        DynamicComponentDirective,
        EnterToTabDirective,

        // Components
        DynamicComponent,
        PageContentContainerComponent,
        ProcessButtonComponent,
        DebugDialogComponent,
        InfoBoxComponent,
        LoadingComponent,
        AvatarComponent,
        CountrySelectionComponent,
        EmailComponent,
        FlowArrowsComponent,
        DebugComponent,
        LicensesComponent,
        CameraComponent,
        ContextMenuComponent,
        InteractiveToastComponent,
        BasicDialogComponent,
        InputDialogComponent,
        ShareUrlComponent
    ],
    exports: [
        // Directives
        DynamicComponentDirective,
        EnterToTabDirective,

        // Components
        DynamicComponent,
        PageContentContainerComponent,
        ProcessButtonComponent,
        DebugDialogComponent,
        InfoBoxComponent,
        LoadingComponent,
        AvatarComponent,
        CountrySelectionComponent,
        EmailComponent,
        FlowArrowsComponent,
        DebugComponent,
        LicensesComponent,
        CameraComponent,
        ContextMenuComponent,
        InteractiveToastComponent,
        BasicDialogComponent,
        InputDialogComponent,
        ShareUrlComponent
    ]
})
export class BaseComponentsModule {
}
