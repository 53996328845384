/* eslint-disable @typescript-eslint/no-magic-numbers,@typescript-eslint/naming-convention */
// noinspection SpellCheckingInspection

import { Language, LanguageItem } from "./humanize-duration.interface";

/**
 * Language class for {@link HumanizeDuration}.
 */
export class HumanizeDurationLanguage {
    public languages: LanguageItem = {
        ar: {
            y: (c: number) => c === 1 ? "سنة" : "سنوات",
            mo: (c: number) => c === 1 ? "شهر" : "أشهر",
            w: (c: number) => c === 1 ? "أسبوع" : "أسابيع",
            d: (c: number) => c === 1 ? "يوم" : "أيام",
            h: (c: number) => c === 1 ? "ساعة" : "ساعات",
            m: (c: number) => ["دقيقة", "دقائق"][this.getArabicForm(c)],
            s: (c: number) => c === 1 ? "ثانية" : "ثواني",
            ms: (c: number) => c === 1 ? "جزء من الثانية" : "أجزاء من الثانية",
            decimal: ","
        },
        bg: {
            y: (c: number) => ["години", "година", "години"][this.getSlavicForm(c)],
            mo: (c: number) => ["месеца", "месец", "месеца"][this.getSlavicForm(c)],
            w: (c: number) => ["седмици", "седмица", "седмици"][this.getSlavicForm(c)],
            d: (c: number) => ["дни", "ден", "дни"][this.getSlavicForm(c)],
            h: (c: number) => ["часа", "час", "часа"][this.getSlavicForm(c)],
            m: (c: number) => ["минути", "минута", "минути"][this.getSlavicForm(c)],
            s: (c: number) => ["секунди", "секунда", "секунди"][this.getSlavicForm(c)],
            ms: (c: number) => ["милисекунди", "милисекунда", "милисекунди"][
                this.getSlavicForm(c)
            ],
            decimal: ","
        },
        ca: {
            y: (c: number) => `any${c === 1 ? "" : "s"}`,
            mo: (c: number) => `mes${c === 1 ? "" : "os"}`,
            w: (c: number) => `setman${c === 1 ? "a" : "es"}`,
            d: (c: number) => `di${c === 1 ? "a" : "es"}`,
            h: (c: number) => `hor${c === 1 ? "a" : "es"}`,
            m: (c: number) => `minut${c === 1 ? "" : "s"}`,
            s: (c: number) => `segon${c === 1 ? "" : "s"}`,
            ms: (c: number) => `milisegon${c === 1 ? "" : "s"}`,
            decimal: ","
        },
        cs: {
            y: (c: number) => ["rok", "roku", "roky", "let"][this.getCzechOrSlovakForm(c)],
            mo: (c: number) => ["měsíc", "měsíce", "měsíce", "měsíců"][
                this.getCzechOrSlovakForm(c)
            ],
            w: (c: number) => ["týden", "týdne", "týdny", "týdnů"][
                this.getCzechOrSlovakForm(c)
            ],
            d: (c: number) => ["den", "dne", "dny", "dní"][this.getCzechOrSlovakForm(c)],
            h: (c: number) => ["hodina", "hodiny", "hodiny", "hodin"][
                this.getCzechOrSlovakForm(c)
            ],
            m: (c: number) => ["minuta", "minuty", "minuty", "minut"][
                this.getCzechOrSlovakForm(c)
            ],
            s: (c: number) => ["sekunda", "sekundy", "sekundy", "sekund"][
                this.getCzechOrSlovakForm(c)
            ],
            ms: (c: number) => ["milisekunda", "milisekundy", "milisekundy", "milisekund"][
                this.getCzechOrSlovakForm(c)
            ],
            decimal: ","
        },
        da: {
            y: () => "år",
            mo: (c: number) => `måned${c === 1 ? "" : "er"}`,
            w: (c: number) => `uge${c === 1 ? "" : "r"}`,
            d: (c: number) => `dag${c === 1 ? "" : "e"}`,
            h: (c: number) => `time${c === 1 ? "" : "r"}`,
            m: (c: number) => `minut${c === 1 ? "" : "ter"}`,
            s: (c: number) => `sekund${c === 1 ? "" : "er"}`,
            ms: (c: number) => `millisekund${c === 1 ? "" : "er"}`,
            decimal: ","
        },
        de: {
            y: (c: number) => `Jahr${c === 1 ? "" : "e"}`,
            mo: (c: number) => `Monat${c === 1 ? "" : "e"}`,
            w: (c: number) => `Woche${c === 1 ? "" : "n"}`,
            d: (c: number) => `Tag${c === 1 ? "" : "e"}`,
            h: (c: number) => `Stunde${c === 1 ? "" : "n"}`,
            m: (c: number) => `Minute${c === 1 ? "" : "n"}`,
            s: (c: number) => `Sekunde${c === 1 ? "" : "n"}`,
            ms: (c: number) => `Millisekunde${c === 1 ? "" : "n"}`,
            decimal: ","
        },
        de_preposition: {
            y: (c: number) => `Jahr${c === 1 ? "" : "en"}`,
            mo: (c: number) => `Monat${c === 1 ? "" : "en"}`,
            w: (c: number) => `Woche${c === 1 ? "" : "n"}`,
            d: (c: number) => `Tag${c === 1 ? "" : "en"}`,
            h: (c: number) => `Stunde${c === 1 ? "" : "n"}`,
            m: (c: number) => `Minute${c === 1 ? "" : "n"}`,
            s: (c: number) => `Sekunde${c === 1 ? "" : "n"}`,
            ms: (c: number) => `Millisekunde${c === 1 ? "" : "n"}`,
            decimal: ","
        },
        el: {
            y: (c: number) => c === 1 ? "χρόνος" : "χρόνια",
            mo: (c: number) => c === 1 ? "μήνας" : "μήνες",
            w: (c: number) => c === 1 ? "εβδομάδα" : "εβδομάδες",
            d: (c: number) => c === 1 ? "μέρα" : "μέρες",
            h: (c: number) => c === 1 ? "ώρα" : "ώρες",
            m: (c: number) => c === 1 ? "λεπτό" : "λεπτά",
            s: (c: number) => c === 1 ? "δευτερόλεπτο" : "δευτερόλεπτα",
            ms: (c: number) => c === 1
                ? "χιλιοστό του δευτερολέπτου"
                : "χιλιοστά του δευτερολέπτου",
            decimal: ","
        },
        en: {
            y: (c: number) => `year${c === 1 ? "" : "s"}`,
            mo: (c: number) => `month${c === 1 ? "" : "s"}`,
            w: (c: number) => `week${c === 1 ? "" : "s"}`,
            d: (c: number) => `day${c === 1 ? "" : "s"}`,
            h: (c: number) => `hour${c === 1 ? "" : "s"}`,
            m: (c: number) => `minute${c === 1 ? "" : "s"}`,
            s: (c: number) => `second${c === 1 ? "" : "s"}`,
            ms: (c: number) => `millisecond${c === 1 ? "" : "s"}`,
            decimal: "."
        },
        es: {
            y: (c: number) => `año${c === 1 ? "" : "s"}`,
            mo: (c: number) => `mes${c === 1 ? "" : "es"}`,
            w: (c: number) => `semana${c === 1 ? "" : "s"}`,
            d: (c: number) => `día${c === 1 ? "" : "s"}`,
            h: (c: number) => `hora${c === 1 ? "" : "s"}`,
            m: (c: number) => `minuto${c === 1 ? "" : "s"}`,
            s: (c: number) => `segundo${c === 1 ? "" : "s"}`,
            ms: (c: number) => `milisegundo${c === 1 ? "" : "s"}`,
            decimal: ","
        },
        et: {
            y: (c: number) => `aasta${c === 1 ? "" : "t"}`,
            mo: (c: number) => `kuu${c === 1 ? "" : "d"}`,
            w: (c: number) => `nädal${c === 1 ? "" : "at"}`,
            d: (c: number) => `päev${c === 1 ? "" : "a"}`,
            h: (c: number) => `tund${c === 1 ? "" : "i"}`,
            m: (c: number) => `minut${c === 1 ? "" : "it"}`,
            s: (c: number) => `sekund${c === 1 ? "" : "it"}`,
            ms: (c: number) => `millisekund${c === 1 ? "" : "it"}`,
            decimal: ","
        },
        fa: {
            y: () => "سال",
            mo: () => "ماه",
            w: () => "هفته",
            d: () => "روز",
            h: () => "ساعت",
            m: () => "دقیقه",
            s: () => "ثانیه",
            ms: () => "میلی ثانیه",
            decimal: "."
        },
        fi: {
            y: (c: number) => c === 1 ? "vuosi" : "vuotta",
            mo: (c: number) => c === 1 ? "kuukausi" : "kuukautta",
            w: (c: number) => `viikko${c === 1 ? "" : "a"}`,
            d: (c: number) => `päivä${c === 1 ? "" : "ä"}`,
            h: (c: number) => `tunti${c === 1 ? "" : "a"}`,
            m: (c: number) => `minuutti${c === 1 ? "" : "a"}`,
            s: (c: number) => `sekunti${c === 1 ? "" : "a"}`,
            ms: (c: number) => `millisekunti${c === 1 ? "" : "a"}`,
            decimal: ","
        },
        fo: {
            y: () => "ár",
            mo: (c: number) => c === 1 ? "mánaður" : "mánaðir",
            w: (c: number) => c === 1 ? "vika" : "vikur",
            d: (c: number) => c === 1 ? "dagur" : "dagar",
            h: (c: number) => c === 1 ? "tími" : "tímar",
            m: (c: number) => c === 1 ? "minuttur" : "minuttir",
            s: () => "sekund",
            ms: () => "millisekund",
            decimal: ","
        },
        fr: {
            y: (c: number) => `an${c >= 2 ? "s" : ""}`,
            mo: () => "mois",
            w: (c: number) => `semaine${c >= 2 ? "s" : ""}`,
            d: (c: number) => `jour${c >= 2 ? "s" : ""}`,
            h: (c: number) => `heure${c >= 2 ? "s" : ""}`,
            m: (c: number) => `minute${c >= 2 ? "s" : ""}`,
            s: (c: number) => `seconde${c >= 2 ? "s" : ""}`,
            ms: (c: number) => `milliseconde${c >= 2 ? "s" : ""}`,
            decimal: ","
        },
        hr: {
            y: (c: number) => {
                if (c % 10 === 2 || c % 10 === 3 || c % 10 === 4) {
                    return "godine";
                }
                return "godina";
            },
            mo: (c: number) => {
                if (c === 1) {
                    return "mjesec";
                } else if (c === 2 || c === 3 || c === 4) {
                    return "mjeseca";
                }
                return "mjeseci";
            },
            w: (c: number) => {
                if (c % 10 === 1 && c !== 11) {
                    return "tjedan";
                }
                return "tjedna";
            },
            d: (c: number) => c === 1 ? "dan" : "dana",
            h: (c: number) => {
                if (c === 1) {
                    return "sat";
                } else if (c === 2 || c === 3 || c === 4) {
                    return "sata";
                }
                return "sati";
            },
            m: (c: number) => {
                const mod10: number = c % 10;
                if ((mod10 === 2 || mod10 === 3 || mod10 === 4) && (c < 10 || c > 14)) {
                    return "minute";
                }
                return "minuta";
            },
            s: (c: number) => {
                if (
                    c === 10 ||
                    c === 11 ||
                    c === 12 ||
                    c === 13 ||
                    c === 14 ||
                    c === 16 ||
                    c === 17 ||
                    c === 18 ||
                    c === 19 ||
                    c % 10 === 5
                ) {
                    return "sekundi";
                } else if (c % 10 === 1) {
                    return "sekunda";
                } else if (c % 10 === 2 || c % 10 === 3 || c % 10 === 4) {
                    return "sekunde";
                }
                return "sekundi";
            },
            ms: (c: number) => {
                if (c === 1) {
                    return "milisekunda";
                } else if (c % 10 === 2 || c % 10 === 3 || c % 10 === 4) {
                    return "milisekunde";
                }
                return "milisekundi";
            },
            decimal: ","
        },
        hu: {
            y: () => "év",
            mo: () => "hónap",
            w: () => "hét",
            d: () => "nap",
            h: () => "óra",
            m: () => "perc",
            s: () => "másodperc",
            ms: () => "ezredmásodperc",
            decimal: ","
        },
        id: {
            y: () => "tahun",
            mo: () => "bulan",
            w: () => "minggu",
            d: () => "hari",
            h: () => "jam",
            m: () => "menit",
            s: () => "detik",
            ms: () => "milidetik",
            decimal: "."
        },
        is: {
            y: () => "ár",
            mo: (c: number) => `mánuð${c === 1 ? "ur" : "ir"}`,
            w: (c: number) => `vik${c === 1 ? "a" : "ur"}`,
            d: (c: number) => `dag${c === 1 ? "ur" : "ar"}`,
            h: (c: number) => `klukkutím${c === 1 ? "i" : "ar"}`,
            m: (c: number) => `mínút${c === 1 ? "a" : "ur"}`,
            s: (c: number) => `sekúnd${c === 1 ? "a" : "ur"}`,
            ms: (c: number) => `millisekúnd${c === 1 ? "a" : "ur"}`,
            decimal: "."
        },
        it: {
            y: (c: number) => `ann${c === 1 ? "o" : "i"}`,
            mo: (c: number) => `mes${c === 1 ? "e" : "i"}`,
            w: (c: number) => `settiman${c === 1 ? "a" : "e"}`,
            d: (c: number) => `giorn${c === 1 ? "o" : "i"}`,
            h: (c: number) => `or${c === 1 ? "a" : "e"}`,
            m: (c: number) => `minut${c === 1 ? "o" : "i"}`,
            s: (c: number) => `second${c === 1 ? "o" : "i"}`,
            ms: (c: number) => `millisecond${c === 1 ? "o" : "i"}`,
            decimal: ","
        },
        ja: {
            y: () => "年",
            mo: () => "月",
            w: () => "週",
            d: () => "日",
            h: () => "時間",
            m: () => "分",
            s: () => "秒",
            ms: () => "ミリ秒",
            decimal: "."
        },
        ko: {
            y: () => "년",
            mo: () => "개월",
            w: () => "주일",
            d: () => "일",
            h: () => "시간",
            m: () => "분",
            s: () => "초",
            ms: () => "밀리 초",
            decimal: "."
        },
        lo: {
            y: () => "ປີ",
            mo: () => "ເດືອນ",
            w: () => "ອາທິດ",
            d: () => "ມື້",
            h: () => "ຊົ່ວໂມງ",
            m: () => "ນາທີ",
            s: () => "ວິນາທີ",
            ms: () => "ມິນລິວິນາທີ",
            decimal: ","
        },
        lt: {
            y: (c: number) => c % 10 === 0 || (c % 100 >= 10 && c % 100 <= 20)
                ? "metų"
                : "metai",
            mo: (c: number) => ["mėnuo", "mėnesiai", "mėnesių"][this.getLithuanianForm(c)],
            w: (c: number) => ["savaitė", "savaitės", "savaičių"][this.getLithuanianForm(c)],
            d: (c: number) => ["diena", "dienos", "dienų"][this.getLithuanianForm(c)],
            h: (c: number) => ["valanda", "valandos", "valandų"][this.getLithuanianForm(c)],
            m: (c: number) => ["minutė", "minutės", "minučių"][this.getLithuanianForm(c)],
            s: (c: number) => ["sekundė", "sekundės", "sekundžių"][this.getLithuanianForm(c)],
            ms: (c: number) => ["milisekundė", "milisekundės", "milisekundžių"][
                this.getLithuanianForm(c)
            ],
            decimal: ","
        },
        lv: {
            y: (c: number) => ["gads", "gadi"][this.getLatvianForm(c)],
            mo: (c: number) => ["mēnesis", "mēneši"][this.getLatvianForm(c)],
            w: (c: number) => ["nedēļa", "nedēļas"][this.getLatvianForm(c)],
            d: (c: number) => ["diena", "dienas"][this.getLatvianForm(c)],
            h: (c: number) => ["stunda", "stundas"][this.getLatvianForm(c)],
            m: (c: number) => ["minūte", "minūtes"][this.getLatvianForm(c)],
            s: (c: number) => ["sekunde", "sekundes"][this.getLatvianForm(c)],
            ms: (c: number) => ["milisekunde", "milisekundes"][this.getLatvianForm(c)],
            decimal: ","
        },
        ms: {
            y: () => "tahun",
            mo: () => "bulan",
            w: () => "minggu",
            d: () => "hari",
            h: () => "jam",
            m: () => "minit",
            s: () => "saat",
            ms: () => "milisaat",
            decimal: "."
        },
        nl: {
            y: () => "jaar",
            mo: (c: number) => c === 1 ? "maand" : "maanden",
            w: (c: number) => c === 1 ? "week" : "weken",
            d: (c: number) => c === 1 ? "dag" : "dagen",
            h: () => "uur",
            m: (c: number) => c === 1 ? "minuut" : "minuten",
            s: (c: number) => c === 1 ? "seconde" : "seconden",
            ms: (c: number) => c === 1 ? "milliseconde" : "milliseconden",
            decimal: ","
        },
        no: {
            y: () => "år",
            mo: (c: number) => `måned${c === 1 ? "" : "er"}`,
            w: (c: number) => `uke${c === 1 ? "" : "r"}`,
            d: (c: number) => `dag${c === 1 ? "" : "er"}`,
            h: (c: number) => `time${c === 1 ? "" : "r"}`,
            m: (c: number) => `minutt${c === 1 ? "" : "er"}`,
            s: (c: number) => `sekund${c === 1 ? "" : "er"}`,
            ms: (c: number) => `millisekund${c === 1 ? "" : "er"}`,
            decimal: ","
        },
        pl: {
            y: (c: number) => ["rok", "roku", "lata", "lat"][this.getPolishForm(c)],
            mo: (c: number) => ["miesiąc", "miesiąca", "miesiące", "miesięcy"][
                this.getPolishForm(c)
            ],
            w: (c: number) => ["tydzień", "tygodnia", "tygodnie", "tygodni"][
                this.getPolishForm(c)
            ],
            d: (c: number) => ["dzień", "dnia", "dni", "dni"][this.getPolishForm(c)],
            h: (c: number) => ["godzina", "godziny", "godziny", "godzin"][
                this.getPolishForm(c)
            ],
            m: (c: number) => ["minuta", "minuty", "minuty", "minut"][this.getPolishForm(c)],
            s: (c: number) => ["sekunda", "sekundy", "sekundy", "sekund"][
                this.getPolishForm(c)
            ],
            ms: (c: number) => ["milisekunda", "milisekundy", "milisekundy", "milisekund"][
                this.getPolishForm(c)
            ],
            decimal: ","
        },
        pt: {
            y: (c: number) => `ano${c === 1 ? "" : "s"}`,
            mo: (c: number) => c === 1 ? "mês" : "meses",
            w: (c: number) => `semana${c === 1 ? "" : "s"}`,
            d: (c: number) => `dia${c === 1 ? "" : "s"}`,
            h: (c: number) => `hora${c === 1 ? "" : "s"}`,
            m: (c: number) => `minuto${c === 1 ? "" : "s"}`,
            s: (c: number) => `segundo${c === 1 ? "" : "s"}`,
            ms: (c: number) => `milissegundo${c === 1 ? "" : "s"}`,
            decimal: ","
        },
        ro: {
            y: (c: number) => c === 1 ? "an" : "ani",
            mo: (c: number) => c === 1 ? "lună" : "luni",
            w: (c: number) => c === 1 ? "săptămână" : "săptămâni",
            d: (c: number) => c === 1 ? "zi" : "zile",
            h: (c: number) => c === 1 ? "oră" : "ore",
            m: (c: number) => c === 1 ? "minut" : "minute",
            s: (c: number) => c === 1 ? "secundă" : "secunde",
            ms: (c: number) => c === 1 ? "milisecundă" : "milisecunde",
            decimal: ","
        },
        ru: {
            y: (c: number) => ["лет", "год", "года"][this.getSlavicForm(c)],
            mo: (c: number) => ["месяцев", "месяц", "месяца"][this.getSlavicForm(c)],
            w: (c: number) => ["недель", "неделя", "недели"][this.getSlavicForm(c)],
            d: (c: number) => ["дней", "день", "дня"][this.getSlavicForm(c)],
            h: (c: number) => ["часов", "час", "часа"][this.getSlavicForm(c)],
            m: (c: number) => ["минут", "минута", "минуты"][this.getSlavicForm(c)],
            s: (c: number) => ["секунд", "секунда", "секунды"][this.getSlavicForm(c)],
            ms: (c: number) => ["миллисекунд", "миллисекунда", "миллисекунды"][
                this.getSlavicForm(c)
            ],
            decimal: ","
        },
        uk: {
            y: (c: number) => ["років", "рік", "роки"][this.getSlavicForm(c)],
            mo: (c: number) => ["місяців", "місяць", "місяці"][this.getSlavicForm(c)],
            w: (c: number) => ["тижнів", "тиждень", "тижні"][this.getSlavicForm(c)],
            d: (c: number) => ["днів", "день", "дні"][this.getSlavicForm(c)],
            h: (c: number) => ["годин", "година", "години"][this.getSlavicForm(c)],
            m: (c: number) => ["хвилин", "хвилина", "хвилини"][this.getSlavicForm(c)],
            s: (c: number) => ["секунд", "секунда", "секунди"][this.getSlavicForm(c)],
            ms: (c: number) => ["мілісекунд", "мілісекунда", "мілісекунди"][
                this.getSlavicForm(c)
            ],
            decimal: ","
        },
        ur: {
            y: () => "سال",
            mo: (c: number) => c === 1 ? "مہینہ" : "مہینے",
            w: (c: number) => c === 1 ? "ہفتہ" : "ہفتے",
            d: () => "دن",
            h: (c: number) => c === 1 ? "گھنٹہ" : "گھنٹے",
            m: () => "منٹ",
            s: () => "سیکنڈ",
            ms: () => "ملی سیکنڈ",
            decimal: "."
        },
        sk: {
            y: (c: number) => ["rok", "roky", "roky", "rokov"][this.getCzechOrSlovakForm(c)],
            mo: (c: number) => ["mesiac", "mesiace", "mesiace", "mesiacov"][
                this.getCzechOrSlovakForm(c)
            ],
            w: (c: number) => ["týždeň", "týždne", "týždne", "týždňov"][
                this.getCzechOrSlovakForm(c)
            ],
            d: (c: number) => ["deň", "dni", "dni", "dní"][this.getCzechOrSlovakForm(c)],
            h: (c: number) => ["hodina", "hodiny", "hodiny", "hodín"][
                this.getCzechOrSlovakForm(c)
            ],
            m: (c: number) => ["minúta", "minúty", "minúty", "minút"][
                this.getCzechOrSlovakForm(c)
            ],
            s: (c: number) => ["sekunda", "sekundy", "sekundy", "sekúnd"][
                this.getCzechOrSlovakForm(c)
            ],
            ms: (c: number) => ["milisekunda", "milisekundy", "milisekundy", "milisekúnd"][
                this.getCzechOrSlovakForm(c)
            ],
            decimal: ","
        },
        sv: {
            y: () => "år",
            mo: (c: number) => `månad${c === 1 ? "" : "er"}`,
            w: (c: number) => `veck${c === 1 ? "a" : "or"}`,
            d: (c: number) => `dag${c === 1 ? "" : "ar"}`,
            h: (c: number) => `timm${c === 1 ? "e" : "ar"}`,
            m: (c: number) => `minut${c === 1 ? "" : "er"}`,
            s: (c: number) => `sekund${c === 1 ? "" : "er"}`,
            ms: (c: number) => `millisekund${c === 1 ? "" : "er"}`,
            decimal: ","
        },
        tr: {
            y: () => "yıl",
            mo: () => "ay",
            w: () => "hafta",
            d: () => "gün",
            h: () => "saat",
            m: () => "dakika",
            s: () => "saniye",
            ms: () => "milisaniye",
            decimal: ","
        },
        th: {
            y: () => "ปี",
            mo: () => "เดือน",
            w: () => "อาทิตย์",
            d: () => "วัน",
            h: () => "ชั่วโมง",
            m: () => "นาที",
            s: () => "วินาที",
            ms: () => "มิลลิวินาที",
            decimal: "."
        },
        vi: {
            y: () => "năm",
            mo: () => "tháng",
            w: () => "tuần",
            d: () => "ngày",
            h: () => "giờ",
            m: () => "phút",
            s: () => "giây",
            ms: () => "mili giây",
            decimal: ","
        },
        zh_CN: {
            y: () => "年",
            mo: () => "个月",
            w: () => "周",
            d: () => "天",
            h: () => "小时",
            m: () => "分钟",
            s: () => "秒",
            ms: () => "毫秒",
            decimal: "."
        },
        zh_TW: {
            y: () => "年",
            mo: () => "個月",
            w: () => "周",
            d: () => "天",
            h: () => "小時",
            m: () => "分鐘",
            s: () => "秒",
            ms: () => "毫秒",
            decimal: "."
        }
    };

    public addLanguage(key: string, lang: Language): void {
        this.languages[key] = lang;
    }

    // Internal helper function for Polish language.
    private getPolishForm(c: number): number {
        if (c === 1) {
            return 0;
        } else if (Math.floor(c) !== c) {
            return 1;
        } else if (c % 10 >= 2 && c % 10 <= 4 && !(c % 100 > 10 && c % 100 < 20)) {
            return 2;
        } else {
            return 3;
        }
    }

    // Internal helper function for Russian and Ukranian languages.
    private getSlavicForm(c: number): number {
        if (Math.floor(c) !== c) {
            return 2;
        } else if (
            (c >= 5 && c <= 20) ||
            (c % 10 >= 5 && c % 10 <= 9) ||
            c % 10 === 0
        ) {
            return 0;
        } else if (c % 10 === 1) {
            return 1;
        } else if (c > 1) {
            return 2;
        } else {
            return 0;
        }
    }

    // Internal helper function for Lithuanian language.
    private getLithuanianForm(c: number): number {
        if (c === 1 || (c % 10 === 1 && c % 100 > 20)) {
            return 0;
        } else if (
            Math.floor(c) !== c ||
            (c % 10 >= 2 && c % 100 > 20) ||
            (c % 10 >= 2 && c % 100 < 10)
        ) {
            return 1;
        } else {
            return 2;
        }
    }

    // Internal helper function for Arabic language.
    private getArabicForm(c: number): number {
        if (c <= 2) {
            return 0;
        }
        if (c > 2 && c < 11) {
            return 1;
        }
        return 0;
    }

    // Internal helper function for Slovak language.
    private getCzechOrSlovakForm(c: number): number {
        if (c === 1) {
            return 0;
        } else if (Math.floor(c) !== c) {
            return 1;
        } else if (c % 10 >= 2 && c % 10 <= 4 && c % 100 < 10) {
            return 2;
        } else {
            return 3;
        }
    }

    // Internal helper function for Latvian language.
    private getLatvianForm(c: number): number {
        if (c === 1 || (c % 10 === 1 && c % 100 !== 11)) {
            return 0;
        } else {
            return 1;
        }
    }
}
