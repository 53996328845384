<form [formGroup]="formData" enter-to-tab>
    <div class="groupedInputs">
        <mat-form-field [appearance]="formHelper.appearance(readonly)" [class.readonly]="readonly" class="countryField">
            <mat-label>
                <fa-icon [icon]="appIcons.addressCountry"></fa-icon>&nbsp;<ng-container i18n="@@address.country">Country</ng-container>
            </mat-label>

            <mat-select #firstInput #nextTab [disabled]="readonly" [formControl]="countryControl">
                <mat-option *ngFor="let country of countries" [value]="country.code">{{country.name}} - {{country.code}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>
