import { ArrayHelper } from "../../../../base/helpers/array-helper";
import { NumbersHelper } from "../../../../base/helpers/numbers-helper";
import { TypeScriptHelper } from "../../../../base/helpers/type-script-helper";
import { AttachmentTypes } from "../../../entities/attachments/attachment-types";
import { FileAttachment } from "../../../entities/attachments/file-attachment";
import { ImageAttachment } from "../../../entities/attachments/image-attachment";
import { QuestionSaveStates } from "../../../entities/documents/question-save-states";
import { DocumentBusinessIdentifier } from "../../../identifiers/document-business-identifier";
import { Identifier } from "../../../identifiers/identifier";
import { DocumentQuestionUpdate } from "../../../services/document-update/update-containers/document-question-update";
import { PartialDocumentUpdate } from "../../../services/document-update/update-containers/partial-document-update";
import { DocumentUpdateTypes } from "../../../services/documents/document-update-types";
import { SyncTableTypes } from "./sync-table-types";

/**
 * A single entry in the sync table.
 */
export class SyncTableItem {
    constructor(type: SyncTableTypes, update: PartialDocumentUpdate|FileAttachment, init?: Partial<SyncTableItem>) {
        TypeScriptHelper.initObjectFromPartial(this, init);

        this.type = type;

        switch (type) {
            case SyncTableTypes.question:
                this.documentUpdate = update as PartialDocumentUpdate;
                break;
            case SyncTableTypes.image:
                this.fileAttachment = update as FileAttachment;
                break;
        }

        this.updateDescription();
    }

    public type: SyncTableTypes;

    public documentUpdate?: PartialDocumentUpdate;

    public fileAttachment?: FileAttachment;

    public description!: string;

    public get documentBusinessIdentifier(): DocumentBusinessIdentifier|undefined {
        if (this.documentUpdate) {
            return this.documentUpdate.documentIdentifier.businessIdentifier;
        }
        if (this.fileAttachment) {
            return this.fileAttachment.linkedDocuments.length > 0 ? this.fileAttachment.linkedDocuments[0].businessIdentifier : undefined;
        }
    }

    public get error(): string {
        if (this.documentUpdate) {
            return this.documentUpdate.synchronizationErrorString || "---";
        }
        if (this.fileAttachment) {
            return this.fileAttachment.uploadError || "---";
        }
        return "---";
    }

    public get canRetry(): boolean {
        if (this.documentUpdate) {
            return this.documentUpdate.saveState != QuestionSaveStates.acceptedByServer;
        }
        return false;
    }

    public get canDelete(): boolean {
        return this.canRetry;
    }

    private updateDescription(): void {
        if (this.documentUpdate) {
            switch (this.documentUpdate.updateType) {
                case DocumentUpdateTypes.question:
                case DocumentUpdateTypes.attachmentLink:
                    const questionUpdate: DocumentQuestionUpdate = this.documentUpdate as DocumentQuestionUpdate;
                    const parts: Array<string|undefined> = [
                        questionUpdate.sectionTemplateIdentifier ? `${questionUpdate.sectionTemplateIdentifier}` : undefined,
                        questionUpdate.questionTemplateIdentifier ? `${questionUpdate.questionTemplateIdentifier}` : undefined,
                        questionUpdate.sectionIdentifier?.businessIdentifier || questionUpdate.sectionIdentifier?.technicalIdentifier ? Identifier.identifierToReadableString(questionUpdate.sectionIdentifier) : undefined,
                        questionUpdate.questionIdentifier?.businessIdentifier || questionUpdate.questionIdentifier?.technicalIdentifier ? Identifier.identifierToReadableString(questionUpdate.questionIdentifier) : undefined
                    ];
                    for (const answer of questionUpdate.answers) {
                        parts.push(`${answer.value}`);
                    }
                    parts.push(questionUpdate.commentValue);

                    for (const linkedAttachment of questionUpdate.linkedAttachments) {
                        if (linkedAttachment.type == AttachmentTypes.image) {
                            const imageAttachment: ImageAttachment = linkedAttachment as ImageAttachment;
                            parts.push(`\n${linkedAttachment.state}, ${Identifier.identifierToReadableString(linkedAttachment.identifier)}`);
                            parts.push(imageAttachment.localStorageId ? `${imageAttachment.localStorageId}` : undefined);
                        } else {
                            parts.push(`\n${linkedAttachment.state}, ${Identifier.identifierToReadableString(linkedAttachment.identifier)}`);
                        }
                    }

                    this.description = ArrayHelper.removeEmpty(parts).join(", ");
                    break;
                case DocumentUpdateTypes.repeatableQuestion:
                    break;
                case DocumentUpdateTypes.samplingPlan:
                    break;
                default:
                    this.description = "---";
                    break;
            }
            return;
        }

        if (this.fileAttachment) {
            const parts: Array<string|undefined> = [
                this.fileAttachment.filename,
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                this.fileAttachment.bytes ? `${NumbersHelper.format(this.fileAttachment.bytes / 1024, "1.0-0")} KiB` : undefined,
                this.fileAttachment.description,
                this.fileAttachment.mimeType
            ];
            this.description = ArrayHelper.removeEmpty(parts).join(", ");
            return;
        }

        this.description = "";
    }
}
