import { EventEmitter, Injectable } from "@angular/core";

import { DialogService } from "../../../base/services/dialog/dialog.service";
import { CoreDataEntityStorageDto } from "../../entities/core-data/core-data-entity-storage-dto";
import { EntityTypes } from "../../entities/entity-types";
import { Identifier } from "../../identifiers/identifier";
import { AttachmentLoaderService } from "../attachments/attachment-loader.service";
import { DocumentsService } from "../documents/documents.service";
import { CoreDataStorageService } from "../master-data/storage/core-data-storage.service";
import { SessionService } from "../session/session.service";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "../storage/storage-keys";

/**
 * Service to activate or deactivate caching.
 */
@Injectable({
    providedIn: "root"
})
export class CachingService {
    constructor(
        private readonly storageService: StorageService,
        private readonly coreDataStorageService: CoreDataStorageService,
        private readonly sessionService: SessionService,
        private readonly attachmentLoaderService: AttachmentLoaderService,
        private readonly documentsService: DocumentsService,
        private readonly masterDataStorageService: CoreDataStorageService,
        private readonly dialogService: DialogService
    ) {
    }

    public cachingActive: boolean = true;

    public clearingCacheActive: boolean = false;

    public infoMessageUpdated: EventEmitter<string> = new EventEmitter<string>();

    public clearingCacheActiveChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public infoMessage?: string;

    public async initialize(): Promise<void> {
        this.cachingActive = !(await this.storageService.get(StorageKeys.cachingDisabled, false) ?? false);
        this.notifyServices(this.cachingActive);
    }

    public async activateCaching(active: boolean): Promise<void> {
        this.cachingActive = active;
        this.notifyServices(active);

        await this.storageService.set(StorageKeys.cachingDisabled, !active);
    }

    private notifyServices(active: boolean): void {
        this.documentsService.activateCaching(active);
        this.masterDataStorageService.activateCaching(active);
    }

    public async clearCache(): Promise<void> {
        if (this.clearingCacheActive) {
            return;
        }

        this.clearingCacheActive = true;
        this.clearingCacheActiveChanged.emit(this.clearingCacheActive);

        try {
            this.infoMessageUpdate("Clearing group \"documents\"...");
            await this.storageService.clearGroup(StorageKeys.groupDocuments);
            this.infoMessageUpdate("Clearing group \"templateVersions\"...");
            await this.storageService.clearGroup(StorageKeys.groupTemplateVersion);
            this.infoMessageUpdate("Clearing group \"translations\"...");
            await this.storageService.clearGroup(StorageKeys.groupTranslations);
            this.infoMessageUpdate("Clearing \"roomFeatures\"...");
            await this.storageService.delete(StorageKeys.roomFeatures);

            this.infoMessageUpdate("Fetching all core data entities...");
            const coreDataEntities: Array<CoreDataEntityStorageDto> = await this.storageService.getAllByGroupKey(StorageKeys.groupCoreData);
            this.infoMessageUpdate(`Found ${coreDataEntities.length} items.`);
            let counter: number = 0;
            for (const coreDataEntity of coreDataEntities) {
                counter++;
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                if (counter % 100 == 0) {
                    this.infoMessageUpdate(`Deleting item ${counter}/${coreDataEntities.length}...`);
                }

                if (!(coreDataEntity.entityType == EntityTypes.person && coreDataEntity.technicalId == this.sessionService.activePersonTechnicalId)) {
                    const key: string = this.coreDataStorageService.getStorageKey(Identifier.fromDto(coreDataEntity), coreDataEntity.entityType as EntityTypes);
                    await this.storageService.deleteGroupItem(StorageKeys.groupCoreData, key);
                }
            }

            this.infoMessageUpdate("Clearing attachments...");
            await this.attachmentLoaderService.clearCache(this.infoMessageUpdate.bind(this));
            this.infoMessageUpdate("All cleared.");
        } catch (error) {
            this.dialogService.showError(error).then();
        } finally {
            this.clearingCacheActive = false;
            this.clearingCacheActiveChanged.emit(this.clearingCacheActive);
        }
    }

    private infoMessageUpdate(message: string): void {
        console.info(message);
        this.infoMessage = message;
        this.infoMessageUpdated.emit(message);
    }
}
