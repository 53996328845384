import {
    AfterViewInit,
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    Output, Type,
    ViewChild,
    ViewContainerRef
} from "@angular/core";

import { DynamicComponentDirective } from "../dynamic-component-directive";

/**
 * Component to house a dynamically loaded component.
 */
@Component({
    selector: "base-dynamic-component",
    templateUrl: "./dynamic.component.html",
    styleUrls: ["./dynamic.component.scss"]
})
export class DynamicComponent implements AfterViewInit {
    constructor() {
        // Do nothing for now
    }

    @Input()
    public component?: Type<any>;

    @Input()
    public options?: any;

    @ViewChild(DynamicComponentDirective)
    public componentContainer!: DynamicComponentDirective;

    @Output()
    public componentCreated: EventEmitter<{ component: Type<any>; instance: any }> = new EventEmitter<{component: Type<any>; instance: any}>();

    public ngAfterViewInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        setTimeout(() => this.initialize(), 10);
    }

    private initialize(): void {
        if (!this.component) {
            return;
        }

        const viewContainerRef: ViewContainerRef = this.componentContainer.viewContainerRef;
        viewContainerRef.clear();

        const componentRef: ComponentRef<any> = viewContainerRef.createComponent(this.component);
        componentRef.instance.options = this.options as unknown;

        this.componentCreated.emit({ component: this.component, instance: componentRef.instance as unknown });
    }
}
