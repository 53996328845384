import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { DialogButton } from "../basic-dialog/dialog-button";
import { InputDialogOptions } from "./input-dialog-options";

/**
 * Shows an alert with an input.
 */
@Component({
    selector: "app-input-dialog",
    templateUrl: "./input-dialog.component.html",
    styleUrls: ["./input-dialog.component.scss"]
})
export class InputDialogComponent implements AfterViewInit {
    constructor(
        public readonly dialogRef: MatDialogRef<InputDialogComponent>,
        public readonly changeDetectorRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public readonly options: InputDialogOptions|undefined
    ) {}

    @ViewChild("input")
    public inputElementRef?: ElementRef<HTMLInputElement>;

    public ngAfterViewInit(): void {
        this.inputElementRef?.nativeElement?.focus();
        this.changeDetectorRef.detectChanges();
    }

    public submit(): void {
        const value: string|undefined = this.inputElementRef?.nativeElement?.value;
        this.dialogRef.close(value);
    }

    public onClick(button?: DialogButton): void {
        if (button?.role != "destructive") {
            this.submit();
        } else {
            this.dialogRef.close();
        }
    }
}
